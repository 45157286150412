// @flow

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Date: any,
  JSON: any,
};






export type Headers = {
  contentType?: ?$ElementType<Scalars, 'String'>,
  authorization?: ?$ElementType<Scalars, 'String'>,
};

export const AuthMessageValues = Object.freeze({
  REQUEST_COOKIE: 'REQUEST_COOKIE', 
  UPDATE_SESSION: 'UPDATE_SESSION', 
  REQUEST_SIGN_OUT: 'REQUEST_SIGN_OUT', 
  SESSION: 'SESSION'
});


export type AuthMessage = $Values<typeof AuthMessageValues>;

export const AppScanBlockedReasonValues = Object.freeze({
  CORRUPTED_APP_PACKAGE: 'CORRUPTED_APP_PACKAGE', 
  NO_CREDENTIALS: 'NO_CREDENTIALS', 
  NOT_BLOCKED: 'NOT_BLOCKED', 
  OTHER: 'OTHER'
});


export type AppScanBlockedReason = $Values<typeof AppScanBlockedReasonValues>;

export const AuthStrategyValues = Object.freeze({
  OTP: 'OTP', 
  GOOGLE_ACCOUNT: 'GOOGLE_ACCOUNT', 
  SAML: 'SAML'
});


export type AuthStrategy = $Values<typeof AuthStrategyValues>;

export const CompliancePolicyValues = Object.freeze({
  GDPR: 'GDPR', 
  PCI: 'PCI', 
  HIPAA: 'HIPAA', 
  FTC: 'FTC', 
  OWASP: 'OWASP', 
  CALOPPA: 'CALOPPA', 
  COPPA: 'COPPA', 
  VPPA: 'VPPA', 
  CCPA: 'CCPA', 
  MITRE: 'MITRE', 
  OWASP_MSTG: 'OWASP_MSTG', 
  OWASP_ASVS: 'OWASP_ASVS'
});


export type CompliancePolicy = $Values<typeof CompliancePolicyValues>;

export const FindingPriorityValues = Object.freeze({
  P0: 'P0', 
  P1: 'P1', 
  P2: 'P2', 
  P3: 'P3', 
  P4: 'P4'
});


export type FindingPriority = $Values<typeof FindingPriorityValues>;

export const FindingSeverityValues = Object.freeze({
  LOW: 'LOW', 
  MEDIUM: 'MEDIUM', 
  HIGH: 'HIGH'
});


export type FindingSeverity = $Values<typeof FindingSeverityValues>;

export const MobileAppListFilterValues = Object.freeze({
  SCAN_AND_SECURE_APPS: 'SCAN_AND_SECURE_APPS', 
  OPENSCAN_APPS: 'OPENSCAN_APPS'
});


export type MobileAppListFilter = $Values<typeof MobileAppListFilterValues>;

export const MobileAppPlatformValues = Object.freeze({
  IOS: 'IOS', 
  ANDROID: 'ANDROID', 
  WINDOWS_PHONE: 'WINDOWS_PHONE', 
  ANDROID_AMAZON: 'ANDROID_AMAZON', 
  ANDROID_OCULUS: 'ANDROID_OCULUS', 
  ANDROID_FACEBOOK_PORTAL: 'ANDROID_FACEBOOK_PORTAL', 
  MACOS: 'MACOS', 
  IOS_ON_MAC: 'IOS_ON_MAC'
});


export type MobileAppPlatform = $Values<typeof MobileAppPlatformValues>;

export const MobileAppReleaseTypeValues = Object.freeze({
  APP_STORE: 'APP_STORE', 
  PRE_PROD: 'PRE_PROD', 
  APP_STORE_THIRD_PARTY: 'APP_STORE_THIRD_PARTY', 
  ENTERPRISE: 'ENTERPRISE'
});


export type MobileAppReleaseType = $Values<typeof MobileAppReleaseTypeValues>;

export const MobileAppSubscriptionValues = Object.freeze({
  STATIC: 'STATIC', 
  DYNAMIC: 'DYNAMIC', 
  APP_LOGIC: 'APP_LOGIC', 
  P1_ALERTS: 'P1_ALERTS', 
  NO_SUBSCRIPTION: 'NO_SUBSCRIPTION'
});


export type MobileAppSubscription = $Values<typeof MobileAppSubscriptionValues>;

export const PiiTypeValues = Object.freeze({
  ADVERTISING_ID: 'ADVERTISING_ID', 
  AGE: 'AGE', 
  CREDIT_CARD_NUMBER: 'CREDIT_CARD_NUMBER', 
  CREDIT_CARD_TRACK_NUMBER: 'CREDIT_CARD_TRACK_NUMBER', 
  DATE_OF_BIRTH: 'DATE_OF_BIRTH', 
  EMAIL_ADDRESS: 'EMAIL_ADDRESS', 
  FEMALE_NAME: 'FEMALE_NAME', 
  FIRST_NAME: 'FIRST_NAME', 
  GENDER: 'GENDER', 
  IBAN_CODE: 'IBAN_CODE', 
  HTTP_COOKIE: 'HTTP_COOKIE', 
  ICD9_CODE: 'ICD9_CODE', 
  ICD10_CODE: 'ICD10_CODE', 
  IMEI_HARDWARE_ID: 'IMEI_HARDWARE_ID', 
  LAST_NAME: 'LAST_NAME', 
  LOCATION: 'LOCATION', 
  MAC_ADDRESS: 'MAC_ADDRESS', 
  MAC_ADDRESS_LOCAL: 'MAC_ADDRESS_LOCAL', 
  MALE_NAME: 'MALE_NAME', 
  MEDICAL_TERM: 'MEDICAL_TERM', 
  PASSPORT: 'PASSPORT', 
  PERSON_NAME: 'PERSON_NAME', 
  PHONE_NUMBER: 'PHONE_NUMBER', 
  STREET_ADDRESS: 'STREET_ADDRESS', 
  SWIFT_CODE: 'SWIFT_CODE', 
  AUTH_TOKEN: 'AUTH_TOKEN', 
  PASSWORD: 'PASSWORD', 
  JAPAN_BANK_ACCOUNT: 'JAPAN_BANK_ACCOUNT', 
  JAPAN_DRIVERS_LICENSE_NUMBER: 'JAPAN_DRIVERS_LICENSE_NUMBER', 
  JAPAN_INDIVIDUAL_NUMBER: 'JAPAN_INDIVIDUAL_NUMBER', 
  AMERICAN_BANKERS_CUSIP_ID: 'AMERICAN_BANKERS_CUSIP_ID', 
  US_BANK_ROUTING_MICR: 'US_BANK_ROUTING_MICR', 
  US_DRIVERS_LICENSE_NUMBER: 'US_DRIVERS_LICENSE_NUMBER', 
  US_EMPLOYER_IDENTIFICATION_NUMBER: 'US_EMPLOYER_IDENTIFICATION_NUMBER', 
  US_HEALTHCARE_NPI: 'US_HEALTHCARE_NPI', 
  US_PASSPORT: 'US_PASSPORT', 
  US_PREPARER_TAXPAYER_IDENTIFICATION_NUMBER: 'US_PREPARER_TAXPAYER_IDENTIFICATION_NUMBER', 
  US_SOCIAL_SECURITY_NUMBER: 'US_SOCIAL_SECURITY_NUMBER', 
  AWS_CREDENTIALS: 'AWS_CREDENTIALS', 
  AZURE_AUTH_TOKEN: 'AZURE_AUTH_TOKEN', 
  BASIC_AUTH_HEADER: 'BASIC_AUTH_HEADER', 
  ENCRYPTION_KEY: 'ENCRYPTION_KEY', 
  GCP_API_KEY: 'GCP_API_KEY', 
  GCP_CREDENTIALS: 'GCP_CREDENTIALS', 
  JSON_WEB_TOKEN: 'JSON_WEB_TOKEN', 
  WEAK_PASSWORD_HASH: 'WEAK_PASSWORD_HASH', 
  XSRF_TOKEN: 'XSRF_TOKEN'
});


export type PiiType = $Values<typeof PiiTypeValues>;

export const RoleValues = Object.freeze({
  DEVELOPER: 'DEVELOPER', 
  MANAGER: 'MANAGER', 
  SECURITY: 'SECURITY', 
  DEVELOPER_ALL_APPS: 'DEVELOPER_ALL_APPS', 
  SECURITY_ALL_APPS: 'SECURITY_ALL_APPS'
});


export type Role = $Values<typeof RoleValues>;

export const SecurityFindingSearchImportanceTagValues = Object.freeze({
  SECURITY_P1: 'SECURITY_P1', 
  SECURITY: 'SECURITY', 
  APPLE: 'APPLE', 
  APPLE_P1: 'APPLE_P1', 
  GOOGLE: 'GOOGLE', 
  GOOGLE_P1: 'GOOGLE_P1', 
  DEFENSE_IN_DEPTH: 'DEFENSE_IN_DEPTH', 
  SERVER_SIDE: 'SERVER_SIDE', 
  CUSTOMER_POLICY: 'CUSTOMER_POLICY', 
  KEYS_TO_THE_KINGDOM: 'KEYS_TO_THE_KINGDOM', 
  DATA_LEAKS: 'DATA_LEAKS', 
  COFFEE_SHOP_COMPROMISE: 'COFFEE_SHOP_COMPROMISE', 
  CATEGORY_SSL_CERTIFICATE_VALIDATION: 'CATEGORY_SSL_CERTIFICATE_VALIDATION', 
  CATEGORY_SSL_HOSTNAME_VALIDATION: 'CATEGORY_SSL_HOSTNAME_VALIDATION', 
  CATEGORY_SSL_VULNERABLE_SDK: 'CATEGORY_SSL_VULNERABLE_SDK', 
  CATEGORY_SSL_SECURE_NETWORK_REQUESTS: 'CATEGORY_SSL_SECURE_NETWORK_REQUESTS', 
  CATEGORY_SSL_OPEN_SOCKETS: 'CATEGORY_SSL_OPEN_SOCKETS', 
  CATEGORY_SSL_CLEARTEXT_COMMUNICATION: 'CATEGORY_SSL_CLEARTEXT_COMMUNICATION', 
  APP_STORE_PRIVACY: 'APP_STORE_PRIVACY', 
  DATA_TYPE_INSTALLED_APPS: 'DATA_TYPE_INSTALLED_APPS', 
  DATA_TYPE_SMS_MESSAGES: 'DATA_TYPE_SMS_MESSAGES', 
  DATA_TYPE_CONTACT_INFO: 'DATA_TYPE_CONTACT_INFO', 
  DATA_TYPE_CONTACT_LIST: 'DATA_TYPE_CONTACT_LIST', 
  DATA_TYPE_PHOTOS: 'DATA_TYPE_PHOTOS', 
  DATA_TYPE_LOCATION: 'DATA_TYPE_LOCATION', 
  DATA_TYPE_IDFA: 'DATA_TYPE_IDFA', 
  DATA_TYPE_HEALTH_AND_FITNESS: 'DATA_TYPE_HEALTH_AND_FITNESS', 
  DATA_TYPE_PASTEBOARD: 'DATA_TYPE_PASTEBOARD', 
  DATA_TYPE_CELLULAR_CARRIER: 'DATA_TYPE_CELLULAR_CARRIER', 
  DATA_TYPE_WIFI_NETWORK: 'DATA_TYPE_WIFI_NETWORK', 
  DATA_TYPE_MAC_ADDRESS: 'DATA_TYPE_MAC_ADDRESS', 
  DATA_TYPE_DEVICE_IDENTIFIER: 'DATA_TYPE_DEVICE_IDENTIFIER', 
  DATA_TYPE_UNKNOWN: 'DATA_TYPE_UNKNOWN'
});


export type SecurityFindingSearchImportanceTag = $Values<typeof SecurityFindingSearchImportanceTagValues>;

export const FilterByImportanceTagEnumValues = Object.freeze({
  DATA_LEAKS: 'DATA_LEAKS'
});


export type FilterByImportanceTagEnum = $Values<typeof FilterByImportanceTagEnumValues>;

export const FilterByProductEnumValues = Object.freeze({
  CLOUD_SECURE: 'CLOUD_SECURE', 
  API_SECURE: 'API_SECURE', 
  WEB_SECURE: 'WEB_SECURE'
});


export type FilterByProductEnum = $Values<typeof FilterByProductEnumValues>;

export const FilterByCategoryEnumValues = Object.freeze({
  API_MANAGEMENT: 'API_MANAGEMENT', 
  DATABASE: 'DATABASE', 
  OTHER: 'OTHER', 
  SECRET_MANAGEMENT: 'SECRET_MANAGEMENT', 
  SERVERLESS: 'SERVERLESS', 
  STORAGE: 'STORAGE', 
  VIRTUAL_MACHINE: 'VIRTUAL_MACHINE'
});


export type FilterByCategoryEnum = $Values<typeof FilterByCategoryEnumValues>;

export const OpenScanFavoriteAppGroupValues = Object.freeze({
  CUSTOMER_APPS: 'CUSTOMER_APPS', 
  COMPETITOR_APPS: 'COMPETITOR_APPS', 
  USED_BY_EXECS: 'USED_BY_EXECS', 
  USED_BY_FINANCE: 'USED_BY_FINANCE', 
  USED_BY_HR: 'USED_BY_HR', 
  USED_BY_IT: 'USED_BY_IT', 
  USED_BY_SALES: 'USED_BY_SALES', 
  USED_BY_MARKETING: 'USED_BY_MARKETING', 
  USED_BY_ENGINEERING: 'USED_BY_ENGINEERING'
});


export type OpenScanFavoriteAppGroup = $Values<typeof OpenScanFavoriteAppGroupValues>;

export const MobileAppAnalysisStatusValues = Object.freeze({
  ONGOING: 'ONGOING', 
  COMPLETED: 'COMPLETED'
});


export type MobileAppAnalysisStatus = $Values<typeof MobileAppAnalysisStatusValues>;

export const MobileAppAnalysisFeatureEnumValues = Object.freeze({
  KEYS_TO_THE_KINGDOM: 'KEYS_TO_THE_KINGDOM', 
  COFFEE_SHOP_COMPROMISE: 'COFFEE_SHOP_COMPROMISE', 
  SDK_SPYWARE: 'SDK_SPYWARE'
});


export type MobileAppAnalysisFeatureEnum = $Values<typeof MobileAppAnalysisFeatureEnumValues>;

export const MobileAppScanStatusValues = Object.freeze({
  NO_SCANS_INITIATED: 'NO_SCANS_INITIATED', 
  SCAN_ONGOING: 'SCAN_ONGOING', 
  SCAN_COMPLETED: 'SCAN_COMPLETED'
});


export type MobileAppScanStatus = $Values<typeof MobileAppScanStatusValues>;

export const SecurityFindingSearchPriorityValues = Object.freeze({
  P0: 'P0', 
  P1: 'P1', 
  P2: 'P2', 
  P3: 'P3', 
  P4: 'P4', 
  NO_PRIORITY: 'NO_PRIORITY'
});


/**
 * Used by the Security Finding Search endpoint as a filter, it's identical to the
 * FindingPriority enum except it also has NO_PRIORITY
 */
export type SecurityFindingSearchPriority = $Values<typeof SecurityFindingSearchPriorityValues>;

export const SecurityFindingSearchReleaseTypeValues = Object.freeze({
  APP_STORE: 'APP_STORE', 
  PRE_PROD: 'PRE_PROD', 
  APP_STORE_THIRD_PARTY: 'APP_STORE_THIRD_PARTY', 
  ENTERPRISE: 'ENTERPRISE'
});


export type SecurityFindingSearchReleaseType = $Values<typeof SecurityFindingSearchReleaseTypeValues>;

export const TargetStatusValues = Object.freeze({
  OPEN: 'OPEN', 
  NEW: 'NEW', 
  CLOSED_BY_POLICY: 'CLOSED_BY_POLICY', 
  CLOSED_FIXED: 'CLOSED_FIXED', 
  CLOSED_RISK_ACCEPTED: 'CLOSED_RISK_ACCEPTED', 
  CLOSED_COMPENSATING_CONTROL: 'CLOSED_COMPENSATING_CONTROL', 
  CLOSED_ITEM_NOT_FOUND: 'CLOSED_ITEM_NOT_FOUND', 
  OPEN_READY_TO_RESCAN: 'OPEN_READY_TO_RESCAN'
});


export type TargetStatus = $Values<typeof TargetStatusValues>;

export const StatusGroupValues = Object.freeze({
  OPEN: 'OPEN', 
  CLOSED: 'CLOSED'
});


export type StatusGroup = $Values<typeof StatusGroupValues>;

export const SdkFindingsListFilterValues = Object.freeze({
  HAS_SDK_ISSUES: 'HAS_SDK_ISSUES', 
  HAS_OPEN_SDK_ISSUES: 'HAS_OPEN_SDK_ISSUES'
});


export type SdkFindingsListFilter = $Values<typeof SdkFindingsListFilterValues>;

export const TargetAdditionalContextTypeValues = Object.freeze({
  CUSTOM_FORMAT: 'CUSTOM_FORMAT', 
  STACK_TRACE: 'STACK_TRACE', 
  CODE_LIST: 'CODE_LIST', 
  ASSOCIATED_SDKS: 'ASSOCIATED_SDKS'
});


export type TargetAdditionalContextType = $Values<typeof TargetAdditionalContextTypeValues>;

export const ComplianceReportTypeValues = Object.freeze({
  data_trust_mobile_reports: 'data_trust_mobile_reports', 
  data_trust_api_reports: 'data_trust_api_reports', 
  data_trust_cloud_reports: 'data_trust_cloud_reports', 
  data_trust_web_reports: 'data_trust_web_reports', 
  app_store_answers_reports: 'app_store_answers_reports', 
  global_app_store_answers_reports: 'global_app_store_answers_reports', 
  coming_soon: 'coming_soon', 
  gdpr_api_reports: 'gdpr_api_reports', 
  soc2_api_reports: 'soc2_api_reports', 
  hipaa_api_reports: 'hipaa_api_reports', 
  iso27001_api_reports: 'iso27001_api_reports', 
  pci_api_reports: 'pci_api_reports', 
  pentest_api_reports: 'pentest_api_reports', 
  gdpr_cloud_reports: 'gdpr_cloud_reports', 
  soc2_cloud_reports: 'soc2_cloud_reports', 
  hipaa_cloud_reports: 'hipaa_cloud_reports', 
  iso27001_cloud_reports: 'iso27001_cloud_reports', 
  pci_cloud_reports: 'pci_cloud_reports', 
  pentest_cloud_reports: 'pentest_cloud_reports', 
  gdpr_mobile_reports: 'gdpr_mobile_reports', 
  sca_mobile_reports: 'sca_mobile_reports', 
  soc2_mobile_reports: 'soc2_mobile_reports', 
  hipaa_mobile_reports: 'hipaa_mobile_reports', 
  iso27001_mobile_reports: 'iso27001_mobile_reports', 
  pci_mobile_reports: 'pci_mobile_reports', 
  pentest_mobile_reports: 'pentest_mobile_reports', 
  gdpr_web_reports: 'gdpr_web_reports', 
  soc2_web_reports: 'soc2_web_reports', 
  hipaa_web_reports: 'hipaa_web_reports', 
  iso27001_web_reports: 'iso27001_web_reports', 
  pci_web_reports: 'pci_web_reports', 
  pentest_web_reports: 'pentest_web_reports', 
  vulnerability_cloud_reports: 'vulnerability_cloud_reports', 
  vulnerability_web_reports: 'vulnerability_web_reports', 
  vulnerability_mobile_reports: 'vulnerability_mobile_reports', 
  vulnerability_api_reports: 'vulnerability_api_reports', 
  fedramp_cloud_reports: 'fedramp_cloud_reports', 
  fedramp_web_reports: 'fedramp_web_reports', 
  fedramp_mobile_reports: 'fedramp_mobile_reports', 
  fedramp_api_reports: 'fedramp_api_reports', 
  owasp_asvs_api_reports: 'owasp_asvs_api_reports', 
  owasp_asvs_web_reports: 'owasp_asvs_web_reports', 
  owasp_asvs_cloud_reports: 'owasp_asvs_cloud_reports', 
  owasp_asvs_mobile_reports: 'owasp_asvs_mobile_reports', 
  owasp_mstg_mobile_reports: 'owasp_mstg_mobile_reports', 
  form_10k_mobile_reports: 'form_10k_mobile_reports', 
  form_10k_api_reports: 'form_10k_api_reports', 
  form_10k_web_reports: 'form_10k_web_reports', 
  form_10k_cloud_reports: 'form_10k_cloud_reports'
});


export type ComplianceReportType = $Values<typeof ComplianceReportTypeValues>;

export const AppleCategoryValues = Object.freeze({
  CONTACT_INFO: 'CONTACT_INFO', 
  HEALTH_AND_FITNESS: 'HEALTH_AND_FITNESS', 
  FINANCIAL_INFO: 'FINANCIAL_INFO', 
  LOCATION: 'LOCATION', 
  SENSITIVE_INFO: 'SENSITIVE_INFO', 
  CONTACTS: 'CONTACTS', 
  USER_CONTENT: 'USER_CONTENT', 
  BROWSING_HISTORY: 'BROWSING_HISTORY', 
  SEARCH_HISTORY: 'SEARCH_HISTORY', 
  IDENTIFIERS: 'IDENTIFIERS', 
  PURCHASES: 'PURCHASES', 
  USAGE_DATA: 'USAGE_DATA', 
  DIAGNOSTICS: 'DIAGNOSTICS', 
  OTHER_DATA: 'OTHER_DATA'
});


export type AppleCategory = $Values<typeof AppleCategoryValues>;

export const DataTypeValues = Object.freeze({
  DATA_TYPE_CONTACT_INFO: 'DATA_TYPE_CONTACT_INFO', 
  DATA_TYPE_CONTACT_LIST: 'DATA_TYPE_CONTACT_LIST', 
  DATA_TYPE_PHOTOS: 'DATA_TYPE_PHOTOS', 
  DATA_TYPE_LOCATION: 'DATA_TYPE_LOCATION', 
  DATA_TYPE_IDFA: 'DATA_TYPE_IDFA', 
  DATA_TYPE_HEALTH_AND_FITNESS: 'DATA_TYPE_HEALTH_AND_FITNESS', 
  DATA_TYPE_PASTEBOARD: 'DATA_TYPE_PASTEBOARD', 
  DATA_TYPE_CELLULAR_CARRIER: 'DATA_TYPE_CELLULAR_CARRIER', 
  DATA_TYPE_WIFI_NETWORK: 'DATA_TYPE_WIFI_NETWORK', 
  DATA_TYPE_MAC_ADDRESS: 'DATA_TYPE_MAC_ADDRESS', 
  DATA_TYPE_DEVICE_IDENTIFIER: 'DATA_TYPE_DEVICE_IDENTIFIER', 
  DATA_TYPE_UNKNOWN: 'DATA_TYPE_UNKNOWN'
});


export type DataType = $Values<typeof DataTypeValues>;

export const OnboardingProductsEnumValues = Object.freeze({
  MOBILE: 'MOBILE', 
  WEB: 'WEB', 
  API: 'API', 
  CLOUD: 'CLOUD'
});


export type OnboardingProductsEnum = $Values<typeof OnboardingProductsEnumValues>;

export const OnboardingStepsEnumValues = Object.freeze({
  MOBILE_CI_CD_SCANS: 'MOBILE_CI_CD_SCANS', 
  MOBILE_PROTECT_SDK_INTEGRATION: 'MOBILE_PROTECT_SDK_INTEGRATION', 
  MOBILE_CREDENTIALS: 'MOBILE_CREDENTIALS', 
  MOBILE_SETUP_VISITED: 'MOBILE_SETUP_VISITED', 
  WEB_URLS: 'WEB_URLS', 
  WEB_UPLOAD_LOGS: 'WEB_UPLOAD_LOGS', 
  WEB_SETUP_VISITED: 'WEB_SETUP_VISITED', 
  API_CI_CD_SCANS: 'API_CI_CD_SCANS', 
  API_UPLOAD_SPECS: 'API_UPLOAD_SPECS', 
  API_CLOUD_PLATFORM: 'API_CLOUD_PLATFORM', 
  API_GATEWAY: 'API_GATEWAY', 
  API_SETUP_VISITED: 'API_SETUP_VISITED', 
  API_PROTECT: 'API_PROTECT', 
  CLOUD_CLOUD_PLATFORM: 'CLOUD_CLOUD_PLATFORM', 
  CLOUD_SETUP_VISITED: 'CLOUD_SETUP_VISITED', 
  CLOUD_ALPHASOC_TELEMETRY: 'CLOUD_ALPHASOC_TELEMETRY'
});


export type OnboardingStepsEnum = $Values<typeof OnboardingStepsEnumValues>;

export const JiraExportFilterEnumValues = Object.freeze({
  ALL_ISSUES: 'ALL_ISSUES', 
  P1_ISSUES_AND_BLOCKERS: 'P1_ISSUES_AND_BLOCKERS', 
  HIGH_SEVERITY: 'HIGH_SEVERITY', 
  DISABLED: 'DISABLED'
});


export type JiraExportFilterEnum = $Values<typeof JiraExportFilterEnumValues>;

export const ScopedAccessTokenTypeEnumValues = Object.freeze({
  APP_SECURE_SHARE_MOBILE_APP_PERMISSIONS: 'APP_SECURE_SHARE_MOBILE_APP_PERMISSIONS', 
  APP_SECURE_SHARE_FINDING_PERMISSIONS: 'APP_SECURE_SHARE_FINDING_PERMISSIONS', 
  APP_SEARCH_SHARE_OPENSCAN_APP_PERMISSIONS: 'APP_SEARCH_SHARE_OPENSCAN_APP_PERMISSIONS'
});


export type ScopedAccessTokenTypeEnum = $Values<typeof ScopedAccessTokenTypeEnumValues>;

export const ApiCredentialsAccessLevelEnumValues = Object.freeze({
  ANY: 'ANY', 
  READ_ONLY: 'READ_ONLY', 
  LOW_PRIVILEGES: 'LOW_PRIVILEGES', 
  HIGH_PRIVILEGES: 'HIGH_PRIVILEGES'
});


export type ApiCredentialsAccessLevelEnum = $Values<typeof ApiCredentialsAccessLevelEnumValues>;

export const ApiCredentialsAuthenticationTypeEnumValues = Object.freeze({
  API_KEYS: 'API_KEYS', 
  OAUTH2: 'OAUTH2', 
  DESCRIBED_IN_UPLOADED_FILE: 'DESCRIBED_IN_UPLOADED_FILE', 
  BASIC_AUTH: 'BASIC_AUTH'
});


export type ApiCredentialsAuthenticationTypeEnum = $Values<typeof ApiCredentialsAuthenticationTypeEnumValues>;

/** Misc Types */
export type ActivityLogEvent = {
  __typename: 'ActivityLogEvent',
  id: $ElementType<Scalars, 'String'>,
  event_type: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
};

export type ApplicationMetadata = {
  __typename: 'ApplicationMetadata',
  closed_security_finding_apple_app_store_blocker_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_beyond_subscription_level_count?: ?$ElementType<Scalars, 'String'>,
  closed_security_finding_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_google_play_blocker_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_high_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_low_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_medium_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_security_p1_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_regulatory_compliance_count: $ElementType<Scalars, 'String'>,
  open_insight_finding_count: $ElementType<Scalars, 'String'>,
  open_sdk_finding_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_sdk_finding_beyond_subscription_level_with_open_issues_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_sdk_finding_count: $ElementType<Scalars, 'String'>,
  open_sdk_finding_with_open_issues_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_sdk_finding_with_open_issues_count: $ElementType<Scalars, 'String'>,
  open_security_finding_apple_app_store_blocker_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_security_finding_apple_app_store_blocker_count: $ElementType<Scalars, 'String'>,
  open_security_finding_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_security_finding_count: $ElementType<Scalars, 'String'>,
  open_security_finding_google_play_blocker_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_security_finding_google_play_blocker_count: $ElementType<Scalars, 'String'>,
  open_security_finding_high_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_security_finding_high_count: $ElementType<Scalars, 'String'>,
  open_security_finding_low_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_security_finding_low_count: $ElementType<Scalars, 'String'>,
  open_security_finding_medium_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_security_finding_medium_count: $ElementType<Scalars, 'String'>,
  open_security_finding_security_p1_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_security_finding_security_p1_count: $ElementType<Scalars, 'String'>,
  open_security_finding_regulatory_compliance_count: $ElementType<Scalars, 'String'>,
  open_security_finding_regulatory_compliance_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
};

export type ApplicationScan = {
  __typename: 'ApplicationScan',
  end_date?: ?$ElementType<Scalars, 'String'>,
  date_updated: $ElementType<Scalars, 'String'>,
  mobile_app_id: $ElementType<Scalars, 'String'>,
  start_date: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'String'>,
  app_version?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
};

export type BlockerMetrics = {
  __typename: 'BlockerMetrics',
  open_blocker_issue_count: $ElementType<Scalars, 'Float'>,
  open_blocker_issue_due_to_third_party_count: $ElementType<Scalars, 'Float'>,
  open_blocker_issue_due_to_first_party_count: $ElementType<Scalars, 'Float'>,
  closed_blocker_issue_count: $ElementType<Scalars, 'Float'>,
  total_blocker_issue_count: $ElementType<Scalars, 'Float'>,
};

export type CompliancePolicyReference = {
  __typename: 'CompliancePolicyReference',
  compliance_policy: CompliancePolicy,
  link: $ElementType<Scalars, 'String'>,
  markdown: $ElementType<Scalars, 'String'>,
};

export type Integrations = {
  __typename: 'Integrations',
  ci_cd?: ?$ElementType<Scalars, 'Boolean'>,
  issue_tracker?: ?$ElementType<Scalars, 'Boolean'>,
  trust_kit?: ?$ElementType<Scalars, 'Boolean'>,
};

export type Note = {
  __typename: 'Note',
  author_email: $ElementType<Scalars, 'String'>,
  date: $ElementType<Scalars, 'Date'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  id: $ElementType<Scalars, 'ID'>,
  security_finding_id: $ElementType<Scalars, 'ID'>,
  text: $ElementType<Scalars, 'String'>,
};

export type TargetStatusWithDate = {
  __typename: 'TargetStatusWithDate',
  date: $ElementType<Scalars, 'Date'>,
  status: TargetStatus,
};

export type TargetStatusWithFinding = {
  __typename: 'TargetStatusWithFinding',
  date: $ElementType<Scalars, 'Date'>,
  status: TargetStatus,
  security_finding?: ?SecurityFinding,
};

export type ToolkitsFindingAndTargetsCount = {
  __typename: 'ToolkitsFindingAndTargetsCount',
  open_security_finding_count: $ElementType<Scalars, 'String'>,
  open_security_finding_target_count: $ElementType<Scalars, 'String'>,
};

export type AppStorePrivacy = {
  __typename: 'AppStorePrivacy',
  data_record_types_collected_count: $ElementType<Scalars, 'Float'>,
  underreported_data_types_count: $ElementType<Scalars, 'Float'>,
};

export type DownloadedInCountriesResponse = {
  __typename: 'DownloadedInCountriesResponse',
  alpha2: $ElementType<Scalars, 'String'>,
  name_en: $ElementType<Scalars, 'String'>,
};

export type DtObservedPrivacyData = {
  __typename: 'DTObservedPrivacyData',
  apple_data_category: ApplePrivacyDataTypeEnum,
  apple_data_category_name_en: $ElementType<Scalars, 'String'>,
  is_underreported: $ElementType<Scalars, 'Boolean'>,
};

export type AppleReportedPrivacyData = {
  __typename: 'AppleReportedPrivacyData',
  apple_data_category: ApplePrivacyDataTypeEnum,
  apple_data_category_name_en: $ElementType<Scalars, 'String'>,
};

export type AppStorePrivacyDetails = {
  __typename: 'AppStorePrivacyDetails',
  embedded_sdk_count: $ElementType<Scalars, 'Float'>,
  data_records_collected_count: $ElementType<Scalars, 'String'>,
  data_records_shared_with_sdks_count: $ElementType<Scalars, 'String'>,
  downloaded_in_countries?: ?Array<DownloadedInCountriesResponse>,
  data_theorem_observed_privacy_data?: ?Array<DtObservedPrivacyData>,
  app_store_privacy_data?: ?Array<AppleReportedPrivacyData>,
  app_store_privacy_screenshot_url?: ?$ElementType<Scalars, 'String'>,
  app_store_privacy_screenshot_url_date?: ?$ElementType<Scalars, 'String'>,
};

/** CORE TYPES */
export type MobileApp = {
  __typename: 'MobileApp',
  app_protection_score?: ?$ElementType<Scalars, 'String'>,
  app_protection_score_ratio?: ?$ElementType<Scalars, 'Float'>,
  blocked_scan_reason?: ?AppScanBlockedReason,
  bundle_id?: ?$ElementType<Scalars, 'String'>,
  category?: ?$ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'String'>,
  date_updated?: ?$ElementType<Scalars, 'String'>,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  uuid?: ?$ElementType<Scalars, 'String'>,
  integrations?: ?Integrations,
  max_app_protection_score: $ElementType<Scalars, 'String'>,
  metadata: ApplicationMetadata,
  most_recent_scan?: ?ApplicationScan,
  name: $ElementType<Scalars, 'String'>,
  platform: MobileAppPlatform,
  release_type?: ?MobileAppReleaseType,
  store_url?: ?$ElementType<Scalars, 'String'>,
  subscription: MobileAppSubscription,
  is_enterprise_internal?: ?$ElementType<Scalars, 'Boolean'>,
  app_store_customer_mobile_app_id?: ?$ElementType<Scalars, 'ID'>,
  keys_to_the_kingdom: ToolkitsFindingAndTargetsCount,
  coffee_shop_compromise: ToolkitsFindingAndTargetsCount,
  trustkit_url?: ?$ElementType<Scalars, 'String'>,
  app_store_privacy?: ?AppStorePrivacy,
  app_store_privacy_details?: ?AppStorePrivacyDetails,
  scan_status?: ?MobileAppScanStatus,
  all_communication_encrypted: $ElementType<Scalars, 'Boolean'>,
  does_not_offload_data_to_third_parties: $ElementType<Scalars, 'Boolean'>,
  enhances_password_security: $ElementType<Scalars, 'Boolean'>,
  full_protection_on_untrusted_networks: $ElementType<Scalars, 'Boolean'>,
  leverages_secure_browser_settings: $ElementType<Scalars, 'Boolean'>,
  leverages_secure_system_libraries: $ElementType<Scalars, 'Boolean'>,
  leverages_secure_system_sdk: $ElementType<Scalars, 'Boolean'>,
  leverages_secure_system_settings: $ElementType<Scalars, 'Boolean'>,
  protects_data_on_screens: $ElementType<Scalars, 'Boolean'>,
  protects_the_keyboard: $ElementType<Scalars, 'Boolean'>,
  removes_data_from_shared_device_locations: $ElementType<Scalars, 'Boolean'>,
  requires_android_device_security: $ElementType<Scalars, 'Boolean'>,
  requires_android_malware_protection: $ElementType<Scalars, 'Boolean'>,
  server_enforces_https: $ElementType<Scalars, 'Boolean'>,
  supports_two_factor_authentication: $ElementType<Scalars, 'Boolean'>,
  app_secure_id?: ?$ElementType<Scalars, 'ID'>,
  asset_tags?: ?Array<?AssetTag>,
};

export type MobileAppInsights = {
  __typename: 'MobileAppInsights',
  mobile_app_id: $ElementType<Scalars, 'ID'>,
  mobile_app_platform?: ?MobileAppPlatform,
  mobile_app_release_type?: ?MobileAppReleaseType,
  mobile_app_bundle_id?: ?$ElementType<Scalars, 'String'>,
  reminders_accessed_by?: ?Array<$ElementType<Scalars, 'String'>>,
  microphone_accessed_by?: ?Array<$ElementType<Scalars, 'String'>>,
  camera_accessed_by?: ?Array<$ElementType<Scalars, 'String'>>,
  calendar_data_accessed_by?: ?Array<$ElementType<Scalars, 'String'>>,
  contacts_accessed_by?: ?Array<$ElementType<Scalars, 'String'>>,
  photo_library_accessed_by?: ?Array<$ElementType<Scalars, 'String'>>,
  location_accessed_by?: ?Array<$ElementType<Scalars, 'String'>>,
  advertisement_identifier_accessed_by?: ?Array<$ElementType<Scalars, 'String'>>,
  open_server_ports?: ?Array<$ElementType<Scalars, 'String'>>,
  app_container_contents?: ?Array<$ElementType<Scalars, 'String'>>,
  hard_coded_secrets?: ?Array<$ElementType<Scalars, 'String'>>,
  custom_fonts?: ?Array<$ElementType<Scalars, 'String'>>,
  sha256_hash?: ?$ElementType<Scalars, 'String'>,
  android_permissions?: ?Array<$ElementType<Scalars, 'String'>>,
  android_minimum_sdk_version?: ?$ElementType<Scalars, 'String'>,
  android_target_sdk_version?: ?$ElementType<Scalars, 'String'>,
  android_apk_signature_key_size?: ?$ElementType<Scalars, 'String'>,
  android_exposed_app_activities?: ?$ElementType<Scalars, 'String'>,
  android_exposed_app_content_providers?: ?Array<$ElementType<Scalars, 'String'>>,
  android_exposed_app_services?: ?Array<$ElementType<Scalars, 'String'>>,
  android_exposed_app_broadcast_receivers?: ?Array<$ElementType<Scalars, 'String'>>,
  android_version_name?: ?$ElementType<Scalars, 'String'>,
  android_version_code?: ?$ElementType<Scalars, 'String'>,
  android_native_components_loaded?: ?Array<$ElementType<Scalars, 'String'>>,
  android_sha512_hash?: ?$ElementType<Scalars, 'String'>,
  ios_permissions?: ?Array<$ElementType<Scalars, 'String'>>,
  ios_app_extensions?: ?Array<$ElementType<Scalars, 'String'>>,
  ios_supports_apple_watch?: ?$ElementType<Scalars, 'Boolean'>,
  ios_supports_face_id?: ?$ElementType<Scalars, 'Boolean'>,
  ios_supports_imessage?: ?$ElementType<Scalars, 'Boolean'>,
  ios_base_sdk?: ?$ElementType<Scalars, 'String'>,
  ios_minimum_sdk?: ?$ElementType<Scalars, 'String'>,
  ios_universal_links_domains?: ?Array<$ElementType<Scalars, 'String'>>,
  ios_accessed_healthkit?: ?$ElementType<Scalars, 'Boolean'>,
  ios_cf_bundle_version?: ?$ElementType<Scalars, 'String'>,
  communicates_with_third_party_origins?: ?Array<$ElementType<Scalars, 'String'>>,
  communicates_with_first_party_origins?: ?Array<$ElementType<Scalars, 'String'>>,
  communicates_with_ip_addresses?: ?Array<$ElementType<Scalars, 'String'>>,
  communicates_with_countries?: ?Array<$ElementType<Scalars, 'String'>>,
  communicates_with_blacklisted_ip_addresses?: ?Array<?BlocklistedIpAddress>,
  sdk_count?: ?$ElementType<Scalars, 'Float'>,
  ios_entitlements?: ?$ElementType<Scalars, 'JSON'>,
};

export type SdkFindingTargetStatus = {
  __typename: 'SDKFindingTargetStatus',
  date: $ElementType<Scalars, 'Date'>,
  status: TargetStatus,
};

export type SdkFindingTarget = {
  __typename: 'SDKFindingTarget',
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  sdk_finding_id: $ElementType<Scalars, 'String'>,
  originating_scan_result_id?: ?$ElementType<Scalars, 'Float'>,
  id: $ElementType<Scalars, 'String'>,
  formatted_text?: ?$ElementType<Scalars, 'String'>,
  statuses?: ?Array<SdkFindingTargetStatus>,
  raw_text?: ?$ElementType<Scalars, 'String'>,
};

export type SdkIssues = {
  __typename: 'SDKIssues',
  security_finding: SecurityFinding,
  target_ids?: ?Array<$ElementType<Scalars, 'String'>>,
};

export type SdkFindingResponse = {
  __typename: 'SDKFindingResponse',
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  id: $ElementType<Scalars, 'String'>,
  sdk_id: $ElementType<Scalars, 'String'>,
  mobile_app_id: $ElementType<Scalars, 'Float'>,
  template_id?: ?$ElementType<Scalars, 'String'>,
  aggregated_status: TargetStatus,
  aggregated_status_date: $ElementType<Scalars, 'Date'>,
  targets?: ?Array<SdkFindingTarget>,
  title?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  logo_url?: ?$ElementType<Scalars, 'String'>,
  category: $ElementType<Scalars, 'String'>,
  url?: ?$ElementType<Scalars, 'String'>,
  sdk_issues?: ?Array<SdkIssues>,
  associated_domains?: ?Array<$ElementType<Scalars, 'String'>>,
};

export type SdkFindingCollectionResponse = {
  __typename: 'SDKFindingCollectionResponse',
  pagination_information: PaginationInformation,
  sdk_findings?: ?Array<SdkFindingResponse>,
};

export type SdkLicenseInformationResponse = {
  __typename: 'SDKLicenseInformationResponse',
  license: $ElementType<Scalars, 'String'>,
  detail: $ElementType<Scalars, 'String'>,
};

export type SdkResponse = {
  __typename: 'SDKResponse',
  id: $ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  category: $ElementType<Scalars, 'String'>,
  logo_url?: ?$ElementType<Scalars, 'String'>,
  url?: ?$ElementType<Scalars, 'String'>,
  sdk_finding_ids?: ?Array<$ElementType<Scalars, 'String'>>,
  related_open_security_finding_count: $ElementType<Scalars, 'Float'>,
  related_open_security_finding_beyond_subscription_level_count: $ElementType<Scalars, 'Float'>,
  mobile_app_ids_curently_with_this_sdk?: ?Array<$ElementType<Scalars, 'Float'>>,
  license_information?: ?Array<SdkLicenseInformationResponse>,
};

export type SdkCollectionResponse = {
  __typename: 'SDKCollectionResponse',
  pagination_information: PaginationInformation,
  sdks?: ?Array<SdkResponse>,
  sdk_findings?: ?Array<SdkFindingResponse>,
};

export type SdkAppFindingResponse = {
  __typename: 'SDKAppFindingResponse',
  id: $ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
};

export type SdkAppResponse = {
  __typename: 'SDKAppResponse',
  id: $ElementType<Scalars, 'ID'>,
  bundle_id: $ElementType<Scalars, 'String'>,
  date_added?: ?$ElementType<Scalars, 'Date'>,
  has_data_firewall_enabled: $ElementType<Scalars, 'Boolean'>,
  name: $ElementType<Scalars, 'String'>,
  platform: $ElementType<Scalars, 'String'>,
  open_findings_count: $ElementType<Scalars, 'Float'>,
  platform_icon_url?: ?$ElementType<Scalars, 'String'>,
  top_three_findings?: ?Array<SdkAppFindingResponse>,
};

export type SdkMobileAppsResponse = {
  __typename: 'SDKMobileAppsResponse',
  mobile_apps?: ?Array<SdkAppResponse>,
  pagination_information: PaginationInformation,
};

export type MobilePolicyRule = {
  __typename: 'MobilePolicyRule',
  id: $ElementType<Scalars, 'ID'>,
  relevance: PolicyRuleTypeRelevance,
  title: $ElementType<Scalars, 'String'>,
  wont_fix_violations_count: $ElementType<Scalars, 'Float'>,
  resolved_violations_count: $ElementType<Scalars, 'Float'>,
  open_violations_count: $ElementType<Scalars, 'Float'>,
};

export type MobilePolicyRuleStatsResponse = {
  __typename: 'MobilePolicyRuleStatsResponse',
  pagination_information: PaginationInformation,
  mobile_policy_rules?: ?Array<MobilePolicyRule>,
};

export type BlocklistedIpAddress = {
  __typename: 'BlocklistedIPAddress',
  ip_address: $ElementType<Scalars, 'String'>,
  blacklist?: ?$ElementType<Scalars, 'String'>,
  reason?: ?$ElementType<Scalars, 'String'>,
};

export type OpenScanMobileAppInsights = {
  __typename: 'OpenScanMobileAppInsights',
  sdk_count: $ElementType<Scalars, 'Float'>,
  communicates_with_hostnames?: ?Array<?$ElementType<Scalars, 'String'>>,
  communicates_with_ip_addresses?: ?Array<?$ElementType<Scalars, 'String'>>,
  communicates_with_countries?: ?Array<?$ElementType<Scalars, 'String'>>,
  communicates_with_blacklisted_ip_addresses?: ?Array<?BlocklistedIpAddress>,
  ios_base_sdk?: ?$ElementType<Scalars, 'String'>,
  ios_minimum_sdk?: ?$ElementType<Scalars, 'String'>,
  ios_permissions?: ?Array<?$ElementType<Scalars, 'String'>>,
  ios_app_extensions?: ?Array<?$ElementType<Scalars, 'String'>>,
  ios_cf_bundle_version?: ?$ElementType<Scalars, 'String'>,
  ios_supports_apple_watch: $ElementType<Scalars, 'Boolean'>,
  ios_supports_face_id: $ElementType<Scalars, 'Boolean'>,
  ios_supports_imessage: $ElementType<Scalars, 'Boolean'>,
  android_permissions?: ?Array<?$ElementType<Scalars, 'String'>>,
  android_minimum_sdk_version?: ?$ElementType<Scalars, 'String'>,
  android_target_sdk_version?: ?$ElementType<Scalars, 'String'>,
};

export type OpenScanMobileAppSecurityMetrics = {
  __typename: 'OpenScanMobileAppSecurityMetrics',
  open_security_finding_count: $ElementType<Scalars, 'Float'>,
  open_security_finding_security_p1_count: $ElementType<Scalars, 'Float'>,
  open_security_finding_apple_app_store_blocker_count: $ElementType<Scalars, 'Float'>,
  open_security_finding_google_play_blocker_count: $ElementType<Scalars, 'Float'>,
  open_security_finding_high_severity_count: $ElementType<Scalars, 'Float'>,
  open_security_finding_medium_severity_count: $ElementType<Scalars, 'Float'>,
  open_security_finding_low_severity_count: $ElementType<Scalars, 'Float'>,
  open_security_finding_caused_by_sdk_count: $ElementType<Scalars, 'Float'>,
  sdk_with_issue_count: $ElementType<Scalars, 'Float'>,
  closed_security_issues_count: $ElementType<Scalars, 'Float'>,
};

export type OpenScanMobileAppAppProtectionMetrics = {
  __typename: 'OpenScanMobileAppAppProtectionMetrics',
  full_protection_on_untrusted_networks: $ElementType<Scalars, 'Boolean'>,
  removes_data_from_shared_device_locations: $ElementType<Scalars, 'Boolean'>,
  protects_the_keyboard: $ElementType<Scalars, 'Boolean'>,
  all_communication_encrypted: $ElementType<Scalars, 'Boolean'>,
  supports_two_factor_authentication: $ElementType<Scalars, 'Boolean'>,
  leverages_secure_system_sdk: $ElementType<Scalars, 'Boolean'>,
  leverages_secure_browser_settings: $ElementType<Scalars, 'Boolean'>,
  leverages_secure_system_libraries: $ElementType<Scalars, 'Boolean'>,
  leverages_secure_system_settings: $ElementType<Scalars, 'Boolean'>,
  enhances_password_security: $ElementType<Scalars, 'Boolean'>,
  does_not_offload_data_to_third_parties: $ElementType<Scalars, 'Boolean'>,
  protects_data_on_screens: $ElementType<Scalars, 'Boolean'>,
  requires_android_malware_protection: $ElementType<Scalars, 'Boolean'>,
  requires_android_device_security: $ElementType<Scalars, 'Boolean'>,
  server_enforces_https: $ElementType<Scalars, 'Boolean'>,
};

export type OpenScanSdk = {
  __typename: 'OpenScanSDK',
  sdk_id: $ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  category: $ElementType<Scalars, 'String'>,
  url?: ?$ElementType<Scalars, 'String'>,
  logo_url?: ?$ElementType<Scalars, 'String'>,
};

export type OpenScanApp = {
  __typename: 'OpenScanApp',
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  platform: MobileAppPlatform,
  bundle_id: $ElementType<Scalars, 'String'>,
  store_url?: ?$ElementType<Scalars, 'String'>,
  category?: ?$ElementType<Scalars, 'String'>,
  last_scanned_version?: ?$ElementType<Scalars, 'Date'>,
  last_scanned_date?: ?$ElementType<Scalars, 'Date'>,
  scan_count: $ElementType<Scalars, 'Float'>,
  has_results: $ElementType<Scalars, 'Boolean'>,
  watchlist_groups?: ?Array<?OpenScanFavoriteAppGroup>,
  security_metrics?: ?OpenScanMobileAppSecurityMetrics,
  insights: OpenScanMobileAppInsights,
  app_protection_score: $ElementType<Scalars, 'Float'>,
  max_app_protection_score: $ElementType<Scalars, 'Float'>,
  app_protection_score_ratio: $ElementType<Scalars, 'Float'>,
  app_protection_percentile?: ?$ElementType<Scalars, 'Float'>,
  app_protection_metrics: OpenScanMobileAppAppProtectionMetrics,
  app_description?: ?$ElementType<Scalars, 'String'>,
  app_price_in_cents?: ?$ElementType<Scalars, 'Float'>,
  has_in_app_purchases?: ?$ElementType<Scalars, 'Boolean'>,
  publisher_name?: ?$ElementType<Scalars, 'String'>,
  publisher_website?: ?$ElementType<Scalars, 'String'>,
  initial_release_date?: ?$ElementType<Scalars, 'Date'>,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  screenshot_urls?: ?Array<?$ElementType<Scalars, 'String'>>,
  ranking?: ?$ElementType<Scalars, 'Float'>,
  publisher_can_be_contacted_automatically: $ElementType<Scalars, 'Boolean'>,
  inappropriate_for_the_enterprise: $ElementType<Scalars, 'Boolean'>,
  sdks?: ?Array<OpenScanSdk>,
  favorite: $ElementType<Scalars, 'Boolean'>,
};

export type OpenScanSecurityFinding = {
  __typename: 'OpenScanSecurityFinding',
  openscan_app_id: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  description_intro?: ?$ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  category?: ?$ElementType<Scalars, 'String'>,
  severity: FindingSeverity,
  exploitability?: ?$ElementType<Scalars, 'String'>,
  compliance_policy_references?: ?Array<CompliancePolicyReference>,
  importance_tags?: ?Array<$ElementType<Scalars, 'String'>>,
  recommendation: $ElementType<Scalars, 'String'>,
  screenshot_url?: ?$ElementType<Scalars, 'String'>,
  secure_code?: ?$ElementType<Scalars, 'String'>,
};

export type OpenScanSecurityFindingResponse = {
  __typename: 'OpenScanSecurityFindingResponse',
  openscan_security_findings?: ?Array<OpenScanSecurityFinding>,
  pagination_information: PaginationInformation,
};

export type SecurityFindingIncludedMobileAppSummary = {
  __typename: 'SecurityFindingIncludedMobileAppSummary',
  id?: ?$ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  platform: MobileAppPlatform,
  release_type?: ?$ElementType<Scalars, 'String'>,
  bundle_id: $ElementType<Scalars, 'String'>,
  app_version?: ?$ElementType<Scalars, 'String'>,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  store_url?: ?$ElementType<Scalars, 'String'>,
};

export type SecurityFinding = {
  __typename: 'SecurityFinding',
  affecting_mediawatch_article_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
  aggregated_status: TargetStatus,
  aggregated_status_date: $ElementType<Scalars, 'Date'>,
  app_protection_score?: ?$ElementType<Scalars, 'String'>,
  category?: ?$ElementType<Scalars, 'String'>,
  compliance_policy_references?: ?Array<CompliancePolicyReference>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  description: $ElementType<Scalars, 'String'>,
  description_intro?: ?$ElementType<Scalars, 'String'>,
  exploitability?: ?$ElementType<Scalars, 'String'>,
  finding_type?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  importance_tags?: ?Array<$ElementType<Scalars, 'String'>>,
  is_permanently_closed?: ?$ElementType<Scalars, 'Boolean'>,
  issue_type_id: $ElementType<Scalars, 'String'>,
  mobile_app_id: $ElementType<Scalars, 'ID'>,
  notes?: ?Array<Note>,
  priority?: ?FindingPriority,
  recommendation?: ?$ElementType<Scalars, 'String'>,
  severity: FindingSeverity,
  targets: Array<Target>,
  title: $ElementType<Scalars, 'String'>,
  secure_code?: ?$ElementType<Scalars, 'String'>,
  screenshot_url?: ?$ElementType<Scalars, 'String'>,
  reproduction_steps?: ?$ElementType<Scalars, 'String'>,
  template_id?: ?$ElementType<Scalars, 'String'>,
  included_mobile_app_summary?: ?SecurityFindingIncludedMobileAppSummary,
};

export type Session = {
  __typename: 'Session',
  session_id: $ElementType<Scalars, 'ID'>,
  session_expiration: $ElementType<Scalars, 'Date'>,
};

export type TargetAdditionalContext = {
  __typename: 'TargetAdditionalContext',
  body: $ElementType<Scalars, 'String'>,
  type: TargetAdditionalContextType,
};

export type Target = {
  __typename: 'Target',
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  external_bug_tracker_url?: ?$ElementType<Scalars, 'String'>,
  external_id?: ?$ElementType<Scalars, 'String'>,
  formatted_text: $ElementType<Scalars, 'String'>,
  additional_contexts?: ?Array<TargetAdditionalContext>,
  id: $ElementType<Scalars, 'ID'>,
  security_finding_id: $ElementType<Scalars, 'ID'>,
  statuses: Array<TargetStatusWithDate>,
  raw_text?: ?$ElementType<Scalars, 'String'>,
};

export type MediawatchArticle = {
  __typename: 'MediawatchArticle',
  id: $ElementType<Scalars, 'ID'>,
  description: $ElementType<Scalars, 'String'>,
  published_time: $ElementType<Scalars, 'Date'>,
  site_name: $ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
  url: $ElementType<Scalars, 'String'>,
};

export type MediawatchArticlesResponse = {
  __typename: 'MediawatchArticlesResponse',
  mediawatch_articles?: ?Array<MediawatchArticle>,
  pagination_information: PaginationInformation,
};

export type SecretString = {
  __typename: 'SecretString',
  id: $ElementType<Scalars, 'ID'>,
  added_by: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  value: $ElementType<Scalars, 'String'>,
  exclude_from_results: $ElementType<Scalars, 'Boolean'>,
};

export type SupportRequest = {
  __typename: 'SupportRequest',
  subject: $ElementType<Scalars, 'String'>,
  body: $ElementType<Scalars, 'String'>,
};

export type AnalyzedApp = {
  __typename: 'AnalyzedApp',
  mobile_app_id: $ElementType<Scalars, 'ID'>,
  scan_status: MobileAppAnalysisStatus,
};

export type AnalysisRequest = {
  __typename: 'AnalysisRequest',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  feature?: ?MobileAppAnalysisFeatureEnum,
  progress: $ElementType<Scalars, 'Float'>,
  requested_by: $ElementType<Scalars, 'String'>,
  status?: ?MobileAppAnalysisStatus,
  analyzed_apps?: ?Array<AnalyzedApp>,
};

export type AdministrativeAccount = {
  __typename: 'AdministrativeAccount',
  id: $ElementType<Scalars, 'ID'>,
  logged_in: $ElementType<Scalars, 'Boolean'>,
  name: $ElementType<Scalars, 'String'>,
};

export type VendorIncludedOverviewStats = {
  __typename: 'VendorIncludedOverviewStats',
  mobile_apps_count: $ElementType<Scalars, 'String'>,
  web_apps_count: $ElementType<Scalars, 'String'>,
  combined_apis_count: $ElementType<Scalars, 'String'>,
  cloud_resources_count: $ElementType<Scalars, 'String'>,
  proactive_protection_score_percent: $ElementType<Scalars, 'String'>,
};

export type VendorIncludedContactEmailDetails = {
  __typename: 'VendorIncludedContactEmailDetails',
  email?: ?$ElementType<Scalars, 'String'>,
  is_validated?: ?$ElementType<Scalars, 'Boolean'>,
};

export type Vendor = {
  __typename: 'Vendor',
  access_to_security_issues_granted: $ElementType<Scalars, 'Boolean'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  deletion_requested?: ?$ElementType<Scalars, 'Boolean'>,
  id: $ElementType<Scalars, 'ID'>,
  last_login_date?: ?$ElementType<Scalars, 'Date'>,
  logged_in: $ElementType<Scalars, 'Boolean'>,
  name: $ElementType<Scalars, 'String'>,
  scans_disabled_date?: ?$ElementType<Scalars, 'Date'>,
  scans_enabled: $ElementType<Scalars, 'Boolean'>,
  setup_completion_date?: ?$ElementType<Scalars, 'Date'>,
  setup_needed: $ElementType<Scalars, 'Boolean'>,
  contact_emails?: ?Array<$ElementType<Scalars, 'String'>>,
  description?: ?$ElementType<Scalars, 'String'>,
  industry?: ?$ElementType<Scalars, 'String'>,
  size?: ?$ElementType<Scalars, 'String'>,
  website_url?: ?$ElementType<Scalars, 'String'>,
  meta_customer_name?: ?$ElementType<Scalars, 'String'>,
  included_overview_stats?: ?VendorIncludedOverviewStats,
  included_contact_email_details?: ?Array<?VendorIncludedContactEmailDetails>,
};

/** ENDPOINTS */
export type MobileAppsListResponse = {
  __typename: 'MobileAppsListResponse',
  pagination_information: PaginationInformation,
  mobile_apps?: ?Array<MobileApp>,
};

export type MobileAppInsightsByAppIdResponseCommunicationInsights = {
  __typename: 'MobileAppInsightsByAppIdResponseCommunicationInsights',
  communicates_with_countries?: ?Array<$ElementType<Scalars, 'String'>>,
  communicates_with_first_party_origins?: ?Array<$ElementType<Scalars, 'String'>>,
  communicates_with_third_party_origins?: ?Array<$ElementType<Scalars, 'String'>>,
  communicates_with_hostnames?: ?Array<$ElementType<Scalars, 'String'>>,
  communicates_with_ip_addresses?: ?Array<$ElementType<Scalars, 'String'>>,
  communicates_with_blacklisted_ip_addresses?: ?Array<$ElementType<Scalars, 'String'>>,
};

export type MobileAppInsightsByAppIdResponseCommunicationIosInsights = {
  __typename: 'MobileAppInsightsByAppIdResponseCommunicationIosInsights',
  ios_accessed_healthkit: $ElementType<Scalars, 'Boolean'>,
  ios_base_sdk: $ElementType<Scalars, 'String'>,
  ios_cf_bundle_version: $ElementType<Scalars, 'String'>,
  ios_minimum_sdk: $ElementType<Scalars, 'String'>,
  ios_permissions?: ?Array<$ElementType<Scalars, 'String'>>,
  ios_supports_apple_watch: $ElementType<Scalars, 'Boolean'>,
  ios_supports_face_id: $ElementType<Scalars, 'Boolean'>,
  ios_supports_imessage: $ElementType<Scalars, 'Boolean'>,
};

export type MobileAppInsightsByAppIdResponse = {
  __typename: 'MobileAppInsightsByAppIdResponse',
  communicates_with_countries?: ?Array<$ElementType<Scalars, 'String'>>,
  communicates_with_first_party_origins?: ?Array<$ElementType<Scalars, 'String'>>,
  communicates_with_third_party_origins?: ?Array<$ElementType<Scalars, 'String'>>,
  communicates_with_ip_addresses?: ?Array<$ElementType<Scalars, 'String'>>,
  communication_insights?: ?MobileAppInsightsByAppIdResponseCommunicationInsights,
  ios_accessed_healthkit: $ElementType<Scalars, 'Boolean'>,
  ios_base_sdk: $ElementType<Scalars, 'String'>,
  ios_cf_bundle_version: $ElementType<Scalars, 'String'>,
  ios_insights?: ?MobileAppInsightsByAppIdResponseCommunicationIosInsights,
  ios_minimum_sdk: $ElementType<Scalars, 'String'>,
  ios_permissions?: ?Array<$ElementType<Scalars, 'String'>>,
  ios_supports_apple_watch: $ElementType<Scalars, 'Boolean'>,
  ios_supports_face_id: $ElementType<Scalars, 'Boolean'>,
  ios_supports_imessage: $ElementType<Scalars, 'Boolean'>,
  mobile_app_id: $ElementType<Scalars, 'String'>,
};

export type InsightFindingTarget = {
  __typename: 'InsightFindingTarget',
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  external_bug_tracker_url?: ?$ElementType<Scalars, 'String'>,
  external_id?: ?$ElementType<Scalars, 'String'>,
  formatted_text: $ElementType<Scalars, 'String'>,
  additional_contexts?: ?Array<TargetAdditionalContext>,
  id: $ElementType<Scalars, 'ID'>,
  statuses: Array<TargetStatusWithDate>,
  raw_text?: ?$ElementType<Scalars, 'String'>,
};

export type InsightFinding = {
  __typename: 'InsightFinding',
  aggregated_status: TargetStatus,
  aggregated_status_date: $ElementType<Scalars, 'Date'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  description: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  insight_type_id?: ?$ElementType<Scalars, 'String'>,
  mobile_app_id?: ?$ElementType<Scalars, 'String'>,
  targets: Array<InsightFindingTarget>,
  title?: ?$ElementType<Scalars, 'String'>,
};

export type MobileAppInsightFindingResponse = {
  __typename: 'MobileAppInsightFindingResponse',
  pagination_information: PaginationInformation,
  insight_findings?: ?Array<InsightFinding>,
};

export type MobileAppInsightsResponse = {
  __typename: 'MobileAppInsightsResponse',
  pagination_information: PaginationInformation,
  mobile_app_insights?: ?Array<MobileAppInsights>,
};

export type UsersListResponse = {
  __typename: 'UsersListResponse',
  pagination_information: PaginationInformation,
  users: Array<User>,
};

export type SecurityFindingsSearchResponse = {
  __typename: 'SecurityFindingsSearchResponse',
  pagination_information: PaginationInformation,
  security_findings?: ?Array<SecurityFinding>,
};

export type SecurityFindingNote = {
  text: $ElementType<Scalars, 'String'>,
  is_internal_comment: $ElementType<Scalars, 'Boolean'>,
  is_question_for_datatheorem: $ElementType<Scalars, 'Boolean'>,
};

export type SecurityFindingPatchParams = {
  is_permanently_closed: $ElementType<Scalars, 'Boolean'>,
};

export type SecurityFindingPatchPriorityParams = {
  priority: FindingPriority,
};

export type ActivityLogEventListResponse = {
  __typename: 'ActivityLogEventListResponse',
  pagination_information: PaginationInformation,
  activity_log_events?: ?Array<?ActivityLogEvent>,
};

export type OpenScanContactPublisherRequest = {
  __typename: 'OpenScanContactPublisherRequest',
  additional_recipients?: ?Array<$ElementType<Scalars, 'String'>>,
  mobile_app_id: $ElementType<Scalars, 'Float'>,
};

export type OpenScanContactPublisherResponse = {
  __typename: 'OpenScanContactPublisherResponse',
  additional_recipients?: ?Array<$ElementType<Scalars, 'String'>>,
  mobile_app_id: $ElementType<Scalars, 'Float'>,
};

export type OpenScanSearchResponse = {
  __typename: 'OpenScanSearchResponse',
  id: $ElementType<Scalars, 'ID'>,
  app_protection_score: $ElementType<Scalars, 'Float'>,
  app_protection_score_ratio: $ElementType<Scalars, 'Float'>,
  has_results: $ElementType<Scalars, 'Boolean'>,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  platform: MobileAppPlatform,
  store_url?: ?$ElementType<Scalars, 'String'>,
};

export type OpenScanMobileAppSearchResponse = {
  __typename: 'OpenScanMobileAppSearchResponse',
  openscan_mobile_app_search_results?: ?Array<OpenScanSearchResponse>,
};

export type OpenScanMobileAppsResponse = {
  __typename: 'OpenScanMobileAppsResponse',
  pagination_information: PaginationInformation,
  openscan_mobile_apps?: ?Array<OpenScanApp>,
};

export type SecretStringsListResponse = {
  __typename: 'SecretStringsListResponse',
  pagination_information: PaginationInformation,
  secret_strings?: ?Array<SecretString>,
};

export type MobileCoverageStatsResponseItemCard = {
  __typename: 'MobileCoverageStatsResponseItemCard',
  issue_count?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  status: $ElementType<Scalars, 'String'>,
};

export type MobileCoverageStatsResponseItem = {
  __typename: 'MobileCoverageStatsResponseItem',
  cards: Array<MobileCoverageStatsResponseItemCard>,
  title: $ElementType<Scalars, 'String'>,
};

export type MobileCoverageStatsResponse = {
  __typename: 'MobileCoverageStatsResponse',
  card_groups: Array<MobileCoverageStatsResponseItem>,
};

export type MobileChangesStatsResponseItem = {
  __typename: 'MobileChangesStatsResponseItem',
  event_count: $ElementType<Scalars, 'String'>,
  event_type: $ElementType<Scalars, 'String'>,
};

export type MobileChangesStatsResponse = {
  __typename: 'MobileChangesStatsResponse',
  mobile_changes_stats: Array<MobileChangesStatsResponseItem>,
};

export type MobileApplicationsStatsResponse = {
  __typename: 'MobileApplicationsStatsResponse',
  android_apps_count: $ElementType<Scalars, 'Float'>,
  android_preprod_apps_count: $ElementType<Scalars, 'Float'>,
  ios_apps_count: $ElementType<Scalars, 'Float'>,
  ios_preprod_apps_count: $ElementType<Scalars, 'Float'>,
  enterprise_apps_count: $ElementType<Scalars, 'Float'>,
  mobile_apis_count: $ElementType<Scalars, 'Float'>,
  total_open_policy_violations_count: $ElementType<Scalars, 'Float'>,
  total_open_toolkit_policy_violations_count: $ElementType<Scalars, 'Float'>,
  total_open_non_toolkit_policy_violations_count: $ElementType<Scalars, 'Float'>,
  android_icon_url: $ElementType<Scalars, 'String'>,
  ios_icon_url: $ElementType<Scalars, 'String'>,
  enterprise_icon_url: $ElementType<Scalars, 'String'>,
  active_installs_count: $ElementType<Scalars, 'Float'>,
  attacks_detected_count: $ElementType<Scalars, 'Float'>,
  attacks_blocked_count: $ElementType<Scalars, 'Float'>,
  total_apps_count: $ElementType<Scalars, 'Float'>,
  apps_with_active_protection_count: $ElementType<Scalars, 'Float'>,
};

export type MobileAppChangesResponseStats = {
  __typename: 'MobileAppChangesResponseStats',
  changes_with_issues_count: $ElementType<Scalars, 'String'>,
  last_30_days_changes_count: $ElementType<Scalars, 'String'>,
  last_30_days_changes_with_issues_count: $ElementType<Scalars, 'String'>,
  last_7_days_changes_count: $ElementType<Scalars, 'String'>,
  last_year_changes_count: $ElementType<Scalars, 'String'>,
};

export type MobileAppChangesResponseItemMobileAppTarget = {
  __typename: 'MobileAppChangesResponseItemMobileAppTarget',
  bundle_id: $ElementType<Scalars, 'String'>,
  icon_url: $ElementType<Scalars, 'String'>,
  mobile_app_id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  platform_url: $ElementType<Scalars, 'String'>,
  version: $ElementType<Scalars, 'String'>,
};

export type MobileAppChangesResponseItem = {
  __typename: 'MobileAppChangesResponseItem',
  date_created: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  discovered_via_icon_url: $ElementType<Scalars, 'String'>,
  icon_url: $ElementType<Scalars, 'String'>,
  is_compliant_to_security_policy: $ElementType<Scalars, 'Boolean'>,
  is_internet_accessible: $ElementType<Scalars, 'Boolean'>,
  issues_count: $ElementType<Scalars, 'String'>,
  mobile_app_target: MobileAppChangesResponseItemMobileAppTarget,
  name: $ElementType<Scalars, 'String'>,
};

export type MobileAppChangesResponse = {
  __typename: 'MobileAppChangesResponse',
  pagination_information: PaginationInformation,
  included_mobile_changes_stats: MobileAppChangesResponseStats,
  mobile_changes: Array<MobileAppChangesResponseItem>,
};

export type MobileAppGetEventsDetailsResponseMobileApp = {
  __typename: 'MobileAppGetEventsDetailsResponseMobileApp',
  bundle_id: $ElementType<Scalars, 'String'>,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  platform: $ElementType<Scalars, 'String'>,
  version: $ElementType<Scalars, 'String'>,
};

export type MobileAppGetEventsDetailsResponse = {
  __typename: 'MobileAppGetEventsDetailsResponse',
  app_version: $ElementType<Scalars, 'String'>,
  context_as_markdown?: ?$ElementType<Scalars, 'String'>,
  event_type_name: $ElementType<Scalars, 'String'>,
  geoip_country: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  ip_address: $ElementType<Scalars, 'String'>,
  received_at: $ElementType<Scalars, 'String'>,
  sdk_version: $ElementType<Scalars, 'String'>,
  source_country_name?: ?$ElementType<Scalars, 'String'>,
  mobile_app: MobileAppGetEventsDetailsResponseMobileApp,
};

export type MobileAppGetEventsResponseItem = {
  __typename: 'MobileAppGetEventsResponseItem',
  app_version: $ElementType<Scalars, 'String'>,
  event_type_name: $ElementType<Scalars, 'String'>,
  geoip_country: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  ip_address: $ElementType<Scalars, 'String'>,
  received_at: $ElementType<Scalars, 'String'>,
  source_country_name?: ?$ElementType<Scalars, 'String'>,
};

export type MobileAppGetEventsResponse = {
  __typename: 'MobileAppGetEventsResponse',
  events: Array<MobileAppGetEventsResponseItem>,
  pagination_information: PaginationInformation,
};

export type MobileAppAnalysisScheduleResponse = {
  __typename: 'MobileAppAnalysisScheduleResponse',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  time_when_to_scan: $ElementType<Scalars, 'String'>,
  weekdays_when_to_scan?: ?Array<Weekday>,
};

export type MobileAppAnalysisRequestListResponse = {
  __typename: 'MobileAppAnalysisRequestListResponse',
  pagination_information: PaginationInformation,
  mobile_app_analysis_requests?: ?Array<AnalysisRequest>,
};

export type VendorManagementVendorsListResponse = {
  __typename: 'VendorManagementVendorsListResponse',
  pagination_information: PaginationInformation,
  administrative_account: AdministrativeAccount,
  vendors?: ?Array<Vendor>,
};

export type VendorManagementMobileStatsResponse = {
  __typename: 'VendorManagementMobileStatsResponse',
  mobile_apps_count: $ElementType<Scalars, 'Float'>,
  mobile_apps_count_30_day_delta: $ElementType<Scalars, 'Float'>,
};

export type VendorVanagementVendorByIdResponse = {
  __typename: 'vendorVanagementVendorByIdResponse',
  access_to_security_issues_granted: $ElementType<Scalars, 'Boolean'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  deletion_requested?: ?$ElementType<Scalars, 'Boolean'>,
  id: $ElementType<Scalars, 'ID'>,
  last_login_date?: ?$ElementType<Scalars, 'Date'>,
  logged_in: $ElementType<Scalars, 'Boolean'>,
  name: $ElementType<Scalars, 'String'>,
  scans_disabled_date?: ?$ElementType<Scalars, 'Date'>,
  scans_enabled: $ElementType<Scalars, 'Boolean'>,
  setup_completion_date?: ?$ElementType<Scalars, 'Date'>,
  setup_needed: $ElementType<Scalars, 'Boolean'>,
  contact_emails?: ?Array<$ElementType<Scalars, 'String'>>,
  description?: ?$ElementType<Scalars, 'String'>,
  industry?: ?$ElementType<Scalars, 'String'>,
  size?: ?$ElementType<Scalars, 'String'>,
  website_url?: ?$ElementType<Scalars, 'String'>,
  meta_customer_name?: ?$ElementType<Scalars, 'String'>,
  included_overview_stats?: ?VendorIncludedOverviewStats,
  included_contact_email_details?: ?Array<?VendorIncludedContactEmailDetails>,
};

export type VendorsMobileSdk = {
  __typename: 'VendorsMobileSdk',
  id?: ?$ElementType<Scalars, 'ID'>,
  status?: ?VendorMobileSdkStatusEnum,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  date_created?: ?$ElementType<Scalars, 'Date'>,
  name?: ?$ElementType<Scalars, 'String'>,
  sevenhell_sdk_uuid?: ?$ElementType<Scalars, 'String'>,
};

export type VendorsMobileSdkResponse = {
  __typename: 'VendorsMobileSdkResponse',
  mobile_sdks?: ?Array<?VendorsMobileSdk>,
  pagination_information: PaginationInformation,
};

export type AlertsIntegrationsListEventValuesItem = {
  __typename: 'AlertsIntegrationsListEventValuesItem',
  name?: ?$ElementType<Scalars, 'String'>,
  value?: ?$ElementType<Scalars, 'String'>,
  value_category?: ?$ElementType<Scalars, 'String'>,
};

export type AlertsIntegrationsListEventValuesResponse = {
  __typename: 'AlertsIntegrationsListEventValuesResponse',
  alerts_integration_event_values?: ?Array<?AlertsIntegrationsListEventValuesItem>,
};

export type OnboardingDataResponse = {
  __typename: 'OnboardingDataResponse',
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  date_discount_ending?: ?$ElementType<Scalars, 'Date'>,
  has_core_driver_compliance: $ElementType<Scalars, 'Boolean'>,
  has_core_driver_incident_response: $ElementType<Scalars, 'Boolean'>,
  has_core_driver_security: $ElementType<Scalars, 'Boolean'>,
  allow_onboarding_of?: ?Array<?OnboardingProductsEnum>,
  required_steps?: ?Array<?OnboardingStepsEnum>,
  completed_steps?: ?Array<?OnboardingStepsEnum>,
};

export type OnboardApiCredentialsPostResponse = {
  __typename: 'OnboardApiCredentialsPostResponse',
  _?: ?$ElementType<Scalars, 'String'>,
};

export type GetApiKeysResponse = {
  __typename: 'GetApiKeysResponse',
  upload_api_key: $ElementType<Scalars, 'String'>,
  pagination_information: PaginationInformation,
};

export type UploadApiKeyResponse = {
  __typename: 'UploadApiKeyResponse',
  upload_api_key?: ?$ElementType<Scalars, 'String'>,
};

export type MobileAppScanTestCredential = {
  __typename: 'MobileAppScanTestCredential',
  mobile_app_id: $ElementType<Scalars, 'String'>,
  username?: ?$ElementType<Scalars, 'String'>,
  comments?: ?$ElementType<Scalars, 'String'>,
};

export type CustomersTestCredential = {
  __typename: 'CustomersTestCredential',
  id: $ElementType<Scalars, 'String'>,
  username: $ElementType<Scalars, 'String'>,
  comments?: ?$ElementType<Scalars, 'String'>,
};

export type CustomersTestCredentialsResponse = {
  __typename: 'CustomersTestCredentialsResponse',
  items?: ?Array<?CustomersTestCredential>,
};

export type SamlSpMetadata = {
  __typename: 'SamlSpMetadata',
  assertion_consumer_service_url: $ElementType<Scalars, 'String'>,
  entity_id: $ElementType<Scalars, 'String'>,
  metadata_file_url: $ElementType<Scalars, 'String'>,
};

export type SamlGet = {
  __typename: 'SamlGet',
  has_saml_idp?: ?$ElementType<Scalars, 'Boolean'>,
  saml_auto_provisioning_of_users_enabled?: ?$ElementType<Scalars, 'Boolean'>,
  saml_sp_metadata?: ?SamlSpMetadata,
};

export type JiraIntegrationConfigStaticField = {
  __typename: 'JiraIntegrationConfigStaticField',
  field_id?: ?$ElementType<Scalars, 'String'>,
  field_value?: ?$ElementType<Scalars, 'String'>,
};

export type JiraIntegrationConfigDynamicField = {
  __typename: 'JiraIntegrationConfigDynamicField',
  field_id?: ?$ElementType<Scalars, 'String'>,
  value?: ?$ElementType<Scalars, 'String'>,
};

export type JiraIntegrationConfigSeverityFieldConfigMessage = {
  __typename: 'JiraIntegrationConfigSeverityFieldConfigMessage',
  field_id?: ?$ElementType<Scalars, 'String'>,
  high_severity_value_id?: ?$ElementType<Scalars, 'String'>,
  low_severity_value_id?: ?$ElementType<Scalars, 'String'>,
  medium_severity_value_id?: ?$ElementType<Scalars, 'String'>,
};

export type JiraIntegrationConfig = {
  __typename: 'JiraIntegrationConfig',
  base_url?: ?$ElementType<Scalars, 'String'>,
  date_created?: ?$ElementType<Scalars, 'Date'>,
  date_updated?: ?$ElementType<Scalars, 'Date'>,
  export_filter?: ?JiraExportFilterEnum,
  export_pre_prod?: ?$ElementType<Scalars, 'Boolean'>,
  export_prod?: ?$ElementType<Scalars, 'Boolean'>,
  id?: ?$ElementType<Scalars, 'String'>,
  is_global?: ?$ElementType<Scalars, 'Boolean'>,
  minimum_severity_filter?: ?$ElementType<Scalars, 'String'>,
  project_key_or_id?: ?$ElementType<Scalars, 'String'>,
  type_of_issue_name?: ?$ElementType<Scalars, 'String'>,
  username?: ?$ElementType<Scalars, 'String'>,
  severity_field_config?: ?JiraIntegrationConfigSeverityFieldConfigMessage,
  static_fields?: ?Array<?JiraIntegrationConfigStaticField>,
  dynamic_fields?: ?Array<?JiraIntegrationConfigDynamicField>,
};

export type JiraIntegrationConfigListResponse = {
  __typename: 'JiraIntegrationConfigListResponse',
  jira_integrations?: ?Array<?JiraIntegrationConfig>,
};

export type AppSecureAccessProperties = {
  __typename: 'AppSecureAccessProperties',
  can_access_all_apps: $ElementType<Scalars, 'Boolean'>,
  can_access_app_ids?: ?Array<$ElementType<Scalars, 'String'>>,
  can_access_scope?: ?ScopedAccessTokenTypeEnum,
  can_access_app_secure_security_finding_id?: ?$ElementType<Scalars, 'String'>,
};

export type AppSearchAccessProperties = {
  __typename: 'AppSearchAccessProperties',
  can_access_all_apps: $ElementType<Scalars, 'Boolean'>,
  can_access_app_ids?: ?Array<$ElementType<Scalars, 'String'>>,
  can_access_scope?: ?ScopedAccessTokenTypeEnum,
};

export type ScopedAccessTokenResponse = {
  __typename: 'ScopedAccessTokenResponse',
  access_granted_by: $ElementType<Scalars, 'String'>,
  app_secure_access_properties?: ?AppSecureAccessProperties,
  app_search_access_properties?: ?AppSearchAccessProperties,
  access_token: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_expires: $ElementType<Scalars, 'Date'>,
  date_updated?: ?$ElementType<Scalars, 'Date'>,
  id: $ElementType<Scalars, 'String'>,
};

export type ScopedAccessTokenListResponse = {
  __typename: 'ScopedAccessTokenListResponse',
  scoped_access_tokens?: ?Array<ScopedAccessTokenResponse>,
  pagination_information: PaginationInformation,
};

export const ApplicationLayerProtocolValues = Object.freeze({
  UNKNOWN: 'UNKNOWN', 
  HTTP: 'HTTP'
});


export type ApplicationLayerProtocol = $Values<typeof ApplicationLayerProtocolValues>;

export const AssetGroupMembershipKindValues = Object.freeze({
  ASSET_SEARCH_MATCH: 'ASSET_SEARCH_MATCH', 
  MANUAL: 'MANUAL', 
  SHADOW_ASSET_MATCH: 'SHADOW_ASSET_MATCH'
});


export type AssetGroupMembershipKind = $Values<typeof AssetGroupMembershipKindValues>;

export const CloudServiceTypeValues = Object.freeze({
  UNKNOWN: 'UNKNOWN', 
  AWS_S3: 'AWS_S3', 
  AWS_API_GATEWAY: 'AWS_API_GATEWAY', 
  AWS_LAMBDA: 'AWS_LAMBDA', 
  AWS_ROUTE53: 'AWS_ROUTE53', 
  AWS_ELASTICSEARCH: 'AWS_ELASTICSEARCH', 
  AWS_EBS: 'AWS_EBS', 
  AWS_RDS: 'AWS_RDS', 
  AWS_API_GATEWAY_MOCK: 'AWS_API_GATEWAY_MOCK', 
  AWS_API_GATEWAY_HTTP: 'AWS_API_GATEWAY_HTTP', 
  AWS_API_GATEWAY_AWS_INTEGRATION: 'AWS_API_GATEWAY_AWS_INTEGRATION', 
  AWS_API_GATEWAY_HTTP_UPSTREAM: 'AWS_API_GATEWAY_HTTP_UPSTREAM', 
  APIGEE_API_GATEWAY: 'APIGEE_API_GATEWAY', 
  APIGEE_API_GATEWAY_HOSTED_TARGET: 'APIGEE_API_GATEWAY_HOSTED_TARGET', 
  APIGEE_API_GATEWAY_HTTP: 'APIGEE_API_GATEWAY_HTTP', 
  APIGEE_API_GATEWAY_HTTP_UPSTREAM: 'APIGEE_API_GATEWAY_HTTP_UPSTREAM', 
  AZURE_API_MANAGEMENT_SERVICE: 'AZURE_API_MANAGEMENT_SERVICE', 
  AZURE_API_MANAGEMENT_SERVICE_API: 'AZURE_API_MANAGEMENT_SERVICE_API', 
  AZURE_API_MANAGEMENT_HTTP_UPSTREAM: 'AZURE_API_MANAGEMENT_HTTP_UPSTREAM', 
  AZURE_WEB_SITE: 'AZURE_WEB_SITE', 
  AZURE_DNS: 'AZURE_DNS', 
  AZURE_FUNCTION_APP: 'AZURE_FUNCTION_APP', 
  GCP_CLOUD_DNS: 'GCP_CLOUD_DNS', 
  GCP_APP_ENGINE: 'GCP_APP_ENGINE', 
  GCP_CLOUD_STORAGE: 'GCP_CLOUD_STORAGE', 
  GCP_FIREBASE_DATABASE: 'GCP_FIREBASE_DATABASE', 
  GCP_FIREBASE_STORAGE: 'GCP_FIREBASE_STORAGE', 
  GCP_FIREBASE_FUNCTIONS: 'GCP_FIREBASE_FUNCTIONS', 
  GCP_CLOUD_FUNCTIONS: 'GCP_CLOUD_FUNCTIONS'
});


export type CloudServiceType = $Values<typeof CloudServiceTypeValues>;

export const DiscoveredViaValues = Object.freeze({
  AMAZON_WEB_SERVICES: 'AMAZON_WEB_SERVICES', 
  APIGEE: 'APIGEE', 
  APIGEE_ON_PREM: 'APIGEE_ON_PREM', 
  APPLICATION_FINGERPRINTING: 'APPLICATION_FINGERPRINTING', 
  AXWAY: 'AXWAY', 
  AZURE: 'AZURE', 
  CERTIFICATE_TRANSPARENCY: 'CERTIFICATE_TRANSPARENCY', 
  GOOGLE_CLOUD_PLATFORM: 'GOOGLE_CLOUD_PLATFORM', 
  KONG: 'KONG', 
  MANUAL_IMPORT: 'MANUAL_IMPORT', 
  MOBILE_APP_SCANS: 'MOBILE_APP_SCANS', 
  MULESOFT: 'MULESOFT', 
  RESTFUL_API_DISCOVERER: 'RESTFUL_API_DISCOVERER', 
  REVERSE_IP_LOOKUP: 'REVERSE_IP_LOOKUP', 
  SAN_EXTRACTION: 'SAN_EXTRACTION', 
  WEB_APPLICATION_SCANS: 'WEB_APPLICATION_SCANS', 
  WEBSITE_CRAWLER: 'WEBSITE_CRAWLER', 
  SWAGGER_HUB: 'SWAGGER_HUB', 
  MOBILE_APP_STORE: 'MOBILE_APP_STORE', 
  MOBILE_PROTECT_USAGE: 'MOBILE_PROTECT_USAGE', 
  CLOUD_PROTECT: 'CLOUD_PROTECT', 
  KUBERNETES_CLUSTER_ANALYSIS: 'KUBERNETES_CLUSTER_ANALYSIS'
});


export type DiscoveredVia = $Values<typeof DiscoveredViaValues>;

export const HostedOnValues = Object.freeze({
  AMAZON_WEB_SERVICES: 'AMAZON_WEB_SERVICES', 
  APIGEE: 'APIGEE', 
  AXWAY: 'AXWAY', 
  AZURE: 'AZURE', 
  DETECTION_FAILED: 'DETECTION_FAILED', 
  GOOGLE_CLOUD_PLATFORM: 'GOOGLE_CLOUD_PLATFORM', 
  KONG: 'KONG', 
  MULESOFT: 'MULESOFT', 
  ON_PREMISE: 'ON_PREMISE', 
  UNKNOWN: 'UNKNOWN'
});


export type HostedOn = $Values<typeof HostedOnValues>;

export const HttpMethodValues = Object.freeze({
  GET: 'GET', 
  PUT: 'PUT', 
  POST: 'POST', 
  DELETE: 'DELETE', 
  OPTIONS: 'OPTIONS', 
  HEAD: 'HEAD', 
  PATCH: 'PATCH', 
  TRACE: 'TRACE', 
  X_HORIZON_UNDEFINED_HTTP_METHOD: 'X_HORIZON_UNDEFINED_HTTP_METHOD', 
  UNKNOWN: 'UNKNOWN'
});


export type HttpMethod = $Values<typeof HttpMethodValues>;

export const PolicyComplianceStandardValues = Object.freeze({
  OWASP: 'OWASP', 
  PCI_DSS: 'PCI_DSS', 
  NIST_800_53: 'NIST_800_53', 
  FFIEC_VC2: 'FFIEC_VC2', 
  CIS_BENCHMARK: 'CIS_BENCHMARK'
});


export type PolicyComplianceStandard = $Values<typeof PolicyComplianceStandardValues>;

export const PolicyComplianceStandardCriteriaValues = Object.freeze({
  OWASP_A1_2019: 'OWASP_A1_2019', 
  OWASP_A2_2019: 'OWASP_A2_2019', 
  OWASP_A3_2019: 'OWASP_A3_2019', 
  OWASP_A4_2019: 'OWASP_A4_2019', 
  OWASP_A5_2019: 'OWASP_A5_2019', 
  OWASP_A6_2019: 'OWASP_A6_2019', 
  OWASP_A7_2019: 'OWASP_A7_2019', 
  OWASP_A8_2019: 'OWASP_A8_2019', 
  OWASP_A9_2019: 'OWASP_A9_2019', 
  OWASP_A10_2019: 'OWASP_A10_2019', 
  OWASP_W1_2021: 'OWASP_W1_2021', 
  OWASP_W2_2021: 'OWASP_W2_2021', 
  OWASP_W3_2021: 'OWASP_W3_2021', 
  OWASP_W4_2021: 'OWASP_W4_2021', 
  OWASP_W5_2021: 'OWASP_W5_2021', 
  OWASP_W6_2021: 'OWASP_W6_2021', 
  OWASP_W7_2021: 'OWASP_W7_2021', 
  OWASP_W8_2021: 'OWASP_W8_2021', 
  OWASP_W9_2021: 'OWASP_W9_2021', 
  OWASP_W10_2021: 'OWASP_W10_2021', 
  OWASP_R1_2011: 'OWASP_R1_2011', 
  OWASP_R2_2011: 'OWASP_R2_2011', 
  OWASP_R3_2011: 'OWASP_R3_2011', 
  OWASP_R4_2011: 'OWASP_R4_2011', 
  OWASP_R5_2011: 'OWASP_R5_2011', 
  OWASP_R6_2011: 'OWASP_R6_2011', 
  OWASP_R7_2011: 'OWASP_R7_2011', 
  OWASP_R8_2011: 'OWASP_R8_2011', 
  OWASP_R9_2011: 'OWASP_R9_2011', 
  OWASP_R10_2011: 'OWASP_R10_2011', 
  PCI_DSS_v3_2_1_a1_3: 'PCI_DSS_v3_2_1_a1_3', 
  PCI_DSS_v3_2_1_a1_2_1: 'PCI_DSS_v3_2_1_a1_2_1', 
  PCI_DSS_v3_2_1_a2_3: 'PCI_DSS_v3_2_1_a2_3', 
  PCI_DSS_v3_2_1_a2_2_3: 'PCI_DSS_v3_2_1_a2_2_3', 
  PCI_DSS_v3_2_1_a3_4: 'PCI_DSS_v3_2_1_a3_4', 
  PCI_DSS_v3_2_1_a4_1: 'PCI_DSS_v3_2_1_a4_1', 
  PCI_DSS_v3_2_1_a6_1: 'PCI_DSS_v3_2_1_a6_1', 
  PCI_DSS_v3_2_1_a6_5_10: 'PCI_DSS_v3_2_1_a6_5_10', 
  PCI_DSS_v3_2_1_a6_6: 'PCI_DSS_v3_2_1_a6_6', 
  PCI_DSS_v3_2_1_a8_7: 'PCI_DSS_v3_2_1_a8_7', 
  PCI_DSS_v3_2_1_a10_1: 'PCI_DSS_v3_2_1_a10_1', 
  PCI_DSS_v3_2_1_aA_2_1: 'PCI_DSS_v3_2_1_aA_2_1', 
  NIST_800_53_AC_1: 'NIST_800_53_AC_1', 
  NIST_800_53_AC_4: 'NIST_800_53_AC_4', 
  NIST_800_53_AU_11: 'NIST_800_53_AU_11', 
  NIST_800_53_SA_2: 'NIST_800_53_SA_2', 
  NIST_800_53_SA_4: 'NIST_800_53_SA_4', 
  NIST_800_53_SC_1: 'NIST_800_53_SC_1', 
  NIST_800_53_SC_7: 'NIST_800_53_SC_7', 
  NIST_800_53_SC_12: 'NIST_800_53_SC_12', 
  NIST_800_53_SC_16: 'NIST_800_53_SC_16', 
  NIST_800_53_SI_1: 'NIST_800_53_SI_1', 
  NIST_800_53_SI_2: 'NIST_800_53_SI_2', 
  NIST_800_53_CM_2: 'NIST_800_53_CM_2', 
  NIST_800_53_CM_4: 'NIST_800_53_CM_4', 
  OWASP_M1_2014: 'OWASP_M1_2014', 
  OWASP_M2_2014: 'OWASP_M2_2014', 
  OWASP_M3_2014: 'OWASP_M3_2014', 
  OWASP_M4_2014: 'OWASP_M4_2014', 
  OWASP_M5_2014: 'OWASP_M5_2014', 
  OWASP_M6_2014: 'OWASP_M6_2014', 
  OWASP_M7_2014: 'OWASP_M7_2014', 
  OWASP_M8_2014: 'OWASP_M8_2014', 
  OWASP_M9_2014: 'OWASP_M9_2014', 
  OWASP_M10_2014: 'OWASP_M10_2014', 
  OWASP_M1_2016: 'OWASP_M1_2016', 
  OWASP_M2_2016: 'OWASP_M2_2016', 
  OWASP_M3_2016: 'OWASP_M3_2016', 
  OWASP_M4_2016: 'OWASP_M4_2016', 
  OWASP_M5_2016: 'OWASP_M5_2016', 
  OWASP_M6_2016: 'OWASP_M6_2016', 
  OWASP_M7_2016: 'OWASP_M7_2016', 
  OWASP_M8_2016: 'OWASP_M8_2016', 
  OWASP_M9_2016: 'OWASP_M9_2016', 
  OWASP_M10_2016: 'OWASP_M10_2016', 
  OWASP_MSTG_ARCH_1: 'OWASP_MSTG_ARCH_1', 
  OWASP_MSTG_ARCH_2: 'OWASP_MSTG_ARCH_2', 
  OWASP_MSTG_ARCH_3: 'OWASP_MSTG_ARCH_3', 
  OWASP_MSTG_ARCH_4: 'OWASP_MSTG_ARCH_4', 
  OWASP_MSTG_ARCH_5: 'OWASP_MSTG_ARCH_5', 
  OWASP_MSTG_ARCH_6: 'OWASP_MSTG_ARCH_6', 
  OWASP_MSTG_ARCH_7: 'OWASP_MSTG_ARCH_7', 
  OWASP_MSTG_ARCH_8: 'OWASP_MSTG_ARCH_8', 
  OWASP_MSTG_ARCH_9: 'OWASP_MSTG_ARCH_9', 
  OWASP_MSTG_ARCH_10: 'OWASP_MSTG_ARCH_10', 
  OWASP_MSTG_ARCH_11: 'OWASP_MSTG_ARCH_11', 
  OWASP_MSTG_ARCH_12: 'OWASP_MSTG_ARCH_12', 
  OWASP_MSTG_STORAGE_1: 'OWASP_MSTG_STORAGE_1', 
  OWASP_MSTG_STORAGE_2: 'OWASP_MSTG_STORAGE_2', 
  OWASP_MSTG_STORAGE_3: 'OWASP_MSTG_STORAGE_3', 
  OWASP_MSTG_STORAGE_4: 'OWASP_MSTG_STORAGE_4', 
  OWASP_MSTG_STORAGE_5: 'OWASP_MSTG_STORAGE_5', 
  OWASP_MSTG_STORAGE_6: 'OWASP_MSTG_STORAGE_6', 
  OWASP_MSTG_STORAGE_7: 'OWASP_MSTG_STORAGE_7', 
  OWASP_MSTG_STORAGE_8: 'OWASP_MSTG_STORAGE_8', 
  OWASP_MSTG_STORAGE_9: 'OWASP_MSTG_STORAGE_9', 
  OWASP_MSTG_STORAGE_10: 'OWASP_MSTG_STORAGE_10', 
  OWASP_MSTG_STORAGE_11: 'OWASP_MSTG_STORAGE_11', 
  OWASP_MSTG_STORAGE_12: 'OWASP_MSTG_STORAGE_12', 
  OWASP_MSTG_STORAGE_13: 'OWASP_MSTG_STORAGE_13', 
  OWASP_MSTG_STORAGE_14: 'OWASP_MSTG_STORAGE_14', 
  OWASP_MSTG_STORAGE_15: 'OWASP_MSTG_STORAGE_15', 
  OWASP_MSTG_CRYPTO_1: 'OWASP_MSTG_CRYPTO_1', 
  OWASP_MSTG_CRYPTO_2: 'OWASP_MSTG_CRYPTO_2', 
  OWASP_MSTG_CRYPTO_3: 'OWASP_MSTG_CRYPTO_3', 
  OWASP_MSTG_CRYPTO_4: 'OWASP_MSTG_CRYPTO_4', 
  OWASP_MSTG_CRYPTO_5: 'OWASP_MSTG_CRYPTO_5', 
  OWASP_MSTG_CRYPTO_6: 'OWASP_MSTG_CRYPTO_6', 
  OWASP_MSTG_AUTH_1: 'OWASP_MSTG_AUTH_1', 
  OWASP_MSTG_AUTH_2: 'OWASP_MSTG_AUTH_2', 
  OWASP_MSTG_AUTH_3: 'OWASP_MSTG_AUTH_3', 
  OWASP_MSTG_AUTH_4: 'OWASP_MSTG_AUTH_4', 
  OWASP_MSTG_AUTH_5: 'OWASP_MSTG_AUTH_5', 
  OWASP_MSTG_AUTH_6: 'OWASP_MSTG_AUTH_6', 
  OWASP_MSTG_AUTH_7: 'OWASP_MSTG_AUTH_7', 
  OWASP_MSTG_AUTH_8: 'OWASP_MSTG_AUTH_8', 
  OWASP_MSTG_AUTH_9: 'OWASP_MSTG_AUTH_9', 
  OWASP_MSTG_AUTH_10: 'OWASP_MSTG_AUTH_10', 
  OWASP_MSTG_AUTH_11: 'OWASP_MSTG_AUTH_11', 
  OWASP_MSTG_AUTH_12: 'OWASP_MSTG_AUTH_12', 
  OWASP_MSTG_NETWORK_1: 'OWASP_MSTG_NETWORK_1', 
  OWASP_MSTG_NETWORK_2: 'OWASP_MSTG_NETWORK_2', 
  OWASP_MSTG_NETWORK_3: 'OWASP_MSTG_NETWORK_3', 
  OWASP_MSTG_NETWORK_4: 'OWASP_MSTG_NETWORK_4', 
  OWASP_MSTG_NETWORK_5: 'OWASP_MSTG_NETWORK_5', 
  OWASP_MSTG_NETWORK_6: 'OWASP_MSTG_NETWORK_6', 
  OWASP_MSTG_PLATFORM_1: 'OWASP_MSTG_PLATFORM_1', 
  OWASP_MSTG_PLATFORM_2: 'OWASP_MSTG_PLATFORM_2', 
  OWASP_MSTG_PLATFORM_3: 'OWASP_MSTG_PLATFORM_3', 
  OWASP_MSTG_PLATFORM_4: 'OWASP_MSTG_PLATFORM_4', 
  OWASP_MSTG_PLATFORM_5: 'OWASP_MSTG_PLATFORM_5', 
  OWASP_MSTG_PLATFORM_6: 'OWASP_MSTG_PLATFORM_6', 
  OWASP_MSTG_PLATFORM_7: 'OWASP_MSTG_PLATFORM_7', 
  OWASP_MSTG_PLATFORM_8: 'OWASP_MSTG_PLATFORM_8', 
  OWASP_MSTG_PLATFORM_9: 'OWASP_MSTG_PLATFORM_9', 
  OWASP_MSTG_PLATFORM_10: 'OWASP_MSTG_PLATFORM_10', 
  OWASP_MSTG_PLATFORM_11: 'OWASP_MSTG_PLATFORM_11', 
  OWASP_MSTG_CODE_1: 'OWASP_MSTG_CODE_1', 
  OWASP_MSTG_CODE_2: 'OWASP_MSTG_CODE_2', 
  OWASP_MSTG_CODE_3: 'OWASP_MSTG_CODE_3', 
  OWASP_MSTG_CODE_4: 'OWASP_MSTG_CODE_4', 
  OWASP_MSTG_CODE_5: 'OWASP_MSTG_CODE_5', 
  OWASP_MSTG_CODE_6: 'OWASP_MSTG_CODE_6', 
  OWASP_MSTG_CODE_7: 'OWASP_MSTG_CODE_7', 
  OWASP_MSTG_CODE_8: 'OWASP_MSTG_CODE_8', 
  OWASP_MSTG_CODE_9: 'OWASP_MSTG_CODE_9', 
  OWASP_MSTG_RESILIENCE_1: 'OWASP_MSTG_RESILIENCE_1', 
  OWASP_MSTG_RESILIENCE_2: 'OWASP_MSTG_RESILIENCE_2', 
  OWASP_MSTG_RESILIENCE_3: 'OWASP_MSTG_RESILIENCE_3', 
  OWASP_MSTG_RESILIENCE_4: 'OWASP_MSTG_RESILIENCE_4', 
  OWASP_MSTG_RESILIENCE_5: 'OWASP_MSTG_RESILIENCE_5', 
  OWASP_MSTG_RESILIENCE_6: 'OWASP_MSTG_RESILIENCE_6', 
  OWASP_MSTG_RESILIENCE_7: 'OWASP_MSTG_RESILIENCE_7', 
  OWASP_MSTG_RESILIENCE_8: 'OWASP_MSTG_RESILIENCE_8', 
  OWASP_MSTG_RESILIENCE_9: 'OWASP_MSTG_RESILIENCE_9', 
  OWASP_MSTG_RESILIENCE_10: 'OWASP_MSTG_RESILIENCE_10', 
  OWASP_MSTG_RESILIENCE_11: 'OWASP_MSTG_RESILIENCE_11', 
  OWASP_MSTG_RESILIENCE_12: 'OWASP_MSTG_RESILIENCE_12', 
  OWASP_MSTG_RESILIENCE_13: 'OWASP_MSTG_RESILIENCE_13', 
  OWASP_ASVS_V_1_1: 'OWASP_ASVS_V_1_1', 
  OWASP_ASVS_V_1_2: 'OWASP_ASVS_V_1_2', 
  OWASP_ASVS_V_1_3: 'OWASP_ASVS_V_1_3', 
  OWASP_ASVS_V_1_4: 'OWASP_ASVS_V_1_4', 
  OWASP_ASVS_V_1_5: 'OWASP_ASVS_V_1_5', 
  OWASP_ASVS_V_1_6: 'OWASP_ASVS_V_1_6', 
  OWASP_ASVS_V_1_7: 'OWASP_ASVS_V_1_7', 
  OWASP_ASVS_V_1_8: 'OWASP_ASVS_V_1_8', 
  OWASP_ASVS_V_1_9: 'OWASP_ASVS_V_1_9', 
  OWASP_ASVS_V_1_10: 'OWASP_ASVS_V_1_10', 
  OWASP_ASVS_V_1_11: 'OWASP_ASVS_V_1_11', 
  OWASP_ASVS_V_1_12: 'OWASP_ASVS_V_1_12', 
  OWASP_ASVS_V_1_13: 'OWASP_ASVS_V_1_13', 
  OWASP_ASVS_V_1_14: 'OWASP_ASVS_V_1_14', 
  OWASP_ASVS_V_2_1: 'OWASP_ASVS_V_2_1', 
  OWASP_ASVS_V_2_2: 'OWASP_ASVS_V_2_2', 
  OWASP_ASVS_V_2_3: 'OWASP_ASVS_V_2_3', 
  OWASP_ASVS_V_2_4: 'OWASP_ASVS_V_2_4', 
  OWASP_ASVS_V_2_5: 'OWASP_ASVS_V_2_5', 
  OWASP_ASVS_V_2_6: 'OWASP_ASVS_V_2_6', 
  OWASP_ASVS_V_2_7: 'OWASP_ASVS_V_2_7', 
  OWASP_ASVS_V_2_8: 'OWASP_ASVS_V_2_8', 
  OWASP_ASVS_V_2_9: 'OWASP_ASVS_V_2_9', 
  OWASP_ASVS_V_2_10: 'OWASP_ASVS_V_2_10', 
  OWASP_ASVS_V_3_1: 'OWASP_ASVS_V_3_1', 
  OWASP_ASVS_V_3_2: 'OWASP_ASVS_V_3_2', 
  OWASP_ASVS_V_3_3: 'OWASP_ASVS_V_3_3', 
  OWASP_ASVS_V_3_4: 'OWASP_ASVS_V_3_4', 
  OWASP_ASVS_V_3_5: 'OWASP_ASVS_V_3_5', 
  OWASP_ASVS_V_3_6: 'OWASP_ASVS_V_3_6', 
  OWASP_ASVS_V_3_7: 'OWASP_ASVS_V_3_7', 
  OWASP_ASVS_V_3_8: 'OWASP_ASVS_V_3_8', 
  OWASP_ASVS_V_3_9: 'OWASP_ASVS_V_3_9', 
  OWASP_ASVS_V_3_10: 'OWASP_ASVS_V_3_10', 
  OWASP_ASVS_V_4_1: 'OWASP_ASVS_V_4_1', 
  OWASP_ASVS_V_4_2: 'OWASP_ASVS_V_4_2', 
  OWASP_ASVS_V_4_3: 'OWASP_ASVS_V_4_3', 
  OWASP_ASVS_V_4_4: 'OWASP_ASVS_V_4_4', 
  OWASP_ASVS_V_4_5: 'OWASP_ASVS_V_4_5', 
  OWASP_ASVS_V_4_6: 'OWASP_ASVS_V_4_6', 
  OWASP_ASVS_V_4_7: 'OWASP_ASVS_V_4_7', 
  OWASP_ASVS_V_4_8: 'OWASP_ASVS_V_4_8', 
  OWASP_ASVS_V_4_9: 'OWASP_ASVS_V_4_9', 
  OWASP_ASVS_V_4_10: 'OWASP_ASVS_V_4_10', 
  OWASP_ASVS_V_5_1: 'OWASP_ASVS_V_5_1', 
  OWASP_ASVS_V_5_2: 'OWASP_ASVS_V_5_2', 
  OWASP_ASVS_V_5_3: 'OWASP_ASVS_V_5_3', 
  OWASP_ASVS_V_5_4: 'OWASP_ASVS_V_5_4', 
  OWASP_ASVS_V_5_5: 'OWASP_ASVS_V_5_5', 
  OWASP_ASVS_V_5_6: 'OWASP_ASVS_V_5_6', 
  OWASP_ASVS_V_5_7: 'OWASP_ASVS_V_5_7', 
  OWASP_ASVS_V_5_8: 'OWASP_ASVS_V_5_8', 
  OWASP_ASVS_V_5_9: 'OWASP_ASVS_V_5_9', 
  OWASP_ASVS_V_5_10: 'OWASP_ASVS_V_5_10', 
  OWASP_ASVS_V_6_1: 'OWASP_ASVS_V_6_1', 
  OWASP_ASVS_V_6_2: 'OWASP_ASVS_V_6_2', 
  OWASP_ASVS_V_6_3: 'OWASP_ASVS_V_6_3', 
  OWASP_ASVS_V_6_4: 'OWASP_ASVS_V_6_4', 
  OWASP_ASVS_V_6_5: 'OWASP_ASVS_V_6_5', 
  OWASP_ASVS_V_6_6: 'OWASP_ASVS_V_6_6', 
  OWASP_ASVS_V_6_7: 'OWASP_ASVS_V_6_7', 
  OWASP_ASVS_V_6_8: 'OWASP_ASVS_V_6_8', 
  OWASP_ASVS_V_6_9: 'OWASP_ASVS_V_6_9', 
  OWASP_ASVS_V_6_10: 'OWASP_ASVS_V_6_10', 
  OWASP_ASVS_V_7_1: 'OWASP_ASVS_V_7_1', 
  OWASP_ASVS_V_7_2: 'OWASP_ASVS_V_7_2', 
  OWASP_ASVS_V_7_3: 'OWASP_ASVS_V_7_3', 
  OWASP_ASVS_V_7_4: 'OWASP_ASVS_V_7_4', 
  OWASP_ASVS_V_7_5: 'OWASP_ASVS_V_7_5', 
  OWASP_ASVS_V_7_6: 'OWASP_ASVS_V_7_6', 
  OWASP_ASVS_V_7_7: 'OWASP_ASVS_V_7_7', 
  OWASP_ASVS_V_7_8: 'OWASP_ASVS_V_7_8', 
  OWASP_ASVS_V_7_9: 'OWASP_ASVS_V_7_9', 
  OWASP_ASVS_V_7_10: 'OWASP_ASVS_V_7_10', 
  OWASP_ASVS_V_8_1: 'OWASP_ASVS_V_8_1', 
  OWASP_ASVS_V_8_2: 'OWASP_ASVS_V_8_2', 
  OWASP_ASVS_V_8_3: 'OWASP_ASVS_V_8_3', 
  OWASP_ASVS_V_8_4: 'OWASP_ASVS_V_8_4', 
  OWASP_ASVS_V_8_5: 'OWASP_ASVS_V_8_5', 
  OWASP_ASVS_V_8_6: 'OWASP_ASVS_V_8_6', 
  OWASP_ASVS_V_8_7: 'OWASP_ASVS_V_8_7', 
  OWASP_ASVS_V_8_8: 'OWASP_ASVS_V_8_8', 
  OWASP_ASVS_V_8_9: 'OWASP_ASVS_V_8_9', 
  OWASP_ASVS_V_8_10: 'OWASP_ASVS_V_8_10', 
  OWASP_ASVS_V_9_1: 'OWASP_ASVS_V_9_1', 
  OWASP_ASVS_V_9_2: 'OWASP_ASVS_V_9_2', 
  OWASP_ASVS_V_9_3: 'OWASP_ASVS_V_9_3', 
  OWASP_ASVS_V_9_4: 'OWASP_ASVS_V_9_4', 
  OWASP_ASVS_V_9_5: 'OWASP_ASVS_V_9_5', 
  OWASP_ASVS_V_9_6: 'OWASP_ASVS_V_9_6', 
  OWASP_ASVS_V_9_7: 'OWASP_ASVS_V_9_7', 
  OWASP_ASVS_V_9_8: 'OWASP_ASVS_V_9_8', 
  OWASP_ASVS_V_9_9: 'OWASP_ASVS_V_9_9', 
  OWASP_ASVS_V_9_10: 'OWASP_ASVS_V_9_10', 
  OWASP_ASVS_V_10_1: 'OWASP_ASVS_V_10_1', 
  OWASP_ASVS_V_10_2: 'OWASP_ASVS_V_10_2', 
  OWASP_ASVS_V_10_3: 'OWASP_ASVS_V_10_3', 
  OWASP_ASVS_V_10_4: 'OWASP_ASVS_V_10_4', 
  OWASP_ASVS_V_10_5: 'OWASP_ASVS_V_10_5', 
  OWASP_ASVS_V_10_6: 'OWASP_ASVS_V_10_6', 
  OWASP_ASVS_V_10_7: 'OWASP_ASVS_V_10_7', 
  OWASP_ASVS_V_10_8: 'OWASP_ASVS_V_10_8', 
  OWASP_ASVS_V_10_9: 'OWASP_ASVS_V_10_9', 
  OWASP_ASVS_V_10_10: 'OWASP_ASVS_V_10_10', 
  OWASP_ASVS_V_11_1: 'OWASP_ASVS_V_11_1', 
  OWASP_ASVS_V_11_2: 'OWASP_ASVS_V_11_2', 
  OWASP_ASVS_V_11_3: 'OWASP_ASVS_V_11_3', 
  OWASP_ASVS_V_11_4: 'OWASP_ASVS_V_11_4', 
  OWASP_ASVS_V_11_5: 'OWASP_ASVS_V_11_5', 
  OWASP_ASVS_V_11_6: 'OWASP_ASVS_V_11_6', 
  OWASP_ASVS_V_11_7: 'OWASP_ASVS_V_11_7', 
  OWASP_ASVS_V_11_8: 'OWASP_ASVS_V_11_8', 
  OWASP_ASVS_V_11_9: 'OWASP_ASVS_V_11_9', 
  OWASP_ASVS_V_11_10: 'OWASP_ASVS_V_11_10', 
  OWASP_ASVS_V_12_1: 'OWASP_ASVS_V_12_1', 
  OWASP_ASVS_V_12_2: 'OWASP_ASVS_V_12_2', 
  OWASP_ASVS_V_12_3: 'OWASP_ASVS_V_12_3', 
  OWASP_ASVS_V_12_4: 'OWASP_ASVS_V_12_4', 
  OWASP_ASVS_V_12_5: 'OWASP_ASVS_V_12_5', 
  OWASP_ASVS_V_12_6: 'OWASP_ASVS_V_12_6', 
  OWASP_ASVS_V_12_7: 'OWASP_ASVS_V_12_7', 
  OWASP_ASVS_V_12_8: 'OWASP_ASVS_V_12_8', 
  OWASP_ASVS_V_12_9: 'OWASP_ASVS_V_12_9', 
  OWASP_ASVS_V_12_10: 'OWASP_ASVS_V_12_10', 
  OWASP_ASVS_V_13_1: 'OWASP_ASVS_V_13_1', 
  OWASP_ASVS_V_13_2: 'OWASP_ASVS_V_13_2', 
  OWASP_ASVS_V_13_3: 'OWASP_ASVS_V_13_3', 
  OWASP_ASVS_V_13_4: 'OWASP_ASVS_V_13_4', 
  OWASP_ASVS_V_13_5: 'OWASP_ASVS_V_13_5', 
  OWASP_ASVS_V_13_6: 'OWASP_ASVS_V_13_6', 
  OWASP_ASVS_V_13_7: 'OWASP_ASVS_V_13_7', 
  OWASP_ASVS_V_13_8: 'OWASP_ASVS_V_13_8', 
  OWASP_ASVS_V_13_9: 'OWASP_ASVS_V_13_9', 
  OWASP_ASVS_V_13_10: 'OWASP_ASVS_V_13_10', 
  OWASP_ASVS_V_14_1: 'OWASP_ASVS_V_14_1', 
  OWASP_ASVS_V_14_2: 'OWASP_ASVS_V_14_2', 
  OWASP_ASVS_V_14_3: 'OWASP_ASVS_V_14_3', 
  OWASP_ASVS_V_14_4: 'OWASP_ASVS_V_14_4', 
  OWASP_ASVS_V_14_5: 'OWASP_ASVS_V_14_5', 
  OWASP_ASVS_V_14_6: 'OWASP_ASVS_V_14_6', 
  OWASP_ASVS_V_14_7: 'OWASP_ASVS_V_14_7', 
  OWASP_ASVS_V_14_8: 'OWASP_ASVS_V_14_8', 
  OWASP_ASVS_V_14_9: 'OWASP_ASVS_V_14_9', 
  OWASP_ASVS_V_14_10: 'OWASP_ASVS_V_14_10', 
  FFIEC_VC2_REQUIREMENT_1: 'FFIEC_VC2_REQUIREMENT_1', 
  FFIEC_VC2_REQUIREMENT_2: 'FFIEC_VC2_REQUIREMENT_2', 
  FFIEC_VC2_REQUIREMENT_3: 'FFIEC_VC2_REQUIREMENT_3', 
  FFIEC_VC2_REQUIREMENT_4: 'FFIEC_VC2_REQUIREMENT_4', 
  FFIEC_VC2_REQUIREMENT_5: 'FFIEC_VC2_REQUIREMENT_5', 
  FFIEC_VC2_REQUIREMENT_6: 'FFIEC_VC2_REQUIREMENT_6', 
  FFIEC_VC2_REQUIREMENT_7: 'FFIEC_VC2_REQUIREMENT_7', 
  FFIEC_VC2_REQUIREMENT_8: 'FFIEC_VC2_REQUIREMENT_8', 
  FFIEC_VC2_REQUIREMENT_9: 'FFIEC_VC2_REQUIREMENT_9', 
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_1: 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_1', 
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_3: 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_3', 
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_4: 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_4', 
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_5: 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_5', 
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_6: 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_6', 
  CIS_BENCHMARK_KUBERNETES_1: 'CIS_BENCHMARK_KUBERNETES_1', 
  CIS_BENCHMARK_KUBERNETES_3: 'CIS_BENCHMARK_KUBERNETES_3', 
  CIS_BENCHMARK_KUBERNETES_5: 'CIS_BENCHMARK_KUBERNETES_5', 
  CIS_BENCHMARK_AWS_1: 'CIS_BENCHMARK_AWS_1', 
  CIS_BENCHMARK_AWS_2: 'CIS_BENCHMARK_AWS_2', 
  CIS_BENCHMARK_AWS_3: 'CIS_BENCHMARK_AWS_3', 
  CIS_BENCHMARK_AZURE_3: 'CIS_BENCHMARK_AZURE_3', 
  CIS_BENCHMARK_AZURE_4: 'CIS_BENCHMARK_AZURE_4', 
  CIS_BENCHMARK_AZURE_6: 'CIS_BENCHMARK_AZURE_6', 
  CIS_BENCHMARK_AZURE_7: 'CIS_BENCHMARK_AZURE_7', 
  CIS_BENCHMARK_AZURE_8: 'CIS_BENCHMARK_AZURE_8', 
  CIS_BENCHMARK_AZURE_9: 'CIS_BENCHMARK_AZURE_9'
});


export type PolicyComplianceStandardCriteria = $Values<typeof PolicyComplianceStandardCriteriaValues>;

export const PolicyRuleGroupValues = Object.freeze({
  REST_API_AND_SERVERLESS: 'REST_API_AND_SERVERLESS', 
  CLOUD_SECURITY: 'CLOUD_SECURITY', 
  WEB_APPS: 'WEB_APPS', 
  ENCRYPTION: 'ENCRYPTION', 
  MOBILE: 'MOBILE', 
  KSPM: 'KSPM', 
  API_DEFINITION_STATIC_ANALYSIS: 'API_DEFINITION_STATIC_ANALYSIS', 
  FUNCTIONAL_API_SECURITY_TEST: 'FUNCTIONAL_API_SECURITY_TEST'
});


export type PolicyRuleGroup = $Values<typeof PolicyRuleGroupValues>;

export const JiraViolationsAutoExportSettingEnumValues = Object.freeze({
  AUTO_EXPORT_NONE: 'AUTO_EXPORT_NONE', 
  AUTO_EXPORT_HIGH_SEVERITY: 'AUTO_EXPORT_HIGH_SEVERITY', 
  AUTO_EXPORT_HIGH_AND_MEDIUM_SEVERITY: 'AUTO_EXPORT_HIGH_AND_MEDIUM_SEVERITY', 
  AUTO_EXPORT_ALL: 'AUTO_EXPORT_ALL'
});


export type JiraViolationsAutoExportSettingEnum = $Values<typeof JiraViolationsAutoExportSettingEnumValues>;

export const PolicyRuleTypeRelevanceValues = Object.freeze({
  PROACTIVE: 'PROACTIVE', 
  IMPORTANT: 'IMPORTANT', 
  URGENT: 'URGENT'
});


export type PolicyRuleTypeRelevance = $Values<typeof PolicyRuleTypeRelevanceValues>;

export const PolicyRuleTypeStatusValues = Object.freeze({
  ENABLED: 'ENABLED', 
  DELETED_BY_USER: 'DELETED_BY_USER'
});


export type PolicyRuleTypeStatus = $Values<typeof PolicyRuleTypeStatusValues>;

export const PolicyViolationExceptionValues = Object.freeze({
  WONT_FIX: 'WONT_FIX', 
  POLICY_RULE_DELETED: 'POLICY_RULE_DELETED'
});


export type PolicyViolationException = $Values<typeof PolicyViolationExceptionValues>;

export const WebRequestTypeValues = Object.freeze({
  XHR: 'XHR', 
  FETCH: 'FETCH', 
  FONT: 'FONT', 
  SCRIPT: 'SCRIPT', 
  STYLESHEET: 'STYLESHEET', 
  IMAGE: 'IMAGE', 
  MEDIA: 'MEDIA', 
  DOCUMENT: 'DOCUMENT', 
  TEXTTRACK: 'TEXTTRACK', 
  EVENTSOURCE: 'EVENTSOURCE', 
  WEBSOCKET: 'WEBSOCKET', 
  MANIFEST: 'MANIFEST', 
  OTHER: 'OTHER'
});


export type WebRequestType = $Values<typeof WebRequestTypeValues>;

export const WebApplicationTypeValues = Object.freeze({
  UNKNOWN: 'UNKNOWN', 
  SINGLE_PAGE: 'SINGLE_PAGE', 
  TRADITIONAL: 'TRADITIONAL', 
  HYBRID: 'HYBRID'
});


export type WebApplicationType = $Values<typeof WebApplicationTypeValues>;

export const WebApplicationStatusEnumValues = Object.freeze({
  ONLINE: 'ONLINE', 
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE'
});


export type WebApplicationStatusEnum = $Values<typeof WebApplicationStatusEnumValues>;

export const GraphQlApiStatusEnumValues = Object.freeze({
  ONLINE: 'ONLINE', 
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE'
});


export type GraphQlApiStatusEnum = $Values<typeof GraphQlApiStatusEnumValues>;

export const RestfulApiStatusEnumValues = Object.freeze({
  ONLINE: 'ONLINE', 
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE'
});


export type RestfulApiStatusEnum = $Values<typeof RestfulApiStatusEnumValues>;

export const NetworkServiceStatusEnumValues = Object.freeze({
  ONLINE: 'ONLINE', 
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE'
});


export type NetworkServiceStatusEnum = $Values<typeof NetworkServiceStatusEnumValues>;

export const VendorMobileSdkStatusEnumValues = Object.freeze({
  DETECTED: 'DETECTED', 
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE'
});


export type VendorMobileSdkStatusEnum = $Values<typeof VendorMobileSdkStatusEnumValues>;

export const WeekdayValues = Object.freeze({
  MONDAY: 'MONDAY', 
  TUESDAY: 'TUESDAY', 
  WEDNESDAY: 'WEDNESDAY', 
  THURSDAY: 'THURSDAY', 
  FRIDAY: 'FRIDAY', 
  SATURDAY: 'SATURDAY', 
  SUNDAY: 'SUNDAY'
});


export type Weekday = $Values<typeof WeekdayValues>;

export const SpecialScanStatusValues = Object.freeze({
  NOT_STARTED: 'NOT_STARTED', 
  ONGOING: 'ONGOING', 
  FINISHED: 'FINISHED', 
  COMPLETED: 'COMPLETED'
});


export type SpecialScanStatus = $Values<typeof SpecialScanStatusValues>;

export const SpecialScanTypeValues = Object.freeze({
  HACK_AND_EXTRACT: 'HACK_AND_EXTRACT', 
  LEAKY_APIS: 'LEAKY_APIS', 
  DETECT_AND_INJECT: 'DETECT_AND_INJECT', 
  XSS_PROTECTION: 'XSS_PROTECTION', 
  SPA_DETECT_AND_INJECT: 'SPA_DETECT_AND_INJECT'
});


export type SpecialScanType = $Values<typeof SpecialScanTypeValues>;

export const PolicyViolationStatusEnumValues = Object.freeze({
  OPEN: 'OPEN', 
  WONT_FIX: 'WONT_FIX', 
  RESOLVED: 'RESOLVED', 
  DELETED: 'DELETED'
});


export type PolicyViolationStatusEnum = $Values<typeof PolicyViolationStatusEnumValues>;

export const IacScanFilterEnumValues = Object.freeze({
  TERRAFORM: 'TERRAFORM'
});


export type IacScanFilterEnum = $Values<typeof IacScanFilterEnumValues>;

export const TlsScanTypeValues = Object.freeze({
  SUPPORTS_SSL_2_0: 'SUPPORTS_SSL_2_0', 
  SUPPORTS_SSL_3_0: 'SUPPORTS_SSL_3_0', 
  SUPPORTS_TLS_1_0: 'SUPPORTS_TLS_1_0', 
  SUPPORTS_TLS_1_1: 'SUPPORTS_TLS_1_1', 
  SUPPORTS_TLS_1_2: 'SUPPORTS_TLS_1_2', 
  SUPPORTS_TLS_1_3: 'SUPPORTS_TLS_1_3', 
  SUPPORTS_OCSP_STAPLING: 'SUPPORTS_OCSP_STAPLING', 
  SUPPORTS_SESSION_RESUMPTION: 'SUPPORTS_SESSION_RESUMPTION', 
  SUPPORTS_COMPRESSION: 'SUPPORTS_COMPRESSION', 
  SUPPORTS_SECURE_RENEGOTIATION: 'SUPPORTS_SECURE_RENEGOTIATION', 
  SUPPORTS_TLS_1_3_EARLY_DATA: 'SUPPORTS_TLS_1_3_EARLY_DATA', 
  VULNERABLE_TO_HEARTBLEED: 'VULNERABLE_TO_HEARTBLEED', 
  VULNERABLE_TO_CCS_INJECTION: 'VULNERABLE_TO_CCS_INJECTION', 
  VULNERABLE_TO_ROBOT: 'VULNERABLE_TO_ROBOT'
});


export type TlsScanType = $Values<typeof TlsScanTypeValues>;

export const SecurityPropertiesEnumValues = Object.freeze({
  MATCHES_HOSTNAME: 'MATCHES_HOSTNAME', 
  IS_EXPIRING: 'IS_EXPIRING', 
  SUPPORTS_MUST_STAPLE: 'SUPPORTS_MUST_STAPLE', 
  EMBEDS_CERTIFICATE_TRANSPARENCY_SCT: 'EMBEDS_CERTIFICATE_TRANSPARENCY_SCT', 
  VULNERABLE_TO_SHA1_COLLISION: 'VULNERABLE_TO_SHA1_COLLISION', 
  RSA_KEY_SMALLER_THAN_2048_BITS: 'RSA_KEY_SMALLER_THAN_2048_BITS', 
  LEGACY_SYMANTEC_ANCHOR: 'LEGACY_SYMANTEC_ANCHOR'
});


export type SecurityPropertiesEnum = $Values<typeof SecurityPropertiesEnumValues>;

export const NetworkServiceConnectivityScanStatusEnumValues = Object.freeze({
  REACHABLE: 'REACHABLE', 
  NOT_REACHABLE: 'NOT_REACHABLE', 
  NOT_REACHABLE_CONNECTION_REJECTED: 'NOT_REACHABLE_CONNECTION_REJECTED', 
  NOT_REACHABLE_CONNECTION_TIMED_OUT: 'NOT_REACHABLE_CONNECTION_TIMED_OUT', 
  NOT_REACHABLE_DNS_LOOKUP_FAILED: 'NOT_REACHABLE_DNS_LOOKUP_FAILED'
});


export type NetworkServiceConnectivityScanStatusEnum = $Values<typeof NetworkServiceConnectivityScanStatusEnumValues>;

export const CredentialTriagingDecisionEnumValues = Object.freeze({
  NONE: 'NONE', 
  MONITORED: 'MONITORED', 
  IGNORED: 'IGNORED'
});


export type CredentialTriagingDecisionEnum = $Values<typeof CredentialTriagingDecisionEnumValues>;

export const ShouldAddAssetsRecentlyDiscoveredViaSourceValues = Object.freeze({
  ALL_SOURCES: 'ALL_SOURCES', 
  PUBLIC_INTERNET_ANALYZER: 'PUBLIC_INTERNET_ANALYZER'
});


export type ShouldAddAssetsRecentlyDiscoveredViaSource = $Values<typeof ShouldAddAssetsRecentlyDiscoveredViaSourceValues>;

export const CloudAuthenticatorCategoryValues = Object.freeze({
  CLOUD_ACCOUNT: 'CLOUD_ACCOUNT', 
  API_GATEWAY_ACCOUNT: 'API_GATEWAY_ACCOUNT', 
  API_DOCUMENTATION_ACCOUNT: 'API_DOCUMENTATION_ACCOUNT'
});


export type CloudAuthenticatorCategory = $Values<typeof CloudAuthenticatorCategoryValues>;

export const CloudAuthenticatorStatusValues = Object.freeze({
  ENABLED: 'ENABLED', 
  ACCESS_ERROR: 'ACCESS_ERROR', 
  DELETED: 'DELETED', 
  MISSING_PERMISSIONS: 'MISSING_PERMISSIONS'
});


export type CloudAuthenticatorStatus = $Values<typeof CloudAuthenticatorStatusValues>;

export const CloudAuthenticatorTypeValues = Object.freeze({
  APIGEE: 'APIGEE', 
  APIGEE_HYBRID: 'APIGEE_HYBRID', 
  APIGEE_ON_PREM: 'APIGEE_ON_PREM', 
  AMAZON_WEB_SERVICES: 'AMAZON_WEB_SERVICES', 
  AZURE: 'AZURE', 
  GOOGLE_CLOUD_PLATFORM: 'GOOGLE_CLOUD_PLATFORM', 
  MULESOFT: 'MULESOFT', 
  KONG: 'KONG', 
  AXWAY: 'AXWAY', 
  SWAGGER_HUB: 'SWAGGER_HUB', 
  KUBERNETES: 'KUBERNETES'
});


export type CloudAuthenticatorType = $Values<typeof CloudAuthenticatorTypeValues>;

export const AutoRemediationAttemptStatusValues = Object.freeze({
  CREATED: 'CREATED', 
  STARTED: 'STARTED', 
  SUCCEEDED: 'SUCCEEDED', 
  FAILED: 'FAILED'
});


export type AutoRemediationAttemptStatus = $Values<typeof AutoRemediationAttemptStatusValues>;

export const AlertsIntegrationsServiceValues = Object.freeze({
  SLACK: 'SLACK', 
  MICROSOFT_TEAMS: 'MICROSOFT_TEAMS'
});


export type AlertsIntegrationsService = $Values<typeof AlertsIntegrationsServiceValues>;

export const AssetGroupsSearchIncludeEnumValues = Object.freeze({
  policy_violation_stats: 'policy_violation_stats', 
  assets_overview: 'assets_overview'
});


export type AssetGroupsSearchIncludeEnum = $Values<typeof AssetGroupsSearchIncludeEnumValues>;

export const PolicyRulesIncludeEnumValues = Object.freeze({
  policy_violation_stats: 'policy_violation_stats'
});


export type PolicyRulesIncludeEnum = $Values<typeof PolicyRulesIncludeEnumValues>;

export const CloudResourceStatusValues = Object.freeze({
  ONLINE: 'ONLINE', 
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE'
});


export type CloudResourceStatus = $Values<typeof CloudResourceStatusValues>;

export const MetricTypesValues = Object.freeze({
  FULL_STACK_METRICS: 'FULL_STACK_METRICS', 
  WEB_APPLICATION_XSS_TOOLKIT_METRICS: 'WEB_APPLICATION_XSS_TOOLKIT_METRICS', 
  CLOUD_RESOURCE_CATEGORIES: 'CLOUD_RESOURCE_CATEGORIES', 
  API_RADAR: 'API_RADAR'
});


export type MetricTypes = $Values<typeof MetricTypesValues>;

export const AuthenticatoTypeValues = Object.freeze({
  APIGEE: 'APIGEE', 
  APIGEE_ON_PREM: 'APIGEE_ON_PREM', 
  AMAZON_WEB_SERVICES: 'AMAZON_WEB_SERVICES', 
  AZURE: 'AZURE', 
  GOOGLE_CLOUD_PLATFORM: 'GOOGLE_CLOUD_PLATFORM', 
  MULESOFT: 'MULESOFT', 
  KONG: 'KONG', 
  AXWAY: 'AXWAY'
});


export type AuthenticatoType = $Values<typeof AuthenticatoTypeValues>;

export const AssetStatusValues = Object.freeze({
  ONLINE: 'ONLINE', 
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE'
});


export type AssetStatus = $Values<typeof AssetStatusValues>;

export const CloudProviderValues = Object.freeze({
  UNKNOWN: 'UNKNOWN', 
  APIGEE: 'APIGEE', 
  AMAZON_WEB_SERVICES: 'AMAZON_WEB_SERVICES', 
  AZURE: 'AZURE', 
  GOOGLE_CLOUD_PLATFORM: 'GOOGLE_CLOUD_PLATFORM', 
  MULESOFT: 'MULESOFT', 
  KONG: 'KONG', 
  AXWAY: 'AXWAY', 
  ON_PREMISE: 'ON_PREMISE'
});


export type CloudProvider = $Values<typeof CloudProviderValues>;

export const ResourceTypeEnumValues = Object.freeze({
  APIGEE_API_PROXY: 'APIGEE_API_PROXY', 
  AWS_API_GATEWAY_API: 'AWS_API_GATEWAY_API', 
  AWS_API_GATEWAY_API_STAGE: 'AWS_API_GATEWAY_API_STAGE', 
  AWS_ATHENA_WORKGROUP: 'AWS_ATHENA_WORKGROUP', 
  AWS_CLOUD_FRONT: 'AWS_CLOUD_FRONT', 
  AWS_CLOUD_TRAIL: 'AWS_CLOUD_TRAIL', 
  AWS_CODEBUILD_PROJECT: 'AWS_CODEBUILD_PROJECT', 
  AWS_DMS_INSTANCE: 'AWS_DMS_INSTANCE', 
  AWS_DOCDB_INSTANCE: 'AWS_DOCDB_INSTANCE', 
  AWS_DYNAMODB_DAX_CLUSTER: 'AWS_DYNAMODB_DAX_CLUSTER', 
  AWS_DYNAMODB_TABLE: 'AWS_DYNAMODB_TABLE', 
  AWS_EBS_SNAPSHOT: 'AWS_EBS_SNAPSHOT', 
  AWS_EBS_VOLUME: 'AWS_EBS_VOLUME', 
  AWS_EC2_AMI_IMAGE: 'AWS_EC2_AMI_IMAGE', 
  AWS_EC2_AUTOSCALING_GROUP: 'AWS_EC2_AUTOSCALING_GROUP', 
  AWS_EC2_VIRTUAL_MACHINE: 'AWS_EC2_VIRTUAL_MACHINE', 
  AWS_EFS_FILESYSTEM: 'AWS_EFS_FILESYSTEM', 
  AWS_EKS_KUBERNETES_CLUSTER: 'AWS_EKS_KUBERNETES_CLUSTER', 
  AWS_ELASTICACHE_MEMCACHED_CLUSTER: 'AWS_ELASTICACHE_MEMCACHED_CLUSTER', 
  AWS_ELASTICACHE_REDIS_CLUSTER: 'AWS_ELASTICACHE_REDIS_CLUSTER', 
  AWS_ELASTICSEARCH_DATABASE: 'AWS_ELASTICSEARCH_DATABASE', 
  AWS_ELB_LOAD_BALANCER: 'AWS_ELB_LOAD_BALANCER', 
  AWS_ELB_V2_LOAD_BALANCER: 'AWS_ELB_V2_LOAD_BALANCER', 
  AWS_EMR_CLUSTER: 'AWS_EMR_CLUSTER', 
  AWS_IAM_PASSWORD_POLICY: 'AWS_IAM_PASSWORD_POLICY', 
  AWS_IAM_USER: 'AWS_IAM_USER', 
  AWS_KINESIS_STREAM: 'AWS_KINESIS_STREAM', 
  AWS_KMS_KEY: 'AWS_KMS_KEY', 
  AWS_LAMBDA_FUNCTION: 'AWS_LAMBDA_FUNCTION', 
  AWS_RDS_CLUSTER: 'AWS_RDS_CLUSTER', 
  AWS_RDS_CLUSTER_SNAPSHOT: 'AWS_RDS_CLUSTER_SNAPSHOT', 
  AWS_RDS_INSTANCE: 'AWS_RDS_INSTANCE', 
  AWS_RDS_INSTANCE_SNAPSHOT: 'AWS_RDS_INSTANCE_SNAPSHOT', 
  AWS_REDSHIFT_CLUSTER: 'AWS_REDSHIFT_CLUSTER', 
  AWS_ROUTE53_DOMAIN: 'AWS_ROUTE53_DOMAIN', 
  AWS_S3_BUCKET: 'AWS_S3_BUCKET', 
  AWS_S3_BUCKET_ACCESS_POINT: 'AWS_S3_BUCKET_ACCESS_POINT', 
  AWS_SAGE_MAKER: 'AWS_SAGE_MAKER', 
  AWS_SNS_TOPIC: 'AWS_SNS_TOPIC', 
  AWS_SQS_QUEUE: 'AWS_SQS_QUEUE', 
  AWS_VPC: 'AWS_VPC', 
  AWS_WORKSPACE: 'AWS_WORKSPACE', 
  AXWAY_API_BACKEND: 'AXWAY_API_BACKEND', 
  AXWAY_API_PROXY: 'AXWAY_API_PROXY', 
  AZURE_API_MANAGEMENT_SERVICE: 'AZURE_API_MANAGEMENT_SERVICE', 
  AZURE_API_MANAGEMENT_SERVICE_API: 'AZURE_API_MANAGEMENT_SERVICE_API', 
  AZURE_APPLICATION_GATEWAY: 'AZURE_APPLICATION_GATEWAY', 
  AZURE_AUTOMATION_VARIABLE: 'AZURE_AUTOMATION_VARIABLE', 
  AZURE_CDN_ENDPOINT: 'AZURE_CDN_ENDPOINT', 
  AZURE_CLOUD_SERVICE: 'AZURE_CLOUD_SERVICE', 
  AZURE_COSMOSDB_INSTANCE: 'AZURE_COSMOSDB_INSTANCE', 
  AZURE_FUNCTION: 'AZURE_FUNCTION', 
  AZURE_FUNCTION_APP: 'AZURE_FUNCTION_APP', 
  AZURE_KEY_VAULT: 'AZURE_KEY_VAULT', 
  AZURE_KEY_VAULT_SECRET: 'AZURE_KEY_VAULT_SECRET', 
  AZURE_KUBERNETES_SERVICE_MANAGED_CLUSTER: 'AZURE_KUBERNETES_SERVICE_MANAGED_CLUSTER', 
  AZURE_MARIADB_INSTANCE: 'AZURE_MARIADB_INSTANCE', 
  AZURE_MARIADB_SERVER: 'AZURE_MARIADB_SERVER', 
  AZURE_POSTGRESQL_DATABASE: 'AZURE_POSTGRESQL_DATABASE', 
  AZURE_POSTGRESQL_SERVER: 'AZURE_POSTGRESQL_SERVER', 
  AZURE_REDIS_CACHE: 'AZURE_REDIS_CACHE', 
  AZURE_SERVICE_FABRIC_CLUSTER: 'AZURE_SERVICE_FABRIC_CLUSTER', 
  AZURE_SQLDB_INSTANCE: 'AZURE_SQLDB_INSTANCE', 
  AZURE_SQLDB_SERVER: 'AZURE_SQLDB_SERVER', 
  AZURE_STORAGE_ACCOUNT: 'AZURE_STORAGE_ACCOUNT', 
  AZURE_STORAGE_ACCOUNT_BLOB_CONTAINER: 'AZURE_STORAGE_ACCOUNT_BLOB_CONTAINER', 
  AZURE_VIRTUAL_MACHINE: 'AZURE_VIRTUAL_MACHINE', 
  AZURE_WEB_APP: 'AZURE_WEB_APP', 
  FINGERPRINTED_AWS_S3_BUCKET: 'FINGERPRINTED_AWS_S3_BUCKET', 
  FINGERPRINTED_AZURE_STORAGE_ACCOUNT_BLOB_CONTAINER: 'FINGERPRINTED_AZURE_STORAGE_ACCOUNT_BLOB_CONTAINER', 
  FINGERPRINTED_CASSANDRA_DB_DATABASE: 'FINGERPRINTED_CASSANDRA_DB_DATABASE', 
  FINGERPRINTED_CONFLUENCE_SERVER: 'FINGERPRINTED_CONFLUENCE_SERVER', 
  FINGERPRINTED_COUCHDB_DATABASE: 'FINGERPRINTED_COUCHDB_DATABASE', 
  FINGERPRINTED_DOCKER_REGISTRY: 'FINGERPRINTED_DOCKER_REGISTRY', 
  FINGERPRINTED_ELASTICSEARCH_DATABASE: 'FINGERPRINTED_ELASTICSEARCH_DATABASE', 
  FINGERPRINTED_GCP_CLOUD_STORAGE_BUCKET: 'FINGERPRINTED_GCP_CLOUD_STORAGE_BUCKET', 
  FINGERPRINTED_JENKINS_SERVER: 'FINGERPRINTED_JENKINS_SERVER', 
  FINGERPRINTED_MICROSOFT_EXCHANGE_SERVER: 'FINGERPRINTED_MICROSOFT_EXCHANGE_SERVER', 
  FINGERPRINTED_MONGODB_INSTANCE: 'FINGERPRINTED_MONGODB_INSTANCE', 
  FINGERPRINTED_REDIS_DATABASE: 'FINGERPRINTED_REDIS_DATABASE', 
  FINGERPRINTED_SOLR_DATABASE: 'FINGERPRINTED_SOLR_DATABASE', 
  GCP_APIGEE_HYBRID_API_PROXY: 'GCP_APIGEE_HYBRID_API_PROXY', 
  GCP_APP_ENGINE_SERVICE: 'GCP_APP_ENGINE_SERVICE', 
  GCP_CLOUD_FUNCTION: 'GCP_CLOUD_FUNCTION', 
  GCP_CLOUD_RUN_INSTANCE: 'GCP_CLOUD_RUN_INSTANCE', 
  GCP_CLOUD_SQL_INSTANCE: 'GCP_CLOUD_SQL_INSTANCE', 
  GCP_CLOUD_STORAGE_BUCKET: 'GCP_CLOUD_STORAGE_BUCKET', 
  GCP_FIREBASE_FIRESTORE_DATABASE: 'GCP_FIREBASE_FIRESTORE_DATABASE', 
  GCP_FIREBASE_FUNCTION: 'GCP_FIREBASE_FUNCTION', 
  GCP_FIREBASE_REALTIME_DATABASE: 'GCP_FIREBASE_REALTIME_DATABASE', 
  GCP_FIREBASE_STORAGE_BUCKET: 'GCP_FIREBASE_STORAGE_BUCKET', 
  GCP_KMS_KEY: 'GCP_KMS_KEY', 
  GCP_KUBERNETES_ENGINE_CLUSTER: 'GCP_KUBERNETES_ENGINE_CLUSTER', 
  GCP_SECRET_MANAGER_SECRET: 'GCP_SECRET_MANAGER_SECRET', 
  GCP_VIRTUAL_MACHINE: 'GCP_VIRTUAL_MACHINE', 
  KONG_API_BACKEND: 'KONG_API_BACKEND', 
  KONG_API_PROXY: 'KONG_API_PROXY', 
  MULESOFT_APPLICATION: 'MULESOFT_APPLICATION'
});


export type ResourceTypeEnum = $Values<typeof ResourceTypeEnumValues>;

export const AssetOrderByValues = Object.freeze({
  policy_violations_count_asc: 'policy_violations_count_asc', 
  policy_violations_count_desc: 'policy_violations_count_desc', 
  date_created_desc: 'date_created_desc', 
  date_created_asc: 'date_created_asc'
});


export type AssetOrderBy = $Values<typeof AssetOrderByValues>;

export const CloudResourceOrderByValues = Object.freeze({
  date_created_asc: 'date_created_asc', 
  date_created_desc: 'date_created_desc', 
  break_glass_order: 'break_glass_order'
});


export type CloudResourceOrderBy = $Values<typeof CloudResourceOrderByValues>;

export const MobileAppOrderByValues = Object.freeze({
  policy_violations_count_asc: 'policy_violations_count_asc', 
  policy_violations_count_desc: 'policy_violations_count_desc', 
  platform_asc: 'platform_asc', 
  platform_desc: 'platform_desc', 
  last_scanned_via_asc: 'last_scanned_via_asc', 
  last_scanned_via_desc: 'last_scanned_via_desc', 
  name_asc: 'name_asc', 
  name_desc: 'name_desc', 
  active_protection_status_asc: 'active_protection_status_asc', 
  active_protection_status_desc: 'active_protection_status_desc', 
  vulnerable_hacker_toolkits_desc: 'vulnerable_hacker_toolkits_desc', 
  vulnerable_hacker_toolkits_asc: 'vulnerable_hacker_toolkits_asc'
});


export type MobileAppOrderBy = $Values<typeof MobileAppOrderByValues>;

export const ToolkitScanTypeEnumValues = Object.freeze({
  APP_STORE_PRIVACY_GLOBAL_REPORT: 'APP_STORE_PRIVACY_GLOBAL_REPORT', 
  APP_STORE_PRIVACY_SPECIFIC_APPS_REPORT: 'APP_STORE_PRIVACY_SPECIFIC_APPS_REPORT', 
  BREAK_GLASS: 'BREAK_GLASS', 
  CLOUD_DLP: 'CLOUD_DLP', 
  CLOUD_LEAKS: 'CLOUD_LEAKS', 
  CLOUD_RANSOMWARE_ENCRYPT: 'CLOUD_RANSOMWARE_ENCRYPT', 
  CLOUD_RANSOMWARE_STORAGE_SCAN: 'CLOUD_RANSOMWARE_STORAGE_SCAN', 
  CLOUD_RANSOMWARE_UPLOAD_MALWARE: 'CLOUD_RANSOMWARE_UPLOAD_MALWARE', 
  CODE_RED: 'CODE_RED', 
  COMPLIANCE_REPORTS: 'COMPLIANCE_REPORTS', 
  DETECT_AND_INJECT: 'DETECT_AND_INJECT', 
  LEAKY_APIS: 'LEAKY_APIS', 
  WEB_APPLICATION_XSS: 'WEB_APPLICATION_XSS'
});


export type ToolkitScanTypeEnum = $Values<typeof ToolkitScanTypeEnumValues>;

export const WebApplicationXssScanIntensityEnumValues = Object.freeze({
  LOW: 'LOW', 
  MEDIUM: 'MEDIUM', 
  HIGH: 'HIGH'
});


export type WebApplicationXssScanIntensityEnum = $Values<typeof WebApplicationXssScanIntensityEnumValues>;

export const XssToolkitResultEnumValues = Object.freeze({
  VULNERABLE: 'VULNERABLE', 
  NOT_VULNERABLE: 'NOT_VULNERABLE', 
  WATCHLIST: 'WATCHLIST'
});


export type XssToolkitResultEnum = $Values<typeof XssToolkitResultEnumValues>;

export const AssetTypeEnumValues = Object.freeze({
  DOMAIN_NAME: 'DOMAIN_NAME', 
  NETWORK_SERVICE: 'NETWORK_SERVICE', 
  RESTFUL_API: 'RESTFUL_API', 
  CLOUD_RESOURCE: 'CLOUD_RESOURCE', 
  WEB_APPLICATION: 'WEB_APPLICATION', 
  GRAPHQL_API: 'GRAPHQL_API', 
  API_OPERATION: 'API_OPERATION', 
  MOBILE_APPLICATION: 'MOBILE_APPLICATION', 
  MOBILE_SDK: 'MOBILE_SDK', 
  GRPC_SERVICE: 'GRPC_SERVICE', 
  GRPC_METHOD: 'GRPC_METHOD', 
  KUBERNETES_CLUSTER: 'KUBERNETES_CLUSTER', 
  KUBERNETES_CLUSTER_COMPONENT: 'KUBERNETES_CLUSTER_COMPONENT', 
  SOAP_API: 'SOAP_API', 
  SOAP_API_OPERATION: 'SOAP_API_OPERATION'
});


export type AssetTypeEnum = $Values<typeof AssetTypeEnumValues>;

export const SharedLinksCloudResourceTypeEnumValues = Object.freeze({
  CLOUD_AUTHENTICATORS_ADD: 'CLOUD_AUTHENTICATORS_ADD', 
  SINGLE_POLICY_VIOLATION_READ: 'SINGLE_POLICY_VIOLATION_READ', 
  SINGLE_NETWORK_SERVICE_READ: 'SINGLE_NETWORK_SERVICE_READ', 
  SINGLE_RESTFUL_API_READ: 'SINGLE_RESTFUL_API_READ', 
  SINGLE_CLOUD_RESOURCE_READ: 'SINGLE_CLOUD_RESOURCE_READ', 
  SINGLE_WEB_APPLICATION_READ: 'SINGLE_WEB_APPLICATION_READ', 
  CROSS_PRODUCT_ONBOARDING: 'CROSS_PRODUCT_ONBOARDING'
});


export type SharedLinksCloudResourceTypeEnum = $Values<typeof SharedLinksCloudResourceTypeEnumValues>;

export const ApplicationLayerProtocolEnumValues = Object.freeze({
  UNKNOWN: 'UNKNOWN', 
  HTTP: 'HTTP', 
  SSH: 'SSH', 
  REDIS: 'REDIS', 
  MONGODB: 'MONGODB'
});


export type ApplicationLayerProtocolEnum = $Values<typeof ApplicationLayerProtocolEnumValues>;

export const ActiveProtectionStatusEnumValues = Object.freeze({
  PROTECTION_DISABLED: 'PROTECTION_DISABLED', 
  PROTECTION_ENABLED: 'PROTECTION_ENABLED', 
  ACTIVE_PROTECTION_ENABLED: 'ACTIVE_PROTECTION_ENABLED', 
  NO_PROTECTION: 'NO_PROTECTION'
});


export type ActiveProtectionStatusEnum = $Values<typeof ActiveProtectionStatusEnumValues>;

export const BulkActionTypeValues = Object.freeze({
  ASSETS_MOVE_TO_ASSET_GROUP: 'ASSETS_MOVE_TO_ASSET_GROUP'
});


export type BulkActionType = $Values<typeof BulkActionTypeValues>;

export const ApplePrivacyDataTypeEnumValues = Object.freeze({
  APPLE_PRIVACY_DATA_TYPE_CONTACT_INFO: 'APPLE_PRIVACY_DATA_TYPE_CONTACT_INFO', 
  APPLE_PRIVACY_DATA_TYPE_HEALTH_AND_FITNESS: 'APPLE_PRIVACY_DATA_TYPE_HEALTH_AND_FITNESS', 
  APPLE_PRIVACY_DATA_TYPE_FINANCIAL_INFO: 'APPLE_PRIVACY_DATA_TYPE_FINANCIAL_INFO', 
  APPLE_PRIVACY_DATA_TYPE_LOCATION: 'APPLE_PRIVACY_DATA_TYPE_LOCATION', 
  APPLE_PRIVACY_DATA_TYPE_SENSITIVE_INFO: 'APPLE_PRIVACY_DATA_TYPE_SENSITIVE_INFO', 
  APPLE_PRIVACY_DATA_TYPE_CONTACTS: 'APPLE_PRIVACY_DATA_TYPE_CONTACTS', 
  APPLE_PRIVACY_DATA_TYPE_USER_CONTENT: 'APPLE_PRIVACY_DATA_TYPE_USER_CONTENT', 
  APPLE_PRIVACY_DATA_TYPE_BROWSING_HISTORY: 'APPLE_PRIVACY_DATA_TYPE_BROWSING_HISTORY', 
  APPLE_PRIVACY_DATA_TYPE_SEARCH_HISTORY: 'APPLE_PRIVACY_DATA_TYPE_SEARCH_HISTORY', 
  APPLE_PRIVACY_DATA_TYPE_IDENTIFIERS: 'APPLE_PRIVACY_DATA_TYPE_IDENTIFIERS', 
  APPLE_PRIVACY_DATA_TYPE_PURCHASES: 'APPLE_PRIVACY_DATA_TYPE_PURCHASES', 
  APPLE_PRIVACY_DATA_TYPE_USAGE_DATA: 'APPLE_PRIVACY_DATA_TYPE_USAGE_DATA', 
  APPLE_PRIVACY_DATA_TYPE_DIAGNOSTICS: 'APPLE_PRIVACY_DATA_TYPE_DIAGNOSTICS', 
  APPLE_PRIVACY_DATA_TYPE_OTHER_DATA: 'APPLE_PRIVACY_DATA_TYPE_OTHER_DATA'
});


export type ApplePrivacyDataTypeEnum = $Values<typeof ApplePrivacyDataTypeEnumValues>;

export const SearchDomainNamesIncludeEnumValues = Object.freeze({
  code_red_stats: 'code_red_stats', 
  discovered_urls_stats: 'discovered_urls_stats'
});


export type SearchDomainNamesIncludeEnum = $Values<typeof SearchDomainNamesIncludeEnumValues>;

export const PostmanCheckScanStatusEnumValues = Object.freeze({
  IN_PROGRESS: 'IN_PROGRESS', 
  FAILED: 'FAILED', 
  COMPLETED: 'COMPLETED'
});


export type PostmanCheckScanStatusEnum = $Values<typeof PostmanCheckScanStatusEnumValues>;

export const PostmanOnboardingStatusEnumValues = Object.freeze({
  PENDING_POSTMAN_COLLECTION: 'PENDING_POSTMAN_COLLECTION', 
  INVALID_POSTMAN_COLLECTION: 'INVALID_POSTMAN_COLLECTION', 
  REQUESTED_BY_CUSTOMER_AND_PENDING: 'REQUESTED_BY_CUSTOMER_AND_PENDING', 
  REQUESTED_BY_CUSTOMER_AND_BLOCKED: 'REQUESTED_BY_CUSTOMER_AND_BLOCKED', 
  AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR', 
  PROCESSED: 'PROCESSED'
});


export type PostmanOnboardingStatusEnum = $Values<typeof PostmanOnboardingStatusEnumValues>;

export const PostmanPurposeEnumValues = Object.freeze({
  AUTHENTICATION_FLOW: 'AUTHENTICATION_FLOW', 
  CUSTOM_CHECK: 'CUSTOM_CHECK'
});


export type PostmanPurposeEnum = $Values<typeof PostmanPurposeEnumValues>;

export const PortalStatusDisplayStyleEnumValues = Object.freeze({
  INFO: 'INFO', 
  OK: 'OK', 
  WARNING: 'WARNING', 
  ALERT: 'ALERT'
});


export type PortalStatusDisplayStyleEnum = $Values<typeof PortalStatusDisplayStyleEnumValues>;

export const BulkActionsActionTypeEnumValues = Object.freeze({
  ASSETS_MOVE_TO_ASSET_GROUP: 'ASSETS_MOVE_TO_ASSET_GROUP', 
  ASSETS_SHADOW_ASSETS_TRIAGE: 'ASSETS_SHADOW_ASSETS_TRIAGE', 
  ASSETS_UPDATE_ASSET_TAGS: 'ASSETS_UPDATE_ASSET_TAGS', 
  POLICY_VIOLATIONS_SECURE_SHARE: 'POLICY_VIOLATIONS_SECURE_SHARE'
});


export type BulkActionsActionTypeEnum = $Values<typeof BulkActionsActionTypeEnumValues>;

export const BulkActionsStatusEnumValues = Object.freeze({
  COMPLETED: 'COMPLETED', 
  ERROR: 'ERROR', 
  ONGOING: 'ONGOING'
});


export type BulkActionsStatusEnum = $Values<typeof BulkActionsStatusEnumValues>;

export const AssetTagImportSourceEnumValues = Object.freeze({
  AWS_IMPORT: 'AWS_IMPORT', 
  GCP_IMPORT: 'GCP_IMPORT', 
  AZURE_IMPORT: 'AZURE_IMPORT'
});


export type AssetTagImportSourceEnum = $Values<typeof AssetTagImportSourceEnumValues>;

export const KnownMalwareTypeEnumValues = Object.freeze({
  STANDARD_MALWARE_TEST_FILE: 'STANDARD_MALWARE_TEST_FILE', 
  OTHER: 'OTHER'
});


export type KnownMalwareTypeEnum = $Values<typeof KnownMalwareTypeEnumValues>;

export const StanfordDishUniProductEnumValues = Object.freeze({
  API_SECURE: 'API_SECURE', 
  WEB_SECURE: 'WEB_SECURE', 
  CLOUD_SECURE: 'CLOUD_SECURE', 
  MOBILE_SECURE: 'MOBILE_SECURE', 
  SUPPLY_CHAIN_SECURE: 'SUPPLY_CHAIN_SECURE'
});


export type StanfordDishUniProductEnum = $Values<typeof StanfordDishUniProductEnumValues>;

export const StanfordDishUniUserAttendanceEnumUpdateValues = Object.freeze({
  NOT_ATTENDING: 'NOT_ATTENDING', 
  PENDING: 'PENDING', 
  ATTENDING: 'ATTENDING'
});


export type StanfordDishUniUserAttendanceEnumUpdate = $Values<typeof StanfordDishUniUserAttendanceEnumUpdateValues>;

export const StanfordDishUniUserAttendanceEnumValues = Object.freeze({
  NOT_ATTENDING: 'NOT_ATTENDING', 
  PENDING: 'PENDING', 
  ATTENDING: 'ATTENDING', 
  COMPLETED: 'COMPLETED'
});


export type StanfordDishUniUserAttendanceEnum = $Values<typeof StanfordDishUniUserAttendanceEnumValues>;

export const StanfordDishUniCategoryEnumValues = Object.freeze({
  MOBILE_APPLICATION_SECURITY: 'MOBILE_APPLICATION_SECURITY', 
  API_SECURITY: 'API_SECURITY', 
  WEB_APP_SECURITY: 'WEB_APP_SECURITY', 
  CLOUD_APP_SECURITY: 'CLOUD_APP_SECURITY', 
  SUPPLY_CHAIN_SECURITY: 'SUPPLY_CHAIN_SECURITY'
});


export type StanfordDishUniCategoryEnum = $Values<typeof StanfordDishUniCategoryEnumValues>;

export const StanfordDishUniMaterialTypeEnumValues = Object.freeze({
  VIDEO: 'VIDEO', 
  PDF: 'PDF', 
  ARTICLE: 'ARTICLE', 
  EBOOK: 'EBOOK', 
  YOUTUBE_VIDEO: 'YOUTUBE_VIDEO', 
  ZOOM_RECORDING: 'ZOOM_RECORDING', 
  GOOGLE_DRIVE_VIDEO: 'GOOGLE_DRIVE_VIDEO'
});


export type StanfordDishUniMaterialTypeEnum = $Values<typeof StanfordDishUniMaterialTypeEnumValues>;

export const StanfordDishUniUserViewingStatusEnumValues = Object.freeze({
  NOT_VIEWED: 'NOT_VIEWED', 
  VIEWED: 'VIEWED'
});


export type StanfordDishUniUserViewingStatusEnum = $Values<typeof StanfordDishUniUserViewingStatusEnumValues>;

export const AssetTypeGroupValues = Object.freeze({
  API: 'API', 
  MOBILE_SDK: 'MOBILE_SDK', 
  CLOUD_RESOURCE: 'CLOUD_RESOURCE'
});


export type AssetTypeGroup = $Values<typeof AssetTypeGroupValues>;

export const VendorListOrderByEnumValues = Object.freeze({
  number_of_apps_related_to_vendor: 'number_of_apps_related_to_vendor', 
  number_of_apps_related_to_vendor_asc: 'number_of_apps_related_to_vendor_asc', 
  number_of_apps_related_to_vendor_desc: 'number_of_apps_related_to_vendor_desc', 
  open_policy_violations_related_to_vendor: 'open_policy_violations_related_to_vendor', 
  open_policy_violations_related_to_vendor_asc: 'open_policy_violations_related_to_vendor_asc', 
  open_policy_violations_related_to_vendor_desc: 'open_policy_violations_related_to_vendor_desc', 
  assets_belonging_to_vendor: 'assets_belonging_to_vendor', 
  assets_belonging_to_vendor_asc: 'assets_belonging_to_vendor_asc', 
  assets_belonging_to_vendor_desc: 'assets_belonging_to_vendor_desc', 
  date_created_asc: 'date_created_asc', 
  date_created_desc: 'date_created_desc', 
  name_asc: 'name_asc', 
  name_desc: 'name_desc'
});


export type VendorListOrderByEnum = $Values<typeof VendorListOrderByEnumValues>;

export const VendorsListIncludeValues = Object.freeze({
  supply_chain_secure_stats: 'supply_chain_secure_stats', 
  supply_chain_secure_dashboard_metrics: 'supply_chain_secure_dashboard_metrics'
});


export type VendorsListInclude = $Values<typeof VendorsListIncludeValues>;

export const SupplyChainSecureAppTypeEnumValues = Object.freeze({
  MOBILE_APPLICATION: 'MOBILE_APPLICATION', 
  WEB_APPLICATION: 'WEB_APPLICATION'
});


export type SupplyChainSecureAppTypeEnum = $Values<typeof SupplyChainSecureAppTypeEnumValues>;

export const ThirdPartyAssetTypeGroupEnumValues = Object.freeze({
  API: 'API', 
  CLOUD_RESOURCE: 'CLOUD_RESOURCE', 
  MOBILE_SDK: 'MOBILE_SDK'
});


export type ThirdPartyAssetTypeGroupEnum = $Values<typeof ThirdPartyAssetTypeGroupEnumValues>;

export const SupplyChainSecureAppOrderByEnumValues = Object.freeze({
  NUMBER_OF_RELATED_ASSETS: 'NUMBER_OF_RELATED_ASSETS'
});


export type SupplyChainSecureAppOrderByEnum = $Values<typeof SupplyChainSecureAppOrderByEnumValues>;

/** Misc Types */
export type IacScanStatsResponse = {
  __typename: 'IacScanStatsResponse',
  file_uploads_count: $ElementType<Scalars, 'Float'>,
  completed_scans_count: $ElementType<Scalars, 'Float'>,
  total_issues_count: $ElementType<Scalars, 'Float'>,
};

export type IacScanFilterResponse = {
  __typename: 'IacScanFilterResponse',
  pagination_information: PaginationInformation,
  iac_scans?: ?Array<IacScan>,
};

export type ApiChangesStatsResponse = {
  __typename: 'ApiChangesStatsResponse',
  api_changes_stats: Array<ApiChangesStatsResponseItem>,
  api_changes_total_count: $ElementType<Scalars, 'Float'>,
};

export type ApiChangesStatsResponseItem = {
  __typename: 'ApiChangesStatsResponseItem',
  api_change_type: $ElementType<Scalars, 'String'>,
  api_change_name: $ElementType<Scalars, 'String'>,
  api_change_count: $ElementType<Scalars, 'Float'>,
  api_change_meta_type: $ElementType<Scalars, 'String'>,
};

export type IacScanGetResponse = {
  __typename: 'IacScanGetResponse',
  id: $ElementType<Scalars, 'String'>,
  status: $ElementType<Scalars, 'String'>,
  scan_type: $ElementType<Scalars, 'String'>,
  date_scanned: $ElementType<Scalars, 'Date'>,
  files_scanned: Array<?IacScanGetResponseFilesScanned>,
  issues_count: $ElementType<Scalars, 'Float'>,
  result_as_markdown: $ElementType<Scalars, 'String'>,
};

export type IacScanGetResponseFilesScanned = {
  __typename: 'IacScanGetResponseFilesScanned',
  original_file_name: $ElementType<Scalars, 'String'>,
  download_url: $ElementType<Scalars, 'String'>,
};

export type IacScan = {
  __typename: 'IacScan',
  id: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  status: $ElementType<Scalars, 'String'>,
  scan_type: $ElementType<Scalars, 'String'>,
  scanned_files_name?: ?Array<$ElementType<Scalars, 'String'>>,
  issue_count: $ElementType<Scalars, 'Float'>,
};

export type PolicyComplianceReference = {
  __typename: 'PolicyComplianceReference',
  compliance_standard: PolicyComplianceStandard,
  compliance_standard_criteria: PolicyComplianceStandardCriteria,
  description: $ElementType<Scalars, 'String'>,
  link: $ElementType<Scalars, 'String'>,
};

export type GetLargeFileUploadUrlResponse = {
  __typename: 'GetLargeFileUploadUrlResponse',
  upload_url: $ElementType<Scalars, 'String'>,
};

export type PolicyRuleConfiguration = {
  __typename: 'PolicyRuleConfiguration',
  nb_of_days_before_cert_expiration?: ?$ElementType<Scalars, 'Float'>,
  http_methods_to_scan?: ?Array<HttpMethod>,
  weekdays_when_to_scan?: ?Array<Weekday>,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  enabled_google_pii_types?: ?Array<PiiType>,
};

export type Remediation = {
  __typename: 'Remediation',
  remediation_instructions: $ElementType<Scalars, 'String'>,
  remediation_url: $ElementType<Scalars, 'String'>,
};

export type WebActivity = {
  __typename: 'WebActivity',
  base_url: $ElementType<Scalars, 'String'>,
  request_types: Array<WebRequestType>,
};

export type WebAnalysisResult = {
  __typename: 'WebAnalysisResult',
  title: $ElementType<Scalars, 'String'>,
  screenshot_url: $ElementType<Scalars, 'String'>,
  fullscreen_screenshot_url: $ElementType<Scalars, 'String'>,
  web_application_type: WebApplicationType,
  date_created: $ElementType<Scalars, 'String'>,
  date_last_received: $ElementType<Scalars, 'String'>,
  detected_libraries?: ?Array<WebLibrary>,
  detected_network_activity?: ?Array<WebActivity>,
  detected_restful_api_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
  exercised_api_operation_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type WebLibrary = {
  __typename: 'WebLibrary',
  name: $ElementType<Scalars, 'String'>,
  enum: $ElementType<Scalars, 'String'>,
  project_url: $ElementType<Scalars, 'String'>,
  icon_url: $ElementType<Scalars, 'String'>,
};

export type TlsScanAttribute = {
  __typename: 'TlsScanAttribute',
  enum: TlsScanType,
  value: $ElementType<Scalars, 'Boolean'>,
  opened_policy_violation_id?: ?$ElementType<Scalars, 'ID'>,
  opened_policy_violation?: ?PolicyViolation,
  resolved_policy_violation_id?: ?$ElementType<Scalars, 'ID'>,
  resolved_policy_violation?: ?PolicyViolation,
};

export type TlsScanResult = {
  __typename: 'TlsScanResult',
  network_service_id: $ElementType<Scalars, 'ID'>,
  tls_scan_attributes: Array<TlsScanAttribute>,
};

export type Certificate = {
  __typename: 'Certificate',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  domain_names_in_cn: Array<$ElementType<Scalars, 'String'>>,
  domain_names_in_san: Array<$ElementType<Scalars, 'String'>>,
  is_end_entity_certificate?: ?$ElementType<Scalars, 'Boolean'>,
  issuer_as_rfc4514_string: $ElementType<Scalars, 'String'>,
  not_valid_after: $ElementType<Scalars, 'Date'>,
  not_valid_before: $ElementType<Scalars, 'Date'>,
  public_key_algorithm: $ElementType<Scalars, 'String'>,
  public_key_pin: $ElementType<Scalars, 'String'>,
  public_key_size: $ElementType<Scalars, 'Float'>,
  serial_number: $ElementType<Scalars, 'String'>,
  sha1_fingerprint: $ElementType<Scalars, 'String'>,
  sha256_fingerprint: $ElementType<Scalars, 'String'>,
  signature_hash_algorithm: $ElementType<Scalars, 'String'>,
  subject_as_rfc4514_string: $ElementType<Scalars, 'String'>,
};

export type CertificateChainSecurityProperty = {
  __typename: 'CertificateChainSecurityProperty',
  enum: SecurityPropertiesEnum,
  value: $ElementType<Scalars, 'Boolean'>,
  relevance?: ?PolicyRuleTypeRelevance,
};

export type CertificateChain = {
  __typename: 'CertificateChain',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_removed?: ?$ElementType<Scalars, 'Date'>,
  deployed_on_network_service_id?: ?$ElementType<Scalars, 'ID'>,
  network_service: NetworkService,
  security_properties: Array<CertificateChainSecurityProperty>,
  certificate_ids: Array<$ElementType<Scalars, 'ID'>>,
  certificates: Array<Certificate>,
};

export type Comment = {
  __typename: 'Comment',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  content?: ?$ElementType<Scalars, 'String'>,
  related_policy_violation_id: $ElementType<Scalars, 'ID'>,
  added_by_actor_id: $ElementType<Scalars, 'ID'>,
  added_by_actor_name: $ElementType<Scalars, 'String'>,
  added_by_user_id: $ElementType<Scalars, 'ID'>,
};

/** CORE TYPES */
export type Event = {
  __typename: 'Event',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  event_type: $ElementType<Scalars, 'String'>,
  event_importance: $ElementType<Scalars, 'String'>,
  user_id?: ?$ElementType<Scalars, 'ID'>,
  user?: ?User,
  network_service_id?: ?$ElementType<Scalars, 'ID'>,
  network_service?: ?NetworkService,
  certificate_chain_id?: ?$ElementType<Scalars, 'ID'>,
  certificate_id?: ?$ElementType<Scalars, 'ID'>,
  restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  restful_api?: ?RestfulApi,
  cloud_resource_id?: ?$ElementType<Scalars, 'ID'>,
  cloud_resource?: ?CloudResource,
  apigee_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  apigee_on_prem_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  kong_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  aws_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  azure_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  axway_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  gcp_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  mulesoft_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  cloud_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  openapi_definition_id?: ?$ElementType<Scalars, 'ID'>,
  api_operation_id?: ?$ElementType<Scalars, 'ID'>,
  api_operation?: ?ApiOperation,
  comment?: ?Comment,
  comment_id?: ?$ElementType<Scalars, 'ID'>,
  policy_violation_id?: ?$ElementType<Scalars, 'ID'>,
  policy_violation?: ?PolicyViolation,
  policy_rule_id?: ?$ElementType<Scalars, 'ID'>,
  policy_id?: ?$ElementType<Scalars, 'ID'>,
  ip_range_id?: ?$ElementType<Scalars, 'ID'>,
  web_application_id?: ?$ElementType<Scalars, 'ID'>,
  web_application?: ?WebApplication,
  alerts_integration_id?: ?$ElementType<Scalars, 'ID'>,
  asset_group_id?: ?$ElementType<Scalars, 'ID'>,
  scoped_access_token_id?: ?$ElementType<Scalars, 'ID'>,
  auto_remediation_attempt_id?: ?$ElementType<Scalars, 'ID'>,
};

export type SevenhellNotificationEvent = {
  __typename: 'SevenhellNotificationEvent',
  id: $ElementType<Scalars, 'ID'>,
  title?: ?$ElementType<Scalars, 'String'>,
  event_type: SevenhellEventTypeEnum,
  icon_url: $ElementType<Scalars, 'String'>,
  detail: $ElementType<Scalars, 'String'>,
  detail_icon_url: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  portal_url: $ElementType<Scalars, 'String'>,
  subtitle?: ?$ElementType<Scalars, 'String'>,
};

export type HorizonNotificationEvent = {
  __typename: 'HorizonNotificationEvent',
  id: $ElementType<Scalars, 'ID'>,
  title?: ?$ElementType<Scalars, 'String'>,
  event_type: HorizonEventTypeEnum,
  icon_url: $ElementType<Scalars, 'String'>,
  detail: $ElementType<Scalars, 'String'>,
  detail_icon_url: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  portal_url: $ElementType<Scalars, 'String'>,
  subtitle?: ?$ElementType<Scalars, 'String'>,
};

export type ApiOperationScanTrace = {
  __typename: 'APIOperationScanTrace',
  id: $ElementType<Scalars, 'ID'>,
  api_operation_id: $ElementType<Scalars, 'ID'>,
  api_operation?: ?ApiOperation,
  url: $ElementType<Scalars, 'String'>,
  http_request: $ElementType<Scalars, 'String'>,
  http_response: $ElementType<Scalars, 'String'>,
};

export type ApiOperation = {
  __typename: 'APIOperation',
  id: $ElementType<Scalars, 'ID'>,
  path: $ElementType<Scalars, 'String'>,
  http_method: HttpMethod,
  is_authenticated?: ?$ElementType<Scalars, 'Boolean'>,
  policy_violation_ids: Array<$ElementType<Scalars, 'ID'>>,
  policy_violations: Array<PolicyViolation>,
  date_created: $ElementType<Scalars, 'Date'>,
  restful_api_id: $ElementType<Scalars, 'ID'>,
  restful_api: RestfulApi,
  restful_api_title?: ?$ElementType<Scalars, 'String'>,
  hosted_on?: ?HostedOn,
  authentication_scheme?: ?$ElementType<Scalars, 'String'>,
};

export type V2ApiOperationApiRadarStats = {
  __typename: 'V2ApiOperationApiRadarStats',
  detect_and_inject_violation_id?: ?$ElementType<Scalars, 'ID'>,
  hack_and_extract_violation_id?: ?$ElementType<Scalars, 'ID'>,
  leaky_apis_violation_id?: ?$ElementType<Scalars, 'ID'>,
  ssrf_violation_id?: ?$ElementType<Scalars, 'ID'>,
};

export type V2ApiOperation = {
  __typename: 'V2ApiOperation',
  id: $ElementType<Scalars, 'ID'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  authentication_scheme: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  http_method: $ElementType<Scalars, 'String'>,
  is_authenticated: $ElementType<Scalars, 'Boolean'>,
  open_important_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_proactive_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_urgent_policy_violations_count: $ElementType<Scalars, 'Float'>,
  path: $ElementType<Scalars, 'String'>,
  restful_api_id: $ElementType<Scalars, 'String'>,
  restful_api_title: $ElementType<Scalars, 'String'>,
  status: $ElementType<Scalars, 'String'>,
  included_api_radar_stats?: ?V2ApiOperationApiRadarStats,
};

export type SoapOperation = {
  __typename: 'SoapOperation',
  open_important_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_proactive_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_urgent_policy_violations_count: $ElementType<Scalars, 'Float'>,
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  soap_api_id: $ElementType<Scalars, 'String'>,
  soap_api_name: $ElementType<Scalars, 'String'>,
  status: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  included_api_radar_stats?: ?V2ApiOperationApiRadarStats,
};

export type GrpcOperation = {
  __typename: 'GrpcOperation',
  open_important_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_proactive_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_urgent_policy_violations_count: $ElementType<Scalars, 'Float'>,
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  grpc_service_id: $ElementType<Scalars, 'String'>,
  grpc_service_name: $ElementType<Scalars, 'String'>,
  grpc_method_type: $ElementType<Scalars, 'String'>,
  grpc_method_name: $ElementType<Scalars, 'String'>,
  grpc_method_type_display_name: $ElementType<Scalars, 'String'>,
  status: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  included_api_radar_stats?: ?V2ApiOperationApiRadarStats,
};

export type AssetGroupMembership = {
  __typename: 'AssetGroupMembership',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  membership_kind: AssetGroupMembershipKind,
  asset_group_id: $ElementType<Scalars, 'ID'>,
  created_by_user_id: $ElementType<Scalars, 'ID'>,
  restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  restful_api?: ?RestfulApi,
  web_application_id?: ?$ElementType<Scalars, 'ID'>,
  web_application?: ?WebApplication,
  cloud_resource_id?: ?$ElementType<Scalars, 'ID'>,
  cloud_resource?: ?CloudResource,
  network_service_id?: ?$ElementType<Scalars, 'ID'>,
  network_service?: ?NetworkService,
};

export type AzureCredential = {
  __typename: 'AzureCredential',
  id: $ElementType<Scalars, 'ID'>,
  tenant_id: $ElementType<Scalars, 'ID'>,
  client_id: $ElementType<Scalars, 'ID'>,
  added_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
};

export type ApigeeCredential = {
  __typename: 'ApigeeCredential',
  id: $ElementType<Scalars, 'ID'>,
  organization: $ElementType<Scalars, 'String'>,
  added_by_user_id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
};

export type ApigeeOnPremCredential = {
  __typename: 'ApigeeOnPremCredential',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  organization: $ElementType<Scalars, 'String'>,
  management_api_url?: ?$ElementType<Scalars, 'String'>,
  management_api_username?: ?$ElementType<Scalars, 'String'>,
};

export type AwsCredential = {
  __typename: 'AwsCredential',
  id: $ElementType<Scalars, 'ID'>,
  role_arn: $ElementType<Scalars, 'String'>,
  external_id: $ElementType<Scalars, 'ID'>,
  added_by_user_id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
};

export type GcpCredential = {
  __typename: 'GcpCredential',
  id: $ElementType<Scalars, 'ID'>,
  added_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  client_email?: ?$ElementType<Scalars, 'String'>,
  organization_id?: ?$ElementType<Scalars, 'String'>,
  private_key_id?: ?$ElementType<Scalars, 'String'>,
};

export type KongCredential = {
  __typename: 'KongCredential',
  id: $ElementType<Scalars, 'ID'>,
  added_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  kong_admin_subdomain?: ?$ElementType<Scalars, 'String'>,
};

export type MulesoftCredential = {
  __typename: 'MulesoftCredential',
  id: $ElementType<Scalars, 'ID'>,
  added_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  organization_id?: ?$ElementType<Scalars, 'String'>,
  account_username?: ?$ElementType<Scalars, 'String'>,
};

export type AxwayCredential = {
  __typename: 'AxwayCredential',
  id: $ElementType<Scalars, 'ID'>,
  added_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  organization_id?: ?$ElementType<Scalars, 'String'>,
  client_id?: ?$ElementType<Scalars, 'String'>,
};

export type CloudAuthenticator_Deprecated = {
  __typename: 'CloudAuthenticator_DEPRECATED',
  status: CloudAuthenticatorStatus,
  apigee_credential?: ?ApigeeCredential,
  apigee_on_prem_credential?: ?ApigeeOnPremCredential,
  aws_credential?: ?AwsCredential,
  azure_credential?: ?AzureCredential,
  axway_credential?: ?AxwayCredential,
  gcp_credential?: ?GcpCredential,
  kong_credential?: ?KongCredential,
  mulesoft_credential?: ?MulesoftCredential,
  authenticator_type?: ?CloudAuthenticatorType,
};

export type AwsCredentialSummaryField = {
  __typename: 'AwsCredentialSummaryField',
  has_policy_to_support_api_auto_protect?: ?$ElementType<Scalars, 'Boolean'>,
};

export type CloudAuthenticator = {
  __typename: 'CloudAuthenticator',
  authenticator_category: CloudAuthenticatorCategory,
  authenticator_type: CloudAuthenticatorType,
  cloud_account_id: $ElementType<Scalars, 'String'>,
  customer_supplied_name?: ?$ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  id: $ElementType<Scalars, 'ID'>,
  status: CloudAuthenticatorStatus,
  access_error_reason?: ?$ElementType<Scalars, 'String'>,
  aws_credential_field?: ?AwsCredentialSummaryField,
};

export type AlertsIntegration = {
  __typename: 'AlertsIntegration',
  id: $ElementType<Scalars, 'ID'>,
  created_by_user_id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  service_enum: AlertsIntegrationsService,
  webhook_url: $ElementType<Scalars, 'String'>,
};

export type BelongsToCloudAccount = {
  __typename: 'BelongsToCloudAccount',
  customer_supplied_name?: ?$ElementType<Scalars, 'String'>,
  discovered_via_cloud_authenticator_id: $ElementType<Scalars, 'ID'>,
  cloud_account_icon_url: $ElementType<Scalars, 'String'>,
  cloud_account_type_name: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
};

export type CloudResourceIncludedCodeRedStats = {
  __typename: 'CloudResourceIncludedCodeRedStats',
  unauthenticated_policy_violation_id?: ?$ElementType<Scalars, 'String'>,
};

export type CloudResourceIncludedCloudDlpStats = {
  __typename: 'CloudResourceIncludedCloudDlpStats',
  pii_leaking_policy_violation_id?: ?$ElementType<Scalars, 'String'>,
  pii_leaking_policy_violation_status?: ?PolicyViolationStatusEnum,
  unauthenticated_policy_violation_id?: ?$ElementType<Scalars, 'String'>,
  unauthenticated_policy_violation_status?: ?PolicyViolationStatusEnum,
};

export type CloudRansomwareStatsField = {
  __typename: 'CloudRansomwareStatsField',
  malware_detected_policy_violation_uuid?: ?$ElementType<Scalars, 'String'>,
  new_directories_since_last_week?: ?$ElementType<Scalars, 'Float'>,
  new_directories_since_last_week_percentage?: ?$ElementType<Scalars, 'Float'>,
  new_edits_since_last_week?: ?$ElementType<Scalars, 'Float'>,
  new_files_since_last_week?: ?$ElementType<Scalars, 'Float'>,
  new_files_since_last_week_percentage?: ?$ElementType<Scalars, 'Float'>,
  ransomware_detected_policy_violation_uuid?: ?$ElementType<Scalars, 'String'>,
  readable_storage_policy_violation_uuid?: ?$ElementType<Scalars, 'String'>,
  writable_storage_policy_violation_uuid?: ?$ElementType<Scalars, 'String'>,
  total_directories_count?: ?$ElementType<Scalars, 'Float'>,
  total_files_count?: ?$ElementType<Scalars, 'Float'>,
  scan_run_id?: ?$ElementType<Scalars, 'String'>,
  last_scan_date?: ?$ElementType<Scalars, 'Date'>,
};

export type CloudResourceIncludedBreakGlassStats = {
  __typename: 'CloudResourceIncludedBreakGlassStats',
  remediation_field?: ?CloudResourceIncludedBreakGlassStatsRemediationField,
  unauthenticated_policy_violation_id?: ?$ElementType<Scalars, 'String'>,
};

export type CloudResourceIncludedBreakGlassStatsRemediationField = {
  __typename: 'CloudResourceIncludedBreakGlassStatsRemediationField',
  remediation_instructions: $ElementType<Scalars, 'String'>,
  remediation_url: $ElementType<Scalars, 'String'>,
};

export type CloudResource = {
  __typename: 'CloudResource',
  id: $ElementType<Scalars, 'ID'>,
  url?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  status: CloudResourceStatus,
  date_no_longer_accessible?: ?$ElementType<Scalars, 'Date'>,
  hosted_on?: ?HostedOn,
  runtime?: ?$ElementType<Scalars, 'String'>,
  region?: ?$ElementType<Scalars, 'String'>,
  resource_type: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  policy_violation_ids: Array<$ElementType<Scalars, 'ID'>>,
  policy_violations: Array<?PolicyViolation>,
  date_created: $ElementType<Scalars, 'Date'>,
  cloud_console_url: $ElementType<Scalars, 'String'>,
  discovered_via: DiscoveredVia,
  discovered_via_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_apigee_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_aws_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_azure_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_axway_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_gcp_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_mulesoft_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_user_id?: ?$ElementType<Scalars, 'ID'>,
  aws_arn?: ?$ElementType<Scalars, 'String'>,
  gcp_project_id?: ?$ElementType<Scalars, 'ID'>,
  mulesoft_domain?: ?$ElementType<Scalars, 'String'>,
  azure_tenant_id?: ?$ElementType<Scalars, 'ID'>,
  axway_org_id?: ?$ElementType<Scalars, 'ID'>,
  kong_workspace_name?: ?$ElementType<Scalars, 'String'>,
  constituting_cloud_services: Array<CloudService>,
  asset_group_memberships: Array<AssetGroupMembership>,
  open_urgent_policy_violations_count?: ?$ElementType<Scalars, 'Float'>,
  open_important_policy_violations_count?: ?$ElementType<Scalars, 'Float'>,
  open_proactive_policy_violations_count?: ?$ElementType<Scalars, 'Float'>,
  included_code_red_stats?: ?CloudResourceIncludedCodeRedStats,
  included_cloud_dlp_stats?: ?CloudResourceIncludedCloudDlpStats,
  included_break_glass_stats?: ?CloudResourceIncludedBreakGlassStats,
  resource_type_icon_url?: ?$ElementType<Scalars, 'String'>,
  included_cloud_ransomware_stats?: ?CloudRansomwareStatsField,
};

export type WebApplicationConnectedAsset = {
  __typename: 'WebApplicationConnectedAsset',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  url: $ElementType<Scalars, 'String'>,
  asset_type: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
};

export type CloudResourceV2 = {
  __typename: 'CloudResourceV2',
  id: $ElementType<Scalars, 'ID'>,
  url?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  status: CloudResourceStatus,
  date_no_longer_accessible?: ?$ElementType<Scalars, 'Date'>,
  hosted_on?: ?HostedOn,
  runtime?: ?$ElementType<Scalars, 'String'>,
  region?: ?$ElementType<Scalars, 'String'>,
  resource_type: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  cloud_console_url?: ?$ElementType<Scalars, 'String'>,
  discovered_via: DiscoveredVia,
  aws_arn?: ?$ElementType<Scalars, 'String'>,
  open_urgent_policy_violations_count?: ?$ElementType<Scalars, 'Float'>,
  open_important_policy_violations_count?: ?$ElementType<Scalars, 'Float'>,
  open_proactive_policy_violations_count?: ?$ElementType<Scalars, 'Float'>,
  belongs_to_cloud_account?: ?BelongsToCloudAccount,
  connected_assets: Array<WebApplicationConnectedAsset>,
};

export type CloudService = {
  __typename: 'CloudService',
  cloud_service_type?: ?CloudServiceType,
  resource_name: $ElementType<Scalars, 'String'>,
  resource_id: $ElementType<Scalars, 'ID'>,
  runtime: $ElementType<Scalars, 'String'>,
  region: $ElementType<Scalars, 'String'>,
};

export type ComplianceStandardCriteria = {
  __typename: 'ComplianceStandardCriteria',
  compliance_standard: PolicyComplianceStandard,
  criterion: PolicyComplianceStandardCriteria,
  title: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  link?: ?$ElementType<Scalars, 'String'>,
  referenced_by_policy_rule_type_ids: Array<$ElementType<Scalars, 'String'>>,
};

export type IncludedCodeRedStats = {
  __typename: 'IncludedCodeRedStats',
  fingerprinted_resources_count?: ?$ElementType<Scalars, 'Float'>,
  unauthenticated_fingerprinted_resources_count?: ?$ElementType<Scalars, 'Float'>,
};

export type DiscoveredUrlsStats = {
  __typename: 'DiscoveredUrlsStats',
  discovered_urls_count: $ElementType<Scalars, 'Float'>,
  subdomains_count?: ?$ElementType<Scalars, 'Float'>,
};

export type DomainName = {
  __typename: 'DomainName',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  discovered_via: DiscoveredVia,
  discovered_via_aws_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_user_id?: ?$ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  asset_type_icon_url?: ?$ElementType<Scalars, 'String'>,
  asset_type_name?: ?$ElementType<Scalars, 'String'>,
  included_code_red_stats?: ?IncludedCodeRedStats,
  included_discovered_urls_stats?: ?DiscoveredUrlsStats,
};

export type DomainNameDiscoveredUrl = {
  __typename: 'DomainNameDiscoveredUrl',
  normalized_url: $ElementType<Scalars, 'String'>,
};

export type GraphqlApi = {
  __typename: 'GraphqlApi',
  asset_group_memberships: Array<AssetGroupMembership>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  aws_arn?: ?$ElementType<Scalars, 'String'>,
  azure_tenant_id?: ?$ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  discovered_via: DiscoveredVia,
  discovered_via_actor_id?: ?$ElementType<Scalars, 'String'>,
  discovered_via_apigee_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_apigee_on_prem_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_aws_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_axway_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_azure_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_cloud_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_gcp_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_kong_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_mulesoft_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_user_id?: ?$ElementType<Scalars, 'ID'>,
  gcp_project_id?: ?$ElementType<Scalars, 'ID'>,
  hosted_on: HostedOn,
  id: $ElementType<Scalars, 'ID'>,
  mulesoft_asset_id?: ?$ElementType<Scalars, 'ID'>,
  network_service_id?: ?$ElementType<Scalars, 'ID'>,
  policy_violation_ids: Array<$ElementType<Scalars, 'ID'>>,
  status: GraphQlApiStatusEnum,
  url: $ElementType<Scalars, 'String'>,
  graphql_schema?: ?$ElementType<Scalars, 'String'>,
};

export type NetworkServiceConnectivityScanResult = {
  __typename: 'NetworkServiceConnectivityScanResult',
  status: NetworkServiceConnectivityScanStatusEnum,
  date_created: $ElementType<Scalars, 'Date'>,
};

export type NetworkServiceV2Result = {
  __typename: 'NetworkServiceV2Result',
  id: $ElementType<Scalars, 'ID'>,
  base_url: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
};

export type NetworkService = {
  __typename: 'NetworkService',
  id: $ElementType<Scalars, 'ID'>,
  domain_name_id: $ElementType<Scalars, 'ID'>,
  domain_name: DomainName,
  port: $ElementType<Scalars, 'Float'>,
  url: $ElementType<Scalars, 'String'>,
  status: NetworkServiceStatusEnum,
  application_layer_protocol: ApplicationLayerProtocol,
  is_tls_encrypted: $ElementType<Scalars, 'Boolean'>,
  hosted_on?: ?HostedOn,
  date_no_longer_accessible?: ?$ElementType<Scalars, 'Date'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  connectivity_scan_results: Array<NetworkServiceConnectivityScanResult>,
  discovered_via: DiscoveredVia,
  discovered_via_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_user_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_user?: ?User,
  date_created: $ElementType<Scalars, 'Date'>,
  policy_violation_ids: Array<$ElementType<Scalars, 'ID'>>,
  policy_violations: Array<PolicyViolation>,
  constituting_cloud_services: Array<CloudService>,
  asset_group_memberships: Array<AssetGroupMembership>,
};

export type Policy = {
  __typename: 'Policy',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  created_by_actor_id: $ElementType<Scalars, 'ID'>,
  created_by_user_id: $ElementType<Scalars, 'ID'>,
  created_by_user: HorizonUserField,
  created_by_actor_name: $ElementType<Scalars, 'String'>,
  is_special_dt_policy: $ElementType<Scalars, 'Boolean'>,
  date_created: $ElementType<Scalars, 'Date'>,
  protected_asset_groups_count: $ElementType<Scalars, 'Float'>,
  enabled_policy_rules_count: $ElementType<Scalars, 'Float'>,
  policy_rule_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type PolicyRule = {
  __typename: 'PolicyRule',
  id: $ElementType<Scalars, 'ID'>,
  belongs_to_policy_id: $ElementType<Scalars, 'ID'>,
  policy: Policy,
  belongs_to_policy_name: $ElementType<Scalars, 'String'>,
  created_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  created_by_actor_id: $ElementType<Scalars, 'ID'>,
  created_by_actor_name: $ElementType<Scalars, 'String'>,
  deleted_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  deleted_by_actor_id?: ?$ElementType<Scalars, 'ID'>,
  deleted_by_actor_name?: ?$ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  policy_rule_type_id: $ElementType<Scalars, 'ID'>,
  policy_rule_type: PolicyRuleType,
  deletion_explanation?: ?$ElementType<Scalars, 'String'>,
  relevance: PolicyRuleTypeRelevance,
  configuration?: ?PolicyRuleConfiguration,
  has_all_alerts_muted: $ElementType<Scalars, 'Boolean'>,
  has_comment_created_alerts_enabled: $ElementType<Scalars, 'Boolean'>,
  has_violation_created_alerts_enabled: $ElementType<Scalars, 'Boolean'>,
  has_violation_exception_added_alerts_enabled: $ElementType<Scalars, 'Boolean'>,
  open_policy_violations_count: $ElementType<Scalars, 'Float'>,
  resolved_policy_violations_count: $ElementType<Scalars, 'Float'>,
};

export type PolicyRuleV2 = {
  __typename: 'PolicyRuleV2',
  id: $ElementType<Scalars, 'ID'>,
  policy_rule_type: PolicyRuleType,
  relevance: PolicyRuleTypeRelevance,
  severity?: ?$ElementType<Scalars, 'String'>,
};

export type PolicyRuleType = {
  __typename: 'PolicyRuleType',
  id: $ElementType<Scalars, 'ID'>,
  title: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  recommendation?: ?$ElementType<Scalars, 'String'>,
  secure_code?: ?$ElementType<Scalars, 'String'>,
  default_relevance?: ?PolicyRuleTypeRelevance,
  configuration_default_value?: ?PolicyRuleConfiguration,
  requires_configuration?: ?$ElementType<Scalars, 'Boolean'>,
  compliance_policy_references: Array<PolicyComplianceReference>,
  group?: ?PolicyRuleGroup,
  related_rule_types?: ?Array<PolicyRuleTypeRelatedRuleType>,
};

export type ImportanceTagType = {
  __typename: 'ImportanceTagType',
  background_color: $ElementType<Scalars, 'String'>,
  icon_url: $ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
};

export type PolicyRuleTypeV2 = {
  __typename: 'PolicyRuleTypeV2',
  id: $ElementType<Scalars, 'ID'>,
  title: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  recommendation?: ?$ElementType<Scalars, 'String'>,
  default_relevance?: ?PolicyRuleTypeRelevance,
  configuration_default_value?: ?PolicyRuleConfiguration,
  requires_configuration?: ?$ElementType<Scalars, 'Boolean'>,
  compliance_policy_references: Array<PolicyComplianceReference>,
  group?: ?PolicyRuleGroup,
  related_rule_types?: ?Array<PolicyRuleTypeRelatedRuleType>,
  policy_rule?: ?PolicyRuleTypeV2PolicyRule,
  headliner_hack_titles?: ?Array<$ElementType<Scalars, 'String'>>,
  importance_tags?: ?Array<?ImportanceTagType>,
};

export type PolicyRuleTypeV2PolicyRule = {
  __typename: 'PolicyRuleTypeV2PolicyRule',
  id: $ElementType<Scalars, 'ID'>,
  configuration?: ?PolicyRuleConfiguration,
  date_created: $ElementType<Scalars, 'Date'>,
  open_policy_violations_count: $ElementType<Scalars, 'Float'>,
  relevance: PolicyRuleTypeRelevance,
  resolved_policy_violations_count: $ElementType<Scalars, 'Float'>,
};

export type PolicyRuleTypeRelatedRuleType = {
  __typename: 'PolicyRuleTypeRelatedRuleType',
  id: $ElementType<Scalars, 'ID'>,
  title: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  recommendation?: ?$ElementType<Scalars, 'String'>,
  default_relevance?: ?PolicyRuleTypeRelevance,
  configuration_default_value?: ?PolicyRuleConfiguration,
  requires_configuration?: ?$ElementType<Scalars, 'Boolean'>,
  compliance_policy_references: Array<PolicyComplianceReference>,
  group?: ?PolicyRuleGroup,
};

export type PolicyViolationExportToJira = {
  __typename: 'PolicyViolationExportToJira',
  external_key: $ElementType<Scalars, 'String'>,
  external_url: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  integration_icon_url: $ElementType<Scalars, 'String'>,
  integration_type: $ElementType<Scalars, 'String'>,
};

export type PolicyViolation = {
  __typename: 'PolicyViolation',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  violated_policy_rule_id: $ElementType<Scalars, 'ID'>,
  violated_policy_rule: PolicyRule,
  date_resolved?: ?$ElementType<Scalars, 'Date'>,
  status: PolicyViolationStatusEnum,
  additional_info?: ?$ElementType<Scalars, 'String'>,
  opened_by_certificate_chain_id?: ?$ElementType<Scalars, 'ID'>,
  exception_type?: ?PolicyViolationException,
  exception_explanation?: ?$ElementType<Scalars, 'String'>,
  exception_date_created?: ?$ElementType<Scalars, 'Date'>,
  exception_added_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  exception_added_by_user?: ?User,
  affected_network_service_id?: ?$ElementType<Scalars, 'ID'>,
  affected_network_service?: ?NetworkService,
  affected_api_operation_id?: ?$ElementType<Scalars, 'ID'>,
  affected_api_operation?: ?ApiOperation,
  affected_cloud_resource_id?: ?$ElementType<Scalars, 'ID'>,
  affected_cloud_resource?: ?CloudResource,
  affected_graphql_api_id?: ?$ElementType<Scalars, 'ID'>,
  affected_graphql_api?: ?GraphqlApi,
  affected_web_application_id?: ?$ElementType<Scalars, 'ID'>,
  affected_web_application?: ?WebApplication,
  remediation?: ?Remediation,
  affected_restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  affected_restful_api?: ?RestfulApi,
};

export type PolicyViolationAffectedAsset = {
  __typename: 'PolicyViolationAffectedAsset',
  id: $ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  cloud_console_url?: ?$ElementType<Scalars, 'String'>,
  url?: ?$ElementType<Scalars, 'String'>,
  asset_type: AssetTypeEnum,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  hosted_on?: ?HostedOn,
  aws_arn?: ?$ElementType<Scalars, 'String'>,
  belongs_to_asset_group?: ?AssetGroupField,
  belongs_to_cloud_account?: ?BelongsToCloudAccount,
  api_operation_authentication_scheme?: ?$ElementType<Scalars, 'String'>,
};

export type AwsAuthenticatorExternalIdResponse = {
  __typename: 'AwsAuthenticatorExternalIdResponse',
  external_id: $ElementType<Scalars, 'String'>,
};

export type PolicyViolationV2HttpTrace = {
  __typename: 'PolicyViolationV2HttpTrace',
  http_request: $ElementType<Scalars, 'String'>,
  http_response: $ElementType<Scalars, 'String'>,
};

export type PolicyViolationV2ExternalIntegration = {
  __typename: 'PolicyViolationV2ExternalIntegration',
  id: $ElementType<Scalars, 'ID'>,
  external_key: $ElementType<Scalars, 'String'>,
  external_url: $ElementType<Scalars, 'String'>,
  integration_icon_url: $ElementType<Scalars, 'String'>,
  integration_type: $ElementType<Scalars, 'String'>,
};

export type PolicyViolationV2 = {
  __typename: 'PolicyViolationV2',
  id: $ElementType<Scalars, 'ID'>,
  affected_asset: PolicyViolationAffectedAsset,
  affected_asset_id?: ?$ElementType<Scalars, 'String'>,
  affected_asset_name: $ElementType<Scalars, 'String'>,
  affected_asset_type: AssetTypeEnum,
  affected_asset_type_name: $ElementType<Scalars, 'String'>,
  affected_asset_type_icon_url?: ?$ElementType<Scalars, 'String'>,
  affected_asset_hosted_on?: ?HostedOn,
  date_created: $ElementType<Scalars, 'Date'>,
  date_resolved?: ?$ElementType<Scalars, 'Date'>,
  date_last_checked?: ?$ElementType<Scalars, 'Date'>,
  status: PolicyViolationStatusEnum,
  additional_info?: ?$ElementType<Scalars, 'String'>,
  opened_by_certificate_chain_id?: ?$ElementType<Scalars, 'ID'>,
  exception_type?: ?PolicyViolationException,
  exception_explanation?: ?$ElementType<Scalars, 'String'>,
  exception_date_created?: ?$ElementType<Scalars, 'Date'>,
  custom_description?: ?$ElementType<Scalars, 'String'>,
  custom_recommendation?: ?$ElementType<Scalars, 'String'>,
  custom_secure_code?: ?$ElementType<Scalars, 'String'>,
  affected_asset_details?: ?$ElementType<Scalars, 'String'>,
  http_trace?: ?PolicyViolationV2HttpTrace,
  included_http_trace?: ?PolicyViolationV2HttpTrace,
  auto_remediation_info?: ?Remediation,
  violated_policy_rule: PolicyRuleV2,
  external_integrations?: ?Array<?PolicyViolationV2ExternalIntegration>,
};

export type RestfulApi = {
  __typename: 'RestfulAPI',
  id: $ElementType<Scalars, 'ID'>,
  network_service_id: $ElementType<Scalars, 'ID'>,
  network_service: NetworkService,
  openapi_definition_id: $ElementType<Scalars, 'ID'>,
  title: $ElementType<Scalars, 'String'>,
  base_url: $ElementType<Scalars, 'String'>,
  status: RestfulApiStatusEnum,
  asset_type_name: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  base_path: $ElementType<Scalars, 'String'>,
  api_operations?: ?Array<ApiOperation>,
  discovered_via: DiscoveredVia,
  discovered_via_aws_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_user_id?: ?$ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_no_longer_accessible?: ?$ElementType<Scalars, 'Date'>,
  integrated_cloud_resource_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
  integrated_cloud_resources?: ?Array<CloudResource>,
  constituting_cloud_services: Array<CloudService>,
  asset_group_memberships: Array<AssetGroupMembership>,
  open_important_policy_violations_count?: ?$ElementType<Scalars, 'Float'>,
  open_proactive_policy_violations_count?: ?$ElementType<Scalars, 'Float'>,
  open_urgent_policy_violations_count?: ?$ElementType<Scalars, 'Float'>,
};

export type User = {
  __typename: 'User',
  allowed_app_ids?: ?Array<?$ElementType<Scalars, 'ID'>>,
  auth_strategy: AuthStrategy,
  can_access_app_search: $ElementType<Scalars, 'Boolean'>,
  can_access_app_secure: $ElementType<Scalars, 'Boolean'>,
  can_access_api_inspect: $ElementType<Scalars, 'Boolean'>,
  can_access_brand_protect: $ElementType<Scalars, 'Boolean'>,
  can_invite_users: $ElementType<Scalars, 'Boolean'>,
  can_download_reports?: ?$ElementType<Scalars, 'Boolean'>,
  can_access_vendor_supply_chain_security: $ElementType<Scalars, 'Boolean'>,
  date_created?: ?$ElementType<Scalars, 'String'>,
  date_updated?: ?$ElementType<Scalars, 'String'>,
  first_name: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  last_login_date?: ?$ElementType<Scalars, 'String'>,
  last_name: $ElementType<Scalars, 'String'>,
  login_email: $ElementType<Scalars, 'String'>,
  notification_email?: ?$ElementType<Scalars, 'String'>,
  renewal_reminder_last_dismissed?: ?$ElementType<Scalars, 'String'>,
  role: Role,
  effective_permissions: UserEffectivePermissions,
};

export type UserEffectivePermissions = {
  __typename: 'UserEffectivePermissions',
  can_access_all_asset_groups: $ElementType<Scalars, 'Boolean'>,
  can_access_all_mobile_apps: $ElementType<Scalars, 'Boolean'>,
  can_access_api_secure: $ElementType<Scalars, 'Boolean'>,
  can_access_asset_group_ids: Array<?$ElementType<Scalars, 'String'>>,
  can_access_cloud_secure: $ElementType<Scalars, 'Boolean'>,
  can_access_mobile_app_ids: Array<?$ElementType<Scalars, 'String'>>,
  can_access_mobile_phishing: $ElementType<Scalars, 'Boolean'>,
  can_access_mobile_secure: $ElementType<Scalars, 'Boolean'>,
  can_access_supply_chain_secure: $ElementType<Scalars, 'Boolean'>,
  can_access_web_secure: $ElementType<Scalars, 'Boolean'>,
};

export type HorizonUserField = {
  __typename: 'HorizonUserField',
  date_created?: ?$ElementType<Scalars, 'String'>,
  first_name: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  last_name: $ElementType<Scalars, 'String'>,
  login_email: $ElementType<Scalars, 'String'>,
  notification_email?: ?$ElementType<Scalars, 'String'>,
};

export type WebApplication = {
  __typename: 'WebApplication',
  id: $ElementType<Scalars, 'ID'>,
  base_url: $ElementType<Scalars, 'String'>,
  base_path: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_no_longer_accessible?: ?$ElementType<Scalars, 'Date'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  network_service_id: $ElementType<Scalars, 'ID'>,
  status: WebApplicationStatusEnum,
  network_service: NetworkService,
  most_recent_analysis_result: WebAnalysisResult,
  discovered_via: DiscoveredVia,
  discovered_via_aws_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_gcp_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_azure_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_axway_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_mulesoft_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_apigee_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_user_id?: ?$ElementType<Scalars, 'ID'>,
  policy_violation_ids: Array<$ElementType<Scalars, 'ID'>>,
  policy_violations: Array<PolicyViolation>,
  asset_group_memberships: Array<AssetGroupMembership>,
  open_important_policy_violations_count?: ?$ElementType<Scalars, 'Float'>,
  open_proactive_policy_violations_count?: ?$ElementType<Scalars, 'Float'>,
  open_urgent_policy_violations_count?: ?$ElementType<Scalars, 'Float'>,
};

export type WebApplicationVersion2 = {
  __typename: 'WebApplicationVersion2',
  id: $ElementType<Scalars, 'ID'>,
  base_url: $ElementType<Scalars, 'String'>,
  base_path: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  network_service_id: $ElementType<Scalars, 'ID'>,
  status: WebApplicationStatusEnum,
  title: $ElementType<Scalars, 'String'>,
  screenshot_url: $ElementType<Scalars, 'String'>,
  hosted_on?: ?HostedOn,
  discovered_via: DiscoveredVia,
  policy_violation_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
  included_detected_spa_credentials_stats?: ?WebAppCredentialsStat,
  included_connected_assets_stats?: ?WebAppConnectedAssetsStats,
  included_xss_toolkit_stats?: ?WebAppXssToolkitStats,
  included_web_app_protection_stats?: ?WebAppProtectionStats,
  open_important_policy_violations_count?: ?$ElementType<Scalars, 'Float'>,
  open_proactive_policy_violations_count?: ?$ElementType<Scalars, 'Float'>,
  open_urgent_policy_violations_count?: ?$ElementType<Scalars, 'Float'>,
};

export type AutoRemediationAttempt = {
  __typename: 'AutoRemediationAttempt',
  id: $ElementType<Scalars, 'ID'>,
  status: AutoRemediationAttemptStatus,
  policy_violation_id: $ElementType<Scalars, 'ID'>,
  affected_cloud_resource_arn?: ?$ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  created_by_user_id: $ElementType<Scalars, 'ID'>,
};

export type AutoRemediationAttemptBatch = {
  __typename: 'AutoRemediationAttemptBatch',
  id: $ElementType<Scalars, 'ID'>,
  created_by_user_id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  auto_remediation_attempts: Array<AutoRemediationAttempt>,
  url: $ElementType<Scalars, 'String'>,
};

export type IpRange = {
  __typename: 'IpRange',
  id: $ElementType<Scalars, 'ID'>,
  has_continuous_discovery_enabled: $ElementType<Scalars, 'Boolean'>,
  ip_range: $ElementType<Scalars, 'String'>,
};

export type IpRangeScanResult = {
  __typename: 'IpRangeScanResult',
  ip_address: $ElementType<Scalars, 'String'>,
  online_network_service_urls: Array<$ElementType<Scalars, 'String'>>,
};

export type IpRangeScan = {
  __typename: 'IpRangeScan',
  ip_address_scan_results: Array<IpRangeScanResult>,
  ip_range: $ElementType<Scalars, 'String'>,
  ip_range_id?: ?$ElementType<Scalars, 'ID'>,
  title?: ?Array<?$ElementType<Scalars, 'String'>>,
};

export type OpenApiDefinition = {
  __typename: 'OpenApiDefinition',
  id: $ElementType<Scalars, 'ID'>,
  title: $ElementType<Scalars, 'String'>,
  discovered_via: DiscoveredVia,
  discovered_via_aws_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_user_id?: ?$ElementType<Scalars, 'ID'>,
  original_document: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
};

export type ShadowAssetsTriageActionsResponse = {
  __typename: 'ShadowAssetsTriageActionsResponse',
  action_type?: ?BulkActionsActionTypeEnum,
  status?: ?BulkActionsStatusEnum,
};

export type BulkAssetMoveToAssetGroup = {
  __typename: 'BulkAssetMoveToAssetGroup',
  action_type?: ?BulkActionsActionTypeEnum,
  completed_tasks_count: $ElementType<Scalars, 'Float'>,
  date_completed?: ?$ElementType<Scalars, 'Date'>,
  date_created: $ElementType<Scalars, 'Date'>,
  id: $ElementType<Scalars, 'ID'>,
  move_to_group_result?: ?$ElementType<Scalars, 'String'>,
  secure_share_policy_violations_result?: ?Array<$ElementType<Scalars, 'ID'>>,
  status?: ?BulkActionsStatusEnum,
  total_tasks_count: $ElementType<Scalars, 'Float'>,
};

export type NamedFilterItem = {
  __typename: 'NamedFilterItem',
  name: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  group?: ?$ElementType<Scalars, 'String'>,
};

export type InventoryV2KeywordSearchValuesResponse = {
  __typename: 'InventoryV2KeywordSearchValuesResponse',
  assets_named_filters: Array<NamedFilterItem>,
};

export type ApiAssetsChangesResponseItem = {
  __typename: 'ApiAssetsChangesResponseItem',
  api_change_type: $ElementType<Scalars, 'String'>,
  api_change_name: $ElementType<Scalars, 'String'>,
  api_change_description: $ElementType<Scalars, 'String'>,
  api_change_icon_url: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'String'>,
  asset_name: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_url: $ElementType<Scalars, 'String'>,
  http_method?: ?$ElementType<Scalars, 'String'>,
  path?: ?$ElementType<Scalars, 'String'>,
  api_authentication_scheme_names?: ?Array<?$ElementType<Scalars, 'String'>>,
  detected_pii_types?: ?Array<?$ElementType<Scalars, 'String'>>,
  is_internet_accessible: $ElementType<Scalars, 'Boolean'>,
  is_compliant_to_security_policy: $ElementType<Scalars, 'Boolean'>,
  issues_count?: ?$ElementType<Scalars, 'Float'>,
  discovered_via: $ElementType<Scalars, 'String'>,
  discovered_via_icon_url: $ElementType<Scalars, 'String'>,
  discovered_via_name: $ElementType<Scalars, 'String'>,
};

export type ApiAssetsChangesResponseStats = {
  __typename: 'ApiAssetsChangesResponseStats',
  last_year_changes_count: $ElementType<Scalars, 'Float'>,
  last_30_days_changes_count: $ElementType<Scalars, 'Float'>,
  last_7_days_changes_count: $ElementType<Scalars, 'Float'>,
  last_30_days_changes_with_issues_count: $ElementType<Scalars, 'Float'>,
  changes_with_issues_count: $ElementType<Scalars, 'Float'>,
};

export type ProtectRestfulApisRequestsResponseItem = {
  __typename: 'ProtectRestfulApisRequestsResponseItem',
  id: $ElementType<Scalars, 'String'>,
  date: $ElementType<Scalars, 'String'>,
  event_type_names: Array<?$ElementType<Scalars, 'String'>>,
  source: SecurityEventsResponseSource,
  path: $ElementType<Scalars, 'String'>,
  method: $ElementType<Scalars, 'String'>,
  user_agent?: ?$ElementType<Scalars, 'String'>,
  content_type?: ?$ElementType<Scalars, 'String'>,
  request_size: $ElementType<Scalars, 'String'>,
  response_status_code?: ?$ElementType<Scalars, 'String'>,
};

export type ProtectRestfulApisRequestsResponse = {
  __typename: 'ProtectRestfulApisRequestsResponse',
  pagination_information: PaginationInformation,
  requests: Array<ProtectRestfulApisRequestsResponseItem>,
};

export type SastStatsResponse = {
  __typename: 'SastStatsResponse',
  first_party_issues_count: $ElementType<Scalars, 'Float'>,
  sca_issues_count: $ElementType<Scalars, 'Float'>,
  total_code_lines_count: $ElementType<Scalars, 'Float'>,
  total_dependencies_count: $ElementType<Scalars, 'Float'>,
  total_issues_count: $ElementType<Scalars, 'Float'>,
  total_repositories_count: $ElementType<Scalars, 'Float'>,
  total_fixed_issues_count: $ElementType<Scalars, 'Float'>,
};

export type SastFilesWithIssuesResponseItem = {
  __typename: 'SastFilesWithIssuesResponseItem',
  detected_file_type: $ElementType<Scalars, 'String'>,
  detected_file_type_icon_url: $ElementType<Scalars, 'String'>,
  file_name: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  total_issues_count?: ?$ElementType<Scalars, 'Float'>,
};

export type SastRepositoryIssuesIssueByIdResponse = {
  __typename: 'SastRepositoryIssuesIssueByIdResponse',
  issue_type_display_name?: ?$ElementType<Scalars, 'String'>,
  detected_in_branch_icon_url: $ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  repository_name?: ?$ElementType<Scalars, 'String'>,
  detected_in_file_icon_url: $ElementType<Scalars, 'String'>,
  detected_in_file_name: $ElementType<Scalars, 'String'>,
  detected_in_file_path: $ElementType<Scalars, 'String'>,
  repository_html_url: $ElementType<Scalars, 'String'>,
  repository_platform: $ElementType<Scalars, 'String'>,
  repository_platform_display_name: $ElementType<Scalars, 'String'>,
  repository_platform_icon_url: $ElementType<Scalars, 'String'>,
  discovered_date: $ElementType<Scalars, 'String'>,
  detected_in_branch_name: $ElementType<Scalars, 'String'>,
  detected_in_branch_url: $ElementType<Scalars, 'String'>,
  issue_type: $ElementType<Scalars, 'String'>,
  issue_code_snippet: $ElementType<Scalars, 'String'>,
  issue_description: $ElementType<Scalars, 'String'>,
  issue_title: $ElementType<Scalars, 'String'>,
  detected_on_line: $ElementType<Scalars, 'Float'>,
  id: $ElementType<Scalars, 'String'>,
  see_issue_in_platform_url: $ElementType<Scalars, 'String'>,
  severity: $ElementType<Scalars, 'String'>,
};

export type SastRepositoryIssuesResponseItem = {
  __typename: 'SastRepositoryIssuesResponseItem',
  issue_type_display_name?: ?$ElementType<Scalars, 'String'>,
  detected_in_branch_name?: ?$ElementType<Scalars, 'String'>,
  detected_in_branch_url?: ?$ElementType<Scalars, 'String'>,
  detected_on_line?: ?$ElementType<Scalars, 'Float'>,
  issue_description?: ?$ElementType<Scalars, 'String'>,
  issue_title?: ?$ElementType<Scalars, 'String'>,
  see_issue_in_platform_url?: ?$ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  discovered_date: $ElementType<Scalars, 'String'>,
  issue_type: $ElementType<Scalars, 'String'>,
  issue_code_snippet: $ElementType<Scalars, 'String'>,
  severity: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
};

export type SastRepositoryIssuesResponse = {
  __typename: 'SastRepositoryIssuesResponse',
  pagination_information: PaginationInformation,
  issues: Array<SastRepositoryIssuesResponseItem>,
};

export type SastFilesWithIssuesResponse = {
  __typename: 'SastFilesWithIssuesResponse',
  pagination_information: PaginationInformation,
  files_with_issues: Array<SastFilesWithIssuesResponseItem>,
};

export type SastListRepositoriesResponseItem = {
  __typename: 'SastListRepositoriesResponseItem',
  dependencies_count?: ?$ElementType<Scalars, 'Float'>,
  files_with_issues_count?: ?$ElementType<Scalars, 'Float'>,
  first_party_issues_count?: ?$ElementType<Scalars, 'Float'>,
  sca_issues_count?: ?$ElementType<Scalars, 'Float'>,
  total_code_lines_count?: ?$ElementType<Scalars, 'Float'>,
  total_fixed_issues_count?: ?$ElementType<Scalars, 'Float'>,
  total_issues_count?: ?$ElementType<Scalars, 'Float'>,
  visibility: $ElementType<Scalars, 'String'>,
  visibility_display_name: $ElementType<Scalars, 'String'>,
  platform: $ElementType<Scalars, 'String'>,
  platform_display_name: $ElementType<Scalars, 'String'>,
  platform_icon_url: $ElementType<Scalars, 'String'>,
  full_name: $ElementType<Scalars, 'String'>,
  html_url: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
};

export type SastListRepositoriesResponse = {
  __typename: 'SastListRepositoriesResponse',
  pagination_information: PaginationInformation,
  repositories: Array<SastListRepositoriesResponseItem>,
};

export type ApiAssetsChangesResponse = {
  __typename: 'ApiAssetsChangesResponse',
  pagination_information: PaginationInformation,
  api_changes: Array<?ApiAssetsChangesResponseItem>,
  included_api_changes_stats: ApiAssetsChangesResponseStats,
};

export type ProtectApiOperationsResponseItem = {
  __typename: 'ProtectApiOperationsResponseItem',
  http_method: $ElementType<Scalars, 'String'>,
  path: $ElementType<Scalars, 'String'>,
  inbound_requests_count: $ElementType<Scalars, 'Float'>,
  attacks_detected_count: $ElementType<Scalars, 'Float'>,
  attacks_blocked_count: $ElementType<Scalars, 'Float'>,
};

export type ProtectApiOperationsResponse = {
  __typename: 'ProtectApiOperationsResponse',
  pagination_information: PaginationInformation,
  api_operations: Array<ProtectApiOperationsResponseItem>,
};

export type SecurityFindingsNamedFiltersResponse = {
  __typename: 'SecurityFindingsNamedFiltersResponse',
  security_findings_named_filters: Array<MobileProtectNamedFilterItem>,
};

export type SastNamedFiltersResponse = {
  __typename: 'SastNamedFiltersResponse',
  repositories_findings_named_filters: Array<MobileProtectNamedFilterItem>,
};

export type MobileApplicationNamedFiltersResponse = {
  __typename: 'MobileApplicationNamedFiltersResponse',
  mobile_applications_named_filters: Array<MobileProtectNamedFilterItem>,
};

export type SecurityFindingsNamedFiltersChildren = {
  __typename: 'SecurityFindingsNamedFiltersChildren',
  children?: ?Array<?SecurityFindingsNamedFiltersChildren>,
  has_children?: ?$ElementType<Scalars, 'Boolean'>,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  value?: ?$ElementType<Scalars, 'String'>,
};

export type SecurityFindingsNamedFiltersChildrenResponse = {
  __typename: 'SecurityFindingsNamedFiltersChildrenResponse',
  pagination_information: PaginationInformation,
  children: Array<?SecurityFindingsNamedFiltersChildren>,
};

export type SastNamedFiltersChildrenResponse = {
  __typename: 'SastNamedFiltersChildrenResponse',
  pagination_information: PaginationInformation,
  children: Array<?SecurityFindingsNamedFiltersChildren>,
};

export type AssetsNamedFiltersResponse = {
  __typename: 'AssetsNamedFiltersResponse',
  assets_named_filters: Array<NamedFilterItem>,
};

export type MobileProtectNamedFilterItemChildrenItem = {
  __typename: 'MobileProtectNamedFilterItemChildrenItem',
  name: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  has_data?: ?$ElementType<Scalars, 'Boolean'>,
};

export type MobileProtectNamedFilterItem = {
  __typename: 'MobileProtectNamedFilterItem',
  name: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  has_children?: ?$ElementType<Scalars, 'Boolean'>,
  children?: ?Array<MobileProtectNamedFilterItemChildrenItem>,
};

export type MobileProtectNamedFiltersResponse = {
  __typename: 'MobileProtectNamedFiltersResponse',
  named_filters: Array<MobileProtectNamedFilterItem>,
};

export type ShadowAssetsStatsResponseItem = {
  __typename: 'ShadowAssetsStatsResponseItem',
  shadow_reason: $ElementType<Scalars, 'String'>,
  shadow_reason_name: $ElementType<Scalars, 'String'>,
  shadow_assets_count: $ElementType<Scalars, 'Float'>,
};

export type ApisAnalysisGlobalDataPointItem = {
  __typename: 'ApisAnalysisGlobalDataPointItem',
  datapoint_name: $ElementType<Scalars, 'String'>,
  datapoint_count: $ElementType<Scalars, 'Float'>,
  secondary_datapoint_count?: ?$ElementType<Scalars, 'Float'>,
  secondary_datapoint_name?: ?$ElementType<Scalars, 'String'>,
};

export type ApisAnalysisResponseItem = {
  __typename: 'ApisAnalysisResponseItem',
  date: $ElementType<Scalars, 'String'>,
  datapoints: Array<ApisAnalysisGlobalDataPointItem>,
};

export type ApisAnalysisResponse = {
  __typename: 'ApisAnalysisResponse',
  apis_analysis: Array<ApisAnalysisResponseItem>,
  global_datapoints: Array<ApisAnalysisGlobalDataPointItem>,
};

export type ShadowAssetsStatsResponse = {
  __typename: 'ShadowAssetsStatsResponse',
  shadow_assets_stats: Array<ShadowAssetsStatsResponseItem>,
};

export type ApiChangesNamedFiltersResponse = {
  __typename: 'ApiChangesNamedFiltersResponse',
  api_changes_named_filters: Array<NamedFilterItem>,
};

export type GetNamedFiltersPoliciesResponse = {
  __typename: 'GetNamedFiltersPoliciesResponse',
  polices_named_filters: Array<NamedFilterItem>,
};

export type MobileApplicationsNamedFiltersResponse = {
  __typename: 'MobileApplicationsNamedFiltersResponse',
  mobile_applications_named_filters: Array<NamedFilterItem>,
};

export type ApiSecurityStatsResponseItemDataPoint = {
  __typename: 'ApiSecurityStatsResponseItemDataPoint',
  datapoint_name: $ElementType<Scalars, 'String'>,
  datapoint_count: $ElementType<Scalars, 'Float'>,
};

export type ApiSecurityStatsResponseItem = {
  __typename: 'ApiSecurityStatsResponseItem',
  date: $ElementType<Scalars, 'String'>,
  datapoints: Array<ApiSecurityStatsResponseItemDataPoint>,
};

export type ApiSecurityStatsResponse = {
  __typename: 'ApiSecurityStatsResponse',
  policy_violations_trends: Array<ApiSecurityStatsResponseItem>,
};

export type MobileSecurityStatsResponseLegendItem = {
  __typename: 'MobileSecurityStatsResponseLegendItem',
  color: $ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
};

export type MobileSecurityStatsResponseMonthItemBarItemMetricItem = {
  __typename: 'MobileSecurityStatsResponseMonthItemBarItemMetricItem',
  color: $ElementType<Scalars, 'String'>,
  count: $ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
};

export type MobileSecurityStatsResponseMonthItemBarItem = {
  __typename: 'MobileSecurityStatsResponseMonthItemBarItem',
  bar_total: $ElementType<Scalars, 'String'>,
  metrics: Array<MobileSecurityStatsResponseMonthItemBarItemMetricItem>,
};

export type MobileSecurityStatsResponseMonthItem = {
  __typename: 'MobileSecurityStatsResponseMonthItem',
  month_name: $ElementType<Scalars, 'String'>,
  bars: Array<MobileSecurityStatsResponseMonthItemBarItem>,
};

export type MobileSecurityStatsResponse = {
  __typename: 'MobileSecurityStatsResponse',
  legend: Array<MobileSecurityStatsResponseLegendItem>,
  months: Array<MobileSecurityStatsResponseMonthItem>,
};

export type MobileAppSecKpisStatsResponseItem = {
  __typename: 'MobileAppSecKpisStatsResponseItem',
  color: $ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
};

export type MobileAppSecKpisStatsResponse = {
  __typename: 'MobileAppSecKpisStatsResponse',
  bars: Array<MobileAppSecKpisStatsResponseItem>,
};

export type AppSecKpisStatsResponseItem = {
  __typename: 'AppSecKpisStatsResponseItem',
  name: $ElementType<Scalars, 'String'>,
  count: $ElementType<Scalars, 'Float'>,
  color: $ElementType<Scalars, 'String'>,
};

export type AppSecKpisStatsResponse = {
  __typename: 'AppSecKpisStatsResponse',
  app_sec_kpis_stats: Array<AppSecKpisStatsResponseItem>,
};

export type AssetsDetailsResponseV2 = {
  __typename: 'AssetsDetailsResponseV2',
  id: $ElementType<Scalars, 'ID'>,
  asset_type: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  belongs_to_asset_group?: ?AssetGroupField,
  date_created: $ElementType<Scalars, 'Date'>,
  date_no_longer_accessible?: ?$ElementType<Scalars, 'Date'>,
  discovered_via: $ElementType<Scalars, 'String'>,
  discovered_via_icon_url: $ElementType<Scalars, 'String'>,
  discovered_via_name: $ElementType<Scalars, 'String'>,
  hosted_on: $ElementType<Scalars, 'String'>,
  hosted_on_icon_url: $ElementType<Scalars, 'String'>,
  hosted_on_name: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  open_important_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_proactive_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_urgent_policy_violations_count: $ElementType<Scalars, 'Float'>,
  status: $ElementType<Scalars, 'String'>,
  url: $ElementType<Scalars, 'String'>,
  belongs_to_cloud_account?: ?ApiProtectRestfulApiBelongsToCloudAccount,
};

export type AssetsAsset = {
  __typename: 'AssetsAsset',
  id: $ElementType<Scalars, 'ID'>,
  asset_type: AssetTypeEnum,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  belongs_to_asset_group?: ?AssetGroupField,
  date_created: $ElementType<Scalars, 'Date'>,
  date_no_longer_accessible?: ?$ElementType<Scalars, 'Date'>,
  discovered_via: $ElementType<Scalars, 'String'>,
  discovered_via_icon_url: $ElementType<Scalars, 'String'>,
  discovered_via_name: $ElementType<Scalars, 'String'>,
  hosted_on: $ElementType<Scalars, 'String'>,
  hosted_on_icon_url: $ElementType<Scalars, 'String'>,
  hosted_on_name: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  open_important_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_proactive_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_urgent_policy_violations_count: $ElementType<Scalars, 'Float'>,
  status: $ElementType<Scalars, 'String'>,
  url?: ?$ElementType<Scalars, 'String'>,
};

export type AssetsAssetsListV2 = {
  __typename: 'AssetsAssetsListV2',
  pagination_information: PaginationInformation,
  assets: Array<AssetsAsset>,
};

export type KubernetesClusterComponentParentCluster = {
  __typename: 'KubernetesClusterComponentParentCluster',
  cluster_name: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  kubernetes_version: $ElementType<Scalars, 'String'>,
};

export type KubernetesClusterComponent = {
  __typename: 'KubernetesClusterComponent',
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  component_type: $ElementType<Scalars, 'String'>,
  component_type_name: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'String'>,
  date_no_longer_accessible?: ?$ElementType<Scalars, 'String'>,
  discovered_via: $ElementType<Scalars, 'String'>,
  discovered_via_icon_url: $ElementType<Scalars, 'String'>,
  discovered_via_name: $ElementType<Scalars, 'String'>,
  hosted_on?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  metadata_creation_timestamp: $ElementType<Scalars, 'String'>,
  metadata_labels?: ?$ElementType<Scalars, 'JSON'>,
  metadata_name: $ElementType<Scalars, 'String'>,
  metadata_namespace: $ElementType<Scalars, 'String'>,
  metadata_uid: $ElementType<Scalars, 'String'>,
  open_important_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_proactive_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_urgent_policy_violations_count: $ElementType<Scalars, 'Float'>,
  parent_kubernetes_cluster_asset: KubernetesClusterComponentParentCluster,
  status: $ElementType<Scalars, 'String'>,
};

export type KubernetesClusterResourceResponse = {
  __typename: 'KubernetesClusterResourceResponse',
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  cluster_name: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'String'>,
  date_no_longer_accessible?: ?$ElementType<Scalars, 'String'>,
  discovered_via: $ElementType<Scalars, 'String'>,
  discovered_via_icon_url: $ElementType<Scalars, 'String'>,
  discovered_via_name: $ElementType<Scalars, 'String'>,
  hosted_on?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  kubernetes_cluster_components_count: $ElementType<Scalars, 'JSON'>,
  kubernetes_control_plane_url: $ElementType<Scalars, 'String'>,
  kubernetes_version: $ElementType<Scalars, 'String'>,
  open_important_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_proactive_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_urgent_policy_violations_count: $ElementType<Scalars, 'Float'>,
  status: $ElementType<Scalars, 'String'>,
};

export type KubernetesClusterComponentsResponse = {
  __typename: 'KubernetesClusterComponentsResponse',
  pagination_information: PaginationInformation,
  kubernetes_cluster_components: Array<KubernetesClusterComponent>,
};

export type PolicyRuleTypeField = {
  __typename: 'PolicyRuleTypeField',
  id: $ElementType<Scalars, 'ID'>,
  title: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  recommendation: $ElementType<Scalars, 'String'>,
  compliance_policy_references: Array<PolicyComplianceReference>,
};

export type PolicyRuleField = {
  __typename: 'PolicyRuleField',
  id: $ElementType<Scalars, 'ID'>,
  policy_rule_type: PolicyRuleTypeField,
  relevance: PolicyRuleTypeRelevance,
  status: PolicyRuleTypeStatus,
};

export type PolicyViolationSummaryResponse = {
  __typename: 'PolicyViolationSummaryResponse',
  id: $ElementType<Scalars, 'ID'>,
  status: PolicyViolationStatusEnum,
  violated_policy_rule: PolicyRuleField,
  affected_asset_name: $ElementType<Scalars, 'String'>,
  affected_asset_date_created?: ?$ElementType<Scalars, 'Date'>,
  affected_asset_type_name: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_resolved?: ?$ElementType<Scalars, 'Date'>,
  exception_date_created?: ?$ElementType<Scalars, 'Date'>,
};

export type AssetsPolicyViolationsListV2 = {
  __typename: 'AssetsPolicyViolationsListV2',
  policy_violations: Array<PolicyViolationSummaryResponse>,
  pagination_information: PaginationInformation,
};

/** ENDPOINTS */
export type ApiOperationsListResponseV2 = {
  __typename: 'ApiOperationsListResponseV2',
  pagination_information: PaginationInformation,
  api_operations: Array<V2ApiOperation>,
};

export type GrpcOperationsListResponse = {
  __typename: 'GrpcOperationsListResponse',
  pagination_information: PaginationInformation,
  grpc_methods: Array<GrpcOperation>,
};

export type SoapApiOperationsListResponse = {
  __typename: 'SoapApiOperationsListResponse',
  pagination_information: PaginationInformation,
  soap_api_operations: Array<SoapOperation>,
};

export type ApiOperationDetailsResponse = {
  __typename: 'APIOperationDetailsResponse',
  api_operations: Array<ApiOperation>,
  api_operation_scan_traces?: ?Array<ApiOperationScanTrace>,
};

export type ApiOperationV2RestfulApi = {
  __typename: 'APIOperationV2RestfulApi',
  id: $ElementType<Scalars, 'ID'>,
};

export type ApiOperationDetailsResponseVersion2 = {
  __typename: 'APIOperationDetailsResponseVersion2',
  id: $ElementType<Scalars, 'ID'>,
  restful_api: ApiOperationV2RestfulApi,
};

export type MobileProtectAppCoverageResponse = {
  __typename: 'MobileProtectAppCoverageResponse',
  exercised_api_operations_count: $ElementType<Scalars, 'Float'>,
};

export type ComplianceReportsStatsResponseItem = {
  __typename: 'ComplianceReportsStatsResponseItem',
  open_policy_violations_count: $ElementType<Scalars, 'Float'>,
  closed_policy_violations_count: $ElementType<Scalars, 'Float'>,
  downloads_count: $ElementType<Scalars, 'Float'>,
  compliance_report_type: $ElementType<Scalars, 'String'>,
  compliance_report_name: $ElementType<Scalars, 'String'>,
  compliance_report_icon_url: $ElementType<Scalars, 'String'>,
};

export type ComplianceReportsStatsResponse = {
  __typename: 'ComplianceReportsStatsResponse',
  compliance_reports_stats: Array<ComplianceReportsStatsResponseItem>,
};

export type ApiMobileApplicationDetailsResponse = {
  __typename: 'APIMobileApplicationDetailsResponse',
  id: $ElementType<Scalars, 'ID'>,
  bundle_id?: ?$ElementType<Scalars, 'String'>,
  discovered_via: $ElementType<Scalars, 'String'>,
  discovered_via_icon_url: $ElementType<Scalars, 'String'>,
  discovered_via_name: $ElementType<Scalars, 'String'>,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  mobile_secure_portal_url: $ElementType<Scalars, 'String'>,
  included_supply_chain_secure_my_apps_details?: ?IncludedSupplyChainSecureMyAppsDetails,
  last_scan_date?: ?$ElementType<Scalars, 'Date'>,
  last_scanned_via_icon_url?: ?$ElementType<Scalars, 'String'>,
  last_scanned_via_name?: ?$ElementType<Scalars, 'String'>,
  last_scanned_via_should_be_highlighted?: ?$ElementType<Scalars, 'Boolean'>,
  name?: ?$ElementType<Scalars, 'String'>,
  platform: $ElementType<Scalars, 'String'>,
  platform_icon_url?: ?$ElementType<Scalars, 'String'>,
  platform_name?: ?$ElementType<Scalars, 'String'>,
  release_type?: ?$ElementType<Scalars, 'String'>,
  release_type_icon_url?: ?$ElementType<Scalars, 'String'>,
  release_type_name?: ?$ElementType<Scalars, 'String'>,
  store_url?: ?$ElementType<Scalars, 'String'>,
  version_id?: ?$ElementType<Scalars, 'String'>,
};

export type BulkMoveToAssetGroupListResponse = {
  __typename: 'BulkMoveToAssetGroupListResponse',
  pagination_information: PaginationInformation,
  bulk_actions?: ?Array<BulkAssetMoveToAssetGroup>,
};

export type MobileApplicationStatsResponse = {
  __typename: 'MobileApplicationStatsResponse',
  android_apps_count: $ElementType<Scalars, 'Float'>,
  android_preprod_apps_count: $ElementType<Scalars, 'Float'>,
  ios_apps_count: $ElementType<Scalars, 'Float'>,
  ios_preprod_apps_count: $ElementType<Scalars, 'Float'>,
  enterprise_apps_count: $ElementType<Scalars, 'Float'>,
  mobile_apis_count: $ElementType<Scalars, 'Float'>,
  total_open_policy_violations_count: $ElementType<Scalars, 'Float'>,
  total_open_toolkit_policy_violations_count: $ElementType<Scalars, 'Float'>,
  total_open_non_toolkit_policy_violations_count: $ElementType<Scalars, 'Float'>,
  android_icon_url: $ElementType<Scalars, 'String'>,
  enterprise_icon_url: $ElementType<Scalars, 'String'>,
  mobile_api_icon_url: $ElementType<Scalars, 'String'>,
  ios_icon_url: $ElementType<Scalars, 'String'>,
  active_installs_count: $ElementType<Scalars, 'Float'>,
  attacks_detected_count: $ElementType<Scalars, 'Float'>,
  attacks_blocked_count: $ElementType<Scalars, 'Float'>,
  apps_with_active_protection_count: $ElementType<Scalars, 'Float'>,
  total_apps_count: $ElementType<Scalars, 'Float'>,
  trend_period_in_days: $ElementType<Scalars, 'Float'>,
  mobile_apis_count_change_in_trend_period: $ElementType<Scalars, 'Float'>,
  enterprise_apps_count_change_in_trend_period: $ElementType<Scalars, 'Float'>,
  ios_apps_count_change_in_trend_period: $ElementType<Scalars, 'Float'>,
  android_apps_count_change_in_trend_period: $ElementType<Scalars, 'Float'>,
};

export type ComplianceStandardCriteriaListResponse = {
  __typename: 'ComplianceStandardCriteriaListResponse',
  compliance_standard_criteria: Array<ComplianceStandardCriteria>,
};

export type MobileApplicationsListItemAssetTagKey = {
  __typename: 'MobileApplicationsListItemAssetTagKey',
  uuid: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
};

export type MobileApplicationsListItem = {
  __typename: 'MobileApplicationsListItem',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  bundle_id: $ElementType<Scalars, 'String'>,
  version_id?: ?$ElementType<Scalars, 'String'>,
  platform: $ElementType<Scalars, 'String'>,
  release_type: $ElementType<Scalars, 'String'>,
  release_type_name?: ?$ElementType<Scalars, 'String'>,
  release_type_icon_url?: ?$ElementType<Scalars, 'String'>,
  sevenhell_customer_mobile_app_id: $ElementType<Scalars, 'Float'>,
  platform_icon_url: $ElementType<Scalars, 'String'>,
  icon_url: $ElementType<Scalars, 'String'>,
  platform_name: $ElementType<Scalars, 'String'>,
  store_url?: ?$ElementType<Scalars, 'String'>,
  has_linked_apps: $ElementType<Scalars, 'Boolean'>,
  toolkit_hacks_total_count: $ElementType<Scalars, 'Float'>,
  toolkit_hacks_vulnerable_to_count: $ElementType<Scalars, 'Float'>,
  toolkit_hacks_vulnerable_to: Array<$ElementType<Scalars, 'String'>>,
  open_policy_violations_count: $ElementType<Scalars, 'Float'>,
  active_protection_status: ActiveProtectionStatusEnum,
  last_scan_date?: ?$ElementType<Scalars, 'String'>,
  belongs_to_asset_group?: ?AssetGroupField,
  asset_tag_keys?: ?Array<?MobileApplicationsListItemAssetTagKey>,
  last_scanned_via_icon_url: $ElementType<Scalars, 'String'>,
  last_scanned_via_name: $ElementType<Scalars, 'String'>,
  last_scanned_via_should_be_highlighted: $ElementType<Scalars, 'Boolean'>,
  has_mobile_secure_subscription: $ElementType<Scalars, 'Boolean'>,
  mobile_protect_attacks_detected_count?: ?$ElementType<Scalars, 'Float'>,
};

export type MobileApplicationsListResponse = {
  __typename: 'MobileApplicationsListResponse',
  pagination_information: PaginationInformation,
  mobile_applications: Array<MobileApplicationsListItem>,
};

export type EventsListResponse = {
  __typename: 'EventsListResponse',
  pagination_information: PaginationInformation,
  events?: ?Array<HorizonNotificationEvent>,
};

export type PortalNotificationsListResponse = {
  __typename: 'PortalNotificationsListResponse',
  pagination_information: PaginationInformation,
  portal_notifications?: ?Array<SevenhellNotificationEvent>,
};

export type PolicyDetailResponse = {
  __typename: 'PolicyDetailResponse',
  policies: Array<Policy>,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
  asset_groups: Array<AssetGroups>,
  events: Array<Event>,
  users: Array<HorizonUserField>,
  compliance_standard_criteria: Array<ComplianceStandardCriteria>,
};

export type PolicyRuleListResponse = {
  __typename: 'PolicyRuleListResponse',
  pagination_information: PaginationInformation,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
  users: Array<HorizonUserField>,
};

export type V2PolicyRulesListResponsePolicyRuleIncludedPolicyViolationStats = {
  __typename: 'V2PolicyRulesListResponsePolicyRuleIncludedPolicyViolationStats',
  open_violations_with_ongoing_triaging_effort_count: $ElementType<Scalars, 'Float'>,
  open_violations_without_ongoing_triaging_effort_count: $ElementType<Scalars, 'Float'>,
  resolved_violations_count: $ElementType<Scalars, 'Float'>,
  wont_fix_violations_count: $ElementType<Scalars, 'Float'>,
  affected_assets_count?: ?$ElementType<Scalars, 'Float'>,
};

export type V2PolicyRulesListResponsePolicyRulePolicyRuleType = {
  __typename: 'V2PolicyRulesListResponsePolicyRulePolicyRuleType',
  id: $ElementType<Scalars, 'ID'>,
  compliance_policy_references: Array<PolicyComplianceReference>,
  description: $ElementType<Scalars, 'String'>,
  recommendation?: ?$ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
};

export type V2PolicyRulesListResponsePolicyRule = {
  __typename: 'V2PolicyRulesListResponsePolicyRule',
  id: $ElementType<Scalars, 'ID'>,
  included_policy_violation_stats?: ?V2PolicyRulesListResponsePolicyRuleIncludedPolicyViolationStats,
  policy_rule_type: V2PolicyRulesListResponsePolicyRulePolicyRuleType,
  relevance: PolicyRuleTypeRelevance,
};

export type V2PolicyRulesListResponse = {
  __typename: 'V2PolicyRulesListResponse',
  pagination_information: PaginationInformation,
  policy_rules: Array<V2PolicyRulesListResponsePolicyRule>,
};

export type PolicyRulesSearchFilterValue = {
  __typename: 'PolicyRulesSearchFilterValue',
  belongs_to_filter: $ElementType<Scalars, 'String'>,
  icon_url: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
  value_category?: ?$ElementType<Scalars, 'String'>,
};

export type PolicyRulesSearchFilterResponse = {
  __typename: 'PolicyRulesSearchFilterResponse',
  policy_rules_search_filter_values: Array<PolicyRulesSearchFilterValue>,
};

export type SastSearchFilterResponse = {
  __typename: 'SastSearchFilterResponse',
  sast_issues_search_filter_values: Array<AssetsSearchFilterValue>,
};

export type MobileAppProtectionLevelStatsResponse = {
  __typename: 'MobileAppProtectionLevelStatsResponse',
  level0_apps_count: $ElementType<Scalars, 'Float'>,
  level1_apps_count: $ElementType<Scalars, 'Float'>,
  level2_apps_count: $ElementType<Scalars, 'Float'>,
  level3_apps_count: $ElementType<Scalars, 'Float'>,
};

export type PolicyRulesByPolicyIdListResponse = {
  __typename: 'PolicyRulesByPolicyIdListResponse',
  pagination_information: PaginationInformation,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
  users: Array<HorizonUserField>,
};

export type PolicyRuleTypeListResponse = {
  __typename: 'PolicyRuleTypeListResponse',
  pagination_information: PaginationInformation,
  policy_rule_types: Array<PolicyRuleType>,
};

export type PoliciesPolicyRuleTypeListResponse = {
  __typename: 'PoliciesPolicyRuleTypeListResponse',
  pagination_information: PaginationInformation,
  policy_rule_types: Array<PolicyRuleTypeV2>,
};

export type PolicyViolationsKeywordSearchResponse = {
  __typename: 'PolicyViolationsKeywordSearchResponse',
  policy_violations_named_filters: Array<NamedFilterItem>,
};

export type PolicyViolationsCommentsListResponse = {
  __typename: 'PolicyViolationsCommentsListResponse',
  pagination_information: PaginationInformation,
  comments: Array<Comment>,
  users: Array<HorizonUserField>,
};

export type PolicyViolationListResponse = {
  __typename: 'PolicyViolationListResponse',
  pagination_information: PaginationInformation,
  policy_violations: Array<PolicyViolation>,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
  users: Array<HorizonUserField>,
  network_services: Array<NetworkService>,
  domain_names: Array<DomainName>,
  cloud_resources: Array<CloudResource>,
  restful_apis: Array<RestfulApi>,
  web_applications: Array<WebApplication>,
  api_operations?: ?Array<ApiOperation>,
};

export type PolicyViolationListResponseVersion2 = {
  __typename: 'PolicyViolationListResponseVersion2',
  pagination_information: PaginationInformation,
  policy_violations: Array<PolicyViolationV2>,
};

export type PolicyViolationResponseVersion2 = {
  __typename: 'PolicyViolationResponseVersion2',
  policy_violation: PolicyViolationV2,
};

export type RestfulApIsListResponse = {
  __typename: 'RestfulAPIsListResponse',
  pagination_information: PaginationInformation,
  restful_apis: Array<RestfulApi>,
};

export type RestfulApIsListResponse_V1 = {
  __typename: 'RestfulAPIsListResponse_v1',
  pagination_information: PaginationInformation,
  restful_apis: Array<RestfulApi>,
  network_services: Array<NetworkService>,
  policy_violations: Array<PolicyViolation>,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
};

export type RestfulApiDetailsResponse = {
  __typename: 'RestfulAPIDetailsResponse',
  restful_apis: Array<RestfulApi>,
  network_services: Array<NetworkService>,
  domain_names: Array<DomainName>,
  cloud_resources: Array<CloudResource>,
  web_applications: Array<WebApplication>,
  mobile_applications: Array<MobileApp>,
  policy_violations: Array<PolicyViolation>,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
};

export type MobileApplicationDetailsResponse = {
  __typename: 'MobileApplicationDetailsResponse',
  cloud_resources: Array<CloudResource>,
  mobile_applications: Array<MobileApp>,
  restful_apis: Array<RestfulApi>,
};

export type PoliciesListResponse = {
  __typename: 'PoliciesListResponse',
  pagination_information: PaginationInformation,
  policies: Array<Policy>,
  users: Array<HorizonUserField>,
};

export type PolicyV2 = {
  __typename: 'PolicyV2',
  id: $ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
};

export type PoliciesListV2Response = {
  __typename: 'PoliciesListV2Response',
  pagination_information: PaginationInformation,
  policies: Array<PolicyV2>,
};

export type WebApplicationListResponse = {
  __typename: 'WebApplicationListResponse',
  pagination_information: PaginationInformation,
  web_applications: Array<WebApplication>,
  network_services: Array<NetworkService>,
  domain_names: Array<DomainName>,
  policy_violations: Array<PolicyViolation>,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
};

export type WebApplicationListResponseVersion2 = {
  __typename: 'WebApplicationListResponseVersion2',
  pagination_information: PaginationInformation,
  web_applications: Array<WebApplicationVersion2>,
};

export type XssScanRunResultUrlGetParameter = {
  __typename: 'XssScanRunResultUrlGetParameter',
  name: $ElementType<Scalars, 'String'>,
  sample_value?: ?$ElementType<Scalars, 'String'>,
};

export type XssScanRunResultUrlGetParameterReflected = {
  __typename: 'XssScanRunResultUrlGetParameterReflected',
  name: $ElementType<Scalars, 'String'>,
  payload?: ?$ElementType<Scalars, 'String'>,
  page_src_position_where_reflected?: ?$ElementType<Scalars, 'Float'>,
  page_src_position_snippet?: ?$ElementType<Scalars, 'String'>,
};

export type XssScanRunResultUrlGetParameterVulnerableToXss = {
  __typename: 'XssScanRunResultUrlGetParameterVulnerableToXss',
  name: $ElementType<Scalars, 'String'>,
  payload?: ?$ElementType<Scalars, 'String'>,
  page_src_position_where_reflected?: ?$ElementType<Scalars, 'Float'>,
  page_src_position_snippet?: ?$ElementType<Scalars, 'String'>,
};

export type XssScanRunResultUrl = {
  __typename: 'XssScanRunResultUrl',
  scanned_get_parameters: Array<XssScanRunResultUrlGetParameter>,
  reflected_get_parameters: Array<XssScanRunResultUrlGetParameterReflected>,
  vulnerable_get_parameters: Array<XssScanRunResultUrlGetParameterVulnerableToXss>,
};

export type WebAppSummaryDiscoveredUrl = {
  __typename: 'WebAppSummaryDiscoveredUrl',
  url: $ElementType<Scalars, 'String'>,
  xss_scan_result?: ?XssScanRunResultUrl,
};

export type WebApplicationUrlsResponse = {
  __typename: 'WebApplicationUrlsResponse',
  urls: Array<WebAppSummaryDiscoveredUrl>,
};

export type NetworkServiceDetailsResponse = {
  __typename: 'NetworkServiceDetailsResponse',
  restful_apis: Array<RestfulApi>,
  network_services: Array<NetworkService>,
  domain_names: Array<DomainName>,
  cloud_resources: Array<CloudResource>,
  web_applications: Array<WebApplication>,
  policy_violations: Array<PolicyViolation>,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
  tls_scan_results: Array<TlsScanResult>,
  certificate_chains: Array<CertificateChain>,
  certificates: Array<Certificate>,
  asset_group_memberships: Array<AssetGroupMembership>,
  events: Array<Event>,
};

export type WebAppCredentialsStat = {
  __typename: 'WebAppCredentialsStat',
  detected_spa_credentials_count: $ElementType<Scalars, 'Float'>,
};

export type WebAppAssetsInventoryStats = {
  __typename: 'WebAppAssetsInventoryStats',
  important_assets_count: $ElementType<Scalars, 'Float'>,
  proactive_assets_count: $ElementType<Scalars, 'Float'>,
  total_assets_count: $ElementType<Scalars, 'Float'>,
  urgent_assets_count: $ElementType<Scalars, 'Float'>,
};

export type WebAppConnectedAssetsStats = {
  __typename: 'WebAppConnectedAssetsStats',
  api_management_cloud_resources_stats: WebAppAssetsInventoryStats,
  api_operations_stats: WebAppAssetsInventoryStats,
  database_cloud_resources_stats: WebAppAssetsInventoryStats,
  graphql_apis_stats: WebAppAssetsInventoryStats,
  network_services_stats: WebAppAssetsInventoryStats,
  other_cloud_resources_stats: WebAppAssetsInventoryStats,
  secret_management_cloud_resources_stats: WebAppAssetsInventoryStats,
  serverless_cloud_resources_stats: WebAppAssetsInventoryStats,
  storage_cloud_resources_stats: WebAppAssetsInventoryStats,
  virtual_machine_cloud_resources_stats: WebAppAssetsInventoryStats,
};

export type LatestScanRun = {
  __typename: 'LatestScanRun',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  scan_type: ToolkitScanTypeEnum,
  status: ScanRunsStatusEnum,
};

export type WebAppXssToolkitStats = {
  __typename: 'WebAppXssToolkitStats',
  next_xss_scan_run_date?: ?$ElementType<Scalars, 'Date'>,
  latest_xss_scan_run?: ?LatestScanRun,
  urls_count: $ElementType<Scalars, 'Float'>,
  open_xss_policy_violations_count: $ElementType<Scalars, 'Float'>,
  xss_result: XssToolkitResultEnum,
};

export type WebAppProtectionStats = {
  __typename: 'WebAppProtectionStats',
  encryption_protection_level_achieved: $ElementType<Scalars, 'Float'>,
  overall_web_app_protection_score: $ElementType<Scalars, 'Float'>,
  third_party_protection_level_achieved: $ElementType<Scalars, 'Float'>,
  xss_protection_level_achieved: $ElementType<Scalars, 'Float'>,
};

export type IncludedSupplyChainSecureMyAppsDetails = {
  __typename: 'IncludedSupplyChainSecureMyAppsDetails',
  related_third_party_assets_count?: ?$ElementType<Scalars, 'Int'>,
  related_third_party_assets_count_30_day_delta?: ?$ElementType<Scalars, 'Int'>,
  related_third_party_assets_count_with_violations_30_day_delta?: ?$ElementType<Scalars, 'Int'>,
  related_third_party_assets_with_violations_count?: ?$ElementType<Scalars, 'Int'>,
  related_third_party_cloud_resource_count?: ?$ElementType<Scalars, 'Int'>,
  related_third_party_cloud_resource_count_30_day_delta?: ?$ElementType<Scalars, 'Int'>,
  related_third_party_combined_apis_count?: ?$ElementType<Scalars, 'Int'>,
  related_third_party_combined_apis_count_30_day_delta?: ?$ElementType<Scalars, 'Int'>,
  related_third_party_mobile_sdk_count?: ?$ElementType<Scalars, 'Int'>,
  related_third_party_mobile_sdk_count_30_day_delta?: ?$ElementType<Scalars, 'Int'>,
  vendors_count?: ?$ElementType<Scalars, 'Int'>,
  vendors_count_30_day_delta?: ?$ElementType<Scalars, 'Int'>,
  full_stack_web_applications_count?: ?$ElementType<Scalars, 'Int'>,
  full_stack_network_services_count?: ?$ElementType<Scalars, 'Int'>,
  full_stack_restful_apis_count?: ?$ElementType<Scalars, 'Int'>,
  full_stack_graphql_apis_count?: ?$ElementType<Scalars, 'Int'>,
};

export type WebApplicationDetailsV2Response = {
  __typename: 'WebApplicationDetailsV2Response',
  id: $ElementType<Scalars, 'ID'>,
  title?: ?$ElementType<Scalars, 'String'>,
  detected_libraries?: ?Array<?WebLibrary>,
  discovered_via?: ?DiscoveredVia,
  discovered_via_icon_url?: ?$ElementType<Scalars, 'String'>,
  discovered_via_name?: ?$ElementType<Scalars, 'String'>,
  hosted_on?: ?$ElementType<Scalars, 'String'>,
  hosted_on_icon_url?: ?$ElementType<Scalars, 'String'>,
  hosted_on_name?: ?$ElementType<Scalars, 'String'>,
  screenshot_url?: ?$ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  base_url: $ElementType<Scalars, 'String'>,
  included_supply_chain_secure_my_apps_details?: ?IncludedSupplyChainSecureMyAppsDetails,
};

export type WebApplicationDetailsResponse = {
  __typename: 'WebApplicationDetailsResponse',
  web_applications: Array<WebApplication>,
  network_services: Array<NetworkService>,
  domain_names: Array<DomainName>,
  graphql_apis: Array<GraphqlApi>,
  policy_violations: Array<PolicyViolation>,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
  restful_apis: Array<RestfulApi>,
  cloud_resources: Array<CloudResource>,
};

export type DetectedSpaCredentialUsedIn = {
  __typename: 'DetectedSpaCredentialUsedIn',
  header_name: $ElementType<Scalars, 'String'>,
  header_value: $ElementType<Scalars, 'String'>,
  method: $ElementType<Scalars, 'String'>,
  url: $ElementType<Scalars, 'String'>,
};

export type DetectedSpaCredential = {
  __typename: 'DetectedSpaCredential',
  id: $ElementType<Scalars, 'ID'>,
  value: $ElementType<Scalars, 'String'>,
  web_application_id: $ElementType<Scalars, 'ID'>,
  found_at_url: $ElementType<Scalars, 'String'>,
  credential_as_markdown: $ElementType<Scalars, 'String'>,
  exported_to_policy_violation_id?: ?$ElementType<Scalars, 'ID'>,
  triaging_decision: CredentialTriagingDecisionEnum,
  used_in_request: DetectedSpaCredentialUsedIn,
};

export type WebApplicationCredentialsResponse = {
  __typename: 'WebApplicationCredentialsResponse',
  detected_spa_credentials: Array<DetectedSpaCredential>,
};

export type DetectedSpaCredentialsResponse = {
  __typename: 'DetectedSpaCredentialsResponse',
  detected_spa_credentials: Array<DetectedSpaCredential>,
  pagination_information: PaginationInformation,
};

export type CloudCiemAlertDetailsResponseItem = {
  __typename: 'CloudCiemAlertDetailsResponseItem',
  date_created: $ElementType<Scalars, 'String'>,
  event_name: $ElementType<Scalars, 'String'>,
  event_type: $ElementType<Scalars, 'String'>,
  flags?: ?Array<?$ElementType<Scalars, 'String'>>,
  source_ip_address: $ElementType<Scalars, 'String'>,
  threats: Array<$ElementType<Scalars, 'String'>>,
  user_agent: $ElementType<Scalars, 'String'>,
  user_identity: $ElementType<Scalars, 'String'>,
};

export type CloudCiemAlertDetailsResponse = {
  __typename: 'CloudCiemAlertDetailsResponse',
  pagination_information: PaginationInformation,
  alerts: Array<CloudCiemAlertDetailsResponseItem>,
};

export type CloudCiemPolicyRulesResponseItem = {
  __typename: 'CloudCiemPolicyRulesResponseItem',
  alerts_count: $ElementType<Scalars, 'Float'>,
  cloud_provider: $ElementType<Scalars, 'String'>,
  policy_rule: $ElementType<Scalars, 'String'>,
  severity: $ElementType<Scalars, 'String'>,
  threat_type: $ElementType<Scalars, 'String'>,
};

export type CloudCiemPolicyRulesResponse = {
  __typename: 'CloudCiemPolicyRulesResponse',
  policy_rules: Array<CloudCiemPolicyRulesResponseItem>,
};

export type CredentialTriageResponse = {
  __typename: 'CredentialTriageResponse',
  detected_spa_credential: DetectedSpaCredential,
};

export type PoliciesCreateResponse = {
  __typename: 'PoliciesCreateResponse',
  policies: Array<Policy>,
  users: Array<HorizonUserField>,
};

export type CloudAuthenticatorsResponse_Deprecated = {
  __typename: 'CloudAuthenticatorsResponse_DEPRECATED',
  pagination_information: PaginationInformation,
  cloud_authenticators: Array<CloudAuthenticator_Deprecated>,
};

export type CloudAuthenticatorsResponse = {
  __typename: 'CloudAuthenticatorsResponse',
  pagination_information: PaginationInformation,
  cloud_authenticators: Array<CloudAuthenticator>,
};

export type AlertsIntegrationsResponse = {
  __typename: 'AlertsIntegrationsResponse',
  pagination_information: PaginationInformation,
  alerts_integrations: Array<AlertsIntegration>,
};

export type DownloadMostRecentComplianceReportResponse = {
  __typename: 'DownloadMostRecentComplianceReportResponse',
  download_link: $ElementType<Scalars, 'String'>,
};

export type ApigeeAuthenticatorsCreateResponse = {
  __typename: 'ApigeeAuthenticatorsCreateResponse',
  id: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_user_id: $ElementType<Scalars, 'String'>,
  organization: $ElementType<Scalars, 'String'>,
};

export type AwsAuthenticatorsCreateResponse = {
  __typename: 'AwsAuthenticatorsCreateResponse',
  id: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_user_id: $ElementType<Scalars, 'String'>,
  role_arn: $ElementType<Scalars, 'String'>,
  external_id: $ElementType<Scalars, 'String'>,
};

export type AxwayAuthenticatorsCreateResponse = {
  __typename: 'AxwayAuthenticatorsCreateResponse',
  id: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_user_id: $ElementType<Scalars, 'String'>,
  client_id: $ElementType<Scalars, 'String'>,
  organization_id: $ElementType<Scalars, 'String'>,
};

export type AzureAuthenticatorsCreateResponse = {
  __typename: 'AzureAuthenticatorsCreateResponse',
  id: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_user_id: $ElementType<Scalars, 'String'>,
  tenant_id: $ElementType<Scalars, 'String'>,
  client_id: $ElementType<Scalars, 'String'>,
};

export type GcpAuthenticatorsCreateResponse = {
  __typename: 'GcpAuthenticatorsCreateResponse',
  id: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_user_id: $ElementType<Scalars, 'String'>,
  organization_id: $ElementType<Scalars, 'String'>,
  client_email: $ElementType<Scalars, 'String'>,
};

export type KongAuthenticatorsCreateResponse = {
  __typename: 'KongAuthenticatorsCreateResponse',
  id: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_user_id: $ElementType<Scalars, 'String'>,
  kong_admin_subdomain: $ElementType<Scalars, 'String'>,
};

export type CreateCloudAuthenticatorResponse = {
  __typename: 'CreateCloudAuthenticatorResponse',
  authenticator_category: $ElementType<Scalars, 'String'>,
  authenticator_type: $ElementType<Scalars, 'String'>,
  cloud_account_id: $ElementType<Scalars, 'String'>,
  customer_supplied_name?: ?$ElementType<Scalars, 'String'>,
  date_created?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  status: $ElementType<Scalars, 'String'>,
};

export type MulesoftAuthenticatorsCreateResponse = {
  __typename: 'MulesoftAuthenticatorsCreateResponse',
  id: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_user_id: $ElementType<Scalars, 'String'>,
  organization_id: $ElementType<Scalars, 'String'>,
};

export type DetectAndInjectScanRunConfigurationType = {
  __typename: 'DetectAndInjectScanRunConfigurationType',
  http_methods_to_scan?: ?Array<HttpMethod>,
};

export type DetectAndInjectConfigurationType = {
  __typename: 'DetectAndInjectConfigurationType',
  api_operation_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
  should_scan_all_api_operations?: ?$ElementType<Scalars, 'Boolean'>,
  scan_run_configuration?: ?DetectAndInjectScanRunConfigurationType,
};

export type DetectAndInjectScansSchedulesResponse = {
  __typename: 'DetectAndInjectScansSchedulesResponse',
  toolkit_scan_type?: ?ToolkitScanTypeEnum,
  weekdays_when_to_scan?: ?Array<Weekday>,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  detect_and_inject_configuration?: ?DetectAndInjectConfigurationType,
};

export type DetectAndInjectScansScheduleSetResponse = {
  __typename: 'DetectAndInjectScansScheduleSetResponse',
  toolkit_scan_type?: ?ToolkitScanTypeEnum,
  weekdays_when_to_scan?: ?Array<Weekday>,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  detect_and_inject_configuration?: ?DetectAndInjectConfigurationType,
};

export type WebApplicationXssScanRunConfigurationType = {
  __typename: 'WebApplicationXssScanRunConfigurationType',
  should_inject_in_get_parameters?: ?$ElementType<Scalars, 'Boolean'>,
  should_inject_in_post_parameters?: ?$ElementType<Scalars, 'Boolean'>,
  should_inject_in_url_path?: ?$ElementType<Scalars, 'Boolean'>,
  intensity?: ?WebApplicationXssScanIntensityEnum,
};

export type WebApplicationXssConfigurationType = {
  __typename: 'WebApplicationXssConfigurationType',
  web_applications_ids?: ?Array<$ElementType<Scalars, 'String'>>,
  should_scan_all_web_applications?: ?$ElementType<Scalars, 'Boolean'>,
  scan_run_configuration?: ?WebApplicationXssScanRunConfigurationType,
};

export type WebApplicationXssScansSchedulesResponse = {
  __typename: 'WebApplicationXssScansSchedulesResponse',
  toolkit_scan_type?: ?ToolkitScanTypeEnum,
  weekdays_when_to_scan?: ?Array<Weekday>,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  web_application_xss_configuration?: ?WebApplicationXssConfigurationType,
};

export type BreakGlassStatsResponse = {
  __typename: 'BreakGlassStatsResponse',
  break_glass_stats_field: BreakGlassStatsField,
};

export type BreakGlassStatsField = {
  __typename: 'BreakGlassStatsField',
  aws_stats: Array<?SecretsResourceCategoryStatsField>,
  azure_stats: Array<?SecretsResourceCategoryStatsField>,
  gcp_stats: Array<?SecretsResourceCategoryStatsField>,
  secret_management_assets_count: $ElementType<Scalars, 'Float'>,
  unauthenticated_secret_management_assets_count: $ElementType<Scalars, 'Float'>,
};

export type SecretsResourceCategoryStatsField = {
  __typename: 'SecretsResourceCategoryStatsField',
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  cloud_provider: CloudProvider,
  resource_type: ResourceTypeEnum,
  secret_management_assets_count: $ElementType<Scalars, 'Float'>,
  unauthenticated_secret_management_assets_count: $ElementType<Scalars, 'Float'>,
};

export type CloudDlpStatsFieldStat = {
  __typename: 'CloudDlpStatsFieldStat',
  resource_type: $ElementType<Scalars, 'String'>,
  assets_count: $ElementType<Scalars, 'Float'>,
  unauthenticated_assets_count?: ?$ElementType<Scalars, 'Float'>,
  sensitive_data_leaking_assets_count?: ?$ElementType<Scalars, 'Float'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
};

export type CloudDlpStatsField = {
  __typename: 'CloudDlpStatsField',
  assets_count: $ElementType<Scalars, 'Float'>,
  aws_assets_stats: Array<CloudDlpStatsFieldStat>,
  gcp_assets_stats: Array<CloudDlpStatsFieldStat>,
  azure_assets_stats: Array<CloudDlpStatsFieldStat>,
  other_assets_stats: Array<CloudDlpStatsFieldStat>,
  total_assets_count_weekly_trend: $ElementType<Scalars, 'String'>,
  authenticated_assets_count_weekly_trend: $ElementType<Scalars, 'String'>,
  unauthenticated_by_design_assets_count: $ElementType<Scalars, 'Float'>,
  unauthenticated_assets_count_weekly_trend: $ElementType<Scalars, 'String'>,
  unauthenticated_assets_count: $ElementType<Scalars, 'Float'>,
  sensitive_data_leaking_assets_count: $ElementType<Scalars, 'Float'>,
  sensitive_data_leaking_assets_count_weekly_trend: $ElementType<Scalars, 'String'>,
};

export type CloudDlpStatsGetResponse = {
  __typename: 'CloudDlpStatsGetResponse',
  dlp_stats_field: CloudDlpStatsField,
};

export type ComplianceReportConfigurationType = {
  __typename: 'ComplianceReportConfigurationType',
  recipient_emails: Array<$ElementType<Scalars, 'String'>>,
  should_report_all_mobile_apps?: ?$ElementType<Scalars, 'Boolean'>,
  customer_mobile_app_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type ComplianceReportScheduleResponse = {
  __typename: 'ComplianceReportScheduleResponse',
  should_only_scan_monthly: $ElementType<Scalars, 'Boolean'>,
  weekdays_when_to_scan?: ?Array<Weekday>,
  toolkit_scan_type: ToolkitScanTypeEnum,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  app_store_privacy_global_report_configuration?: ?ComplianceReportConfigurationType,
  app_store_privacy_specific_apps_report_configuration?: ?ComplianceReportConfigurationType,
};

export type GlobalComplianceReportScheduleResponse = {
  __typename: 'GlobalComplianceReportScheduleResponse',
  should_only_scan_monthly: $ElementType<Scalars, 'Boolean'>,
  weekdays_when_to_scan?: ?Array<Weekday>,
  toolkit_scan_type: ToolkitScanTypeEnum,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  app_store_privacy_global_report_configuration: ComplianceReportConfigurationType,
};

export type AppSpecificComplianceReportScheduleResponse = {
  __typename: 'AppSpecificComplianceReportScheduleResponse',
  should_only_scan_monthly: $ElementType<Scalars, 'Boolean'>,
  weekdays_when_to_scan?: ?Array<Weekday>,
  toolkit_scan_type: ToolkitScanTypeEnum,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  app_store_privacy_specific_apps_report_configuration: ComplianceReportConfigurationType,
};

export type TriggeredScanRuns = {
  __typename: 'TriggeredScanRuns',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  scanned_asset_id: $ElementType<Scalars, 'ID'>,
  scanned_asset_type: AssetTypeEnum,
};

export type ToolkitScanStartRequestResponse = {
  __typename: 'ToolkitScanStartRequestResponse',
  toolkit_scan_type: ToolkitScanTypeEnum,
  web_application_xss_configuration?: ?WebApplicationXssConfigurationType,
  detect_and_inject_configuration?: ?DetectAndInjectConfigurationType,
  cloud_ransomware_encrypt_configuration?: ?ToolkitScansScheduleCloudRansomwareEncryptConfiguration,
  cloud_ransomware_scan_storage_configuration?: ?ToolkitScansScheduleCloudRansomwareStorageConfig,
  cloud_ransomware_upload_malware_configuration?: ?ToolkitScansScheduleCloudRansomwareUploadMalwareConfiguration,
  triggered_scan_runs?: ?Array<TriggeredScanRuns>,
};

export type ToolkitScansSchedulesPutResponseCloudDlpConfigScanRunConfig = {
  __typename: 'ToolkitScansSchedulesPutResponseCloudDlpConfigScanRunConfig',
  aws_cloud_resource_types_to_scan: Array<$ElementType<Scalars, 'String'>>,
  gcp_cloud_resource_types_to_scan: Array<$ElementType<Scalars, 'String'>>,
  azure_cloud_resource_types_to_scan: Array<$ElementType<Scalars, 'String'>>,
  other_cloud_resource_types_to_scan: Array<$ElementType<Scalars, 'String'>>,
  enabled_google_pii_types: Array<$ElementType<Scalars, 'String'>>,
};

export type ToolkitScansScheduleCloudDlpConfig = {
  __typename: 'ToolkitScansScheduleCloudDlpConfig',
  cloud_dlp_analysis_enabled: $ElementType<Scalars, 'Boolean'>,
  scan_run_configuration: ToolkitScansSchedulesPutResponseCloudDlpConfigScanRunConfig,
};

export type ToolkitScansScheduleCloudRansomwareEncryptConfiguration = {
  __typename: 'ToolkitScansScheduleCloudRansomwareEncryptConfiguration',
  decryption_key: $ElementType<Scalars, 'String'>,
};

export type ToolkitScansScheduleCloudRansomwareStorageConfig = {
  __typename: 'ToolkitScansScheduleCloudRansomwareStorageConfig',
  _?: ?$ElementType<Scalars, 'String'>,
};

export type ToolkitScansScheduleCloudRansomwareUploadMalwareConfiguration = {
  __typename: 'ToolkitScansScheduleCloudRansomwareUploadMalwareConfiguration',
  malware_types_to_upload: Array<?$ElementType<Scalars, 'String'>>,
};

export type ToolkitScanSchedulesPutResponse = {
  __typename: 'ToolkitScanSchedulesPutResponse',
  toolkit_scan_type: ToolkitScanTypeEnum,
  cloud_dlp_configuration?: ?ToolkitScansScheduleCloudDlpConfig,
  cloud_ransomware_encrypt_configuration?: ?ToolkitScansScheduleCloudRansomwareEncryptConfiguration,
  cloud_ransomware_scan_storage_configuration?: ?ToolkitScansScheduleCloudRansomwareStorageConfig,
  cloud_ransomware_upload_malware_configuration?: ?ToolkitScansScheduleCloudRansomwareUploadMalwareConfiguration,
  weekdays_when_to_scan: Array<$ElementType<Scalars, 'String'>>,
  time_when_to_scan: $ElementType<Scalars, 'String'>,
};

export type ToolkitScansScheduleGetResponse = {
  __typename: 'ToolkitScansScheduleGetResponse',
  toolkit_scan_type: ToolkitScanTypeEnum,
  cloud_dlp_configuration?: ?ToolkitScansScheduleCloudDlpConfig,
  cloud_ransomware_encrypt_configuration?: ?ToolkitScansScheduleCloudRansomwareEncryptConfiguration,
  cloud_ransomware_scan_storage_configuration?: ?ToolkitScansScheduleCloudRansomwareStorageConfig,
  cloud_ransomware_upload_malware_configuration?: ?ToolkitScansScheduleCloudRansomwareUploadMalwareConfiguration,
  weekdays_when_to_scan: Array<$ElementType<Scalars, 'String'>>,
  time_when_to_scan: $ElementType<Scalars, 'String'>,
};

export type AssetsInventoryStats = {
  __typename: 'AssetsInventoryStats',
  assets_total_count: $ElementType<Scalars, 'Float'>,
  shadow_assets_count: $ElementType<Scalars, 'Float'>,
  urgent_assets_count: $ElementType<Scalars, 'Float'>,
  important_assets_count: $ElementType<Scalars, 'Float'>,
  proactive_assets_count: $ElementType<Scalars, 'Float'>,
};

export type CloudResourcesInventoryStats = {
  __typename: 'CloudResourcesInventoryStats',
  assets_total_count: $ElementType<Scalars, 'Float'>,
  shadow_assets_count: $ElementType<Scalars, 'Float'>,
  urgent_assets_count: $ElementType<Scalars, 'Float'>,
  important_assets_count: $ElementType<Scalars, 'Float'>,
  proactive_assets_count: $ElementType<Scalars, 'Float'>,
  serverless_assets_count: $ElementType<Scalars, 'Float'>,
  shadow_serverless_assets_count: $ElementType<Scalars, 'Float'>,
};

export type AllAssetsStats = {
  __typename: 'AllAssetsStats',
  network_services_stats: AssetsInventoryStats,
  restful_apis_stats: AssetsInventoryStats,
  web_applications_stats: AssetsInventoryStats,
  cloud_resources_stats: CloudResourcesInventoryStats,
};

export type InventoryStats = {
  __typename: 'InventoryStats',
  reference_date: $ElementType<Scalars, 'Date'>,
  all_assets_stats: AllAssetsStats,
  aws_assets_stats: AllAssetsStats,
  gcp_assets_stats: AllAssetsStats,
  apigee_assets_stats: AllAssetsStats,
  mulesoft_assets_stats: AllAssetsStats,
  kong_assets_stats: AllAssetsStats,
  axway_assets_stats: AllAssetsStats,
  azure_assets_stats: AllAssetsStats,
  unresolved_policy_violations_total_count: $ElementType<Scalars, 'Float'>,
  resolved_policy_violations_total_count: $ElementType<Scalars, 'Float'>,
};

export type InventoryStatsResponse = {
  __typename: 'InventoryStatsResponse',
  inventory_stats: Array<InventoryStats>,
};

export type SpecialScanRequestConfiguration = {
  __typename: 'SpecialScanRequestConfiguration',
  selected_all_web_applications?: ?$ElementType<Scalars, 'Boolean'>,
  selected_web_application_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type SpecialScanRequest = {
  __typename: 'SpecialScanRequest',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_completed?: ?$ElementType<Scalars, 'Date'>,
  created_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  current_status: SpecialScanStatus,
  scan_type: SpecialScanType,
  scan_configuration?: ?SpecialScanRequestConfiguration,
};

export type SpecialScanRequestResponse = {
  __typename: 'SpecialScanRequestResponse',
  special_scan_requests: Array<SpecialScanRequest>,
};

export type AssetGroupMembershipsOverview = {
  __typename: 'AssetGroupMembershipsOverview',
  restful_apis_count: $ElementType<Scalars, 'Float'>,
  api_operations_count: $ElementType<Scalars, 'Float'>,
  web_applications_count: $ElementType<Scalars, 'Float'>,
  network_services_count: $ElementType<Scalars, 'Float'>,
  cloud_resources_count: $ElementType<Scalars, 'Float'>,
};

export type PolicyViolationStatsField = {
  __typename: 'PolicyViolationStatsField',
  assets_count: $ElementType<Scalars, 'Float'>,
  open_urgent_violations_count: $ElementType<Scalars, 'Float'>,
  open_important_violations_count: $ElementType<Scalars, 'Float'>,
  open_proactive_violations_count: $ElementType<Scalars, 'Float'>,
};

export type AssetGroups = {
  __typename: 'AssetGroups',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  name: $ElementType<Scalars, 'String'>,
  is_read_only: $ElementType<Scalars, 'Boolean'>,
  description: $ElementType<Scalars, 'String'>,
  attached_policy_id: $ElementType<Scalars, 'String'>,
  created_by_user_id: $ElementType<Scalars, 'ID'>,
  created_by_user: HorizonUserField,
  asset_group_memberships_overview: AssetGroupMembershipsOverview,
  created_by_actor_name?: ?$ElementType<Scalars, 'String'>,
};

export type AssetGroupIncludedAssetsOverview = {
  __typename: 'AssetGroupIncludedAssetsOverview',
  restful_apis_count: $ElementType<Scalars, 'Float'>,
  api_operations_count: $ElementType<Scalars, 'Float'>,
  web_applications_count: $ElementType<Scalars, 'Float'>,
  network_services_count: $ElementType<Scalars, 'Float'>,
  cloud_resources_count: $ElementType<Scalars, 'Float'>,
  graphql_apis_count: $ElementType<Scalars, 'Float'>,
};

export type V2AssetGroup = {
  __typename: 'V2AssetGroup',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  created_by_actor_id: $ElementType<Scalars, 'String'>,
  created_by_actor_name: $ElementType<Scalars, 'String'>,
  included_policy_violation_stats?: ?PolicyViolationStatsField,
  included_assets_overview?: ?AssetGroupIncludedAssetsOverview,
};

export type AssetGroupListResponse = {
  __typename: 'AssetGroupListResponse',
  pagination_information: PaginationInformation,
  asset_groups: Array<AssetGroups>,
  users: Array<HorizonUserField>,
};

export type V2AssetGroupListResponse = {
  __typename: 'V2AssetGroupListResponse',
  pagination_information: PaginationInformation,
  asset_groups: Array<V2AssetGroup>,
};

export type SingleAssetGroupResponse = {
  __typename: 'SingleAssetGroupResponse',
  asset_groups: Array<AssetGroups>,
  events: Array<Event>,
  policies: Array<Policy>,
  policy_rule_types: Array<PolicyRuleType>,
  policy_rules: Array<PolicyRule>,
  users: Array<HorizonUserField>,
};

export type AssetSummary = {
  __typename: 'AssetSummary',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  asset_type: AssetTypeEnum,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  open_urgent_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_important_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_proactive_policy_violations_count: $ElementType<Scalars, 'Float'>,
  discovered_via: DiscoveredVia,
  hosted_on: HostedOn,
  date_created: $ElementType<Scalars, 'Date'>,
};

export type AssetGroupAssetsSummaryListResponse = {
  __typename: 'AssetGroupAssetsSummaryListResponse',
  pagination_information: PaginationInformation,
  assets: Array<AssetSummary>,
};

export type AssetTagKeyResponse = {
  __typename: 'AssetTagKeyResponse',
  id: $ElementType<Scalars, 'ID'>,
};

export type ShadowAssetsMembersResponse = {
  __typename: 'ShadowAssetsMembersResponse',
  pagination_information: PaginationInformation,
  asset_group_memberships: Array<AssetGroupMembership>,
  users: Array<HorizonUserField>,
  network_services: Array<NetworkService>,
  domain_names: Array<DomainName>,
  cloud_resources: Array<CloudResource>,
  restful_apis: Array<RestfulApi>,
  web_applications: Array<WebApplication>,
};

export type ShadowAssetsConfiguration = {
  __typename: 'ShadowAssetsConfiguration',
  days_count_when_move_assets_out_of_group?: ?$ElementType<Scalars, 'Float'>,
  should_add_assets_hosted_in_cloud_providers?: ?Array<$ElementType<Scalars, 'String'>>,
  should_add_assets_hosted_in_unknown_cloud_environment: $ElementType<Scalars, 'Boolean'>,
  should_add_assets_not_used_by_mobile_or_web_applications: $ElementType<Scalars, 'Boolean'>,
  should_add_assets_recently_discovered_via_source?: ?ShouldAddAssetsRecentlyDiscoveredViaSource,
  should_add_cloud_resource_of_types?: ?Array<$ElementType<Scalars, 'String'>>,
};

export type ShadowAssetsConfigurationResponse = {
  __typename: 'ShadowAssetsConfigurationResponse',
  configuration: ShadowAssetsConfiguration,
};

export type WebApplicationsStatsResponse = {
  __typename: 'WebApplicationsStatsResponse',
  web_applications_stats: Array<WebAppAssetsInventoryStats>,
};

export type CloudResourceListResponse = {
  __typename: 'CloudResourceListResponse',
  pagination_information: PaginationInformation,
  cloud_resources: Array<CloudResource>,
};

export type CloudResourceDetailsResponse = {
  __typename: 'CloudResourceDetailsResponse',
  asset_groups: Array<AssetGroups>,
  cloud_resources: Array<CloudResource>,
  mobile_applications: Array<MobileApp>,
  domain_names: Array<DomainName>,
  events: Array<Event>,
  network_services: Array<NetworkService>,
  policy_rule_types: Array<PolicyRuleType>,
  policy_rules: Array<PolicyRule>,
  policy_violations: Array<PolicyViolation>,
  restful_apis: Array<RestfulApi>,
  web_applications: Array<WebApplication>,
};

export type GraphqlApiDetailsResponse = {
  __typename: 'GraphqlApiDetailsResponse',
  asset_groups: Array<AssetGroups>,
  domain_names: Array<DomainName>,
  events: Array<Event>,
  graphql_apis: Array<GraphqlApi>,
  mobile_applications: Array<MobileApp>,
  network_services: Array<NetworkService>,
  policy_rule_types: Array<PolicyRuleType>,
  policy_rules: Array<PolicyRule>,
  policy_violations: Array<PolicyViolation>,
  web_applications: Array<WebApplication>,
};

export type GraphqlApiScanTrace = {
  __typename: 'GraphqlApiScanTrace',
  query?: ?$ElementType<Scalars, 'String'>,
  response?: ?$ElementType<Scalars, 'String'>,
};

export type GraphqlApiScanTracesResponse = {
  __typename: 'GraphqlApiScanTracesResponse',
  unauthenticated_scan_trace?: ?GraphqlApiScanTrace,
  authenticated_scan_trace?: ?GraphqlApiScanTrace,
};

export type FullStackMetrics = {
  __typename: 'FullStackMetrics',
  api_operations_total_count: $ElementType<Scalars, 'Float'>,
  api_operations_exercised_by_mobile_applications_count: $ElementType<Scalars, 'Float'>,
  api_operations_exercised_by_web_applications_count: $ElementType<Scalars, 'Float'>,
  api_operations_hosted_on_cloud_platforms_count: $ElementType<Scalars, 'Float'>,
};

export type GraphqlApiListResponse = {
  __typename: 'GraphqlApiListResponse',
  pagination_information: PaginationInformation,
  graphql_apis: Array<GraphqlApi>,
};

export type WebApplicationXssToolkitMetrics = {
  __typename: 'WebApplicationXssToolkitMetrics',
  urls_count: $ElementType<Scalars, 'Float'>,
  web_applications_count: $ElementType<Scalars, 'Float'>,
  vulnerable_web_applications_count: $ElementType<Scalars, 'Float'>,
  watchlisted_web_applications_count: $ElementType<Scalars, 'Float'>,
  xss_scan_runs_count: $ElementType<Scalars, 'Float'>,
  ongoing_xss_scan_runs_count: $ElementType<Scalars, 'Float'>,
};

export type PerEnvironmentCloudResourceCategoriesMetrics = {
  __typename: 'PerEnvironmentCloudResourceCategoriesMetrics',
  assets_total_count: $ElementType<Scalars, 'Float'>,
  serverless_assets_count: $ElementType<Scalars, 'Float'>,
  database_assets_count: $ElementType<Scalars, 'Float'>,
  virtual_machine_assets_count: $ElementType<Scalars, 'Float'>,
  storage_assets_count: $ElementType<Scalars, 'Float'>,
  api_management_assets_count: $ElementType<Scalars, 'Float'>,
  secret_management_assets_count: $ElementType<Scalars, 'Float'>,
  other_assets_count: $ElementType<Scalars, 'Float'>,
  kubernetes_assets_count: $ElementType<Scalars, 'Float'>,
};

export type CloudResourceCategoriesMetrics = {
  __typename: 'CloudResourceCategoriesMetrics',
  reference_date: $ElementType<Scalars, 'Date'>,
  all_assets_stats: PerEnvironmentCloudResourceCategoriesMetrics,
  aws_assets_stats: PerEnvironmentCloudResourceCategoriesMetrics,
  gcp_assets_stats: PerEnvironmentCloudResourceCategoriesMetrics,
  azure_assets_stats: PerEnvironmentCloudResourceCategoriesMetrics,
};

export type ApiRadarMetrics = {
  __typename: 'ApiRadarMetrics',
  restful_apis_count: $ElementType<Scalars, 'Float'>,
  api_operations_count: $ElementType<Scalars, 'Float'>,
  graphql_apis_count: $ElementType<Scalars, 'Float'>,
  leaky_apis_count: $ElementType<Scalars, 'Float'>,
  detect_and_inject_count: $ElementType<Scalars, 'Float'>,
  grpc_services_count: $ElementType<Scalars, 'Float'>,
  grpc_methods_count: $ElementType<Scalars, 'Float'>,
  hack_and_extract_count: $ElementType<Scalars, 'Float'>,
  ssrf_count: $ElementType<Scalars, 'Float'>,
  soap_apis_count: $ElementType<Scalars, 'Float'>,
  soap_api_operations_count: $ElementType<Scalars, 'Float'>,
  restful_apis_last_30_days_count?: ?$ElementType<Scalars, 'Float'>,
  graphql_apis_last_30_days_count?: ?$ElementType<Scalars, 'Float'>,
  grpc_services_last_30_days_count?: ?$ElementType<Scalars, 'Float'>,
  soap_apis_last_30_days_count?: ?$ElementType<Scalars, 'Float'>,
};

export type Metrics = {
  __typename: 'Metrics',
  metric_type: MetricTypes,
  full_stack_metrics?: ?FullStackMetrics,
  xss_toolkit_metrics?: ?WebApplicationXssToolkitMetrics,
  cloud_resource_categories_metrics?: ?CloudResourceCategoriesMetrics,
  api_radar_metrics?: ?ApiRadarMetrics,
};

export type MetricsResponse = {
  __typename: 'MetricsResponse',
  metrics: Array<Metrics>,
};

export type IpRangeCreateResponse = {
  __typename: 'IpRangeCreateResponse',
  ip_ranges: Array<IpRange>,
};

export type IpRangeListResponse = {
  __typename: 'IpRangeListResponse',
  pagination_information: PaginationInformation,
  ip_ranges: Array<IpRange>,
};

export type IpRangeScanCreateResponse = {
  __typename: 'IpRangeScanCreateResponse',
  ip_range_scans: Array<IpRangeScan>,
  ip_ranges: Array<IpRange>,
};

export type IpRangePatchResponse = {
  __typename: 'IpRangePatchResponse',
  ip_ranges: Array<IpRange>,
};

export type PolicyViolationsPatchResponse = {
  __typename: 'PolicyViolationsPatchResponse',
  policy_violations: Array<PolicyViolation>,
};

export type OpenApiDefinitionsListResponse = {
  __typename: 'OpenAPIDefinitionsListResponse',
  pagination_information: PaginationInformation,
  openapi_definitions: Array<OpenApiDefinition>,
};

export type OpenApiDefinitionsCreateResponse = {
  __typename: 'OpenApiDefinitionsCreateResponse',
  id: $ElementType<Scalars, 'ID'>,
  domain_names: Array<DomainName>,
  network_services: Array<NetworkService>,
  restful_apis: Array<RestfulApi>,
};

export type SharedLinksCreateForPolicyViolationResponse = {
  __typename: 'SharedLinksCreateForPolicyViolationResponse',
  shared_resource_id?: ?$ElementType<Scalars, 'String'>,
  shared_resource_type: SharedLinksCloudResourceTypeEnum,
  access_token: $ElementType<Scalars, 'String'>,
};

export type SecureSharePolicyViolationsResult = {
  __typename: 'SecureSharePolicyViolationsResult',
  policy_violations_ids?: ?Array<?$ElementType<Scalars, 'String'>>,
  access_token: $ElementType<Scalars, 'String'>,
};

export type SharedLinksCreateForPolicyViolationsResponse = {
  __typename: 'SharedLinksCreateForPolicyViolationsResponse',
  action_type?: ?$ElementType<Scalars, 'String'>,
  status?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'String'>,
  move_to_group_result?: ?$ElementType<Scalars, 'String'>,
  completed_tasks_count?: ?$ElementType<Scalars, 'Float'>,
  total_tasks_count?: ?$ElementType<Scalars, 'Float'>,
  secure_share_policy_violations_result?: ?SecureSharePolicyViolationsResult,
  date_created: $ElementType<Scalars, 'Date'>,
  date_completed: $ElementType<Scalars, 'Date'>,
};

export type BulkActionResponse = {
  __typename: 'BulkActionResponse',
  action_type?: ?BulkActionType,
  status?: ?BulkActionsStatusEnum,
};

export type FileUploadApiCustomCheckStatsResponse = {
  __typename: 'FileUploadApiCustomCheckStatsResponse',
  apis_with_onboarded_custom_check_count: $ElementType<Scalars, 'Float'>,
  custom_checks_count: $ElementType<Scalars, 'Float'>,
  failed_custom_checks_count?: ?$ElementType<Scalars, 'Float'>,
  passed_custom_checks_count?: ?$ElementType<Scalars, 'Float'>,
};

export type SdkVariant = {
  __typename: 'SDKVariant',
  id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  icon_url: $ElementType<Scalars, 'String'>,
  version?: ?$ElementType<Scalars, 'String'>,
};

export type GetAllSdkVariantsResponse = {
  __typename: 'GetAllSdkVariantsResponse',
  sdk_variants: Array<SdkVariant>,
};

export type MobileProtectApiKeysResponse = {
  __typename: 'MobileProtectApiKeysResponse',
  id: $ElementType<Scalars, 'String'>,
  key: $ElementType<Scalars, 'String'>,
  type: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  created_at: $ElementType<Scalars, 'Date'>,
};

export type FileUploadsResponse = {
  __typename: 'FileUploadsResponse',
  file_sha1_hash: $ElementType<Scalars, 'String'>,
  file_type: $ElementType<Scalars, 'String'>,
  original_file_name: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  created_by_actor_name: $ElementType<Scalars, 'String'>,
  status: FileUploadStatusEnum,
  uploader_comment?: ?$ElementType<Scalars, 'String'>,
  processing_completion_message?: ?$ElementType<Scalars, 'String'>,
  file_type_display_name: $ElementType<Scalars, 'String'>,
  file_type_icon_url?: ?$ElementType<Scalars, 'String'>,
  download_url?: ?$ElementType<Scalars, 'String'>,
  api_custom_checks_stats?: ?FileUploadApiCustomCheckStatsResponse,
};

export type FileUploadsListResponse = {
  __typename: 'FileUploadsListResponse',
  pagination_information: PaginationInformation,
  file_uploads: Array<FileUploadsResponse>,
};

export type ThreatBreakdown = {
  __typename: 'ThreatBreakdown',
  count: $ElementType<Scalars, 'Int'>,
  display_name: $ElementType<Scalars, 'String'>,
  security_event_type: $ElementType<Scalars, 'String'>,
  is_high_severity_security_event: $ElementType<Scalars, 'Boolean'>,
};

export type ThreatsBreakdownResponse = {
  __typename: 'ThreatsBreakdownResponse',
  threats_breakdown: Array<ThreatBreakdown>,
};

export type PaginationInformation = {
  __typename: 'PaginationInformation',
  next_cursor?: ?$ElementType<Scalars, 'ID'>,
  total_count?: ?$ElementType<Scalars, 'Float'>,
  exact_total_count?: ?$ElementType<Scalars, 'Float'>,
  hint_for_total_count?: ?$ElementType<Scalars, 'Float'>,
  is_approximate_total_count_enabled?: ?$ElementType<Scalars, 'Boolean'>,
};

export type PolicyViolationResponse = {
  __typename: 'PolicyViolationResponse',
  web_applications: Array<WebApplication>,
  network_services: Array<NetworkService>,
  domain_names: Array<DomainName>,
  policy_violations: Array<PolicyViolation>,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
  restful_apis: Array<RestfulApi>,
  graphql_apis: Array<GraphqlApi>,
  api_operations: Array<ApiOperation>,
  cloud_resources: Array<CloudResource>,
};

export type ApiOperationListResponse = {
  __typename: 'ApiOperationListResponse',
  pagination_information: PaginationInformation,
  api_operations: Array<ApiOperation>,
};

export type PolicyRuleTypesResponse = {
  __typename: 'PolicyRuleTypesResponse',
  policy_rule_types: Array<PolicyRuleType>,
};

export type PolicyRulesUpdateResponse = {
  __typename: 'PolicyRulesUpdateResponse',
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
  events: Array<Event>,
};

export type OnboardingCompletionResponse = {
  __typename: 'OnboardingCompletionResponse',
  onboarding_completed: $ElementType<Scalars, 'Boolean'>,
};

export type EmptyResponse = {
  __typename: 'EmptyResponse',
  nothing?: ?$ElementType<Scalars, 'Boolean'>,
};

export type AppUploadsResponse = {
  __typename: 'AppUploadsResponse',
  upload_url: $ElementType<Scalars, 'String'>,
};

export type ScanLogResponse = {
  __typename: 'ScanLogResponse',
  text: $ElementType<Scalars, 'String'>,
};

export type AssetGroupAccess = {
  __typename: 'AssetGroupAccess',
  asset_group_id: $ElementType<Scalars, 'String'>,
  asset_group_name: $ElementType<Scalars, 'String'>,
  is_accessible_to_user: $ElementType<Scalars, 'Boolean'>,
};

export type UserAssetGroupsResponse = {
  __typename: 'UserAssetGroupsResponse',
  asset_group_accesses: Array<?AssetGroupAccess>,
  has_access_to_all_asset_groups: $ElementType<Scalars, 'Boolean'>,
};

export type UserResponse = {
  __typename: 'UserResponse',
  pagination_information: PaginationInformation,
  users: Array<?User>,
};

export type CustomCheckType = {
  __typename: 'CustomCheckType',
  custom_check_type: $ElementType<Scalars, 'String'>,
  display_name: $ElementType<Scalars, 'String'>,
  onboarded_assets_count: $ElementType<Scalars, 'Float'>,
  open_policy_violations_count: $ElementType<Scalars, 'Float'>,
  subtitle?: ?$ElementType<Scalars, 'String'>,
};

export type CustomChecksRequestsResponse = {
  __typename: 'CustomChecksRequestsResponse',
  description: $ElementType<Scalars, 'String'>,
};

export type CustomCheckTypesResponse = {
  __typename: 'CustomCheckTypesResponse',
  custom_check_types?: ?Array<CustomCheckType>,
  pagination_information: PaginationInformation,
};

export type CustomCheckStatsResponse = {
  __typename: 'CustomCheckStatsResponse',
  assets_count: $ElementType<Scalars, 'Float'>,
  assets_with_onboarded_checks_count: $ElementType<Scalars, 'Float'>,
  weekly_assets_with_onboarded_checks_trend: $ElementType<Scalars, 'Float'>,
  weekly_custom_checks_scans_count: $ElementType<Scalars, 'Float'>,
  weekly_custom_checks_scans_trend: $ElementType<Scalars, 'Float'>,
  weekly_failed_custom_checks_scans_count: $ElementType<Scalars, 'Float'>,
  weekly_failed_custom_checks_scans_trend: $ElementType<Scalars, 'Float'>,
  weekly_passed_custom_checks_scans_count: $ElementType<Scalars, 'Float'>,
  weekly_passed_custom_checks_scans_trend: $ElementType<Scalars, 'Float'>,
};

export type VendorsListVendorIncludeSupplyChainSecureStats = {
  __typename: 'VendorsListVendorIncludeSupplyChainSecureStats',
  cloud_resources_count: $ElementType<Scalars, 'Float'>,
  graphql_apis_count: $ElementType<Scalars, 'Float'>,
  mobile_sdks_count: $ElementType<Scalars, 'Float'>,
  open_important_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_proactive_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_urgent_policy_violations_count: $ElementType<Scalars, 'Float'>,
  related_first_party_mobile_apps_count: $ElementType<Scalars, 'Float'>,
  related_first_party_web_apps_count: $ElementType<Scalars, 'Float'>,
  restful_apis_count: $ElementType<Scalars, 'Float'>,
};

export type VendorsListVendorIncludeSupplyChainSecureDashboardStats = {
  __typename: 'VendorsListVendorIncludeSupplyChainSecureDashboardStats',
  embed_rate_percent: $ElementType<Scalars, 'String'>,
  total_third_party_assets_count: $ElementType<Scalars, 'Float'>,
  total_third_party_assets_count_30_day_trend: $ElementType<Scalars, 'Float'>,
  total_urgent_policy_violations_count: $ElementType<Scalars, 'Float'>,
  total_urgent_policy_violations_count_30_day_trend: $ElementType<Scalars, 'Float'>,
};

export type VendorsListVendor = {
  __typename: 'VendorsListVendor',
  id: $ElementType<Scalars, 'ID'>,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  website_url?: ?$ElementType<Scalars, 'String'>,
  date_created?: ?$ElementType<Scalars, 'Date'>,
  date_updated?: ?$ElementType<Scalars, 'Date'>,
  included_supply_chain_secure_stats?: ?VendorsListVendorIncludeSupplyChainSecureStats,
  included_supply_chain_secure_dashboard_metrics?: ?VendorsListVendorIncludeSupplyChainSecureDashboardStats,
};

export type VendorsListResponse = {
  __typename: 'VendorsListResponse',
  pagination_information: PaginationInformation,
  vendors: Array<VendorsListVendor>,
};

export type SupplyChainSecureAppTopVendor = {
  __typename: 'SupplyChainSecureAppTopVendor',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  icon_url: $ElementType<Scalars, 'String'>,
};

export type SupplyChainSecureApp = {
  __typename: 'SupplyChainSecureApp',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  asset_type: AssetTypeEnum,
  icon_url: $ElementType<Scalars, 'String'>,
  open_urgent_policy_violations: $ElementType<Scalars, 'Float'>,
  open_important_policy_violations: $ElementType<Scalars, 'Float'>,
  open_proactive_policy_violations: $ElementType<Scalars, 'Float'>,
  related_third_party_cloud_resources_count: $ElementType<Scalars, 'Float'>,
  related_third_party_graphql_apis_count: $ElementType<Scalars, 'Float'>,
  related_third_party_mobile_sdks_count: $ElementType<Scalars, 'Float'>,
  related_third_party_restful_apis_count: $ElementType<Scalars, 'Float'>,
  bundle_id?: ?$ElementType<Scalars, 'String'>,
  extra_vendors_count?: ?$ElementType<Scalars, 'Float'>,
  url?: ?$ElementType<Scalars, 'String'>,
  version_id?: ?$ElementType<Scalars, 'String'>,
  top_vendors: Array<?SupplyChainSecureAppTopVendor>,
};

export type SupplyChainSecureAppsResponse = {
  __typename: 'SupplyChainSecureAppsResponse',
  pagination_information: PaginationInformation,
  supply_chain_secure_apps: Array<SupplyChainSecureApp>,
};

export type SupplyChainSecureMetricsDashboardAssetStatsByMonth = {
  __typename: 'SupplyChainSecureMetricsDashboardAssetStatsByMonth',
  cloud_resources_count?: ?$ElementType<Scalars, 'Float'>,
  combined_apis_count?: ?$ElementType<Scalars, 'Float'>,
  mobile_sdk_count?: ?$ElementType<Scalars, 'Float'>,
  display_month: $ElementType<Scalars, 'String'>,
  start_date: $ElementType<Scalars, 'Date'>,
};

export type SupplyChainSecureMetricsDashboardEmbedRatedVendor = {
  __typename: 'SupplyChainSecureMetricsDashboardEmbedRatedVendor',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  icon_url: $ElementType<Scalars, 'String'>,
  website_url?: ?$ElementType<Scalars, 'String'>,
};

export type SupplyChainSecureMetricsDashboard = {
  __typename: 'SupplyChainSecureMetricsDashboard',
  total_first_party_full_stack_apps_count: $ElementType<Scalars, 'Float'>,
  total_first_party_full_stack_apps_count_30_day_delta: $ElementType<Scalars, 'Float'>,
  total_high_embed_rated_vendors_count: $ElementType<Scalars, 'Float'>,
  total_low_embed_rated_vendors_count: $ElementType<Scalars, 'Float'>,
  total_mid_embed_rated_vendors_count: $ElementType<Scalars, 'Float'>,
  total_vendors_count: $ElementType<Scalars, 'Float'>,
  total_vendors_count_30_day_delta: $ElementType<Scalars, 'Float'>,
  total_third_party_assets_count: $ElementType<Scalars, 'Float'>,
  total_third_party_assets_count_30_day_delta: $ElementType<Scalars, 'Float'>,
  total_third_party_scans_count: $ElementType<Scalars, 'Float'>,
  total_third_party_scans_count_1_day_delta: $ElementType<Scalars, 'Float'>,
  asset_stats_by_month: Array<?SupplyChainSecureMetricsDashboardAssetStatsByMonth>,
  high_embed_rated_vendors: Array<?SupplyChainSecureMetricsDashboardEmbedRatedVendor>,
  mid_embed_rated_vendors: Array<?SupplyChainSecureMetricsDashboardEmbedRatedVendor>,
  low_embed_rated_vendors: Array<?SupplyChainSecureMetricsDashboardEmbedRatedVendor>,
};

export type SupplyChainSecureMetricsResponse = {
  __typename: 'SupplyChainSecureMetricsResponse',
  included_main_dashboard_metrics?: ?SupplyChainSecureMetricsDashboard,
};

export type HeadlinerHacksStats = {
  __typename: 'HeadlinerHacksStats',
  assets_could_be_affected_count: $ElementType<Scalars, 'Float'>,
  assets_not_affected_count: $ElementType<Scalars, 'Float'>,
  headliner_hack_icon_url: $ElementType<Scalars, 'String'>,
  headliner_hack_title: $ElementType<Scalars, 'String'>,
  open_policy_violations_count: $ElementType<Scalars, 'Float'>,
  relevant_policy_rule_type_ids: Array<?$ElementType<Scalars, 'ID'>>,
};

export type HeadlinerHacksStatsResponse = {
  __typename: 'HeadlinerHacksStatsResponse',
  headliner_hacks_stats: Array<?HeadlinerHacksStats>,
  pagination_information: PaginationInformation,
};

export type VendorManagementStatsResponse = {
  __typename: 'VendorManagementStatsResponse',
  web_apps_count: $ElementType<Scalars, 'Float'>,
  web_apps_count_30_day_delta: $ElementType<Scalars, 'Float'>,
  vendor_security_score_percent: $ElementType<Scalars, 'String'>,
  vendor_security_score_percent_30_day_delta: $ElementType<Scalars, 'String'>,
  total_assets_count: $ElementType<Scalars, 'Float'>,
  total_assets_count_30_day_delta: $ElementType<Scalars, 'Float'>,
  combined_apis_count: $ElementType<Scalars, 'Float'>,
  combined_apis_count_30_day_delta: $ElementType<Scalars, 'Float'>,
  mobile_sdks_count: $ElementType<Scalars, 'Float'>,
  mobile_sdks_count_30_day_delta: $ElementType<Scalars, 'Float'>,
  cloud_resources_count: $ElementType<Scalars, 'Float'>,
  cloud_resources_count_30_day_delta: $ElementType<Scalars, 'Float'>,
  mobile_apps_count: $ElementType<Scalars, 'Int'>,
  mobile_apps_count_30_day_delta: $ElementType<Scalars, 'Int'>,
};

export type NetworkThreatRelevance = {
  __typename: 'NetworkThreatRelevance',
  IMPORTANT: $ElementType<Scalars, 'Int'>,
  PROACTIVE: $ElementType<Scalars, 'Int'>,
  URGENT: $ElementType<Scalars, 'Int'>,
};

export type NetworkThreatType = {
  __typename: 'NetworkThreatType',
  count: $ElementType<Scalars, 'Int'>,
  threat_type: $ElementType<Scalars, 'String'>,
};

export type NetworkThreatEventsTrendItem = {
  __typename: 'NetworkThreatEventsTrendItem',
  datetime: $ElementType<Scalars, 'Date'>,
  threats_count?: ?Array<NetworkThreatType>,
};

export type NetworkThreatTitle = {
  __typename: 'NetworkThreatTitle',
  threat_type: $ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
};

export type NetworkTelemetryThreatEvent = {
  __typename: 'NetworkTelemetryThreatEvent',
  alphasoc_threat_title: $ElementType<Scalars, 'String'>,
  alphasoc_threat_type: $ElementType<Scalars, 'String'>,
  event_dns_qtype?: ?$ElementType<Scalars, 'String'>,
  event_dns_query?: ?$ElementType<Scalars, 'String'>,
  event_ip_action?: ?$ElementType<Scalars, 'String'>,
  event_ip_dest_ip?: ?$ElementType<Scalars, 'String'>,
  event_ip_dest_port?: ?$ElementType<Scalars, 'Int'>,
  event_ip_dest_proto?: ?$ElementType<Scalars, 'Int'>,
  event_ip_proto_name?: ?$ElementType<Scalars, 'String'>,
  src_host: $ElementType<Scalars, 'String'>,
  src_ip?: ?$ElementType<Scalars, 'String'>,
  src_port?: ?$ElementType<Scalars, 'Int'>,
  ts: $ElementType<Scalars, 'String'>,
  wisdom: $ElementType<Scalars, 'JSON'>,
};

export type NetworkTelemetryThreatEventsResponse = {
  __typename: 'NetworkTelemetryThreatEventsResponse',
  pagination_information: PaginationInformation,
  threat_events?: ?Array<NetworkTelemetryThreatEvent>,
};

export type NetworkTelemetryPolicyViolationSummaryResponse = {
  __typename: 'NetworkTelemetryPolicyViolationSummaryResponse',
  network_telemetry_policy_violations?: ?Array<PolicyViolationV2>,
  pagination_information: PaginationInformation,
};

export type NetworkTelemetryEventCountItem = {
  __typename: 'NetworkTelemetryEventCountItem',
  count: $ElementType<Scalars, 'Float'>,
  event_type: $ElementType<Scalars, 'String'>,
  event_type_name: $ElementType<Scalars, 'String'>,
};

export type NetworkTelemetryThreatEventsStatsSummary = {
  __typename: 'NetworkTelemetryThreatEventsStatsSummary',
  events_count: Array<NetworkTelemetryEventCountItem>,
  primary_metric_count: $ElementType<Scalars, 'Float'>,
  primary_metric_name: $ElementType<Scalars, 'String'>,
  secondary_metric_count: $ElementType<Scalars, 'Float'>,
  secondary_metric_name: $ElementType<Scalars, 'Float'>,
};

export type PerCountryEventsStatsResponseItemEventCount = {
  __typename: 'PerCountryEventsStatsResponseItemEventCount',
  event_type: $ElementType<Scalars, 'String'>,
  event_type_name: $ElementType<Scalars, 'String'>,
  count: $ElementType<Scalars, 'Float'>,
};

export type PerCountryEventsStatsResponseItem = {
  __typename: 'PerCountryEventsStatsResponseItem',
  country_code: $ElementType<Scalars, 'String'>,
  country_name: $ElementType<Scalars, 'String'>,
  total_events_count: $ElementType<Scalars, 'Float'>,
  events_count: Array<PerCountryEventsStatsResponseItemEventCount>,
  subdivisions?: ?Array<?PerCountryEventsStatsResponseItemSubdvision>,
};

export type PerCountryEventsStatsResponseItemSubdvision = {
  __typename: 'PerCountryEventsStatsResponseItemSubdvision',
  subdivision_code: $ElementType<Scalars, 'String'>,
  subdivision_name: $ElementType<Scalars, 'String'>,
  total_events_count: $ElementType<Scalars, 'Float'>,
  events_count: Array<PerCountryEventsStatsResponseItemEventCount>,
};

export type PerCountryEventsStatsResponse = {
  __typename: 'PerCountryEventsStatsResponse',
  per_country_events_stats: Array<PerCountryEventsStatsResponseItem>,
};

export type NetworkTelemetryEventStatsPerDateItem = {
  __typename: 'NetworkTelemetryEventStatsPerDateItem',
  date: $ElementType<Scalars, 'String'>,
  events_count: Array<NetworkTelemetryEventCountItem>,
  primary_metric_count: $ElementType<Scalars, 'Float'>,
  primary_metric_name: $ElementType<Scalars, 'String'>,
  secondary_metric_name: $ElementType<Scalars, 'String'>,
  secondary_metric_count: $ElementType<Scalars, 'Float'>,
};

export type NetworkTelemetryEventsStatsSummary = {
  __typename: 'NetworkTelemetryEventsStatsSummary',
  events_count: Array<NetworkTelemetryEventCountItem>,
  primary_metric_count: $ElementType<Scalars, 'Float'>,
  primary_metric_name: $ElementType<Scalars, 'String'>,
  secondary_metric_name: $ElementType<Scalars, 'String'>,
  secondary_metric_count: $ElementType<Scalars, 'Float'>,
};

export type NetworkThreatEventStatsResponse = {
  __typename: 'NetworkThreatEventStatsResponse',
  stats_per_date: Array<NetworkTelemetryEventStatsPerDateItem>,
  stats_summary: NetworkTelemetryEventsStatsSummary,
};

export type NetworkTelemetryConfigurationsResponse = {
  __typename: 'NetworkTelemetryConfigurationsResponse',
  aws_route53_resolver_query_logs_destination: $ElementType<Scalars, 'String'>,
  aws_vpc_flow_logs_destination: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  is_new: $ElementType<Scalars, 'Boolean'>,
};

export type NetworkThreatEvent = {
  __typename: 'NetworkThreatEvent',
  event_type_name: $ElementType<Scalars, 'String'>,
  count: $ElementType<Scalars, 'Float'>,
  event_type: $ElementType<Scalars, 'String'>,
};

export type NetworkThreatEventStatsPerDate = {
  __typename: 'NetworkThreatEventStatsPerDate',
  primary_metric_count: $ElementType<Scalars, 'Float'>,
  primary_metric_name: $ElementType<Scalars, 'String'>,
  secondary_metric_count: $ElementType<Scalars, 'Float'>,
  secondary_metric_name: $ElementType<Scalars, 'String'>,
  events_count: Array<NetworkThreatEvent>,
  date: $ElementType<Scalars, 'String'>,
};

export type NetworkThreatEventStatsSummary = {
  __typename: 'NetworkThreatEventStatsSummary',
  primary_metric_count: $ElementType<Scalars, 'Float'>,
  primary_metric_name: $ElementType<Scalars, 'String'>,
  secondary_metric_count: $ElementType<Scalars, 'Float'>,
  secondary_metric_name: $ElementType<Scalars, 'String'>,
  events_count: Array<NetworkThreatEvent>,
};

export type Oauth2AuthDetails = {
  __typename: 'Oauth2AuthDetails',
  authentication_url: $ElementType<Scalars, 'String'>,
  username: $ElementType<Scalars, 'String'>,
  password: $ElementType<Scalars, 'String'>,
};

export type ApikeyAuthDetails = {
  __typename: 'ApikeyAuthDetails',
  location: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
};

export type BasicAuthAuthDetails = {
  __typename: 'BasicAuthAuthDetails',
  username?: ?$ElementType<Scalars, 'String'>,
  password?: ?$ElementType<Scalars, 'String'>,
};

export type DescribedInUploadedFileAuthDetails = {
  __typename: 'DescribedInUploadedFileAuthDetails',
  uploaded_authentication_file_hash: $ElementType<Scalars, 'String'>,
};

export type UploadedAuthenticationDetails = {
  __typename: 'UploadedAuthenticationDetails',
  id: $ElementType<Scalars, 'String'>,
  authentication_type: $ElementType<Scalars, 'String'>,
  onboarded_for_asset_name?: ?$ElementType<Scalars, 'String'>,
  oauth2_authentication_details?: ?Oauth2AuthDetails,
  api_keys_authentication_details?: ?ApikeyAuthDetails,
  basic_auth_authentication_details?: ?BasicAuthAuthDetails,
  described_in_uploaded_file_authentication_details?: ?DescribedInUploadedFileAuthDetails,
};

export type UploadedAuthenticationDetailsResponse = {
  __typename: 'UploadedAuthenticationDetailsResponse',
  pagination_information: PaginationInformation,
  uploaded_authentication_details?: ?Array<UploadedAuthenticationDetails>,
};

export const HorizonEventTypeEnumValues = Object.freeze({
  USER_LOGGED_IN: 'USER_LOGGED_IN', 
  NETWORK_SERVICE_DISCOVERED: 'NETWORK_SERVICE_DISCOVERED', 
  RESTFUL_API_DISCOVERED: 'RESTFUL_API_DISCOVERED', 
  CLOUD_RESOURCE_DISCOVERED: 'CLOUD_RESOURCE_DISCOVERED', 
  AWS_AUTHENTICATOR_CREATED: 'AWS_AUTHENTICATOR_CREATED', 
  POLICY_VIOLATION_CREATED: 'POLICY_VIOLATION_CREATED', 
  POLICY_VIOLATION_RESOLVED: 'POLICY_VIOLATION_RESOLVED', 
  POLICY_VIOLATION_EXCEPTION_ADDED: 'POLICY_VIOLATION_EXCEPTION_ADDED', 
  NETWORK_SERVICE_WENT_OFFLINE: 'NETWORK_SERVICE_WENT_OFFLINE', 
  NETWORK_SERVICE_WENT_ONLINE: 'NETWORK_SERVICE_WENT_ONLINE', 
  COMMENT_CREATED: 'COMMENT_CREATED', 
  COMMENT_DELETED: 'COMMENT_DELETED', 
  OPENAPI_DEFINITION_CREATED: 'OPENAPI_DEFINITION_CREATED', 
  RESTFUL_API_UPDATED: 'RESTFUL_API_UPDATED', 
  API_OPERATION_UPDATED: 'API_OPERATION_UPDATED', 
  API_OPERATION_NO_LONGER_ACCESSIBLE: 'API_OPERATION_NO_LONGER_ACCESSIBLE', 
  API_OPERATION_CREATED: 'API_OPERATION_CREATED', 
  CERTIFICATE_DISCOVERED: 'CERTIFICATE_DISCOVERED', 
  CERTIFICATE_CHAIN_DISCOVERED: 'CERTIFICATE_CHAIN_DISCOVERED', 
  CERTIFICATE_CHAIN_REMOVED: 'CERTIFICATE_CHAIN_REMOVED', 
  AZURE_AUTHENTICATOR_CREATED: 'AZURE_AUTHENTICATOR_CREATED', 
  AZURE_AUTHENTICATOR_UPDATED: 'AZURE_AUTHENTICATOR_UPDATED', 
  GCP_AUTHENTICATOR_CREATED: 'GCP_AUTHENTICATOR_CREATED', 
  GCP_AUTHENTICATOR_KEY_UPDATED: 'GCP_AUTHENTICATOR_KEY_UPDATED', 
  APIGEE_AUTHENTICATOR_CREATED: 'APIGEE_AUTHENTICATOR_CREATED', 
  POLICY_RULE_CREATED: 'POLICY_RULE_CREATED', 
  POLICY_RULE_DELETED: 'POLICY_RULE_DELETED', 
  IP_RANGE_CREATED: 'IP_RANGE_CREATED', 
  IP_RANGE_UPDATED: 'IP_RANGE_UPDATED', 
  WEB_APPLICATION_DISCOVERED: 'WEB_APPLICATION_DISCOVERED', 
  MULESOFT_AUTHENTICATOR_CREATED: 'MULESOFT_AUTHENTICATOR_CREATED', 
  POLICY_RULE_UPDATED: 'POLICY_RULE_UPDATED', 
  APIGEE_ON_PREM_AUTHENTICATOR_CREATED: 'APIGEE_ON_PREM_AUTHENTICATOR_CREATED', 
  ALERTS_INTEGRATION_CREATED: 'ALERTS_INTEGRATION_CREATED', 
  ALERTS_INTEGRATION_UPDATED: 'ALERTS_INTEGRATION_UPDATED', 
  ALERTS_INTEGRATION_DELETED: 'ALERTS_INTEGRATION_DELETED', 
  ASSET_GROUP_CREATED: 'ASSET_GROUP_CREATED', 
  ASSET_GROUP_DELETED: 'ASSET_GROUP_DELETED', 
  ASSET_GROUP_UPDATED: 'ASSET_GROUP_UPDATED', 
  ASSET_GROUP_MEMBERSHIP_CREATED: 'ASSET_GROUP_MEMBERSHIP_CREATED', 
  ASSET_GROUP_MEMBERSHIP_DELETED: 'ASSET_GROUP_MEMBERSHIP_DELETED', 
  POLICY_CREATED: 'POLICY_CREATED', 
  POLICY_DELETED: 'POLICY_DELETED', 
  POLICY_UPDATED: 'POLICY_UPDATED', 
  KONG_AUTHENTICATOR_CREATED: 'KONG_AUTHENTICATOR_CREATED', 
  KONG_AUTHENTICATOR_UPDATED: 'KONG_AUTHENTICATOR_UPDATED', 
  SCOPED_ACCESS_TOKEN_CREATED: 'SCOPED_ACCESS_TOKEN_CREATED', 
  SCOPED_ACCESS_TOKEN_DELETED: 'SCOPED_ACCESS_TOKEN_DELETED', 
  DOMAIN_NAME_OWNERSHIP_UPDATED: 'DOMAIN_NAME_OWNERSHIP_UPDATED', 
  RESTFUL_API_OWNERSHIP_UPDATED: 'RESTFUL_API_OWNERSHIP_UPDATED', 
  CLOUD_AUTHENTICATOR_CREATED: 'CLOUD_AUTHENTICATOR_CREATED', 
  CLOUD_AUTHENTICATOR_UPDATED: 'CLOUD_AUTHENTICATOR_UPDATED', 
  AUTO_REMEDIATION_ATTEMPT_CREATED: 'AUTO_REMEDIATION_ATTEMPT_CREATED', 
  AUTO_REMEDIATION_ATTEMPT_STARTED: 'AUTO_REMEDIATION_ATTEMPT_STARTED', 
  AUTO_REMEDIATION_ATTEMPT_SUCCEEDED: 'AUTO_REMEDIATION_ATTEMPT_SUCCEEDED', 
  AUTO_REMEDIATION_ATTEMPT_FAILED: 'AUTO_REMEDIATION_ATTEMPT_FAILED', 
  AUTO_REMEDIATION_ATTEMPT_BATCH_CREATED: 'AUTO_REMEDIATION_ATTEMPT_BATCH_CREATED', 
  AXWAY_AUTHENTICATOR_CREATED: 'AXWAY_AUTHENTICATOR_CREATED', 
  AXWAY_AUTHENTICATOR_UPDATED: 'AXWAY_AUTHENTICATOR_UPDATED', 
  RESTFUL_API_NO_LONGER_ACCESSIBLE: 'RESTFUL_API_NO_LONGER_ACCESSIBLE', 
  WEB_APPLICATION_NO_LONGER_ACCESSIBLE: 'WEB_APPLICATION_NO_LONGER_ACCESSIBLE', 
  CLOUD_RESOURCE_DELETED_FROM_CLOUD: 'CLOUD_RESOURCE_DELETED_FROM_CLOUD', 
  POLICY_VIOLATION_TRIAGING_EFFORT_CREATED: 'POLICY_VIOLATION_TRIAGING_EFFORT_CREATED', 
  POLICY_VIOLATION_TRIAGING_EFFORT_COMPLETED: 'POLICY_VIOLATION_TRIAGING_EFFORT_COMPLETED', 
  POLICY_VIOLATION_TRIAGING_EFFORT_DELETED: 'POLICY_VIOLATION_TRIAGING_EFFORT_DELETED', 
  TOOLKIT_SCANS_SCHEDULE_ENABLED: 'TOOLKIT_SCANS_SCHEDULE_ENABLED', 
  TOOLKIT_SCANS_SCHEDULE_UPDATED: 'TOOLKIT_SCANS_SCHEDULE_UPDATED', 
  TOOLKIT_SCANS_SCHEDULE_DISABLED: 'TOOLKIT_SCANS_SCHEDULE_DISABLED', 
  API_CREDENTIAL_CREATED: 'API_CREDENTIAL_CREATED', 
  API_CREDENTIAL_DELETED: 'API_CREDENTIAL_DELETED', 
  GRAPHQL_API_CREATED: 'GRAPHQL_API_CREATED', 
  GRAPHQL_API_NO_LONGER_ACCESSIBLE: 'GRAPHQL_API_NO_LONGER_ACCESSIBLE', 
  WEB_APPLICATION_WENT_ONLINE: 'WEB_APPLICATION_WENT_ONLINE', 
  CLOUD_RESOURCE_WENT_ONLINE: 'CLOUD_RESOURCE_WENT_ONLINE', 
  RESTFUL_API_WENT_ONLINE: 'RESTFUL_API_WENT_ONLINE', 
  GRAPHQL_API_WENT_ONLINE: 'GRAPHQL_API_WENT_ONLINE'
});


export type HorizonEventTypeEnum = $Values<typeof HorizonEventTypeEnumValues>;

export const FileUploadStatusEnumValues = Object.freeze({
  PROCESSED: 'PROCESSED', 
  PENDING: 'PENDING', 
  INVALID: 'INVALID'
});


export type FileUploadStatusEnum = $Values<typeof FileUploadStatusEnumValues>;

export const SevenhellEventTypeEnumValues = Object.freeze({
  COMMENT: 'COMMENT', 
  PERMANENTLY_CLOSE_ISSUE: 'PERMANENTLY_CLOSE_ISSUE', 
  CLOSE_TARGET: 'CLOSE_TARGET', 
  CHANGE_PRIORITY: 'CHANGE_PRIORITY', 
  RE_OPEN_ISSUE: 'RE_OPEN_ISSUE', 
  RE_OPEN_TARGET: 'RE_OPEN_TARGET', 
  ADD_NEW_APP: 'ADD_NEW_APP'
});


export type SevenhellEventTypeEnum = $Values<typeof SevenhellEventTypeEnumValues>;

export const ScanRunsTypeEnumValues = Object.freeze({
  WEB_APPLICATION_XSS: 'WEB_APPLICATION_XSS', 
  CLOUD_RANSOMWARE: 'CLOUD_RANSOMWARE'
});


export type ScanRunsTypeEnum = $Values<typeof ScanRunsTypeEnumValues>;

export const ScanRunsStatusEnumValues = Object.freeze({
  COMPLETED: 'COMPLETED', 
  ONGOING: 'ONGOING', 
  STARTED: 'STARTED', 
  QUEUED: 'QUEUED', 
  FAILED: 'FAILED'
});


export type ScanRunsStatusEnum = $Values<typeof ScanRunsStatusEnumValues>;

export type ScanRun = {
  __typename: 'ScanRun',
  uuid: $ElementType<Scalars, 'ID'>,
  scan_type: ScanRunsTypeEnum,
  status: ScanRunsStatusEnum,
  date_created?: ?$ElementType<Scalars, 'Date'>,
  date_started?: ?$ElementType<Scalars, 'Date'>,
  date_finished?: ?$ElementType<Scalars, 'Date'>,
  logs_file_url?: ?$ElementType<Scalars, 'String'>,
  result_issues_found_count?: ?$ElementType<Scalars, 'Float'>,
  result_markdown?: ?$ElementType<Scalars, 'String'>,
  scanned_asset_display_name?: ?$ElementType<Scalars, 'String'>,
  triggered_by_actor_display_name?: ?$ElementType<Scalars, 'String'>,
};

export type ScanRunsResponse = {
  __typename: 'ScanRunsResponse',
  pagination_information: PaginationInformation,
  scan_runs: Array<ScanRun>,
};

export type ComplianceReportResponse = {
  __typename: 'ComplianceReportResponse',
  download_url: $ElementType<Scalars, 'String'>,
};

export type ToolkitScans = {
  __typename: 'ToolkitScans',
  date_created: $ElementType<Scalars, 'Date'>,
  date_finished?: ?$ElementType<Scalars, 'Date'>,
  status: SpecialScanStatus,
  toolkit_scan_type: ToolkitScanTypeEnum,
};

export type ToolkitScansResponse = {
  __typename: 'ToolkitScansResponse',
  pagination_information: PaginationInformation,
  toolkit_scans: Array<ToolkitScans>,
};

export const FingerprintedResourceCategoryEnumValues = Object.freeze({
  FINGERPRINTED_AWS_S3_BUCKET: 'FINGERPRINTED_AWS_S3_BUCKET', 
  FINGERPRINTED_GCP_CLOUD_STORAGE_BUCKET: 'FINGERPRINTED_GCP_CLOUD_STORAGE_BUCKET', 
  FINGERPRINTED_DOCKER_REGISTRY: 'FINGERPRINTED_DOCKER_REGISTRY', 
  FINGERPRINTED_REDIS_DATABASE: 'FINGERPRINTED_REDIS_DATABASE', 
  FINGERPRINTED_MONGODB_INSTANCE: 'FINGERPRINTED_MONGODB_INSTANCE', 
  FINGERPRINTED_AZURE_STORAGE_ACCOUNT_BLOB_CONTAINER: 'FINGERPRINTED_AZURE_STORAGE_ACCOUNT_BLOB_CONTAINER', 
  FINGERPRINTED_CASSANDRA_DB_DATABASE: 'FINGERPRINTED_CASSANDRA_DB_DATABASE', 
  FINGERPRINTED_COUCHDB_DATABASE: 'FINGERPRINTED_COUCHDB_DATABASE', 
  FINGERPRINTED_ELASTICSEARCH_DATABASE: 'FINGERPRINTED_ELASTICSEARCH_DATABASE', 
  FINGERPRINTED_SOLR_DATABASE: 'FINGERPRINTED_SOLR_DATABASE'
});


export type FingerprintedResourceCategoryEnum = $Values<typeof FingerprintedResourceCategoryEnumValues>;

export type DomanNamesFingerprintedResourceCategoriesStats = {
  __typename: 'DomanNamesFingerprintedResourceCategoriesStats',
  fingerprinted_resource_category?: ?FingerprintedResourceCategoryEnum,
  fingerprinted_resources_count?: ?$ElementType<Scalars, 'Float'>,
  unauthenticated_fingerprinted_resources_count?: ?$ElementType<Scalars, 'Float'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
};

export type DomainNamesLastScanStats = {
  __typename: 'DomainNamesLastScanStats',
  scanned_domain_names_count?: ?$ElementType<Scalars, 'Float'>,
  fingerprinted_resources_count?: ?$ElementType<Scalars, 'Float'>,
  unauthenticated_fingerprinted_resources_count?: ?$ElementType<Scalars, 'Float'>,
  date_since_last_breached?: ?$ElementType<Scalars, 'Date'>,
};

export type FingerprintedResourceStatsField = {
  __typename: 'FingerprintedResourceStatsField',
  fingerprinted_resources_categories_stats: Array<DomanNamesFingerprintedResourceCategoriesStats>,
  last_scan_stats: DomainNamesLastScanStats,
};

export type DomainNamesStatsResponse = {
  __typename: 'DomainNamesStatsResponse',
  fingerprinted_resource_stats_field: FingerprintedResourceStatsField,
};

export type DomainNameListResponse = {
  __typename: 'DomainNameListResponse',
  pagination_information: PaginationInformation,
  domain_names: Array<DomainName>,
};

export type DomainNamesDiscoveredUrlsListResponse = {
  __typename: 'DomainNamesDiscoveredUrlsListResponse',
  pagination_information: PaginationInformation,
  discovered_urls: Array<DomainNameDiscoveredUrl>,
};

export type AssetGroupField = {
  __typename: 'AssetGroupField',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
};

export type IncludedCloudRansomwareStats = {
  __typename: 'IncludedCloudRansomwareStats',
  last_scan_date?: ?$ElementType<Scalars, 'String'>,
  total_files_count: $ElementType<Scalars, 'Float'>,
  last_scan_change_files_count: $ElementType<Scalars, 'Float'>,
  total_directories_count: $ElementType<Scalars, 'Float'>,
  last_scan_change_directories_count: $ElementType<Scalars, 'Float'>,
  is_publicly_writable: $ElementType<Scalars, 'Boolean'>,
  is_publicly_readable: $ElementType<Scalars, 'Boolean'>,
  is_malware_detected: $ElementType<Scalars, 'Boolean'>,
  is_ransomware_detected: $ElementType<Scalars, 'Boolean'>,
};

export type AssetIncludedApiRadarStats = {
  __typename: 'AssetIncludedApiRadarStats',
  last_tested_date?: ?$ElementType<Scalars, 'Date'>,
  active_protection_status?: ?$ElementType<Scalars, 'String'>,
  api_authentication_scheme_names?: ?Array<?$ElementType<Scalars, 'String'>>,
  api_protect_api_calls_analyzed_count?: ?$ElementType<Scalars, 'Float'>,
  has_active_protection_enabled: $ElementType<Scalars, 'Boolean'>,
  insecure_operations_count: $ElementType<Scalars, 'Float'>,
  secure_operations_count: $ElementType<Scalars, 'Float'>,
  total_operations_count: $ElementType<Scalars, 'Float'>,
};

export type IncludedSupplyChainSecureDetailsVendor = {
  __typename: 'IncludedSupplyChainSecureDetailsVendor',
  id: $ElementType<Scalars, 'ID'>,
  icon_url: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
};

export type IncludedSupplyChainSecureDetails = {
  __typename: 'IncludedSupplyChainSecureDetails',
  related_mobile_apps_count: $ElementType<Scalars, 'Float'>,
  related_web_apps_count: $ElementType<Scalars, 'Float'>,
  vendor?: ?IncludedSupplyChainSecureDetailsVendor,
};

export type AssetIncludedApiCustomCheckDetails = {
  __typename: 'AssetIncludedApiCustomCheckDetails',
  api_operation_exposed_methods?: ?Array<$ElementType<Scalars, 'String'>>,
  api_operations_count?: ?$ElementType<Scalars, 'Float'>,
  onboarded_api_custom_check_types?: ?Array<$ElementType<Scalars, 'String'>>,
};

export type AssetIncludedVendorManagementStats = {
  __typename: 'AssetIncludedVendorManagementStats',
  resolved_violations_count: $ElementType<Scalars, 'Float'>,
  wont_fix_violations_count: $ElementType<Scalars, 'Float'>,
};

export type AssetIncludedNetworkTelemetryStats = {
  __typename: 'AssetIncludedNetworkTelemetryStats',
  sources?: ?Array<$ElementType<Scalars, 'String'>>,
  telemetry_sources?: ?Array<$ElementType<Scalars, 'String'>>,
  last_event_date: $ElementType<Scalars, 'Date'>,
  open_network_telemetry_policy_violations_count: $ElementType<Scalars, 'Int'>,
  open_urgent_network_telemetry_policy_violations_count: $ElementType<Scalars, 'Int'>,
  open_important_network_telemetry_policy_violations_count: $ElementType<Scalars, 'Int'>,
  open_proactive_network_telemetry_policy_violations_count: $ElementType<Scalars, 'Int'>,
};

export type AssetIncludedV2Fields = {
  __typename: 'AssetIncludedV2Fields',
  detected_pii_types: Array<$ElementType<Scalars, 'String'>>,
  is_internet_accessible: $ElementType<Scalars, 'Boolean'>,
  is_compliant_to_security_policy: $ElementType<Scalars, 'Boolean'>,
};

export type AssetSummaryField = {
  __typename: 'AssetSummaryField',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  url?: ?$ElementType<Scalars, 'String'>,
  status: AssetStatus,
  asset_type: AssetTypeEnum,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  belongs_to_asset_group?: ?AssetGroupField,
  date_created: $ElementType<Scalars, 'Date'>,
  date_no_longer_accessible?: ?$ElementType<Scalars, 'String'>,
  discovered_via: DiscoveredVia,
  discovered_via_icon_url: $ElementType<Scalars, 'String'>,
  discovered_via_name: $ElementType<Scalars, 'String'>,
  hosted_on?: ?HostedOn,
  open_important_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_proactive_policy_violations_count: $ElementType<Scalars, 'Float'>,
  open_urgent_policy_violations_count: $ElementType<Scalars, 'Float'>,
  included_cloud_ransomware_stats?: ?IncludedCloudRansomwareStats,
  hosted_on_icon_url: $ElementType<Scalars, 'String'>,
  hosted_on_name: $ElementType<Scalars, 'String'>,
  tags: Array<?AssetTag>,
  included_api_radar_stats?: ?AssetIncludedApiRadarStats,
  included_supply_chain_secure_details?: ?IncludedSupplyChainSecureDetails,
  included_api_custom_check_details?: ?AssetIncludedApiCustomCheckDetails,
  included_vendor_management_stats?: ?AssetIncludedVendorManagementStats,
  included_network_telemetry_stats?: ?AssetIncludedNetworkTelemetryStats,
  included_v2_fields?: ?AssetIncludedV2Fields,
};

export type AssetListResponse = {
  __typename: 'AssetListResponse',
  pagination_information: PaginationInformation,
  assets: Array<AssetSummaryField>,
};

export type ValidateScopedAccessTokensResponse = {
  __typename: 'ValidateScopedAccessTokensResponse',
  description?: ?$ElementType<Scalars, 'String'>,
};

export type AssetsSearchFilterValue = {
  __typename: 'AssetsSearchFilterValue',
  belongs_to_filter: $ElementType<Scalars, 'String'>,
  icon_url: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
  value_category?: ?$ElementType<Scalars, 'String'>,
};

export type AssetsSearchFilterValuesResponse = {
  __typename: 'AssetsSearchFilterValuesResponse',
  assets_search_filter_values?: ?Array<?AssetsSearchFilterValue>,
};

export type DiscoveredAppsSearchFilterValuesResponse = {
  __typename: 'DiscoveredAppsSearchFilterValuesResponse',
  discovered_apps_search_filter_values?: ?Array<?AssetsSearchFilterValue>,
};

export type MaterialsSearchFilterValuesResponse = {
  __typename: 'MaterialsSearchFilterValuesResponse',
  materials_search_filter_values?: ?Array<?AssetsSearchFilterValue>,
};

export type PostmanWorkflowRelatedAsset = {
  __typename: 'PostmanWorkflowRelatedAsset',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  asset_type: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  asset_type_icon_url?: ?$ElementType<Scalars, 'String'>,
  url?: ?$ElementType<Scalars, 'String'>,
};

export type CustomerUploadedFileType = {
  __typename: 'CustomerUploadedFileType',
  created_by_actor_name: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  file_sha1_hash: $ElementType<Scalars, 'String'>,
  file_type: $ElementType<Scalars, 'String'>,
  file_type_display_name: $ElementType<Scalars, 'String'>,
  file_type_icon_url: $ElementType<Scalars, 'String'>,
  original_file_name: $ElementType<Scalars, 'String'>,
  processing_completion_message?: ?$ElementType<Scalars, 'String'>,
  status: $ElementType<Scalars, 'String'>,
  uploader_comment?: ?$ElementType<Scalars, 'String'>,
};

export type PostmanWorkflowSummaryField = {
  __typename: 'PostmanWorkflowSummaryField',
  id: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  last_scan_date?: ?$ElementType<Scalars, 'String'>,
  last_successful_scan_date?: ?$ElementType<Scalars, 'String'>,
  next_scan_date?: ?$ElementType<Scalars, 'Date'>,
  next_scan_date_fallback: $ElementType<Scalars, 'String'>,
  postman_collection_filename?: ?$ElementType<Scalars, 'String'>,
  postman_environment_filename?: ?$ElementType<Scalars, 'String'>,
  postman_collection_hash?: ?$ElementType<Scalars, 'String'>,
  postman_environment_hash?: ?$ElementType<Scalars, 'String'>,
  purpose: PostmanPurposeEnum,
  workflow_display_name: $ElementType<Scalars, 'String'>,
  related_asset?: ?PostmanWorkflowRelatedAsset,
  custom_check_detailed_name?: ?$ElementType<Scalars, 'String'>,
  custom_check_type?: ?$ElementType<Scalars, 'String'>,
  open_policy_violation_id?: ?$ElementType<Scalars, 'String'>,
  customer_uploaded_files_used_to_build_check?: ?Array<CustomerUploadedFileType>,
  workflow_status_display_name: $ElementType<Scalars, 'String'>,
  workflow_status_display_color: PortalStatusDisplayStyleEnum,
};

export type PostmanWorkflowsResponse = {
  __typename: 'PostmanWorkflowsResponse',
  pagination_information: PaginationInformation,
  postman_workflows: Array<PostmanWorkflowSummaryField>,
};

export type PostmanWorkflowsGetResponse = {
  __typename: 'PostmanWorkflowsGetResponse',
  id?: ?$ElementType<Scalars, 'String'>,
  workflow_display_name?: ?$ElementType<Scalars, 'String'>,
  last_scan_status?: ?PostmanCheckScanStatusEnum,
  last_scan_date?: ?$ElementType<Scalars, 'String'>,
  onboarding_status: PostmanOnboardingStatusEnum,
  custom_check_result_as_json?: ?$ElementType<Scalars, 'String'>,
  custom_check_result_fallback?: ?$ElementType<Scalars, 'String'>,
  custom_check_issues_count?: ?$ElementType<Scalars, 'Float'>,
  open_policy_violation_id?: ?$ElementType<Scalars, 'ID'>,
  related_asset?: ?PostmanWorkflowRelatedAsset,
};

export type CloudDlpToolkitConfigDataCloudResourceType = {
  __typename: 'CloudDlpToolkitConfigDataCloudResourceType',
  cloud_resource_type: $ElementType<Scalars, 'String'>,
  cloud_resource_type_display_name: $ElementType<Scalars, 'String'>,
  cloud_resource_type_icon_url: $ElementType<Scalars, 'String'>,
};

export type CloudDlpToolkitConfigDataGooglePiiTypes = {
  __typename: 'CloudDlpToolkitConfigDataGooglePiiTypes',
  data_type: $ElementType<Scalars, 'String'>,
  display_name: $ElementType<Scalars, 'String'>,
};

export type CloudDlpToolkitConfigDataGetResponse = {
  __typename: 'CloudDlpToolkitConfigDataGetResponse',
  supported_aws_cloud_resource_types: Array<CloudDlpToolkitConfigDataCloudResourceType>,
  supported_gcp_cloud_resource_types: Array<CloudDlpToolkitConfigDataCloudResourceType>,
  supported_azure_cloud_resource_types: Array<CloudDlpToolkitConfigDataCloudResourceType>,
  supported_other_cloud_resource_types: Array<CloudDlpToolkitConfigDataCloudResourceType>,
  google_pii_types: Array<CloudDlpToolkitConfigDataGooglePiiTypes>,
};

export type AssetTag = {
  __typename: 'AssetTag',
  id: $ElementType<Scalars, 'ID'>,
  tag_id: $ElementType<Scalars, 'String'>,
  tag: $ElementType<Scalars, 'String'>,
  value?: ?$ElementType<Scalars, 'String'>,
  imported_from?: ?AssetTagImportSourceEnum,
  imported_external_id?: ?$ElementType<Scalars, 'String'>,
  imported_from_icon_url?: ?$ElementType<Scalars, 'String'>,
};

export type AssetTagKey = {
  __typename: 'assetTagKey',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  imported_from?: ?AssetTagImportSourceEnum,
  imported_external_id?: ?$ElementType<Scalars, 'String'>,
  imported_from_icon_url?: ?$ElementType<Scalars, 'String'>,
};

export type AssetTagsResponse = {
  __typename: 'AssetTagsResponse',
  asset_tags: Array<AssetTag>,
};

export type AssetSpecificationFileResponse = {
  __typename: 'AssetSpecificationFileResponse',
  content: $ElementType<Scalars, 'String'>,
  filename: $ElementType<Scalars, 'String'>,
};

export type AssetTagsKeysResponse = {
  __typename: 'AssetTagsKeysResponse',
  pagination_information: PaginationInformation,
  asset_tag_keys: Array<AssetTagKey>,
};

export type AssetTagsValuesResponse = {
  __typename: 'AssetTagsValuesResponse',
  pagination_information: PaginationInformation,
  asset_tags: Array<AssetTag>,
};

export type ProtectedWebAppSummaryField = {
  __typename: 'ProtectedWebAppSummaryField',
  web_application_id: $ElementType<Scalars, 'String'>,
  web_application_url: $ElementType<Scalars, 'String'>,
  overall_protection_score: $ElementType<Scalars, 'Float'>,
};

export type ProtectionSummaryField = {
  __typename: 'ProtectionSummaryField',
  total_protection_score: $ElementType<Scalars, 'Float'>,
  average_protection_score: $ElementType<Scalars, 'Float'>,
  most_protected_web_app_summary?: ?ProtectedWebAppSummaryField,
  least_protected_web_app_summary?: ?ProtectedWebAppSummaryField,
};

export type XssProtectionLevelField = {
  __typename: 'XSSProtectionLevelField',
  completed_web_applications_count: $ElementType<Scalars, 'Float'>,
  incomplete_web_applications_count: $ElementType<Scalars, 'Float'>,
  per_level_web_applications_count?: ?$ElementType<Scalars, 'Float'>,
};

export type LevelSummariesField = {
  __typename: 'LevelSummariesField',
  XSS_PROTECTION_LEVEL_ONE: XssProtectionLevelField,
  XSS_PROTECTION_LEVEL_TWO: XssProtectionLevelField,
  XSS_PROTECTION_LEVEL_THREE: XssProtectionLevelField,
};

export type ComponentSummariesField = {
  __typename: 'ComponentSummariesField',
  XSS_PROTECTION_BASIC_CSP: XssProtectionLevelField,
  XSS_PROTECTION_IMPROVED_CSP: XssProtectionLevelField,
  XSS_PROTECTION_ADVANCED_CSP: XssProtectionLevelField,
  XSS_PROTECTION_HTTP_ONLY_COOKIES: XssProtectionLevelField,
};

export type ProtectionCategoryField = {
  __typename: 'ProtectionCategoryField',
  category_id: $ElementType<Scalars, 'String'>,
  total_levels: $ElementType<Scalars, 'Float'>,
  level_summaries: LevelSummariesField,
  component_summaries: ComponentSummariesField,
};

export type ProtectionCategoriesSummaryField = {
  __typename: 'ProtectionCategoriesSummaryField',
  xss_protection: ProtectionCategoryField,
};

export type ScanStatusField = {
  __typename: 'ScanStatusField',
  last_scanned_date: $ElementType<Scalars, 'Date'>,
};

export type WebAppProtectionToolkitDataLevelsComponentRequirementsFields = {
  __typename: 'WebAppProtectionToolkitDataLevelsComponentRequirementsFields',
  id: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
};

export type WebAppProtectionToolkitDataLevelsComponentsFields = {
  __typename: 'WebAppProtectionToolkitDataLevelsComponentsFields',
  id: $ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
  category_id: $ElementType<Scalars, 'String'>,
  category_level_id: $ElementType<Scalars, 'String'>,
  corresponding_policy_rule_type_id: $ElementType<Scalars, 'String'>,
  requirements: Array<WebAppProtectionToolkitDataLevelsComponentRequirementsFields>,
};

export type WebAppProtectionToolkitDataLevelsField = {
  __typename: 'WebAppProtectionToolkitDataLevelsField',
  id: $ElementType<Scalars, 'String'>,
  category_id: $ElementType<Scalars, 'String'>,
  summary: $ElementType<Scalars, 'String'>,
  benefits: $ElementType<Scalars, 'String'>,
  tradeoffs: $ElementType<Scalars, 'String'>,
  components: Array<WebAppProtectionToolkitDataLevelsComponentsFields>,
};

export type WebAppProtectionToolkitXssProtectionField = {
  __typename: 'WebAppProtectionToolkitXSSProtectionField',
  id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  levels: Array<WebAppProtectionToolkitDataLevelsField>,
};

export type WebAppProtectionToolkitDataField = {
  __typename: 'WebAppProtectionToolkitDataField',
  xss_protection: WebAppProtectionToolkitXssProtectionField,
};

export type WebAppProtectionProgressListResponse = {
  __typename: 'WebAppProtectionProgressListResponse',
  protection_summary: ProtectionSummaryField,
  protection_categories_summary: ProtectionCategoriesSummaryField,
  scan_status: ScanStatusField,
  web_app_protection_toolkit_data: WebAppProtectionToolkitDataField,
};

export type WebAppXssProtectionComponentRequirementStatusesCompletedField = {
  __typename: 'WebAppXSSProtectionComponentRequirementStatusesCompletedField',
  requirement_type: $ElementType<Scalars, 'String'>,
  status: $ElementType<Scalars, 'String'>,
  requirement_status_unmet_reason?: ?$ElementType<Scalars, 'String'>,
};

export type WebAppXssProtectionComponentsAdditionalInformation = {
  __typename: 'WebAppXSSProtectionComponentsAdditionalInformation',
  title: $ElementType<Scalars, 'String'>,
  content_as_markdown: $ElementType<Scalars, 'String'>,
};

export type WebAppXssProtectionComponentsCompletedField = {
  __typename: 'WebAppXSSProtectionComponentsCompletedField',
  id: $ElementType<Scalars, 'String'>,
  is_completed: $ElementType<Scalars, 'Boolean'>,
  requirement_statuses: Array<WebAppXssProtectionComponentRequirementStatusesCompletedField>,
  additional_information?: ?WebAppXssProtectionComponentsAdditionalInformation,
};

export type WebAppXssProtectionCompletedField = {
  __typename: 'WebAppXSSProtectionCompletedField',
  highest_completed_level: $ElementType<Scalars, 'Float'>,
  components: Array<WebAppXssProtectionComponentsCompletedField>,
};

export type WebAppProtectionProgressResponse = {
  __typename: 'WebAppProtectionProgressResponse',
  overall_protection_score: $ElementType<Scalars, 'Float'>,
  last_scanned_date: $ElementType<Scalars, 'Date'>,
  xss_protection: WebAppXssProtectionCompletedField,
  web_app_protection_toolkit_data: WebAppProtectionToolkitDataField,
};

export type RansomwareLastScanStats = {
  __typename: 'RansomwareLastScanStats',
  malware_detected_count: $ElementType<Scalars, 'Float'>,
  public_readable_storage_count: $ElementType<Scalars, 'Float'>,
  public_writable_storage_count: $ElementType<Scalars, 'Float'>,
  ransomware_detected_count: $ElementType<Scalars, 'Float'>,
  scanned_cloud_storage_count: $ElementType<Scalars, 'Float'>,
  scanned_directories_count: $ElementType<Scalars, 'Float'>,
  scanned_files_count: $ElementType<Scalars, 'Float'>,
};

export type CloudStorageRansomwareVulnerabilityStats = {
  __typename: 'CloudStorageRansomwareVulnerabilityStats',
  asset_uuid?: ?$ElementType<Scalars, 'ID'>,
  cloud_resource_type?: ?$ElementType<Scalars, 'String'>,
  vulnerable_to_ransomware?: ?$ElementType<Scalars, 'Boolean'>,
  malware_found?: ?Array<?KnownMalwareTypeEnum>,
};

export type RansomwareStatsField = {
  __typename: 'RansomwareStatsField',
  cloud_storage_ransomware_vulnerability_stats?: ?Array<?CloudStorageRansomwareVulnerabilityStats>,
  last_scan_stats: RansomwareLastScanStats,
};

export type CloudRansomwareStats = {
  __typename: 'CloudRansomwareStats',
  ransomware_stats_field: RansomwareStatsField,
};

export type CloudProviderShadowInventoryStatsField = {
  __typename: 'CloudProviderShadowInventoryStatsField',
  title: $ElementType<Scalars, 'String'>,
  cloud_provider: $ElementType<Scalars, 'String'>,
  assets_count: $ElementType<Scalars, 'Float'>,
  last_discovered_date: $ElementType<Scalars, 'Date'>,
};

export type ShadowInventoryPerCloudProviderStats = {
  __typename: 'shadowInventoryPerCloudProviderStats',
  shadow_inventory_per_cloud_provider_stats: Array<CloudProviderShadowInventoryStatsField>,
};

export type AssetTypeShadowInventoryStatsField = {
  __typename: 'AssetTypeShadowInventoryStatsField',
  title: $ElementType<Scalars, 'String'>,
  asset_type: AssetTypeEnum,
  assets_count: $ElementType<Scalars, 'Float'>,
  last_discovered_date: $ElementType<Scalars, 'Date'>,
};

export type ShadowInventoryPerAssetTypeStats = {
  __typename: 'shadowInventoryPerAssetTypeStats',
  shadow_inventory_per_asset_type_stats: Array<AssetTypeShadowInventoryStatsField>,
};

export const AlertsIntegrationsTypeEnumValues = Object.freeze({
  MSFT_TEAMS: 'MSFT_TEAMS', 
  SLACK: 'SLACK', 
  WEBHOOK: 'WEBHOOK', 
  AWS_SECURITY_HUB: 'AWS_SECURITY_HUB', 
  JIRA: 'JIRA'
});


export type AlertsIntegrationsTypeEnum = $Values<typeof AlertsIntegrationsTypeEnumValues>;

export const AlertsIntegrationsStatusEnumValues = Object.freeze({
  ENABLED: 'ENABLED', 
  DISABLED: 'DISABLED', 
  CONNECTION_ERROR: 'CONNECTION_ERROR'
});


export type AlertsIntegrationsStatusEnum = $Values<typeof AlertsIntegrationsStatusEnumValues>;

export type AlertsIntegrations = {
  __typename: 'AlertsIntegrations',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  integration_type: AlertsIntegrationsTypeEnum,
  status: AlertsIntegrationsStatusEnum,
  most_recent_attempt_date?: ?$ElementType<Scalars, 'Date'>,
};

export type JiraIntegrationProjectsFetchesResponseJiraProject = {
  __typename: 'JiraIntegrationProjectsFetchesResponseJiraProject',
  key: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  issue_types: Array<$ElementType<Scalars, 'String'>>,
};

export type JiraIntegrationProjectsFetchesResponseJiraIssueField = {
  __typename: 'JiraIntegrationProjectsFetchesResponseJiraIssueField',
  field_id: $ElementType<Scalars, 'String'>,
  field_type: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  required: $ElementType<Scalars, 'Boolean'>,
  allowed_values?: ?Array<?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue>,
  default_array_values?: ?Array<?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue>,
  is_eligible_for_tracking_relevance?: ?$ElementType<Scalars, 'Boolean'>,
  default_value?: ?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue,
};

export type JiraIntegrationProjectsFetchesResponse = {
  __typename: 'JiraIntegrationProjectsFetchesResponse',
  instance_url: $ElementType<Scalars, 'String'>,
  jira_projects: Array<JiraIntegrationProjectsFetchesResponseJiraProject>,
};

export type JiraIntegrationFieldsFetchesResponse = {
  __typename: 'JiraIntegrationFieldsFetchesResponse',
  instance_url: $ElementType<Scalars, 'String'>,
  project_key: $ElementType<Scalars, 'String'>,
  issue_type: $ElementType<Scalars, 'String'>,
  jira_issue_fields: Array<JiraIntegrationProjectsFetchesResponseJiraIssueField>,
};

export type DetailsMobileSecureSlackOrMsftTeamsConfigField = {
  __typename: 'DetailsMobileSecureSlackOrMsftTeamsConfigField',
  should_send_summary_notifications: $ElementType<Scalars, 'Boolean'>,
  should_send_real_time_notifications: $ElementType<Scalars, 'Boolean'>,
  should_notify_about_mobile_scan_alerts?: ?$ElementType<Scalars, 'Boolean'>,
  should_notify_about_security_p1_issues: $ElementType<Scalars, 'Boolean'>,
  should_notify_about_store_blocker_issues: $ElementType<Scalars, 'Boolean'>,
  should_notify_about_high_severity_issues: $ElementType<Scalars, 'Boolean'>,
  should_notify_about_medium_severity_issues: $ElementType<Scalars, 'Boolean'>,
  should_notify_about_low_severity_issues: $ElementType<Scalars, 'Boolean'>,
  should_notify_about_all_mobile_apps: $ElementType<Scalars, 'Boolean'>,
  should_notify_about_mobile_app_ids?: ?Array<$ElementType<Scalars, 'String'>>,
};

export type DetailsApiWebCloudSecureSlackOrMsftTeamsConfigField = {
  __typename: 'DetailsApiWebCloudSecureSlackOrMsftTeamsConfigField',
  should_send_summary_notifications: $ElementType<Scalars, 'Boolean'>,
  should_send_real_time_notifications: $ElementType<Scalars, 'Boolean'>,
};

export type DetailsSlackOrMsftTeamsConfigField = {
  __typename: 'DetailsSlackOrMsftTeamsConfigField',
  url: $ElementType<Scalars, 'String'>,
  should_send_asm_notifications_for_all_asset_types: $ElementType<Scalars, 'Boolean'>,
  should_send_asm_notifications_for_asset_types?: ?Array<?AssetTypeEnum>,
  should_send_asm_notifications: $ElementType<Scalars, 'Boolean'>,
  mobile_secure_config: DetailsMobileSecureSlackOrMsftTeamsConfigField,
  api_web_cloud_secure_config: DetailsApiWebCloudSecureSlackOrMsftTeamsConfigField,
};

export type AlertsIntegrationAsmAssetTypeValuesResponseItem = {
  __typename: 'AlertsIntegrationAsmAssetTypeValuesResponseItem',
  name: $ElementType<Scalars, 'String'>,
  value: AssetTypeEnum,
  value_category?: ?$ElementType<Scalars, 'String'>,
};

export type AlertsIntegrationAsmAssetTypeValuesResponse = {
  __typename: 'AlertsIntegrationAsmAssetTypeValuesResponse',
  alerts_integration_asm_asset_type_values: Array<AlertsIntegrationAsmAssetTypeValuesResponseItem>,
};

export type AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue = {
  __typename: 'AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue',
  name: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
};

export type AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValueInput = {
  name: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
};

export type AlertsIntegrationsDetailsJiraConfigFieldStaticField = {
  __typename: 'AlertsIntegrationsDetailsJiraConfigFieldStaticField',
  field_id?: ?$ElementType<Scalars, 'String'>,
  field_type?: ?$ElementType<Scalars, 'String'>,
  string_value?: ?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue,
  string_array_value?: ?Array<?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue>,
  component_array_value?: ?Array<?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue>,
  option_value?: ?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue,
  option_array_value?: ?Array<?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue>,
  number_value?: ?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue,
  user_value?: ?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue,
};

export type AlertsIntegrationsDetailsJiraConfigField = {
  __typename: 'AlertsIntegrationsDetailsJiraConfigField',
  instance_url?: ?$ElementType<Scalars, 'String'>,
  username?: ?$ElementType<Scalars, 'String'>,
  password?: ?$ElementType<Scalars, 'String'>,
  project_key?: ?$ElementType<Scalars, 'String'>,
  issue_type?: ?$ElementType<Scalars, 'String'>,
  relevance_field_id?: ?$ElementType<Scalars, 'String'>,
  relevance_proactive_value?: ?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue,
  relevance_important_value?: ?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue,
  relevance_urgent_value?: ?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue,
  policy_violations_auto_export_setting?: ?$ElementType<Scalars, 'String'>,
  static_fields?: ?Array<?AlertsIntegrationsDetailsJiraConfigFieldStaticField>,
};

export type AlertsIntegrationsDetails = {
  __typename: 'AlertsIntegrationsDetails',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  integration_type: AlertsIntegrationsTypeEnum,
  status: AlertsIntegrationsStatusEnum,
  most_recent_attempt_date?: ?$ElementType<Scalars, 'Date'>,
  slack_or_msft_teams_config?: ?DetailsSlackOrMsftTeamsConfigField,
  webhook_config?: ?AlertsIntegrationsDetailsWebhookConfigField,
  jira_config?: ?AlertsIntegrationsDetailsJiraConfigField,
};

export type AlertsIntegrationsDetailsWebhookConfigField = {
  __typename: 'AlertsIntegrationsDetailsWebhookConfigField',
  authorization_header?: ?$ElementType<Scalars, 'String'>,
  subscribed_events?: ?Array<?$ElementType<Scalars, 'String'>>,
  subscribed_to_all_events?: ?$ElementType<Scalars, 'Boolean'>,
  url?: ?$ElementType<Scalars, 'String'>,
};

export type AlertsIntegrationsListResponse = {
  __typename: 'AlertsIntegrationsListResponse',
  pagination_information: PaginationInformation,
  alerts_integrations: Array<AlertsIntegrations>,
};

export const IosSdkEventsEnumValues = Object.freeze({
  ios_sdk_events: 'ios_sdk_events', 
  android_sdk_events: 'android_sdk_events'
});


export type IosSdkEventsEnum = $Values<typeof IosSdkEventsEnumValues>;

export const TrustKitProtectionStatusValues = Object.freeze({
  PROTECTED_WITH_ACTIVE_MODE: 'PROTECTED_WITH_ACTIVE_MODE', 
  PROTECTED_WITH_PASSIVE_MODE: 'PROTECTED_WITH_PASSIVE_MODE', 
  UNPROTECTED: 'UNPROTECTED'
});


export type TrustKitProtectionStatus = $Values<typeof TrustKitProtectionStatusValues>;

export type TrustKitAccessedNetworkService = {
  __typename: 'TrustKitAccessedNetworkService',
  id: $ElementType<Scalars, 'String'>,
  scheme: $ElementType<Scalars, 'String'>,
  hostname: $ElementType<Scalars, 'String'>,
  port: $ElementType<Scalars, 'Float'>,
};

export type TrustKitAccessedNetworkServicesResponse = {
  __typename: 'TrustKitAccessedNetworkServicesResponse',
  pagination_information: PaginationInformation,
  accessed_network_services: Array<?TrustKitAccessedNetworkService>,
};

export type TrustKitTopAccessedNetworkServicesResponse = {
  __typename: 'TrustKitTopAccessedNetworkServicesResponse',
  top_accessed_urls: Array<TrustKitTopAccessedNetworkServicesResponseItem>,
};

export type TrustKitTopAccessedNetworkServicesResponseItem = {
  __typename: 'TrustKitTopAccessedNetworkServicesResponseItem',
  url: $ElementType<Scalars, 'String'>,
  access_count: $ElementType<Scalars, 'Float'>,
  first_seen_date: $ElementType<Scalars, 'Date'>,
};

export type PatchNetworkFlowsBody = {
  name: $ElementType<Scalars, 'String'>,
};

export type PostNetworkFlowsBody = {
  network_type: $ElementType<Scalars, 'String'>,
};

export type MobilePhishingPostComment = {
  content: $ElementType<Scalars, 'String'>,
};

export type FullSubscriptionInquiryBody = {
  comment: $ElementType<Scalars, 'String'>,
};

export type UnlockDataReportBody = {
  comment: $ElementType<Scalars, 'String'>,
};

export type PostApiProtectSdkBody = {
  api_protect_sdk_flavor: $ElementType<Scalars, 'String'>,
};

export type PostApiProtectSdkResponseExample = {
  __typename: 'PostApiProtectSdkResponseExample',
  title: $ElementType<Scalars, 'String'>,
  example_as_markdown: $ElementType<Scalars, 'String'>,
};

export type PostApiProtectSdkResponseStep1 = {
  __typename: 'PostApiProtectSdkResponseStep1',
  title: $ElementType<Scalars, 'String'>,
  artifact_download_url: $ElementType<Scalars, 'String'>,
  artifact_name: $ElementType<Scalars, 'String'>,
  examples: Array<PostApiProtectSdkResponseExample>,
};

export type PostApiProtectSdkResponseStep2 = {
  __typename: 'PostApiProtectSdkResponseStep2',
  title: $ElementType<Scalars, 'String'>,
  examples: Array<PostApiProtectSdkResponseExample>,
};

export type PostApiProtectSdkResponseStep3 = {
  __typename: 'PostApiProtectSdkResponseStep3',
  api_key: $ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
  examples: Array<PostApiProtectSdkResponseExample>,
};

export type PatchNetworkFlowsResponse = {
  __typename: 'PatchNetworkFlowsResponse',
  id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
};

export type PostApiProtectSdkResponse = {
  __typename: 'PostApiProtectSdkResponse',
  api_protect_sdk_flavor: $ElementType<Scalars, 'String'>,
  step_1: PostApiProtectSdkResponseStep1,
  step_2: PostApiProtectSdkResponseStep2,
  step_3: PostApiProtectSdkResponseStep3,
};

export type PostNetworkFlowsResponseStep1 = {
  __typename: 'PostNetworkFlowsResponseStep1',
  api_key: $ElementType<Scalars, 'String'>,
  instructions_as_markdown: $ElementType<Scalars, 'String'>,
};

export type PostNetworkFlowsResponseGenericStep = {
  __typename: 'PostNetworkFlowsResponseGenericStep',
  title: $ElementType<Scalars, 'String'>,
  intro_as_markdown: $ElementType<Scalars, 'String'>,
  artifact_download_url: $ElementType<Scalars, 'String'>,
  artifact_name: $ElementType<Scalars, 'String'>,
  instructions_as_markdown: $ElementType<Scalars, 'String'>,
};

export type PostNetworkFlowsResponse = {
  __typename: 'PostNetworkFlowsResponse',
  network_id?: ?$ElementType<Scalars, 'String'>,
  network_type: $ElementType<Scalars, 'String'>,
  network_name?: ?$ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  step_1: PostNetworkFlowsResponseStep1,
  step_2: PostNetworkFlowsResponseGenericStep,
  step_3?: ?PostNetworkFlowsResponseGenericStep,
};

export type ProtectionStatsResponseItem = {
  __typename: 'ProtectionStatsResponseItem',
  count?: ?$ElementType<Scalars, 'Float'>,
  name: $ElementType<Scalars, 'String'>,
  trend_value: $ElementType<Scalars, 'Float'>,
  trend_period: $ElementType<Scalars, 'String'>,
  icon_url: $ElementType<Scalars, 'String'>,
  card_type?: ?$ElementType<Scalars, 'String'>,
  text?: ?$ElementType<Scalars, 'String'>,
};

export type TopClientDomainsStatsResponseItem = {
  __typename: 'TopClientDomainsStatsResponseItem',
  name: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'Float'>,
  trend_value: $ElementType<Scalars, 'Float'>,
};

export type TopClientDomainsStatsResponse = {
  __typename: 'TopClientDomainsStatsResponse',
  top_client_domains_stats: Array<TopClientDomainsStatsResponseItem>,
};

export type MobileAppSessionBreakdownResponseMetricsItemsItem = {
  __typename: 'MobileAppSessionBreakdownResponseMetricsItemsItem',
  color: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
};

export type MobileAppSessionBreakdownResponseMetricsItem = {
  __typename: 'MobileAppSessionBreakdownResponseMetricsItem',
  items: Array<MobileAppSessionBreakdownResponseMetricsItemsItem>,
  name: $ElementType<Scalars, 'String'>,
};

export type MobileAppSessionBreakdownResponseTopApisItem = {
  __typename: 'MobileAppSessionBreakdownResponseTopApisItem',
  detail: $ElementType<Scalars, 'String'>,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
  title_url: $ElementType<Scalars, 'String'>,
};

export type MobileAppSessionBreakdownResponse = {
  __typename: 'MobileAppSessionBreakdownResponse',
  metrics: Array<MobileAppSessionBreakdownResponseMetricsItem>,
  top_apis: Array<MobileAppSessionBreakdownResponseTopApisItem>,
  top_apis_name: $ElementType<Scalars, 'String'>,
};

export type TopClientCountriesStatsResponse = {
  __typename: 'TopClientCountriesStatsResponse',
  top_client_countries_stats: Array<TopClientDomainsStatsResponseItem>,
};

export type ProtectionStatsResponse = {
  __typename: 'ProtectionStatsResponse',
  protection_stats: Array<ProtectionStatsResponseItem>,
};

export type UsageStatsResponse = {
  __typename: 'UsageStatsResponse',
  usage_stats: Array<ProtectionStatsResponseItem>,
};

export type ListConnectorsResponseItem = {
  __typename: 'ListConnectorsResponseItem',
  connector_type: $ElementType<Scalars, 'String'>,
  connector_name: $ElementType<Scalars, 'String'>,
  network_type?: ?$ElementType<Scalars, 'String'>,
  api_protect_sdk_flavor?: ?$ElementType<Scalars, 'String'>,
};

export type ListConnectorsResponse = {
  __typename: 'ListConnectorsResponse',
  connectors: Array<ListConnectorsResponseItem>,
  pagination_information: PaginationInformation,
};

export type TrustKitDynamicCoverageSummaryResponse = {
  __typename: 'TrustKitDynamicCoverageSummaryResponse',
  urls_discovered_count: $ElementType<Scalars, 'Float'>,
  domains_discovered_count: $ElementType<Scalars, 'Float'>,
};

export type MobileProtectAppsResponseTrustkitSummaryAttackTrendItem = {
  __typename: 'MobileProtectAppsResponseTrustkitSummaryAttackTrendItem',
  day: $ElementType<Scalars, 'String'>,
  attacks_count: $ElementType<Scalars, 'Float'>,
  app_installs_count: $ElementType<Scalars, 'Float'>,
};

export type MobileProtectAppsResponseTrustkitSummary = {
  __typename: 'MobileProtectAppsResponseTrustkitSummary',
  mobile_apps_with_trustkit_count?: ?$ElementType<Scalars, 'Float'>,
  mobile_apps_without_trustkit_count?: ?$ElementType<Scalars, 'Float'>,
  attacks_trend?: ?Array<?MobileProtectAppsResponseTrustkitSummaryAttackTrendItem>,
};

export type MobileProtectAppsResponseItemTrustkitStatsItem = {
  __typename: 'MobileProtectAppsResponseItemTrustkitStatsItem',
  enforce_pinning: $ElementType<Scalars, 'Boolean'>,
  total_app_installs_count: $ElementType<Scalars, 'Float'>,
  total_attacks_count: $ElementType<Scalars, 'Float'>,
  total_attacks_blocked_count: $ElementType<Scalars, 'Float'>,
};

export type MobileProtectAppsResponseItem = {
  __typename: 'MobileProtectAppsResponseItem',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  platform: $ElementType<Scalars, 'String'>,
  bundle_id: $ElementType<Scalars, 'String'>,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  latest_version?: ?$ElementType<Scalars, 'String'>,
  sevenhell_customer_app_id: $ElementType<Scalars, 'Float'>,
  horizon_asset_uuid: $ElementType<Scalars, 'String'>,
  protection_status: $ElementType<Scalars, 'String'>,
  trustkit_stats: MobileProtectAppsResponseItemTrustkitStatsItem,
  connector_name: $ElementType<Scalars, 'String'>,
  connector_icon_url: $ElementType<Scalars, 'String'>,
  connector_version: $ElementType<Scalars, 'String'>,
};

export type MobileProtectAppsResponse = {
  __typename: 'MobileProtectAppsResponse',
  mobile_apps: Array<MobileProtectAppsResponseItem>,
  trustkit_summary: MobileProtectAppsResponseTrustkitSummary,
};

export type MobileProtectionStatsResponseItem = {
  __typename: 'MobileProtectionStatsResponseItem',
  count: $ElementType<Scalars, 'Float'>,
  name: $ElementType<Scalars, 'String'>,
  icon_url: $ElementType<Scalars, 'String'>,
  trend_value: $ElementType<Scalars, 'Float'>,
  trend_period: $ElementType<Scalars, 'String'>,
};

export type MobileProtectionStatsResponse = {
  __typename: 'MobileProtectionStatsResponse',
  protection_stats: Array<MobileProtectionStatsResponseItem>,
};

export type TrustKitMobileAppStats = {
  __typename: 'TrustKitMobileAppStats',
  enforce_pinning: $ElementType<Scalars, 'Boolean'>,
  total_app_installs_count: $ElementType<Scalars, 'Float'>,
  total_attacks_blocked_count: $ElementType<Scalars, 'Float'>,
  total_attacks_count: $ElementType<Scalars, 'Float'>,
};

export type TrustKitMobileApp = {
  __typename: 'TrustKitMobileApp',
  bundle_id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  platform: MobileAppPlatform,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  latest_version?: ?$ElementType<Scalars, 'String'>,
  protection_status: TrustKitProtectionStatus,
  trustkit_stats?: ?TrustKitMobileAppStats,
  sevenhell_customer_app_id?: ?$ElementType<Scalars, 'Float'>,
  horizon_asset_uuid?: ?$ElementType<Scalars, 'String'>,
};

export type TrustKitAttackTrend = {
  __typename: 'TrustKitAttackTrend',
  app_installs_count: $ElementType<Scalars, 'Float'>,
  attacks_count: $ElementType<Scalars, 'Float'>,
  day: $ElementType<Scalars, 'String'>,
};

export type TrustKitSummary = {
  __typename: 'TrustKitSummary',
  attacks_trend: Array<TrustKitAttackTrend>,
  mobile_apps_with_trustkit_count: $ElementType<Scalars, 'Float'>,
  mobile_apps_without_trustkit_count: $ElementType<Scalars, 'Float'>,
};

export type TrustKitMobileAppListResponse = {
  __typename: 'TrustKitMobileAppListResponse',
  mobile_apps: Array<TrustKitMobileApp>,
  trustkit_summary: TrustKitSummary,
};

export type DataAtRestEncryption = {
  __typename: 'DataAtRestEncryption',
  total_files_count?: ?$ElementType<Scalars, 'Float'>,
  protected_files_count?: ?$ElementType<Scalars, 'Float'>,
};

export type DeviceCheck = {
  __typename: 'DeviceCheck',
  app_installs_on_secure_device_count: $ElementType<Scalars, 'Float'>,
  app_installs_on_insecure_device_count: $ElementType<Scalars, 'Float'>,
  devices_insecure_after_install_count: $ElementType<Scalars, 'Float'>,
};

export type PasteboardExpirationStats = {
  __typename: 'PasteboardExpirationStats',
  pastes_with_expiration_count?: ?$ElementType<Scalars, 'Float'>,
  pastes_without_expiration_count?: ?$ElementType<Scalars, 'Float'>,
  pastes_with_expiration_added_by_mobile_protect_count?: ?$ElementType<Scalars, 'Float'>,
  last_thirty_days_pastes_with_expiration_added_by_mobile_protect_count?: ?$ElementType<Scalars, 'Float'>,
};

export type PasteboardAccessStats = {
  __typename: 'PasteboardAccessStats',
  total_pasteboard_read_count?: ?$ElementType<Scalars, 'Float'>,
  pasteboard_read_blocked_by_mobile_protect_count?: ?$ElementType<Scalars, 'Float'>,
  last_thirty_days_pasteboard_read_blocked_by_mobile_protect_count?: ?$ElementType<Scalars, 'Float'>,
};

export type ThirdPartyKeyboardStats = {
  __typename: 'ThirdPartyKeyboardStats',
  third_party_keyboards_blocked_count?: ?$ElementType<Scalars, 'Float'>,
  last_thirty_days_third_party_keyboards_blocked_count?: ?$ElementType<Scalars, 'Float'>,
};

export type UserLocationStats = {
  __typename: 'UserLocationStats',
  total_location_read_count: $ElementType<Scalars, 'Float'>,
  precise_location_read_count: $ElementType<Scalars, 'Float'>,
  precise_location_downgraded_by_mobile_protect_count: $ElementType<Scalars, 'Float'>,
  last_thirty_days_precise_location_downgraded_by_mobile_protect_count: $ElementType<Scalars, 'Float'>,
};

export type SensitiveApisStatsType = {
  __typename: 'SensitiveApisStatsType',
  apis_blocked_by_mobile_protect_count: $ElementType<Scalars, 'Float'>,
  apis_accessed_by_app_count: $ElementType<Scalars, 'Float'>,
  apis_accessed_by_sdk_count: $ElementType<Scalars, 'Float'>,
  last_thirty_days_apis_blocked_by_mobile_protect_count: $ElementType<Scalars, 'Float'>,
};

export type JailbreakStats = {
  __typename: 'JailbreakStats',
  jailbreak_name?: ?$ElementType<Scalars, 'String'>,
  jailbreak_count?: ?$ElementType<Scalars, 'Float'>,
};

export type TamperingStats = {
  __typename: 'TamperingStats',
  tampering_attempts_detected_by_app_count?: ?$ElementType<Scalars, 'Float'>,
  tampering_attempts_detected_by_sdk_count?: ?$ElementType<Scalars, 'Float'>,
  tampering_attempts_blocked_by_app_count?: ?$ElementType<Scalars, 'Float'>,
  tampering_attempts_blocked_by_sdk_count?: ?$ElementType<Scalars, 'Float'>,
};

export type DebuggingStats = {
  __typename: 'DebuggingStats',
  debugging_attempts_detected_count?: ?$ElementType<Scalars, 'Float'>,
  debugging_attempts_blocked_count?: ?$ElementType<Scalars, 'Float'>,
};

export type SensitiveApisStats = {
  __typename: 'SensitiveApisStats',
  AD_ID: SensitiveApisStatsType,
  CAMERA: SensitiveApisStatsType,
  CONTACTS: SensitiveApisStatsType,
  HEALTH: SensitiveApisStatsType,
  IDFA: SensitiveApisStatsType,
  LOCATION: SensitiveApisStatsType,
  PASTEBOARD: SensitiveApisStatsType,
  PHOTO_ACCESS: SensitiveApisStatsType,
  PHOTO_EXIF: SensitiveApisStatsType,
};

export type SpywareStats = {
  __typename: 'SpywareStats',
  app_platform_version_range: $ElementType<Scalars, 'String'>,
  checked_devices_count: $ElementType<Scalars, 'Float'>,
  checks_count: $ElementType<Scalars, 'Float'>,
  infected_devices_count: $ElementType<Scalars, 'Float'>,
  latest_check?: ?$ElementType<Scalars, 'Date'>,
  name: $ElementType<Scalars, 'String'>,
  vulnerable_devices_count: $ElementType<Scalars, 'Float'>,
};

export type DeviceSpywareStats = {
  __typename: 'DeviceSpywareStats',
  app_platform_version_range: $ElementType<Scalars, 'String'>,
  checked_devices_count: $ElementType<Scalars, 'Float'>,
  checks_count: $ElementType<Scalars, 'Float'>,
  infected_devices_count: $ElementType<Scalars, 'Float'>,
  spywares?: ?Array<SpywareStats>,
  vulnerable_devices_count: $ElementType<Scalars, 'Float'>,
};

export type TrustKitIosSdkEventsResponse = {
  __typename: 'TrustKitIosSdkEventsResponse',
  data_at_rest_encryption?: ?DataAtRestEncryption,
  device_check?: ?DeviceCheck,
  pasteboard_expiration_stats?: ?PasteboardExpirationStats,
  pasteboard_access_stats?: ?PasteboardAccessStats,
  third_party_keyboard_stats?: ?ThirdPartyKeyboardStats,
  user_location_stats: UserLocationStats,
  jailbreak_stats: Array<?JailbreakStats>,
  tampering_stats?: ?TamperingStats,
  debugging_stats?: ?DebuggingStats,
  sensitive_apis_stats: SensitiveApisStats,
  device_spywares_stats?: ?DeviceSpywareStats,
  dynamic_coverage_stats?: ?DynamicCoverageStats,
};

export type OutdatedSecurityProviderStats = {
  __typename: 'OutdatedSecurityProviderStats',
  devices_with_security_provider_already_updated_count?: ?$ElementType<Scalars, 'Float'>,
  devices_with_security_provider_outdated_count?: ?$ElementType<Scalars, 'Float'>,
  devices_with_security_provider_updated_by_mobile_protect_count?: ?$ElementType<Scalars, 'Float'>,
  last_thirty_days_devices_with_security_provider_updated_by_mobile_protect_count?: ?$ElementType<Scalars, 'Float'>,
};

export type ScreenProtectionFromThirdPartiesStats = {
  __typename: 'ScreenProtectionFromThirdPartiesStats',
  activity_instances_already_protected_count?: ?$ElementType<Scalars, 'Float'>,
  activity_instances_protected_by_mobile_protect_count?: ?$ElementType<Scalars, 'Float'>,
  activity_instances_unprotected_count?: ?$ElementType<Scalars, 'Float'>,
  last_thirty_days_activity_instances_protected_by_mobile_protect_count?: ?$ElementType<Scalars, 'Float'>,
};

export type ScreenProtectionFromTapJackingStats = {
  __typename: 'ScreenProtectionFromTapJackingStats',
  activity_instances_already_protected_count?: ?$ElementType<Scalars, 'Float'>,
  activity_instances_protected_by_mobile_protect_count?: ?$ElementType<Scalars, 'Float'>,
  activity_instances_unprotected_count?: ?$ElementType<Scalars, 'Float'>,
  last_thirty_days_activity_instances_protected_by_mobile_protect_count?: ?$ElementType<Scalars, 'Float'>,
};

export type VerifyAppsStats = {
  __typename: 'VerifyAppsStats',
  disabled_count?: ?$ElementType<Scalars, 'Float'>,
  enabled_count?: ?$ElementType<Scalars, 'Float'>,
  unknown_count?: ?$ElementType<Scalars, 'Float'>,
  last_thirty_days_enabled_count?: ?$ElementType<Scalars, 'Float'>,
};

export type SecureWebviewUsageStats = {
  __typename: 'SecureWebviewUsageStats',
  insecure_webviews_initialized_count?: ?$ElementType<Scalars, 'Float'>,
  last_thirty_days_secure_webviews_initialized_by_mobile_protect_count?: ?$ElementType<Scalars, 'Float'>,
  secure_webviews_initialized_by_mobile_protect_count?: ?$ElementType<Scalars, 'Float'>,
  secure_webviews_initialized_count?: ?$ElementType<Scalars, 'Float'>,
};

export type RootDetectionStats = {
  __typename: 'RootDetectionStats',
  devices_with_unlocked_bootloader_count?: ?$ElementType<Scalars, 'Float'>,
  emulator_devices_count?: ?$ElementType<Scalars, 'Float'>,
  rooted_devices_count?: ?$ElementType<Scalars, 'Float'>,
};

export type AndroidDebuggingStats = {
  __typename: 'AndroidDebuggingStats',
  debugging_attempts_blocked_count?: ?$ElementType<Scalars, 'Float'>,
  debugging_attempts_detected_count?: ?$ElementType<Scalars, 'Float'>,
  last_thirty_days_debugging_attempts_blocked_count?: ?$ElementType<Scalars, 'Float'>,
};

export type AndroidDeviceCheckStats = {
  __typename: 'AndroidDeviceCheckStats',
  devices_with_harmful_apps_count?: ?$ElementType<Scalars, 'Float'>,
  devices_with_verify_apps_disabled_count?: ?$ElementType<Scalars, 'Float'>,
  devices_with_verify_apps_enabled_count?: ?$ElementType<Scalars, 'Float'>,
  last_thirty_days_devices_with_verify_apps_enabled_count?: ?$ElementType<Scalars, 'Float'>,
};

export type DataAtRestEncryptionStats = {
  __typename: 'DataAtRestEncryptionStats',
  protected_files_count?: ?$ElementType<Scalars, 'Float'>,
  total_files_count?: ?$ElementType<Scalars, 'Float'>,
};

export type AndroidTamperingStats = {
  __typename: 'AndroidTamperingStats',
  last_thirty_days_tampering_attempts_blocked_by_app_count?: ?$ElementType<Scalars, 'Float'>,
  last_thirty_days_tampering_attempts_blocked_by_sdk_count?: ?$ElementType<Scalars, 'Float'>,
  tampering_attempts_blocked_by_app_count?: ?$ElementType<Scalars, 'Float'>,
  tampering_attempts_blocked_by_sdk_count?: ?$ElementType<Scalars, 'Float'>,
  tampering_attempts_detected_by_app_count?: ?$ElementType<Scalars, 'Float'>,
  tampering_attempts_detected_by_sdk_count?: ?$ElementType<Scalars, 'Float'>,
};

export type DynamicCoverageStats = {
  __typename: 'DynamicCoverageStats',
  url_accessed_count?: ?$ElementType<Scalars, 'Float'>,
  last_thirty_days_url_accessed_count?: ?$ElementType<Scalars, 'Float'>,
};

export type AndroidCheatEngineStats = {
  __typename: 'AndroidCheatEngineStats',
  cheat_engine_name: $ElementType<Scalars, 'String'>,
  cheating_attempts_blocked_count?: ?$ElementType<Scalars, 'Float'>,
  cheating_attempts_detected_count?: ?$ElementType<Scalars, 'Float'>,
};

export type TrustKitAndroidSdkEventsResponse = {
  __typename: 'TrustKitAndroidSdkEventsResponse',
  data_at_rest_encryption_stats?: ?DataAtRestEncryptionStats,
  debugging_stats?: ?AndroidDebuggingStats,
  device_check_stats?: ?AndroidDeviceCheckStats,
  outdated_security_provider_stats?: ?OutdatedSecurityProviderStats,
  root_detection_stats?: ?RootDetectionStats,
  screen_protection_from_tap_jacking_stats?: ?ScreenProtectionFromTapJackingStats,
  screen_protection_from_third_parties_stats?: ?ScreenProtectionFromThirdPartiesStats,
  secure_webview_usage_stats?: ?SecureWebviewUsageStats,
  sensitive_apis_stats: SensitiveApisStats,
  tampering_stats?: ?AndroidTamperingStats,
  user_location_stats: UserLocationStats,
  dynamic_coverage_stats?: ?DynamicCoverageStats,
  cheat_engines_stats?: ?Array<AndroidCheatEngineStats>,
};

export type TrustkitSummaryCountrySubdivisionsStats = {
  __typename: 'TrustkitSummaryCountrySubdivisionsStats',
  app_installs_count: $ElementType<Scalars, 'Float'>,
  attacks_blocked_count: $ElementType<Scalars, 'Float'>,
  attacks_count: $ElementType<Scalars, 'Float'>,
  subdivision_code?: ?$ElementType<Scalars, 'String'>,
  subdivision_name: $ElementType<Scalars, 'String'>,
};

export type TrustkitSummaryCountryStats = {
  __typename: 'TrustkitSummaryCountryStats',
  country_code: $ElementType<Scalars, 'String'>,
  country_name: $ElementType<Scalars, 'String'>,
  attacks_count: $ElementType<Scalars, 'Float'>,
  attacks_blocked_count?: ?$ElementType<Scalars, 'Float'>,
  app_installs_count?: ?$ElementType<Scalars, 'Float'>,
  subdivisions: Array<?TrustkitSummaryCountrySubdivisionsStats>,
};

export type TopDomainDestinations = {
  __typename: 'TopDomainDestinations',
  total_attacks_count: $ElementType<Scalars, 'Float'>,
  domain: $ElementType<Scalars, 'String'>,
};

export type AttacksBlockedPerAppVersion = {
  __typename: 'AttacksBlockedPerAppVersion',
  app_version: $ElementType<Scalars, 'String'>,
  total_attacks_blocked_count: $ElementType<Scalars, 'Float'>,
};

export type TopValidationResults = {
  __typename: 'TopValidationResults',
  total_attacks_count: $ElementType<Scalars, 'Float'>,
  validation_result: $ElementType<Scalars, 'Float'>,
  validation_result_explanation: $ElementType<Scalars, 'String'>,
};

export type TrustkitSummaryResponse = {
  __typename: 'TrustkitSummaryResponse',
  mobile_app: TrustKitMobileApp,
  enforce_pinning: $ElementType<Scalars, 'Boolean'>,
  total_app_installs_count: $ElementType<Scalars, 'Float'>,
  total_attacks_count: $ElementType<Scalars, 'Float'>,
  total_attacks_blocked_count: $ElementType<Scalars, 'Float'>,
  attacks_block_rate: $ElementType<Scalars, 'Float'>,
  per_country_stats: Array<?TrustkitSummaryCountryStats>,
  top_country_stats: Array<?TrustkitSummaryCountryStats>,
  top_domain_destinations: Array<?TopDomainDestinations>,
  attacks_blocked_per_app_version: Array<?AttacksBlockedPerAppVersion>,
  top_validation_results: Array<?TopValidationResults>,
  attacks_block_rate_percentile: $ElementType<Scalars, 'Float'>,
};

export type EntitlementsPlistResponse = {
  __typename: 'EntitlementsPlistResponse',
  entitlements_as_plist: $ElementType<Scalars, 'String'>,
  download_url: $ElementType<Scalars, 'String'>,
};

export type InboundRequestsAccessPolicy = {
  __typename: 'InboundRequestsAccessPolicy',
  should_restrict_access_based_on_api_keys?: ?$ElementType<Scalars, 'Boolean'>,
  should_restrict_access_based_on_countries?: ?$ElementType<Scalars, 'Boolean'>,
  should_restrict_access_based_on_domains?: ?$ElementType<Scalars, 'Boolean'>,
  should_restrict_access_based_on_email_addresses?: ?$ElementType<Scalars, 'Boolean'>,
  should_restrict_access_based_on_ip_ranges?: ?$ElementType<Scalars, 'Boolean'>,
};

export type ApiProtectConfigurationResponse = {
  __typename: 'ApiProtectConfigurationResponse',
  inbound_requests_access_policy?: ?InboundRequestsAccessPolicy,
  inbound_requests_https_enforcement?: ?$ElementType<Scalars, 'String'>,
  should_block_dos_attacks?: ?$ElementType<Scalars, 'Boolean'>,
  should_block_malicious_ip_addresses?: ?$ElementType<Scalars, 'Boolean'>,
  should_block_sql_injection_attacks?: ?$ElementType<Scalars, 'Boolean'>,
  should_block_ssrf_attacks?: ?$ElementType<Scalars, 'Boolean'>,
};

export type ApiProtectProtectedAssets = {
  __typename: 'ApiProtectProtectedAssets',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  asset_type: AssetTypeEnum,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  total_attacks_blocked_count: $ElementType<Scalars, 'Float'>,
  total_attacks_detected_count: $ElementType<Scalars, 'Float'>,
};

export type ApiProtectProtectedAssetsResponse = {
  __typename: 'ApiProtectProtectedAssetsResponse',
  pagination_information: PaginationInformation,
  protected_assets: Array<?ApiProtectProtectedAssets>,
};

export type SecurityEventsResponseSource = {
  __typename: 'SecurityEventsResponseSource',
  source_ip_address: $ElementType<Scalars, 'String'>,
  source_country_name?: ?$ElementType<Scalars, 'String'>,
  source_country_code?: ?$ElementType<Scalars, 'String'>,
  source_subdivision_name?: ?$ElementType<Scalars, 'String'>,
  source_subdivision_code?: ?$ElementType<Scalars, 'String'>,
};

export type SecurityEventsResponseAsset = {
  __typename: 'SecurityEventsResponseAsset',
  asset_uuid?: ?$ElementType<Scalars, 'String'>,
  asset_name: $ElementType<Scalars, 'String'>,
  asset_url: $ElementType<Scalars, 'String'>,
  asset_type: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
};

export type SecurityEventsResponse = {
  __typename: 'SecurityEventsResponse',
  pagination_information: PaginationInformation,
  security_events: Array<SecurityEventsResponseItem>,
};

export type GroupedSecurityEventsByIdResponseEvent = {
  __typename: 'GroupedSecurityEventsByIdResponseEvent',
  id: $ElementType<Scalars, 'String'>,
  date: $ElementType<Scalars, 'String'>,
  event_type: $ElementType<Scalars, 'String'>,
  event_type_name: $ElementType<Scalars, 'String'>,
  source: SecurityEventsResponseSource,
  asset: SecurityEventsResponseAsset,
  severity: $ElementType<Scalars, 'String'>,
  severity_name: $ElementType<Scalars, 'String'>,
  path: $ElementType<Scalars, 'String'>,
  method: $ElementType<Scalars, 'String'>,
  raw_request: $ElementType<Scalars, 'String'>,
  raw_response?: ?$ElementType<Scalars, 'String'>,
  additional_info?: ?$ElementType<Scalars, 'String'>,
};

export type GroupedSecurityEventsByIdResponse = {
  __typename: 'GroupedSecurityEventsByIdResponse',
  user_agent?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'String'>,
  start_date: $ElementType<Scalars, 'String'>,
  end_date: $ElementType<Scalars, 'String'>,
  count: $ElementType<Scalars, 'Float'>,
  event_type: $ElementType<Scalars, 'String'>,
  event_type_name: $ElementType<Scalars, 'String'>,
  source: SecurityEventsResponseSource,
  asset: SecurityEventsResponseAsset,
  severity: $ElementType<Scalars, 'String'>,
  severity_name: $ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  action_taken?: ?$ElementType<Scalars, 'String'>,
  action_color: $ElementType<Scalars, 'String'>,
  http_method: $ElementType<Scalars, 'String'>,
  response_status_code?: ?$ElementType<Scalars, 'String'>,
  affected_api_operation_path: $ElementType<Scalars, 'String'>,
  events: Array<GroupedSecurityEventsByIdResponseEvent>,
};

export type ProtectionRulesResponseItem = {
  __typename: 'ProtectionRulesResponseItem',
  security_event_type: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
};

export type ProtectionRulesResponse = {
  __typename: 'ProtectionRulesResponse',
  protection_rules: Array<ProtectionRulesResponseItem>,
};

export type GroupedSecurityEventsResponseItem = {
  __typename: 'GroupedSecurityEventsResponseItem',
  id: $ElementType<Scalars, 'String'>,
  start_date: $ElementType<Scalars, 'String'>,
  end_date: $ElementType<Scalars, 'String'>,
  count: $ElementType<Scalars, 'Float'>,
  event_type: $ElementType<Scalars, 'String'>,
  event_type_name: $ElementType<Scalars, 'String'>,
  source: SecurityEventsResponseSource,
  asset: SecurityEventsResponseAsset,
  severity: $ElementType<Scalars, 'String'>,
  severity_name: $ElementType<Scalars, 'String'>,
  http_method: $ElementType<Scalars, 'String'>,
  affected_api_operation_path: $ElementType<Scalars, 'String'>,
  response_status_code?: ?$ElementType<Scalars, 'String'>,
};

export type GroupedSecurityEventsResponse = {
  __typename: 'GroupedSecurityEventsResponse',
  pagination_information: PaginationInformation,
  grouped_security_events: Array<GroupedSecurityEventsResponseItem>,
};

export type SecurityEventsResponseItem = {
  __typename: 'SecurityEventsResponseItem',
  date: $ElementType<Scalars, 'Date'>,
  method: $ElementType<Scalars, 'String'>,
  path: $ElementType<Scalars, 'String'>,
  event_type: $ElementType<Scalars, 'String'>,
  event_type_name: $ElementType<Scalars, 'String'>,
  source: SecurityEventsResponseSource,
  asset: SecurityEventsResponseAsset,
  severity: FindingSeverity,
  severity_name: $ElementType<Scalars, 'String'>,
};

export type ApiProtectAllowedEmailAddresses = {
  __typename: 'ApiProtectAllowedEmailAddresses',
  pagination_information: PaginationInformation,
  email_addresses: Array<?$ElementType<Scalars, 'String'>>,
};

export type ApiProtectAllowedApiKeys = {
  __typename: 'ApiProtectAllowedApiKeys',
  pagination_information: PaginationInformation,
  api_keys: Array<?$ElementType<Scalars, 'String'>>,
};

export type ApiProtectAllowedIpRanges = {
  __typename: 'ApiProtectAllowedIpRanges',
  pagination_information: PaginationInformation,
  ip_ranges: Array<?$ElementType<Scalars, 'String'>>,
};

export type ApiProtectAllowedDomains = {
  __typename: 'ApiProtectAllowedDomains',
  pagination_information: PaginationInformation,
  domains: Array<?$ElementType<Scalars, 'String'>>,
};

export type ApiProtectAllowedCountries = {
  __typename: 'ApiProtectAllowedCountries',
  pagination_information: PaginationInformation,
  countries: Array<?$ElementType<Scalars, 'String'>>,
};

export type ApiProtectAllowedEmailAddressesCreateResponse = {
  __typename: 'ApiProtectAllowedEmailAddressesCreateResponse',
  id?: ?$ElementType<Scalars, 'ID'>,
  user_or_org_email_address?: ?$ElementType<Scalars, 'String'>,
};

export type ApiProtectAllowedApiKeysCreateResponse = {
  __typename: 'ApiProtectAllowedApiKeysCreateResponse',
  id?: ?$ElementType<Scalars, 'ID'>,
  masked_api_key_value?: ?$ElementType<Scalars, 'String'>,
};

export type ApiProtectAllowedIpRangesCreateResponse = {
  __typename: 'ApiProtectAllowedIpRangesCreateResponse',
  id?: ?$ElementType<Scalars, 'ID'>,
  ip_range?: ?$ElementType<Scalars, 'String'>,
};

export type ApiProtectAllowedDomainsCreateResponse = {
  __typename: 'ApiProtectAllowedDomainsCreateResponse',
  id?: ?$ElementType<Scalars, 'ID'>,
  domain_name?: ?$ElementType<Scalars, 'String'>,
};

export type ApiProtectAllowedCountriesCreateResponse = {
  __typename: 'ApiProtectAllowedCountriesCreateResponse',
  id?: ?$ElementType<Scalars, 'ID'>,
  country_code?: ?$ElementType<Scalars, 'String'>,
};

export type ApiProtectRestfulApisAttacksSummaryResponse = {
  __typename: 'ApiProtectRestfulApisAttacksSummaryResponse',
  attacks_trend: Array<ApiProtectAttacksTrendItem>,
  total_eligible_restful_apis_count: $ElementType<Scalars, 'Float'>,
  total_eligible_restful_apis_protected_count: $ElementType<Scalars, 'Float'>,
  https_inbound_requests_count: $ElementType<Scalars, 'Float'>,
  total_attacks_blocked_count: $ElementType<Scalars, 'Float'>,
  total_attacks_detected_count: $ElementType<Scalars, 'Float'>,
  total_inbound_requests_count: $ElementType<Scalars, 'Float'>,
  total_outbound_requests_count: $ElementType<Scalars, 'Float'>,
  https_outbound_requests_count: $ElementType<Scalars, 'Float'>,
};

export type ApiProtectRestfulApiBelongsToCloudAccount = {
  __typename: 'ApiProtectRestfulApiBelongsToCloudAccount',
  cloud_account_icon_url: $ElementType<Scalars, 'String'>,
  cloud_account_type_name: $ElementType<Scalars, 'String'>,
  customer_supplied_name?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
};

export const ApiProtectRestfulApiSummaryFieldConnectorTypeEnumValues = Object.freeze({
  NETWORK: 'NETWORK', 
  CLOUD_ACCOUNT: 'CLOUD_ACCOUNT', 
  API_PROTECT_SDK: 'API_PROTECT_SDK'
});


export type ApiProtectRestfulApiSummaryFieldConnectorTypeEnum = $Values<typeof ApiProtectRestfulApiSummaryFieldConnectorTypeEnumValues>;

export type ApiProtectRestfulApiSummaryFieldConnector = {
  __typename: 'ApiProtectRestfulApiSummaryFieldConnector',
  api_protect_sdk_flavor?: ?$ElementType<Scalars, 'String'>,
  cloud_account_name?: ?$ElementType<Scalars, 'String'>,
  connector_icon_url?: ?$ElementType<Scalars, 'String'>,
  connector_name?: ?$ElementType<Scalars, 'String'>,
  connector_type?: ?ApiProtectRestfulApiSummaryFieldConnectorTypeEnum,
  network_name?: ?$ElementType<Scalars, 'String'>,
  network_url?: ?$ElementType<Scalars, 'String'>,
};

export type ApiProtectRestfulApiSummaryField = {
  __typename: 'ApiProtectRestfulApiSummaryField',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  url: $ElementType<Scalars, 'String'>,
  icon_url: $ElementType<Scalars, 'String'>,
  protection_status?: ?TrustKitProtectionStatus,
  attacks_blocked_count?: ?$ElementType<Scalars, 'Float'>,
  attacks_detected_count?: ?$ElementType<Scalars, 'Float'>,
  inbound_requests_count?: ?$ElementType<Scalars, 'Float'>,
  belongs_to_cloud_account?: ?ApiProtectRestfulApiBelongsToCloudAccount,
  region?: ?$ElementType<Scalars, 'String'>,
  horizon_uuid?: ?$ElementType<Scalars, 'String'>,
  connector?: ?ApiProtectRestfulApiSummaryFieldConnector,
};

export type ApiProtectRestfulApisResponse = {
  __typename: 'ApiProtectRestfulApisResponse',
  pagination_information: PaginationInformation,
  restful_apis: Array<?ApiProtectRestfulApiSummaryField>,
};

export type ApiProtectGenericTopItem = {
  __typename: 'ApiProtectGenericTopItem',
  value?: ?$ElementType<Scalars, 'String'>,
  was_authorized?: ?$ElementType<Scalars, 'Boolean'>,
  percentage?: ?$ElementType<Scalars, 'Float'>,
};

export type ApiProtectAttacksTrendItem = {
  __typename: 'ApiProtectAttacksTrendItem',
  day: $ElementType<Scalars, 'String'>,
  inbound_requests_count: $ElementType<Scalars, 'Float'>,
  attacks_detected_count: $ElementType<Scalars, 'Float'>,
  attacks_blocked_count: $ElementType<Scalars, 'Float'>,
};

export type ApiProtectRestfulApisRequestDetailsResponse = {
  __typename: 'ApiProtectRestfulApisRequestDetailsResponse',
  id: $ElementType<Scalars, 'String'>,
  date: $ElementType<Scalars, 'String'>,
  event_type_names: Array<$ElementType<Scalars, 'String'>>,
  source: SecurityEventsResponseSource,
  path: $ElementType<Scalars, 'String'>,
  method: $ElementType<Scalars, 'String'>,
  user_agent?: ?$ElementType<Scalars, 'String'>,
  content_type?: ?$ElementType<Scalars, 'String'>,
  request_size: $ElementType<Scalars, 'String'>,
  response_status_code?: ?$ElementType<Scalars, 'String'>,
  asset: SecurityEventsResponseAsset,
  raw_request: $ElementType<Scalars, 'String'>,
  raw_response: $ElementType<Scalars, 'String'>,
};

export type ApiProtectRestfulApisDetailsResponse = {
  __typename: 'ApiProtectRestfulApisDetailsResponse',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  protection_status?: ?TrustKitProtectionStatus,
  url?: ?$ElementType<Scalars, 'String'>,
  attacks_trend: Array<ApiProtectAttacksTrendItem>,
  total_attacks_detected_count: $ElementType<Scalars, 'Float'>,
  total_attacks_blocked_count: $ElementType<Scalars, 'Float'>,
  total_inbound_requests_count: $ElementType<Scalars, 'Float'>,
  https_inbound_requests_count: $ElementType<Scalars, 'Float'>,
  ssrf_attacks_detected_count: $ElementType<Scalars, 'Float'>,
  ssrf_attacks_blocked_count: $ElementType<Scalars, 'Float'>,
  sqli_attacks_detected_count: $ElementType<Scalars, 'Float'>,
  sqli_attacks_blocked_count: $ElementType<Scalars, 'Float'>,
  ddos_attacks_detected_count: $ElementType<Scalars, 'Float'>,
  ddos_attacks_blocked_count: $ElementType<Scalars, 'Float'>,
  total_outbound_requests_count: $ElementType<Scalars, 'Float'>,
  https_outbound_requests_count: $ElementType<Scalars, 'Float'>,
  should_block_non_https_requests: $ElementType<Scalars, 'Boolean'>,
  should_log_non_https_requests: $ElementType<Scalars, 'Boolean'>,
  malicious_inbound_requests_count: $ElementType<Scalars, 'Float'>,
  malicious_inbound_requests_blocked_count: $ElementType<Scalars, 'Float'>,
  authorized_inbound_requests_count: $ElementType<Scalars, 'Float'>,
  top_authorized_inbound_requests_ip_addresses: Array<?ApiProtectGenericTopItem>,
  top_authorized_inbound_requests_countries: Array<?ApiProtectGenericTopItem>,
  top_authorized_inbound_requests_domains: Array<?ApiProtectGenericTopItem>,
  top_authenticated_inbound_requests_user_ids: Array<?ApiProtectGenericTopItem>,
  top_authenticated_inbound_requests_api_keys: Array<?ApiProtectGenericTopItem>,
};

export type ApiProtectRequestsLogField = {
  __typename: 'ApiProtectRequestsLogField',
  id: $ElementType<Scalars, 'ID'>,
  source?: ?$ElementType<Scalars, 'String'>,
  date: $ElementType<Scalars, 'Date'>,
  human_readable_request_size?: ?$ElementType<Scalars, 'String'>,
  is_tls_encrypted?: ?$ElementType<Scalars, 'Boolean'>,
  detected_attack_types?: ?Array<?$ElementType<Scalars, 'String'>>,
  was_blocked?: ?$ElementType<Scalars, 'Boolean'>,
};

export type ApiProtectRestfulApisInboundRequestsLogsResponse = {
  __typename: 'ApiProtectRestfulApisInboundRequestsLogsResponse',
  pagination_information: PaginationInformation,
  inbound_requests_logs: Array<?ApiProtectRequestsLogField>,
};

export type ApiProtectPerCountrySubdivisionStatsIem = {
  __typename: 'ApiProtectPerCountrySubdivisionStatsIem',
  subdivision_name?: ?$ElementType<Scalars, 'String'>,
  subdivision_code?: ?$ElementType<Scalars, 'String'>,
  inbound_requests_count?: ?$ElementType<Scalars, 'Float'>,
};

export type ApiProtectPerCountryStatsItem = {
  __typename: 'ApiProtectPerCountryStatsItem',
  country_name?: ?$ElementType<Scalars, 'String'>,
  country_code?: ?$ElementType<Scalars, 'String'>,
  inbound_requests_count?: ?$ElementType<Scalars, 'Float'>,
  subdivisions?: ?Array<?ApiProtectPerCountrySubdivisionStatsIem>,
};

export type ApiProtectRestfulApisInboundRequestsLogsStatsResponse = {
  __typename: 'ApiProtectRestfulApisInboundRequestsLogsStatsResponse',
  per_country_stats: Array<?ApiProtectPerCountryStatsItem>,
};

export type HttpMethodBreakdown = {
  __typename: 'HttpMethodBreakdown',
  http_method: HttpMethod,
  count: $ElementType<Scalars, 'Int'>,
};

export type HttpMethodsBreakdownResponse = {
  __typename: 'HttpMethodsBreakdownResponse',
  http_methods_breakdown: Array<HttpMethodBreakdown>,
};

export type UserAssetGroupAccesses = {
  __typename: 'UserAssetGroupAccesses',
  asset_group_id?: ?$ElementType<Scalars, 'ID'>,
  asset_group_name?: ?$ElementType<Scalars, 'String'>,
  is_accessible_to_user?: ?$ElementType<Scalars, 'Boolean'>,
};

export type UserAssetGroup = {
  __typename: 'UserAssetGroup',
  id?: ?$ElementType<Scalars, 'ID'>,
  sevenhell_id?: ?$ElementType<Scalars, 'Float'>,
  first_name?: ?$ElementType<Scalars, 'String'>,
  last_name?: ?$ElementType<Scalars, 'String'>,
  login_email?: ?$ElementType<Scalars, 'String'>,
  has_access_to_all_asset_groups?: ?$ElementType<Scalars, 'Boolean'>,
  included_access_to_asset_group?: ?UserAssetGroupAccesses,
};

export type UserAssetGroupListResponse = {
  __typename: 'UserAssetGroupListResponse',
  pagination_information: PaginationInformation,
  users?: ?Array<?UserAssetGroup>,
};

export type TrustKitSingleStepSdkConfigurationResponse = {
  __typename: 'TrustKitSingleStepSDKConfigurationResponse',
  ios_config_as_plist?: ?$ElementType<Scalars, 'String'>,
  android_config_as_xml?: ?$ElementType<Scalars, 'String'>,
  sdk_download_url: $ElementType<Scalars, 'String'>,
};

export type TrustKitPinnedDomain = {
  __typename: 'TrustKitPinnedDomain',
  domain: $ElementType<Scalars, 'String'>,
  enforce_pinning: $ElementType<Scalars, 'Boolean'>,
  include_subdomains: $ElementType<Scalars, 'Boolean'>,
  expiration_date: $ElementType<Scalars, 'String'>,
  public_key_hashes: Array<$ElementType<Scalars, 'String'>>,
};

export type TrustKitPinnedDomainResponse = {
  __typename: 'TrustKitPinnedDomainResponse',
  pinned_domains: Array<TrustKitPinnedDomain>,
};

export type TrustKitSdkConfigurationResponse = {
  __typename: 'TrustKitSDKConfigurationResponse',
  ios_config_as_plist?: ?$ElementType<Scalars, 'String'>,
  android_config_as_xml?: ?$ElementType<Scalars, 'String'>,
};

export type ApiProtectOnboardingUrlResponse = {
  __typename: 'ApiProtectOnboardingUrlResponse',
  onboarding_url?: ?$ElementType<Scalars, 'String'>,
};

export type MobileAppCsvResponseHeader = {
  __typename: 'MobileAppCsvResponseHeader',
  code_name: $ElementType<Scalars, 'String'>,
  file_name: $ElementType<Scalars, 'String'>,
};

export type MobileAppCsvResponseRows = {
  __typename: 'MobileAppCsvResponseRows',
  answer_requirement: $ElementType<Scalars, 'String'>,
  question_id: $ElementType<Scalars, 'String'>,
  question_label: $ElementType<Scalars, 'String'>,
  response_id: $ElementType<Scalars, 'String'>,
  response_value: $ElementType<Scalars, 'String'>,
};

export type MobileAppCsvResponse = {
  __typename: 'MobileAppCsvResponse',
  headers: Array<MobileAppCsvResponseHeader>,
  rows: Array<MobileAppCsvResponseRows>,
};

export type UpdateGooglePlayDataSafetyManualAnswersBody = {
  answers: Array<GooglePlayDataSafetyManualAnswersItem>,
};

export type GooglePlayDataSafetyManualAnswersItem = {
  response_id?: ?$ElementType<Scalars, 'String'>,
  question_id: $ElementType<Scalars, 'String'>,
  response_value?: ?$ElementType<Scalars, 'Boolean'>,
};

export type GooglePlayDataSafetyManualAnswersResponses = {
  __typename: 'GooglePlayDataSafetyManualAnswersResponses',
  response_id: $ElementType<Scalars, 'String'>,
  response_id_display_name: $ElementType<Scalars, 'String'>,
  response_value?: ?$ElementType<Scalars, 'Boolean'>,
};

export type GooglePlayDataSafetyManualAnswersResponseQuestion = {
  __typename: 'GooglePlayDataSafetyManualAnswersResponseQuestion',
  answer_requirement: $ElementType<Scalars, 'String'>,
  needs_manual_answers: $ElementType<Scalars, 'Boolean'>,
  question_id: $ElementType<Scalars, 'String'>,
  question_label: $ElementType<Scalars, 'String'>,
  responses: Array<?GooglePlayDataSafetyManualAnswersResponses>,
};

export type GooglePlayDataSafetyManualAnswersResponseDataType = {
  __typename: 'GooglePlayDataSafetyManualAnswersResponseDataType',
  data_type: $ElementType<Scalars, 'String'>,
  data_type_display_name: $ElementType<Scalars, 'String'>,
  data_usage_questions: Array<?GooglePlayDataSafetyManualAnswersResponseQuestion>,
};

export type GooglePlayDataSafetyManualAnswersMutationResponse = {
  __typename: 'GooglePlayDataSafetyManualAnswersMutationResponse',
  data_collection_user_request_delete_question?: ?GooglePlayDataSafetyManualAnswersResponseQuestion,
  data_types?: ?Array<?GooglePlayDataSafetyManualAnswersResponseDataType>,
};

export type GooglePlayDataSafetyManualAnswersResponse = {
  __typename: 'GooglePlayDataSafetyManualAnswersResponse',
  data_collection_user_request_delete_question?: ?GooglePlayDataSafetyManualAnswersResponseQuestion,
  data_types?: ?Array<?GooglePlayDataSafetyManualAnswersResponseDataType>,
  needs_manual_answers: $ElementType<Scalars, 'Boolean'>,
};

export type ConfigurationsShadowResponseConfiguration = {
  __typename: 'ConfigurationsShadowResponseConfiguration',
  approved_asset_types: Array<$ElementType<Scalars, 'String'>>,
  approved_cloud_providers: Array<$ElementType<Scalars, 'String'>>,
  approved_app_stores: Array<$ElementType<Scalars, 'String'>>,
  public_asm_enabled: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_apis_not_going_through_gateway: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_assets_except_on_approved_asset_types: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_cloud_resources_except_on_approved_cloud_providers: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_mobile_apps_on_3rd_party_stores: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_mobile_apps_on_unofficial_app_stores: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_mobile_apps_except_on_approved_app_stores: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_mobile_apps_on_official_stores: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_network_services_with_dev_in_url: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_network_services_with_preprod_in_url: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_network_services_with_uat_in_url: $ElementType<Scalars, 'Boolean'>,
};

export type MobilePhishingDiscoveredPhishingAppsClonedLegitimateAppVisualIdentity = {
  __typename: 'MobilePhishingDiscoveredPhishingAppsClonedLegitimateAppVisualIdentity',
  date_seen_first: $ElementType<Scalars, 'String'>,
  date_seen_last?: ?$ElementType<Scalars, 'String'>,
  icon_url: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  name_detection_enabled: $ElementType<Scalars, 'Boolean'>,
};

export type MobilePhishingDiscoveredPhishingAppsStore = {
  __typename: 'MobilePhishingDiscoveredPhishingAppsStore',
  icon_url: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  popularity: $ElementType<Scalars, 'String'>,
  presence: $ElementType<Scalars, 'String'>,
};

export type MobilePhishingDiscoveredPhishingAppsClonedLegitimateApp = {
  __typename: 'MobilePhishingDiscoveredPhishingAppsClonedLegitimateApp',
  bundle_id: $ElementType<Scalars, 'String'>,
  last_5_visual_identities: Array<?MobilePhishingDiscoveredPhishingAppsClonedLegitimateAppVisualIdentity>,
  platform: $ElementType<Scalars, 'String'>,
  store: MobilePhishingDiscoveredPhishingAppsStore,
  url: $ElementType<Scalars, 'String'>,
  uuid: $ElementType<Scalars, 'String'>,
};

export type MobilePhishingDiscoveredPhishingAppsEventsResponseEvent = {
  __typename: 'MobilePhishingDiscoveredPhishingAppsEventsResponseEvent',
  date_created: $ElementType<Scalars, 'Date'>,
  discovered_app_uuid: $ElementType<Scalars, 'String'>,
  type: $ElementType<Scalars, 'String'>,
};

export type MobilePhishingDiscoveredPhishingAppsEventsResponse = {
  __typename: 'MobilePhishingDiscoveredPhishingAppsEventsResponse',
  pagination_information: PaginationInformation,
  events: Array<MobilePhishingDiscoveredPhishingAppsEventsResponseEvent>,
};

export type MobilePhishingDiscoveredPhishingAppsEventsResponseCommentUser = {
  __typename: 'MobilePhishingDiscoveredPhishingAppsEventsResponseCommentUser',
  date_created: $ElementType<Scalars, 'Date'>,
  first_name: $ElementType<Scalars, 'String'>,
  last_name: $ElementType<Scalars, 'String'>,
  login_email: $ElementType<Scalars, 'String'>,
  notification_email?: ?$ElementType<Scalars, 'String'>,
  uuid: $ElementType<Scalars, 'ID'>,
};

export type MobilePhishingDiscoveredPhishingAppsEventsResponseComment = {
  __typename: 'MobilePhishingDiscoveredPhishingAppsEventsResponseComment',
  content: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  user?: ?MobilePhishingDiscoveredPhishingAppsEventsResponseCommentUser,
  uuid: $ElementType<Scalars, 'ID'>,
};

export type MobilePhishingDiscoveredPhishingAppsCommentsResponse = {
  __typename: 'MobilePhishingDiscoveredPhishingAppsCommentsResponse',
  pagination_information: PaginationInformation,
  comments: Array<MobilePhishingDiscoveredPhishingAppsEventsResponseComment>,
};

export type MobilePhishingIgnoredKeyword = {
  __typename: 'MobilePhishingIgnoredKeyword',
  uuid: $ElementType<Scalars, 'String'>,
  keyword: $ElementType<Scalars, 'String'>,
  app_source_icon_url?: ?$ElementType<Scalars, 'String'>,
  app_source_name?: ?$ElementType<Scalars, 'String'>,
};

export type MobilePhishingIgnoredKeywordsResponse = {
  __typename: 'MobilePhishingIgnoredKeywordsResponse',
  pagination_information: PaginationInformation,
  keywords?: ?Array<?MobilePhishingIgnoredKeyword>,
};

export type MobilePhishingLegitimateAppsResponse = {
  __typename: 'MobilePhishingLegitimateAppsResponse',
  pagination_information: PaginationInformation,
  legitimate_apps: Array<MobilePhishingDiscoveredPhishingAppsClonedLegitimateApp>,
};

export type MobilePhishingStatsResponse = {
  __typename: 'MobilePhishingStatsResponse',
  apps_taken_down_following_data_theorem_intervention_count: $ElementType<Scalars, 'Float'>,
  apps_with_take_down_initiated_count: $ElementType<Scalars, 'Float'>,
  discovered_phishing_apps_count: $ElementType<Scalars, 'Float'>,
  discovered_phishing_apps_ignored_count: $ElementType<Scalars, 'Float'>,
  discovered_phishing_apps_removed_count: $ElementType<Scalars, 'Float'>,
  discovered_phishing_brands_count: $ElementType<Scalars, 'Float'>,
  legitimate_apps_with_phishing_version_count: $ElementType<Scalars, 'Float'>,
  onboarded_legitimate_apps_count: $ElementType<Scalars, 'Float'>,
};

export type MobilePhishingDiscoveredPhishingAppsResponseResult = {
  __typename: 'MobilePhishingDiscoveredPhishingAppsResponseResult',
  canonical_id: $ElementType<Scalars, 'String'>,
  cloned_legitimate_app?: ?MobilePhishingDiscoveredPhishingAppsClonedLegitimateApp,
  date_seen_first: $ElementType<Scalars, 'String'>,
  date_seen_last?: ?$ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  discovered_app_status: $ElementType<Scalars, 'String'>,
  icon_url: $ElementType<Scalars, 'String'>,
  impact_rating: $ElementType<Scalars, 'String'>,
  impact_rating_explanation: $ElementType<Scalars, 'String'>,
  infringement_level: $ElementType<Scalars, 'String'>,
  matching_keywords: Array<$ElementType<Scalars, 'String'>>,
  minimum_downloads?: ?$ElementType<Scalars, 'Float'>,
  name: $ElementType<Scalars, 'String'>,
  platform: $ElementType<Scalars, 'String'>,
  publisher_name?: ?$ElementType<Scalars, 'String'>,
  screenshot_urls: Array<$ElementType<Scalars, 'String'>>,
  store: MobilePhishingDiscoveredPhishingAppsStore,
  triaging_decision?: ?$ElementType<Scalars, 'String'>,
  url: $ElementType<Scalars, 'String'>,
  uuid: $ElementType<Scalars, 'String'>,
};

export type MobilePhishingDiscoveredPhishingAppsResponse = {
  __typename: 'MobilePhishingDiscoveredPhishingAppsResponse',
  pagination_information: PaginationInformation,
  discovered_phishing_apps: Array<MobilePhishingDiscoveredPhishingAppsResponseResult>,
};

export type ConfigurationsShadowResponse = {
  __typename: 'ConfigurationsShadowResponse',
  configuration: ConfigurationsShadowResponseConfiguration,
};

export type StanfordDishUniGetUserStatsResponseRewardsItem = {
  __typename: 'StanfordDishUniGetUserStatsResponseRewardsItem',
  completed: $ElementType<Scalars, 'Float'>,
  total: $ElementType<Scalars, 'Float'>,
};

export type StanfordDishUniGetUserStatsResponseRewards = {
  __typename: 'StanfordDishUniGetUserStatsResponseRewards',
  api: StanfordDishUniGetUserStatsResponseRewardsItem,
  web: StanfordDishUniGetUserStatsResponseRewardsItem,
  cloud: StanfordDishUniGetUserStatsResponseRewardsItem,
  mobile: StanfordDishUniGetUserStatsResponseRewardsItem,
};

export type StanfordDishUniGetUserStatsResponse = {
  __typename: 'StanfordDishUniGetUserStatsResponse',
  percentile: $ElementType<Scalars, 'Float'>,
  sections_viewed: $ElementType<Scalars, 'Float'>,
  total_sections: $ElementType<Scalars, 'Float'>,
  total_view_time: $ElementType<Scalars, 'Float'>,
  rewards: StanfordDishUniGetUserStatsResponseRewards,
};

export type StanfordDishUniMaterial = {
  __typename: 'StanfordDishUniMaterial',
  category: StanfordDishUniCategoryEnum,
  date_published: $ElementType<Scalars, 'String'>,
  date_viewed?: ?$ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  file_signed_url: $ElementType<Scalars, 'String'>,
  material_type: StanfordDishUniMaterialTypeEnum,
  material_type_icon_url: $ElementType<Scalars, 'String'>,
  user_view_time: $ElementType<Scalars, 'Float'>,
  thumbnail_image_signed_url: $ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
  user_viewing_status: StanfordDishUniUserViewingStatusEnum,
  uuid: $ElementType<Scalars, 'String'>,
};

export type StanfordDishUniChangelogEntry = {
  __typename: 'StanfordDishUniChangelogEntry',
  date_published: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  uuid: $ElementType<Scalars, 'String'>,
  products: Array<StanfordDishUniProductEnum>,
  product_icon_urls: Array<$ElementType<Scalars, 'String'>>,
  tags: Array<?$ElementType<Scalars, 'String'>>,
  thumbnail_image_signed_url: $ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
  learn_more_url?: ?$ElementType<Scalars, 'String'>,
  learn_more_display_name?: ?$ElementType<Scalars, 'String'>,
};

export type StanfordDishUniLiveTraining = {
  __typename: 'StanfordDishUniLiveTraining',
  date: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  location?: ?$ElementType<Scalars, 'String'>,
  product: StanfordDishUniProductEnum,
  product_icon_url: $ElementType<Scalars, 'String'>,
  thumbnail_image_signed_url: $ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
  user_attendance_status: StanfordDishUniUserAttendanceEnum,
  uuid: $ElementType<Scalars, 'String'>,
};

export type StanfordDishUniGetHighlightsResponseEntry = {
  __typename: 'StanfordDishUniGetHighlightsResponseEntry',
  uuid: $ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
  product?: ?$ElementType<Scalars, 'String'>,
  product_icon_url?: ?$ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  tags?: ?Array<?$ElementType<Scalars, 'String'>>,
  date_published?: ?$ElementType<Scalars, 'String'>,
  file_signed_url?: ?$ElementType<Scalars, 'String'>,
  file_type?: ?$ElementType<Scalars, 'String'>,
  thumbnail_image_signed_url: $ElementType<Scalars, 'String'>,
};

export type MobilePhishingIgnoredKeywordsPostResponse = {
  __typename: 'MobilePhishingIgnoredKeywordsPostResponse',
  keyword: $ElementType<Scalars, 'String'>,
  uuid: $ElementType<Scalars, 'String'>,
};

export type MobilePhishingIgnoredKeywordResponse = {
  __typename: 'MobilePhishingIgnoredKeywordResponse',
  keyword: $ElementType<Scalars, 'String'>,
  uuid: $ElementType<Scalars, 'String'>,
};

export type StanfordDishUniGetHighlightsResponse = {
  __typename: 'StanfordDishUniGetHighlightsResponse',
  materials: Array<StanfordDishUniMaterial>,
  changelog_entries: Array<StanfordDishUniChangelogEntry>,
  live_trainings: Array<StanfordDishUniLiveTraining>,
};

export type StanfordDishUniGetMaterialsResponse = {
  __typename: 'StanfordDishUniGetMaterialsResponse',
  materials: Array<StanfordDishUniMaterial>,
  pagination_information: PaginationInformation,
};

export type StanfordDishUniGetLatestChangelogEntriesResponseItem = {
  __typename: 'StanfordDishUniGetLatestChangelogEntriesResponseItem',
  date_from: $ElementType<Scalars, 'String'>,
  date_to: $ElementType<Scalars, 'String'>,
  month: $ElementType<Scalars, 'String'>,
  thumbnail_url: $ElementType<Scalars, 'String'>,
};

export type StanfordDishUniGetLatestChangelogEntriesResponse = {
  __typename: 'StanfordDishUniGetLatestChangelogEntriesResponse',
  pagination_information: PaginationInformation,
  months_with_changelog_entries: Array<StanfordDishUniGetLatestChangelogEntriesResponseItem>,
};

export type StanfordDishUniGetChangelogEntriesResponse = {
  __typename: 'StanfordDishUniGetChangelogEntriesResponse',
  changelog_entries: Array<StanfordDishUniChangelogEntry>,
  pagination_information: PaginationInformation,
};

export type StanfordDishUniGetLiveTrainingsResponse = {
  __typename: 'StanfordDishUniGetLiveTrainingsResponse',
  pagination_information: PaginationInformation,
  live_trainings: Array<StanfordDishUniLiveTraining>,
};

export type MobileProtectConfigurationWizardSdkVariantsCustomizeResponse = {
  __typename: 'MobileProtectConfigurationWizardSdkVariantsCustomizeResponse',
  ios_config_as_plist?: ?$ElementType<Scalars, 'String'>,
  android_config_as_xml?: ?$ElementType<Scalars, 'String'>,
  sdk_download_url: $ElementType<Scalars, 'String'>,
  onboarding_video_url: $ElementType<Scalars, 'String'>,
  sdk_variant_name: $ElementType<Scalars, 'String'>,
};

export type Query = {
  __typename: 'Query',
  mobile_phishing_discovered_phishing_apps_stats: MobilePhishingStatsResponse,
  discovered_apps_search_filter_values: DiscoveredAppsSearchFilterValuesResponse,
  mobile_phishing_legitimate_apps: MobilePhishingLegitimateAppsResponse,
  mobile_phishing_discovered_phishing_apps: MobilePhishingDiscoveredPhishingAppsResponse,
  mobile_phishing_discovered_phishing_apps_events: MobilePhishingDiscoveredPhishingAppsEventsResponse,
  mobile_phishing_discovered_phishing_apps_comments: MobilePhishingDiscoveredPhishingAppsCommentsResponse,
  mobile_phishing_ignored_keywords: MobilePhishingIgnoredKeywordsResponse,
  sdu_get_user_stats: StanfordDishUniGetUserStatsResponse,
  sdu_get_highlights: StanfordDishUniGetHighlightsResponse,
  sdu_get_latest_changelog_entries: StanfordDishUniGetLatestChangelogEntriesResponse,
  sdu_get_live_trainings: StanfordDishUniGetLiveTrainingsResponse,
  sdu_get_materials: StanfordDishUniGetMaterialsResponse,
  sdu_get_changelog_entries: StanfordDishUniGetChangelogEntriesResponse,
  assets_details_v2: AssetsDetailsResponseV2,
  app_sec_kpis_stats: AppSecKpisStatsResponse,
  mobile_app_sec_kpis_stats: MobileAppSecKpisStatsResponse,
  api_security_stats: MobileSecurityStatsResponse,
  mobile_security_stats: MobileSecurityStatsResponse,
  inventory_v2_keyword_search_values: InventoryV2KeywordSearchValuesResponse,
  api_assets_changes: ApiAssetsChangesResponse,
  sast_list_repositories: SastListRepositoriesResponse,
  sast_files_with_issues: SastFilesWithIssuesResponse,
  sast_issues_search_filter_values: SastSearchFilterResponse,
  sast_repository_issues: SastRepositoryIssuesResponse,
  sast_repository_issues_issue_by_id: SastRepositoryIssuesIssueByIdResponse,
  sast_stats: SastStatsResponse,
  assets_named_filters: AssetsNamedFiltersResponse,
  mobile_protect_named_filters: MobileProtectNamedFiltersResponse,
  mobile_protect_applications_named_filters: MobileApplicationNamedFiltersResponse,
  mobile_protect_named_filters_children: SecurityFindingsNamedFiltersChildrenResponse,
  protect_api_operations: ProtectApiOperationsResponse,
  protect_restful_apis_requests: ProtectRestfulApisRequestsResponse,
  get_named_filters_policies: GetNamedFiltersPoliciesResponse,
  security_findings_named_filters: SecurityFindingsNamedFiltersResponse,
  sast_named_filters: SastNamedFiltersResponse,
  security_findings_named_filters_children: SecurityFindingsNamedFiltersChildrenResponse,
  sast_named_filters_children: SastNamedFiltersChildrenResponse,
  mobile_applications_named_filters: MobileApplicationsNamedFiltersResponse,
  api_changes_named_filters: ApiChangesNamedFiltersResponse,
  shadow_assets_stats: ShadowAssetsStatsResponse,
  apis_analysis: ApisAnalysisResponse,
  kubernetes_cluster_components: KubernetesClusterComponentsResponse,
  kubernetes_cluster_resource: KubernetesClusterResourceResponse,
  kubernetes_cluster_component_resource: KubernetesClusterComponent,
  assets_assets_list_v2: AssetsAssetsListV2,
  assets_policy_violations_list_v2: AssetsPolicyViolationsListV2,
  compliance_standard_criteria_list: ComplianceStandardCriteriaListResponse,
  notification_events_list: EventsListResponse,
  policy_rules_by_policy_id_list: PolicyRulesByPolicyIdListResponse,
  policy_rules_list: PolicyRuleListResponse,
  policy_rules_list_v2: V2PolicyRulesListResponse,
  policy_rules_search_filter_values: PolicyRulesSearchFilterResponse,
  mobile_applications_protection_levels_stats: MobileAppProtectionLevelStatsResponse,
  policy_rule_types_list: PolicyRuleTypeListResponse,
  policies_policy_rule_types_list: PoliciesPolicyRuleTypeListResponse,
  policy_violations_comments_list: PolicyViolationsCommentsListResponse,
  get_policy_violations_keyword_search_items: PolicyViolationsKeywordSearchResponse,
  policy_violations_list: PolicyViolationListResponse,
  policy_violations_list_v2: PolicyViolationListResponseVersion2,
  policy_violation_details_v2: PolicyViolationV2,
  aws_authenticator_external_id: AwsAuthenticatorExternalIdResponse,
  policy_violation_by_id: PolicyViolationResponse,
  restful_api_list: RestfulApIsListResponse,
  restful_api_list_v1: RestfulApIsListResponse_V1,
  restful_api_details: RestfulApiDetailsResponse,
  user_asset_group_list: UserAssetGroupListResponse,
  grpc_methods_list: GrpcOperationsListResponse,
  soap_api_operations: SoapApiOperationsListResponse,
  api_operations_list_v2: ApiOperationsListResponseV2,
  api_operation_details: ApiOperationDetailsResponse,
  api_operation_details_v2: ApiOperationDetailsResponseVersion2,
  mobile_app_details_v2: ApiMobileApplicationDetailsResponse,
  compliance_reports_stats: ComplianceReportsStatsResponse,
  mobile_protect_app_coverage: MobileProtectAppCoverageResponse,
  mobile_applications_stats: MobileApplicationStatsResponse,
  mobile_app_list: MobileApplicationsListResponse,
  network_service_details: NetworkServiceDetailsResponse,
  policies_list: PoliciesListResponse,
  policies_list_v2: PoliciesListV2Response,
  policy_by_id: PolicyDetailResponse,
  web_applications_list: WebApplicationListResponse,
  web_applications_list_v2: WebApplicationListResponseVersion2,
  web_applications_details_v2: WebApplicationDetailsV2Response,
  web_application_details: WebApplicationDetailsResponse,
  web_application_credentials: WebApplicationCredentialsResponse,
  web_application_urls: WebApplicationUrlsResponse,
  mobile_application_details: MobileApplicationDetailsResponse,
  inventory_stats_get: InventoryStatsResponse,
  inventory_stats_reference_date_get: InventoryStatsResponse,
  special_scan_requests_list: SpecialScanRequestResponse,
  asset_group_list: AssetGroupListResponse,
  asset_group_list_v2: V2AssetGroupListResponse,
  asset_group_details: SingleAssetGroupResponse,
  asset_group_assets_summary_list: AssetGroupAssetsSummaryListResponse,
  shadow_assets_all_members_list: ShadowAssetsMembersResponse,
  shadow_assets_configuration_get: ShadowAssetsConfigurationResponse,
  detected_spa_credentials_list: DetectedSpaCredentialsResponse,
  cloud_authenticators_list_DEPRECATED: CloudAuthenticatorsResponse_Deprecated,
  cloud_authenticators_list: CloudAuthenticatorsResponse,
  horizon_alerts_integrations_list: AlertsIntegrationsResponse,
  web_applications_stats_get: WebApplicationsStatsResponse,
  api_operation_list: ApiOperationListResponse,
  cloud_resource_list: CloudResourceListResponse,
  cloud_resource_details_v2: CloudResourceV2,
  cloud_resource_details_policy_violations_list: PolicyViolationListResponseVersion2,
  cloud_resource_details: CloudResourceDetailsResponse,
  cloud_resource_categories_metrics: MetricsResponse,
  graphql_api_list: GraphqlApiListResponse,
  graphql_api_details: GraphqlApiDetailsResponse,
  graphql_api_scan_traces: GraphqlApiScanTracesResponse,
  policy_rule_type_list: PolicyRuleTypesResponse,
  full_stack_security_metrics: MetricsResponse,
  web_application_xss_toolkit_metrics: MetricsResponse,
  api_radar_metrics: MetricsResponse,
  ip_ranges_list: IpRangeListResponse,
  openapi_definitions_list: OpenApiDefinitionsListResponse,
  detect_and_inject_scans_schedules: DetectAndInjectScansSchedulesResponse,
  web_application_xss_scans_schedules: WebApplicationXssScansSchedulesResponse,
  specific_apps_compliance_report_schedules: AppSpecificComplianceReportScheduleResponse,
  global_compliance_report_schedules: GlobalComplianceReportScheduleResponse,
  toolkit_scans: ToolkitScansResponse,
  cloud_ciem_policy_rules: CloudCiemPolicyRulesResponse,
  cloud_ciem_alert_details: CloudCiemAlertDetailsResponse,
  domain_name_list: DomainNameListResponse,
  domain_names_discovered_urls_list: DomainNamesDiscoveredUrlsListResponse,
  domain_names_stats: DomainNamesStatsResponse,
  user_asset_groups_get?: ?UserAssetGroupsResponse,
  asset_list: AssetListResponse,
  bulk_action_move_to_asset_group_list: BulkMoveToAssetGroupListResponse,
  validate_scoped_access_tokens: ValidateScopedAccessTokensResponse,
  assets_search_filter_values: AssetsSearchFilterValuesResponse,
  sdu_assets_search_filter_values: MaterialsSearchFilterValuesResponse,
  postman_workflows_list: PostmanWorkflowsResponse,
  postman_workflows_get: PostmanWorkflowsGetResponse,
  asset_tags: AssetTagsResponse,
  asset_specification_file: AssetSpecificationFileResponse,
  asset_tag_key_list: AssetTagsKeysResponse,
  asset_tag_value_list: AssetTagsValuesResponse,
  break_glass_stats: BreakGlassStatsResponse,
  cloud_dlp_stats: CloudDlpStatsGetResponse,
  cloud_dlp_toolkit_config_data_get: CloudDlpToolkitConfigDataGetResponse,
  toolkit_scans_schedules_get: ToolkitScansScheduleGetResponse,
  web_app_protection_progress_list: WebAppProtectionProgressListResponse,
  web_app_protection_progress_get: WebAppProtectionProgressResponse,
  cloud_ransomware_stats: CloudRansomwareStats,
  shadow_inventory_per_cloud_provider_stats_get: ShadowInventoryPerCloudProviderStats,
  shadow_inventory_per_asset_type_stats_get: ShadowInventoryPerAssetTypeStats,
  configurations_shadow_get: ConfigurationsShadowResponse,
  custom_check_types_list: CustomCheckTypesResponse,
  custom_checks_stats_get: CustomCheckStatsResponse,
  vendors_list: VendorsListResponse,
  supply_chain_secure_app_list: SupplyChainSecureAppsResponse,
  supply_chain_secure_metrics_get: SupplyChainSecureMetricsResponse,
  headliner_hacks_stats_list: HeadlinerHacksStatsResponse,
  vendor_management_stats_get: VendorManagementStatsResponse,
  uploaded_authentication_details_list: UploadedAuthenticationDetailsResponse,
  network_telemetry_affected_asset_threat_event_stats: NetworkThreatEventStatsResponse,
  network_telemetry_threat_event_stats_cloud_list: NetworkThreatEventStatsResponse,
  network_telemetry_threat_event_stats_api_list: NetworkThreatEventStatsResponse,
  mobile_network_telemetry_threat_event_stats_api_list: NetworkThreatEventStatsResponse,
  network_telemetry_affected_assets_threat_events_list: NetworkTelemetryThreatEventsResponse,
  network_telemetry_affected_assets_policy_violations_list: NetworkTelemetryPolicyViolationSummaryResponse,
  blocker_metrics_get: BlockerMetrics,
  users_list: UsersListResponse,
  user_by_id?: ?User,
  portal_notifications_list: PortalNotificationsListResponse,
  mobile_apps_list: MobileAppsListResponse,
  mobile_apps_changes: MobileAppChangesResponse,
  mobile_changes_stats: MobileChangesStatsResponse,
  mobile_coverage_stats: MobileCoverageStatsResponse,
  current_user: User,
  mobile_app_analysis_schedules_get: MobileAppAnalysisScheduleResponse,
  mobile_app_analysis_requests_list: MobileAppAnalysisRequestListResponse,
  mobile_app_get: MobileApp,
  mobile_app_get_google_safety_csv: MobileAppCsvResponse,
  get_google_play_data_safety_manual_answers: GooglePlayDataSafetyManualAnswersResponse,
  mobile_app_insights: MobileAppInsightsResponse,
  mobile_app_insights_by_app_id: MobileAppInsightsByAppIdResponse,
  security_findings_search: SecurityFindingsSearchResponse,
  security_finding: SecurityFinding,
  auto_remediation_attempt_batch_details: AutoRemediationAttemptBatch,
  activity_log_events_search: ActivityLogEventListResponse,
  openscan_mobile_apps_get: OpenScanApp,
  openscan_mobile_apps_search: OpenScanMobileAppSearchResponse,
  openscan_favorite_apps: OpenScanMobileAppsResponse,
  secret_strings_list: SecretStringsListResponse,
  vendor_management_vendors_list: VendorManagementVendorsListResponse,
  vendor_management_current_vendor_get: Vendor,
  vendor_management_mobile_stats: VendorManagementMobileStatsResponse,
  vendor_management_vendor_by_id: VendorVanagementVendorByIdResponse,
  vendors_mobile_sdk_list: VendorsMobileSdkResponse,
  mediawatch_articles_list: MediawatchArticlesResponse,
  onboarding_data_get: OnboardingDataResponse,
  upload_api_key_get: UploadApiKeyResponse,
  get_api_keys: GetApiKeysResponse,
  customers_test_credentials_get: CustomersTestCredentialsResponse,
  saml_get: SamlGet,
  jira_integration_configs_list: JiraIntegrationConfigListResponse,
  jira_integration_configs_get: JiraIntegrationConfig,
  sdk_findings_list: SdkFindingCollectionResponse,
  sdks_list: SdkCollectionResponse,
  mobile_apps_sdks_list: SdkMobileAppsResponse,
  mobile_policy_rule_stats_list: MobilePolicyRuleStatsResponse,
  scoped_access_token_list?: ?ScopedAccessTokenListResponse,
  openscan_security_findings_get: OpenScanSecurityFindingResponse,
  mobile_app_insight_findings_get: MobileAppInsightFindingResponse,
  scan_runs_list: ScanRunsResponse,
  scan_run: ScanRun,
  scan_log: ScanLogResponse,
  list_connectors: ListConnectorsResponse,
  protection_stats: ProtectionStatsResponse,
  usage_stats: UsageStatsResponse,
  mobile_usage_stats: UsageStatsResponse,
  mobile_app_get_events: MobileAppGetEventsResponse,
  mobile_app_get_events_details: MobileAppGetEventsDetailsResponse,
  top_client_domains_stats: TopClientDomainsStatsResponse,
  mobile_top_client_domains_stats: TopClientDomainsStatsResponse,
  top_client_countries_stats: TopClientCountriesStatsResponse,
  mobile_top_client_countries_stats: TopClientCountriesStatsResponse,
  mobile_app_session_breakdown: MobileAppSessionBreakdownResponse,
  trustkit_dynamic_coverage_summary: TrustKitDynamicCoverageSummaryResponse,
  mobile_protection_stats: MobileProtectionStatsResponse,
  mobile_protect_apps: MobileProtectAppsResponse,
  trustkit_top_accessed_network_services: TrustKitTopAccessedNetworkServicesResponse,
  trustkit_accessed_network_services: TrustKitAccessedNetworkServicesResponse,
  trustkit_mobile_apps_list: TrustKitMobileAppListResponse,
  trustkit_summary_sdk_events: TrustKitIosSdkEventsResponse,
  trustkit_android_sdk_events: TrustKitAndroidSdkEventsResponse,
  trustkit_summary: TrustkitSummaryResponse,
  file_uploads_list: FileUploadsListResponse,
  file_uploads_get: FileUploadsResponse,
  get_all_sdk_variants: GetAllSdkVariantsResponse,
  api_protect_configuration: ApiProtectConfigurationResponse,
  grouped_security_events: GroupedSecurityEventsResponse,
  protection_rules: ProtectionRulesResponse,
  grouped_security_events_by_id: GroupedSecurityEventsByIdResponse,
  security_events: SecurityEventsResponse,
  per_country_events_stats: PerCountryEventsStatsResponse,
  mobile_protect_per_country_events_stats: PerCountryEventsStatsResponse,
  api_protect_protected_assets: ApiProtectProtectedAssetsResponse,
  api_protect_protected_allowed_email_addresses: ApiProtectAllowedEmailAddresses,
  api_protect_protected_allowed_api_keys: ApiProtectAllowedApiKeys,
  api_protect_protected_allowed_ip_ranges: ApiProtectAllowedIpRanges,
  api_protect_protected_allowed_domains: ApiProtectAllowedDomains,
  api_protect_protected_allowed_countries: ApiProtectAllowedCountries,
  restful_apis_attacks_summary: ApiProtectRestfulApisAttacksSummaryResponse,
  api_protect_restful_apis: ApiProtectRestfulApisResponse,
  api_protect_restful_apis_details: ApiProtectRestfulApisDetailsResponse,
  api_protect_restful_apis_request_details: ApiProtectRestfulApisRequestDetailsResponse,
  api_protect_restful_apis_inbound_requests_logs: ApiProtectRestfulApisInboundRequestsLogsResponse,
  api_protect_restful_apis_inbound_requests_logs_stats: ApiProtectRestfulApisInboundRequestsLogsStatsResponse,
  http_methods_breakdown: HttpMethodsBreakdownResponse,
  alerts_integrations_list: AlertsIntegrationsListResponse,
  alerts_integrations_get: AlertsIntegrationsDetails,
  alerts_integration_asm_asset_type_values: AlertsIntegrationAsmAssetTypeValuesResponse,
  alerts_integration_list_event_values: AlertsIntegrationsListEventValuesResponse,
  iac_scans: IacScanFilterResponse,
  api_changes_stats: ApiChangesStatsResponse,
  iac_scans_get: IacScanGetResponse,
  iac_scans_stats: IacScanStatsResponse,
  threats_breakdown: ThreatsBreakdownResponse,
};


export type QueryMobile_Phishing_Legitimate_AppsArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_Phishing_Discovered_Phishing_AppsArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_discovered_application_keyword?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_infringement_level?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_discovered_app_status?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_triaging_decision?: ?Array<?$ElementType<Scalars, 'String'>>,
  order_by?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_Phishing_Discovered_Phishing_Apps_EventsArgs = {
  uuid: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_Phishing_Discovered_Phishing_Apps_CommentsArgs = {
  uuid: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_Phishing_Ignored_KeywordsArgs = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySdu_Get_Latest_Changelog_EntriesArgs = {
  page_size?: ?$ElementType<Scalars, 'Float'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySdu_Get_Live_TrainingsArgs = {
  page_size?: ?$ElementType<Scalars, 'Float'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySdu_Get_MaterialsArgs = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_material_type?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_category?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_is_product_demo?: ?$ElementType<Scalars, 'String'>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySdu_Get_Changelog_EntriesArgs = {
  page_size?: ?$ElementType<Scalars, 'Float'>,
  date_from?: ?$ElementType<Scalars, 'String'>,
  date_to?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryAssets_Details_V2Args = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryApi_Assets_ChangesArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySast_List_RepositoriesArgs = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_platform?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
  filter_by_visibility?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySast_Files_With_IssuesArgs = {
  repoId: $ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_type?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_severity?: ?Array<?$ElementType<Scalars, 'String'>>,
};


export type QuerySast_Repository_IssuesArgs = {
  repoId: $ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_present_in_file_id: $ElementType<Scalars, 'String'>,
  filter_by_present_in_branch_name?: ?$ElementType<Scalars, 'String'>,
  filter_by_status?: ?$ElementType<Scalars, 'String'>,
  filter_by_type?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_severity?: ?Array<?$ElementType<Scalars, 'String'>>,
};


export type QuerySast_Repository_Issues_Issue_By_IdArgs = {
  repoId: $ElementType<Scalars, 'String'>,
  issueId: $ElementType<Scalars, 'String'>,
};


export type QueryMobile_Protect_Named_FiltersArgs = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_Protect_Applications_Named_FiltersArgs = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_Protect_Named_Filters_ChildrenArgs = {
  name: $ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryProtect_Api_OperationsArgs = {
  id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryProtect_Restful_Apis_RequestsArgs = {
  id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySecurity_Findings_Named_FiltersArgs = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySast_Named_FiltersArgs = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySecurity_Findings_Named_Filters_ChildrenArgs = {
  name: $ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySast_Named_Filters_ChildrenArgs = {
  name: $ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryKubernetes_Cluster_ComponentsArgs = {
  id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryKubernetes_Cluster_ResourceArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryKubernetes_Cluster_Component_ResourceArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryAssets_Assets_List_V2Args = {
  asset_id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryAssets_Policy_Violations_List_V2Args = {
  asset_id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryCompliance_Standard_Criteria_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryNotification_Events_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  network_service_id?: ?$ElementType<Scalars, 'ID'>,
  restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  cloud_resource_id?: ?$ElementType<Scalars, 'ID'>,
  policy_violation_id?: ?$ElementType<Scalars, 'ID'>,
  openapi_definition_id?: ?$ElementType<Scalars, 'ID'>,
  importance?: ?$ElementType<Scalars, 'String'>,
  start_date?: ?$ElementType<Scalars, 'Date'>,
  end_date?: ?$ElementType<Scalars, 'Date'>,
};


export type QueryPolicy_Rules_By_Policy_Id_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  policyId: $ElementType<Scalars, 'ID'>,
};


export type QueryPolicy_Rules_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  policy_rule_type_id?: ?$ElementType<Scalars, 'String'>,
  filter_by_has_open_policy_violations?: ?$ElementType<Scalars, 'Boolean'>,
  is_eligible_for_auto_remediation?: ?$ElementType<Scalars, 'Boolean'>,
  most_relevant_rules_only?: ?$ElementType<Scalars, 'Boolean'>,
};


export type QueryPolicy_Rules_List_V2Args = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?PolicyRulesIncludeEnum,
  filter_by_policy_rule_type_id?: ?$ElementType<Scalars, 'String'>,
  filter_by_has_open_policy_violations?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_asset_group_id?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_no_asset_group?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_relevance?: ?Array<PolicyRuleTypeRelevance>,
  filter_by_asset_type?: ?Array<AssetTypeEnum>,
  filter_by_compliance_standard?: ?Array<PolicyComplianceStandardCriteria>,
  is_eligible_for_auto_remediation?: ?$ElementType<Scalars, 'Boolean'>,
  most_relevant_rules_only?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_asset_type_group?: ?Array<?AssetTypeGroup>,
  filter_by_asset_ownership?: ?$ElementType<Scalars, 'String'>,
  filter_by_has_policy_violations_with_status?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_related_first_party_asset_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_asset_vendor_id?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_has_policy_violations_with_statuses?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_severity?: ?Array<?FindingSeverity>,
  filter_by_policy_violation_named_filter?: ?$ElementType<Scalars, 'String'>,
  filter_by_belongs_to_products?: ?Array<?$ElementType<Scalars, 'String'>>,
};


export type QueryPolicy_Rule_Types_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryPolicies_Policy_Rule_Types_ListArgs = {
  policy_id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
  filter_by_group?: ?Array<$ElementType<Scalars, 'String'>>,
  filter_by_compliance_standard_criteria?: ?Array<$ElementType<Scalars, 'String'>>,
  include_headliner_hacks_only?: ?$ElementType<Scalars, 'Boolean'>,
  include_vendor_scs_headliner_hacks_only?: ?$ElementType<Scalars, 'Boolean'>,
};


export type QueryPolicy_Violations_Comments_ListArgs = {
  policy_violation_id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryGet_Policy_Violations_Keyword_Search_ItemsArgs = {
  filter_by_search_ui: $ElementType<Scalars, 'String'>,
};


export type QueryPolicy_Violations_ListArgs = {
  violated_policy_rule_id?: ?$ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  is_eligible_for_auto_remediation?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_violation_status?: ?Array<?PolicyViolationStatusEnum>,
  filter_by_policy_rule_type_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_relevance?: ?Array<?PolicyRuleTypeRelevance>,
  was_discovered_after?: ?$ElementType<Scalars, 'Date'>,
  filter_by_importance_tag?: ?Array<?FilterByImportanceTagEnum>,
  filter_by_product?: ?Array<?FilterByProductEnum>,
};


export type QueryPolicy_Violations_List_V2Args = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  order_by?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
  was_discovered_after?: ?$ElementType<Scalars, 'Date'>,
  filter_by_importance_tag?: ?Array<?FilterByImportanceTagEnum>,
  filter_by_product?: ?Array<?FilterByProductEnum>,
  filter_by_violation_status?: ?Array<?PolicyViolationStatusEnum>,
  filter_by_relevance?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_asset_group_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_asset_ownership?: ?$ElementType<Scalars, 'String'>,
  filter_by_related_first_party_asset_ids?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_no_asset_group?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_asset_vendor_ids?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_policy_rule_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_policy_rule_type_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_ongoing_triaging_effort?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryPolicy_Violation_Details_V2Args = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryPolicy_Violation_By_IdArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryRestful_Api_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryRestful_Api_List_V1Args = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryRestful_Api_DetailsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryUser_Asset_Group_ListArgs = {
  include_access_to_asset_group_id?: ?$ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_access_to_asset_group_id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryGrpc_Methods_ListArgs = {
  grpc_service_id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  include_api_radar_stats?: ?$ElementType<Scalars, 'Boolean'>,
};


export type QuerySoap_Api_OperationsArgs = {
  soap_api_id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  include_api_radar_stats?: ?$ElementType<Scalars, 'Boolean'>,
};


export type QueryApi_Operations_List_V2Args = {
  restful_api_id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_is_authenticated?: ?$ElementType<Scalars, 'Boolean'>,
  include_api_radar_stats?: ?$ElementType<Scalars, 'Boolean'>,
};


export type QueryApi_Operation_DetailsArgs = {
  restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryApi_Operation_Details_V2Args = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryMobile_App_Details_V2Args = {
  id?: ?$ElementType<Scalars, 'ID'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_Protect_App_CoverageArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryMobile_App_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_cloud_provider?: ?Array<?HostedOn>,
  filter_by_asset_type?: ?Array<?AssetTypeEnum>,
  filter_by_vulnerable_to_toolkit_hacks?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_active_protection_status?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_external_id?: ?$ElementType<Scalars, 'String'>,
  filter_by_asset_group_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_platform?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_discovered_via?: ?Array<?DiscoveredVia>,
  order_by?: ?Array<?MobileAppOrderBy>,
  filter_by_linked_to_customer_mobile_app_id?: ?$ElementType<Scalars, 'String'>,
  filter_by_link_candidate_type?: ?$ElementType<Scalars, 'String'>,
  filter_by_asset_tags?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
  filter_by_subscription?: ?Array<?$ElementType<Scalars, 'String'>>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
};


export type QueryNetwork_Service_DetailsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryPolicies_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  include_scs_policies_only?: ?$ElementType<Scalars, 'Boolean'>,
};


export type QueryPolicies_List_V2Args = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  include_scs_policies_only?: ?$ElementType<Scalars, 'Boolean'>,
};


export type QueryPolicy_By_IdArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryWeb_Applications_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryWeb_Applications_List_V2Args = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
  order_by?: ?$ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
};


export type QueryWeb_Applications_Details_V2Args = {
  id?: ?$ElementType<Scalars, 'ID'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type QueryWeb_Application_DetailsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryWeb_Application_CredentialsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryWeb_Application_UrlsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_Application_DetailsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryInventory_Stats_Reference_Date_GetArgs = {
  reference_date?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySpecial_Scan_Requests_ListArgs = {
  scan_type?: ?$ElementType<Scalars, 'String'>,
};


export type QueryAsset_Group_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryAsset_Group_List_V2Args = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?AssetGroupsSearchIncludeEnum,
  page_size?: ?$ElementType<Scalars, 'Float'>,
};


export type QueryAsset_Group_DetailsArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryAsset_Group_Assets_Summary_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryShadow_Assets_All_Members_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryCloud_Authenticators_ListArgs = {
  filter_by_authenticator?: ?Array<?CloudAuthenticatorType>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryApi_Operation_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_is_authenticated?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_asset_group_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
};


export type QueryCloud_Resource_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
  filter_by_discovered_via?: ?$ElementType<Scalars, 'String'>,
  filter_by_domain_name_id?: ?$ElementType<Scalars, 'ID'>,
  filter_by_cloud_provider?: ?Array<?HostedOn>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_category?: ?Array<?FilterByCategoryEnum>,
  order_by?: ?CloudResourceOrderBy,
};


export type QueryCloud_Resource_Details_V2Args = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryCloud_Resource_Details_Policy_Violations_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryCloud_Resource_DetailsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryGraphql_Api_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  hosted_on_cloud_providers?: ?HostedOn,
  text?: ?$ElementType<Scalars, 'String'>,
};


export type QueryGraphql_Api_DetailsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryGraphql_Api_Scan_TracesArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryIp_Ranges_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryOpenapi_Definitions_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryToolkit_ScansArgs = {
  filter_by_type?: ?Array<?ToolkitScanTypeEnum>,
  filter_by_status?: ?SpecialScanStatus,
};


export type QueryCloud_Ciem_Alert_DetailsArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_threat_type: $ElementType<Scalars, 'String'>,
};


export type QueryDomain_Name_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?SearchDomainNamesIncludeEnum,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_parent_domain_name_id?: ?$ElementType<Scalars, 'String'>,
  include_parent_domains_only?: ?$ElementType<Scalars, 'Boolean'>,
};


export type QueryDomain_Names_Discovered_Urls_ListArgs = {
  id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryUser_Asset_Groups_GetArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryAsset_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
  include_v2_fields?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_cloud_provider?: ?Array<?HostedOn>,
  filter_by_asset_type?: ?Array<?AssetTypeEnum>,
  filter_by_discovered_via?: ?Array<?DiscoveredVia>,
  filter_by_asset_group_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_is_shadow?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
  filter_by_vendor_id?: ?$ElementType<Scalars, 'String'>,
  filter_by_asset_tags?: ?$ElementType<Scalars, 'String'>,
  filter_by_ownership?: ?$ElementType<Scalars, 'String'>,
  filter_by_related_asset?: ?$ElementType<Scalars, 'String'>,
  filter_by_related_first_party_asset_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_asset_type_group?: ?Array<?$ElementType<Scalars, 'String'>>,
  order_by?: ?AssetOrderBy,
  page_size?: ?$ElementType<Scalars, 'Float'>,
};


export type QueryBulk_Action_Move_To_Asset_Group_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_status?: ?BulkActionsStatusEnum,
};


export type QueryValidate_Scoped_Access_TokensArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryAssets_Search_Filter_ValuesArgs = {
  filter_by_search_ui?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySdu_Assets_Search_Filter_ValuesArgs = {
  filter_by_is_product_demo?: ?$ElementType<Scalars, 'String'>,
};


export type QueryPostman_Workflows_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_onboarding_status?: ?Array<$ElementType<Scalars, 'String'>>,
  filter_by_purpose?: ?$ElementType<Scalars, 'String'>,
  filter_by_custom_check_type?: ?Array<$ElementType<Scalars, 'String'>>,
  filter_by_product_type?: ?$ElementType<Scalars, 'String'>,
};


export type QueryPostman_Workflows_GetArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryAsset_TagsArgs = {
  uuid: $ElementType<Scalars, 'ID'>,
};


export type QueryAsset_Specification_FileArgs = {
  uuid: $ElementType<Scalars, 'ID'>,
};


export type QueryAsset_Tag_Key_ListArgs = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
};


export type QueryAsset_Tag_Value_ListArgs = {
  tag_uuid: $ElementType<Scalars, 'ID'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
};


export type QueryToolkit_Scans_Schedules_GetArgs = {
  toolkit_scan_type: ToolkitScanTypeEnum,
};


export type QueryWeb_App_Protection_Progress_GetArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryCustom_Check_Types_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
  product_type: $ElementType<Scalars, 'String'>,
};


export type QueryCustom_Checks_Stats_GetArgs = {
  product_type: $ElementType<Scalars, 'String'>,
};


export type QueryVendors_ListArgs = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_related_first_party_asset_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_related_first_party_asset_type?: ?Array<?SupplyChainSecureAppTypeEnum>,
  filter_by_asset_type?: ?Array<?AssetTypeEnum>,
  filter_by_uses_third_party_vendor_id?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_asset_type_group?: ?Array<?ThirdPartyAssetTypeGroupEnum>,
  include?: ?VendorsListInclude,
  page_size?: ?$ElementType<Scalars, 'Float'>,
  order_by?: ?VendorListOrderByEnum,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySupply_Chain_Secure_App_ListArgs = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_asset_type?: ?Array<SupplyChainSecureAppTypeEnum>,
  filter_by_uses_third_party_vendor_id?: ?Array<$ElementType<Scalars, 'String'>>,
  filter_by_related_first_party_asset_type?: ?Array<?SupplyChainSecureAppTypeEnum>,
  order_by?: ?SupplyChainSecureAppOrderByEnum,
  page_size?: ?$ElementType<Scalars, 'Int'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySupply_Chain_Secure_Metrics_GetArgs = {
  include?: ?$ElementType<Scalars, 'String'>,
};


export type QueryHeadliner_Hacks_Stats_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_vendor_scs_only?: ?$ElementType<Scalars, 'Boolean'>,
};


export type QueryUploaded_Authentication_Details_ListArgs = {
  filter_by_authentication_type: $ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryNetwork_Telemetry_Affected_Asset_Threat_Event_StatsArgs = {
  asset_uuid: $ElementType<Scalars, 'ID'>,
};


export type QueryNetwork_Telemetry_Threat_Event_Stats_Api_ListArgs = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_Network_Telemetry_Threat_Event_Stats_Api_ListArgs = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryNetwork_Telemetry_Affected_Assets_Threat_Events_ListArgs = {
  asset_uuid: $ElementType<Scalars, 'ID'>,
  filter_by_threat_type: $ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  page_size?: ?$ElementType<Scalars, 'Int'>,
};


export type QueryNetwork_Telemetry_Affected_Assets_Policy_Violations_ListArgs = {
  asset_uuid: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_threat_type?: ?$ElementType<Scalars, 'String'>,
};


export type QueryUsers_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryUser_By_IdArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryPortal_Notifications_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  network_service_id?: ?$ElementType<Scalars, 'ID'>,
  restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  cloud_resource_id?: ?$ElementType<Scalars, 'ID'>,
  policy_violation_id?: ?$ElementType<Scalars, 'ID'>,
  openapi_definition_id?: ?$ElementType<Scalars, 'ID'>,
  importance?: ?$ElementType<Scalars, 'String'>,
  start_date?: ?$ElementType<Scalars, 'Date'>,
  end_date?: ?$ElementType<Scalars, 'Date'>,
};


export type QueryMobile_Apps_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter?: ?MobileAppListFilter,
  platform?: ?MobileAppPlatform,
  include?: ?Array<$ElementType<Scalars, 'String'>>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
  bundle_id?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  release_type?: ?MobileAppReleaseType,
  subscription?: ?MobileAppSubscription,
  updated_since?: ?$ElementType<Scalars, 'Date'>,
};


export type QueryMobile_Apps_ChangesArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_App_Analysis_Schedules_GetArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryMobile_App_Analysis_Requests_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_App_GetArgs = {
  id: $ElementType<Scalars, 'ID'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_App_Get_Google_Safety_CsvArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryGet_Google_Play_Data_Safety_Manual_AnswersArgs = {
  id: $ElementType<Scalars, 'String'>,
};


export type QueryMobile_App_InsightsArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  mobile_app_bundle_id?: ?$ElementType<Scalars, 'String'>,
  mobile_app_platform?: ?MobileAppPlatform,
  mobile_app_release_type?: ?MobileAppReleaseType,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_App_Insights_By_App_IdArgs = {
  appId: $ElementType<Scalars, 'String'>,
};


export type QuerySecurity_Findings_SearchArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  keywords?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
  mobile_app_id?: ?Array<$ElementType<Scalars, 'String'>>,
  mobile_app_uuid?: ?Array<$ElementType<Scalars, 'String'>>,
  current_status?: ?Array<TargetStatus>,
  priority?: ?Array<SecurityFindingSearchPriority>,
  importance_tags?: ?Array<SecurityFindingSearchImportanceTag>,
  severity?: ?Array<FindingSeverity>,
  compliance_policy?: ?Array<CompliancePolicy>,
  release_type?: ?Array<SecurityFindingSearchReleaseType>,
  age?: ?$ElementType<Scalars, 'Float'>,
  targets_created_after?: ?$ElementType<Scalars, 'Date'>,
  issue_type_id?: ?$ElementType<Scalars, 'String'>,
  has_associated_sdks?: ?$ElementType<Scalars, 'Boolean'>,
  include_only_targets_related_to_sdks?: ?$ElementType<Scalars, 'Boolean'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySecurity_FindingArgs = {
  id: $ElementType<Scalars, 'String'>,
};


export type QueryAuto_Remediation_Attempt_Batch_DetailsArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryActivity_Log_Events_SearchArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
  keywords?: ?$ElementType<Scalars, 'String'>,
};


export type QueryOpenscan_Mobile_Apps_GetArgs = {
  id: $ElementType<Scalars, 'ID'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type QueryOpenscan_Mobile_Apps_SearchArgs = {
  query: $ElementType<Scalars, 'String'>,
};


export type QueryOpenscan_Favorite_AppsArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySecret_Strings_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryVendor_Management_Vendors_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type QueryVendor_Management_Current_Vendor_GetArgs = {
  include?: ?$ElementType<Scalars, 'String'>,
};


export type QueryVendor_Management_Vendor_By_IdArgs = {
  vendorId: $ElementType<Scalars, 'ID'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type QueryVendors_Mobile_Sdk_ListArgs = {
  vendorId: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMediawatch_Articles_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  impacted_security_finding_id?: ?$ElementType<Scalars, 'String'>,
};


export type QueryJira_Integration_Configs_GetArgs = {
  id?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySdk_Findings_ListArgs = {
  mobile_app_id?: ?$ElementType<Scalars, 'Float'>,
  sdk_id?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter?: ?SdkFindingsListFilter,
  status_group?: ?StatusGroup,
  updated_since?: ?$ElementType<Scalars, 'Date'>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
};


export type QuerySdks_ListArgs = {
  page_size?: ?$ElementType<Scalars, 'Float'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter?: ?$ElementType<Scalars, 'String'>,
  omit_sdk_findings?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_Apps_Sdks_ListArgs = {
  id: $ElementType<Scalars, 'ID'>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  platform?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_Policy_Rule_Stats_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_third_party_only?: ?$ElementType<Scalars, 'Boolean'>,
  keywords?: ?$ElementType<Scalars, 'String'>,
  relevance?: ?Array<?PolicyRuleTypeRelevance>,
  mobile_app_id?: ?$ElementType<Scalars, 'String'>,
  mobile_app_uuid?: ?Array<$ElementType<Scalars, 'String'>>,
  sdk_id?: ?Array<$ElementType<Scalars, 'String'>>,
};


export type QueryOpenscan_Security_Findings_GetArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryMobile_App_Insight_Findings_GetArgs = {
  mobile_app_id: $ElementType<Scalars, 'String'>,
};


export type QueryScan_Runs_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_scan_type?: ?Array<?ScanRunsTypeEnum>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_date_updated?: ?$ElementType<Scalars, 'Date'>,
};


export type QueryScan_RunArgs = {
  uuid: $ElementType<Scalars, 'ID'>,
};


export type QueryScan_LogArgs = {
  url: $ElementType<Scalars, 'String'>,
  headers: Headers,
};


export type QueryProtection_StatsArgs = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryUsage_StatsArgs = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_Usage_StatsArgs = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_App_Get_EventsArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryMobile_App_Get_Events_DetailsArgs = {
  id: $ElementType<Scalars, 'ID'>,
  eventId: $ElementType<Scalars, 'ID'>,
};


export type QueryTop_Client_Domains_StatsArgs = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_Top_Client_Domains_StatsArgs = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryTop_Client_Countries_StatsArgs = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_Top_Client_Countries_StatsArgs = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_App_Session_BreakdownArgs = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryTrustkit_Dynamic_Coverage_SummaryArgs = {
  bundle_id: $ElementType<Scalars, 'String'>,
  platform: $ElementType<Scalars, 'String'>,
};


export type QueryMobile_Protection_StatsArgs = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_Protect_AppsArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryTrustkit_Top_Accessed_Network_ServicesArgs = {
  bundle_id: $ElementType<Scalars, 'String'>,
  platform: $ElementType<Scalars, 'String'>,
};


export type QueryTrustkit_Accessed_Network_ServicesArgs = {
  bundle_id: $ElementType<Scalars, 'String'>,
  platform: $ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryTrustkit_Summary_Sdk_EventsArgs = {
  bundle_id: $ElementType<Scalars, 'String'>,
};


export type QueryTrustkit_Android_Sdk_EventsArgs = {
  bundle_id: $ElementType<Scalars, 'String'>,
};


export type QueryTrustkit_SummaryArgs = {
  bundle_id: $ElementType<Scalars, 'String'>,
  platform: $ElementType<Scalars, 'String'>,
};


export type QueryFile_Uploads_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_file_type?: ?Array<?$ElementType<Scalars, 'String'>>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type QueryFile_Uploads_GetArgs = {
  hash?: ?$ElementType<Scalars, 'String'>,
};


export type QueryGrouped_Security_EventsArgs = {
  filter_by_severity?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryGrouped_Security_Events_By_IdArgs = {
  eventId: $ElementType<Scalars, 'String'>,
};


export type QuerySecurity_EventsArgs = {
  filter_by_asset_id?: ?$ElementType<Scalars, 'ID'>,
  filter_by_event_type?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_source_country_code?: ?$ElementType<Scalars, 'String'>,
  filter_by_source_subdivision_code?: ?$ElementType<Scalars, 'String'>,
  filter_by_severity?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryPer_Country_Events_StatsArgs = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_Protect_Per_Country_Events_StatsArgs = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryApi_Protect_Protected_AssetsArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryApi_Protect_Restful_ApisArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryApi_Protect_Restful_Apis_DetailsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryApi_Protect_Restful_Apis_Request_DetailsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
  requestId?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryApi_Protect_Restful_Apis_Inbound_Requests_LogsArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryApi_Protect_Restful_Apis_Inbound_Requests_Logs_StatsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryHttp_Methods_BreakdownArgs = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type QueryAlerts_Integrations_ListArgs = {
  filter_by_integration_type?: ?Array<?$ElementType<Scalars, 'String'>>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryAlerts_Integrations_GetArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryIac_ScansArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_scan_type: IacScanFilterEnum,
};


export type QueryIac_Scans_GetArgs = {
  id: $ElementType<Scalars, 'String'>,
};


export type QueryThreats_BreakdownArgs = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};

export type MobileProtectApiKeysParams = {
  type: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
};

export type CredentialTriageParams = {
  triaging_decision?: ?CredentialTriagingDecisionEnum,
};

export type ShadowAssetsTriageActionsParams = {
  perform_on_assets_with_ids: Array<$ElementType<Scalars, 'ID'>>,
  move_to_asset_group_id?: ?$ElementType<Scalars, 'ID'>,
};

export type AutoRemediationAttemptBatches = {
  policy_violations_ids: Array<$ElementType<Scalars, 'ID'>>,
};

export type SpecialScanRequestParams = {
  scan_type: SpecialScanType,
};

export type PolicyRuleConfigurationParams = {
  http_methods_to_scan?: ?Array<HttpMethod>,
  weekdays_when_to_scan?: ?Array<Weekday>,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
};

export type PolicyRulesUpdateParams = {
  has_all_alerts_muted?: ?$ElementType<Scalars, 'Boolean'>,
  relevance?: ?PolicyRuleTypeRelevance,
  configuration?: ?PolicyRuleConfigurationParams,
};

export type PolicyRulesCreateParams = {
  policy_rule_type_id: $ElementType<Scalars, 'String'>,
  relevance?: ?PolicyRuleTypeRelevance,
  configuration?: ?PolicyRuleConfigurationParams,
};

export type GetLargeFileUploadUrlParams = {
  file_name: $ElementType<Scalars, 'String'>,
  file_type: $ElementType<Scalars, 'String'>,
  file_size: $ElementType<Scalars, 'Float'>,
  uploader_comment?: ?$ElementType<Scalars, 'String'>,
};

export type DetectAndInjectScanRunConfigurationParams = {
  http_methods_to_scan?: ?Array<HttpMethod>,
};

export type DetectAndInjectConfigurationParams = {
  should_scan_all_api_operations?: ?$ElementType<Scalars, 'Boolean'>,
  scan_run_configuration?: ?DetectAndInjectScanRunConfigurationParams,
};

export type DetectAndInjectScansScheduleSetRequestParams = {
  weekdays_when_to_scan?: ?Array<Weekday>,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  toolkit_scan_type?: ?ToolkitScanTypeEnum,
  detect_and_inject_configuration?: ?DetectAndInjectConfigurationParams,
};

export type ToolkitScanStartRequestEmptyParams = {
  _?: ?$ElementType<Scalars, 'String'>,
};

export type WebApplicationXssScanRunConfigurationParams = {
  should_inject_in_get_parameters?: ?$ElementType<Scalars, 'Boolean'>,
  should_inject_in_post_parameters?: ?$ElementType<Scalars, 'Boolean'>,
  should_inject_in_url_path?: ?$ElementType<Scalars, 'Boolean'>,
  intensity?: ?WebApplicationXssScanIntensityEnum,
};

export type WebApplicationXssConfigurationParams = {
  web_applications_ids?: ?Array<$ElementType<Scalars, 'String'>>,
  should_scan_all_web_applications?: ?$ElementType<Scalars, 'Boolean'>,
  scan_run_configuration?: ?WebApplicationXssScanRunConfigurationParams,
};

export type WebApplicationXssScansScheduleSetRequestParams = {
  weekdays_when_to_scan?: ?Array<Weekday>,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  toolkit_scan_type?: ?ToolkitScanTypeEnum,
  web_application_xss_configuration?: ?WebApplicationXssConfigurationParams,
};

export type WeekdaysParams = {
  data: Array<Weekday>,
};

export type MobileAppAnalysisScheduleParams = {
  time_when_to_scan: $ElementType<Scalars, 'String'>,
  weekdays_when_to_scan?: ?WeekdaysParams,
};

export type MobileAppAnalysisRequestParams = {
  feature: MobileAppAnalysisFeatureEnum,
};

export type SecretStringParams = {
  value: $ElementType<Scalars, 'String'>,
  exclude_from_results?: ?$ElementType<Scalars, 'Boolean'>,
};

export type SecretStringUpdateParams = {
  exclude_from_results?: ?$ElementType<Scalars, 'Boolean'>,
};

export type SevenhellSupportRequestsCreateParams = {
  body: $ElementType<Scalars, 'String'>,
  subject: $ElementType<Scalars, 'String'>,
};

export type VendorManagementCreateParams = {
  name: $ElementType<Scalars, 'String'>,
  contact_emails?: ?Array<$ElementType<Scalars, 'String'>>,
  website_url?: ?$ElementType<Scalars, 'String'>,
};

export type VendorManagementRequestOnboardingContactsParams = {
  contact_emails?: ?Array<$ElementType<Scalars, 'String'>>,
};

export type VendorManagementUpdateParams = {
  name?: ?$ElementType<Scalars, 'String'>,
  deletion_requested?: ?$ElementType<Scalars, 'Boolean'>,
  scans_enabled?: ?$ElementType<Scalars, 'Boolean'>,
  contact_emails?: ?VendorManagementCurrentVendorPatchContactEmailParams,
};

export type AlertsIntegrationsTestRequest = {
  integration_type: AlertsIntegrationsTypeEnum,
  slack_or_teams_url?: ?$ElementType<Scalars, 'String'>,
  webhook_url?: ?$ElementType<Scalars, 'String'>,
  webhook_authorization_header?: ?$ElementType<Scalars, 'String'>,
};

export type MobileSecureSlackOrMsftTeamsConfigField = {
  should_send_summary_notifications: $ElementType<Scalars, 'Boolean'>,
  should_send_real_time_notifications: $ElementType<Scalars, 'Boolean'>,
  should_notify_about_mobile_scan_alerts: $ElementType<Scalars, 'Boolean'>,
  should_notify_about_security_p1_issues: $ElementType<Scalars, 'Boolean'>,
  should_notify_about_store_blocker_issues: $ElementType<Scalars, 'Boolean'>,
  should_notify_about_high_severity_issues: $ElementType<Scalars, 'Boolean'>,
  should_notify_about_medium_severity_issues: $ElementType<Scalars, 'Boolean'>,
  should_notify_about_low_severity_issues: $ElementType<Scalars, 'Boolean'>,
  should_notify_about_all_mobile_apps: $ElementType<Scalars, 'Boolean'>,
  should_notify_about_mobile_app_ids?: ?Array<$ElementType<Scalars, 'String'>>,
};

export type ApiWebCloudSecureSlackOrMsftTeamsConfigField = {
  should_send_summary_notifications: $ElementType<Scalars, 'Boolean'>,
  should_send_real_time_notifications: $ElementType<Scalars, 'Boolean'>,
};

export type SlackOrMsftTeamsConfigField = {
  url: $ElementType<Scalars, 'String'>,
  should_send_asm_notifications: $ElementType<Scalars, 'Boolean'>,
  should_send_asm_notifications_for_asset_types?: ?Array<?AssetTypeEnum>,
  should_send_asm_notifications_for_all_asset_types: $ElementType<Scalars, 'Boolean'>,
  mobile_secure_config: MobileSecureSlackOrMsftTeamsConfigField,
  api_web_cloud_secure_config: ApiWebCloudSecureSlackOrMsftTeamsConfigField,
};

export type AlertsIntegrationWebhookConfigInput = {
  url: $ElementType<Scalars, 'String'>,
  authorization_header?: ?$ElementType<Scalars, 'String'>,
  subscribed_events?: ?Array<$ElementType<Scalars, 'String'>>,
  subscribed_to_all_events: $ElementType<Scalars, 'Boolean'>,
};

export type AlertsIntegrationJiraConfigInputStaticField = {
  field_id?: ?$ElementType<Scalars, 'String'>,
  field_type?: ?$ElementType<Scalars, 'String'>,
  string_value?: ?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValueInput,
  option_value?: ?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValueInput,
  number_value?: ?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValueInput,
  user_value?: ?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValueInput,
  string_array_value?: ?Array<?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValueInput>,
  option_array_value?: ?Array<?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValueInput>,
  component_array_value?: ?Array<?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValueInput>,
};

export type AlertsIntegrationJiraConfigInput = {
  instance_url?: ?$ElementType<Scalars, 'String'>,
  username?: ?$ElementType<Scalars, 'String'>,
  password?: ?$ElementType<Scalars, 'String'>,
  project_key?: ?$ElementType<Scalars, 'String'>,
  issue_type?: ?$ElementType<Scalars, 'String'>,
  relevance_field_id?: ?$ElementType<Scalars, 'String'>,
  relevance_proactive_value?: ?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValueInput,
  relevance_important_value?: ?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValueInput,
  relevance_urgent_value?: ?AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValueInput,
  policy_violations_auto_export_setting?: ?$ElementType<Scalars, 'String'>,
  static_fields?: ?Array<?AlertsIntegrationJiraConfigInputStaticField>,
};

export type AlertsIntegrationCreateRequest = {
  name?: ?$ElementType<Scalars, 'String'>,
  integration_type: AlertsIntegrationsTypeEnum,
  slack_or_msft_teams_config?: ?SlackOrMsftTeamsConfigField,
  webhook_config?: ?AlertsIntegrationWebhookConfigInput,
  jira_config?: ?AlertsIntegrationJiraConfigInput,
};

export type JiraIntegrationsJiraProjectsFetchesRequest = {
  instance_url?: ?$ElementType<Scalars, 'String'>,
  username?: ?$ElementType<Scalars, 'String'>,
  password?: ?$ElementType<Scalars, 'String'>,
  integration_uuid?: ?$ElementType<Scalars, 'String'>,
};

export type JiraIntegrationsJiraFieldsFetchesRequest = {
  instance_url?: ?$ElementType<Scalars, 'String'>,
  username?: ?$ElementType<Scalars, 'String'>,
  password?: ?$ElementType<Scalars, 'String'>,
  project_key?: ?$ElementType<Scalars, 'String'>,
  issue_type?: ?$ElementType<Scalars, 'String'>,
  integration_uuid?: ?$ElementType<Scalars, 'String'>,
};

export type IpRangeCreateParams = {
  ip_range: $ElementType<Scalars, 'String'>,
  has_continuous_discovery_enabled: $ElementType<Scalars, 'Boolean'>,
  ip_addresses_to_import?: ?Array<$ElementType<Scalars, 'String'>>,
};

export type IpRangeScanCreateParams = {
  ip_range: $ElementType<Scalars, 'String'>,
};

export type IpRangePatchParams = {
  has_continuous_discovery_enabled?: ?$ElementType<Scalars, 'Boolean'>,
};

export type PolicyViolationEportToJiraParams = {
  alerts_integration_id: $ElementType<Scalars, 'String'>,
};

export type ComplianceReportParams = {
  mobile_app_id?: ?$ElementType<Scalars, 'ID'>,
};

export type CloudAuthenticatorUpdateParams = {
  customer_supplied_name?: ?$ElementType<Scalars, 'String'>,
  has_policy_to_support_api_auto_protect?: ?$ElementType<Scalars, 'Boolean'>,
};

export type ToolkitScanStartRequestParams = {
  break_glass_configuration?: ?ToolkitScanStartRequestEmptyParams,
  toolkit_scan_type: ToolkitScanTypeEnum,
  web_application_xss_configuration?: ?WebApplicationXssConfigurationParams,
  detect_and_inject_configuration?: ?DetectAndInjectConfigurationParams,
  cloud_leaks_configuration?: ?ToolkitScanStartRequestEmptyParams,
  code_red_configuration?: ?ToolkitScanStartRequestEmptyParams,
  cloud_ransomware_encrypt_configuration?: ?ToolkitScansSchedulesPutCloudRansomwareEncryptConfiguration,
  cloud_ransomware_upload_malware_configuration?: ?ToolkitScansSchedulesPutCloudRansomwareUploadMalwareConfiguration,
  cloud_ransomware_scan_storage_configuration?: ?ToolkitScansSchedulesPutCloudRansomwareStorageConfig,
};

export type ToolkitScansSchedulePutParamsCloudDlpConfigScanRunConfig = {
  aws_cloud_resource_types_to_scan: Array<$ElementType<Scalars, 'String'>>,
  gcp_cloud_resource_types_to_scan: Array<$ElementType<Scalars, 'String'>>,
  azure_cloud_resource_types_to_scan: Array<$ElementType<Scalars, 'String'>>,
  other_cloud_resource_types_to_scan: Array<$ElementType<Scalars, 'String'>>,
  enabled_google_pii_types: Array<$ElementType<Scalars, 'String'>>,
};

export type ToolkitScansSchedulesPutParamsCloudDlpConfig = {
  cloud_dlp_analysis_enabled: $ElementType<Scalars, 'Boolean'>,
  scan_run_configuration: ToolkitScansSchedulePutParamsCloudDlpConfigScanRunConfig,
};

export type ToolkitScansSchedulesPutCloudRansomwareEncryptConfiguration = {
  decryption_key: $ElementType<Scalars, 'String'>,
};

export type ToolkitScansSchedulesPutCloudRansomwareUploadMalwareConfiguration = {
  malware_types_to_upload: Array<?$ElementType<Scalars, 'String'>>,
};

export type ToolkitScansSchedulesPutCloudRansomwareStorageConfig = {
  _?: ?$ElementType<Scalars, 'String'>,
};

export type ToolkitScansSchedulesPutParams = {
  toolkit_scan_type: ToolkitScanTypeEnum,
  cloud_dlp_configuration?: ?ToolkitScansSchedulesPutParamsCloudDlpConfig,
  cloud_ransomware_encrypt_configuration?: ?ToolkitScansSchedulesPutCloudRansomwareEncryptConfiguration,
  cloud_ransomware_upload_malware_configuration?: ?ToolkitScansSchedulesPutCloudRansomwareUploadMalwareConfiguration,
  cloud_ransomware_scan_storage_configuration?: ?ToolkitScansSchedulesPutCloudRansomwareStorageConfig,
  weekdays_when_to_scan: Array<$ElementType<Scalars, 'String'>>,
  time_when_to_scan: $ElementType<Scalars, 'Date'>,
};

export type PolicyViolationsPatchParams = {
  status?: ?PolicyViolationStatusEnum,
  exception_type?: ?PolicyViolationException,
  exception_explanation?: ?$ElementType<Scalars, 'String'>,
};

export type SharedLinksCreateForPolicyViolationParams = {
  validity_duration: $ElementType<Scalars, 'Float'>,
};

export type SharedLinksCreateForPolicyViolationsParams = {
  validity_duration_in_minutes: $ElementType<Scalars, 'Float'>,
  should_share_all_policy_violations: $ElementType<Scalars, 'Boolean'>,
  share_all_policy_violations_from_asset_group_id?: ?$ElementType<Scalars, 'String'>,
};

export type TargetStatusCreateParams = {
  status: TargetStatus,
};

export type DownloadMostRecentComplianceReportBody = {
  report_type: $ElementType<Scalars, 'String'>,
};

export type ApigeeAuthenticatorsCreateParams = {
  organization: $ElementType<Scalars, 'String'>,
};

export type AwsAuthenticatorsCreateParams = {
  role_arn: $ElementType<Scalars, 'String'>,
  external_id: $ElementType<Scalars, 'String'>,
};

export type AxwayAuthenticatorsCreateParams = {
  client_id: $ElementType<Scalars, 'String'>,
  organization_id: $ElementType<Scalars, 'String'>,
  private_key: $ElementType<Scalars, 'String'>,
};

export type AzureAuthenticatorsCreateParams = {
  tenant_id: $ElementType<Scalars, 'String'>,
  client_id: $ElementType<Scalars, 'String'>,
  client_secret: $ElementType<Scalars, 'String'>,
};

export type GcpAuthenticatorsCreateParams = {
  organization_id: $ElementType<Scalars, 'String'>,
  service_account: $ElementType<Scalars, 'JSON'>,
};

export type KongAuthenticatorsCreateParams = {
  kong_admin_subdomain: $ElementType<Scalars, 'String'>,
  kong_admin_token: $ElementType<Scalars, 'String'>,
};

export type CloudAuthenticatorOrganizationCreateParams = {
  organization_id: $ElementType<Scalars, 'String'>,
};

export type CloudAuthenticatorCreateParamsKubernetes = {
  kubeconfig: $ElementType<Scalars, 'String'>,
};

export type CloudAuthenticatorCreateParams = {
  authenticator_type: $ElementType<Scalars, 'String'>,
  swagger_hub_credential?: ?CloudAuthenticatorOrganizationCreateParams,
  customer_supplied_name?: ?$ElementType<Scalars, 'String'>,
  kubernetes_credential?: ?CloudAuthenticatorCreateParamsKubernetes,
};

export type MulesoftAuthenticatorsCreateParams = {
  organization_id: $ElementType<Scalars, 'String'>,
  account_username: $ElementType<Scalars, 'String'>,
  account_password: $ElementType<Scalars, 'String'>,
};

export type NetworkServicesCreateV2Params = {
  base_url: $ElementType<Scalars, 'String'>,
  username_1?: ?$ElementType<Scalars, 'String'>,
  password_1?: ?$ElementType<Scalars, 'String'>,
  username_2?: ?$ElementType<Scalars, 'String'>,
  password_2?: ?$ElementType<Scalars, 'String'>,
  authentication_details_list?: ?Array<AuthenticationDetailsField>,
};

export type NetworkServicesCreateParams = {
  domain_name: $ElementType<Scalars, 'String'>,
  port: $ElementType<Scalars, 'Float'>,
  application_layer_protocol: ApplicationLayerProtocol,
  is_tls_encrypted: $ElementType<Scalars, 'Boolean'>,
  requires_static_ip_addresses?: ?$ElementType<Scalars, 'Boolean'>,
};

export type PoliciesCreateParams = {
  name: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
};

export type PolicyViolationsCommentsCreateParams = {
  content: $ElementType<Scalars, 'String'>,
};

export type HorizonSupportRequestsCreateParams = {
  subject: $ElementType<Scalars, 'String'>,
  body?: ?$ElementType<Scalars, 'String'>,
};

export type ComplianceReportScheduleConfigParams = {
  recipient_emails: Array<$ElementType<Scalars, 'String'>>,
  compliance_report_type?: ?ToolkitScanTypeEnum,
  should_report_all_mobile_apps: $ElementType<Scalars, 'Boolean'>,
  customer_mobile_app_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type ComplianceReportScheduleParams = {
  should_only_scan_monthly?: ?$ElementType<Scalars, 'Boolean'>,
  weekdays_when_to_scan?: ?Array<Weekday>,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  toolkit_scan_type?: ?ToolkitScanTypeEnum,
  app_store_privacy_global_report_configuration?: ?ComplianceReportScheduleConfigParams,
  app_store_privacy_specific_apps_report_configuration?: ?ComplianceReportScheduleConfigParams,
};

export type BulkActionMoveToAssetGroupParams = {
  move_to_asset_group_id?: ?$ElementType<Scalars, 'ID'>,
  perform_on_assets_with_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
  perform_on_assets_matching?: ?BulkActionMoveToAssetGroupByFilterParams,
};

export type BulkActionMoveToAssetGroupByFilterParams = {
  filter_by_asset_types?: ?Array<?AssetTypeEnum>,
  filter_by_asset_group_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
  filter_by_cloud_providers?: ?Array<HostedOn>,
  filter_by_discovered_vias?: ?Array<DiscoveredVia>,
  filter_by_is_shadow?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
};

export type AssetGroupCreateParams = {
  attached_policy_id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
};

export type AssetGroupUpdateParams = {
  attached_policy_id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
};

export type AssetGroupGrantUserAccessParams = {
  asset_group_id: $ElementType<Scalars, 'ID'>,
};

export type UserCreateParams = {
  allowed_app_ids?: ?Array<?$ElementType<Scalars, 'ID'>>,
  auth_strategy: AuthStrategy,
  can_access_app_search?: ?$ElementType<Scalars, 'Boolean'>,
  can_access_app_secure?: ?$ElementType<Scalars, 'Boolean'>,
  can_access_mobile_secure?: ?$ElementType<Scalars, 'Boolean'>,
  can_access_api_secure?: ?$ElementType<Scalars, 'Boolean'>,
  can_access_web_secure?: ?$ElementType<Scalars, 'Boolean'>,
  can_access_cloud_secure?: ?$ElementType<Scalars, 'Boolean'>,
  can_access_supply_chain_secure?: ?$ElementType<Scalars, 'Boolean'>,
  has_access_to_all_asset_groups?: ?$ElementType<Scalars, 'Boolean'>,
  has_access_to_asset_group_ids?: ?Array<?$ElementType<Scalars, 'String'>>,
  can_access_api_inspect?: ?$ElementType<Scalars, 'Boolean'>,
  can_access_brand_protect?: ?$ElementType<Scalars, 'Boolean'>,
  can_invite_users: $ElementType<Scalars, 'Boolean'>,
  can_access_vendor_supply_chain_security?: ?$ElementType<Scalars, 'Boolean'>,
  can_download_reports?: ?$ElementType<Scalars, 'Boolean'>,
  first_name: $ElementType<Scalars, 'String'>,
  last_name: $ElementType<Scalars, 'String'>,
  login_email: $ElementType<Scalars, 'String'>,
  notification_email?: ?$ElementType<Scalars, 'String'>,
  role: Role,
};

export type AllowedAppIds = {
  data: Array<$ElementType<Scalars, 'ID'>>,
};

export type AssetGroupIdsPatch = {
  data: Array<$ElementType<Scalars, 'ID'>>,
};

export type UserPatchParams = {
  allowed_app_ids: AllowedAppIds,
  auth_strategy: AuthStrategy,
  can_access_app_search?: ?$ElementType<Scalars, 'Boolean'>,
  can_access_app_secure?: ?$ElementType<Scalars, 'Boolean'>,
  can_access_api_inspect?: ?$ElementType<Scalars, 'Boolean'>,
  can_access_brand_protect?: ?$ElementType<Scalars, 'Boolean'>,
  can_access_mobile_secure?: ?$ElementType<Scalars, 'Boolean'>,
  can_access_api_secure?: ?$ElementType<Scalars, 'Boolean'>,
  can_access_web_secure?: ?$ElementType<Scalars, 'Boolean'>,
  can_access_cloud_secure?: ?$ElementType<Scalars, 'Boolean'>,
  can_access_supply_chain_secure?: ?$ElementType<Scalars, 'Boolean'>,
  has_access_to_all_asset_groups?: ?$ElementType<Scalars, 'Boolean'>,
  has_access_to_asset_group_ids?: ?AssetGroupIdsPatch,
  can_invite_users: $ElementType<Scalars, 'Boolean'>,
  can_download_reports?: ?$ElementType<Scalars, 'Boolean'>,
  first_name: $ElementType<Scalars, 'String'>,
  last_name: $ElementType<Scalars, 'String'>,
  login_email: $ElementType<Scalars, 'String'>,
  notification_email?: ?$ElementType<Scalars, 'String'>,
  role: Role,
  can_access_vendor_supply_chain_security?: ?$ElementType<Scalars, 'Boolean'>,
};

export type UserAssetGroupsPatchParams = {
  has_access_to_asset_group_ids?: ?Array<?$ElementType<Scalars, 'String'>>,
  has_access_to_all_asset_groups: $ElementType<Scalars, 'Boolean'>,
};

export type ConfigurationsShadowPutParamsConfiguration = {
  approved_asset_types: Array<$ElementType<Scalars, 'String'>>,
  approved_cloud_providers: Array<$ElementType<Scalars, 'String'>>,
  approved_app_stores: Array<$ElementType<Scalars, 'String'>>,
  public_asm_enabled: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_apis_not_going_through_gateway: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_assets_except_on_approved_asset_types: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_cloud_resources_except_on_approved_cloud_providers: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_mobile_apps_on_3rd_party_stores: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_mobile_apps_on_unofficial_app_stores: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_mobile_apps_except_on_approved_app_stores: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_mobile_apps_on_official_stores: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_network_services_with_dev_in_url: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_network_services_with_preprod_in_url: $ElementType<Scalars, 'Boolean'>,
  should_mark_new_network_services_with_uat_in_url: $ElementType<Scalars, 'Boolean'>,
};

export type ConfigurationsShadowPutParams = {
  configuration: ConfigurationsShadowPutParamsConfiguration,
};

export type EntitlementsCreateParams = {
  entitlements_as_json: $ElementType<Scalars, 'String'>,
};

export type InboundRequestsAccessPolicyParams = {
  should_restrict_access_based_on_api_keys?: ?$ElementType<Scalars, 'Boolean'>,
  should_restrict_access_based_on_countries?: ?$ElementType<Scalars, 'Boolean'>,
  should_restrict_access_based_on_domains?: ?$ElementType<Scalars, 'Boolean'>,
  should_restrict_access_based_on_email_addresses?: ?$ElementType<Scalars, 'Boolean'>,
  should_restrict_access_based_on_ip_ranges?: ?$ElementType<Scalars, 'Boolean'>,
};

export type ApiProtectConfigurationsUpdateParams = {
  inbound_requests_access_policy?: ?InboundRequestsAccessPolicyParams,
  inbound_requests_https_enforcement?: ?$ElementType<Scalars, 'String'>,
  should_block_dos_attacks?: ?$ElementType<Scalars, 'Boolean'>,
  should_block_malicious_ip_addresses?: ?$ElementType<Scalars, 'Boolean'>,
  should_block_sql_injection_attacks?: ?$ElementType<Scalars, 'Boolean'>,
  should_block_ssrf_attacks?: ?$ElementType<Scalars, 'Boolean'>,
};

export type ApiProtectConfigurationsAllowedEmailAdressesCreateParams = {
  user_or_org_email_address?: ?$ElementType<Scalars, 'String'>,
};

export type ApiProtectConfigurationsAllowedApiKeysCreateParams = {
  api_key_value?: ?$ElementType<Scalars, 'String'>,
};

export type ApiProtectConfigurationsAllowedIpRangesCreateParams = {
  ip_range?: ?$ElementType<Scalars, 'String'>,
};

export type ApiProtectConfigurationsAllowedDomainsCreateParams = {
  domain_name?: ?$ElementType<Scalars, 'String'>,
};

export type ApiProtectConfigurationsAllowedCountriesCreateParams = {
  country_code?: ?$ElementType<Scalars, 'String'>,
};

export type TrustKitPinnedDomainParams = {
  domain: $ElementType<Scalars, 'String'>,
  enforce_pinning: $ElementType<Scalars, 'Boolean'>,
  include_subdomains: $ElementType<Scalars, 'Boolean'>,
  expiration_date?: ?$ElementType<Scalars, 'String'>,
  public_key_hashes?: ?Array<$ElementType<Scalars, 'String'>>,
};

export type TrustKitPinnedDomainUpdateParams = {
  domains: Array<TrustKitPinnedDomainParams>,
};

export type TrustKitPinnedDomainSdkConfigurationParams = {
  app_platform: MobileAppPlatform,
  trustkit_pinned_domains: Array<TrustKitPinnedDomainParams>,
};

export type TrustKitSingleStepSdkConfigurationParams = {
  app_platform: $ElementType<Scalars, 'String'>,
};

export type AssetTagInput = {
  key: $ElementType<Scalars, 'String'>,
  value?: ?$ElementType<Scalars, 'String'>,
  imported_from?: ?AssetTagImportSourceEnum,
  imported_external_id?: ?$ElementType<Scalars, 'String'>,
};

export type AssetTagPutParams = {
  asset_tags: Array<?AssetTagInput>,
};

export type ApiProtectOnboardingUrlParams = {
  _?: ?$ElementType<Scalars, 'String'>,
};

export type ApiProtectOnboardingUrlBulkParams = {
  perform_on_restful_apis_with_ids?: ?Array<$ElementType<Scalars, 'String'>>,
};

export type MobileApplicationsLinkAppBody = {
  is_enterprise_internal: $ElementType<Scalars, 'Boolean'>,
  linked_app_store_mobile_app_id: $ElementType<Scalars, 'Float'>,
};

export type OnboardingCoreDriverPatch = {
  has_core_driver_compliance?: ?$ElementType<Scalars, 'Boolean'>,
  has_core_driver_security?: ?$ElementType<Scalars, 'Boolean'>,
  has_core_driver_incident_response?: ?$ElementType<Scalars, 'Boolean'>,
};

export type OnboardApiCredentialsParams = {
  file_sha1_hash: $ElementType<Scalars, 'String'>,
  original_file_name: $ElementType<Scalars, 'String'>,
  user_comment?: ?$ElementType<Scalars, 'String'>,
  authentication_details?: ?Array<AuthenticationDetailsField>,
};

export type MobileAppScanCredentialsParams = {
  mobile_app_id: $ElementType<Scalars, 'String'>,
  comments?: ?$ElementType<Scalars, 'String'>,
  password?: ?$ElementType<Scalars, 'String'>,
  username?: ?$ElementType<Scalars, 'String'>,
};

export type CustomersTestCredentialsPostParams = {
  username: $ElementType<Scalars, 'String'>,
  password?: ?$ElementType<Scalars, 'String'>,
  comments?: ?$ElementType<Scalars, 'String'>,
};

export type CustomersTestCredentialsPatchParams = {
  id: $ElementType<Scalars, 'String'>,
  username: $ElementType<Scalars, 'String'>,
  password?: ?$ElementType<Scalars, 'String'>,
  comments?: ?$ElementType<Scalars, 'String'>,
};

export type SeverityFieldConfigMessageData = {
  field_id?: ?$ElementType<Scalars, 'String'>,
  high_severity_value_id?: ?$ElementType<Scalars, 'String'>,
  low_severity_value_id?: ?$ElementType<Scalars, 'String'>,
  medium_severity_value_id?: ?$ElementType<Scalars, 'String'>,
};

export type SeverityFieldConfigMessage = {
  data?: ?SeverityFieldConfigMessageData,
};

export type StaticFieldsMessageData = {
  field_id?: ?$ElementType<Scalars, 'String'>,
  field_value?: ?$ElementType<Scalars, 'String'>,
};

export type StaticFieldsMessage = {
  data?: ?Array<?StaticFieldsMessageData>,
};

export type DynamicFieldsMessageData = {
  field_id?: ?$ElementType<Scalars, 'String'>,
  value?: ?$ElementType<Scalars, 'String'>,
};

export type DynamicFieldsMessage = {
  data?: ?Array<?DynamicFieldsMessageData>,
};

export type JiraIntegrationConfigsPatchPatchParams = {
  base_url?: ?$ElementType<Scalars, 'String'>,
  username?: ?$ElementType<Scalars, 'String'>,
  password?: ?$ElementType<Scalars, 'String'>,
  project_key_or_id?: ?$ElementType<Scalars, 'String'>,
  type_of_issue_name?: ?$ElementType<Scalars, 'String'>,
  export_pre_prod?: ?$ElementType<Scalars, 'Boolean'>,
  export_prod?: ?$ElementType<Scalars, 'Boolean'>,
  export_filter?: ?JiraExportFilterEnum,
  is_global?: ?$ElementType<Scalars, 'Boolean'>,
  severity_field_config?: ?SeverityFieldConfigMessage,
  static_fields?: ?StaticFieldsMessage,
  dynamic_fields?: ?DynamicFieldsMessage,
  base_id?: ?$ElementType<Scalars, 'String'>,
  raw_mobile_app_id?: ?$ElementType<Scalars, 'String'>,
  mobile_app_id?: ?$ElementType<Scalars, 'String'>,
};

export type JiraIntegrationConfigsPostParams = {
  base_url?: ?$ElementType<Scalars, 'String'>,
  username?: ?$ElementType<Scalars, 'String'>,
  password?: ?$ElementType<Scalars, 'String'>,
  project_key_or_id?: ?$ElementType<Scalars, 'String'>,
  type_of_issue_name?: ?$ElementType<Scalars, 'String'>,
  export_pre_prod?: ?$ElementType<Scalars, 'Boolean'>,
  export_prod?: ?$ElementType<Scalars, 'Boolean'>,
  export_filter?: ?JiraExportFilterEnum,
  is_global?: ?$ElementType<Scalars, 'Boolean'>,
  severity_field_config?: ?SeverityFieldConfigMessage,
  static_fields?: ?StaticFieldsMessage,
  dynamic_fields?: ?DynamicFieldsMessage,
  base_id?: ?$ElementType<Scalars, 'String'>,
  raw_mobile_app_id?: ?$ElementType<Scalars, 'String'>,
  mobile_app_id?: ?$ElementType<Scalars, 'String'>,
};

export type StanfordDishUniUpdateLiveTrainingBody = {
  user_attendance_status: StanfordDishUniUserAttendanceEnumUpdate,
};

export type StanfordDishUniUpdateMaterialViewedBody = {
  view_time_increment: $ElementType<Scalars, 'Float'>,
};

export type MobilePhishingIgnoredKeywordsParamsBody = {
  keyword: $ElementType<Scalars, 'String'>,
};

export type ApikeyAuthenticationDetails = {
  already_submitted_authentication_details_ids?: ?Array<$ElementType<Scalars, 'String'>>,
  idx?: ?$ElementType<Scalars, 'Float'>,
  location: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
};

export type DescribedInUploadedFileAuthenticationDetails = {
  uploaded_authentication_file_hash: $ElementType<Scalars, 'String'>,
};

export type Oauth2AuthenticationDetails = {
  already_submitted_authentication_details_ids?: ?Array<$ElementType<Scalars, 'String'>>,
  authentication_url: $ElementType<Scalars, 'String'>,
  username: $ElementType<Scalars, 'String'>,
  password: $ElementType<Scalars, 'String'>,
};

export type BasicAuthAuthenticationDetails = {
  already_submitted_authentication_details_ids?: ?Array<$ElementType<Scalars, 'String'>>,
  username: $ElementType<Scalars, 'String'>,
  password: $ElementType<Scalars, 'String'>,
};

export type AuthenticationDetailsField = {
  access_level: $ElementType<Scalars, 'String'>,
  authentication_type: $ElementType<Scalars, 'String'>,
  api_keys_authentication_details?: ?Array<ApikeyAuthenticationDetails>,
  described_in_uploaded_file_authentication_details?: ?DescribedInUploadedFileAuthenticationDetails,
  oauth2_authentication_details?: ?Oauth2AuthenticationDetails,
  basic_auth_authentication_details?: ?BasicAuthAuthenticationDetails,
};

export type CustomCheckAssetWithAuthenticationDetailsField = {
  id: $ElementType<Scalars, 'String'>,
  authentication_details?: ?Array<AuthenticationDetailsField>,
};

export type CustomChecksRequestParams = {
  custom_check_type: $ElementType<Scalars, 'String'>,
  asset_id?: ?$ElementType<Scalars, 'String'>,
  authentication_details?: ?Array<AuthenticationDetailsField>,
  user_selected_check_name?: ?$ElementType<Scalars, 'String'>,
  user_selected_check_description?: ?$ElementType<Scalars, 'String'>,
  custom_check_additional_comment?: ?$ElementType<Scalars, 'String'>,
  assets_with_authentication_details?: ?Array<?CustomCheckAssetWithAuthenticationDetailsField>,
};

export type VendorManagementCurrentVendorPatchContactEmailParams = {
  data?: ?Array<?$ElementType<Scalars, 'String'>>,
};

export type VendorManagementCurrentVendorPatchParams = {
  deletion_requested?: ?$ElementType<Scalars, 'Boolean'>,
  scans_enabled?: ?$ElementType<Scalars, 'Boolean'>,
  website_url?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  contact_emails?: ?VendorManagementCurrentVendorPatchContactEmailParams,
};

export type ScopedAccessTokenParams = {
  validity_duration: $ElementType<Scalars, 'Float'>,
};

export type ScopedAccessTokenOnboardingParams = {
  validity_duration_in_days: $ElementType<Scalars, 'Float'>,
};

export type Mutation = {
  __typename: 'Mutation',
  unlock_data_report: EmptyResponse,
  full_subscription_inquiry: EmptyResponse,
  triage_discovered_app: EmptyResponse,
  mobile_phishing_post_comment: MobilePhishingDiscoveredPhishingAppsResponseResult,
  post_network_flows: PostNetworkFlowsResponse,
  post_api_protect_sdk: PostApiProtectSdkResponse,
  patch_network_flows: PatchNetworkFlowsResponse,
  sdu_update_live_training: StanfordDishUniLiveTraining,
  sdu_update_material_viewed: StanfordDishUniMaterial,
  mobile_phishing_ignored_keywords_post: MobilePhishingIgnoredKeywordResponse,
  mobile_phishing_ignored_keywords_patch: MobilePhishingIgnoredKeywordResponse,
  mobile_phishing_ignored_keywords_delete?: ?EmptyResponse,
  triage: CredentialTriageResponse,
  shadow_assets_triage_actions: ShadowAssetsTriageActionsResponse,
  auto_remediation_attempt_batches: AutoRemediationAttemptBatch,
  cloud_authenticator_update: CloudAuthenticator,
  special_scan_request: SpecialScanRequestResponse,
  policy_rules_update: PolicyRulesUpdateResponse,
  policy_rules_create: PolicyRule,
  get_large_file_upload_url: GetLargeFileUploadUrlResponse,
  policy_rules_remove?: ?EmptyResponse,
  detect_and_inject_scans_schedules_set: DetectAndInjectScansScheduleSetResponse,
  web_application_xss_scans_schedules_set: WebApplicationXssScansSchedulesResponse,
  toolkit_scan_create: ToolkitScanStartRequestResponse,
  toolkit_scans_schedules_put: ToolkitScanSchedulesPutResponse,
  toolkit_scans_schedules_delete: EmptyResponse,
  ip_ranges_create: IpRangeCreateResponse,
  ip_range_scans_create: IpRangeScanCreateResponse,
  ip_ranges_patch: IpRangePatchResponse,
  policy_violation_export_jira: PolicyViolationExportToJira,
  policy_violations_patch: PolicyViolationsPatchResponse,
  openapi_definitions_create: OpenApiDefinitionsCreateResponse,
  shared_links_for_policy_violation_create: SharedLinksCreateForPolicyViolationResponse,
  shared_links_for_policy_violations_create: SharedLinksCreateForPolicyViolationsResponse,
  apigee_authenticators_create: ApigeeAuthenticatorsCreateResponse,
  download_most_recent_compliance_report: DownloadMostRecentComplianceReportResponse,
  aws_authenticators_create: AwsAuthenticatorsCreateResponse,
  axway_authenticators_create: AxwayAuthenticatorsCreateResponse,
  azure_authenticators_create: AzureAuthenticatorsCreateResponse,
  gcp_authenticators_create: GcpAuthenticatorsCreateResponse,
  kong_authenticators_create: KongAuthenticatorsCreateResponse,
  create_cloud_authenticator: CreateCloudAuthenticatorResponse,
  mulesoft_authenticators_create: MulesoftAuthenticatorsCreateResponse,
  network_services_create: NetworkService,
  network_services_create_v2: NetworkServiceV2Result,
  policies_create: PoliciesCreateResponse,
  policies_remove: EmptyResponse,
  policy_violations_comments_create: Comment,
  horizon_support_requests_create?: ?EmptyResponse,
  app_store_privacy_global_report_update: ComplianceReportScheduleResponse,
  app_store_privacy_specific_apps_report_update: ComplianceReportScheduleResponse,
  bulk_action_move_to_asset_group_create: BulkActionResponse,
  asset_group_create: SingleAssetGroupResponse,
  asset_group_delete?: ?EmptyResponse,
  asset_group_user_grant_access?: ?EmptyResponse,
  asset_group_user_deny_access?: ?EmptyResponse,
  asset_group_patch: SingleAssetGroupResponse,
  user_asset_groups_update?: ?EmptyResponse,
  configurations_shadow_put?: ?EmptyResponse,
  asset_tags_put: AssetTagsResponse,
  custom_checks_requests: CustomChecksRequestsResponse,
  network_telemetry_configurations_create: NetworkTelemetryConfigurationsResponse,
  onboard_api_credentials_post: OnboardApiCredentialsPostResponse,
  update_google_play_data_safety_manual_answers: GooglePlayDataSafetyManualAnswersMutationResponse,
  openscan_favorite_apps_add: OpenScanApp,
  openscan_favorite_apps_remove: OpenScanApp,
  mobile_app_analysis_request_create: AnalysisRequest,
  mobile_app_analysis_schedule_patch: MobileAppAnalysisScheduleResponse,
  mobile_applications_link_app: MobileApp,
  secret_strings_create: SecretString,
  secret_strings_update: EmptyResponse,
  secret_strings_remove?: ?EmptyResponse,
  sevenhell_support_requests_create: EmptyResponse,
  security_findings_targets_statuses_create: TargetStatusWithFinding,
  security_finding_targets_jira_ticket_create: SecurityFinding,
  vendor_management_vendor_create: Vendor,
  vendor_management_request_onboarding_from_contacts?: ?EmptyResponse,
  vendor_management_request_approval_from_contacts?: ?EmptyResponse,
  vendor_management_vendor_login: Session,
  vendor_management_vendor_update: Vendor,
  onboarding_completion_put: OnboardingCompletionResponse,
  onboarding_completion_delete: OnboardingCompletionResponse,
  users_create: User,
  users_update: User,
  onboarding_data_core_driver_patch: OnboardingDataResponse,
  onboarding_data_completed_step_put: OnboardingDataResponse,
  customers_test_credentials_post: CustomersTestCredential,
  delete_user?: ?EmptyResponse,
  mobile_app_scan_credentials_post: EmptyResponse,
  customers_test_credentials_patch: CustomersTestCredential,
  jira_integration_configs_patch: JiraIntegrationConfig,
  jira_integration_configs_post: JiraIntegrationConfig,
  vendor_management_current_vendor_patch: Vendor,
  vendor_management_current_vendor_access_approval_patch: Vendor,
  vendor_management_current_vendor_access_approval_delete: Vendor,
  scoped_access_token_for_finding_id: ScopedAccessTokenResponse,
  scoped_access_token_for_mobile_app_id: ScopedAccessTokenResponse,
  scoped_access_token_for_onboarding: ScopedAccessTokenResponse,
  scoped_access_token_for_openscan_app_id: ScopedAccessTokenResponse,
  scoped_access_token_delete?: ?EmptyResponse,
  app_uploads_create: AppUploadsResponse,
  security_finding_note_create: Note,
  security_finding_patch: SecurityFinding,
  security_finding_update_priority: SecurityFinding,
  data_trust_report: ComplianceReportResponse,
  global_app_store_answers_reports: ComplianceReportResponse,
  app_store_answers_reports: ComplianceReportResponse,
  api_protect_configurations_put: ApiProtectConfigurationResponse,
  api_protect_protected_allowed_email_addresses_create: ApiProtectAllowedEmailAddressesCreateResponse,
  api_protect_protected_allowed_api_keys_create: ApiProtectAllowedApiKeysCreateResponse,
  api_protect_protected_allowed_ip_ranges_create: ApiProtectAllowedIpRangesCreateResponse,
  api_protect_protected_allowed_domains_create: ApiProtectAllowedDomainsCreateResponse,
  api_protect_protected_allowed_countries_create: ApiProtectAllowedCountriesCreateResponse,
  mobile_protect_configuration_wizard_sdk_variants_customize?: ?MobileProtectConfigurationWizardSdkVariantsCustomizeResponse,
  alerts_integrations_test?: ?EmptyResponse,
  alerts_integrations_create: AlertsIntegrations,
  jira_projects_fetches: JiraIntegrationProjectsFetchesResponse,
  jira_issue_fields_fetches: JiraIntegrationFieldsFetchesResponse,
  alerts_integrations_update: AlertsIntegrations,
  alerts_integrations_delete?: ?EmptyResponse,
  ios_data_protection_create: EntitlementsPlistResponse,
  trustkit_domains_configuration_create: TrustKitPinnedDomainResponse,
  sdk_configuration_create: TrustKitSdkConfigurationResponse,
  one_step_sdk_configuration_create: TrustKitSingleStepSdkConfigurationResponse,
  api_protect_onboarding_url_create: ApiProtectOnboardingUrlResponse,
  api_protect_onboarding_url_bulk_create: ApiProtectOnboardingUrlResponse,
  mobile_protect_keys_or_token_post: MobileProtectApiKeysResponse,
};


export type MutationUnlock_Data_ReportArgs = {
  body: UnlockDataReportBody,
  id: $ElementType<Scalars, 'ID'>,
};


export type MutationFull_Subscription_InquiryArgs = {
  body: FullSubscriptionInquiryBody,
  id: $ElementType<Scalars, 'ID'>,
};


export type MutationTriage_Discovered_AppArgs = {
  id: $ElementType<Scalars, 'ID'>,
  triaging_decision: $ElementType<Scalars, 'String'>,
};


export type MutationMobile_Phishing_Post_CommentArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: MobilePhishingPostComment,
};


export type MutationPost_Network_FlowsArgs = {
  body: PostNetworkFlowsBody,
};


export type MutationPost_Api_Protect_SdkArgs = {
  body: PostApiProtectSdkBody,
};


export type MutationPatch_Network_FlowsArgs = {
  networkId: $ElementType<Scalars, 'String'>,
  body: PatchNetworkFlowsBody,
};


export type MutationSdu_Update_Live_TrainingArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: StanfordDishUniUpdateLiveTrainingBody,
};


export type MutationSdu_Update_Material_ViewedArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: StanfordDishUniUpdateMaterialViewedBody,
};


export type MutationMobile_Phishing_Ignored_Keywords_PostArgs = {
  body?: ?MobilePhishingIgnoredKeywordsParamsBody,
};


export type MutationMobile_Phishing_Ignored_Keywords_PatchArgs = {
  uuid: $ElementType<Scalars, 'ID'>,
  body?: ?MobilePhishingIgnoredKeywordsParamsBody,
};


export type MutationMobile_Phishing_Ignored_Keywords_DeleteArgs = {
  uuid: $ElementType<Scalars, 'ID'>,
};


export type MutationTriageArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
  credential_id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?CredentialTriageParams,
};


export type MutationShadow_Assets_Triage_ActionsArgs = {
  body?: ?ShadowAssetsTriageActionsParams,
};


export type MutationAuto_Remediation_Attempt_BatchesArgs = {
  body?: ?AutoRemediationAttemptBatches,
};


export type MutationCloud_Authenticator_UpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: CloudAuthenticatorUpdateParams,
};


export type MutationSpecial_Scan_RequestArgs = {
  body?: ?SpecialScanRequestParams,
};


export type MutationPolicy_Rules_UpdateArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
  policy_rule_id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?PolicyRulesUpdateParams,
};


export type MutationPolicy_Rules_CreateArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?PolicyRulesCreateParams,
};


export type MutationGet_Large_File_Upload_UrlArgs = {
  body?: ?GetLargeFileUploadUrlParams,
};


export type MutationPolicy_Rules_RemoveArgs = {
  policyId: $ElementType<Scalars, 'ID'>,
  ruleId: $ElementType<Scalars, 'ID'>,
};


export type MutationDetect_And_Inject_Scans_Schedules_SetArgs = {
  body?: ?DetectAndInjectScansScheduleSetRequestParams,
};


export type MutationWeb_Application_Xss_Scans_Schedules_SetArgs = {
  body?: ?WebApplicationXssScansScheduleSetRequestParams,
};


export type MutationToolkit_Scan_CreateArgs = {
  body: ToolkitScanStartRequestParams,
};


export type MutationToolkit_Scans_Schedules_PutArgs = {
  toolkit_scan_type: ToolkitScanTypeEnum,
  body: ToolkitScansSchedulesPutParams,
};


export type MutationToolkit_Scans_Schedules_DeleteArgs = {
  toolkit_scan_type: ToolkitScanTypeEnum,
};


export type MutationIp_Ranges_CreateArgs = {
  body: IpRangeCreateParams,
};


export type MutationIp_Range_Scans_CreateArgs = {
  body: IpRangeScanCreateParams,
};


export type MutationIp_Ranges_PatchArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: IpRangePatchParams,
};


export type MutationPolicy_Violation_Export_JiraArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: PolicyViolationEportToJiraParams,
};


export type MutationPolicy_Violations_PatchArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: PolicyViolationsPatchParams,
};


export type MutationOpenapi_Definitions_CreateArgs = {
  body: $ElementType<Scalars, 'String'>,
};


export type MutationShared_Links_For_Policy_Violation_CreateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body?: ?SharedLinksCreateForPolicyViolationParams,
};


export type MutationShared_Links_For_Policy_Violations_CreateArgs = {
  body?: ?SharedLinksCreateForPolicyViolationsParams,
};


export type MutationApigee_Authenticators_CreateArgs = {
  body: ApigeeAuthenticatorsCreateParams,
};


export type MutationDownload_Most_Recent_Compliance_ReportArgs = {
  body: DownloadMostRecentComplianceReportBody,
};


export type MutationAws_Authenticators_CreateArgs = {
  body: AwsAuthenticatorsCreateParams,
};


export type MutationAxway_Authenticators_CreateArgs = {
  body: AxwayAuthenticatorsCreateParams,
};


export type MutationAzure_Authenticators_CreateArgs = {
  body: AzureAuthenticatorsCreateParams,
};


export type MutationGcp_Authenticators_CreateArgs = {
  body: GcpAuthenticatorsCreateParams,
};


export type MutationKong_Authenticators_CreateArgs = {
  body: KongAuthenticatorsCreateParams,
};


export type MutationCreate_Cloud_AuthenticatorArgs = {
  body: CloudAuthenticatorCreateParams,
};


export type MutationMulesoft_Authenticators_CreateArgs = {
  body: MulesoftAuthenticatorsCreateParams,
};


export type MutationNetwork_Services_CreateArgs = {
  body: NetworkServicesCreateParams,
};


export type MutationNetwork_Services_Create_V2Args = {
  body: NetworkServicesCreateV2Params,
};


export type MutationPolicies_CreateArgs = {
  body: PoliciesCreateParams,
};


export type MutationPolicies_RemoveArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type MutationPolicy_Violations_Comments_CreateArgs = {
  policy_violation_id: $ElementType<Scalars, 'ID'>,
  body?: ?PolicyViolationsCommentsCreateParams,
};


export type MutationHorizon_Support_Requests_CreateArgs = {
  body: HorizonSupportRequestsCreateParams,
};


export type MutationApp_Store_Privacy_Global_Report_UpdateArgs = {
  body: ComplianceReportScheduleParams,
};


export type MutationApp_Store_Privacy_Specific_Apps_Report_UpdateArgs = {
  body: ComplianceReportScheduleParams,
};


export type MutationBulk_Action_Move_To_Asset_Group_CreateArgs = {
  body: BulkActionMoveToAssetGroupParams,
};


export type MutationAsset_Group_CreateArgs = {
  body: AssetGroupCreateParams,
};


export type MutationAsset_Group_DeleteArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type MutationAsset_Group_User_Grant_AccessArgs = {
  userId: $ElementType<Scalars, 'ID'>,
  body: AssetGroupGrantUserAccessParams,
};


export type MutationAsset_Group_User_Deny_AccessArgs = {
  userId: $ElementType<Scalars, 'ID'>,
  assetGroupId: $ElementType<Scalars, 'ID'>,
};


export type MutationAsset_Group_PatchArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: AssetGroupUpdateParams,
};


export type MutationUser_Asset_Groups_UpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: UserAssetGroupsPatchParams,
};


export type MutationConfigurations_Shadow_PutArgs = {
  body: ConfigurationsShadowPutParams,
};


export type MutationAsset_Tags_PutArgs = {
  uuid: $ElementType<Scalars, 'ID'>,
  body: AssetTagPutParams,
};


export type MutationCustom_Checks_RequestsArgs = {
  product_type: $ElementType<Scalars, 'String'>,
  body?: ?CustomChecksRequestParams,
};


export type MutationOnboard_Api_Credentials_PostArgs = {
  body: OnboardApiCredentialsParams,
};


export type MutationUpdate_Google_Play_Data_Safety_Manual_AnswersArgs = {
  id: $ElementType<Scalars, 'String'>,
  body: UpdateGooglePlayDataSafetyManualAnswersBody,
};


export type MutationOpenscan_Favorite_Apps_AddArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type MutationOpenscan_Favorite_Apps_RemoveArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type MutationMobile_App_Analysis_Request_CreateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: MobileAppAnalysisRequestParams,
};


export type MutationMobile_App_Analysis_Schedule_PatchArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: MobileAppAnalysisScheduleParams,
};


export type MutationMobile_Applications_Link_AppArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: MobileApplicationsLinkAppBody,
};


export type MutationSecret_Strings_CreateArgs = {
  body: SecretStringParams,
};


export type MutationSecret_Strings_UpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: SecretStringUpdateParams,
};


export type MutationSecret_Strings_RemoveArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type MutationSevenhell_Support_Requests_CreateArgs = {
  body: SevenhellSupportRequestsCreateParams,
};


export type MutationSecurity_Findings_Targets_Statuses_CreateArgs = {
  security_finding_id: $ElementType<Scalars, 'ID'>,
  target_id: $ElementType<Scalars, 'ID'>,
  body?: ?TargetStatusCreateParams,
};


export type MutationSecurity_Finding_Targets_Jira_Ticket_CreateArgs = {
  security_finding_id: $ElementType<Scalars, 'ID'>,
  target_id: $ElementType<Scalars, 'ID'>,
};


export type MutationVendor_Management_Vendor_CreateArgs = {
  body: VendorManagementCreateParams,
};


export type MutationVendor_Management_Request_Onboarding_From_ContactsArgs = {
  vendorId: $ElementType<Scalars, 'ID'>,
  body: VendorManagementRequestOnboardingContactsParams,
};


export type MutationVendor_Management_Request_Approval_From_ContactsArgs = {
  vendorId: $ElementType<Scalars, 'ID'>,
};


export type MutationVendor_Management_Vendor_LoginArgs = {
  vendorId: $ElementType<Scalars, 'ID'>,
};


export type MutationVendor_Management_Vendor_UpdateArgs = {
  vendorId: $ElementType<Scalars, 'ID'>,
  body: VendorManagementUpdateParams,
};


export type MutationUsers_CreateArgs = {
  body: UserCreateParams,
};


export type MutationUsers_UpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: UserPatchParams,
};


export type MutationOnboarding_Data_Core_Driver_PatchArgs = {
  body: OnboardingCoreDriverPatch,
};


export type MutationOnboarding_Data_Completed_Step_PutArgs = {
  stepEnum: OnboardingStepsEnum,
};


export type MutationCustomers_Test_Credentials_PostArgs = {
  body: CustomersTestCredentialsPostParams,
};


export type MutationDelete_UserArgs = {
  userId: $ElementType<Scalars, 'ID'>,
};


export type MutationMobile_App_Scan_Credentials_PostArgs = {
  body: MobileAppScanCredentialsParams,
};


export type MutationCustomers_Test_Credentials_PatchArgs = {
  body: CustomersTestCredentialsPatchParams,
};


export type MutationJira_Integration_Configs_PatchArgs = {
  id: $ElementType<Scalars, 'String'>,
  body: JiraIntegrationConfigsPatchPatchParams,
};


export type MutationJira_Integration_Configs_PostArgs = {
  body: JiraIntegrationConfigsPostParams,
};


export type MutationVendor_Management_Current_Vendor_PatchArgs = {
  body: VendorManagementCurrentVendorPatchParams,
};


export type MutationScoped_Access_Token_For_Finding_IdArgs = {
  finding_id: $ElementType<Scalars, 'String'>,
  body: ScopedAccessTokenParams,
};


export type MutationScoped_Access_Token_For_Mobile_App_IdArgs = {
  mobile_app_id: $ElementType<Scalars, 'String'>,
  body: ScopedAccessTokenParams,
};


export type MutationScoped_Access_Token_For_OnboardingArgs = {
  body: ScopedAccessTokenOnboardingParams,
};


export type MutationScoped_Access_Token_For_Openscan_App_IdArgs = {
  mobile_app_id: $ElementType<Scalars, 'String'>,
  body: ScopedAccessTokenParams,
};


export type MutationScoped_Access_Token_DeleteArgs = {
  scoped_access_token_id: $ElementType<Scalars, 'ID'>,
};


export type MutationSecurity_Finding_Note_CreateArgs = {
  id: $ElementType<Scalars, 'String'>,
  body: SecurityFindingNote,
};


export type MutationSecurity_Finding_PatchArgs = {
  id: $ElementType<Scalars, 'String'>,
  body: SecurityFindingPatchParams,
};


export type MutationSecurity_Finding_Update_PriorityArgs = {
  id: $ElementType<Scalars, 'String'>,
  body: SecurityFindingPatchPriorityParams,
};


export type MutationData_Trust_ReportArgs = {
  report_type: ComplianceReportType,
  body: ComplianceReportParams,
};


export type MutationApp_Store_Answers_ReportsArgs = {
  body: ComplianceReportParams,
};


export type MutationApi_Protect_Configurations_PutArgs = {
  body: ApiProtectConfigurationsUpdateParams,
};


export type MutationApi_Protect_Protected_Allowed_Email_Addresses_CreateArgs = {
  body: ApiProtectConfigurationsAllowedEmailAdressesCreateParams,
};


export type MutationApi_Protect_Protected_Allowed_Api_Keys_CreateArgs = {
  body: ApiProtectConfigurationsAllowedApiKeysCreateParams,
};


export type MutationApi_Protect_Protected_Allowed_Ip_Ranges_CreateArgs = {
  body: ApiProtectConfigurationsAllowedIpRangesCreateParams,
};


export type MutationApi_Protect_Protected_Allowed_Domains_CreateArgs = {
  body: ApiProtectConfigurationsAllowedDomainsCreateParams,
};


export type MutationApi_Protect_Protected_Allowed_Countries_CreateArgs = {
  body: ApiProtectConfigurationsAllowedCountriesCreateParams,
};


export type MutationMobile_Protect_Configuration_Wizard_Sdk_Variants_CustomizeArgs = {
  sdk_variant: $ElementType<Scalars, 'String'>,
  body?: ?TrustKitSingleStepSdkConfigurationParams,
};


export type MutationAlerts_Integrations_TestArgs = {
  body: AlertsIntegrationsTestRequest,
};


export type MutationAlerts_Integrations_CreateArgs = {
  body: AlertsIntegrationCreateRequest,
};


export type MutationJira_Projects_FetchesArgs = {
  body: JiraIntegrationsJiraProjectsFetchesRequest,
};


export type MutationJira_Issue_Fields_FetchesArgs = {
  body: JiraIntegrationsJiraFieldsFetchesRequest,
};


export type MutationAlerts_Integrations_UpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: AlertsIntegrationCreateRequest,
};


export type MutationAlerts_Integrations_DeleteArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type MutationIos_Data_Protection_CreateArgs = {
  body: EntitlementsCreateParams,
};


export type MutationTrustkit_Domains_Configuration_CreateArgs = {
  body?: ?TrustKitPinnedDomainUpdateParams,
};


export type MutationSdk_Configuration_CreateArgs = {
  body?: ?TrustKitPinnedDomainSdkConfigurationParams,
};


export type MutationOne_Step_Sdk_Configuration_CreateArgs = {
  body?: ?TrustKitSingleStepSdkConfigurationParams,
};


export type MutationApi_Protect_Onboarding_Url_CreateArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?ApiProtectOnboardingUrlParams,
};


export type MutationApi_Protect_Onboarding_Url_Bulk_CreateArgs = {
  body?: ?ApiProtectOnboardingUrlBulkParams,
};


export type MutationMobile_Protect_Keys_Or_Token_PostArgs = {
  body: MobileProtectApiKeysParams,
};

type $Pick<Origin: Object, Keys: Object> = $ObjMapi<Keys, <Key>(k: Key) => $ElementType<Origin, Key>>;

export type GetTheMostOfDtAuthenticatorCountQueryVariables = {};


export type GetTheMostOfDtAuthenticatorCountQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_authenticators_list: ({
      ...{ __typename: 'CloudAuthenticatorsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }) }
  }) }
});

export type GetTheMostOfDtIntegrationCountQueryVariables = {};


export type GetTheMostOfDtIntegrationCountQuery = ({
    ...{ __typename: 'Query' },
  ...{ alerts_integrations_list: ({
      ...{ __typename: 'AlertsIntegrationsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }) }
  }) }
});

export type ResetAssetGroupFragment = ({
    ...{ __typename: 'AssetSummaryField' },
  ...{ belongs_to_asset_group?: ?({
      ...{ __typename: 'AssetGroupField' },
    ...$Pick<AssetGroupField, { id: *, name: * }>
  }) }
});

export type LinkAssetGroupFragment = ({
    ...{ __typename: 'AssetSummaryField' },
  ...{ belongs_to_asset_group?: ?({
      ...{ __typename: 'AssetGroupField' },
    ...$Pick<AssetGroupField, { id: * }>
  }) }
});

export type GetAssetGroupFragment = ({
    ...{ __typename: 'AssetSummaryField' },
  ...$Pick<AssetSummaryField, { id: *, name: *, url?: *, status: *, asset_type: *, asset_type_icon_url: *, asset_type_name: *, date_created: *, date_no_longer_accessible?: *, discovered_via: *, hosted_on?: *, open_important_policy_violations_count: *, open_proactive_policy_violations_count: *, open_urgent_policy_violations_count: * }>,
  ...{ belongs_to_asset_group?: ?({
      ...{ __typename: 'AssetGroupField' },
    ...$Pick<AssetGroupField, { id: *, name: * }>
  }) }
});

export type PolicyRuleTypeV2PolicyRuleUpdateFragment = ({
    ...{ __typename: 'PolicyRuleTypeV2PolicyRule' },
  ...$Pick<PolicyRuleTypeV2PolicyRule, { relevance: * }>
});

export type SecurityToolKitMagecartQueryQueryVariables = {
  policyRuleTypeIds?: ?Array<?$ElementType<Scalars, 'ID'>>,
};


export type SecurityToolKitMagecartQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }) }
  }) }
});

export type GetScanLogQueryQueryVariables = {
  url: $ElementType<Scalars, 'String'>,
  headers: Headers,
};


export type GetScanLogQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ scan_log: ({
      ...{ __typename: 'ScanLogResponse' },
    ...$Pick<ScanLogResponse, { text: * }>
  }) }
});

export type SecurityToolKitXssProtectionQueryQueryVariables = {
  policyRuleTypeIds?: ?Array<?$ElementType<Scalars, 'ID'>>,
};


export type SecurityToolKitXssProtectionQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, additional_info?: * }>,
      ...{ affected_web_application?: ?({
          ...{ __typename: 'WebApplication' },
        ...$Pick<WebApplication, { id: *, base_url: * }>,
        ...{ most_recent_analysis_result: ({
            ...{ __typename: 'WebAnalysisResult' },
          ...$Pick<WebAnalysisResult, { title: * }>
        }) }
      }) }
    })> }
  }) }
});

export type PolicyViolationsWithRemeditationQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type PolicyViolationsWithRemeditationQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { violated_policy_rule_id: * }>,
      ...PolicyViolationItemFragmentFragment
    })> }
  }) }
});

export type CorrectProtectAuthenticatorCountQueryVariables = {};


export type CorrectProtectAuthenticatorCountQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_authenticators_list: ({
      ...{ __typename: 'CloudAuthenticatorsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }) }
  }) }
});

export type OpenViolationsQueryVariables = {};


export type OpenViolationsQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }) }
  }) }
});

export type ResolvedViolationsQueryVariables = {};


export type ResolvedViolationsQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }) }
  }) }
});

export type CloudResourceIncludedDlpStatsUnauthenticatedUpdateFragment = ({
    ...{ __typename: 'CloudResourceIncludedCloudDlpStats' },
  ...$Pick<CloudResourceIncludedCloudDlpStats, { unauthenticated_policy_violation_status?: * }>
});

export type CloudResourceIncludedDlpStatsPiiUpdateFragment = ({
    ...{ __typename: 'CloudResourceIncludedCloudDlpStats' },
  ...$Pick<CloudResourceIncludedCloudDlpStats, { pii_leaking_policy_violation_status?: * }>
});

export type ShadowAssetsOverviewInventoryStatsQueryQueryVariables = {
  reference_date?: ?$ElementType<Scalars, 'String'>,
};


export type ShadowAssetsOverviewInventoryStatsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ inventory_stats_reference_date_get: ({
      ...{ __typename: 'InventoryStatsResponse' },
    ...{ inventory_stats: Array<({
        ...{ __typename: 'InventoryStats' },
      ...{ all_assets_stats: ({
          ...{ __typename: 'AllAssetsStats' },
        ...{ network_services_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { shadow_assets_count: * }>
        }), restful_apis_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { shadow_assets_count: * }>
        }), web_applications_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { shadow_assets_count: * }>
        }), cloud_resources_stats: ({
            ...{ __typename: 'CloudResourcesInventoryStats' },
          ...$Pick<CloudResourcesInventoryStats, { shadow_assets_count: *, shadow_serverless_assets_count: * }>
        }) }
      }) }
    })> }
  }) }
});

export type GetWebApplicationsStatsQueryQueryVariables = {};


export type GetWebApplicationsStatsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_applications_stats_get: ({
      ...{ __typename: 'WebApplicationsStatsResponse' },
    ...{ web_applications_stats: Array<({
        ...{ __typename: 'WebAppAssetsInventoryStats' },
      ...$Pick<WebAppAssetsInventoryStats, { total_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
    })> }
  }) }
});

export type SpaXssProtectionPolicyRulesTypeListResponseQueryQueryVariables = {};


export type SpaXssProtectionPolicyRulesTypeListResponseQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_rule_types_list: ({
      ...{ __typename: 'PolicyRuleTypeListResponse' },
    ...{ policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: *, title: *, description: * }>
    })> }
  }) }
});

export type SpaXssProtectionSpecialScansQueryQueryVariables = {
  scan_type?: ?$ElementType<Scalars, 'String'>,
};


export type SpaXssProtectionSpecialScansQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ special_scan_requests_list: ({
      ...{ __typename: 'SpecialScanRequestResponse' },
    ...{ special_scan_requests: Array<({
        ...{ __typename: 'SpecialScanRequest' },
      ...$Pick<SpecialScanRequest, { id: *, date_created: *, date_completed?: *, created_by_user_id?: *, current_status: *, scan_type: * }>
    })> }
  }) }
});

export type SpaXssProtectionPolicyViolationsQueryQueryVariables = {
  ruleTypeIds?: ?Array<?$ElementType<Scalars, 'ID'>>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type SpaXssProtectionPolicyViolationsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, violated_policy_rule_id: *, status: *, date_created: *, date_resolved?: *, affected_web_application_id?: * }>
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, policy_rule_type_id: * }>
    })>, policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: *, title: *, description: * }>
    })> }
  }) }
});

export type SpaXssProtectionInventoryStatsQueryQueryVariables = {};


export type SpaXssProtectionInventoryStatsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ inventory_stats_get: ({
      ...{ __typename: 'InventoryStatsResponse' },
    ...{ inventory_stats: Array<({
        ...{ __typename: 'InventoryStats' },
      ...$Pick<InventoryStats, { reference_date: * }>,
      ...{ all_assets_stats: ({
          ...{ __typename: 'AllAssetsStats' },
        ...{ web_applications_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: * }>
        }) }
      }) }
    })> }
  }) }
});

export type SpaXssProtectionWebApplicationsQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type SpaXssProtectionWebApplicationsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_applications_list: ({
      ...{ __typename: 'WebApplicationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), web_applications: Array<({
        ...{ __typename: 'WebApplication' },
      ...$Pick<WebApplication, { id: *, network_service_id: *, base_url: *, base_path: *, date_created: *, policy_violation_ids: * }>,
      ...{ most_recent_analysis_result: ({
          ...{ __typename: 'WebAnalysisResult' },
        ...$Pick<WebAnalysisResult, { title: * }>
      }) }
    })>, network_services: Array<({
        ...{ __typename: 'NetworkService' },
      ...$Pick<NetworkService, { id: *, hosted_on?: * }>
    })> }
  }) }
});

export type TriageCredentialAhuraMutationVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
  credential_id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?CredentialTriageParams,
};


export type TriageCredentialAhuraMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ triage: ({
      ...{ __typename: 'CredentialTriageResponse' },
    ...{ detected_spa_credential: ({
        ...{ __typename: 'DetectedSpaCredential' },
      ...$Pick<DetectedSpaCredential, { id: *, web_application_id: *, found_at_url: *, credential_as_markdown: *, exported_to_policy_violation_id?: *, triaging_decision: * }>
    }) }
  }) }
});

export type WebApplicationDetailsQueryAhuraQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type WebApplicationDetailsQueryAhuraQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_application_details: ({
      ...{ __typename: 'WebApplicationDetailsResponse' },
    ...{ network_services: Array<({
        ...{ __typename: 'NetworkService' },
      ...$Pick<NetworkService, { id: * }>
    })>, restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: * }>
    })>, graphql_apis: Array<({
        ...{ __typename: 'GraphqlApi' },
      ...$Pick<GraphqlApi, { id: * }>
    })>, web_applications: Array<({
        ...{ __typename: 'WebApplication' },
      ...$Pick<WebApplication, { id: *, base_url: *, base_path: *, network_service_id: *, date_created: *, discovered_via: * }>,
      ...{ network_service: ({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, hosted_on?: * }>
      }), most_recent_analysis_result: ({
          ...{ __typename: 'WebAnalysisResult' },
        ...$Pick<WebAnalysisResult, { title: *, screenshot_url: *, fullscreen_screenshot_url: *, web_application_type: *, date_created: *, date_last_received: *, detected_restful_api_ids?: *, exercised_api_operation_ids?: * }>,
        ...{ detected_libraries?: ?Array<({
            ...{ __typename: 'WebLibrary' },
          ...$Pick<WebLibrary, { name: *, project_url: *, icon_url: * }>
        })>, detected_network_activity?: ?Array<({
            ...{ __typename: 'WebActivity' },
          ...$Pick<WebActivity, { base_url: *, request_types: * }>
        })> }
      }) }
    })> }
  }) }
});

export type WebApplicationCredentialsQueryAhuraQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type WebApplicationCredentialsQueryAhuraQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_application_credentials: ({
      ...{ __typename: 'WebApplicationCredentialsResponse' },
    ...{ detected_spa_credentials: Array<({
        ...{ __typename: 'DetectedSpaCredential' },
      ...$Pick<DetectedSpaCredential, { id: *, value: *, web_application_id: *, found_at_url: *, credential_as_markdown: *, exported_to_policy_violation_id?: *, triaging_decision: * }>,
      ...{ used_in_request: ({
          ...{ __typename: 'DetectedSpaCredentialUsedIn' },
        ...$Pick<DetectedSpaCredentialUsedIn, { header_name: *, header_value: *, method: *, url: * }>
      }) }
    })> }
  }) }
});

export type CloudAuthenticatorsQueryVariables = {};


export type CloudAuthenticatorsQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_authenticators_list_DEPRECATED: ({
      ...{ __typename: 'CloudAuthenticatorsResponse_DEPRECATED' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }), cloud_authenticators: Array<({
        ...{ __typename: 'CloudAuthenticator_DEPRECATED' },
      ...$Pick<CloudAuthenticator_Deprecated, { status: * }>,
      ...{ apigee_credential?: ?({
          ...{ __typename: 'ApigeeCredential' },
        ...$Pick<ApigeeCredential, { id: *, organization: *, added_by_user_id: *, date_created: * }>
      }), apigee_on_prem_credential?: ?({
          ...{ __typename: 'ApigeeOnPremCredential' },
        ...$Pick<ApigeeOnPremCredential, { id: *, date_created: *, added_by_user_id?: *, organization: *, management_api_url?: *, management_api_username?: * }>
      }), aws_credential?: ?({
          ...{ __typename: 'AwsCredential' },
        ...$Pick<AwsCredential, { id: *, role_arn: *, external_id: *, added_by_user_id: *, date_created: * }>
      }), azure_credential?: ?({
          ...{ __typename: 'AzureCredential' },
        ...$Pick<AzureCredential, { id: *, tenant_id: *, client_id: *, added_by_user_id?: *, date_created: * }>
      }), axway_credential?: ?({
          ...{ __typename: 'AxwayCredential' },
        ...$Pick<AxwayCredential, { id: *, added_by_user_id?: *, date_created: *, organization_id?: *, client_id?: * }>
      }), mulesoft_credential?: ?({
          ...{ __typename: 'MulesoftCredential' },
        ...$Pick<MulesoftCredential, { id: *, added_by_user_id?: *, date_created: *, organization_id?: *, account_username?: * }>
      }), kong_credential?: ?({
          ...{ __typename: 'KongCredential' },
        ...$Pick<KongCredential, { id: *, added_by_user_id?: *, date_created: *, kong_admin_subdomain?: * }>
      }), gcp_credential?: ?({
          ...{ __typename: 'GcpCredential' },
        ...$Pick<GcpCredential, { id: *, added_by_user_id?: *, date_created: *, client_email?: *, organization_id?: *, private_key_id?: * }>
      }) }
    })> }
  }) }
});

export type DetectInjectApiOperationDetailsQueryQueryVariables = {
  restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type DetectInjectApiOperationDetailsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_operation_details: ({
      ...{ __typename: 'APIOperationDetailsResponse' },
    ...{ api_operations: Array<({
        ...{ __typename: 'APIOperation' },
      ...$Pick<ApiOperation, { id: *, http_method: *, policy_violation_ids: * }>
    })>, api_operation_scan_traces?: ?Array<({
        ...{ __typename: 'APIOperationScanTrace' },
      ...$Pick<ApiOperationScanTrace, { id: *, url: *, http_request: *, http_response: * }>
    })> }
  }) }
});

export type LeakyApisPolicyRuleUpdateMutationVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
  policy_rule_id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?PolicyRulesUpdateParams,
};


export type LeakyApisPolicyRuleUpdateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ policy_rules_update: ({
      ...{ __typename: 'PolicyRulesUpdateResponse' },
    ...{ policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, relevance: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: * }>,
      ...{ configuration?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { weekdays_when_to_scan?: *, time_when_to_scan?: * }>
      }) }
    })>, policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: *, title: *, default_relevance?: * }>
    })> }
  }) }
});

export type LeakyApisPolicyRuleCreateMutationVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?PolicyRulesCreateParams,
};


export type LeakyApisPolicyRuleCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ policy_rules_create: ({
      ...{ __typename: 'PolicyRule' },
    ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, relevance: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: * }>,
    ...{ configuration?: ?({
        ...{ __typename: 'PolicyRuleConfiguration' },
      ...$Pick<PolicyRuleConfiguration, { weekdays_when_to_scan?: *, time_when_to_scan?: * }>
    }) }
  }) }
});

export type LeakyApisSpecialScansQueryQueryVariables = {
  scan_type?: ?$ElementType<Scalars, 'String'>,
};


export type LeakyApisSpecialScansQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ special_scan_requests_list: ({
      ...{ __typename: 'SpecialScanRequestResponse' },
    ...{ special_scan_requests: Array<({
        ...{ __typename: 'SpecialScanRequest' },
      ...$Pick<SpecialScanRequest, { id: *, date_created: *, date_completed?: *, created_by_user_id?: *, current_status: *, scan_type: * }>
    })> }
  }) }
});

export type StartSpecialScanMutationVariables = {
  body: SpecialScanRequestParams,
};


export type StartSpecialScanMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ special_scan_request: ({
      ...{ __typename: 'SpecialScanRequestResponse' },
    ...{ special_scan_requests: Array<({
        ...{ __typename: 'SpecialScanRequest' },
      ...$Pick<SpecialScanRequest, { id: *, date_created: *, date_completed?: *, created_by_user_id?: *, current_status: *, scan_type: * }>
    })> }
  }) }
});

export type LeakyApisPoliciesListResponseQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type LeakyApisPoliciesListResponseQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policies_list: ({
      ...{ __typename: 'PoliciesListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policies: Array<({
        ...{ __typename: 'Policy' },
      ...$Pick<Policy, { id: *, is_special_dt_policy: * }>
    })> }
  }) }
});

export type LeakyApisPolicyRulesListResponseQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  policy_rule_type_id?: ?$ElementType<Scalars, 'String'>,
};


export type LeakyApisPolicyRulesListResponseQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_rules_list: ({
      ...{ __typename: 'PolicyRuleListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, relevance: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: * }>,
      ...{ configuration?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { weekdays_when_to_scan?: *, time_when_to_scan?: * }>
      }) }
    })> }
  }) }
});

export type LeakyApisPolicyRulesTypeListResponseQueryQueryVariables = {};


export type LeakyApisPolicyRulesTypeListResponseQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_rule_types_list: ({
      ...{ __typename: 'PolicyRuleTypeListResponse' },
    ...{ policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: *, title: *, default_relevance?: * }>
    })> }
  }) }
});

export type NetworkServiceDetailsQueryQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type NetworkServiceDetailsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ network_service_details: ({
      ...{ __typename: 'NetworkServiceDetailsResponse' },
    ...{ restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, network_service_id: *, openapi_definition_id: *, title: *, base_url: *, base_path: *, discovered_via: *, discovered_via_user_id?: *, date_created: *, asset_type_name: *, asset_type_icon_url: * }>,
      ...{ asset_group_memberships: Array<({
          ...{ __typename: 'AssetGroupMembership' },
        ...$Pick<AssetGroupMembership, { id: *, date_created: *, membership_kind: *, asset_group_id: * }>
      })>, api_operations?: ?Array<({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, path: *, http_method: *, is_authenticated?: * }>
      })> }
    })>, web_applications: Array<({
        ...{ __typename: 'WebApplication' },
      ...$Pick<WebApplication, { id: *, discovered_via: *, network_service_id: *, base_url: *, base_path: *, date_created: *, asset_type_name: *, asset_type_icon_url: *, policy_violation_ids: * }>,
      ...{ most_recent_analysis_result: ({
          ...{ __typename: 'WebAnalysisResult' },
        ...$Pick<WebAnalysisResult, { title: * }>
      }) }
    })>, network_services: Array<({
        ...{ __typename: 'NetworkService' },
      ...$Pick<NetworkService, { id: *, url: *, hosted_on?: *, date_created: *, asset_type_name: *, asset_type_icon_url: *, discovered_via: *, discovered_via_authenticator_id?: * }>,
      ...{ domain_name: ({
          ...{ __typename: 'DomainName' },
        ...$Pick<DomainName, { id: *, name: *, date_created: *, discovered_via: * }>
      }), connectivity_scan_results: Array<({
          ...{ __typename: 'NetworkServiceConnectivityScanResult' },
        ...$Pick<NetworkServiceConnectivityScanResult, { status: *, date_created: * }>
      })> }
    })>, events: Array<({
        ...{ __typename: 'Event' },
      ...$Pick<Event, { id: *, date_created: *, event_importance: *, event_type: * }>,
      ...{ user?: ?({
          ...{ __typename: 'User' },
        ...$Pick<User, { id: *, login_email: * }>
      }), cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { id: *, discovered_via: *, asset_type_name: * }>
      }), policy_violation?: ?({
          ...{ __typename: 'PolicyViolation' },
        ...$Pick<PolicyViolation, { id: *, exception_explanation?: *, date_resolved?: *, exception_type?: *, status: * }>,
        ...{ exception_added_by_user?: ?({
            ...{ __typename: 'User' },
          ...$Pick<User, { login_email: * }>
        }), violated_policy_rule: ({
            ...{ __typename: 'PolicyRule' },
          ...{ policy_rule_type: ({
              ...{ __typename: 'PolicyRuleType' },
            ...$Pick<PolicyRuleType, { title: * }>
          }) }
        }) }
      }), network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, discovered_via: *, url: * }>
      }), restful_api?: ?({
          ...{ __typename: 'RestfulAPI' },
        ...$Pick<RestfulApi, { id: *, discovered_via: *, base_url: * }>
      }), api_operation?: ?({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, http_method: *, path: * }>
      }), comment?: ?({
          ...{ __typename: 'Comment' },
        ...$Pick<Comment, { id: *, date_created: *, content?: *, related_policy_violation_id: *, added_by_user_id: * }>
      }) }
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, relevance: * }>
    })>, policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, date_created: *, date_resolved?: *, exception_date_created?: *, exception_type?: *, status: * }>,
      ...{ exception_added_by_user?: ?({
          ...{ __typename: 'User' },
        ...$Pick<User, { login_email: * }>
      }), violated_policy_rule: ({
          ...{ __typename: 'PolicyRule' },
        ...$Pick<PolicyRule, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { title: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { description: *, link: *, compliance_standard: *, compliance_standard_criteria: * }>
          })> }
        }) }
      }), affected_network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...{ domain_name: ({
            ...{ __typename: 'DomainName' },
          ...$Pick<DomainName, { name: * }>
        }) }
      }), affected_restful_api?: ?({
          ...{ __typename: 'RestfulAPI' },
        ...$Pick<RestfulApi, { base_url: * }>
      }), affected_api_operation?: ?({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, http_method: *, path: *, restful_api_id: * }>
      }), affected_cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { url?: *, asset_type_name: * }>
      }), affected_web_application?: ?({
          ...{ __typename: 'WebApplication' },
        ...$Pick<WebApplication, { base_url: * }>
      }) }
    })>, certificate_chains: Array<({
        ...{ __typename: 'CertificateChain' },
      ...$Pick<CertificateChain, { id: * }>,
      ...{ security_properties: Array<({
          ...{ __typename: 'CertificateChainSecurityProperty' },
        ...$Pick<CertificateChainSecurityProperty, { enum: *, value: *, relevance?: * }>
      })>, certificates: Array<({
          ...{ __typename: 'Certificate' },
        ...$Pick<Certificate, { id: *, issuer_as_rfc4514_string: *, date_created: *, domain_names_in_cn: *, public_key_algorithm: *, signature_hash_algorithm: *, public_key_size: *, not_valid_after: * }>
      })> }
    })>, cloud_resources: Array<({
        ...{ __typename: 'CloudResource' },
      ...$Pick<CloudResource, { id: *, discovered_via: *, url?: *, cloud_console_url: *, asset_type_name: *, asset_type_icon_url: * }>
    })>, tls_scan_results: Array<({
        ...{ __typename: 'TlsScanResult' },
      ...$Pick<TlsScanResult, { network_service_id: * }>,
      ...{ tls_scan_attributes: Array<({
          ...{ __typename: 'TlsScanAttribute' },
        ...$Pick<TlsScanAttribute, { enum: *, value: * }>,
        ...{ opened_policy_violation?: ?({
            ...{ __typename: 'PolicyViolation' },
          ...$Pick<PolicyViolation, { violated_policy_rule_id: *, date_resolved?: * }>
        }), resolved_policy_violation?: ?({
            ...{ __typename: 'PolicyViolation' },
          ...$Pick<PolicyViolation, { violated_policy_rule_id: *, date_resolved?: * }>
        }) }
      })> }
    })> }
  }) }
});

export type InventoryStatsForPolicyViolationsDashboardQueryVariables = {};


export type InventoryStatsForPolicyViolationsDashboardQuery = ({
    ...{ __typename: 'Query' },
  ...{ inventory_stats_get: ({
      ...{ __typename: 'InventoryStatsResponse' },
    ...{ inventory_stats: Array<({
        ...{ __typename: 'InventoryStats' },
      ...$Pick<InventoryStats, { unresolved_policy_violations_total_count: *, resolved_policy_violations_total_count: * }>
    })> }
  }) }
});

export type PolicyViolationsApiOperationDetailsQueryQueryVariables = {
  restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type PolicyViolationsApiOperationDetailsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_operation_details: ({
      ...{ __typename: 'APIOperationDetailsResponse' },
    ...{ api_operations: Array<({
        ...{ __typename: 'APIOperation' },
      ...$Pick<ApiOperation, { id: *, http_method: *, policy_violation_ids: * }>
    })>, api_operation_scan_traces?: ?Array<({
        ...{ __typename: 'APIOperationScanTrace' },
      ...$Pick<ApiOperationScanTrace, { id: *, url: *, http_request: *, http_response: * }>
    })> }
  }) }
});

export type PolicyViolationsForRuleIdQueryVariables = {
  violated_policy_rule_id?: ?$ElementType<Scalars, 'ID'>,
};


export type PolicyViolationsForRuleIdQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { violated_policy_rule_id: * }>,
      ...PolicyViolationItemFragmentFragment
    })> }
  }) }
});

export type PolicyViolationsWithRemeditationForRuleIdQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  policy_rule_id: $ElementType<Scalars, 'ID'>,
  is_eligible_for_auto_remediation?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_violation_status?: ?Array<?PolicyViolationStatusEnum>,
};


export type PolicyViolationsWithRemeditationForRuleIdQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { violated_policy_rule_id: * }>,
      ...PolicyViolationItemFragmentFragment
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, relevance: *, belongs_to_policy_id: * }>,
      ...{ policy_rule_type: ({
          ...{ __typename: 'PolicyRuleType' },
        ...$Pick<PolicyRuleType, { id: *, title: * }>
      }) }
    })> }
  }) }
});

export type PolicyViolationItemFragmentFragment = ({
    ...{ __typename: 'PolicyViolation' },
  ...$Pick<PolicyViolation, { id: *, date_resolved?: *, date_created: *, exception_type?: *, exception_date_created?: *, status: * }>,
  ...{ violated_policy_rule: ({
      ...{ __typename: 'PolicyRule' },
    ...$Pick<PolicyRule, { id: *, relevance: * }>,
    ...{ policy_rule_type: ({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: *, title: * }>,
      ...{ compliance_policy_references: Array<({
          ...{ __typename: 'PolicyComplianceReference' },
        ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
      })> }
    }) }
  }), affected_api_operation?: ?({
      ...{ __typename: 'APIOperation' },
    ...$Pick<ApiOperation, { id: *, http_method: *, path: * }>,
    ...{ restful_api: ({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { base_url: * }>
    }) }
  }), affected_cloud_resource?: ?({
      ...{ __typename: 'CloudResource' },
    ...$Pick<CloudResource, { id: *, asset_type_name: *, url?: * }>
  }), affected_network_service?: ?({
      ...{ __typename: 'NetworkService' },
    ...$Pick<NetworkService, { id: * }>,
    ...{ domain_name: ({
        ...{ __typename: 'DomainName' },
      ...$Pick<DomainName, { name: * }>
    }) }
  }), affected_web_application?: ?({
      ...{ __typename: 'WebApplication' },
    ...$Pick<WebApplication, { id: *, base_url: * }>
  }) }
});

export type RemediationBatchMutationVariables = {
  body: AutoRemediationAttemptBatches,
};


export type RemediationBatchMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ auto_remediation_attempt_batches: ({
      ...{ __typename: 'AutoRemediationAttemptBatch' },
    ...$Pick<AutoRemediationAttemptBatch, { id: *, url: * }>,
    ...{ auto_remediation_attempts: Array<({
        ...{ __typename: 'AutoRemediationAttempt' },
      ...$Pick<AutoRemediationAttempt, { status: * }>
    })> }
  }) }
});

export type RemediationBatchCheckStatusQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type RemediationBatchCheckStatusQuery = ({
    ...{ __typename: 'Query' },
  ...{ auto_remediation_attempt_batch_details: ({
      ...{ __typename: 'AutoRemediationAttemptBatch' },
    ...{ auto_remediation_attempts: Array<({
        ...{ __typename: 'AutoRemediationAttempt' },
      ...$Pick<AutoRemediationAttempt, { status: * }>
    })> }
  }) }
});

export type PolicyViolationsForPolicyRuleIdsQueryQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type PolicyViolationsForPolicyRuleIdsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, violated_policy_rule_id: *, status: *, date_created: *, date_resolved?: *, affected_web_application_id?: * }>
    })> }
  }) }
});

export type GetRestfulApiIntegratedCloudResourcesQueryQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type GetRestfulApiIntegratedCloudResourcesQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ restful_api_details: ({
      ...{ __typename: 'RestfulAPIDetailsResponse' },
    ...{ restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...{ integrated_cloud_resources?: ?Array<({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { name: *, id: *, asset_type_name: *, runtime?: *, region?: *, asset_type_icon_url: * }>
      })> }
    })> }
  }) }
});

export type ApiOperationsGetQueryVariables = {
  restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type ApiOperationsGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_operation_details: ({
      ...{ __typename: 'APIOperationDetailsResponse' },
    ...{ api_operations: Array<({
        ...{ __typename: 'APIOperation' },
      ...$Pick<ApiOperation, { id: *, http_method: *, policy_violation_ids: * }>
    })>, api_operation_scan_traces?: ?Array<({
        ...{ __typename: 'APIOperationScanTrace' },
      ...$Pick<ApiOperationScanTrace, { id: *, url: *, http_request: *, http_response: * }>
    })> }
  }) }
});

export type ApiOperationsGetVersion2QueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type ApiOperationsGetVersion2Query = ({
    ...{ __typename: 'Query' },
  ...{ api_operation_details_v2: ({
      ...{ __typename: 'APIOperationDetailsResponseVersion2' },
    ...$Pick<ApiOperationDetailsResponseVersion2, { id: * }>,
    ...{ restful_api: ({
        ...{ __typename: 'APIOperationV2RestfulApi' },
      ...$Pick<ApiOperationV2RestfulApi, { id: * }>
    }) }
  }) }
});

export type ApiOperationsListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_asset_group_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
};


export type ApiOperationsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_operation_list: ({
      ...{ __typename: 'ApiOperationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), api_operations: Array<({
        ...{ __typename: 'APIOperation' },
      ...$Pick<ApiOperation, { id: *, path: *, http_method: *, is_authenticated?: *, date_created: *, restful_api_id: *, restful_api_title?: *, hosted_on?: *, authentication_scheme?: * }>
    })> }
  }) }
});

export type ApiOperationsListV2QueryVariables = {
  restful_api_id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_is_authenticated?: ?$ElementType<Scalars, 'Boolean'>,
  include_api_radar_stats?: ?$ElementType<Scalars, 'Boolean'>,
};


export type ApiOperationsListV2Query = ({
    ...{ __typename: 'Query' },
  ...{ api_operations_list_v2: ({
      ...{ __typename: 'ApiOperationsListResponseV2' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), api_operations: Array<({
        ...{ __typename: 'V2ApiOperation' },
      ...$Pick<V2ApiOperation, { id: *, asset_type_icon_url: *, asset_type_name: *, authentication_scheme: *, date_created: *, http_method: *, is_authenticated: *, open_important_policy_violations_count: *, open_proactive_policy_violations_count: *, open_urgent_policy_violations_count: *, path: *, restful_api_id: *, restful_api_title: *, status: * }>,
      ...{ included_api_radar_stats?: ?({
          ...{ __typename: 'V2ApiOperationApiRadarStats' },
        ...$Pick<V2ApiOperationApiRadarStats, { detect_and_inject_violation_id?: *, hack_and_extract_violation_id?: *, leaky_apis_violation_id?: *, ssrf_violation_id?: * }>
      }) }
    })> }
  }) }
});

export type ApigeeAuthenticatorsCreateMutationMutationVariables = {
  body: ApigeeAuthenticatorsCreateParams,
};


export type ApigeeAuthenticatorsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ apigee_authenticators_create: ({
      ...{ __typename: 'ApigeeAuthenticatorsCreateResponse' },
    ...$Pick<ApigeeAuthenticatorsCreateResponse, { id: *, date_created: *, added_by_user_id: *, organization: * }>
  }) }
});

export type AssetGroupsAssetsSummaryListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
};


export type AssetGroupsAssetsSummaryListQuery = ({
    ...{ __typename: 'Query' },
  ...{ asset_group_assets_summary_list: ({
      ...{ __typename: 'AssetGroupAssetsSummaryListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), assets: Array<({
        ...{ __typename: 'AssetSummary' },
      ...$Pick<AssetSummary, { id: *, name: *, asset_type: *, asset_type_icon_url: *, asset_type_name: *, open_urgent_policy_violations_count: *, open_important_policy_violations_count: *, open_proactive_policy_violations_count: *, discovered_via: *, hosted_on: *, date_created: * }>
    })> }
  }) }
});

export type AssetGroupsListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type AssetGroupsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ asset_group_list: ({
      ...{ __typename: 'AssetGroupListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), asset_groups: Array<({
        ...{ __typename: 'AssetGroups' },
      ...$Pick<AssetGroups, { id: *, date_created: *, name: *, is_read_only: *, description: *, attached_policy_id: *, created_by_user_id: *, created_by_actor_name?: * }>,
      ...{ created_by_user: ({
          ...{ __typename: 'HorizonUserField' },
        ...$Pick<HorizonUserField, { date_created?: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
      }), asset_group_memberships_overview: ({
          ...{ __typename: 'AssetGroupMembershipsOverview' },
        ...$Pick<AssetGroupMembershipsOverview, { restful_apis_count: *, api_operations_count: *, web_applications_count: *, network_services_count: *, cloud_resources_count: * }>
      }) }
    })>, users: Array<({
        ...{ __typename: 'HorizonUserField' },
      ...$Pick<HorizonUserField, { first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
    })> }
  }) }
});

export type V2AssetGroupsListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?AssetGroupsSearchIncludeEnum,
  page_size?: ?$ElementType<Scalars, 'Float'>,
};


export type V2AssetGroupsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ asset_group_list_v2: ({
      ...{ __typename: 'V2AssetGroupListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), asset_groups: Array<({
        ...{ __typename: 'V2AssetGroup' },
      ...$Pick<V2AssetGroup, { id: *, name: *, date_created: *, created_by_actor_id: *, created_by_actor_name: * }>,
      ...{ included_policy_violation_stats?: ?({
          ...{ __typename: 'PolicyViolationStatsField' },
        ...$Pick<PolicyViolationStatsField, { assets_count: *, open_urgent_violations_count: *, open_important_violations_count: *, open_proactive_violations_count: * }>
      }), included_assets_overview?: ?({
          ...{ __typename: 'AssetGroupIncludedAssetsOverview' },
        ...$Pick<AssetGroupIncludedAssetsOverview, { restful_apis_count: *, api_operations_count: *, web_applications_count: *, network_services_count: *, cloud_resources_count: *, graphql_apis_count: * }>
      }) }
    })> }
  }) }
});

export type AssetGroupDetailsQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type AssetGroupDetailsQuery = ({
    ...{ __typename: 'Query' },
  ...{ asset_group_details: ({
      ...{ __typename: 'SingleAssetGroupResponse' },
    ...{ asset_groups: Array<({
        ...{ __typename: 'AssetGroups' },
      ...$Pick<AssetGroups, { id: *, date_created: *, name: *, is_read_only: *, description: *, attached_policy_id: *, created_by_user_id: *, created_by_actor_name?: * }>,
      ...{ created_by_user: ({
          ...{ __typename: 'HorizonUserField' },
        ...$Pick<HorizonUserField, { date_created?: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
      }), asset_group_memberships_overview: ({
          ...{ __typename: 'AssetGroupMembershipsOverview' },
        ...$Pick<AssetGroupMembershipsOverview, { restful_apis_count: *, api_operations_count: *, web_applications_count: *, network_services_count: *, cloud_resources_count: * }>
      }) }
    })>, policies: Array<({
        ...{ __typename: 'Policy' },
      ...$Pick<Policy, { id: *, name: *, created_by_user_id: *, created_by_actor_id: *, created_by_actor_name: *, is_special_dt_policy: *, date_created: *, protected_asset_groups_count: *, enabled_policy_rules_count: *, policy_rule_ids?: *, description: * }>,
      ...{ created_by_user: ({
          ...{ __typename: 'HorizonUserField' },
        ...$Pick<HorizonUserField, { date_created?: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
      }) }
    })>, policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: *, title: *, description: *, recommendation?: *, default_relevance?: *, requires_configuration?: *, group?: * }>
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, belongs_to_policy_name: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: *, relevance: *, open_policy_violations_count: * }>
    })>, users: Array<({
        ...{ __typename: 'HorizonUserField' },
      ...$Pick<HorizonUserField, { date_created?: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
    })> }
  }) }
});

export type AssetGroupCreateMutationVariables = {
  body: AssetGroupCreateParams,
};


export type AssetGroupCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ asset_group_create: ({
      ...{ __typename: 'SingleAssetGroupResponse' },
    ...{ asset_groups: Array<({
        ...{ __typename: 'AssetGroups' },
      ...$Pick<AssetGroups, { id: *, date_created: *, name: *, is_read_only: *, description: *, attached_policy_id: *, created_by_user_id: * }>,
      ...{ created_by_user: ({
          ...{ __typename: 'HorizonUserField' },
        ...$Pick<HorizonUserField, { date_created?: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
      }), asset_group_memberships_overview: ({
          ...{ __typename: 'AssetGroupMembershipsOverview' },
        ...$Pick<AssetGroupMembershipsOverview, { restful_apis_count: *, api_operations_count: *, web_applications_count: *, network_services_count: *, cloud_resources_count: * }>
      }) }
    })> }
  }) }
});

export type AssetGroupPatchMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: AssetGroupUpdateParams,
};


export type AssetGroupPatchMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ asset_group_patch: ({
      ...{ __typename: 'SingleAssetGroupResponse' },
    ...{ asset_groups: Array<({
        ...{ __typename: 'AssetGroups' },
      ...$Pick<AssetGroups, { id: *, date_created: *, name: *, is_read_only: *, description: *, attached_policy_id: *, created_by_user_id: * }>,
      ...{ created_by_user: ({
          ...{ __typename: 'HorizonUserField' },
        ...$Pick<HorizonUserField, { date_created?: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
      }), asset_group_memberships_overview: ({
          ...{ __typename: 'AssetGroupMembershipsOverview' },
        ...$Pick<AssetGroupMembershipsOverview, { restful_apis_count: *, api_operations_count: *, web_applications_count: *, network_services_count: *, cloud_resources_count: * }>
      }) }
    })> }
  }) }
});

export type AssetGroupDeleteMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type AssetGroupDeleteMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ asset_group_delete?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type UserGrantAccessMutationVariables = {
  userId: $ElementType<Scalars, 'ID'>,
  body: AssetGroupGrantUserAccessParams,
};


export type UserGrantAccessMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ asset_group_user_grant_access?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type UserDenyAccessMutationVariables = {
  userId: $ElementType<Scalars, 'ID'>,
  assetGroupId: $ElementType<Scalars, 'ID'>,
};


export type UserDenyAccessMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ asset_group_user_deny_access?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type AssetTagKeyListQueryVariables = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
};


export type AssetTagKeyListQuery = ({
    ...{ __typename: 'Query' },
  ...{ asset_tag_key_list: ({
      ...{ __typename: 'AssetTagsKeysResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), asset_tag_keys: Array<({
        ...{ __typename: 'assetTagKey' },
      ...$Pick<AssetTagKey, { id: *, name: *, imported_external_id?: *, imported_from?: * }>
    })> }
  }) }
});

export type AssetTagValueListQueryVariables = {
  tag_uuid: $ElementType<Scalars, 'ID'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
};


export type AssetTagValueListQuery = ({
    ...{ __typename: 'Query' },
  ...{ asset_tag_value_list: ({
      ...{ __typename: 'AssetTagsValuesResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), asset_tags: Array<({
        ...{ __typename: 'AssetTag' },
      ...$Pick<AssetTag, { id: *, tag_id: *, tag: *, value?: *, imported_external_id?: *, imported_from?: * }>
    })> }
  }) }
});

export type AssetDetailsV2QueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type AssetDetailsV2Query = ({
    ...{ __typename: 'Query' },
  ...{ assets_details_v2: ({
      ...{ __typename: 'AssetsDetailsResponseV2' },
    ...$Pick<AssetsDetailsResponseV2, { id: *, asset_type: *, asset_type_icon_url: *, asset_type_name: *, date_created: *, date_no_longer_accessible?: *, discovered_via: *, discovered_via_icon_url: *, discovered_via_name: *, hosted_on: *, hosted_on_icon_url: *, hosted_on_name: *, name: *, open_important_policy_violations_count: *, open_proactive_policy_violations_count: *, open_urgent_policy_violations_count: *, status: *, url: * }>,
    ...{ belongs_to_asset_group?: ?({
        ...{ __typename: 'AssetGroupField' },
      ...$Pick<AssetGroupField, { id: *, name: * }>
    }), belongs_to_cloud_account?: ?({
        ...{ __typename: 'ApiProtectRestfulApiBelongsToCloudAccount' },
      ...$Pick<ApiProtectRestfulApiBelongsToCloudAccount, { id: *, cloud_account_icon_url: *, cloud_account_type_name: *, customer_supplied_name?: * }>
    }) }
  }) }
});

export type AssetListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
  include_v2_fields?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_cloud_provider?: ?Array<?HostedOn>,
  filter_by_asset_type?: ?Array<?AssetTypeEnum>,
  filter_by_discovered_via?: ?Array<?DiscoveredVia>,
  filter_by_asset_group_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_is_shadow?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_asset_tags?: ?$ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
  filter_by_ownership?: ?$ElementType<Scalars, 'String'>,
  filter_by_vendor_id?: ?$ElementType<Scalars, 'String'>,
  filter_by_related_asset?: ?$ElementType<Scalars, 'String'>,
  filter_by_related_first_party_asset_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_asset_type_group?: ?Array<?$ElementType<Scalars, 'String'>>,
  order_by?: ?AssetOrderBy,
  page_size?: ?$ElementType<Scalars, 'Float'>,
};


export type AssetListQuery = ({
    ...{ __typename: 'Query' },
  ...{ asset_list: ({
      ...{ __typename: 'AssetListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: *, exact_total_count?: *, hint_for_total_count?: *, is_approximate_total_count_enabled?: * }>
    }), assets: Array<({
        ...{ __typename: 'AssetSummaryField' },
      ...$Pick<AssetSummaryField, { id: *, name: *, url?: *, status: *, asset_type: *, asset_type_icon_url: *, asset_type_name: *, date_created: *, date_no_longer_accessible?: *, discovered_via: *, discovered_via_icon_url: *, discovered_via_name: *, hosted_on?: *, open_important_policy_violations_count: *, open_proactive_policy_violations_count: *, open_urgent_policy_violations_count: *, hosted_on_icon_url: *, hosted_on_name: * }>,
      ...{ belongs_to_asset_group?: ?({
          ...{ __typename: 'AssetGroupField' },
        ...$Pick<AssetGroupField, { id: *, name: * }>
      }), included_cloud_ransomware_stats?: ?({
          ...{ __typename: 'IncludedCloudRansomwareStats' },
        ...$Pick<IncludedCloudRansomwareStats, { last_scan_date?: *, total_files_count: *, last_scan_change_files_count: *, total_directories_count: *, last_scan_change_directories_count: *, is_publicly_writable: *, is_publicly_readable: *, is_malware_detected: *, is_ransomware_detected: * }>
      }), tags: Array<?({
          ...{ __typename: 'AssetTag' },
        ...$Pick<AssetTag, { id: *, tag_id: *, tag: *, value?: *, imported_external_id?: *, imported_from?: *, imported_from_icon_url?: * }>
      })>, included_api_radar_stats?: ?({
          ...{ __typename: 'AssetIncludedApiRadarStats' },
        ...$Pick<AssetIncludedApiRadarStats, { last_tested_date?: *, active_protection_status?: *, api_authentication_scheme_names?: *, api_protect_api_calls_analyzed_count?: *, has_active_protection_enabled: *, insecure_operations_count: *, secure_operations_count: *, total_operations_count: * }>
      }), included_supply_chain_secure_details?: ?({
          ...{ __typename: 'IncludedSupplyChainSecureDetails' },
        ...$Pick<IncludedSupplyChainSecureDetails, { related_mobile_apps_count: *, related_web_apps_count: * }>,
        ...{ vendor?: ?({
            ...{ __typename: 'IncludedSupplyChainSecureDetailsVendor' },
          ...$Pick<IncludedSupplyChainSecureDetailsVendor, { id: *, icon_url: *, name: * }>
        }) }
      }), included_api_custom_check_details?: ?({
          ...{ __typename: 'AssetIncludedApiCustomCheckDetails' },
        ...$Pick<AssetIncludedApiCustomCheckDetails, { api_operation_exposed_methods?: *, api_operations_count?: *, onboarded_api_custom_check_types?: * }>
      }), included_vendor_management_stats?: ?({
          ...{ __typename: 'AssetIncludedVendorManagementStats' },
        ...$Pick<AssetIncludedVendorManagementStats, { resolved_violations_count: *, wont_fix_violations_count: * }>
      }), included_network_telemetry_stats?: ?({
          ...{ __typename: 'AssetIncludedNetworkTelemetryStats' },
        ...$Pick<AssetIncludedNetworkTelemetryStats, { sources?: *, telemetry_sources?: *, last_event_date: *, open_network_telemetry_policy_violations_count: *, open_urgent_network_telemetry_policy_violations_count: *, open_important_network_telemetry_policy_violations_count: *, open_proactive_network_telemetry_policy_violations_count: * }>
      }), included_v2_fields?: ?({
          ...{ __typename: 'AssetIncludedV2Fields' },
        ...$Pick<AssetIncludedV2Fields, { detected_pii_types: *, is_internet_accessible: *, is_compliant_to_security_policy: * }>
      }) }
    })> }
  }) }
});

export type AssetsAssetsListV2QueryVariables = {
  asset_id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type AssetsAssetsListV2Query = ({
    ...{ __typename: 'Query' },
  ...{ assets_assets_list_v2: ({
      ...{ __typename: 'AssetsAssetsListV2' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), assets: Array<({
        ...{ __typename: 'AssetsAsset' },
      ...$Pick<AssetsAsset, { id: *, asset_type: *, asset_type_icon_url: *, asset_type_name: *, date_created: *, date_no_longer_accessible?: *, discovered_via: *, discovered_via_icon_url: *, discovered_via_name: *, hosted_on: *, hosted_on_icon_url: *, hosted_on_name: *, name: *, open_important_policy_violations_count: *, open_proactive_policy_violations_count: *, open_urgent_policy_violations_count: *, status: *, url?: * }>,
      ...{ belongs_to_asset_group?: ?({
          ...{ __typename: 'AssetGroupField' },
        ...$Pick<AssetGroupField, { id: *, name: * }>
      }) }
    })> }
  }) }
});

export type AssetsPolicyViolationsV2ListQueryVariables = {
  asset_id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type AssetsPolicyViolationsV2ListQuery = ({
    ...{ __typename: 'Query' },
  ...{ assets_policy_violations_list_v2: ({
      ...{ __typename: 'AssetsPolicyViolationsListV2' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolationSummaryResponse' },
      ...$Pick<PolicyViolationSummaryResponse, { id: *, status: *, affected_asset_name: *, affected_asset_date_created?: *, affected_asset_type_name: *, date_created: *, date_resolved?: *, exception_date_created?: * }>,
      ...{ violated_policy_rule: ({
          ...{ __typename: 'PolicyRuleField' },
        ...$Pick<PolicyRuleField, { id: *, relevance: *, status: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleTypeField' },
          ...$Pick<PolicyRuleTypeField, { id: *, title: *, description: *, recommendation: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })> }
        }) }
      }) }
    })> }
  }) }
});

export type AssetTagsQueryVariables = {
  uuid: $ElementType<Scalars, 'ID'>,
};


export type AssetTagsQuery = ({
    ...{ __typename: 'Query' },
  ...{ asset_tags: ({
      ...{ __typename: 'AssetTagsResponse' },
    ...{ asset_tags: Array<({
        ...{ __typename: 'AssetTag' },
      ...$Pick<AssetTag, { id: *, tag_id: *, tag: *, value?: *, imported_external_id?: *, imported_from?: *, imported_from_icon_url?: * }>
    })> }
  }) }
});

export type AssetTagsPutMutationVariables = {
  uuid: $ElementType<Scalars, 'ID'>,
  body: AssetTagPutParams,
};


export type AssetTagsPutMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ asset_tags_put: ({
      ...{ __typename: 'AssetTagsResponse' },
    ...{ asset_tags: Array<({
        ...{ __typename: 'AssetTag' },
      ...$Pick<AssetTag, { id: *, tag_id: *, tag: *, value?: *, imported_external_id?: *, imported_from?: * }>
    })> }
  }) }
});

export type AssetSpecificationFileQueryVariables = {
  uuid: $ElementType<Scalars, 'ID'>,
};


export type AssetSpecificationFileQuery = ({
    ...{ __typename: 'Query' },
  ...{ asset_specification_file: ({
      ...{ __typename: 'AssetSpecificationFileResponse' },
    ...$Pick<AssetSpecificationFileResponse, { content: *, filename: * }>
  }) }
});

export type AssetsSearchFilterValuesQueryVariables = {
  filter_by_search_ui?: ?$ElementType<Scalars, 'String'>,
};


export type AssetsSearchFilterValuesQuery = ({
    ...{ __typename: 'Query' },
  ...{ assets_search_filter_values: ({
      ...{ __typename: 'AssetsSearchFilterValuesResponse' },
    ...{ assets_search_filter_values?: ?Array<?({
        ...{ __typename: 'AssetsSearchFilterValue' },
      ...$Pick<AssetsSearchFilterValue, { belongs_to_filter: *, icon_url: *, name: *, value: *, value_category?: * }>
    })> }
  }) }
});

export type AwsAuthenticatorExternalIdQueryVariables = {};


export type AwsAuthenticatorExternalIdQuery = ({
    ...{ __typename: 'Query' },
  ...{ aws_authenticator_external_id: ({
      ...{ __typename: 'AwsAuthenticatorExternalIdResponse' },
    ...$Pick<AwsAuthenticatorExternalIdResponse, { external_id: * }>
  }) }
});

export type AwsAuthenticatorsCreateMutationMutationVariables = {
  body: AwsAuthenticatorsCreateParams,
};


export type AwsAuthenticatorsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ aws_authenticators_create: ({
      ...{ __typename: 'AwsAuthenticatorsCreateResponse' },
    ...$Pick<AwsAuthenticatorsCreateResponse, { id: *, date_created: *, added_by_user_id: *, role_arn: *, external_id: * }>
  }) }
});

export type AxwayAuthenticatorsCreateMutationMutationVariables = {
  body: AxwayAuthenticatorsCreateParams,
};


export type AxwayAuthenticatorsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ axway_authenticators_create: ({
      ...{ __typename: 'AxwayAuthenticatorsCreateResponse' },
    ...$Pick<AxwayAuthenticatorsCreateResponse, { id: *, date_created: *, added_by_user_id: *, client_id: *, organization_id: * }>
  }) }
});

export type AzureAuthenticatorsCreateMutationMutationVariables = {
  body: AzureAuthenticatorsCreateParams,
};


export type AzureAuthenticatorsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ azure_authenticators_create: ({
      ...{ __typename: 'AzureAuthenticatorsCreateResponse' },
    ...$Pick<AzureAuthenticatorsCreateResponse, { id: *, date_created: *, added_by_user_id: *, tenant_id: *, client_id: * }>
  }) }
});

export type BreakGlassStatsQueryVariables = {};


export type BreakGlassStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ break_glass_stats: ({
      ...{ __typename: 'BreakGlassStatsResponse' },
    ...{ break_glass_stats_field: ({
        ...{ __typename: 'BreakGlassStatsField' },
      ...$Pick<BreakGlassStatsField, { secret_management_assets_count: *, unauthenticated_secret_management_assets_count: * }>,
      ...{ aws_stats: Array<?({
          ...{ __typename: 'SecretsResourceCategoryStatsField' },
        ...$Pick<SecretsResourceCategoryStatsField, { asset_type_icon_url: *, asset_type_name: *, cloud_provider: *, resource_type: *, secret_management_assets_count: *, unauthenticated_secret_management_assets_count: * }>
      })>, azure_stats: Array<?({
          ...{ __typename: 'SecretsResourceCategoryStatsField' },
        ...$Pick<SecretsResourceCategoryStatsField, { asset_type_icon_url: *, asset_type_name: *, cloud_provider: *, resource_type: *, secret_management_assets_count: *, unauthenticated_secret_management_assets_count: * }>
      })>, gcp_stats: Array<?({
          ...{ __typename: 'SecretsResourceCategoryStatsField' },
        ...$Pick<SecretsResourceCategoryStatsField, { asset_type_icon_url: *, asset_type_name: *, cloud_provider: *, resource_type: *, secret_management_assets_count: *, unauthenticated_secret_management_assets_count: * }>
      })> }
    }) }
  }) }
});

export type BulkActionMoveToAssetGroupListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_status?: ?BulkActionsStatusEnum,
};


export type BulkActionMoveToAssetGroupListQuery = ({
    ...{ __typename: 'Query' },
  ...{ bulk_action_move_to_asset_group_list: ({
      ...{ __typename: 'BulkMoveToAssetGroupListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), bulk_actions?: ?Array<({
        ...{ __typename: 'BulkAssetMoveToAssetGroup' },
      ...$Pick<BulkAssetMoveToAssetGroup, { action_type?: *, completed_tasks_count: *, date_completed?: *, date_created: *, id: *, move_to_group_result?: *, secure_share_policy_violations_result?: *, status?: *, total_tasks_count: * }>
    })> }
  }) }
});

export type BulkActionMoveToAssetGroupCreateMutationVariables = {
  body: BulkActionMoveToAssetGroupParams,
};


export type BulkActionMoveToAssetGroupCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ bulk_action_move_to_asset_group_create: ({
      ...{ __typename: 'BulkActionResponse' },
    ...$Pick<BulkActionResponse, { action_type?: *, status?: * }>
  }) }
});

export type ShadowAssetsTriageActionsMutationVariables = {
  body?: ?ShadowAssetsTriageActionsParams,
};


export type ShadowAssetsTriageActionsMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ shadow_assets_triage_actions: ({
      ...{ __typename: 'ShadowAssetsTriageActionsResponse' },
    ...$Pick<ShadowAssetsTriageActionsResponse, { action_type?: *, status?: * }>
  }) }
});

export type CloudAuthenticatorsListQueryVariables = {
  filter_by_authenticator?: ?Array<?CloudAuthenticatorType>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type CloudAuthenticatorsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_authenticators_list: ({
      ...{ __typename: 'CloudAuthenticatorsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), cloud_authenticators: Array<({
        ...{ __typename: 'CloudAuthenticator' },
      ...$Pick<CloudAuthenticator, { authenticator_category: *, authenticator_type: *, cloud_account_id: *, customer_supplied_name?: *, access_error_reason?: *, date_created: *, id: *, status: * }>,
      ...{ aws_credential_field?: ?({
          ...{ __typename: 'AwsCredentialSummaryField' },
        ...$Pick<AwsCredentialSummaryField, { has_policy_to_support_api_auto_protect?: * }>
      }) }
    })> }
  }) }
});

export type UpdateCloudAuthenticatorMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: CloudAuthenticatorUpdateParams,
};


export type UpdateCloudAuthenticatorMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ cloud_authenticator_update: ({
      ...{ __typename: 'CloudAuthenticator' },
    ...$Pick<CloudAuthenticator, { authenticator_category: *, authenticator_type: *, cloud_account_id: *, customer_supplied_name?: *, date_created: *, id: *, status: * }>,
    ...{ aws_credential_field?: ?({
        ...{ __typename: 'AwsCredentialSummaryField' },
      ...$Pick<AwsCredentialSummaryField, { has_policy_to_support_api_auto_protect?: * }>
    }) }
  }) }
});

export type CreateCloudAuthenticatorMutationVariables = {
  body: CloudAuthenticatorCreateParams,
};


export type CreateCloudAuthenticatorMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ create_cloud_authenticator: ({
      ...{ __typename: 'CreateCloudAuthenticatorResponse' },
    ...$Pick<CreateCloudAuthenticatorResponse, { authenticator_category: *, authenticator_type: *, cloud_account_id: *, customer_supplied_name?: *, date_created?: *, id: *, status: * }>
  }) }
});

export type CloudDlpStatsGetQueryVariables = {};


export type CloudDlpStatsGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_dlp_stats: ({
      ...{ __typename: 'CloudDlpStatsGetResponse' },
    ...{ dlp_stats_field: ({
        ...{ __typename: 'CloudDlpStatsField' },
      ...$Pick<CloudDlpStatsField, { assets_count: *, total_assets_count_weekly_trend: *, authenticated_assets_count_weekly_trend: *, unauthenticated_by_design_assets_count: *, unauthenticated_assets_count_weekly_trend: *, unauthenticated_assets_count: *, sensitive_data_leaking_assets_count: *, sensitive_data_leaking_assets_count_weekly_trend: * }>,
      ...{ aws_assets_stats: Array<({
          ...{ __typename: 'CloudDlpStatsFieldStat' },
        ...$Pick<CloudDlpStatsFieldStat, { resource_type: *, assets_count: *, unauthenticated_assets_count?: *, sensitive_data_leaking_assets_count?: *, asset_type_icon_url: *, asset_type_name: * }>
      })>, gcp_assets_stats: Array<({
          ...{ __typename: 'CloudDlpStatsFieldStat' },
        ...$Pick<CloudDlpStatsFieldStat, { resource_type: *, assets_count: *, unauthenticated_assets_count?: *, sensitive_data_leaking_assets_count?: *, asset_type_icon_url: *, asset_type_name: * }>
      })>, azure_assets_stats: Array<({
          ...{ __typename: 'CloudDlpStatsFieldStat' },
        ...$Pick<CloudDlpStatsFieldStat, { resource_type: *, assets_count: *, unauthenticated_assets_count?: *, sensitive_data_leaking_assets_count?: *, asset_type_icon_url: *, asset_type_name: * }>
      })>, other_assets_stats: Array<({
          ...{ __typename: 'CloudDlpStatsFieldStat' },
        ...$Pick<CloudDlpStatsFieldStat, { resource_type: *, assets_count: *, unauthenticated_assets_count?: *, sensitive_data_leaking_assets_count?: *, asset_type_icon_url: *, asset_type_name: * }>
      })> }
    }) }
  }) }
});

export type CloudDlpToolkitConfigDataGetQueryVariables = {};


export type CloudDlpToolkitConfigDataGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_dlp_toolkit_config_data_get: ({
      ...{ __typename: 'CloudDlpToolkitConfigDataGetResponse' },
    ...{ supported_aws_cloud_resource_types: Array<({
        ...{ __typename: 'CloudDlpToolkitConfigDataCloudResourceType' },
      ...$Pick<CloudDlpToolkitConfigDataCloudResourceType, { cloud_resource_type: *, cloud_resource_type_display_name: *, cloud_resource_type_icon_url: * }>
    })>, supported_gcp_cloud_resource_types: Array<({
        ...{ __typename: 'CloudDlpToolkitConfigDataCloudResourceType' },
      ...$Pick<CloudDlpToolkitConfigDataCloudResourceType, { cloud_resource_type: *, cloud_resource_type_display_name: *, cloud_resource_type_icon_url: * }>
    })>, supported_azure_cloud_resource_types: Array<({
        ...{ __typename: 'CloudDlpToolkitConfigDataCloudResourceType' },
      ...$Pick<CloudDlpToolkitConfigDataCloudResourceType, { cloud_resource_type: *, cloud_resource_type_display_name: *, cloud_resource_type_icon_url: * }>
    })>, supported_other_cloud_resource_types: Array<({
        ...{ __typename: 'CloudDlpToolkitConfigDataCloudResourceType' },
      ...$Pick<CloudDlpToolkitConfigDataCloudResourceType, { cloud_resource_type: *, cloud_resource_type_display_name: *, cloud_resource_type_icon_url: * }>
    })>, google_pii_types: Array<({
        ...{ __typename: 'CloudDlpToolkitConfigDataGooglePiiTypes' },
      ...$Pick<CloudDlpToolkitConfigDataGooglePiiTypes, { data_type: *, display_name: * }>
    })> }
  }) }
});

export type CloudRansomwareStatsQueryVariables = {};


export type CloudRansomwareStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_ransomware_stats: ({
      ...{ __typename: 'CloudRansomwareStats' },
    ...{ ransomware_stats_field: ({
        ...{ __typename: 'RansomwareStatsField' },
      ...{ cloud_storage_ransomware_vulnerability_stats?: ?Array<?({
          ...{ __typename: 'CloudStorageRansomwareVulnerabilityStats' },
        ...$Pick<CloudStorageRansomwareVulnerabilityStats, { asset_uuid?: *, cloud_resource_type?: *, vulnerable_to_ransomware?: *, malware_found?: * }>
      })>, last_scan_stats: ({
          ...{ __typename: 'RansomwareLastScanStats' },
        ...$Pick<RansomwareLastScanStats, { malware_detected_count: *, public_readable_storage_count: *, public_writable_storage_count: *, ransomware_detected_count: *, scanned_cloud_storage_count: *, scanned_directories_count: *, scanned_files_count: * }>
      }) }
    }) }
  }) }
});

export type CloudResourceDetailsQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type CloudResourceDetailsQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_resource_details: ({
      ...{ __typename: 'CloudResourceDetailsResponse' },
    ...{ asset_groups: Array<({
        ...{ __typename: 'AssetGroups' },
      ...$Pick<AssetGroups, { id: * }>
    })>, cloud_resources: Array<({
        ...{ __typename: 'CloudResource' },
      ...$Pick<CloudResource, { id: *, date_created: *, name: *, region?: *, resource_type: *, asset_type_icon_url: *, asset_type_name: *, runtime?: *, status: *, date_no_longer_accessible?: *, discovered_via: *, hosted_on?: *, cloud_console_url: *, url?: * }>,
      ...{ policy_violations: Array<?({
          ...{ __typename: 'PolicyViolation' },
        ...$Pick<PolicyViolation, { id: *, status: * }>,
        ...{ violated_policy_rule: ({
            ...{ __typename: 'PolicyRule' },
          ...$Pick<PolicyRule, { id: *, relevance: * }>,
          ...{ policy_rule_type: ({
              ...{ __typename: 'PolicyRuleType' },
            ...$Pick<PolicyRuleType, { id: *, title: * }>
          }) }
        }) }
      })> }
    })>, policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, status: *, additional_info?: *, date_created: *, date_resolved?: *, exception_type?: *, exception_date_created?: *, affected_cloud_resource_id?: * }>,
      ...{ violated_policy_rule: ({
          ...{ __typename: 'PolicyRule' },
        ...$Pick<PolicyRule, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: *, title: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })> }
        }) }
      }), affected_network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, asset_type_name: * }>,
        ...{ domain_name: ({
            ...{ __typename: 'DomainName' },
          ...$Pick<DomainName, { id: *, name: * }>
        }) }
      }), affected_cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { id: *, asset_type_name: *, url?: * }>
      }) }
    })>, restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>
    })>, network_services: Array<({
        ...{ __typename: 'NetworkService' },
      ...$Pick<NetworkService, { id: *, url: *, hosted_on?: *, asset_type_icon_url: *, asset_type_name: * }>
    })>, web_applications: Array<({
        ...{ __typename: 'WebApplication' },
      ...$Pick<WebApplication, { id: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>
    })>, mobile_applications: Array<({
        ...{ __typename: 'MobileApp' },
      ...$Pick<MobileApp, { id: * }>
    })>, events: Array<({
        ...{ __typename: 'Event' },
      ...$Pick<Event, { id: * }>
    })>, policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: * }>
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: * }>
    })> }
  }) }
});

export type CloudResourceDetailsVersion2QueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type CloudResourceDetailsVersion2Query = ({
    ...{ __typename: 'Query' },
  ...{ cloud_resource_details_v2: ({
      ...{ __typename: 'CloudResourceV2' },
    ...$Pick<CloudResourceV2, { id: *, date_created: *, name: *, region?: *, resource_type: *, asset_type_icon_url: *, asset_type_name: *, runtime?: *, status: *, date_no_longer_accessible?: *, discovered_via: *, hosted_on?: *, cloud_console_url?: *, url?: *, open_urgent_policy_violations_count?: *, open_important_policy_violations_count?: *, open_proactive_policy_violations_count?: * }>,
    ...{ belongs_to_cloud_account?: ?({
        ...{ __typename: 'BelongsToCloudAccount' },
      ...$Pick<BelongsToCloudAccount, { id: *, cloud_account_icon_url: *, cloud_account_type_name: *, discovered_via_cloud_authenticator_id: *, customer_supplied_name?: * }>
    }), connected_assets: Array<({
        ...{ __typename: 'WebApplicationConnectedAsset' },
      ...$Pick<WebApplicationConnectedAsset, { id: *, name: *, url: *, asset_type: *, asset_type_name: *, asset_type_icon_url: * }>
    })> }
  }) }
});

export type CloudResourcesListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
  filter_by_discovered_via?: ?$ElementType<Scalars, 'String'>,
  filter_by_domain_name_id?: ?$ElementType<Scalars, 'ID'>,
  filter_by_cloud_provider?: ?Array<?HostedOn>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_category?: ?Array<?FilterByCategoryEnum>,
  order_by?: ?CloudResourceOrderBy,
};


export type CloudResourcesListQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_resource_list: ({
      ...{ __typename: 'CloudResourceListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), cloud_resources: Array<({
        ...{ __typename: 'CloudResource' },
      ...$Pick<CloudResource, { id: *, url?: *, name: *, status: *, date_no_longer_accessible?: *, hosted_on?: *, runtime?: *, region?: *, resource_type: *, asset_type_name: *, asset_type_icon_url: *, date_created: *, cloud_console_url: *, discovered_via: *, aws_arn?: *, gcp_project_id?: *, mulesoft_domain?: *, azure_tenant_id?: *, axway_org_id?: *, kong_workspace_name?: *, open_urgent_policy_violations_count?: *, open_important_policy_violations_count?: *, open_proactive_policy_violations_count?: *, resource_type_icon_url?: * }>,
      ...{ included_break_glass_stats?: ?({
          ...{ __typename: 'CloudResourceIncludedBreakGlassStats' },
        ...$Pick<CloudResourceIncludedBreakGlassStats, { unauthenticated_policy_violation_id?: * }>,
        ...{ remediation_field?: ?({
            ...{ __typename: 'CloudResourceIncludedBreakGlassStatsRemediationField' },
          ...$Pick<CloudResourceIncludedBreakGlassStatsRemediationField, { remediation_instructions: *, remediation_url: * }>
        }) }
      }), included_code_red_stats?: ?({
          ...{ __typename: 'CloudResourceIncludedCodeRedStats' },
        ...$Pick<CloudResourceIncludedCodeRedStats, { unauthenticated_policy_violation_id?: * }>
      }), included_cloud_dlp_stats?: ?({
          ...{ __typename: 'CloudResourceIncludedCloudDlpStats' },
        ...$Pick<CloudResourceIncludedCloudDlpStats, { pii_leaking_policy_violation_id?: *, pii_leaking_policy_violation_status?: *, unauthenticated_policy_violation_id?: *, unauthenticated_policy_violation_status?: * }>
      }), included_cloud_ransomware_stats?: ?({
          ...{ __typename: 'CloudRansomwareStatsField' },
        ...$Pick<CloudRansomwareStatsField, { malware_detected_policy_violation_uuid?: *, new_directories_since_last_week?: *, new_directories_since_last_week_percentage?: *, new_edits_since_last_week?: *, new_files_since_last_week?: *, new_files_since_last_week_percentage?: *, ransomware_detected_policy_violation_uuid?: *, readable_storage_policy_violation_uuid?: *, writable_storage_policy_violation_uuid?: *, total_directories_count?: *, total_files_count?: *, scan_run_id?: *, last_scan_date?: * }>
      }) }
    })> }
  }) }
});

export type CloudResourceDetailsPolicyViolationsListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type CloudResourceDetailsPolicyViolationsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_resource_details_policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponseVersion2' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolationV2' },
      ...$Pick<PolicyViolationV2, { id: *, date_created: *, date_resolved?: *, exception_type?: *, additional_info?: *, status: *, exception_date_created?: *, exception_explanation?: * }>,
      ...{ affected_asset: ({
          ...{ __typename: 'PolicyViolationAffectedAsset' },
        ...$Pick<PolicyViolationAffectedAsset, { id: *, name?: *, url?: *, asset_type: *, asset_type_icon_url: *, asset_type_name: *, date_created: *, hosted_on?: *, aws_arn?: * }>,
        ...{ belongs_to_cloud_account?: ?({
            ...{ __typename: 'BelongsToCloudAccount' },
          ...$Pick<BelongsToCloudAccount, { id: *, cloud_account_icon_url: *, cloud_account_type_name: *, discovered_via_cloud_authenticator_id: *, customer_supplied_name?: * }>
        }) }
      }), violated_policy_rule: ({
          ...{ __typename: 'PolicyRuleV2' },
        ...$Pick<PolicyRuleV2, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: *, title: *, description: *, recommendation?: *, default_relevance?: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })>, related_rule_types?: ?Array<({
              ...{ __typename: 'PolicyRuleTypeRelatedRuleType' },
            ...$Pick<PolicyRuleTypeRelatedRuleType, { id: *, title: *, description: *, recommendation?: *, default_relevance?: * }>,
            ...{ compliance_policy_references: Array<({
                ...{ __typename: 'PolicyComplianceReference' },
              ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
            })> }
          })> }
        }) }
      }) }
    })> }
  }) }
});

export type ComplianceStandardCriteriaListQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type ComplianceStandardCriteriaListQuery = ({
    ...{ __typename: 'Query' },
  ...{ compliance_standard_criteria_list: ({
      ...{ __typename: 'ComplianceStandardCriteriaListResponse' },
    ...{ compliance_standard_criteria: Array<({
        ...{ __typename: 'ComplianceStandardCriteria' },
      ...$Pick<ComplianceStandardCriteria, { compliance_standard: *, criterion: *, description: *, title: *, link?: *, referenced_by_policy_rule_type_ids: * }>
    })> }
  }) }
});

export type ConfigurationsShadowGetQueryVariables = {};


export type ConfigurationsShadowGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ configurations_shadow_get: ({
      ...{ __typename: 'ConfigurationsShadowResponse' },
    ...{ configuration: ({
        ...{ __typename: 'ConfigurationsShadowResponseConfiguration' },
      ...$Pick<ConfigurationsShadowResponseConfiguration, { approved_asset_types: *, approved_cloud_providers: *, approved_app_stores: *, public_asm_enabled: *, should_mark_new_apis_not_going_through_gateway: *, should_mark_new_assets_except_on_approved_asset_types: *, should_mark_new_cloud_resources_except_on_approved_cloud_providers: *, should_mark_new_mobile_apps_on_3rd_party_stores: *, should_mark_new_mobile_apps_on_unofficial_app_stores: *, should_mark_new_mobile_apps_except_on_approved_app_stores: *, should_mark_new_mobile_apps_on_official_stores: *, should_mark_new_network_services_with_dev_in_url: *, should_mark_new_network_services_with_preprod_in_url: *, should_mark_new_network_services_with_uat_in_url: * }>
    }) }
  }) }
});

export type ConfigurationsShadowPutMutationVariables = {
  body: ConfigurationsShadowPutParams,
};


export type ConfigurationsShadowPutMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ configurations_shadow_put?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type CustomCheckTypesGetQueryVariables = {
  product_type: $ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type CustomCheckTypesGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ custom_check_types_list: ({
      ...{ __typename: 'CustomCheckTypesResponse' },
    ...{ custom_check_types?: ?Array<({
        ...{ __typename: 'CustomCheckType' },
      ...$Pick<CustomCheckType, { custom_check_type: *, display_name: *, onboarded_assets_count: *, open_policy_violations_count: *, subtitle?: * }>
    })>, pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: *, next_cursor?: * }>
    }) }
  }) }
});

export type CustomCheckStatsGetQueryVariables = {
  product_type: $ElementType<Scalars, 'String'>,
};


export type CustomCheckStatsGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ custom_checks_stats_get: ({
      ...{ __typename: 'CustomCheckStatsResponse' },
    ...$Pick<CustomCheckStatsResponse, { assets_count: *, assets_with_onboarded_checks_count: *, weekly_assets_with_onboarded_checks_trend: *, weekly_custom_checks_scans_count: *, weekly_custom_checks_scans_trend: *, weekly_failed_custom_checks_scans_count: *, weekly_failed_custom_checks_scans_trend: *, weekly_passed_custom_checks_scans_count: *, weekly_passed_custom_checks_scans_trend: * }>
  }) }
});

export type CustomChecksCreateMutationVariables = {
  product_type: $ElementType<Scalars, 'String'>,
  body?: ?CustomChecksRequestParams,
};


export type CustomChecksCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ custom_checks_requests: ({
      ...{ __typename: 'CustomChecksRequestsResponse' },
    ...$Pick<CustomChecksRequestsResponse, { description: * }>
  }) }
});

export type DetectedSpaCredentialsListQueryQueryVariables = {};


export type DetectedSpaCredentialsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ detected_spa_credentials_list: ({
      ...{ __typename: 'DetectedSpaCredentialsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }), detected_spa_credentials: Array<({
        ...{ __typename: 'DetectedSpaCredential' },
      ...$Pick<DetectedSpaCredential, { id: *, web_application_id: *, found_at_url: *, credential_as_markdown: *, exported_to_policy_violation_id?: *, triaging_decision: *, value: * }>,
      ...{ used_in_request: ({
          ...{ __typename: 'DetectedSpaCredentialUsedIn' },
        ...$Pick<DetectedSpaCredentialUsedIn, { header_name: *, header_value: *, method: *, url: * }>
      }) }
    })> }
  }) }
});

export type DomainNameListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?SearchDomainNamesIncludeEnum,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_parent_domain_name_id?: ?$ElementType<Scalars, 'String'>,
  include_parent_domains_only?: ?$ElementType<Scalars, 'Boolean'>,
};


export type DomainNameListQuery = ({
    ...{ __typename: 'Query' },
  ...{ domain_name_list: ({
      ...{ __typename: 'DomainNameListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), domain_names: Array<({
        ...{ __typename: 'DomainName' },
      ...$Pick<DomainName, { id: *, name: *, discovered_via: *, date_created: *, asset_type_icon_url?: *, asset_type_name?: * }>,
      ...{ included_code_red_stats?: ?({
          ...{ __typename: 'IncludedCodeRedStats' },
        ...$Pick<IncludedCodeRedStats, { fingerprinted_resources_count?: *, unauthenticated_fingerprinted_resources_count?: * }>
      }), included_discovered_urls_stats?: ?({
          ...{ __typename: 'DiscoveredUrlsStats' },
        ...$Pick<DiscoveredUrlsStats, { discovered_urls_count: *, subdomains_count?: * }>
      }) }
    })> }
  }) }
});

export type DomainNamesDiscoveredUrlsListQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type DomainNamesDiscoveredUrlsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ domain_names_discovered_urls_list: ({
      ...{ __typename: 'DomainNamesDiscoveredUrlsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), discovered_urls: Array<({
        ...{ __typename: 'DomainNameDiscoveredUrl' },
      ...$Pick<DomainNameDiscoveredUrl, { normalized_url: * }>
    })> }
  }) }
});

export type DomainNamesStatsQueryVariables = {};


export type DomainNamesStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ domain_names_stats: ({
      ...{ __typename: 'DomainNamesStatsResponse' },
    ...{ fingerprinted_resource_stats_field: ({
        ...{ __typename: 'FingerprintedResourceStatsField' },
      ...{ fingerprinted_resources_categories_stats: Array<({
          ...{ __typename: 'DomanNamesFingerprintedResourceCategoriesStats' },
        ...$Pick<DomanNamesFingerprintedResourceCategoriesStats, { fingerprinted_resource_category?: *, fingerprinted_resources_count?: *, unauthenticated_fingerprinted_resources_count?: *, asset_type_icon_url: *, asset_type_name: * }>
      })>, last_scan_stats: ({
          ...{ __typename: 'DomainNamesLastScanStats' },
        ...$Pick<DomainNamesLastScanStats, { scanned_domain_names_count?: *, fingerprinted_resources_count?: *, unauthenticated_fingerprinted_resources_count?: *, date_since_last_breached?: * }>
      }) }
    }) }
  }) }
});

export type FileUploadsGetQueryVariables = {
  hash?: ?$ElementType<Scalars, 'String'>,
};


export type FileUploadsGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ file_uploads_get: ({
      ...{ __typename: 'FileUploadsResponse' },
    ...$Pick<FileUploadsResponse, { file_sha1_hash: *, file_type: *, download_url?: *, original_file_name: *, date_created: *, created_by_actor_name: *, status: *, uploader_comment?: *, processing_completion_message?: *, file_type_display_name: *, file_type_icon_url?: * }>
  }) }
});

export type FileUploadsListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_file_type?: ?Array<?$ElementType<Scalars, 'String'>>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type FileUploadsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ file_uploads_list: ({
      ...{ __typename: 'FileUploadsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), file_uploads: Array<({
        ...{ __typename: 'FileUploadsResponse' },
      ...$Pick<FileUploadsResponse, { file_sha1_hash: *, file_type: *, download_url?: *, original_file_name: *, date_created: *, created_by_actor_name: *, status: *, uploader_comment?: *, processing_completion_message?: *, file_type_display_name: *, file_type_icon_url?: * }>,
      ...{ api_custom_checks_stats?: ?({
          ...{ __typename: 'FileUploadApiCustomCheckStatsResponse' },
        ...$Pick<FileUploadApiCustomCheckStatsResponse, { apis_with_onboarded_custom_check_count: *, custom_checks_count: *, failed_custom_checks_count?: *, passed_custom_checks_count?: * }>
      }) }
    })> }
  }) }
});

export type GcpAuthenticatorsCreateMutationMutationVariables = {
  body: GcpAuthenticatorsCreateParams,
};


export type GcpAuthenticatorsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ gcp_authenticators_create: ({
      ...{ __typename: 'GcpAuthenticatorsCreateResponse' },
    ...$Pick<GcpAuthenticatorsCreateResponse, { id: *, date_created: *, added_by_user_id: *, organization_id: *, client_email: * }>
  }) }
});

export type GraphqlApiDetailsQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type GraphqlApiDetailsQuery = ({
    ...{ __typename: 'Query' },
  ...{ graphql_api_details: ({
      ...{ __typename: 'GraphqlApiDetailsResponse' },
    ...{ asset_groups: Array<({
        ...{ __typename: 'AssetGroups' },
      ...$Pick<AssetGroups, { id: * }>
    })>, domain_names: Array<({
        ...{ __typename: 'DomainName' },
      ...$Pick<DomainName, { id: * }>
    })>, events: Array<({
        ...{ __typename: 'Event' },
      ...$Pick<Event, { id: * }>
    })>, graphql_apis: Array<({
        ...{ __typename: 'GraphqlApi' },
      ...$Pick<GraphqlApi, { asset_type_icon_url: *, asset_type_name: *, aws_arn?: *, azure_tenant_id?: *, date_created: *, discovered_via: *, discovered_via_actor_id?: *, discovered_via_apigee_authenticator_id?: *, discovered_via_apigee_on_prem_authenticator_id?: *, discovered_via_aws_authenticator_id?: *, discovered_via_axway_authenticator_id?: *, discovered_via_azure_authenticator_id?: *, discovered_via_cloud_authenticator_id?: *, discovered_via_gcp_authenticator_id?: *, discovered_via_kong_authenticator_id?: *, discovered_via_mulesoft_authenticator_id?: *, discovered_via_user_id?: *, gcp_project_id?: *, hosted_on: *, id: *, mulesoft_asset_id?: *, network_service_id?: *, policy_violation_ids: *, status: *, url: *, graphql_schema?: * }>
    })>, mobile_applications: Array<({
        ...{ __typename: 'MobileApp' },
      ...$Pick<MobileApp, { id: * }>
    })>, network_services: Array<({
        ...{ __typename: 'NetworkService' },
      ...$Pick<NetworkService, { id: *, url: *, hosted_on?: *, asset_type_icon_url: *, asset_type_name: * }>
    })>, policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: * }>
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: * }>
    })>, policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, status: *, additional_info?: *, date_created: *, date_resolved?: *, exception_type?: *, exception_date_created?: *, affected_cloud_resource_id?: * }>,
      ...{ violated_policy_rule: ({
          ...{ __typename: 'PolicyRule' },
        ...$Pick<PolicyRule, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: *, title: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })> }
        }) }
      }), affected_network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, asset_type_name: * }>,
        ...{ domain_name: ({
            ...{ __typename: 'DomainName' },
          ...$Pick<DomainName, { id: *, name: * }>
        }) }
      }), affected_cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { id: *, asset_type_name: *, url?: * }>
      }) }
    })>, web_applications: Array<({
        ...{ __typename: 'WebApplication' },
      ...$Pick<WebApplication, { id: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>
    })> }
  }) }
});

export type GraphqlApisListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_hosted_on?: ?HostedOn,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
};


export type GraphqlApisListQuery = ({
    ...{ __typename: 'Query' },
  ...{ graphql_api_list: ({
      ...{ __typename: 'GraphqlApiListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), graphql_apis: Array<({
        ...{ __typename: 'GraphqlApi' },
      ...$Pick<GraphqlApi, { asset_type_icon_url: *, asset_type_name: *, aws_arn?: *, azure_tenant_id?: *, date_created: *, discovered_via: *, discovered_via_actor_id?: *, discovered_via_apigee_authenticator_id?: *, discovered_via_apigee_on_prem_authenticator_id?: *, discovered_via_aws_authenticator_id?: *, discovered_via_axway_authenticator_id?: *, discovered_via_azure_authenticator_id?: *, discovered_via_cloud_authenticator_id?: *, discovered_via_gcp_authenticator_id?: *, discovered_via_kong_authenticator_id?: *, discovered_via_mulesoft_authenticator_id?: *, discovered_via_user_id?: *, gcp_project_id?: *, hosted_on: *, id: *, mulesoft_asset_id?: *, network_service_id?: *, policy_violation_ids: *, status: *, url: * }>
    })> }
  }) }
});

export type GraphqlApiScanTracesQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type GraphqlApiScanTracesQuery = ({
    ...{ __typename: 'Query' },
  ...{ graphql_api_scan_traces: ({
      ...{ __typename: 'GraphqlApiScanTracesResponse' },
    ...{ unauthenticated_scan_trace?: ?({
        ...{ __typename: 'GraphqlApiScanTrace' },
      ...$Pick<GraphqlApiScanTrace, { query?: *, response?: * }>
    }), authenticated_scan_trace?: ?({
        ...{ __typename: 'GraphqlApiScanTrace' },
      ...$Pick<GraphqlApiScanTrace, { query?: *, response?: * }>
    }) }
  }) }
});

export type GrpcMethodsListQueryVariables = {
  grpc_service_id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  include_api_radar_stats?: ?$ElementType<Scalars, 'Boolean'>,
};


export type GrpcMethodsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ grpc_methods_list: ({
      ...{ __typename: 'GrpcOperationsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), grpc_methods: Array<({
        ...{ __typename: 'GrpcOperation' },
      ...$Pick<GrpcOperation, { open_urgent_policy_violations_count: *, open_important_policy_violations_count: *, open_proactive_policy_violations_count: *, id: *, name: *, grpc_service_id: *, grpc_service_name: *, grpc_method_type_display_name: *, grpc_method_name: *, status: *, asset_type_icon_url: *, asset_type_name: *, date_created: *, grpc_method_type: * }>,
      ...{ included_api_radar_stats?: ?({
          ...{ __typename: 'V2ApiOperationApiRadarStats' },
        ...$Pick<V2ApiOperationApiRadarStats, { detect_and_inject_violation_id?: *, hack_and_extract_violation_id?: *, leaky_apis_violation_id?: *, ssrf_violation_id?: * }>
      }) }
    })> }
  }) }
});

export type HeadlinerHacksStatsListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_vendor_scs_only?: ?$ElementType<Scalars, 'Boolean'>,
};


export type HeadlinerHacksStatsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ headliner_hacks_stats_list: ({
      ...{ __typename: 'HeadlinerHacksStatsResponse' },
    ...{ headliner_hacks_stats: Array<?({
        ...{ __typename: 'HeadlinerHacksStats' },
      ...$Pick<HeadlinerHacksStats, { assets_could_be_affected_count: *, assets_not_affected_count: *, headliner_hack_icon_url: *, headliner_hack_title: *, open_policy_violations_count: *, relevant_policy_rule_type_ids: * }>
    })>, pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }) }
  }) }
});

export type IacScanGetQueryVariables = {
  id: $ElementType<Scalars, 'String'>,
};


export type IacScanGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ iac_scans_get: ({
      ...{ __typename: 'IacScanGetResponse' },
    ...$Pick<IacScanGetResponse, { id: *, status: *, scan_type: *, date_scanned: *, issues_count: *, result_as_markdown: * }>,
    ...{ files_scanned: Array<?({
        ...{ __typename: 'IacScanGetResponseFilesScanned' },
      ...$Pick<IacScanGetResponseFilesScanned, { original_file_name: *, download_url: * }>
    })> }
  }) }
});

export type IacScanListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_scan_type: IacScanFilterEnum,
};


export type IacScanListQuery = ({
    ...{ __typename: 'Query' },
  ...{ iac_scans: ({
      ...{ __typename: 'IacScanFilterResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), iac_scans?: ?Array<({
        ...{ __typename: 'IacScan' },
      ...$Pick<IacScan, { id: *, date_created: *, status: *, scan_type: *, scanned_files_name?: *, issue_count: * }>
    })> }
  }) }
});

export type IacScanStatsQueryVariables = {};


export type IacScanStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ iac_scans_stats: ({
      ...{ __typename: 'IacScanStatsResponse' },
    ...$Pick<IacScanStatsResponse, { file_uploads_count: *, completed_scans_count: *, total_issues_count: * }>
  }) }
});

export type InventoryStatsGetQueryQueryVariables = {};


export type InventoryStatsGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ inventory_stats_get: ({
      ...{ __typename: 'InventoryStatsResponse' },
    ...{ inventory_stats: Array<({
        ...{ __typename: 'InventoryStats' },
      ...{ all_assets_stats: ({
          ...{ __typename: 'AllAssetsStats' },
        ...{ network_services_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), restful_apis_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), web_applications_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), cloud_resources_stats: ({
            ...{ __typename: 'CloudResourcesInventoryStats' },
          ...$Pick<CloudResourcesInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: *, serverless_assets_count: *, shadow_serverless_assets_count: * }>
        }) }
      }) }
    })> }
  }) }
});

export type InventoryStatsByDateQueryVariables = {
  reference_date?: ?$ElementType<Scalars, 'String'>,
};


export type InventoryStatsByDateQuery = ({
    ...{ __typename: 'Query' },
  ...{ inventory_stats_reference_date_get: ({
      ...{ __typename: 'InventoryStatsResponse' },
    ...{ inventory_stats: Array<({
        ...{ __typename: 'InventoryStats' },
      ...{ all_assets_stats: ({
          ...{ __typename: 'AllAssetsStats' },
        ...{ network_services_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { shadow_assets_count: * }>
        }), restful_apis_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { shadow_assets_count: * }>
        }), web_applications_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { shadow_assets_count: * }>
        }), cloud_resources_stats: ({
            ...{ __typename: 'CloudResourcesInventoryStats' },
          ...$Pick<CloudResourcesInventoryStats, { shadow_assets_count: *, shadow_serverless_assets_count: * }>
        }) }
      }), aws_assets_stats: ({
          ...{ __typename: 'AllAssetsStats' },
        ...{ network_services_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), restful_apis_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), web_applications_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), cloud_resources_stats: ({
            ...{ __typename: 'CloudResourcesInventoryStats' },
          ...$Pick<CloudResourcesInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }) }
      }), gcp_assets_stats: ({
          ...{ __typename: 'AllAssetsStats' },
        ...{ network_services_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), restful_apis_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), web_applications_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), cloud_resources_stats: ({
            ...{ __typename: 'CloudResourcesInventoryStats' },
          ...$Pick<CloudResourcesInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }) }
      }), azure_assets_stats: ({
          ...{ __typename: 'AllAssetsStats' },
        ...{ network_services_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), restful_apis_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), web_applications_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), cloud_resources_stats: ({
            ...{ __typename: 'CloudResourcesInventoryStats' },
          ...$Pick<CloudResourcesInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }) }
      }) }
    })> }
  }) }
});

export type KongAuthenticatorsCreateMutationMutationVariables = {
  body: KongAuthenticatorsCreateParams,
};


export type KongAuthenticatorsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ kong_authenticators_create: ({
      ...{ __typename: 'KongAuthenticatorsCreateResponse' },
    ...$Pick<KongAuthenticatorsCreateResponse, { id: *, date_created: *, added_by_user_id: *, kong_admin_subdomain: * }>
  }) }
});

export type ApiRadarMetricsQueryVariables = {};


export type ApiRadarMetricsQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_radar_metrics: ({
      ...{ __typename: 'MetricsResponse' },
    ...{ metrics: Array<({
        ...{ __typename: 'Metrics' },
      ...{ api_radar_metrics?: ?({
          ...{ __typename: 'ApiRadarMetrics' },
        ...$Pick<ApiRadarMetrics, { restful_apis_count: *, api_operations_count: *, graphql_apis_count: *, leaky_apis_count: *, detect_and_inject_count: *, hack_and_extract_count: *, ssrf_count: *, grpc_services_count: *, grpc_methods_count: *, soap_apis_count: *, soap_api_operations_count: *, restful_apis_last_30_days_count?: *, graphql_apis_last_30_days_count?: *, grpc_services_last_30_days_count?: *, soap_apis_last_30_days_count?: * }>
      }) }
    })> }
  }) }
});

export type FullStackSecurityMetricsQueryVariables = {};


export type FullStackSecurityMetricsQuery = ({
    ...{ __typename: 'Query' },
  ...{ full_stack_security_metrics: ({
      ...{ __typename: 'MetricsResponse' },
    ...{ metrics: Array<({
        ...{ __typename: 'Metrics' },
      ...{ full_stack_metrics?: ?({
          ...{ __typename: 'FullStackMetrics' },
        ...$Pick<FullStackMetrics, { api_operations_total_count: *, api_operations_exercised_by_mobile_applications_count: *, api_operations_exercised_by_web_applications_count: *, api_operations_hosted_on_cloud_platforms_count: * }>
      }) }
    })> }
  }) }
});

export type WebApplicationXssToolkitMetricsQueryVariables = {};


export type WebApplicationXssToolkitMetricsQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_application_xss_toolkit_metrics: ({
      ...{ __typename: 'MetricsResponse' },
    ...{ metrics: Array<({
        ...{ __typename: 'Metrics' },
      ...{ xss_toolkit_metrics?: ?({
          ...{ __typename: 'WebApplicationXssToolkitMetrics' },
        ...$Pick<WebApplicationXssToolkitMetrics, { urls_count: *, web_applications_count: *, vulnerable_web_applications_count: *, watchlisted_web_applications_count: *, xss_scan_runs_count: *, ongoing_xss_scan_runs_count: * }>
      }) }
    })> }
  }) }
});

export type CloudResourceCategoriesMetricsQueryVariables = {};


export type CloudResourceCategoriesMetricsQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_resource_categories_metrics: ({
      ...{ __typename: 'MetricsResponse' },
    ...{ metrics: Array<({
        ...{ __typename: 'Metrics' },
      ...{ cloud_resource_categories_metrics?: ?({
          ...{ __typename: 'CloudResourceCategoriesMetrics' },
        ...$Pick<CloudResourceCategoriesMetrics, { reference_date: * }>,
        ...{ all_assets_stats: ({
            ...{ __typename: 'PerEnvironmentCloudResourceCategoriesMetrics' },
          ...$Pick<PerEnvironmentCloudResourceCategoriesMetrics, { assets_total_count: *, serverless_assets_count: *, database_assets_count: *, virtual_machine_assets_count: *, storage_assets_count: *, api_management_assets_count: *, secret_management_assets_count: *, other_assets_count: *, kubernetes_assets_count: * }>
        }), aws_assets_stats: ({
            ...{ __typename: 'PerEnvironmentCloudResourceCategoriesMetrics' },
          ...$Pick<PerEnvironmentCloudResourceCategoriesMetrics, { assets_total_count: *, serverless_assets_count: *, database_assets_count: *, virtual_machine_assets_count: *, storage_assets_count: *, api_management_assets_count: *, secret_management_assets_count: *, other_assets_count: *, kubernetes_assets_count: * }>
        }), gcp_assets_stats: ({
            ...{ __typename: 'PerEnvironmentCloudResourceCategoriesMetrics' },
          ...$Pick<PerEnvironmentCloudResourceCategoriesMetrics, { assets_total_count: *, serverless_assets_count: *, database_assets_count: *, virtual_machine_assets_count: *, storage_assets_count: *, api_management_assets_count: *, secret_management_assets_count: *, other_assets_count: *, kubernetes_assets_count: * }>
        }), azure_assets_stats: ({
            ...{ __typename: 'PerEnvironmentCloudResourceCategoriesMetrics' },
          ...$Pick<PerEnvironmentCloudResourceCategoriesMetrics, { assets_total_count: *, serverless_assets_count: *, database_assets_count: *, virtual_machine_assets_count: *, storage_assets_count: *, api_management_assets_count: *, secret_management_assets_count: *, other_assets_count: *, kubernetes_assets_count: * }>
        }) }
      }) }
    })> }
  }) }
});

export type MobileApplicationDetailsGetV2QueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type MobileApplicationDetailsGetV2Query = ({
    ...{ __typename: 'Query' },
  ...{ mobile_app_details_v2: ({
      ...{ __typename: 'APIMobileApplicationDetailsResponse' },
    ...$Pick<ApiMobileApplicationDetailsResponse, { id: *, bundle_id?: *, discovered_via: *, discovered_via_icon_url: *, discovered_via_name: *, icon_url?: *, mobile_secure_portal_url: *, last_scan_date?: *, last_scanned_via_icon_url?: *, last_scanned_via_name?: *, last_scanned_via_should_be_highlighted?: *, name?: *, platform: *, platform_icon_url?: *, platform_name?: *, release_type?: *, release_type_icon_url?: *, release_type_name?: *, store_url?: *, version_id?: * }>,
    ...{ included_supply_chain_secure_my_apps_details?: ?({
        ...{ __typename: 'IncludedSupplyChainSecureMyAppsDetails' },
      ...$Pick<IncludedSupplyChainSecureMyAppsDetails, { related_third_party_assets_count?: *, related_third_party_assets_count_30_day_delta?: *, related_third_party_assets_count_with_violations_30_day_delta?: *, related_third_party_assets_with_violations_count?: *, related_third_party_cloud_resource_count?: *, related_third_party_cloud_resource_count_30_day_delta?: *, related_third_party_combined_apis_count?: *, related_third_party_combined_apis_count_30_day_delta?: *, related_third_party_mobile_sdk_count?: *, related_third_party_mobile_sdk_count_30_day_delta?: *, vendors_count?: *, vendors_count_30_day_delta?: * }>
    }) }
  }) }
});

export type MobileProtectAppCoverageQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type MobileProtectAppCoverageQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_protect_app_coverage: ({
      ...{ __typename: 'MobileProtectAppCoverageResponse' },
    ...$Pick<MobileProtectAppCoverageResponse, { exercised_api_operations_count: * }>
  }) }
});

export type MobileApplicationLinkAppMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: MobileApplicationsLinkAppBody,
};


export type MobileApplicationLinkAppMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ mobile_applications_link_app: ({
      ...{ __typename: 'MobileApp' },
    ...$Pick<MobileApp, { id: * }>
  }) }
});

export type MobileApplicationStatsQueryVariables = {};


export type MobileApplicationStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_applications_stats: ({
      ...{ __typename: 'MobileApplicationStatsResponse' },
    ...$Pick<MobileApplicationStatsResponse, { android_apps_count: *, android_preprod_apps_count: *, ios_apps_count: *, ios_preprod_apps_count: *, enterprise_apps_count: *, mobile_apis_count: *, total_open_policy_violations_count: *, total_open_toolkit_policy_violations_count: *, total_open_non_toolkit_policy_violations_count: *, android_icon_url: *, enterprise_icon_url: *, ios_icon_url: *, active_installs_count: *, attacks_detected_count: *, attacks_blocked_count: *, apps_with_active_protection_count: *, total_apps_count: *, mobile_api_icon_url: *, trend_period_in_days: *, mobile_apis_count_change_in_trend_period: *, enterprise_apps_count_change_in_trend_period: *, ios_apps_count_change_in_trend_period: *, android_apps_count_change_in_trend_period: * }>
  }) }
});

export type MobileApplicationListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_asset_type?: ?Array<?AssetTypeEnum>,
  filter_by_vulnerable_to_toolkit_hacks?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_active_protection_status?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_external_id?: ?$ElementType<Scalars, 'String'>,
  filter_by_asset_group_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_platform?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_cloud_provider?: ?Array<?HostedOn>,
  filter_by_discovered_via?: ?Array<?DiscoveredVia>,
  order_by?: ?Array<?MobileAppOrderBy>,
  filter_by_linked_to_customer_mobile_app_id?: ?$ElementType<Scalars, 'String'>,
  filter_by_link_candidate_type?: ?$ElementType<Scalars, 'String'>,
  filter_by_asset_tags?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
  filter_by_subscription?: ?Array<?$ElementType<Scalars, 'String'>>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
};


export type MobileApplicationListQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_app_list: ({
      ...{ __typename: 'MobileApplicationsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), mobile_applications: Array<({
        ...{ __typename: 'MobileApplicationsListItem' },
      ...$Pick<MobileApplicationsListItem, { id: *, name: *, bundle_id: *, version_id?: *, platform: *, release_type: *, release_type_name?: *, release_type_icon_url?: *, sevenhell_customer_mobile_app_id: *, platform_icon_url: *, platform_name: *, icon_url: *, store_url?: *, has_linked_apps: *, toolkit_hacks_total_count: *, toolkit_hacks_vulnerable_to_count: *, toolkit_hacks_vulnerable_to: *, open_policy_violations_count: *, active_protection_status: *, mobile_protect_attacks_detected_count?: *, last_scan_date?: *, last_scanned_via_icon_url: *, last_scanned_via_name: *, last_scanned_via_should_be_highlighted: *, has_mobile_secure_subscription: * }>,
      ...{ belongs_to_asset_group?: ?({
          ...{ __typename: 'AssetGroupField' },
        ...$Pick<AssetGroupField, { id: *, name: * }>
      }), asset_tag_keys?: ?Array<?({
          ...{ __typename: 'MobileApplicationsListItemAssetTagKey' },
        ...$Pick<MobileApplicationsListItemAssetTagKey, { uuid: *, name: * }>
      })> }
    })> }
  }) }
});

export type MobileApplicationDetailsGetQueryQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type MobileApplicationDetailsGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_application_details: ({
      ...{ __typename: 'MobileApplicationDetailsResponse' },
    ...{ mobile_applications: Array<({
        ...{ __typename: 'MobileApp' },
      ...$Pick<MobileApp, { id: *, app_secure_id?: *, name: *, platform: *, icon_url?: * }>,
      ...{ most_recent_scan?: ?({
          ...{ __typename: 'ApplicationScan' },
        ...$Pick<ApplicationScan, { end_date?: *, date_updated: *, mobile_app_id: *, start_date: *, date_created: *, app_version?: *, id: * }>
      }) }
    })>, restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, title: *, base_url: *, asset_type_name: *, asset_type_icon_url: *, date_created: *, date_no_longer_accessible?: *, discovered_via: * }>,
      ...{ api_operations?: ?Array<({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { http_method: *, path: *, is_authenticated?: * }>,
        ...{ policy_violations: Array<({
            ...{ __typename: 'PolicyViolation' },
          ...$Pick<PolicyViolation, { id: *, status: *, date_resolved?: * }>,
          ...{ violated_policy_rule: ({
              ...{ __typename: 'PolicyRule' },
            ...$Pick<PolicyRule, { id: *, relevance: * }>
          }) }
        })>, restful_api: ({
            ...{ __typename: 'RestfulAPI' },
          ...$Pick<RestfulApi, { id: *, base_url: * }>
        }) }
      })> }
    })>, cloud_resources: Array<({
        ...{ __typename: 'CloudResource' },
      ...$Pick<CloudResource, { id: *, date_created: *, name: *, region?: *, resource_type: *, asset_type_icon_url: *, asset_type_name: *, runtime?: *, status: *, date_no_longer_accessible?: *, discovered_via: *, hosted_on?: *, cloud_console_url: *, url?: * }>,
      ...{ policy_violations: Array<?({
          ...{ __typename: 'PolicyViolation' },
        ...$Pick<PolicyViolation, { id: *, status: * }>,
        ...{ violated_policy_rule: ({
            ...{ __typename: 'PolicyRule' },
          ...$Pick<PolicyRule, { id: *, relevance: * }>,
          ...{ policy_rule_type: ({
              ...{ __typename: 'PolicyRuleType' },
            ...$Pick<PolicyRuleType, { id: *, title: * }>
          }) }
        }) }
      })> }
    })> }
  }) }
});

export type MulesoftAuthenticatorsCreateMutationMutationVariables = {
  body: MulesoftAuthenticatorsCreateParams,
};


export type MulesoftAuthenticatorsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ mulesoft_authenticators_create: ({
      ...{ __typename: 'MulesoftAuthenticatorsCreateResponse' },
    ...$Pick<MulesoftAuthenticatorsCreateResponse, { id: *, date_created: *, added_by_user_id: *, organization_id: * }>
  }) }
});

export type NetworkServicesCreateMutationV2MutationVariables = {
  body: NetworkServicesCreateV2Params,
};


export type NetworkServicesCreateMutationV2Mutation = ({
    ...{ __typename: 'Mutation' },
  ...{ network_services_create_v2: ({
      ...{ __typename: 'NetworkServiceV2Result' },
    ...$Pick<NetworkServiceV2Result, { base_url: *, id: *, date_created: * }>
  }) }
});

export type NetworkServicesCreateMutationMutationVariables = {
  body: NetworkServicesCreateParams,
};


export type NetworkServicesCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ network_services_create: ({
      ...{ __typename: 'NetworkService' },
    ...$Pick<NetworkService, { id: *, port: *, url: *, status: *, application_layer_protocol: *, is_tls_encrypted: *, hosted_on?: *, date_no_longer_accessible?: *, asset_type_name: *, asset_type_icon_url: *, discovered_via: *, date_created: * }>
  }) }
});

export type NetworkServiceDetailsQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type NetworkServiceDetailsQuery = ({
    ...{ __typename: 'Query' },
  ...{ network_service_details: ({
      ...{ __typename: 'NetworkServiceDetailsResponse' },
    ...{ cloud_resources: Array<({
        ...{ __typename: 'CloudResource' },
      ...$Pick<CloudResource, { id: *, name: *, resource_type: *, asset_type_name: *, asset_type_icon_url: * }>
    })>, restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>
    })>, network_services: Array<({
        ...{ __typename: 'NetworkService' },
      ...$Pick<NetworkService, { id: *, url: *, hosted_on?: *, date_created: *, status: *, date_no_longer_accessible?: *, asset_type_icon_url: *, asset_type_name: *, discovered_via: * }>,
      ...{ domain_name: ({
          ...{ __typename: 'DomainName' },
        ...$Pick<DomainName, { id: *, name: * }>
      }) }
    })>, web_applications: Array<({
        ...{ __typename: 'WebApplication' },
      ...$Pick<WebApplication, { id: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>
    })>, policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, date_resolved?: *, date_created: *, exception_type?: *, exception_date_created?: *, status: * }>,
      ...{ violated_policy_rule: ({
          ...{ __typename: 'PolicyRule' },
        ...$Pick<PolicyRule, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: *, title: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })> }
        }) }
      }), affected_api_operation?: ?({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, http_method: *, path: * }>,
        ...{ restful_api: ({
            ...{ __typename: 'RestfulAPI' },
          ...$Pick<RestfulApi, { id: *, base_url: * }>
        }) }
      }), affected_cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { id: *, asset_type_name: *, url?: * }>
      }), affected_network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: * }>,
        ...{ domain_name: ({
            ...{ __typename: 'DomainName' },
          ...$Pick<DomainName, { id: *, name: * }>
        }) }
      }), affected_web_application?: ?({
          ...{ __typename: 'WebApplication' },
        ...$Pick<WebApplication, { id: *, base_url: * }>
      }) }
    })>, certificate_chains: Array<({
        ...{ __typename: 'CertificateChain' },
      ...$Pick<CertificateChain, { id: * }>,
      ...{ security_properties: Array<({
          ...{ __typename: 'CertificateChainSecurityProperty' },
        ...$Pick<CertificateChainSecurityProperty, { enum: *, value: *, relevance?: * }>
      })>, certificates: Array<({
          ...{ __typename: 'Certificate' },
        ...$Pick<Certificate, { id: *, issuer_as_rfc4514_string: *, date_created: *, domain_names_in_cn: *, public_key_algorithm: *, signature_hash_algorithm: *, public_key_size: *, not_valid_after: * }>
      })> }
    })>, tls_scan_results: Array<({
        ...{ __typename: 'TlsScanResult' },
      ...{ tls_scan_attributes: Array<({
          ...{ __typename: 'TlsScanAttribute' },
        ...$Pick<TlsScanAttribute, { enum: *, value: * }>,
        ...{ policy_violation?: ?({
            ...{ __typename: 'PolicyViolation' },
          ...{ violated_policy_rule: ({
              ...{ __typename: 'PolicyRule' },
            ...$Pick<PolicyRule, { id: *, relevance: * }>
          }) }
        }) }
      })> }
    })> }
  }) }
});

export type NotificationEventsListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type NotificationEventsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ notification_events_list: ({
      ...{ __typename: 'EventsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), events?: ?Array<({
        ...{ __typename: 'HorizonNotificationEvent' },
      ...$Pick<HorizonNotificationEvent, { id: *, title?: *, event_type: *, icon_url: *, detail: *, detail_icon_url: *, date_created: *, portal_url: *, subtitle?: * }>
    })> }
  }) }
});

export type OnboardApiCredentialsPostMutationVariables = {
  body: OnboardApiCredentialsParams,
};


export type OnboardApiCredentialsPostMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ onboard_api_credentials_post: ({
      ...{ __typename: 'OnboardApiCredentialsPostResponse' },
    ...$Pick<OnboardApiCredentialsPostResponse, { _?: * }>
  }) }
});

export type OpenApiDefinitionsListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type OpenApiDefinitionsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ openapi_definitions_list: ({
      ...{ __typename: 'OpenAPIDefinitionsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), openapi_definitions: Array<({
        ...{ __typename: 'OpenApiDefinition' },
      ...$Pick<OpenApiDefinition, { id: *, title: *, discovered_via: *, discovered_via_aws_authenticator_id?: *, discovered_via_user_id?: *, original_document: *, date_created: * }>
    })> }
  }) }
});

export type OpenApiDefinitionsCreateMutationMutationVariables = {
  body: $ElementType<Scalars, 'String'>,
};


export type OpenApiDefinitionsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ openapi_definitions_create: ({
      ...{ __typename: 'OpenApiDefinitionsCreateResponse' },
    ...{ restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { title: * }>
    })> }
  }) }
});

export type PoliciesCreateMutationVariables = {
  body: PoliciesCreateParams,
};


export type PoliciesCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ policies_create: ({
      ...{ __typename: 'PoliciesCreateResponse' },
    ...{ policies: Array<({
        ...{ __typename: 'Policy' },
      ...$Pick<Policy, { id: *, name: *, created_by_user_id: *, is_special_dt_policy: *, date_created: *, enabled_policy_rules_count: *, policy_rule_ids?: *, description: *, protected_asset_groups_count: * }>,
      ...{ created_by_user: ({
          ...{ __typename: 'HorizonUserField' },
        ...$Pick<HorizonUserField, { id: *, first_name: *, last_name: * }>
      }) }
    })> }
  }) }
});

export type PoliciesListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  include_scs_policies_only?: ?$ElementType<Scalars, 'Boolean'>,
};


export type PoliciesListQuery = ({
    ...{ __typename: 'Query' },
  ...{ policies_list: ({
      ...{ __typename: 'PoliciesListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policies: Array<({
        ...{ __typename: 'Policy' },
      ...$Pick<Policy, { id: *, name: *, created_by_user_id: *, created_by_actor_id: *, created_by_actor_name: *, is_special_dt_policy: *, date_created: *, enabled_policy_rules_count: *, policy_rule_ids?: *, description: *, protected_asset_groups_count: * }>,
      ...{ created_by_user: ({
          ...{ __typename: 'HorizonUserField' },
        ...$Pick<HorizonUserField, { id: *, first_name: *, last_name: *, login_email: *, date_created?: *, notification_email?: * }>
      }) }
    })> }
  }) }
});

export type PoliciesListV2QueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  include_scs_policies_only?: ?$ElementType<Scalars, 'Boolean'>,
};


export type PoliciesListV2Query = ({
    ...{ __typename: 'Query' },
  ...{ policies_list_v2: ({
      ...{ __typename: 'PoliciesListV2Response' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policies: Array<({
        ...{ __typename: 'PolicyV2' },
      ...$Pick<PolicyV2, { id: *, name?: * }>
    })> }
  }) }
});

export type PoliciesGetQueryVariables = {
  policyId?: ?$ElementType<Scalars, 'ID'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type PoliciesGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_by_id: ({
      ...{ __typename: 'PolicyDetailResponse' },
    ...{ policies: Array<({
        ...{ __typename: 'Policy' },
      ...$Pick<Policy, { id: *, name: *, created_by_user_id: *, created_by_actor_id: *, created_by_actor_name: *, is_special_dt_policy: *, date_created: *, protected_asset_groups_count: *, enabled_policy_rules_count: *, policy_rule_ids?: *, description: * }>,
      ...{ created_by_user: ({
          ...{ __typename: 'HorizonUserField' },
        ...$Pick<HorizonUserField, { id: *, first_name: *, last_name: *, login_email: *, date_created?: *, notification_email?: * }>
      }) }
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: *, relevance: *, open_policy_violations_count: *, resolved_policy_violations_count: *, belongs_to_policy_name: * }>,
      ...{ configuration?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { nb_of_days_before_cert_expiration?: *, http_methods_to_scan?: *, weekdays_when_to_scan?: *, time_when_to_scan?: *, enabled_google_pii_types?: * }>
      }) }
    })>, asset_groups: Array<({
        ...{ __typename: 'AssetGroups' },
      ...$Pick<AssetGroups, { id: *, date_created: *, name: *, is_read_only: *, description: *, attached_policy_id: *, created_by_user_id: *, created_by_actor_name?: * }>,
      ...{ asset_group_memberships_overview: ({
          ...{ __typename: 'AssetGroupMembershipsOverview' },
        ...$Pick<AssetGroupMembershipsOverview, { restful_apis_count: *, web_applications_count: *, network_services_count: *, cloud_resources_count: *, api_operations_count: * }>
      }), created_by_user: ({
          ...{ __typename: 'HorizonUserField' },
        ...$Pick<HorizonUserField, { date_created?: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
      }) }
    })>, users: Array<({
        ...{ __typename: 'HorizonUserField' },
      ...$Pick<HorizonUserField, { first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
    })> }
  }) }
});

export type PoliciesRemoveMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type PoliciesRemoveMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ policies_remove: ({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type PoliciesPolicyRuleTypesListQueryVariables = {
  policy_id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
  filter_by_group?: ?Array<$ElementType<Scalars, 'String'>>,
  filter_by_compliance_standard_criteria?: ?Array<$ElementType<Scalars, 'String'>>,
  include_headliner_hacks_only?: ?$ElementType<Scalars, 'Boolean'>,
  include_vendor_scs_headliner_hacks_only?: ?$ElementType<Scalars, 'Boolean'>,
};


export type PoliciesPolicyRuleTypesListQuery = ({
    ...{ __typename: 'Query' },
  ...{ policies_policy_rule_types_list: ({
      ...{ __typename: 'PoliciesPolicyRuleTypeListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleTypeV2' },
      ...$Pick<PolicyRuleTypeV2, { id: *, default_relevance?: *, description: *, group?: *, recommendation?: *, requires_configuration?: *, title: *, headliner_hack_titles?: * }>,
      ...{ configuration_default_value?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { http_methods_to_scan?: *, nb_of_days_before_cert_expiration?: *, time_when_to_scan?: *, weekdays_when_to_scan?: *, enabled_google_pii_types?: * }>
      }), compliance_policy_references: Array<({
          ...{ __typename: 'PolicyComplianceReference' },
        ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
      })>, related_rule_types?: ?Array<({
          ...{ __typename: 'PolicyRuleTypeRelatedRuleType' },
        ...$Pick<PolicyRuleTypeRelatedRuleType, { id: *, default_relevance?: *, description: *, group?: *, recommendation?: *, requires_configuration?: *, title: * }>,
        ...{ configuration_default_value?: ?({
            ...{ __typename: 'PolicyRuleConfiguration' },
          ...$Pick<PolicyRuleConfiguration, { http_methods_to_scan?: *, nb_of_days_before_cert_expiration?: *, time_when_to_scan?: *, weekdays_when_to_scan?: *, enabled_google_pii_types?: * }>
        }), compliance_policy_references: Array<({
            ...{ __typename: 'PolicyComplianceReference' },
          ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
        })> }
      })>, policy_rule?: ?({
          ...{ __typename: 'PolicyRuleTypeV2PolicyRule' },
        ...$Pick<PolicyRuleTypeV2PolicyRule, { id: *, date_created: *, relevance: *, open_policy_violations_count: *, resolved_policy_violations_count: * }>,
        ...{ configuration?: ?({
            ...{ __typename: 'PolicyRuleConfiguration' },
          ...$Pick<PolicyRuleConfiguration, { nb_of_days_before_cert_expiration?: *, http_methods_to_scan?: *, weekdays_when_to_scan?: *, time_when_to_scan?: *, enabled_google_pii_types?: * }>
        }) }
      }), importance_tags?: ?Array<?({
          ...{ __typename: 'ImportanceTagType' },
        ...$Pick<ImportanceTagType, { background_color: *, icon_url: *, title: * }>
      })> }
    })> }
  }) }
});

export type PolicyRuleQueryQueryVariables = {
  policyId?: ?$ElementType<Scalars, 'ID'>,
};


export type PolicyRuleQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_by_id: ({
      ...{ __typename: 'PolicyDetailResponse' },
    ...{ policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, belongs_to_policy_name: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: *, relevance: *, open_policy_violations_count: *, resolved_policy_violations_count: * }>,
      ...{ policy: ({
          ...{ __typename: 'Policy' },
        ...$Pick<Policy, { id: * }>
      }), configuration?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { nb_of_days_before_cert_expiration?: *, http_methods_to_scan?: *, weekdays_when_to_scan?: *, time_when_to_scan?: *, enabled_google_pii_types?: * }>
      }) }
    })> }
  }) }
});

export type PolicyRulesListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  policy_rule_type_id?: ?$ElementType<Scalars, 'String'>,
  filter_by_has_open_policy_violations?: ?$ElementType<Scalars, 'Boolean'>,
  is_eligible_for_auto_remediation?: ?$ElementType<Scalars, 'Boolean'>,
};


export type PolicyRulesListQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_rules_list: ({
      ...{ __typename: 'PolicyRuleListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, relevance: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: *, belongs_to_policy_name: *, open_policy_violations_count: *, resolved_policy_violations_count: * }>,
      ...{ configuration?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { nb_of_days_before_cert_expiration?: *, http_methods_to_scan?: *, weekdays_when_to_scan?: *, time_when_to_scan?: *, enabled_google_pii_types?: * }>
      }), policy_rule_type: ({
          ...{ __typename: 'PolicyRuleType' },
        ...$Pick<PolicyRuleType, { id: *, title: * }>
      }) }
    })> }
  }) }
});

export type V2PolicyRulesListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?PolicyRulesIncludeEnum,
  filter_by_policy_rule_type_id?: ?$ElementType<Scalars, 'String'>,
  filter_by_has_open_policy_violations?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_asset_group_id?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_no_asset_group?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_relevance?: ?Array<PolicyRuleTypeRelevance>,
  filter_by_asset_type?: ?Array<AssetTypeEnum>,
  filter_by_compliance_standard?: ?Array<PolicyComplianceStandardCriteria>,
  is_eligible_for_auto_remediation?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_asset_type_group?: ?Array<?AssetTypeGroup>,
  filter_by_asset_ownership?: ?$ElementType<Scalars, 'String'>,
  filter_by_has_policy_violations_with_status?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_asset_vendor_id?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_has_policy_violations_with_statuses?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_severity?: ?Array<?FindingSeverity>,
  filter_by_policy_violation_named_filter?: ?$ElementType<Scalars, 'String'>,
  filter_by_belongs_to_products?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_related_first_party_asset_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
};


export type V2PolicyRulesListQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_rules_list_v2: ({
      ...{ __typename: 'V2PolicyRulesListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), policy_rules: Array<({
        ...{ __typename: 'V2PolicyRulesListResponsePolicyRule' },
      ...$Pick<V2PolicyRulesListResponsePolicyRule, { id: *, relevance: * }>,
      ...{ included_policy_violation_stats?: ?({
          ...{ __typename: 'V2PolicyRulesListResponsePolicyRuleIncludedPolicyViolationStats' },
        ...$Pick<V2PolicyRulesListResponsePolicyRuleIncludedPolicyViolationStats, { open_violations_with_ongoing_triaging_effort_count: *, open_violations_without_ongoing_triaging_effort_count: *, resolved_violations_count: *, wont_fix_violations_count: *, affected_assets_count?: * }>
      }), policy_rule_type: ({
          ...{ __typename: 'V2PolicyRulesListResponsePolicyRulePolicyRuleType' },
        ...$Pick<V2PolicyRulesListResponsePolicyRulePolicyRuleType, { id: *, description: *, recommendation?: *, title: * }>
      }) }
    })> }
  }) }
});

export type PolicyRulesByPolicyIdListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  policyId: $ElementType<Scalars, 'ID'>,
};


export type PolicyRulesByPolicyIdListQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_rules_by_policy_id_list: ({
      ...{ __typename: 'PolicyRulesByPolicyIdListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { default_relevance?: *, description: *, group?: *, id: *, recommendation?: *, requires_configuration?: *, title: * }>,
      ...{ compliance_policy_references: Array<({
          ...{ __typename: 'PolicyComplianceReference' },
        ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
      })>, configuration_default_value?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { nb_of_days_before_cert_expiration?: *, http_methods_to_scan?: *, weekdays_when_to_scan?: *, time_when_to_scan?: *, enabled_google_pii_types?: * }>
      }) }
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, relevance: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: *, open_policy_violations_count: *, belongs_to_policy_name: * }>,
      ...{ configuration?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { nb_of_days_before_cert_expiration?: *, http_methods_to_scan?: *, weekdays_when_to_scan?: *, time_when_to_scan?: *, enabled_google_pii_types?: * }>
      }) }
    })>, users: Array<({
        ...{ __typename: 'HorizonUserField' },
      ...$Pick<HorizonUserField, { date_created?: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
    })> }
  }) }
});

export type PolicyRulesSearchFilterValuesQueryVariables = {};


export type PolicyRulesSearchFilterValuesQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_rules_search_filter_values: ({
      ...{ __typename: 'PolicyRulesSearchFilterResponse' },
    ...{ policy_rules_search_filter_values: Array<({
        ...{ __typename: 'PolicyRulesSearchFilterValue' },
      ...$Pick<PolicyRulesSearchFilterValue, { belongs_to_filter: *, icon_url: *, name: *, value: *, value_category?: * }>
    })> }
  }) }
});

export type PolicyRulesCreateMutationVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?PolicyRulesCreateParams,
};


export type PolicyRulesCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ policy_rules_create: ({
      ...{ __typename: 'PolicyRule' },
    ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, relevance: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: *, open_policy_violations_count: *, resolved_policy_violations_count: *, belongs_to_policy_name: * }>,
    ...{ configuration?: ?({
        ...{ __typename: 'PolicyRuleConfiguration' },
      ...$Pick<PolicyRuleConfiguration, { nb_of_days_before_cert_expiration?: *, http_methods_to_scan?: *, weekdays_when_to_scan?: *, time_when_to_scan?: *, enabled_google_pii_types?: * }>
    }) }
  }) }
});

export type PolicyRulesRemoveMutationVariables = {
  policyId: $ElementType<Scalars, 'ID'>,
  ruleId: $ElementType<Scalars, 'ID'>,
};


export type PolicyRulesRemoveMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ policy_rules_remove?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type PolicyRulesUpdateMutationVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
  policy_rule_id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?PolicyRulesUpdateParams,
};


export type PolicyRulesUpdateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ policy_rules_update: ({
      ...{ __typename: 'PolicyRulesUpdateResponse' },
    ...{ policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, relevance: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: *, open_policy_violations_count: *, resolved_policy_violations_count: * }>,
      ...{ configuration?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { nb_of_days_before_cert_expiration?: *, http_methods_to_scan?: *, weekdays_when_to_scan?: *, time_when_to_scan?: *, enabled_google_pii_types?: * }>
      }) }
    })>, policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: *, title: *, default_relevance?: * }>
    })> }
  }) }
});

export type PolicyViolationsGetQueryQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type PolicyViolationsGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violation_by_id: ({
      ...{ __typename: 'PolicyViolationResponse' },
    ...{ policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, date_created: *, date_resolved?: *, exception_date_created?: *, exception_type?: *, additional_info?: *, status: *, affected_api_operation_id?: *, violated_policy_rule_id: * }>,
      ...{ remediation?: ?({
          ...{ __typename: 'Remediation' },
        ...$Pick<Remediation, { remediation_instructions: *, remediation_url: * }>
      }), violated_policy_rule: ({
          ...{ __typename: 'PolicyRule' },
        ...$Pick<PolicyRule, { id: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: * }>
        }) }
      }), affected_web_application?: ?({
          ...{ __typename: 'WebApplication' },
        ...$Pick<WebApplication, { id: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>,
        ...{ most_recent_analysis_result: ({
            ...{ __typename: 'WebAnalysisResult' },
          ...$Pick<WebAnalysisResult, { title: * }>
        }), network_service: ({
            ...{ __typename: 'NetworkService' },
          ...$Pick<NetworkService, { id: *, hosted_on?: * }>
        }) }
      }), affected_network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, hosted_on?: *, asset_type_name: *, asset_type_icon_url: * }>,
        ...{ domain_name: ({
            ...{ __typename: 'DomainName' },
          ...$Pick<DomainName, { id: *, name: * }>
        }) }
      }), affected_graphql_api?: ?({
          ...{ __typename: 'GraphqlApi' },
        ...$Pick<GraphqlApi, { id: *, url: *, asset_type_name: *, asset_type_icon_url: *, hosted_on: * }>
      }), affected_api_operation?: ?({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, path: *, http_method: * }>,
        ...{ restful_api: ({
            ...{ __typename: 'RestfulAPI' },
          ...$Pick<RestfulApi, { id: *, title: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>,
          ...{ network_service: ({
              ...{ __typename: 'NetworkService' },
            ...$Pick<NetworkService, { id: *, hosted_on?: * }>
          }) }
        }) }
      }), affected_cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { id: *, name: *, url?: *, asset_type_name: *, asset_type_icon_url: *, hosted_on?: *, cloud_console_url: * }>
      }) }
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, relevance: * }>
    })>, policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: *, title: *, description: *, recommendation?: * }>
    })> }
  }) }
});

export type PolicyViolationsListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_policy_rule_type_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  is_eligible_for_auto_remediation?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_violation_status?: ?Array<?PolicyViolationStatusEnum>,
  was_discovered_after?: ?$ElementType<Scalars, 'Date'>,
  filter_by_importance_tag?: ?Array<?FilterByImportanceTagEnum>,
  filter_by_product?: ?Array<?FilterByProductEnum>,
};


export type PolicyViolationsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, additional_info?: *, affected_api_operation_id?: *, date_resolved?: *, date_created: *, exception_type?: *, exception_date_created?: *, violated_policy_rule_id: *, status: * }>,
      ...{ violated_policy_rule: ({
          ...{ __typename: 'PolicyRule' },
        ...$Pick<PolicyRule, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: *, title: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })> }
        }) }
      }), affected_restful_api?: ?({
          ...{ __typename: 'RestfulAPI' },
        ...$Pick<RestfulApi, { id: *, title: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>,
        ...{ network_service: ({
            ...{ __typename: 'NetworkService' },
          ...$Pick<NetworkService, { id: *, hosted_on?: * }>
        }) }
      }), affected_api_operation?: ?({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, http_method: *, path: * }>,
        ...{ restful_api: ({
            ...{ __typename: 'RestfulAPI' },
          ...$Pick<RestfulApi, { id: *, title: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>,
          ...{ network_service: ({
              ...{ __typename: 'NetworkService' },
            ...$Pick<NetworkService, { id: *, hosted_on?: * }>
          }) }
        }) }
      }), affected_cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { id: *, asset_type_name: *, url?: * }>
      }), affected_graphql_api?: ?({
          ...{ __typename: 'GraphqlApi' },
        ...$Pick<GraphqlApi, { id: *, url: *, asset_type_name: *, asset_type_icon_url: *, hosted_on: * }>
      }), affected_network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, hosted_on?: *, url: *, asset_type_icon_url: *, asset_type_name: * }>,
        ...{ domain_name: ({
            ...{ __typename: 'DomainName' },
          ...$Pick<DomainName, { name: * }>
        }) }
      }), affected_web_application?: ?({
          ...{ __typename: 'WebApplication' },
        ...$Pick<WebApplication, { id: *, base_url: * }>
      }) }
    })>, restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, title: *, base_url: *, asset_type_name: *, asset_type_icon_url: *, date_created: *, discovered_via: * }>,
      ...{ api_operations?: ?Array<({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, http_method: *, path: * }>
      })>, network_service: ({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, hosted_on?: * }>
      }) }
    })> }
  }) }
});

export type PolicyViolationGetVersion2QueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type PolicyViolationGetVersion2Query = ({
    ...{ __typename: 'Query' },
  ...{ policy_violation_details_v2: ({
      ...{ __typename: 'PolicyViolationV2' },
    ...$Pick<PolicyViolationV2, { id: *, date_created: *, date_resolved?: *, date_last_checked?: *, exception_type?: *, additional_info?: *, status: *, exception_date_created?: *, exception_explanation?: *, custom_description?: *, custom_recommendation?: *, custom_secure_code?: *, affected_asset_details?: * }>,
    ...{ external_integrations?: ?Array<?({
        ...{ __typename: 'PolicyViolationV2ExternalIntegration' },
      ...$Pick<PolicyViolationV2ExternalIntegration, { id: *, external_key: *, external_url: *, integration_icon_url: *, integration_type: * }>
    })>, auto_remediation_info?: ?({
        ...{ __typename: 'Remediation' },
      ...$Pick<Remediation, { remediation_instructions: *, remediation_url: * }>
    }), http_trace?: ?({
        ...{ __typename: 'PolicyViolationV2HttpTrace' },
      ...$Pick<PolicyViolationV2HttpTrace, { http_request: *, http_response: * }>
    }), affected_asset: ({
        ...{ __typename: 'PolicyViolationAffectedAsset' },
      ...$Pick<PolicyViolationAffectedAsset, { id: *, name?: *, url?: *, cloud_console_url?: *, asset_type: *, asset_type_icon_url: *, asset_type_name: *, date_created: *, hosted_on?: *, aws_arn?: * }>,
      ...{ belongs_to_asset_group?: ?({
          ...{ __typename: 'AssetGroupField' },
        ...$Pick<AssetGroupField, { id: *, name: * }>
      }), belongs_to_cloud_account?: ?({
          ...{ __typename: 'BelongsToCloudAccount' },
        ...$Pick<BelongsToCloudAccount, { id: *, cloud_account_icon_url: *, cloud_account_type_name: *, discovered_via_cloud_authenticator_id: *, customer_supplied_name?: * }>
      }) }
    }), violated_policy_rule: ({
        ...{ __typename: 'PolicyRuleV2' },
      ...$Pick<PolicyRuleV2, { id: *, relevance: *, severity?: * }>,
      ...{ policy_rule_type: ({
          ...{ __typename: 'PolicyRuleType' },
        ...$Pick<PolicyRuleType, { id: *, title: *, description: *, recommendation?: *, secure_code?: *, default_relevance?: * }>,
        ...{ compliance_policy_references: Array<({
            ...{ __typename: 'PolicyComplianceReference' },
          ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
        })>, related_rule_types?: ?Array<({
            ...{ __typename: 'PolicyRuleTypeRelatedRuleType' },
          ...$Pick<PolicyRuleTypeRelatedRuleType, { id: *, title: *, description: *, recommendation?: *, default_relevance?: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })> }
        })> }
      }) }
    }) }
  }) }
});

export type PolicyViolationsListQueryVersion2QueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  order_by?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
  was_discovered_after?: ?$ElementType<Scalars, 'Date'>,
  filter_by_importance_tag?: ?Array<?FilterByImportanceTagEnum>,
  filter_by_product?: ?Array<?FilterByProductEnum>,
  filter_by_violation_status?: ?Array<?PolicyViolationStatusEnum>,
  filter_by_relevance?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_asset_group_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_asset_ownership?: ?$ElementType<Scalars, 'String'>,
  filter_by_related_first_party_asset_ids?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_no_asset_group?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_asset_vendor_ids?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_policy_rule_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_policy_rule_type_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_ongoing_triaging_effort?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type PolicyViolationsListQueryVersion2Query = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list_v2: ({
      ...{ __typename: 'PolicyViolationListResponseVersion2' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolationV2' },
      ...$Pick<PolicyViolationV2, { id: *, affected_asset_id?: *, affected_asset_name: *, affected_asset_type: *, affected_asset_type_name: *, affected_asset_type_icon_url?: *, affected_asset_hosted_on?: *, date_created: *, date_resolved?: *, status: *, additional_info?: *, opened_by_certificate_chain_id?: *, exception_type?: *, exception_explanation?: *, exception_date_created?: * }>,
      ...{ affected_asset: ({
          ...{ __typename: 'PolicyViolationAffectedAsset' },
        ...$Pick<PolicyViolationAffectedAsset, { id: *, name?: *, url?: *, asset_type: *, asset_type_icon_url: *, asset_type_name: *, date_created: *, hosted_on?: *, api_operation_authentication_scheme?: * }>
      }), violated_policy_rule: ({
          ...{ __typename: 'PolicyRuleV2' },
        ...$Pick<PolicyRuleV2, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: *, title: *, description: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })> }
        }) }
      }) }
    })> }
  }) }
});

export type PolicyViolationsPatchMutationMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: PolicyViolationsPatchParams,
};


export type PolicyViolationsPatchMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ policy_violations_patch: ({
      ...{ __typename: 'PolicyViolationsPatchResponse' },
    ...{ policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, status: *, exception_type?: *, exception_explanation?: *, exception_date_created?: * }>
    })> }
  }) }
});

export type PolicyViolationsCommentsListQueryVariables = {
  policy_violation_id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type PolicyViolationsCommentsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_comments_list: ({
      ...{ __typename: 'PolicyViolationsCommentsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), comments: Array<({
        ...{ __typename: 'Comment' },
      ...$Pick<Comment, { id: *, date_created: *, content?: *, related_policy_violation_id: *, added_by_actor_id: *, added_by_actor_name: *, added_by_user_id: * }>
    })>, users: Array<({
        ...{ __typename: 'HorizonUserField' },
      ...$Pick<HorizonUserField, { date_created?: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
    })> }
  }) }
});

export type PolicyViolationsCommentsCreateMutationVariables = {
  policy_violation_id: $ElementType<Scalars, 'ID'>,
  body: PolicyViolationsCommentsCreateParams,
};


export type PolicyViolationsCommentsCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ policy_violations_comments_create: ({
      ...{ __typename: 'Comment' },
    ...$Pick<Comment, { id: *, date_created: *, content?: *, related_policy_violation_id: *, added_by_actor_id: *, added_by_actor_name: *, added_by_user_id: * }>
  }) }
});

export type PostmanWorkflowsListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_onboarding_status?: ?Array<$ElementType<Scalars, 'String'>>,
  filter_by_purpose?: ?$ElementType<Scalars, 'String'>,
  filter_by_custom_check_type?: ?Array<$ElementType<Scalars, 'String'>>,
  filter_by_product_type?: ?$ElementType<Scalars, 'String'>,
};


export type PostmanWorkflowsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ postman_workflows_list: ({
      ...{ __typename: 'PostmanWorkflowsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), postman_workflows: Array<({
        ...{ __typename: 'PostmanWorkflowSummaryField' },
      ...$Pick<PostmanWorkflowSummaryField, { id: *, date_created: *, last_scan_date?: *, last_successful_scan_date?: *, next_scan_date?: *, next_scan_date_fallback: *, postman_collection_filename?: *, postman_environment_filename?: *, postman_collection_hash?: *, postman_environment_hash?: *, purpose: *, custom_check_detailed_name?: *, custom_check_type?: *, open_policy_violation_id?: *, workflow_display_name: *, workflow_status_display_name: *, workflow_status_display_color: * }>,
      ...{ related_asset?: ?({
          ...{ __typename: 'PostmanWorkflowRelatedAsset' },
        ...$Pick<PostmanWorkflowRelatedAsset, { id: *, name: *, asset_type: *, asset_type_name: *, asset_type_icon_url?: *, url?: * }>
      }), customer_uploaded_files_used_to_build_check?: ?Array<({
          ...{ __typename: 'CustomerUploadedFileType' },
        ...$Pick<CustomerUploadedFileType, { created_by_actor_name: *, date_created: *, file_sha1_hash: *, file_type: *, file_type_display_name: *, file_type_icon_url: *, original_file_name: *, processing_completion_message?: *, status: *, uploader_comment?: * }>
      })> }
    })> }
  }) }
});

export type PostmanWorkflowsGetQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type PostmanWorkflowsGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ postman_workflows_get: ({
      ...{ __typename: 'PostmanWorkflowsGetResponse' },
    ...$Pick<PostmanWorkflowsGetResponse, { id?: *, workflow_display_name?: *, last_scan_status?: *, last_scan_date?: *, onboarding_status: *, custom_check_result_as_json?: *, custom_check_result_fallback?: *, custom_check_issues_count?: *, open_policy_violation_id?: * }>,
    ...{ related_asset?: ?({
        ...{ __typename: 'PostmanWorkflowRelatedAsset' },
      ...$Pick<PostmanWorkflowRelatedAsset, { id: *, name: *, asset_type: *, asset_type_name: *, asset_type_icon_url?: *, url?: * }>
    }) }
  }) }
});

export type ApiDetailsGetQueryQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type ApiDetailsGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ restful_api_details: ({
      ...{ __typename: 'RestfulAPIDetailsResponse' },
    ...{ restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, title: *, base_url: *, status: *, asset_type_name: *, asset_type_icon_url: *, date_created: *, date_no_longer_accessible?: *, discovered_via: * }>,
      ...{ api_operations?: ?Array<({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { http_method: *, path: *, is_authenticated?: * }>,
        ...{ policy_violations: Array<({
            ...{ __typename: 'PolicyViolation' },
          ...$Pick<PolicyViolation, { id: *, status: *, date_resolved?: * }>,
          ...{ violated_policy_rule: ({
              ...{ __typename: 'PolicyRule' },
            ...$Pick<PolicyRule, { id: *, relevance: * }>
          }) }
        })>, restful_api: ({
            ...{ __typename: 'RestfulAPI' },
          ...$Pick<RestfulApi, { id: *, base_url: * }>
        }) }
      })>, network_service: ({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, hosted_on?: *, url: *, asset_type_icon_url: *, asset_type_name: * }>
      }) }
    })>, network_services: Array<({
        ...{ __typename: 'NetworkService' },
      ...$Pick<NetworkService, { id: *, url: *, hosted_on?: *, asset_type_icon_url: *, asset_type_name: * }>
    })>, web_applications: Array<({
        ...{ __typename: 'WebApplication' },
      ...$Pick<WebApplication, { id: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>
    })>, cloud_resources: Array<({
        ...{ __typename: 'CloudResource' },
      ...$Pick<CloudResource, { id: *, date_created: *, name: *, region?: *, resource_type: *, asset_type_icon_url: *, asset_type_name: *, runtime?: *, status: *, date_no_longer_accessible?: *, discovered_via: *, hosted_on?: *, cloud_console_url: *, url?: * }>,
      ...{ policy_violations: Array<?({
          ...{ __typename: 'PolicyViolation' },
        ...$Pick<PolicyViolation, { id: *, status: * }>,
        ...{ violated_policy_rule: ({
            ...{ __typename: 'PolicyRule' },
          ...$Pick<PolicyRule, { id: *, relevance: * }>,
          ...{ policy_rule_type: ({
              ...{ __typename: 'PolicyRuleType' },
            ...$Pick<PolicyRuleType, { id: *, title: * }>
          }) }
        }) }
      })> }
    })>, mobile_applications: Array<({
        ...{ __typename: 'MobileApp' },
      ...$Pick<MobileApp, { id: * }>
    })>, policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, date_resolved?: *, date_created: *, exception_type?: *, exception_date_created?: *, status: * }>,
      ...{ violated_policy_rule: ({
          ...{ __typename: 'PolicyRule' },
        ...$Pick<PolicyRule, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: *, title: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })> }
        }) }
      }), affected_cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { id: *, asset_type_name: *, url?: * }>
      }), affected_network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: * }>,
        ...{ domain_name: ({
            ...{ __typename: 'DomainName' },
          ...$Pick<DomainName, { id: *, name: * }>
        }) }
      }), affected_web_application?: ?({
          ...{ __typename: 'WebApplication' },
        ...$Pick<WebApplication, { id: *, base_url: * }>
      }) }
    })> }
  }) }
});

export type RestfulApIsListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type RestfulApIsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ restful_api_list: ({
      ...{ __typename: 'RestfulAPIsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }), restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, title: *, base_url: *, asset_type_name: *, asset_type_icon_url: *, date_created: *, date_no_longer_accessible?: *, discovered_via: *, open_important_policy_violations_count?: *, open_proactive_policy_violations_count?: *, open_urgent_policy_violations_count?: * }>
    })> }
  }) }
});

export type RestfulApIsListQuery_V1QueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type RestfulApIsListQuery_V1Query = ({
    ...{ __typename: 'Query' },
  ...{ restful_api_list_v1: ({
      ...{ __typename: 'RestfulAPIsListResponse_v1' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, title: *, base_url: *, asset_type_name: *, asset_type_icon_url: *, date_created: *, date_no_longer_accessible?: *, discovered_via: * }>,
      ...{ api_operations?: ?Array<({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, http_method: *, path: *, is_authenticated?: * }>,
        ...{ policy_violations: Array<({
            ...{ __typename: 'PolicyViolation' },
          ...$Pick<PolicyViolation, { id: *, status: *, date_resolved?: * }>,
          ...{ violated_policy_rule: ({
              ...{ __typename: 'PolicyRule' },
            ...$Pick<PolicyRule, { id: *, relevance: * }>,
            ...{ policy_rule_type: ({
                ...{ __typename: 'PolicyRuleType' },
              ...$Pick<PolicyRuleType, { id: *, title: * }>,
              ...{ compliance_policy_references: Array<({
                  ...{ __typename: 'PolicyComplianceReference' },
                ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
              })> }
            }) }
          }) }
        })>, restful_api: ({
            ...{ __typename: 'RestfulAPI' },
          ...$Pick<RestfulApi, { id: *, base_url: * }>
        }) }
      })>, network_service: ({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, hosted_on?: *, url: *, asset_type_icon_url: *, asset_type_name: * }>
      }) }
    })> }
  }) }
});

export type ValidateScopedAccessTokensQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type ValidateScopedAccessTokensQuery = ({
    ...{ __typename: 'Query' },
  ...{ validate_scoped_access_tokens: ({
      ...{ __typename: 'ValidateScopedAccessTokensResponse' },
    ...$Pick<ValidateScopedAccessTokensResponse, { description?: * }>
  }) }
});

export type ShadowInventoryPerAssetTypeStatsGetQueryVariables = {};


export type ShadowInventoryPerAssetTypeStatsGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ shadow_inventory_per_asset_type_stats_get: ({
      ...{ __typename: 'shadowInventoryPerAssetTypeStats' },
    ...{ shadow_inventory_per_asset_type_stats: Array<({
        ...{ __typename: 'AssetTypeShadowInventoryStatsField' },
      ...$Pick<AssetTypeShadowInventoryStatsField, { title: *, asset_type: *, assets_count: *, last_discovered_date: * }>
    })> }
  }) }
});

export type ShadowInventoryPerCloudProviderStatsGetQueryVariables = {};


export type ShadowInventoryPerCloudProviderStatsGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ shadow_inventory_per_cloud_provider_stats_get: ({
      ...{ __typename: 'shadowInventoryPerCloudProviderStats' },
    ...{ shadow_inventory_per_cloud_provider_stats: Array<({
        ...{ __typename: 'CloudProviderShadowInventoryStatsField' },
      ...$Pick<CloudProviderShadowInventoryStatsField, { title: *, cloud_provider: *, assets_count: *, last_discovered_date: * }>
    })> }
  }) }
});

export type SharedLinksCreateForPolicyViolationMutationMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: SharedLinksCreateForPolicyViolationParams,
};


export type SharedLinksCreateForPolicyViolationMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ shared_links_for_policy_violation_create: ({
      ...{ __typename: 'SharedLinksCreateForPolicyViolationResponse' },
    ...$Pick<SharedLinksCreateForPolicyViolationResponse, { shared_resource_type: *, shared_resource_id?: *, access_token: * }>
  }) }
});

export type SharedLinksCreateForPolicyViolationsMutationVariables = {
  body: SharedLinksCreateForPolicyViolationsParams,
};


export type SharedLinksCreateForPolicyViolationsMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ shared_links_for_policy_violations_create: ({
      ...{ __typename: 'SharedLinksCreateForPolicyViolationsResponse' },
    ...$Pick<SharedLinksCreateForPolicyViolationsResponse, { action_type?: *, status?: *, id?: *, move_to_group_result?: *, completed_tasks_count?: *, total_tasks_count?: *, date_created: *, date_completed: * }>,
    ...{ secure_share_policy_violations_result?: ?({
        ...{ __typename: 'SecureSharePolicyViolationsResult' },
      ...$Pick<SecureSharePolicyViolationsResult, { policy_violations_ids?: *, access_token: * }>
    }) }
  }) }
});

export type SpecialScanRequestsListQueryQueryVariables = {
  scan_type?: ?$ElementType<Scalars, 'String'>,
};


export type SpecialScanRequestsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ special_scan_requests_list: ({
      ...{ __typename: 'SpecialScanRequestResponse' },
    ...{ special_scan_requests: Array<({
        ...{ __typename: 'SpecialScanRequest' },
      ...$Pick<SpecialScanRequest, { id: *, date_created: *, date_completed?: *, current_status: * }>,
      ...{ scan_configuration?: ?({
          ...{ __typename: 'SpecialScanRequestConfiguration' },
        ...$Pick<SpecialScanRequestConfiguration, { selected_all_web_applications?: *, selected_web_application_ids?: * }>
      }) }
    })> }
  }) }
});

export type SupplyChainSecureAppsListQueryVariables = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_asset_type?: ?Array<SupplyChainSecureAppTypeEnum>,
  filter_by_uses_third_party_vendor_id?: ?Array<$ElementType<Scalars, 'String'>>,
  order_by?: ?SupplyChainSecureAppOrderByEnum,
  page_size?: ?$ElementType<Scalars, 'Int'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type SupplyChainSecureAppsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ supply_chain_secure_app_list: ({
      ...{ __typename: 'SupplyChainSecureAppsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), supply_chain_secure_apps: Array<({
        ...{ __typename: 'SupplyChainSecureApp' },
      ...$Pick<SupplyChainSecureApp, { id: *, name: *, asset_type: *, icon_url: *, open_urgent_policy_violations: *, open_important_policy_violations: *, open_proactive_policy_violations: *, related_third_party_cloud_resources_count: *, related_third_party_graphql_apis_count: *, related_third_party_mobile_sdks_count: *, related_third_party_restful_apis_count: *, bundle_id?: *, extra_vendors_count?: *, url?: *, version_id?: * }>,
      ...{ top_vendors: Array<?({
          ...{ __typename: 'SupplyChainSecureAppTopVendor' },
        ...$Pick<SupplyChainSecureAppTopVendor, { id: *, name: *, icon_url: * }>
      })> }
    })> }
  }) }
});

export type SupplyChainSecureMetricsQueryVariables = {
  include?: ?$ElementType<Scalars, 'String'>,
};


export type SupplyChainSecureMetricsQuery = ({
    ...{ __typename: 'Query' },
  ...{ supply_chain_secure_metrics_get: ({
      ...{ __typename: 'SupplyChainSecureMetricsResponse' },
    ...{ included_main_dashboard_metrics?: ?({
        ...{ __typename: 'SupplyChainSecureMetricsDashboard' },
      ...$Pick<SupplyChainSecureMetricsDashboard, { total_first_party_full_stack_apps_count: *, total_first_party_full_stack_apps_count_30_day_delta: *, total_high_embed_rated_vendors_count: *, total_low_embed_rated_vendors_count: *, total_mid_embed_rated_vendors_count: *, total_vendors_count: *, total_vendors_count_30_day_delta: *, total_third_party_assets_count: *, total_third_party_assets_count_30_day_delta: *, total_third_party_scans_count: *, total_third_party_scans_count_1_day_delta: * }>,
      ...{ asset_stats_by_month: Array<?({
          ...{ __typename: 'SupplyChainSecureMetricsDashboardAssetStatsByMonth' },
        ...$Pick<SupplyChainSecureMetricsDashboardAssetStatsByMonth, { cloud_resources_count?: *, combined_apis_count?: *, mobile_sdk_count?: *, display_month: *, start_date: * }>
      })>, high_embed_rated_vendors: Array<?({
          ...{ __typename: 'SupplyChainSecureMetricsDashboardEmbedRatedVendor' },
        ...$Pick<SupplyChainSecureMetricsDashboardEmbedRatedVendor, { id: *, name: *, icon_url: *, website_url?: * }>
      })>, mid_embed_rated_vendors: Array<?({
          ...{ __typename: 'SupplyChainSecureMetricsDashboardEmbedRatedVendor' },
        ...$Pick<SupplyChainSecureMetricsDashboardEmbedRatedVendor, { id: *, name: *, icon_url: *, website_url?: * }>
      })>, low_embed_rated_vendors: Array<?({
          ...{ __typename: 'SupplyChainSecureMetricsDashboardEmbedRatedVendor' },
        ...$Pick<SupplyChainSecureMetricsDashboardEmbedRatedVendor, { id: *, name: *, icon_url: *, website_url?: * }>
      })> }
    }) }
  }) }
});

export type SupportRequestsCreateMutationMutationVariables = {
  body: HorizonSupportRequestsCreateParams,
};


export type SupportRequestsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ horizon_support_requests_create?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type ToolkitScanCreateMutationVariables = {
  body: ToolkitScanStartRequestParams,
};


export type ToolkitScanCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ toolkit_scan_create: ({
      ...{ __typename: 'ToolkitScanStartRequestResponse' },
    ...$Pick<ToolkitScanStartRequestResponse, { toolkit_scan_type: * }>,
    ...{ detect_and_inject_configuration?: ?({
        ...{ __typename: 'DetectAndInjectConfigurationType' },
      ...$Pick<DetectAndInjectConfigurationType, { api_operation_ids?: *, should_scan_all_api_operations?: * }>,
      ...{ scan_run_configuration?: ?({
          ...{ __typename: 'DetectAndInjectScanRunConfigurationType' },
        ...$Pick<DetectAndInjectScanRunConfigurationType, { http_methods_to_scan?: * }>
      }) }
    }), web_application_xss_configuration?: ?({
        ...{ __typename: 'WebApplicationXssConfigurationType' },
      ...$Pick<WebApplicationXssConfigurationType, { web_applications_ids?: *, should_scan_all_web_applications?: * }>,
      ...{ scan_run_configuration?: ?({
          ...{ __typename: 'WebApplicationXssScanRunConfigurationType' },
        ...$Pick<WebApplicationXssScanRunConfigurationType, { should_inject_in_get_parameters?: *, should_inject_in_post_parameters?: *, should_inject_in_url_path?: *, intensity?: * }>
      }) }
    }), cloud_ransomware_encrypt_configuration?: ?({
        ...{ __typename: 'ToolkitScansScheduleCloudRansomwareEncryptConfiguration' },
      ...$Pick<ToolkitScansScheduleCloudRansomwareEncryptConfiguration, { decryption_key: * }>
    }), cloud_ransomware_upload_malware_configuration?: ?({
        ...{ __typename: 'ToolkitScansScheduleCloudRansomwareUploadMalwareConfiguration' },
      ...$Pick<ToolkitScansScheduleCloudRansomwareUploadMalwareConfiguration, { malware_types_to_upload: * }>
    }), triggered_scan_runs?: ?Array<({
        ...{ __typename: 'TriggeredScanRuns' },
      ...$Pick<TriggeredScanRuns, { id: *, date_created: *, scanned_asset_id: *, scanned_asset_type: * }>
    })> }
  }) }
});

export type ToolkitScansListQueryVariables = {
  filter_by_type?: ?Array<?ToolkitScanTypeEnum>,
  filter_by_status?: ?SpecialScanStatus,
};


export type ToolkitScansListQuery = ({
    ...{ __typename: 'Query' },
  ...{ toolkit_scans: ({
      ...{ __typename: 'ToolkitScansResponse' },
    ...{ toolkit_scans: Array<({
        ...{ __typename: 'ToolkitScans' },
      ...$Pick<ToolkitScans, { date_created: *, date_finished?: *, status: *, toolkit_scan_type: * }>
    })> }
  }) }
});

export type ToolkitScansScheduleRemoveMutationVariables = {
  toolkit_scan_type: ToolkitScanTypeEnum,
};


export type ToolkitScansScheduleRemoveMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ toolkit_scans_schedules_delete: ({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type DetectAndInjectScansSchedulesGetQueryQueryVariables = {};


export type DetectAndInjectScansSchedulesGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ detect_and_inject_scans_schedules: ({
      ...{ __typename: 'DetectAndInjectScansSchedulesResponse' },
    ...$Pick<DetectAndInjectScansSchedulesResponse, { time_when_to_scan?: *, toolkit_scan_type?: *, weekdays_when_to_scan?: * }>,
    ...{ detect_and_inject_configuration?: ?({
        ...{ __typename: 'DetectAndInjectConfigurationType' },
      ...$Pick<DetectAndInjectConfigurationType, { api_operation_ids?: *, should_scan_all_api_operations?: * }>,
      ...{ scan_run_configuration?: ?({
          ...{ __typename: 'DetectAndInjectScanRunConfigurationType' },
        ...$Pick<DetectAndInjectScanRunConfigurationType, { http_methods_to_scan?: * }>
      }) }
    }) }
  }) }
});

export type DetectAndInjectScansSchedulesSetQueryMutationVariables = {
  body?: ?DetectAndInjectScansScheduleSetRequestParams,
};


export type DetectAndInjectScansSchedulesSetQueryMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ detect_and_inject_scans_schedules_set: ({
      ...{ __typename: 'DetectAndInjectScansScheduleSetResponse' },
    ...$Pick<DetectAndInjectScansScheduleSetResponse, { weekdays_when_to_scan?: *, time_when_to_scan?: *, toolkit_scan_type?: * }>,
    ...{ detect_and_inject_configuration?: ?({
        ...{ __typename: 'DetectAndInjectConfigurationType' },
      ...{ scan_run_configuration?: ?({
          ...{ __typename: 'DetectAndInjectScanRunConfigurationType' },
        ...$Pick<DetectAndInjectScanRunConfigurationType, { http_methods_to_scan?: * }>
      }) }
    }) }
  }) }
});

export type WebApplicationXssSchedulesGetQueryQueryVariables = {};


export type WebApplicationXssSchedulesGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_application_xss_scans_schedules: ({
      ...{ __typename: 'WebApplicationXssScansSchedulesResponse' },
    ...$Pick<WebApplicationXssScansSchedulesResponse, { time_when_to_scan?: *, toolkit_scan_type?: *, weekdays_when_to_scan?: * }>,
    ...{ web_application_xss_configuration?: ?({
        ...{ __typename: 'WebApplicationXssConfigurationType' },
      ...$Pick<WebApplicationXssConfigurationType, { web_applications_ids?: *, should_scan_all_web_applications?: * }>,
      ...{ scan_run_configuration?: ?({
          ...{ __typename: 'WebApplicationXssScanRunConfigurationType' },
        ...$Pick<WebApplicationXssScanRunConfigurationType, { should_inject_in_get_parameters?: *, should_inject_in_post_parameters?: *, should_inject_in_url_path?: *, intensity?: * }>
      }) }
    }) }
  }) }
});

export type WebApplicationXssScansSchedulesSetQueryMutationVariables = {
  body?: ?WebApplicationXssScansScheduleSetRequestParams,
};


export type WebApplicationXssScansSchedulesSetQueryMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ web_application_xss_scans_schedules_set: ({
      ...{ __typename: 'WebApplicationXssScansSchedulesResponse' },
    ...$Pick<WebApplicationXssScansSchedulesResponse, { time_when_to_scan?: *, toolkit_scan_type?: *, weekdays_when_to_scan?: * }>,
    ...{ web_application_xss_configuration?: ?({
        ...{ __typename: 'WebApplicationXssConfigurationType' },
      ...$Pick<WebApplicationXssConfigurationType, { web_applications_ids?: *, should_scan_all_web_applications?: * }>,
      ...{ scan_run_configuration?: ?({
          ...{ __typename: 'WebApplicationXssScanRunConfigurationType' },
        ...$Pick<WebApplicationXssScanRunConfigurationType, { should_inject_in_get_parameters?: *, should_inject_in_post_parameters?: *, should_inject_in_url_path?: *, intensity?: * }>
      }) }
    }) }
  }) }
});

export type SpecificAppComplianceReportScheduleQueryQueryVariables = {};


export type SpecificAppComplianceReportScheduleQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ specific_apps_compliance_report_schedules: ({
      ...{ __typename: 'AppSpecificComplianceReportScheduleResponse' },
    ...$Pick<AppSpecificComplianceReportScheduleResponse, { time_when_to_scan?: *, toolkit_scan_type: *, weekdays_when_to_scan?: *, should_only_scan_monthly: * }>,
    ...{ app_store_privacy_specific_apps_report_configuration: ({
        ...{ __typename: 'ComplianceReportConfigurationType' },
      ...$Pick<ComplianceReportConfigurationType, { recipient_emails: *, should_report_all_mobile_apps?: *, customer_mobile_app_ids?: * }>
    }) }
  }) }
});

export type GlobalComplianceReportScheduleQueryQueryVariables = {};


export type GlobalComplianceReportScheduleQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ global_compliance_report_schedules: ({
      ...{ __typename: 'GlobalComplianceReportScheduleResponse' },
    ...$Pick<GlobalComplianceReportScheduleResponse, { time_when_to_scan?: *, toolkit_scan_type: *, weekdays_when_to_scan?: *, should_only_scan_monthly: * }>,
    ...{ app_store_privacy_global_report_configuration: ({
        ...{ __typename: 'ComplianceReportConfigurationType' },
      ...$Pick<ComplianceReportConfigurationType, { recipient_emails: *, should_report_all_mobile_apps?: *, customer_mobile_app_ids?: * }>
    }) }
  }) }
});

export type AppStorePrivacyGlobalScheduleUpdateMutationVariables = {
  body: ComplianceReportScheduleParams,
};


export type AppStorePrivacyGlobalScheduleUpdateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ app_store_privacy_global_report_update: ({
      ...{ __typename: 'ComplianceReportScheduleResponse' },
    ...$Pick<ComplianceReportScheduleResponse, { time_when_to_scan?: *, toolkit_scan_type: *, weekdays_when_to_scan?: *, should_only_scan_monthly: * }>,
    ...{ app_store_privacy_global_report_configuration?: ?({
        ...{ __typename: 'ComplianceReportConfigurationType' },
      ...$Pick<ComplianceReportConfigurationType, { recipient_emails: *, should_report_all_mobile_apps?: *, customer_mobile_app_ids?: * }>
    }) }
  }) }
});

export type AppStorePrivacySpecificAppsScheduleUpdateMutationVariables = {
  body: ComplianceReportScheduleParams,
};


export type AppStorePrivacySpecificAppsScheduleUpdateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ app_store_privacy_specific_apps_report_update: ({
      ...{ __typename: 'ComplianceReportScheduleResponse' },
    ...$Pick<ComplianceReportScheduleResponse, { time_when_to_scan?: *, toolkit_scan_type: *, weekdays_when_to_scan?: *, should_only_scan_monthly: * }>,
    ...{ app_store_privacy_specific_apps_report_configuration?: ?({
        ...{ __typename: 'ComplianceReportConfigurationType' },
      ...$Pick<ComplianceReportConfigurationType, { recipient_emails: *, should_report_all_mobile_apps?: *, customer_mobile_app_ids?: * }>
    }) }
  }) }
});

export type ToolkitScansSchedulesGetQueryVariables = {
  toolkit_scan_type: ToolkitScanTypeEnum,
};


export type ToolkitScansSchedulesGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ toolkit_scans_schedules_get: ({
      ...{ __typename: 'ToolkitScansScheduleGetResponse' },
    ...$Pick<ToolkitScansScheduleGetResponse, { toolkit_scan_type: *, weekdays_when_to_scan: *, time_when_to_scan: * }>,
    ...{ cloud_dlp_configuration?: ?({
        ...{ __typename: 'ToolkitScansScheduleCloudDlpConfig' },
      ...$Pick<ToolkitScansScheduleCloudDlpConfig, { cloud_dlp_analysis_enabled: * }>,
      ...{ scan_run_configuration: ({
          ...{ __typename: 'ToolkitScansSchedulesPutResponseCloudDlpConfigScanRunConfig' },
        ...$Pick<ToolkitScansSchedulesPutResponseCloudDlpConfigScanRunConfig, { aws_cloud_resource_types_to_scan: *, gcp_cloud_resource_types_to_scan: *, azure_cloud_resource_types_to_scan: *, other_cloud_resource_types_to_scan: *, enabled_google_pii_types: * }>
      }) }
    }), cloud_ransomware_encrypt_configuration?: ?({
        ...{ __typename: 'ToolkitScansScheduleCloudRansomwareEncryptConfiguration' },
      ...$Pick<ToolkitScansScheduleCloudRansomwareEncryptConfiguration, { decryption_key: * }>
    }), cloud_ransomware_upload_malware_configuration?: ?({
        ...{ __typename: 'ToolkitScansScheduleCloudRansomwareUploadMalwareConfiguration' },
      ...$Pick<ToolkitScansScheduleCloudRansomwareUploadMalwareConfiguration, { malware_types_to_upload: * }>
    }) }
  }) }
});

export type ToolkitScansSchedulesPutMutationVariables = {
  toolkit_scan_type: ToolkitScanTypeEnum,
  body: ToolkitScansSchedulesPutParams,
};


export type ToolkitScansSchedulesPutMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ toolkit_scans_schedules_put: ({
      ...{ __typename: 'ToolkitScanSchedulesPutResponse' },
    ...$Pick<ToolkitScanSchedulesPutResponse, { toolkit_scan_type: *, weekdays_when_to_scan: *, time_when_to_scan: * }>,
    ...{ cloud_dlp_configuration?: ?({
        ...{ __typename: 'ToolkitScansScheduleCloudDlpConfig' },
      ...$Pick<ToolkitScansScheduleCloudDlpConfig, { cloud_dlp_analysis_enabled: * }>,
      ...{ scan_run_configuration: ({
          ...{ __typename: 'ToolkitScansSchedulesPutResponseCloudDlpConfigScanRunConfig' },
        ...$Pick<ToolkitScansSchedulesPutResponseCloudDlpConfigScanRunConfig, { aws_cloud_resource_types_to_scan: *, gcp_cloud_resource_types_to_scan: *, azure_cloud_resource_types_to_scan: *, other_cloud_resource_types_to_scan: *, enabled_google_pii_types: * }>
      }) }
    }), cloud_ransomware_encrypt_configuration?: ?({
        ...{ __typename: 'ToolkitScansScheduleCloudRansomwareEncryptConfiguration' },
      ...$Pick<ToolkitScansScheduleCloudRansomwareEncryptConfiguration, { decryption_key: * }>
    }), cloud_ransomware_upload_malware_configuration?: ?({
        ...{ __typename: 'ToolkitScansScheduleCloudRansomwareUploadMalwareConfiguration' },
      ...$Pick<ToolkitScansScheduleCloudRansomwareUploadMalwareConfiguration, { malware_types_to_upload: * }>
    }) }
  }) }
});

export type ApiChangesStatsQueryVariables = {};


export type ApiChangesStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_changes_stats: ({
      ...{ __typename: 'ApiChangesStatsResponse' },
    ...$Pick<ApiChangesStatsResponse, { api_changes_total_count: * }>,
    ...{ api_changes_stats: Array<({
        ...{ __typename: 'ApiChangesStatsResponseItem' },
      ...$Pick<ApiChangesStatsResponseItem, { api_change_type: *, api_change_name: *, api_change_count: *, api_change_meta_type: * }>
    })> }
  }) }
});

export type ApisAnalysisQueryVariables = {};


export type ApisAnalysisQuery = ({
    ...{ __typename: 'Query' },
  ...{ apis_analysis: ({
      ...{ __typename: 'ApisAnalysisResponse' },
    ...{ global_datapoints: Array<({
        ...{ __typename: 'ApisAnalysisGlobalDataPointItem' },
      ...$Pick<ApisAnalysisGlobalDataPointItem, { datapoint_name: *, datapoint_count: *, secondary_datapoint_count?: *, secondary_datapoint_name?: * }>
    })>, apis_analysis: Array<({
        ...{ __typename: 'ApisAnalysisResponseItem' },
      ...$Pick<ApisAnalysisResponseItem, { date: * }>,
      ...{ datapoints: Array<({
          ...{ __typename: 'ApisAnalysisGlobalDataPointItem' },
        ...$Pick<ApisAnalysisGlobalDataPointItem, { datapoint_name: *, datapoint_count: *, secondary_datapoint_count?: *, secondary_datapoint_name?: * }>
      })> }
    })> }
  }) }
});

export type ShadowAssetsStatsQueryVariables = {};


export type ShadowAssetsStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ shadow_assets_stats: ({
      ...{ __typename: 'ShadowAssetsStatsResponse' },
    ...{ shadow_assets_stats: Array<({
        ...{ __typename: 'ShadowAssetsStatsResponseItem' },
      ...$Pick<ShadowAssetsStatsResponseItem, { shadow_reason: *, shadow_reason_name: *, shadow_assets_count: * }>
    })> }
  }) }
});

export type ApiAssetsChangesQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type ApiAssetsChangesQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_assets_changes: ({
      ...{ __typename: 'ApiAssetsChangesResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), included_api_changes_stats: ({
        ...{ __typename: 'ApiAssetsChangesResponseStats' },
      ...$Pick<ApiAssetsChangesResponseStats, { last_year_changes_count: *, last_30_days_changes_count: *, last_7_days_changes_count: *, last_30_days_changes_with_issues_count: *, changes_with_issues_count: * }>
    }), api_changes: Array<?({
        ...{ __typename: 'ApiAssetsChangesResponseItem' },
      ...$Pick<ApiAssetsChangesResponseItem, { api_change_icon_url: *, api_change_description: *, api_change_type: *, api_change_name: *, date_created: *, asset_name: *, asset_type_icon_url: *, asset_url: *, http_method?: *, path?: *, api_authentication_scheme_names?: *, detected_pii_types?: *, is_internet_accessible: *, is_compliant_to_security_policy: *, issues_count?: *, discovered_via: *, discovered_via_icon_url: *, discovered_via_name: * }>
    })> }
  }) }
});

export type InventoryV2KeywordSearchValuesQueryVariables = {};


export type InventoryV2KeywordSearchValuesQuery = ({
    ...{ __typename: 'Query' },
  ...{ inventory_v2_keyword_search_values: ({
      ...{ __typename: 'InventoryV2KeywordSearchValuesResponse' },
    ...{ assets_named_filters: Array<({
        ...{ __typename: 'NamedFilterItem' },
      ...$Pick<NamedFilterItem, { name: *, value: *, icon_url?: *, group?: * }>
    })> }
  }) }
});

export type AssetsNamedFiltersQueryVariables = {};


export type AssetsNamedFiltersQuery = ({
    ...{ __typename: 'Query' },
  ...{ assets_named_filters: ({
      ...{ __typename: 'AssetsNamedFiltersResponse' },
    ...{ assets_named_filters: Array<({
        ...{ __typename: 'NamedFilterItem' },
      ...$Pick<NamedFilterItem, { name: *, value: *, icon_url?: *, group?: * }>
    })> }
  }) }
});

export type ApiChangesNamedFiltersQueryVariables = {};


export type ApiChangesNamedFiltersQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_changes_named_filters: ({
      ...{ __typename: 'ApiChangesNamedFiltersResponse' },
    ...{ api_changes_named_filters: Array<({
        ...{ __typename: 'NamedFilterItem' },
      ...$Pick<NamedFilterItem, { name: *, value: *, icon_url?: *, group?: * }>
    })> }
  }) }
});

export type KubernetesClusterComponentResourceQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type KubernetesClusterComponentResourceQuery = ({
    ...{ __typename: 'Query' },
  ...{ kubernetes_cluster_component_resource: ({
      ...{ __typename: 'KubernetesClusterComponent' },
    ...$Pick<KubernetesClusterComponent, { asset_type_icon_url: *, asset_type_name: *, component_type: *, component_type_name: *, date_created: *, date_no_longer_accessible?: *, discovered_via: *, discovered_via_icon_url: *, discovered_via_name: *, hosted_on?: *, id: *, metadata_creation_timestamp: *, metadata_labels?: *, metadata_name: *, metadata_namespace: *, metadata_uid: *, open_important_policy_violations_count: *, open_proactive_policy_violations_count: *, open_urgent_policy_violations_count: *, status: * }>,
    ...{ parent_kubernetes_cluster_asset: ({
        ...{ __typename: 'KubernetesClusterComponentParentCluster' },
      ...$Pick<KubernetesClusterComponentParentCluster, { cluster_name: *, kubernetes_version: *, id: * }>
    }) }
  }) }
});

export type KubernetesClusterResourceQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type KubernetesClusterResourceQuery = ({
    ...{ __typename: 'Query' },
  ...{ kubernetes_cluster_resource: ({
      ...{ __typename: 'KubernetesClusterResourceResponse' },
    ...$Pick<KubernetesClusterResourceResponse, { asset_type_icon_url: *, asset_type_name: *, cluster_name: *, date_created: *, date_no_longer_accessible?: *, discovered_via: *, discovered_via_icon_url: *, discovered_via_name: *, hosted_on?: *, id: *, kubernetes_cluster_components_count: *, kubernetes_control_plane_url: *, kubernetes_version: *, open_important_policy_violations_count: *, open_proactive_policy_violations_count: *, open_urgent_policy_violations_count: *, status: * }>
  }) }
});

export type KubernetesClusterComponentsQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type KubernetesClusterComponentsQuery = ({
    ...{ __typename: 'Query' },
  ...{ kubernetes_cluster_components: ({
      ...{ __typename: 'KubernetesClusterComponentsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), kubernetes_cluster_components: Array<({
        ...{ __typename: 'KubernetesClusterComponent' },
      ...$Pick<KubernetesClusterComponent, { asset_type_icon_url: *, asset_type_name: *, component_type: *, component_type_name: *, date_created: *, date_no_longer_accessible?: *, discovered_via: *, discovered_via_icon_url: *, discovered_via_name: *, hosted_on?: *, id: *, metadata_creation_timestamp: *, metadata_labels?: *, metadata_name: *, metadata_namespace: *, metadata_uid: *, open_important_policy_violations_count: *, open_proactive_policy_violations_count: *, open_urgent_policy_violations_count: *, status: * }>,
      ...{ parent_kubernetes_cluster_asset: ({
          ...{ __typename: 'KubernetesClusterComponentParentCluster' },
        ...$Pick<KubernetesClusterComponentParentCluster, { cluster_name: *, kubernetes_version: *, id: * }>
      }) }
    })> }
  }) }
});

export type AuthenticationDetailsListQueryVariables = {
  filter_by_authentication_type: $ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type AuthenticationDetailsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ uploaded_authentication_details_list: ({
      ...{ __typename: 'UploadedAuthenticationDetailsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: *, next_cursor?: *, is_approximate_total_count_enabled?: *, exact_total_count?: *, hint_for_total_count?: * }>
    }), uploaded_authentication_details?: ?Array<({
        ...{ __typename: 'UploadedAuthenticationDetails' },
      ...$Pick<UploadedAuthenticationDetails, { id: *, authentication_type: *, onboarded_for_asset_name?: * }>,
      ...{ oauth2_authentication_details?: ?({
          ...{ __typename: 'Oauth2AuthDetails' },
        ...$Pick<Oauth2AuthDetails, { authentication_url: *, username: *, password: * }>
      }), api_keys_authentication_details?: ?({
          ...{ __typename: 'ApikeyAuthDetails' },
        ...$Pick<ApikeyAuthDetails, { location: *, name: *, value: * }>
      }), basic_auth_authentication_details?: ?({
          ...{ __typename: 'BasicAuthAuthDetails' },
        ...$Pick<BasicAuthAuthDetails, { username?: *, password?: * }>
      }), described_in_uploaded_file_authentication_details?: ?({
          ...{ __typename: 'DescribedInUploadedFileAuthDetails' },
        ...$Pick<DescribedInUploadedFileAuthDetails, { uploaded_authentication_file_hash: * }>
      }) }
    })> }
  }) }
});

export type CloudCiemPolicyRulesQueryVariables = {};


export type CloudCiemPolicyRulesQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_ciem_policy_rules: ({
      ...{ __typename: 'CloudCiemPolicyRulesResponse' },
    ...{ policy_rules: Array<({
        ...{ __typename: 'CloudCiemPolicyRulesResponseItem' },
      ...$Pick<CloudCiemPolicyRulesResponseItem, { alerts_count: *, cloud_provider: *, policy_rule: *, severity: *, threat_type: * }>
    })> }
  }) }
});

export type CloudCiemAlertDetailsQueryVariables = {
  filter_by_threat_type: $ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type CloudCiemAlertDetailsQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_ciem_alert_details: ({
      ...{ __typename: 'CloudCiemAlertDetailsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), alerts: Array<({
        ...{ __typename: 'CloudCiemAlertDetailsResponseItem' },
      ...$Pick<CloudCiemAlertDetailsResponseItem, { date_created: *, event_name: *, event_type: *, flags?: *, source_ip_address: *, threats: *, user_agent: *, user_identity: * }>
    })> }
  }) }
});

export type DownloadMostRecentComplianceReportMutationMutationVariables = {
  body: DownloadMostRecentComplianceReportBody,
};


export type DownloadMostRecentComplianceReportMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ download_most_recent_compliance_report: ({
      ...{ __typename: 'DownloadMostRecentComplianceReportResponse' },
    ...$Pick<DownloadMostRecentComplianceReportResponse, { download_link: * }>
  }) }
});

export type GetLargeFileUploadUrlMutationVariables = {
  body?: ?GetLargeFileUploadUrlParams,
};


export type GetLargeFileUploadUrlMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ get_large_file_upload_url: ({
      ...{ __typename: 'GetLargeFileUploadUrlResponse' },
    ...$Pick<GetLargeFileUploadUrlResponse, { upload_url: * }>
  }) }
});

export type MobileApplicationsNamedFiltersQueryVariables = {};


export type MobileApplicationsNamedFiltersQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_applications_named_filters: ({
      ...{ __typename: 'MobileApplicationsNamedFiltersResponse' },
    ...{ mobile_applications_named_filters: Array<({
        ...{ __typename: 'NamedFilterItem' },
      ...$Pick<NamedFilterItem, { name: *, value: *, icon_url?: * }>
    })> }
  }) }
});

export type NetworkTelemetryStatsListCloudQueryVariables = {};


export type NetworkTelemetryStatsListCloudQuery = ({
    ...{ __typename: 'Query' },
  ...{ network_telemetry_threat_event_stats_cloud_list: ({
      ...{ __typename: 'NetworkThreatEventStatsResponse' },
    ...{ stats_per_date: Array<({
        ...{ __typename: 'NetworkTelemetryEventStatsPerDateItem' },
      ...$Pick<NetworkTelemetryEventStatsPerDateItem, { date: *, primary_metric_count: *, primary_metric_name: *, secondary_metric_name: *, secondary_metric_count: * }>,
      ...{ events_count: Array<({
          ...{ __typename: 'NetworkTelemetryEventCountItem' },
        ...$Pick<NetworkTelemetryEventCountItem, { count: *, event_type: *, event_type_name: * }>
      })> }
    })>, stats_summary: ({
        ...{ __typename: 'NetworkTelemetryEventsStatsSummary' },
      ...$Pick<NetworkTelemetryEventsStatsSummary, { primary_metric_count: *, primary_metric_name: *, secondary_metric_count: *, secondary_metric_name: * }>,
      ...{ events_count: Array<({
          ...{ __typename: 'NetworkTelemetryEventCountItem' },
        ...$Pick<NetworkTelemetryEventCountItem, { count: *, event_type: *, event_type_name: * }>
      })> }
    }) }
  }) }
});

export type NetworkTelemetryStatsListApiQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type NetworkTelemetryStatsListApiQuery = ({
    ...{ __typename: 'Query' },
  ...{ network_telemetry_threat_event_stats_api_list: ({
      ...{ __typename: 'NetworkThreatEventStatsResponse' },
    ...{ stats_per_date: Array<({
        ...{ __typename: 'NetworkTelemetryEventStatsPerDateItem' },
      ...$Pick<NetworkTelemetryEventStatsPerDateItem, { date: *, primary_metric_count: *, primary_metric_name: *, secondary_metric_count: *, secondary_metric_name: * }>,
      ...{ events_count: Array<({
          ...{ __typename: 'NetworkTelemetryEventCountItem' },
        ...$Pick<NetworkTelemetryEventCountItem, { count: *, event_type: *, event_type_name: * }>
      })> }
    })>, stats_summary: ({
        ...{ __typename: 'NetworkTelemetryEventsStatsSummary' },
      ...$Pick<NetworkTelemetryEventsStatsSummary, { primary_metric_count: *, primary_metric_name: *, secondary_metric_count: *, secondary_metric_name: * }>,
      ...{ events_count: Array<({
          ...{ __typename: 'NetworkTelemetryEventCountItem' },
        ...$Pick<NetworkTelemetryEventCountItem, { count: *, event_type: *, event_type_name: * }>
      })> }
    }) }
  }) }
});

export type NetworkTelemetryStatsListMobileQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type NetworkTelemetryStatsListMobileQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_network_telemetry_threat_event_stats_api_list: ({
      ...{ __typename: 'NetworkThreatEventStatsResponse' },
    ...{ stats_per_date: Array<({
        ...{ __typename: 'NetworkTelemetryEventStatsPerDateItem' },
      ...$Pick<NetworkTelemetryEventStatsPerDateItem, { date: *, primary_metric_count: *, primary_metric_name: *, secondary_metric_count: *, secondary_metric_name: * }>,
      ...{ events_count: Array<({
          ...{ __typename: 'NetworkTelemetryEventCountItem' },
        ...$Pick<NetworkTelemetryEventCountItem, { count: *, event_type: *, event_type_name: * }>
      })> }
    })>, stats_summary: ({
        ...{ __typename: 'NetworkTelemetryEventsStatsSummary' },
      ...$Pick<NetworkTelemetryEventsStatsSummary, { primary_metric_count: *, primary_metric_name: *, secondary_metric_count: *, secondary_metric_name: * }>,
      ...{ events_count: Array<({
          ...{ __typename: 'NetworkTelemetryEventCountItem' },
        ...$Pick<NetworkTelemetryEventCountItem, { count: *, event_type: *, event_type_name: * }>
      })> }
    }) }
  }) }
});

export type NetworkTelemetryAffectedAssetsThreatEventsQueryVariables = {
  asset_uuid: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_threat_type: $ElementType<Scalars, 'String'>,
  page_size?: ?$ElementType<Scalars, 'Int'>,
};


export type NetworkTelemetryAffectedAssetsThreatEventsQuery = ({
    ...{ __typename: 'Query' },
  ...{ network_telemetry_affected_assets_threat_events_list: ({
      ...{ __typename: 'NetworkTelemetryThreatEventsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: *, next_cursor?: * }>
    }), threat_events?: ?Array<({
        ...{ __typename: 'NetworkTelemetryThreatEvent' },
      ...$Pick<NetworkTelemetryThreatEvent, { alphasoc_threat_title: *, alphasoc_threat_type: *, event_dns_qtype?: *, event_dns_query?: *, event_ip_action?: *, event_ip_dest_ip?: *, event_ip_dest_port?: *, event_ip_dest_proto?: *, event_ip_proto_name?: *, src_host: *, src_ip?: *, src_port?: *, ts: *, wisdom: * }>
    })> }
  }) }
});

export type NetworkTelemetryAffectedAssetsThreatEventsStatsQueryVariables = {
  asset_uuid: $ElementType<Scalars, 'ID'>,
};


export type NetworkTelemetryAffectedAssetsThreatEventsStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ network_telemetry_affected_asset_threat_event_stats: ({
      ...{ __typename: 'NetworkThreatEventStatsResponse' },
    ...{ stats_per_date: Array<({
        ...{ __typename: 'NetworkTelemetryEventStatsPerDateItem' },
      ...$Pick<NetworkTelemetryEventStatsPerDateItem, { date: *, primary_metric_count: *, primary_metric_name: *, secondary_metric_name: *, secondary_metric_count: * }>,
      ...{ events_count: Array<({
          ...{ __typename: 'NetworkTelemetryEventCountItem' },
        ...$Pick<NetworkTelemetryEventCountItem, { count: *, event_type: *, event_type_name: * }>
      })> }
    })>, stats_summary: ({
        ...{ __typename: 'NetworkTelemetryEventsStatsSummary' },
      ...$Pick<NetworkTelemetryEventsStatsSummary, { primary_metric_count: *, primary_metric_name: *, secondary_metric_name: *, secondary_metric_count: * }>,
      ...{ events_count: Array<({
          ...{ __typename: 'NetworkTelemetryEventCountItem' },
        ...$Pick<NetworkTelemetryEventCountItem, { count: *, event_type: *, event_type_name: * }>
      })> }
    }) }
  }) }
});

export type NetworkTelemetryAffectedAssetsPolicyViolationsQueryVariables = {
  asset_uuid: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_threat_type?: ?$ElementType<Scalars, 'String'>,
};


export type NetworkTelemetryAffectedAssetsPolicyViolationsQuery = ({
    ...{ __typename: 'Query' },
  ...{ network_telemetry_affected_assets_policy_violations_list: ({
      ...{ __typename: 'NetworkTelemetryPolicyViolationSummaryResponse' },
    ...{ network_telemetry_policy_violations?: ?Array<({
        ...{ __typename: 'PolicyViolationV2' },
      ...$Pick<PolicyViolationV2, { id: *, date_created: *, date_resolved?: *, date_last_checked?: *, exception_type?: *, additional_info?: *, status: *, exception_date_created?: *, exception_explanation?: * }>,
      ...{ external_integrations?: ?Array<?({
          ...{ __typename: 'PolicyViolationV2ExternalIntegration' },
        ...$Pick<PolicyViolationV2ExternalIntegration, { id: *, external_key: *, external_url: *, integration_icon_url: *, integration_type: * }>
      })>, auto_remediation_info?: ?({
          ...{ __typename: 'Remediation' },
        ...$Pick<Remediation, { remediation_instructions: *, remediation_url: * }>
      }), http_trace?: ?({
          ...{ __typename: 'PolicyViolationV2HttpTrace' },
        ...$Pick<PolicyViolationV2HttpTrace, { http_request: *, http_response: * }>
      }), affected_asset: ({
          ...{ __typename: 'PolicyViolationAffectedAsset' },
        ...$Pick<PolicyViolationAffectedAsset, { id: *, name?: *, url?: *, cloud_console_url?: *, asset_type: *, asset_type_icon_url: *, asset_type_name: *, date_created: *, hosted_on?: *, aws_arn?: * }>,
        ...{ belongs_to_cloud_account?: ?({
            ...{ __typename: 'BelongsToCloudAccount' },
          ...$Pick<BelongsToCloudAccount, { id: *, cloud_account_icon_url: *, cloud_account_type_name: *, discovered_via_cloud_authenticator_id: *, customer_supplied_name?: * }>
        }) }
      }), violated_policy_rule: ({
          ...{ __typename: 'PolicyRuleV2' },
        ...$Pick<PolicyRuleV2, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: *, title: *, description: *, recommendation?: *, default_relevance?: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })>, related_rule_types?: ?Array<({
              ...{ __typename: 'PolicyRuleTypeRelatedRuleType' },
            ...$Pick<PolicyRuleTypeRelatedRuleType, { id: *, title: *, description: *, recommendation?: *, default_relevance?: * }>,
            ...{ compliance_policy_references: Array<({
                ...{ __typename: 'PolicyComplianceReference' },
              ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
            })> }
          })> }
        }) }
      }) }
    })>, pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }) }
  }) }
});

export type NetworkTelemetryConfigurationsCreateMutationVariables = {};


export type NetworkTelemetryConfigurationsCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ network_telemetry_configurations_create: ({
      ...{ __typename: 'NetworkTelemetryConfigurationsResponse' },
    ...$Pick<NetworkTelemetryConfigurationsResponse, { id: *, is_new: *, aws_vpc_flow_logs_destination: *, aws_route53_resolver_query_logs_destination: * }>
  }) }
});

export type GetNamedFiltersPoliciesQueryVariables = {};


export type GetNamedFiltersPoliciesQuery = ({
    ...{ __typename: 'Query' },
  ...{ get_named_filters_policies: ({
      ...{ __typename: 'GetNamedFiltersPoliciesResponse' },
    ...{ polices_named_filters: Array<({
        ...{ __typename: 'NamedFilterItem' },
      ...$Pick<NamedFilterItem, { name: *, value: *, icon_url?: *, group?: * }>
    })> }
  }) }
});

export type GetPolicyViolationsKeywordSearchItemsQueryVariables = {
  filter_by_search_ui: $ElementType<Scalars, 'String'>,
};


export type GetPolicyViolationsKeywordSearchItemsQuery = ({
    ...{ __typename: 'Query' },
  ...{ get_policy_violations_keyword_search_items: ({
      ...{ __typename: 'PolicyViolationsKeywordSearchResponse' },
    ...{ policy_violations_named_filters: Array<({
        ...{ __typename: 'NamedFilterItem' },
      ...$Pick<NamedFilterItem, { value: *, name: *, icon_url?: *, group?: * }>
    })> }
  }) }
});

export type PolicyViolationExportToJiraMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: PolicyViolationEportToJiraParams,
};


export type PolicyViolationExportToJiraMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ policy_violation_export_jira: ({
      ...{ __typename: 'PolicyViolationExportToJira' },
    ...$Pick<PolicyViolationExportToJira, { external_key: *, external_url: *, id: *, integration_icon_url: *, integration_type: * }>
  }) }
});

export type PolicyViolationListV3QueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  order_by?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
  was_discovered_after?: ?$ElementType<Scalars, 'Date'>,
  filter_by_importance_tag?: ?Array<?FilterByImportanceTagEnum>,
  filter_by_product?: ?Array<?FilterByProductEnum>,
  filter_by_violation_status?: ?Array<?PolicyViolationStatusEnum>,
  filter_by_relevance?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_asset_group_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_asset_ownership?: ?$ElementType<Scalars, 'String'>,
  filter_by_related_first_party_asset_ids?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_no_asset_group?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_asset_vendor_ids?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_policy_rule_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_policy_rule_type_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_ongoing_triaging_effort?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type PolicyViolationListV3Query = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list_v2: ({
      ...{ __typename: 'PolicyViolationListResponseVersion2' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolationV2' },
      ...$Pick<PolicyViolationV2, { id: *, status: *, date_created: * }>,
      ...{ violated_policy_rule: ({
          ...{ __typename: 'PolicyRuleV2' },
        ...$Pick<PolicyRuleV2, { id: *, severity?: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { title: * }>
        }) }
      }), affected_asset: ({
          ...{ __typename: 'PolicyViolationAffectedAsset' },
        ...$Pick<PolicyViolationAffectedAsset, { id: *, name?: *, url?: *, asset_type_icon_url: * }>
      }), included_http_trace?: ?({
          ...{ __typename: 'PolicyViolationV2HttpTrace' },
        ...$Pick<PolicyViolationV2HttpTrace, { http_request: * }>
      }) }
    })> }
  }) }
});

export type SastIssuesSearchFilterValuesQueryVariables = {};


export type SastIssuesSearchFilterValuesQuery = ({
    ...{ __typename: 'Query' },
  ...{ sast_issues_search_filter_values: ({
      ...{ __typename: 'SastSearchFilterResponse' },
    ...{ sast_issues_search_filter_values: Array<({
        ...{ __typename: 'AssetsSearchFilterValue' },
      ...$Pick<AssetsSearchFilterValue, { belongs_to_filter: *, icon_url: *, name: *, value: *, value_category?: * }>
    })> }
  }) }
});

export type SastFilesWithIssuesQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  repoId: $ElementType<Scalars, 'String'>,
  filter_by_type?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_severity?: ?Array<?$ElementType<Scalars, 'String'>>,
};


export type SastFilesWithIssuesQuery = ({
    ...{ __typename: 'Query' },
  ...{ sast_files_with_issues: ({
      ...{ __typename: 'SastFilesWithIssuesResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), files_with_issues: Array<({
        ...{ __typename: 'SastFilesWithIssuesResponseItem' },
      ...$Pick<SastFilesWithIssuesResponseItem, { detected_file_type: *, detected_file_type_icon_url: *, file_name: *, id: *, total_issues_count?: * }>
    })> }
  }) }
});

export type SastRepositoryIssuesQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  repoId: $ElementType<Scalars, 'String'>,
  filter_by_present_in_file_id: $ElementType<Scalars, 'String'>,
  filter_by_present_in_branch_name?: ?$ElementType<Scalars, 'String'>,
  filter_by_status?: ?$ElementType<Scalars, 'String'>,
  filter_by_type?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_severity?: ?Array<?$ElementType<Scalars, 'String'>>,
};


export type SastRepositoryIssuesQuery = ({
    ...{ __typename: 'Query' },
  ...{ sast_repository_issues: ({
      ...{ __typename: 'SastRepositoryIssuesResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), issues: Array<({
        ...{ __typename: 'SastRepositoryIssuesResponseItem' },
      ...$Pick<SastRepositoryIssuesResponseItem, { issue_type_display_name?: *, detected_in_branch_name?: *, detected_in_branch_url?: *, detected_on_line?: *, issue_description?: *, issue_title?: *, see_issue_in_platform_url?: *, status?: *, issue_code_snippet: *, severity: *, discovered_date: *, issue_type: *, id: * }>
    })> }
  }) }
});

export type SastListRepositoriesQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_platform?: ?$ElementType<Scalars, 'String'>,
  filter_by_visibility?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type SastListRepositoriesQuery = ({
    ...{ __typename: 'Query' },
  ...{ sast_list_repositories: ({
      ...{ __typename: 'SastListRepositoriesResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), repositories: Array<({
        ...{ __typename: 'SastListRepositoriesResponseItem' },
      ...$Pick<SastListRepositoriesResponseItem, { dependencies_count?: *, files_with_issues_count?: *, first_party_issues_count?: *, sca_issues_count?: *, total_code_lines_count?: *, total_fixed_issues_count?: *, total_issues_count?: *, visibility: *, visibility_display_name: *, platform: *, platform_display_name: *, platform_icon_url: *, full_name: *, html_url: *, name: *, id: * }>
    })> }
  }) }
});

export type SastStatsQueryQueryVariables = {};


export type SastStatsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ sast_stats: ({
      ...{ __typename: 'SastStatsResponse' },
    ...$Pick<SastStatsResponse, { first_party_issues_count: *, sca_issues_count: *, total_code_lines_count: *, total_dependencies_count: *, total_issues_count: *, total_repositories_count: *, total_fixed_issues_count: * }>
  }) }
});

export type SastNamedFiltersQueryVariables = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
};


export type SastNamedFiltersQuery = ({
    ...{ __typename: 'Query' },
  ...{ sast_named_filters: ({
      ...{ __typename: 'SastNamedFiltersResponse' },
    ...{ repositories_findings_named_filters: Array<({
        ...{ __typename: 'MobileProtectNamedFilterItem' },
      ...$Pick<MobileProtectNamedFilterItem, { name: *, value: *, icon_url?: *, has_children?: * }>,
      ...{ children?: ?Array<({
          ...{ __typename: 'MobileProtectNamedFilterItemChildrenItem' },
        ...$Pick<MobileProtectNamedFilterItemChildrenItem, { name: *, value: *, icon_url?: *, has_data?: * }>
      })> }
    })> }
  }) }
});

export type SastRepositoryIssuesIssuesByIdQueryVariables = {
  repoId: $ElementType<Scalars, 'String'>,
  issueId: $ElementType<Scalars, 'String'>,
};


export type SastRepositoryIssuesIssuesByIdQuery = ({
    ...{ __typename: 'Query' },
  ...{ sast_repository_issues_issue_by_id: ({
      ...{ __typename: 'SastRepositoryIssuesIssueByIdResponse' },
    ...$Pick<SastRepositoryIssuesIssueByIdResponse, { repository_name?: *, detected_in_branch_icon_url: *, issue_type_display_name?: *, status?: *, detected_in_file_icon_url: *, detected_in_file_name: *, detected_in_file_path: *, repository_html_url: *, repository_platform: *, repository_platform_display_name: *, repository_platform_icon_url: *, discovered_date: *, detected_in_branch_name: *, detected_in_branch_url: *, issue_type: *, issue_code_snippet: *, issue_description: *, issue_title: *, detected_on_line: *, id: *, see_issue_in_platform_url: *, severity: * }>
  }) }
});

export type SastNamedFiltersChildrenQueryVariables = {
  name: $ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type SastNamedFiltersChildrenQuery = ({
    ...{ __typename: 'Query' },
  ...{ sast_named_filters_children: ({
      ...{ __typename: 'SastNamedFiltersChildrenResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), children: Array<?({
        ...{ __typename: 'SecurityFindingsNamedFiltersChildren' },
      ...$Pick<SecurityFindingsNamedFiltersChildren, { has_children?: *, icon_url?: *, name?: *, value?: * }>,
      ...{ children?: ?Array<?({
          ...{ __typename: 'SecurityFindingsNamedFiltersChildren' },
        ...$Pick<SecurityFindingsNamedFiltersChildren, { icon_url?: *, name?: *, value?: * }>
      })> }
    })> }
  }) }
});

export type SoapApiOperationsQueryVariables = {
  soap_api_id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  include_api_radar_stats?: ?$ElementType<Scalars, 'Boolean'>,
};


export type SoapApiOperationsQuery = ({
    ...{ __typename: 'Query' },
  ...{ soap_api_operations: ({
      ...{ __typename: 'SoapApiOperationsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: *, is_approximate_total_count_enabled?: *, exact_total_count?: *, hint_for_total_count?: * }>
    }), soap_api_operations: Array<({
        ...{ __typename: 'SoapOperation' },
      ...$Pick<SoapOperation, { open_urgent_policy_violations_count: *, open_important_policy_violations_count: *, open_proactive_policy_violations_count: *, id: *, name: *, soap_api_id: *, soap_api_name: *, status: *, asset_type_icon_url: *, asset_type_name: *, date_created: * }>,
      ...{ included_api_radar_stats?: ?({
          ...{ __typename: 'V2ApiOperationApiRadarStats' },
        ...$Pick<V2ApiOperationApiRadarStats, { detect_and_inject_violation_id?: *, hack_and_extract_violation_id?: *, leaky_apis_violation_id?: *, ssrf_violation_id?: * }>
      }) }
    })> }
  }) }
});

export type VendorsListQueryVariables = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_related_first_party_asset_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_related_first_party_asset_type?: ?Array<?SupplyChainSecureAppTypeEnum>,
  filter_by_asset_type?: ?Array<?AssetTypeEnum>,
  filter_by_uses_third_party_vendor_id?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_asset_type_group?: ?Array<?ThirdPartyAssetTypeGroupEnum>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?VendorsListInclude,
  page_size?: ?$ElementType<Scalars, 'Float'>,
  order_by?: ?VendorListOrderByEnum,
};


export type VendorsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ vendors_list: ({
      ...{ __typename: 'VendorsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), vendors: Array<({
        ...{ __typename: 'VendorsListVendor' },
      ...$Pick<VendorsListVendor, { id: *, icon_url?: *, name: *, website_url?: *, date_created?: *, date_updated?: * }>,
      ...{ included_supply_chain_secure_stats?: ?({
          ...{ __typename: 'VendorsListVendorIncludeSupplyChainSecureStats' },
        ...$Pick<VendorsListVendorIncludeSupplyChainSecureStats, { cloud_resources_count: *, graphql_apis_count: *, mobile_sdks_count: *, open_important_policy_violations_count: *, open_proactive_policy_violations_count: *, open_urgent_policy_violations_count: *, related_first_party_mobile_apps_count: *, related_first_party_web_apps_count: *, restful_apis_count: * }>
      }), included_supply_chain_secure_dashboard_metrics?: ?({
          ...{ __typename: 'VendorsListVendorIncludeSupplyChainSecureDashboardStats' },
        ...$Pick<VendorsListVendorIncludeSupplyChainSecureDashboardStats, { embed_rate_percent: *, total_third_party_assets_count: *, total_third_party_assets_count_30_day_trend: *, total_urgent_policy_violations_count: *, total_urgent_policy_violations_count_30_day_trend: * }>
      }) }
    })> }
  }) }
});

export type VendorsMobileSdkListQueryVariables = {
  vendorId: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type VendorsMobileSdkListQuery = ({
    ...{ __typename: 'Query' },
  ...{ vendors_mobile_sdk_list: ({
      ...{ __typename: 'VendorsMobileSdkResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), mobile_sdks?: ?Array<?({
        ...{ __typename: 'VendorsMobileSdk' },
      ...$Pick<VendorsMobileSdk, { id?: *, status?: *, asset_type_icon_url: *, asset_type_name: *, date_created?: *, name?: *, sevenhell_sdk_uuid?: * }>
    })> }
  }) }
});

export type WebApplicationDetailsGetQueryQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type WebApplicationDetailsGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_application_details: ({
      ...{ __typename: 'WebApplicationDetailsResponse' },
    ...{ web_applications: Array<({
        ...{ __typename: 'WebApplication' },
      ...$Pick<WebApplication, { id: *, base_url: *, status: *, date_no_longer_accessible?: *, base_path: *, discovered_via: *, asset_type_name: *, asset_type_icon_url: *, network_service_id: *, date_created: * }>,
      ...{ network_service: ({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, url: *, hosted_on?: *, asset_type_icon_url: *, asset_type_name: * }>,
        ...{ policy_violations: Array<({
            ...{ __typename: 'PolicyViolation' },
          ...$Pick<PolicyViolation, { id: *, status: * }>
        })> }
      }), most_recent_analysis_result: ({
          ...{ __typename: 'WebAnalysisResult' },
        ...$Pick<WebAnalysisResult, { title: *, screenshot_url: *, fullscreen_screenshot_url: *, web_application_type: *, detected_restful_api_ids?: *, exercised_api_operation_ids?: *, date_created: *, date_last_received: * }>,
        ...{ detected_libraries?: ?Array<({
            ...{ __typename: 'WebLibrary' },
          ...$Pick<WebLibrary, { name: *, project_url: *, icon_url: * }>
        })>, detected_network_activity?: ?Array<({
            ...{ __typename: 'WebActivity' },
          ...$Pick<WebActivity, { base_url: *, request_types: * }>
        })> }
      }), policy_violations: Array<({
          ...{ __typename: 'PolicyViolation' },
        ...$Pick<PolicyViolation, { id: *, additional_info?: *, status: *, date_resolved?: *, exception_type?: *, affected_restful_api_id?: *, affected_api_operation_id?: *, affected_network_service_id?: * }>,
        ...{ violated_policy_rule: ({
            ...{ __typename: 'PolicyRule' },
          ...$Pick<PolicyRule, { id: *, relevance: * }>,
          ...{ policy_rule_type: ({
              ...{ __typename: 'PolicyRuleType' },
            ...$Pick<PolicyRuleType, { id: * }>
          }) }
        }) }
      })> }
    })>, restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, title: *, base_url: *, base_path: *, network_service_id: *, asset_type_name: *, asset_type_icon_url: * }>,
      ...{ api_operations?: ?Array<({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, http_method: *, path: * }>
      })> }
    })>, cloud_resources: Array<({
        ...{ __typename: 'CloudResource' },
      ...$Pick<CloudResource, { id: *, date_created: *, name: *, region?: *, resource_type: *, asset_type_icon_url: *, asset_type_name: *, runtime?: *, status: *, date_no_longer_accessible?: *, discovered_via: *, hosted_on?: *, cloud_console_url: *, url?: * }>
    })>, network_services: Array<({
        ...{ __typename: 'NetworkService' },
      ...$Pick<NetworkService, { id: *, url: *, hosted_on?: *, asset_type_icon_url: *, asset_type_name: * }>
    })>, graphql_apis: Array<({
        ...{ __typename: 'GraphqlApi' },
      ...$Pick<GraphqlApi, { id: *, url: *, hosted_on: *, asset_type_icon_url: *, asset_type_name: * }>
    })>, policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, affected_restful_api_id?: *, affected_api_operation_id?: *, affected_network_service_id?: *, status: *, date_resolved?: *, exception_type?: *, date_created: *, exception_date_created?: * }>,
      ...{ violated_policy_rule: ({
          ...{ __typename: 'PolicyRule' },
        ...$Pick<PolicyRule, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: *, title: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })> }
        }) }
      }), affected_api_operation?: ?({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, http_method: *, path: * }>,
        ...{ restful_api: ({
            ...{ __typename: 'RestfulAPI' },
          ...$Pick<RestfulApi, { id: *, base_url: * }>
        }) }
      }), affected_cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { id: *, asset_type_name: *, url?: * }>
      }), affected_network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: * }>,
        ...{ domain_name: ({
            ...{ __typename: 'DomainName' },
          ...$Pick<DomainName, { id: *, name: * }>
        }) }
      }), affected_web_application?: ?({
          ...{ __typename: 'WebApplication' },
        ...$Pick<WebApplication, { id: *, base_url: * }>
      }) }
    })> }
  }) }
});

export type WebApplicationsListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
  order_by?: ?$ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
};


export type WebApplicationsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_applications_list_v2: ({
      ...{ __typename: 'WebApplicationListResponseVersion2' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), web_applications: Array<({
        ...{ __typename: 'WebApplicationVersion2' },
      ...$Pick<WebApplicationVersion2, { id: *, discovered_via: *, network_service_id: *, base_url: *, base_path: *, title: *, screenshot_url: *, date_created: *, status: *, policy_violation_ids?: *, asset_type_name: *, asset_type_icon_url: *, open_important_policy_violations_count?: *, open_proactive_policy_violations_count?: *, open_urgent_policy_violations_count?: *, hosted_on?: * }>,
      ...{ included_detected_spa_credentials_stats?: ?({
          ...{ __typename: 'WebAppCredentialsStat' },
        ...$Pick<WebAppCredentialsStat, { detected_spa_credentials_count: * }>
      }), included_connected_assets_stats?: ?({
          ...{ __typename: 'WebAppConnectedAssetsStats' },
        ...{ api_management_cloud_resources_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), api_operations_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), database_cloud_resources_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), graphql_apis_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), network_services_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), other_cloud_resources_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), secret_management_cloud_resources_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), serverless_cloud_resources_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), storage_cloud_resources_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), virtual_machine_cloud_resources_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }) }
      }), included_xss_toolkit_stats?: ?({
          ...{ __typename: 'WebAppXssToolkitStats' },
        ...$Pick<WebAppXssToolkitStats, { next_xss_scan_run_date?: *, urls_count: *, open_xss_policy_violations_count: *, xss_result: * }>,
        ...{ latest_xss_scan_run?: ?({
            ...{ __typename: 'LatestScanRun' },
          ...$Pick<LatestScanRun, { id: *, date_created: *, scan_type: *, status: * }>
        }) }
      }), included_web_app_protection_stats?: ?({
          ...{ __typename: 'WebAppProtectionStats' },
        ...$Pick<WebAppProtectionStats, { encryption_protection_level_achieved: *, overall_web_app_protection_score: *, third_party_protection_level_achieved: *, xss_protection_level_achieved: * }>
      }) }
    })> }
  }) }
});

export type WebApplicationDetailsGetUrlsQueryQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type WebApplicationDetailsGetUrlsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_application_urls: ({
      ...{ __typename: 'WebApplicationUrlsResponse' },
    ...{ urls: Array<({
        ...{ __typename: 'WebAppSummaryDiscoveredUrl' },
      ...$Pick<WebAppSummaryDiscoveredUrl, { url: * }>,
      ...{ xss_scan_result?: ?({
          ...{ __typename: 'XssScanRunResultUrl' },
        ...{ scanned_get_parameters: Array<({
            ...{ __typename: 'XssScanRunResultUrlGetParameter' },
          ...$Pick<XssScanRunResultUrlGetParameter, { name: *, sample_value?: * }>
        })>, reflected_get_parameters: Array<({
            ...{ __typename: 'XssScanRunResultUrlGetParameterReflected' },
          ...$Pick<XssScanRunResultUrlGetParameterReflected, { name: *, payload?: *, page_src_position_where_reflected?: *, page_src_position_snippet?: * }>
        })>, vulnerable_get_parameters: Array<({
            ...{ __typename: 'XssScanRunResultUrlGetParameterVulnerableToXss' },
          ...$Pick<XssScanRunResultUrlGetParameterVulnerableToXss, { name: *, payload?: *, page_src_position_where_reflected?: *, page_src_position_snippet?: * }>
        })> }
      }) }
    })> }
  }) }
});

export type WebAppProtectionProgressQueryVariables = {};


export type WebAppProtectionProgressQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_app_protection_progress_list: ({
      ...{ __typename: 'WebAppProtectionProgressListResponse' },
    ...{ protection_summary: ({
        ...{ __typename: 'ProtectionSummaryField' },
      ...$Pick<ProtectionSummaryField, { total_protection_score: *, average_protection_score: * }>,
      ...{ most_protected_web_app_summary?: ?({
          ...{ __typename: 'ProtectedWebAppSummaryField' },
        ...$Pick<ProtectedWebAppSummaryField, { web_application_id: *, web_application_url: *, overall_protection_score: * }>
      }), least_protected_web_app_summary?: ?({
          ...{ __typename: 'ProtectedWebAppSummaryField' },
        ...$Pick<ProtectedWebAppSummaryField, { web_application_id: *, web_application_url: *, overall_protection_score: * }>
      }) }
    }), protection_categories_summary: ({
        ...{ __typename: 'ProtectionCategoriesSummaryField' },
      ...{ xss_protection: ({
          ...{ __typename: 'ProtectionCategoryField' },
        ...$Pick<ProtectionCategoryField, { category_id: *, total_levels: * }>,
        ...{ level_summaries: ({
            ...{ __typename: 'LevelSummariesField' },
          ...{ XSS_PROTECTION_LEVEL_ONE: ({
              ...{ __typename: 'XSSProtectionLevelField' },
            ...$Pick<XssProtectionLevelField, { completed_web_applications_count: *, incomplete_web_applications_count: *, per_level_web_applications_count?: * }>
          }), XSS_PROTECTION_LEVEL_TWO: ({
              ...{ __typename: 'XSSProtectionLevelField' },
            ...$Pick<XssProtectionLevelField, { completed_web_applications_count: *, incomplete_web_applications_count: *, per_level_web_applications_count?: * }>
          }), XSS_PROTECTION_LEVEL_THREE: ({
              ...{ __typename: 'XSSProtectionLevelField' },
            ...$Pick<XssProtectionLevelField, { completed_web_applications_count: *, incomplete_web_applications_count: *, per_level_web_applications_count?: * }>
          }) }
        }), component_summaries: ({
            ...{ __typename: 'ComponentSummariesField' },
          ...{ XSS_PROTECTION_BASIC_CSP: ({
              ...{ __typename: 'XSSProtectionLevelField' },
            ...$Pick<XssProtectionLevelField, { completed_web_applications_count: *, incomplete_web_applications_count: *, per_level_web_applications_count?: * }>
          }), XSS_PROTECTION_IMPROVED_CSP: ({
              ...{ __typename: 'XSSProtectionLevelField' },
            ...$Pick<XssProtectionLevelField, { completed_web_applications_count: *, incomplete_web_applications_count: *, per_level_web_applications_count?: * }>
          }), XSS_PROTECTION_ADVANCED_CSP: ({
              ...{ __typename: 'XSSProtectionLevelField' },
            ...$Pick<XssProtectionLevelField, { completed_web_applications_count: *, incomplete_web_applications_count: *, per_level_web_applications_count?: * }>
          }), XSS_PROTECTION_HTTP_ONLY_COOKIES: ({
              ...{ __typename: 'XSSProtectionLevelField' },
            ...$Pick<XssProtectionLevelField, { completed_web_applications_count: *, incomplete_web_applications_count: *, per_level_web_applications_count?: * }>
          }) }
        }) }
      }) }
    }), scan_status: ({
        ...{ __typename: 'ScanStatusField' },
      ...$Pick<ScanStatusField, { last_scanned_date: * }>
    }), web_app_protection_toolkit_data: ({
        ...{ __typename: 'WebAppProtectionToolkitDataField' },
      ...{ xss_protection: ({
          ...{ __typename: 'WebAppProtectionToolkitXSSProtectionField' },
        ...$Pick<WebAppProtectionToolkitXssProtectionField, { id: *, name: * }>,
        ...{ levels: Array<({
            ...{ __typename: 'WebAppProtectionToolkitDataLevelsField' },
          ...$Pick<WebAppProtectionToolkitDataLevelsField, { id: *, category_id: *, summary: *, benefits: *, tradeoffs: * }>,
          ...{ components: Array<({
              ...{ __typename: 'WebAppProtectionToolkitDataLevelsComponentsFields' },
            ...$Pick<WebAppProtectionToolkitDataLevelsComponentsFields, { id: *, category_id: *, category_level_id: *, title: *, corresponding_policy_rule_type_id: * }>,
            ...{ requirements: Array<({
                ...{ __typename: 'WebAppProtectionToolkitDataLevelsComponentRequirementsFields' },
              ...$Pick<WebAppProtectionToolkitDataLevelsComponentRequirementsFields, { id: *, description: * }>
            })> }
          })> }
        })> }
      }) }
    }) }
  }) }
});

export type WebAppProtectionProgressGetQueryQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type WebAppProtectionProgressGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_app_protection_progress_get: ({
      ...{ __typename: 'WebAppProtectionProgressResponse' },
    ...$Pick<WebAppProtectionProgressResponse, { overall_protection_score: *, last_scanned_date: * }>,
    ...{ xss_protection: ({
        ...{ __typename: 'WebAppXSSProtectionCompletedField' },
      ...$Pick<WebAppXssProtectionCompletedField, { highest_completed_level: * }>,
      ...{ components: Array<({
          ...{ __typename: 'WebAppXSSProtectionComponentsCompletedField' },
        ...$Pick<WebAppXssProtectionComponentsCompletedField, { id: *, is_completed: * }>,
        ...{ requirement_statuses: Array<({
            ...{ __typename: 'WebAppXSSProtectionComponentRequirementStatusesCompletedField' },
          ...$Pick<WebAppXssProtectionComponentRequirementStatusesCompletedField, { requirement_type: *, status: *, requirement_status_unmet_reason?: * }>
        })>, additional_information?: ?({
            ...{ __typename: 'WebAppXSSProtectionComponentsAdditionalInformation' },
          ...$Pick<WebAppXssProtectionComponentsAdditionalInformation, { title: *, content_as_markdown: * }>
        }) }
      })> }
    }), web_app_protection_toolkit_data: ({
        ...{ __typename: 'WebAppProtectionToolkitDataField' },
      ...{ xss_protection: ({
          ...{ __typename: 'WebAppProtectionToolkitXSSProtectionField' },
        ...$Pick<WebAppProtectionToolkitXssProtectionField, { id: *, name: * }>,
        ...{ levels: Array<({
            ...{ __typename: 'WebAppProtectionToolkitDataLevelsField' },
          ...$Pick<WebAppProtectionToolkitDataLevelsField, { id: *, category_id: *, summary: *, benefits: *, tradeoffs: * }>,
          ...{ components: Array<({
              ...{ __typename: 'WebAppProtectionToolkitDataLevelsComponentsFields' },
            ...$Pick<WebAppProtectionToolkitDataLevelsComponentsFields, { id: *, category_id: *, category_level_id: *, title: *, corresponding_policy_rule_type_id: * }>,
            ...{ requirements: Array<({
                ...{ __typename: 'WebAppProtectionToolkitDataLevelsComponentRequirementsFields' },
              ...$Pick<WebAppProtectionToolkitDataLevelsComponentRequirementsFields, { id: *, description: * }>
            })> }
          })> }
        })> }
      }) }
    }) }
  }) }
});

export type WebApplicationDetailsV2GetQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type WebApplicationDetailsV2GetQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_applications_details_v2: ({
      ...{ __typename: 'WebApplicationDetailsV2Response' },
    ...$Pick<WebApplicationDetailsV2Response, { id: *, title?: *, discovered_via?: *, discovered_via_icon_url?: *, discovered_via_name?: *, hosted_on?: *, hosted_on_icon_url?: *, hosted_on_name?: *, screenshot_url?: *, base_url: *, date_created: * }>,
    ...{ detected_libraries?: ?Array<?({
        ...{ __typename: 'WebLibrary' },
      ...$Pick<WebLibrary, { enum: *, name: *, project_url: *, icon_url: * }>
    })>, included_supply_chain_secure_my_apps_details?: ?({
        ...{ __typename: 'IncludedSupplyChainSecureMyAppsDetails' },
      ...$Pick<IncludedSupplyChainSecureMyAppsDetails, { related_third_party_assets_count?: *, related_third_party_assets_count_30_day_delta?: *, related_third_party_assets_count_with_violations_30_day_delta?: *, related_third_party_assets_with_violations_count?: *, related_third_party_cloud_resource_count?: *, related_third_party_cloud_resource_count_30_day_delta?: *, related_third_party_combined_apis_count?: *, related_third_party_combined_apis_count_30_day_delta?: *, related_third_party_mobile_sdk_count?: *, related_third_party_mobile_sdk_count_30_day_delta?: *, vendors_count?: *, vendors_count_30_day_delta?: *, full_stack_web_applications_count?: *, full_stack_network_services_count?: *, full_stack_restful_apis_count?: *, full_stack_graphql_apis_count?: * }>
    }) }
  }) }
});

export type UserAssetGroupListQueryVariables = {
  include_access_to_asset_group_id?: ?$ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_access_to_asset_group_id?: ?$ElementType<Scalars, 'ID'>,
};


export type UserAssetGroupListQuery = ({
    ...{ __typename: 'Query' },
  ...{ user_asset_group_list: ({
      ...{ __typename: 'UserAssetGroupListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), users?: ?Array<?({
        ...{ __typename: 'UserAssetGroup' },
      ...$Pick<UserAssetGroup, { id?: *, sevenhell_id?: *, first_name?: *, last_name?: *, login_email?: *, has_access_to_all_asset_groups?: * }>,
      ...{ included_access_to_asset_group?: ?({
          ...{ __typename: 'UserAssetGroupAccesses' },
        ...$Pick<UserAssetGroupAccesses, { asset_group_id?: *, asset_group_name?: *, is_accessible_to_user?: * }>
      }) }
    })> }
  }) }
});

export type VendorManagementStatsQueryVariables = {};


export type VendorManagementStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ vendor_management_stats_get: ({
      ...{ __typename: 'VendorManagementStatsResponse' },
    ...$Pick<VendorManagementStatsResponse, { web_apps_count: *, web_apps_count_30_day_delta: *, vendor_security_score_percent: *, vendor_security_score_percent_30_day_delta: *, total_assets_count: *, total_assets_count_30_day_delta: *, combined_apis_count: *, combined_apis_count_30_day_delta: *, mobile_sdks_count: *, mobile_sdks_count_30_day_delta: *, cloud_resources_count: *, cloud_resources_count_30_day_delta: *, mobile_apps_count: *, mobile_apps_count_30_day_delta: * }>
  }) }
});

export type ApiProtectOnboardingUrlBulkMutationVariables = {
  body?: ?ApiProtectOnboardingUrlBulkParams,
};


export type ApiProtectOnboardingUrlBulkMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ api_protect_onboarding_url_bulk_create: ({
      ...{ __typename: 'ApiProtectOnboardingUrlResponse' },
    ...$Pick<ApiProtectOnboardingUrlResponse, { onboarding_url?: * }>
  }) }
});

export type ApiProtectConfigurationListQueryVariables = {};


export type ApiProtectConfigurationListQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_protect_configuration: ({
      ...{ __typename: 'ApiProtectConfigurationResponse' },
    ...$Pick<ApiProtectConfigurationResponse, { inbound_requests_https_enforcement?: *, should_block_dos_attacks?: *, should_block_malicious_ip_addresses?: *, should_block_sql_injection_attacks?: *, should_block_ssrf_attacks?: * }>,
    ...{ inbound_requests_access_policy?: ?({
        ...{ __typename: 'InboundRequestsAccessPolicy' },
      ...$Pick<InboundRequestsAccessPolicy, { should_restrict_access_based_on_api_keys?: *, should_restrict_access_based_on_countries?: *, should_restrict_access_based_on_domains?: *, should_restrict_access_based_on_email_addresses?: *, should_restrict_access_based_on_ip_ranges?: * }>
    }) }
  }) }
});

export type ApiProtectConfigurationsMutationMutationVariables = {
  body: ApiProtectConfigurationsUpdateParams,
};


export type ApiProtectConfigurationsMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ api_protect_configurations_put: ({
      ...{ __typename: 'ApiProtectConfigurationResponse' },
    ...$Pick<ApiProtectConfigurationResponse, { inbound_requests_https_enforcement?: *, should_block_dos_attacks?: *, should_block_malicious_ip_addresses?: *, should_block_sql_injection_attacks?: *, should_block_ssrf_attacks?: * }>,
    ...{ inbound_requests_access_policy?: ?({
        ...{ __typename: 'InboundRequestsAccessPolicy' },
      ...$Pick<InboundRequestsAccessPolicy, { should_restrict_access_based_on_api_keys?: *, should_restrict_access_based_on_countries?: *, should_restrict_access_based_on_domains?: *, should_restrict_access_based_on_email_addresses?: *, should_restrict_access_based_on_ip_ranges?: * }>
    }) }
  }) }
});

export type ApiProtectProtectedAssetsListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type ApiProtectProtectedAssetsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_protect_protected_assets: ({
      ...{ __typename: 'ApiProtectProtectedAssetsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), protected_assets: Array<?({
        ...{ __typename: 'ApiProtectProtectedAssets' },
      ...$Pick<ApiProtectProtectedAssets, { id: *, name: *, asset_type: *, asset_type_icon_url: *, asset_type_name: *, total_attacks_blocked_count: *, total_attacks_detected_count: * }>
    })> }
  }) }
});

export type ApiProtectAllowedEmailAddressesListQueryVariables = {};


export type ApiProtectAllowedEmailAddressesListQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_protect_protected_allowed_email_addresses: ({
      ...{ __typename: 'ApiProtectAllowedEmailAddresses' },
    ...$Pick<ApiProtectAllowedEmailAddresses, { email_addresses: * }>,
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }) }
  }) }
});

export type ApiProtectConfigurationsAllowedEmailAdressesCreateMutationVariables = {
  body: ApiProtectConfigurationsAllowedEmailAdressesCreateParams,
};


export type ApiProtectConfigurationsAllowedEmailAdressesCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ api_protect_protected_allowed_email_addresses_create: ({
      ...{ __typename: 'ApiProtectAllowedEmailAddressesCreateResponse' },
    ...$Pick<ApiProtectAllowedEmailAddressesCreateResponse, { id?: *, user_or_org_email_address?: * }>
  }) }
});

export type ApiProtectAllowedApiKeysListQueryVariables = {};


export type ApiProtectAllowedApiKeysListQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_protect_protected_allowed_api_keys: ({
      ...{ __typename: 'ApiProtectAllowedApiKeys' },
    ...$Pick<ApiProtectAllowedApiKeys, { api_keys: * }>,
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }) }
  }) }
});

export type ApiProtectConfigurationsAllowedApiKeysCreateMutationVariables = {
  body: ApiProtectConfigurationsAllowedApiKeysCreateParams,
};


export type ApiProtectConfigurationsAllowedApiKeysCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ api_protect_protected_allowed_api_keys_create: ({
      ...{ __typename: 'ApiProtectAllowedApiKeysCreateResponse' },
    ...$Pick<ApiProtectAllowedApiKeysCreateResponse, { id?: *, masked_api_key_value?: * }>
  }) }
});

export type ApiProtectAllowedIpRangesListQueryVariables = {};


export type ApiProtectAllowedIpRangesListQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_protect_protected_allowed_ip_ranges: ({
      ...{ __typename: 'ApiProtectAllowedIpRanges' },
    ...$Pick<ApiProtectAllowedIpRanges, { ip_ranges: * }>,
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }) }
  }) }
});

export type ApiProtectConfigurationsAllowedIpRangesCreateMutationVariables = {
  body: ApiProtectConfigurationsAllowedIpRangesCreateParams,
};


export type ApiProtectConfigurationsAllowedIpRangesCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ api_protect_protected_allowed_ip_ranges_create: ({
      ...{ __typename: 'ApiProtectAllowedIpRangesCreateResponse' },
    ...$Pick<ApiProtectAllowedIpRangesCreateResponse, { id?: *, ip_range?: * }>
  }) }
});

export type ApiProtectAllowedDomainsListQueryVariables = {};


export type ApiProtectAllowedDomainsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_protect_protected_allowed_domains: ({
      ...{ __typename: 'ApiProtectAllowedDomains' },
    ...$Pick<ApiProtectAllowedDomains, { domains: * }>,
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }) }
  }) }
});

export type ApiProtectConfigurationsAllowedDomainsCreateMutationVariables = {
  body: ApiProtectConfigurationsAllowedDomainsCreateParams,
};


export type ApiProtectConfigurationsAllowedDomainsCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ api_protect_protected_allowed_domains_create: ({
      ...{ __typename: 'ApiProtectAllowedDomainsCreateResponse' },
    ...$Pick<ApiProtectAllowedDomainsCreateResponse, { id?: *, domain_name?: * }>
  }) }
});

export type ApiProtectAllowedCountriesListQueryVariables = {};


export type ApiProtectAllowedCountriesListQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_protect_protected_allowed_countries: ({
      ...{ __typename: 'ApiProtectAllowedCountries' },
    ...$Pick<ApiProtectAllowedCountries, { countries: * }>,
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }) }
  }) }
});

export type ApiProtectConfigurationsAllowedCountriesCreateMutationVariables = {
  body: ApiProtectConfigurationsAllowedCountriesCreateParams,
};


export type ApiProtectConfigurationsAllowedCountriesCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ api_protect_protected_allowed_countries_create: ({
      ...{ __typename: 'ApiProtectAllowedCountriesCreateResponse' },
    ...$Pick<ApiProtectAllowedCountriesCreateResponse, { id?: *, country_code?: * }>
  }) }
});

export type ApiProtectOnboardingUrlMutationVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?ApiProtectOnboardingUrlParams,
};


export type ApiProtectOnboardingUrlMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ api_protect_onboarding_url_create: ({
      ...{ __typename: 'ApiProtectOnboardingUrlResponse' },
    ...$Pick<ApiProtectOnboardingUrlResponse, { onboarding_url?: * }>
  }) }
});

export type ApiProtectRestfulApisListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type ApiProtectRestfulApisListQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_protect_restful_apis: ({
      ...{ __typename: 'ApiProtectRestfulApisResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), restful_apis: Array<?({
        ...{ __typename: 'ApiProtectRestfulApiSummaryField' },
      ...$Pick<ApiProtectRestfulApiSummaryField, { id: *, name: *, url: *, icon_url: *, protection_status?: *, attacks_blocked_count?: *, attacks_detected_count?: *, region?: *, inbound_requests_count?: *, horizon_uuid?: * }>,
      ...{ connector?: ?({
          ...{ __typename: 'ApiProtectRestfulApiSummaryFieldConnector' },
        ...$Pick<ApiProtectRestfulApiSummaryFieldConnector, { api_protect_sdk_flavor?: *, cloud_account_name?: *, connector_icon_url?: *, connector_name?: *, connector_type?: *, network_name?: *, network_url?: * }>
      }), belongs_to_cloud_account?: ?({
          ...{ __typename: 'ApiProtectRestfulApiBelongsToCloudAccount' },
        ...$Pick<ApiProtectRestfulApiBelongsToCloudAccount, { cloud_account_icon_url: *, cloud_account_type_name: *, customer_supplied_name?: *, id: * }>
      }) }
    })> }
  }) }
});

export type ApiProtectRestfulApisDetailsGetQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type ApiProtectRestfulApisDetailsGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_protect_restful_apis_details: ({
      ...{ __typename: 'ApiProtectRestfulApisDetailsResponse' },
    ...$Pick<ApiProtectRestfulApisDetailsResponse, { id: *, name: *, icon_url?: *, protection_status?: *, url?: *, total_attacks_detected_count: *, total_attacks_blocked_count: *, total_inbound_requests_count: *, https_inbound_requests_count: *, ssrf_attacks_detected_count: *, ssrf_attacks_blocked_count: *, sqli_attacks_detected_count: *, sqli_attacks_blocked_count: *, ddos_attacks_detected_count: *, ddos_attacks_blocked_count: *, total_outbound_requests_count: *, https_outbound_requests_count: *, should_block_non_https_requests: *, should_log_non_https_requests: *, malicious_inbound_requests_count: *, malicious_inbound_requests_blocked_count: *, authorized_inbound_requests_count: * }>,
    ...{ attacks_trend: Array<({
        ...{ __typename: 'ApiProtectAttacksTrendItem' },
      ...$Pick<ApiProtectAttacksTrendItem, { day: *, inbound_requests_count: *, attacks_detected_count: *, attacks_blocked_count: * }>
    })>, top_authorized_inbound_requests_ip_addresses: Array<?({
        ...{ __typename: 'ApiProtectGenericTopItem' },
      ...$Pick<ApiProtectGenericTopItem, { value?: *, was_authorized?: *, percentage?: * }>
    })>, top_authorized_inbound_requests_countries: Array<?({
        ...{ __typename: 'ApiProtectGenericTopItem' },
      ...$Pick<ApiProtectGenericTopItem, { value?: *, was_authorized?: *, percentage?: * }>
    })>, top_authorized_inbound_requests_domains: Array<?({
        ...{ __typename: 'ApiProtectGenericTopItem' },
      ...$Pick<ApiProtectGenericTopItem, { value?: *, was_authorized?: *, percentage?: * }>
    })>, top_authenticated_inbound_requests_user_ids: Array<?({
        ...{ __typename: 'ApiProtectGenericTopItem' },
      ...$Pick<ApiProtectGenericTopItem, { value?: *, was_authorized?: *, percentage?: * }>
    })>, top_authenticated_inbound_requests_api_keys: Array<?({
        ...{ __typename: 'ApiProtectGenericTopItem' },
      ...$Pick<ApiProtectGenericTopItem, { value?: *, was_authorized?: *, percentage?: * }>
    })> }
  }) }
});

export type ApiProtectRestfulApisInboundRequestsLogsQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type ApiProtectRestfulApisInboundRequestsLogsQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_protect_restful_apis_inbound_requests_logs: ({
      ...{ __typename: 'ApiProtectRestfulApisInboundRequestsLogsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), inbound_requests_logs: Array<?({
        ...{ __typename: 'ApiProtectRequestsLogField' },
      ...$Pick<ApiProtectRequestsLogField, { id: *, source?: *, date: *, human_readable_request_size?: *, is_tls_encrypted?: *, detected_attack_types?: *, was_blocked?: * }>
    })> }
  }) }
});

export type ApiProtectRestfulApisInboundRequestsLogsStatsQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type ApiProtectRestfulApisInboundRequestsLogsStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_protect_restful_apis_inbound_requests_logs_stats: ({
      ...{ __typename: 'ApiProtectRestfulApisInboundRequestsLogsStatsResponse' },
    ...{ per_country_stats: Array<?({
        ...{ __typename: 'ApiProtectPerCountryStatsItem' },
      ...$Pick<ApiProtectPerCountryStatsItem, { country_name?: *, country_code?: *, inbound_requests_count?: * }>,
      ...{ subdivisions?: ?Array<?({
          ...{ __typename: 'ApiProtectPerCountrySubdivisionStatsIem' },
        ...$Pick<ApiProtectPerCountrySubdivisionStatsIem, { subdivision_name?: *, subdivision_code?: *, inbound_requests_count?: * }>
      })> }
    })> }
  }) }
});

export type ApiProtectRestfulApisAttacksSummaryGetQueryVariables = {};


export type ApiProtectRestfulApisAttacksSummaryGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ restful_apis_attacks_summary: ({
      ...{ __typename: 'ApiProtectRestfulApisAttacksSummaryResponse' },
    ...$Pick<ApiProtectRestfulApisAttacksSummaryResponse, { total_eligible_restful_apis_count: *, total_eligible_restful_apis_protected_count: *, https_inbound_requests_count: *, total_attacks_blocked_count: *, total_attacks_detected_count: *, total_inbound_requests_count: *, total_outbound_requests_count: *, https_outbound_requests_count: * }>,
    ...{ attacks_trend: Array<({
        ...{ __typename: 'ApiProtectAttacksTrendItem' },
      ...$Pick<ApiProtectAttacksTrendItem, { day: *, inbound_requests_count: *, attacks_detected_count: *, attacks_blocked_count: * }>
    })> }
  }) }
});

export type GroupedSecurityEventsByIdQueryVariables = {
  eventId: $ElementType<Scalars, 'String'>,
};


export type GroupedSecurityEventsByIdQuery = ({
    ...{ __typename: 'Query' },
  ...{ grouped_security_events_by_id: ({
      ...{ __typename: 'GroupedSecurityEventsByIdResponse' },
    ...$Pick<GroupedSecurityEventsByIdResponse, { id: *, user_agent?: *, start_date: *, end_date: *, count: *, event_type: *, event_type_name: *, severity: *, severity_name: *, description?: *, action_taken?: *, action_color: *, response_status_code?: *, affected_api_operation_path: *, http_method: * }>,
    ...{ source: ({
        ...{ __typename: 'SecurityEventsResponseSource' },
      ...$Pick<SecurityEventsResponseSource, { source_ip_address: *, source_country_name?: *, source_country_code?: *, source_subdivision_name?: *, source_subdivision_code?: * }>
    }), asset: ({
        ...{ __typename: 'SecurityEventsResponseAsset' },
      ...$Pick<SecurityEventsResponseAsset, { asset_uuid?: *, asset_name: *, asset_type: *, asset_type_name: *, asset_type_icon_url: *, asset_url: * }>
    }), events: Array<({
        ...{ __typename: 'GroupedSecurityEventsByIdResponseEvent' },
      ...$Pick<GroupedSecurityEventsByIdResponseEvent, { raw_request: *, raw_response?: *, id: *, date: *, event_type: *, event_type_name: *, additional_info?: *, severity: *, severity_name: *, path: *, method: * }>,
      ...{ source: ({
          ...{ __typename: 'SecurityEventsResponseSource' },
        ...$Pick<SecurityEventsResponseSource, { source_ip_address: *, source_country_name?: *, source_country_code?: *, source_subdivision_name?: *, source_subdivision_code?: * }>
      }), asset: ({
          ...{ __typename: 'SecurityEventsResponseAsset' },
        ...$Pick<SecurityEventsResponseAsset, { asset_uuid?: *, asset_name: *, asset_type: *, asset_type_name: *, asset_type_icon_url: *, asset_url: * }>
      }) }
    })> }
  }) }
});

export type GroupedSecurityEventsQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
  filter_by_severity?: ?$ElementType<Scalars, 'String'>,
};


export type GroupedSecurityEventsQuery = ({
    ...{ __typename: 'Query' },
  ...{ grouped_security_events: ({
      ...{ __typename: 'GroupedSecurityEventsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), grouped_security_events: Array<({
        ...{ __typename: 'GroupedSecurityEventsResponseItem' },
      ...$Pick<GroupedSecurityEventsResponseItem, { id: *, start_date: *, end_date: *, count: *, event_type: *, event_type_name: *, response_status_code?: *, affected_api_operation_path: *, http_method: *, severity: *, severity_name: * }>,
      ...{ source: ({
          ...{ __typename: 'SecurityEventsResponseSource' },
        ...$Pick<SecurityEventsResponseSource, { source_ip_address: *, source_country_name?: *, source_country_code?: *, source_subdivision_name?: *, source_subdivision_code?: * }>
      }), asset: ({
          ...{ __typename: 'SecurityEventsResponseAsset' },
        ...$Pick<SecurityEventsResponseAsset, { asset_uuid?: *, asset_name: *, asset_type: *, asset_type_name: *, asset_type_icon_url: *, asset_url: * }>
      }) }
    })> }
  }) }
});

export type SecurityEventsQueryVariables = {
  filter_by_asset_id?: ?$ElementType<Scalars, 'ID'>,
  filter_by_event_type?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_source_country_code?: ?$ElementType<Scalars, 'String'>,
  filter_by_source_subdivision_code?: ?$ElementType<Scalars, 'String'>,
  filter_by_severity?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type SecurityEventsQuery = ({
    ...{ __typename: 'Query' },
  ...{ security_events: ({
      ...{ __typename: 'SecurityEventsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), security_events: Array<({
        ...{ __typename: 'SecurityEventsResponseItem' },
      ...$Pick<SecurityEventsResponseItem, { date: *, method: *, path: *, event_type: *, event_type_name: *, severity: *, severity_name: * }>,
      ...{ source: ({
          ...{ __typename: 'SecurityEventsResponseSource' },
        ...$Pick<SecurityEventsResponseSource, { source_ip_address: *, source_country_name?: *, source_country_code?: *, source_subdivision_name?: *, source_subdivision_code?: * }>
      }), asset: ({
          ...{ __typename: 'SecurityEventsResponseAsset' },
        ...$Pick<SecurityEventsResponseAsset, { asset_uuid?: *, asset_url: *, asset_name: *, asset_type: *, asset_type_name: *, asset_type_icon_url: * }>
      }) }
    })> }
  }) }
});

export type PerCountryStatsQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type PerCountryStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ per_country_events_stats: ({
      ...{ __typename: 'PerCountryEventsStatsResponse' },
    ...{ per_country_events_stats: Array<({
        ...{ __typename: 'PerCountryEventsStatsResponseItem' },
      ...$Pick<PerCountryEventsStatsResponseItem, { country_code: *, country_name: *, total_events_count: * }>,
      ...{ events_count: Array<({
          ...{ __typename: 'PerCountryEventsStatsResponseItemEventCount' },
        ...$Pick<PerCountryEventsStatsResponseItemEventCount, { event_type: *, event_type_name: *, count: * }>
      })>, subdivisions?: ?Array<?({
          ...{ __typename: 'PerCountryEventsStatsResponseItemSubdvision' },
        ...$Pick<PerCountryEventsStatsResponseItemSubdvision, { subdivision_code: *, subdivision_name: *, total_events_count: * }>,
        ...{ events_count: Array<({
            ...{ __typename: 'PerCountryEventsStatsResponseItemEventCount' },
          ...$Pick<PerCountryEventsStatsResponseItemEventCount, { event_type: *, event_type_name: *, count: * }>
        })> }
      })> }
    })> }
  }) }
});

export type ProtectionRulesQueryVariables = {};


export type ProtectionRulesQuery = ({
    ...{ __typename: 'Query' },
  ...{ protection_rules: ({
      ...{ __typename: 'ProtectionRulesResponse' },
    ...{ protection_rules: Array<({
        ...{ __typename: 'ProtectionRulesResponseItem' },
      ...$Pick<ProtectionRulesResponseItem, { security_event_type: *, name: * }>
    })> }
  }) }
});

export type ApiProtectRestfulApisRequestDetailsQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  requestId: $ElementType<Scalars, 'ID'>,
};


export type ApiProtectRestfulApisRequestDetailsQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_protect_restful_apis_request_details: ({
      ...{ __typename: 'ApiProtectRestfulApisRequestDetailsResponse' },
    ...$Pick<ApiProtectRestfulApisRequestDetailsResponse, { id: *, date: *, event_type_names: *, path: *, method: *, user_agent?: *, content_type?: *, request_size: *, response_status_code?: *, raw_request: *, raw_response: * }>,
    ...{ source: ({
        ...{ __typename: 'SecurityEventsResponseSource' },
      ...$Pick<SecurityEventsResponseSource, { source_ip_address: *, source_country_name?: *, source_country_code?: *, source_subdivision_name?: *, source_subdivision_code?: * }>
    }), asset: ({
        ...{ __typename: 'SecurityEventsResponseAsset' },
      ...$Pick<SecurityEventsResponseAsset, { asset_uuid?: *, asset_name: *, asset_url: *, asset_type: *, asset_type_name: *, asset_type_icon_url: * }>
    }) }
  }) }
});

export type ProtectRestfulApisRequestsQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
};


export type ProtectRestfulApisRequestsQuery = ({
    ...{ __typename: 'Query' },
  ...{ protect_restful_apis_requests: ({
      ...{ __typename: 'ProtectRestfulApisRequestsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), requests: Array<({
        ...{ __typename: 'ProtectRestfulApisRequestsResponseItem' },
      ...$Pick<ProtectRestfulApisRequestsResponseItem, { id: *, date: *, event_type_names: *, path: *, method: *, user_agent?: *, content_type?: *, request_size: *, response_status_code?: * }>,
      ...{ source: ({
          ...{ __typename: 'SecurityEventsResponseSource' },
        ...$Pick<SecurityEventsResponseSource, { source_ip_address: *, source_country_name?: *, source_country_code?: *, source_subdivision_name?: *, source_subdivision_code?: * }>
      }) }
    })> }
  }) }
});

export type ProtectApiOperationsQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
};


export type ProtectApiOperationsQuery = ({
    ...{ __typename: 'Query' },
  ...{ protect_api_operations: ({
      ...{ __typename: 'ProtectApiOperationsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), api_operations: Array<({
        ...{ __typename: 'ProtectApiOperationsResponseItem' },
      ...$Pick<ProtectApiOperationsResponseItem, { http_method: *, path: *, inbound_requests_count: *, attacks_detected_count: *, attacks_blocked_count: * }>
    })> }
  }) }
});

export type GetThreatsBreakdownQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type GetThreatsBreakdownQuery = ({
    ...{ __typename: 'Query' },
  ...{ threats_breakdown: ({
      ...{ __typename: 'ThreatsBreakdownResponse' },
    ...{ threats_breakdown: Array<({
        ...{ __typename: 'ThreatBreakdown' },
      ...$Pick<ThreatBreakdown, { count: *, display_name: *, security_event_type: *, is_high_severity_security_event: * }>
    })> }
  }) }
});

export type MobilePhishingDiscoveredPhishingAppsStatsQueryVariables = {};


export type MobilePhishingDiscoveredPhishingAppsStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_phishing_discovered_phishing_apps_stats: ({
      ...{ __typename: 'MobilePhishingStatsResponse' },
    ...$Pick<MobilePhishingStatsResponse, { apps_taken_down_following_data_theorem_intervention_count: *, apps_with_take_down_initiated_count: *, discovered_phishing_apps_count: *, discovered_phishing_apps_ignored_count: *, discovered_phishing_apps_removed_count: *, discovered_phishing_brands_count: *, legitimate_apps_with_phishing_version_count: *, onboarded_legitimate_apps_count: * }>
  }) }
});

export type FullSubscriptionInquiryMutationVariables = {
  body: FullSubscriptionInquiryBody,
  id: $ElementType<Scalars, 'ID'>,
};


export type FullSubscriptionInquiryMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ full_subscription_inquiry: ({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type UnlockDataReportMutationVariables = {
  body: UnlockDataReportBody,
  id: $ElementType<Scalars, 'ID'>,
};


export type UnlockDataReportMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ unlock_data_report: ({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type MobilePhishingPostcommentMutationVariables = {
  body: MobilePhishingPostComment,
  id: $ElementType<Scalars, 'ID'>,
};


export type MobilePhishingPostcommentMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ mobile_phishing_post_comment: ({
      ...{ __typename: 'MobilePhishingDiscoveredPhishingAppsResponseResult' },
    ...$Pick<MobilePhishingDiscoveredPhishingAppsResponseResult, { uuid: * }>
  }) }
});

export type TriageDiscoveredAppMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  decision: $ElementType<Scalars, 'String'>,
};


export type TriageDiscoveredAppMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ triage_discovered_app: ({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type DiscoveredAppsSearchFilterValuesQueryVariables = {};


export type DiscoveredAppsSearchFilterValuesQuery = ({
    ...{ __typename: 'Query' },
  ...{ discovered_apps_search_filter_values: ({
      ...{ __typename: 'DiscoveredAppsSearchFilterValuesResponse' },
    ...{ discovered_apps_search_filter_values?: ?Array<?({
        ...{ __typename: 'AssetsSearchFilterValue' },
      ...$Pick<AssetsSearchFilterValue, { belongs_to_filter: *, icon_url: *, name: *, value: *, value_category?: * }>
    })> }
  }) }
});

export type MobilePhishingDiscoveredPhishingAppsQueryVariables = {
  filter_by_discovered_application_keyword?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_infringement_level?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_discovered_app_status?: ?Array<?$ElementType<Scalars, 'String'>>,
  filter_by_triaging_decision?: ?Array<?$ElementType<Scalars, 'String'>>,
  order_by?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type MobilePhishingDiscoveredPhishingAppsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_phishing_discovered_phishing_apps: ({
      ...{ __typename: 'MobilePhishingDiscoveredPhishingAppsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), discovered_phishing_apps: Array<({
        ...{ __typename: 'MobilePhishingDiscoveredPhishingAppsResponseResult' },
      ...$Pick<MobilePhishingDiscoveredPhishingAppsResponseResult, { canonical_id: *, date_seen_first: *, date_seen_last?: *, description: *, discovered_app_status: *, icon_url: *, impact_rating: *, impact_rating_explanation: *, infringement_level: *, matching_keywords: *, name: *, platform: *, publisher_name?: *, screenshot_urls: *, triaging_decision?: *, url: *, minimum_downloads?: *, uuid: * }>,
      ...{ store: ({
          ...{ __typename: 'MobilePhishingDiscoveredPhishingAppsStore' },
        ...$Pick<MobilePhishingDiscoveredPhishingAppsStore, { icon_url: *, name: *, popularity: *, presence: * }>
      }), cloned_legitimate_app?: ?({
          ...{ __typename: 'MobilePhishingDiscoveredPhishingAppsClonedLegitimateApp' },
        ...$Pick<MobilePhishingDiscoveredPhishingAppsClonedLegitimateApp, { bundle_id: *, platform: *, url: *, uuid: * }>,
        ...{ last_5_visual_identities: Array<?({
            ...{ __typename: 'MobilePhishingDiscoveredPhishingAppsClonedLegitimateAppVisualIdentity' },
          ...$Pick<MobilePhishingDiscoveredPhishingAppsClonedLegitimateAppVisualIdentity, { date_seen_first: *, date_seen_last?: *, icon_url: *, name: *, name_detection_enabled: * }>
        })>, store: ({
            ...{ __typename: 'MobilePhishingDiscoveredPhishingAppsStore' },
          ...$Pick<MobilePhishingDiscoveredPhishingAppsStore, { icon_url: *, name: *, popularity: *, presence: * }>
        }) }
      }) }
    })> }
  }) }
});

export type MobilePhishingLegitimateAppsQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type MobilePhishingLegitimateAppsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_phishing_legitimate_apps: ({
      ...{ __typename: 'MobilePhishingLegitimateAppsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), legitimate_apps: Array<({
        ...{ __typename: 'MobilePhishingDiscoveredPhishingAppsClonedLegitimateApp' },
      ...$Pick<MobilePhishingDiscoveredPhishingAppsClonedLegitimateApp, { bundle_id: *, platform: *, url: *, uuid: * }>,
      ...{ last_5_visual_identities: Array<?({
          ...{ __typename: 'MobilePhishingDiscoveredPhishingAppsClonedLegitimateAppVisualIdentity' },
        ...$Pick<MobilePhishingDiscoveredPhishingAppsClonedLegitimateAppVisualIdentity, { date_seen_first: *, date_seen_last?: *, icon_url: *, name: *, name_detection_enabled: * }>
      })>, store: ({
          ...{ __typename: 'MobilePhishingDiscoveredPhishingAppsStore' },
        ...$Pick<MobilePhishingDiscoveredPhishingAppsStore, { icon_url: *, name: *, popularity: *, presence: * }>
      }) }
    })> }
  }) }
});

export type MobilePhishingDiscoveredPhishingAppsEventsQueryVariables = {
  uuid: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type MobilePhishingDiscoveredPhishingAppsEventsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_phishing_discovered_phishing_apps_events: ({
      ...{ __typename: 'MobilePhishingDiscoveredPhishingAppsEventsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), events: Array<({
        ...{ __typename: 'MobilePhishingDiscoveredPhishingAppsEventsResponseEvent' },
      ...$Pick<MobilePhishingDiscoveredPhishingAppsEventsResponseEvent, { date_created: *, discovered_app_uuid: *, type: * }>
    })> }
  }) }
});

export type MobilePhishingDiscoveredPhishingAppsCommentsQueryVariables = {
  uuid: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type MobilePhishingDiscoveredPhishingAppsCommentsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_phishing_discovered_phishing_apps_comments: ({
      ...{ __typename: 'MobilePhishingDiscoveredPhishingAppsCommentsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), comments: Array<({
        ...{ __typename: 'MobilePhishingDiscoveredPhishingAppsEventsResponseComment' },
      ...$Pick<MobilePhishingDiscoveredPhishingAppsEventsResponseComment, { content: *, date_created: *, uuid: * }>,
      ...{ user?: ?({
          ...{ __typename: 'MobilePhishingDiscoveredPhishingAppsEventsResponseCommentUser' },
        ...$Pick<MobilePhishingDiscoveredPhishingAppsEventsResponseCommentUser, { date_created: *, first_name: *, last_name: *, login_email: *, notification_email?: *, uuid: * }>
      }) }
    })> }
  }) }
});

export type MobilePhishingIgnoredKeywordsQueryVariables = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type MobilePhishingIgnoredKeywordsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_phishing_ignored_keywords: ({
      ...{ __typename: 'MobilePhishingIgnoredKeywordsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), keywords?: ?Array<?({
        ...{ __typename: 'MobilePhishingIgnoredKeyword' },
      ...$Pick<MobilePhishingIgnoredKeyword, { uuid: *, keyword: *, app_source_icon_url?: *, app_source_name?: * }>
    })> }
  }) }
});

export type MobilePhishingIgnoredKeywordsPostMutationVariables = {
  body: MobilePhishingIgnoredKeywordsParamsBody,
};


export type MobilePhishingIgnoredKeywordsPostMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ mobile_phishing_ignored_keywords_post: ({
      ...{ __typename: 'MobilePhishingIgnoredKeywordResponse' },
    ...$Pick<MobilePhishingIgnoredKeywordResponse, { keyword: *, uuid: * }>
  }) }
});

export type MobilePhishingIgnoredKeywordsPatchMutationVariables = {
  uuid: $ElementType<Scalars, 'ID'>,
  body: MobilePhishingIgnoredKeywordsParamsBody,
};


export type MobilePhishingIgnoredKeywordsPatchMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ mobile_phishing_ignored_keywords_patch: ({
      ...{ __typename: 'MobilePhishingIgnoredKeywordResponse' },
    ...$Pick<MobilePhishingIgnoredKeywordResponse, { keyword: *, uuid: * }>
  }) }
});

export type MobilePhishingIgnoredKeywordsDeleteMutationVariables = {
  uuid: $ElementType<Scalars, 'ID'>,
};


export type MobilePhishingIgnoredKeywordsDeleteMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ mobile_phishing_ignored_keywords_delete?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type ScanRunGetQueryQueryVariables = {
  uuid: $ElementType<Scalars, 'ID'>,
};


export type ScanRunGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ scan_run: ({
      ...{ __typename: 'ScanRun' },
    ...$Pick<ScanRun, { uuid: *, scan_type: *, status: *, date_created?: *, date_started?: *, date_finished?: *, logs_file_url?: *, result_issues_found_count?: *, result_markdown?: *, scanned_asset_display_name?: *, triggered_by_actor_display_name?: * }>
  }) }
});

export type ScanRunsListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_scan_type?: ?Array<?ScanRunsTypeEnum>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_date_updated?: ?$ElementType<Scalars, 'Date'>,
};


export type ScanRunsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ scan_runs_list: ({
      ...{ __typename: 'ScanRunsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), scan_runs: Array<({
        ...{ __typename: 'ScanRun' },
      ...$Pick<ScanRun, { uuid: *, scan_type: *, status: *, date_created?: *, date_started?: *, date_finished?: *, logs_file_url?: *, result_issues_found_count?: *, result_markdown?: *, scanned_asset_display_name?: *, triggered_by_actor_display_name?: * }>
    })> }
  }) }
});

export type AlertsIntegrationsGetQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type AlertsIntegrationsGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ alerts_integrations_get: ({
      ...{ __typename: 'AlertsIntegrationsDetails' },
    ...$Pick<AlertsIntegrationsDetails, { id: *, name: *, integration_type: *, status: *, most_recent_attempt_date?: * }>,
    ...{ webhook_config?: ?({
        ...{ __typename: 'AlertsIntegrationsDetailsWebhookConfigField' },
      ...$Pick<AlertsIntegrationsDetailsWebhookConfigField, { authorization_header?: *, subscribed_events?: *, subscribed_to_all_events?: *, url?: * }>
    }), jira_config?: ?({
        ...{ __typename: 'AlertsIntegrationsDetailsJiraConfigField' },
      ...$Pick<AlertsIntegrationsDetailsJiraConfigField, { instance_url?: *, username?: *, password?: *, project_key?: *, issue_type?: *, relevance_field_id?: *, policy_violations_auto_export_setting?: * }>,
      ...{ relevance_proactive_value?: ?({
          ...{ __typename: 'AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue' },
        ...$Pick<AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue, { name: *, id: * }>
      }), relevance_important_value?: ?({
          ...{ __typename: 'AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue' },
        ...$Pick<AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue, { name: *, id: * }>
      }), relevance_urgent_value?: ?({
          ...{ __typename: 'AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue' },
        ...$Pick<AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue, { name: *, id: * }>
      }), static_fields?: ?Array<?({
          ...{ __typename: 'AlertsIntegrationsDetailsJiraConfigFieldStaticField' },
        ...$Pick<AlertsIntegrationsDetailsJiraConfigFieldStaticField, { field_id?: *, field_type?: * }>,
        ...{ string_value?: ?({
            ...{ __typename: 'AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue' },
          ...$Pick<AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue, { name: *, id: * }>
        }), string_array_value?: ?Array<?({
            ...{ __typename: 'AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue' },
          ...$Pick<AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue, { name: *, id: * }>
        })>, component_array_value?: ?Array<?({
            ...{ __typename: 'AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue' },
          ...$Pick<AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue, { name: *, id: * }>
        })>, option_value?: ?({
            ...{ __typename: 'AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue' },
          ...$Pick<AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue, { name: *, id: * }>
        }), option_array_value?: ?Array<?({
            ...{ __typename: 'AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue' },
          ...$Pick<AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue, { name: *, id: * }>
        })>, number_value?: ?({
            ...{ __typename: 'AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue' },
          ...$Pick<AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue, { name: *, id: * }>
        }), user_value?: ?({
            ...{ __typename: 'AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue' },
          ...$Pick<AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue, { name: *, id: * }>
        }) }
      })> }
    }), slack_or_msft_teams_config?: ?({
        ...{ __typename: 'DetailsSlackOrMsftTeamsConfigField' },
      ...$Pick<DetailsSlackOrMsftTeamsConfigField, { url: *, should_send_asm_notifications_for_asset_types?: *, should_send_asm_notifications_for_all_asset_types: *, should_send_asm_notifications: * }>,
      ...{ mobile_secure_config: ({
          ...{ __typename: 'DetailsMobileSecureSlackOrMsftTeamsConfigField' },
        ...$Pick<DetailsMobileSecureSlackOrMsftTeamsConfigField, { should_notify_about_mobile_scan_alerts?: *, should_send_summary_notifications: *, should_send_real_time_notifications: *, should_notify_about_security_p1_issues: *, should_notify_about_store_blocker_issues: *, should_notify_about_high_severity_issues: *, should_notify_about_medium_severity_issues: *, should_notify_about_low_severity_issues: *, should_notify_about_all_mobile_apps: *, should_notify_about_mobile_app_ids?: * }>
      }), api_web_cloud_secure_config: ({
          ...{ __typename: 'DetailsApiWebCloudSecureSlackOrMsftTeamsConfigField' },
        ...$Pick<DetailsApiWebCloudSecureSlackOrMsftTeamsConfigField, { should_send_summary_notifications: *, should_send_real_time_notifications: * }>
      }) }
    }) }
  }) }
});

export type AlertsIntegrationsListQueryVariables = {
  filter_by_integration_type?: ?Array<?$ElementType<Scalars, 'String'>>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type AlertsIntegrationsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ alerts_integrations_list: ({
      ...{ __typename: 'AlertsIntegrationsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), alerts_integrations: Array<({
        ...{ __typename: 'AlertsIntegrations' },
      ...$Pick<AlertsIntegrations, { id: *, name: *, integration_type: *, status: *, most_recent_attempt_date?: * }>
    })> }
  }) }
});

export type AlertsIntegrationsTestMutationVariables = {
  body: AlertsIntegrationsTestRequest,
};


export type AlertsIntegrationsTestMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ alerts_integrations_test?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type AlertsIntegrationsCreateMutationVariables = {
  body: AlertsIntegrationCreateRequest,
};


export type AlertsIntegrationsCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ alerts_integrations_create: ({
      ...{ __typename: 'AlertsIntegrations' },
    ...$Pick<AlertsIntegrations, { id: *, name: *, integration_type: *, status: *, most_recent_attempt_date?: * }>
  }) }
});

export type AlertsIntegrationsUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: AlertsIntegrationCreateRequest,
};


export type AlertsIntegrationsUpdateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ alerts_integrations_update: ({
      ...{ __typename: 'AlertsIntegrations' },
    ...$Pick<AlertsIntegrations, { id: *, name: *, integration_type: *, status: *, most_recent_attempt_date?: * }>
  }) }
});

export type AlertsIntegrationsDeleteMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type AlertsIntegrationsDeleteMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ alerts_integrations_delete?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type AlertsIntegrationListEventValuesQueryVariables = {};


export type AlertsIntegrationListEventValuesQuery = ({
    ...{ __typename: 'Query' },
  ...{ alerts_integration_list_event_values: ({
      ...{ __typename: 'AlertsIntegrationsListEventValuesResponse' },
    ...{ alerts_integration_event_values?: ?Array<?({
        ...{ __typename: 'AlertsIntegrationsListEventValuesItem' },
      ...$Pick<AlertsIntegrationsListEventValuesItem, { name?: *, value?: *, value_category?: * }>
    })> }
  }) }
});

export type JiraIntegrationsJiraProjectsFetchesMutationVariables = {
  body: JiraIntegrationsJiraProjectsFetchesRequest,
};


export type JiraIntegrationsJiraProjectsFetchesMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ jira_projects_fetches: ({
      ...{ __typename: 'JiraIntegrationProjectsFetchesResponse' },
    ...$Pick<JiraIntegrationProjectsFetchesResponse, { instance_url: * }>,
    ...{ jira_projects: Array<({
        ...{ __typename: 'JiraIntegrationProjectsFetchesResponseJiraProject' },
      ...$Pick<JiraIntegrationProjectsFetchesResponseJiraProject, { key: *, name: *, issue_types: * }>
    })> }
  }) }
});

export type JiraIntegrationsJiraFieldsFetchesMutationVariables = {
  body: JiraIntegrationsJiraFieldsFetchesRequest,
};


export type JiraIntegrationsJiraFieldsFetchesMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ jira_issue_fields_fetches: ({
      ...{ __typename: 'JiraIntegrationFieldsFetchesResponse' },
    ...$Pick<JiraIntegrationFieldsFetchesResponse, { instance_url: *, project_key: *, issue_type: * }>,
    ...{ jira_issue_fields: Array<({
        ...{ __typename: 'JiraIntegrationProjectsFetchesResponseJiraIssueField' },
      ...$Pick<JiraIntegrationProjectsFetchesResponseJiraIssueField, { field_id: *, field_type: *, name: *, required: *, is_eligible_for_tracking_relevance?: * }>,
      ...{ allowed_values?: ?Array<?({
          ...{ __typename: 'AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue' },
        ...$Pick<AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue, { name: *, id: * }>
      })>, default_value?: ?({
          ...{ __typename: 'AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue' },
        ...$Pick<AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue, { name: *, id: * }>
      }), default_array_values?: ?Array<?({
          ...{ __typename: 'AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue' },
        ...$Pick<AlertsIntegrationsDetailsJiraConfigFieldStaticFieldOptionValue, { name: *, id: * }>
      })> }
    })> }
  }) }
});

export type GetAsmAssetTypeValuesQueryVariables = {};


export type GetAsmAssetTypeValuesQuery = ({
    ...{ __typename: 'Query' },
  ...{ alerts_integration_asm_asset_type_values: ({
      ...{ __typename: 'AlertsIntegrationAsmAssetTypeValuesResponse' },
    ...{ alerts_integration_asm_asset_type_values: Array<({
        ...{ __typename: 'AlertsIntegrationAsmAssetTypeValuesResponseItem' },
      ...$Pick<AlertsIntegrationAsmAssetTypeValuesResponseItem, { name: *, value_category?: *, value: * }>
    })> }
  }) }
});

export type GetAllSdkVariantsQueryVariables = {};


export type GetAllSdkVariantsQuery = ({
    ...{ __typename: 'Query' },
  ...{ get_all_sdk_variants: ({
      ...{ __typename: 'GetAllSdkVariantsResponse' },
    ...{ sdk_variants: Array<({
        ...{ __typename: 'SDKVariant' },
      ...$Pick<SdkVariant, { id: *, name: *, icon_url: *, version?: * }>
    })> }
  }) }
});

export type TrustKitMobileAppsListQueryVariables = {};


export type TrustKitMobileAppsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ trustkit_mobile_apps_list: ({
      ...{ __typename: 'TrustKitMobileAppListResponse' },
    ...{ mobile_apps: Array<({
        ...{ __typename: 'TrustKitMobileApp' },
      ...$Pick<TrustKitMobileApp, { bundle_id: *, horizon_asset_uuid?: *, name: *, platform: *, icon_url?: *, latest_version?: *, protection_status: *, sevenhell_customer_app_id?: * }>,
      ...{ trustkit_stats?: ?({
          ...{ __typename: 'TrustKitMobileAppStats' },
        ...$Pick<TrustKitMobileAppStats, { enforce_pinning: *, total_app_installs_count: *, total_attacks_blocked_count: *, total_attacks_count: * }>
      }) }
    })>, trustkit_summary: ({
        ...{ __typename: 'TrustKitSummary' },
      ...$Pick<TrustKitSummary, { mobile_apps_with_trustkit_count: *, mobile_apps_without_trustkit_count: * }>,
      ...{ attacks_trend: Array<({
          ...{ __typename: 'TrustKitAttackTrend' },
        ...$Pick<TrustKitAttackTrend, { app_installs_count: *, attacks_count: *, day: * }>
      })> }
    }) }
  }) }
});

export type TrustKitMobileAppIosSdkEventsQueryVariables = {
  bundle_id: $ElementType<Scalars, 'String'>,
};


export type TrustKitMobileAppIosSdkEventsQuery = ({
    ...{ __typename: 'Query' },
  ...{ trustkit_summary_sdk_events: ({
      ...{ __typename: 'TrustKitIosSdkEventsResponse' },
    ...{ data_at_rest_encryption?: ?({
        ...{ __typename: 'DataAtRestEncryption' },
      ...$Pick<DataAtRestEncryption, { total_files_count?: *, protected_files_count?: * }>
    }), dynamic_coverage_stats?: ?({
        ...{ __typename: 'DynamicCoverageStats' },
      ...$Pick<DynamicCoverageStats, { url_accessed_count?: *, last_thirty_days_url_accessed_count?: * }>
    }), device_check?: ?({
        ...{ __typename: 'DeviceCheck' },
      ...$Pick<DeviceCheck, { app_installs_on_secure_device_count: *, app_installs_on_insecure_device_count: *, devices_insecure_after_install_count: * }>
    }), device_spywares_stats?: ?({
        ...{ __typename: 'DeviceSpywareStats' },
      ...$Pick<DeviceSpywareStats, { app_platform_version_range: *, checked_devices_count: *, checks_count: *, infected_devices_count: *, vulnerable_devices_count: * }>,
      ...{ spywares?: ?Array<({
          ...{ __typename: 'SpywareStats' },
        ...$Pick<SpywareStats, { app_platform_version_range: *, checked_devices_count: *, checks_count: *, infected_devices_count: *, latest_check?: *, name: *, vulnerable_devices_count: * }>
      })> }
    }), pasteboard_expiration_stats?: ?({
        ...{ __typename: 'PasteboardExpirationStats' },
      ...$Pick<PasteboardExpirationStats, { pastes_with_expiration_count?: *, pastes_without_expiration_count?: *, pastes_with_expiration_added_by_mobile_protect_count?: *, last_thirty_days_pastes_with_expiration_added_by_mobile_protect_count?: * }>
    }), pasteboard_access_stats?: ?({
        ...{ __typename: 'PasteboardAccessStats' },
      ...$Pick<PasteboardAccessStats, { total_pasteboard_read_count?: *, pasteboard_read_blocked_by_mobile_protect_count?: *, last_thirty_days_pasteboard_read_blocked_by_mobile_protect_count?: * }>
    }), third_party_keyboard_stats?: ?({
        ...{ __typename: 'ThirdPartyKeyboardStats' },
      ...$Pick<ThirdPartyKeyboardStats, { third_party_keyboards_blocked_count?: *, last_thirty_days_third_party_keyboards_blocked_count?: * }>
    }), user_location_stats: ({
        ...{ __typename: 'UserLocationStats' },
      ...$Pick<UserLocationStats, { total_location_read_count: *, precise_location_read_count: *, precise_location_downgraded_by_mobile_protect_count: *, last_thirty_days_precise_location_downgraded_by_mobile_protect_count: * }>
    }), jailbreak_stats: Array<?({
        ...{ __typename: 'JailbreakStats' },
      ...$Pick<JailbreakStats, { jailbreak_name?: *, jailbreak_count?: * }>
    })>, tampering_stats?: ?({
        ...{ __typename: 'TamperingStats' },
      ...$Pick<TamperingStats, { tampering_attempts_detected_by_app_count?: *, tampering_attempts_detected_by_sdk_count?: *, tampering_attempts_blocked_by_app_count?: *, tampering_attempts_blocked_by_sdk_count?: * }>
    }), debugging_stats?: ?({
        ...{ __typename: 'DebuggingStats' },
      ...$Pick<DebuggingStats, { debugging_attempts_detected_count?: *, debugging_attempts_blocked_count?: * }>
    }), sensitive_apis_stats: ({
        ...{ __typename: 'SensitiveApisStats' },
      ...{ AD_ID: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }), CAMERA: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }), CONTACTS: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }), HEALTH: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }), IDFA: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }), LOCATION: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }), PASTEBOARD: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }), PHOTO_ACCESS: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }), PHOTO_EXIF: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }) }
    }) }
  }) }
});

export type TrustKitMobileAppAndroidSdkEventsQueryVariables = {
  bundle_id: $ElementType<Scalars, 'String'>,
};


export type TrustKitMobileAppAndroidSdkEventsQuery = ({
    ...{ __typename: 'Query' },
  ...{ trustkit_android_sdk_events: ({
      ...{ __typename: 'TrustKitAndroidSdkEventsResponse' },
    ...{ data_at_rest_encryption_stats?: ?({
        ...{ __typename: 'DataAtRestEncryptionStats' },
      ...$Pick<DataAtRestEncryptionStats, { protected_files_count?: *, total_files_count?: * }>
    }), dynamic_coverage_stats?: ?({
        ...{ __typename: 'DynamicCoverageStats' },
      ...$Pick<DynamicCoverageStats, { url_accessed_count?: *, last_thirty_days_url_accessed_count?: * }>
    }), screen_protection_from_third_parties_stats?: ?({
        ...{ __typename: 'ScreenProtectionFromThirdPartiesStats' },
      ...$Pick<ScreenProtectionFromThirdPartiesStats, { activity_instances_already_protected_count?: *, activity_instances_protected_by_mobile_protect_count?: *, activity_instances_unprotected_count?: *, last_thirty_days_activity_instances_protected_by_mobile_protect_count?: * }>
    }), screen_protection_from_tap_jacking_stats?: ?({
        ...{ __typename: 'ScreenProtectionFromTapJackingStats' },
      ...$Pick<ScreenProtectionFromTapJackingStats, { activity_instances_already_protected_count?: *, activity_instances_protected_by_mobile_protect_count?: *, activity_instances_unprotected_count?: *, last_thirty_days_activity_instances_protected_by_mobile_protect_count?: * }>
    }), outdated_security_provider_stats?: ?({
        ...{ __typename: 'OutdatedSecurityProviderStats' },
      ...$Pick<OutdatedSecurityProviderStats, { devices_with_security_provider_already_updated_count?: *, devices_with_security_provider_outdated_count?: *, devices_with_security_provider_updated_by_mobile_protect_count?: *, last_thirty_days_devices_with_security_provider_updated_by_mobile_protect_count?: * }>
    }), secure_webview_usage_stats?: ?({
        ...{ __typename: 'SecureWebviewUsageStats' },
      ...$Pick<SecureWebviewUsageStats, { insecure_webviews_initialized_count?: *, last_thirty_days_secure_webviews_initialized_by_mobile_protect_count?: *, secure_webviews_initialized_by_mobile_protect_count?: *, secure_webviews_initialized_count?: * }>
    }), device_check_stats?: ?({
        ...{ __typename: 'AndroidDeviceCheckStats' },
      ...$Pick<AndroidDeviceCheckStats, { devices_with_harmful_apps_count?: *, devices_with_verify_apps_disabled_count?: *, devices_with_verify_apps_enabled_count?: *, last_thirty_days_devices_with_verify_apps_enabled_count?: * }>
    }), user_location_stats: ({
        ...{ __typename: 'UserLocationStats' },
      ...$Pick<UserLocationStats, { total_location_read_count: *, precise_location_read_count: *, precise_location_downgraded_by_mobile_protect_count: *, last_thirty_days_precise_location_downgraded_by_mobile_protect_count: * }>
    }), root_detection_stats?: ?({
        ...{ __typename: 'RootDetectionStats' },
      ...$Pick<RootDetectionStats, { devices_with_unlocked_bootloader_count?: *, emulator_devices_count?: *, rooted_devices_count?: * }>
    }), tampering_stats?: ?({
        ...{ __typename: 'AndroidTamperingStats' },
      ...$Pick<AndroidTamperingStats, { last_thirty_days_tampering_attempts_blocked_by_app_count?: *, last_thirty_days_tampering_attempts_blocked_by_sdk_count?: *, tampering_attempts_blocked_by_app_count?: *, tampering_attempts_blocked_by_sdk_count?: *, tampering_attempts_detected_by_app_count?: *, tampering_attempts_detected_by_sdk_count?: * }>
    }), debugging_stats?: ?({
        ...{ __typename: 'AndroidDebuggingStats' },
      ...$Pick<AndroidDebuggingStats, { debugging_attempts_detected_count?: *, debugging_attempts_blocked_count?: * }>
    }), cheat_engines_stats?: ?Array<({
        ...{ __typename: 'AndroidCheatEngineStats' },
      ...$Pick<AndroidCheatEngineStats, { cheat_engine_name: *, cheating_attempts_blocked_count?: *, cheating_attempts_detected_count?: * }>
    })>, sensitive_apis_stats: ({
        ...{ __typename: 'SensitiveApisStats' },
      ...{ AD_ID: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }), CAMERA: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }), CONTACTS: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }), HEALTH: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }), IDFA: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }), LOCATION: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }), PASTEBOARD: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }), PHOTO_ACCESS: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }), PHOTO_EXIF: ({
          ...{ __typename: 'SensitiveApisStatsType' },
        ...$Pick<SensitiveApisStatsType, { apis_blocked_by_mobile_protect_count: *, apis_accessed_by_app_count: *, apis_accessed_by_sdk_count: *, last_thirty_days_apis_blocked_by_mobile_protect_count: * }>
      }) }
    }) }
  }) }
});

export type TrustKitMobileAppSummaryQueryVariables = {
  bundle_id: $ElementType<Scalars, 'String'>,
  platform: $ElementType<Scalars, 'String'>,
};


export type TrustKitMobileAppSummaryQuery = ({
    ...{ __typename: 'Query' },
  ...{ trustkit_summary: ({
      ...{ __typename: 'TrustkitSummaryResponse' },
    ...$Pick<TrustkitSummaryResponse, { enforce_pinning: *, total_app_installs_count: *, total_attacks_count: *, total_attacks_blocked_count: *, attacks_block_rate: *, attacks_block_rate_percentile: * }>,
    ...{ mobile_app: ({
        ...{ __typename: 'TrustKitMobileApp' },
      ...$Pick<TrustKitMobileApp, { bundle_id: *, name: *, platform: *, icon_url?: *, latest_version?: *, protection_status: * }>
    }), per_country_stats: Array<?({
        ...{ __typename: 'TrustkitSummaryCountryStats' },
      ...$Pick<TrustkitSummaryCountryStats, { country_code: *, country_name: *, attacks_count: *, attacks_blocked_count?: *, app_installs_count?: * }>,
      ...{ subdivisions: Array<?({
          ...{ __typename: 'TrustkitSummaryCountrySubdivisionsStats' },
        ...$Pick<TrustkitSummaryCountrySubdivisionsStats, { app_installs_count: *, attacks_blocked_count: *, attacks_count: *, subdivision_code?: *, subdivision_name: * }>
      })> }
    })>, top_country_stats: Array<?({
        ...{ __typename: 'TrustkitSummaryCountryStats' },
      ...$Pick<TrustkitSummaryCountryStats, { country_code: *, country_name: *, attacks_count: *, attacks_blocked_count?: *, app_installs_count?: * }>,
      ...{ subdivisions: Array<?({
          ...{ __typename: 'TrustkitSummaryCountrySubdivisionsStats' },
        ...$Pick<TrustkitSummaryCountrySubdivisionsStats, { app_installs_count: *, attacks_blocked_count: *, attacks_count: *, subdivision_code?: *, subdivision_name: * }>
      })> }
    })>, top_domain_destinations: Array<?({
        ...{ __typename: 'TopDomainDestinations' },
      ...$Pick<TopDomainDestinations, { domain: *, total_attacks_count: * }>
    })>, attacks_blocked_per_app_version: Array<?({
        ...{ __typename: 'AttacksBlockedPerAppVersion' },
      ...$Pick<AttacksBlockedPerAppVersion, { app_version: *, total_attacks_blocked_count: * }>
    })>, top_validation_results: Array<?({
        ...{ __typename: 'TopValidationResults' },
      ...$Pick<TopValidationResults, { total_attacks_count: *, validation_result: *, validation_result_explanation: * }>
    })> }
  }) }
});

export type TrustKitMobileProtectDataProtectionMutationVariables = {
  body: EntitlementsCreateParams,
};


export type TrustKitMobileProtectDataProtectionMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ ios_data_protection_create: ({
      ...{ __typename: 'EntitlementsPlistResponse' },
    ...$Pick<EntitlementsPlistResponse, { entitlements_as_plist: *, download_url: * }>
  }) }
});

export type TrustKitPinnedDomainConfigurationMutationVariables = {
  body?: ?TrustKitPinnedDomainUpdateParams,
};


export type TrustKitPinnedDomainConfigurationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ trustkit_domains_configuration_create: ({
      ...{ __typename: 'TrustKitPinnedDomainResponse' },
    ...{ pinned_domains: Array<({
        ...{ __typename: 'TrustKitPinnedDomain' },
      ...$Pick<TrustKitPinnedDomain, { domain: *, enforce_pinning: *, expiration_date: *, include_subdomains: *, public_key_hashes: * }>
    })> }
  }) }
});

export type TrustKitPinnedDomainSdkConfigurationMutationVariables = {
  body?: ?TrustKitPinnedDomainSdkConfigurationParams,
};


export type TrustKitPinnedDomainSdkConfigurationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ sdk_configuration_create: ({
      ...{ __typename: 'TrustKitSDKConfigurationResponse' },
    ...$Pick<TrustKitSdkConfigurationResponse, { ios_config_as_plist?: *, android_config_as_xml?: * }>
  }) }
});

export type TrustKitOneStepSdkConfigurationMutationVariables = {
  body?: ?TrustKitSingleStepSdkConfigurationParams,
};


export type TrustKitOneStepSdkConfigurationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ one_step_sdk_configuration_create: ({
      ...{ __typename: 'TrustKitSingleStepSDKConfigurationResponse' },
    ...$Pick<TrustKitSingleStepSdkConfigurationResponse, { ios_config_as_plist?: *, android_config_as_xml?: *, sdk_download_url: * }>
  }) }
});

export type ConfigurationSdkVariantCreateMutationVariables = {
  sdk_variant: $ElementType<Scalars, 'String'>,
  body?: ?TrustKitSingleStepSdkConfigurationParams,
};


export type ConfigurationSdkVariantCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ mobile_protect_configuration_wizard_sdk_variants_customize?: ?({
      ...{ __typename: 'MobileProtectConfigurationWizardSdkVariantsCustomizeResponse' },
    ...$Pick<MobileProtectConfigurationWizardSdkVariantsCustomizeResponse, { ios_config_as_plist?: *, android_config_as_xml?: *, sdk_download_url: *, onboarding_video_url: *, sdk_variant_name: * }>
  }) }
});

export type TrustkitDynamicCoverageSummaryQueryVariables = {
  bundle_id: $ElementType<Scalars, 'String'>,
  platform: $ElementType<Scalars, 'String'>,
};


export type TrustkitDynamicCoverageSummaryQuery = ({
    ...{ __typename: 'Query' },
  ...{ trustkit_dynamic_coverage_summary: ({
      ...{ __typename: 'TrustKitDynamicCoverageSummaryResponse' },
    ...$Pick<TrustKitDynamicCoverageSummaryResponse, { urls_discovered_count: *, domains_discovered_count: * }>
  }) }
});

export type TrustkitTopAccessedNetworkServicesQueryVariables = {
  bundle_id: $ElementType<Scalars, 'String'>,
  platform: $ElementType<Scalars, 'String'>,
};


export type TrustkitTopAccessedNetworkServicesQuery = ({
    ...{ __typename: 'Query' },
  ...{ trustkit_top_accessed_network_services: ({
      ...{ __typename: 'TrustKitTopAccessedNetworkServicesResponse' },
    ...{ top_accessed_urls: Array<({
        ...{ __typename: 'TrustKitTopAccessedNetworkServicesResponseItem' },
      ...$Pick<TrustKitTopAccessedNetworkServicesResponseItem, { url: *, access_count: *, first_seen_date: * }>
    })> }
  }) }
});

export type TrustKitAccessedNetworkServicesResponseQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  bundle_id: $ElementType<Scalars, 'String'>,
  platform: $ElementType<Scalars, 'String'>,
};


export type TrustKitAccessedNetworkServicesResponseQuery = ({
    ...{ __typename: 'Query' },
  ...{ trustkit_accessed_network_services: ({
      ...{ __typename: 'TrustKitAccessedNetworkServicesResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), accessed_network_services: Array<?({
        ...{ __typename: 'TrustKitAccessedNetworkService' },
      ...$Pick<TrustKitAccessedNetworkService, { id: *, scheme: *, hostname: *, port: * }>
    })> }
  }) }
});

export type MobileProtectApiKeysMutationVariables = {
  body: MobileProtectApiKeysParams,
};


export type MobileProtectApiKeysMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ mobile_protect_keys_or_token_post: ({
      ...{ __typename: 'MobileProtectApiKeysResponse' },
    ...$Pick<MobileProtectApiKeysResponse, { id: *, key: *, type: *, name: *, created_at: * }>
  }) }
});

export type MobileProtectBuildsApiKeysMutationVariables = {
  body: MobileProtectApiKeysParams,
};


export type MobileProtectBuildsApiKeysMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ mobile_protect_keys_or_token_post: ({
      ...{ __typename: 'MobileProtectApiKeysResponse' },
    ...$Pick<MobileProtectApiKeysResponse, { id: *, key: *, type: *, name: *, created_at: * }>
  }) }
});

export type MobileProtectAppsQueryVariables = {};


export type MobileProtectAppsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_protect_apps: ({
      ...{ __typename: 'MobileProtectAppsResponse' },
    ...{ mobile_apps: Array<({
        ...{ __typename: 'MobileProtectAppsResponseItem' },
      ...$Pick<MobileProtectAppsResponseItem, { id: *, name: *, platform: *, bundle_id: *, icon_url?: *, latest_version?: *, sevenhell_customer_app_id: *, horizon_asset_uuid: *, protection_status: *, connector_name: *, connector_icon_url: *, connector_version: * }>,
      ...{ trustkit_stats: ({
          ...{ __typename: 'MobileProtectAppsResponseItemTrustkitStatsItem' },
        ...$Pick<MobileProtectAppsResponseItemTrustkitStatsItem, { enforce_pinning: *, total_app_installs_count: *, total_attacks_count: *, total_attacks_blocked_count: * }>
      }) }
    })>, trustkit_summary: ({
        ...{ __typename: 'MobileProtectAppsResponseTrustkitSummary' },
      ...$Pick<MobileProtectAppsResponseTrustkitSummary, { mobile_apps_without_trustkit_count?: *, mobile_apps_with_trustkit_count?: * }>,
      ...{ attacks_trend?: ?Array<?({
          ...{ __typename: 'MobileProtectAppsResponseTrustkitSummaryAttackTrendItem' },
        ...$Pick<MobileProtectAppsResponseTrustkitSummaryAttackTrendItem, { day: *, app_installs_count: *, attacks_count: * }>
      })> }
    }) }
  }) }
});

export type MobileAppGetEventsQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type MobileAppGetEventsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_app_get_events: ({
      ...{ __typename: 'MobileAppGetEventsResponse' },
    ...{ events: Array<({
        ...{ __typename: 'MobileAppGetEventsResponseItem' },
      ...$Pick<MobileAppGetEventsResponseItem, { app_version: *, event_type_name: *, geoip_country: *, id: *, ip_address: *, received_at: *, source_country_name?: * }>
    })>, pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }) }
  }) }
});

export type MobileAppGetEventsDetailsQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  eventId: $ElementType<Scalars, 'ID'>,
};


export type MobileAppGetEventsDetailsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_app_get_events_details: ({
      ...{ __typename: 'MobileAppGetEventsDetailsResponse' },
    ...$Pick<MobileAppGetEventsDetailsResponse, { app_version: *, context_as_markdown?: *, event_type_name: *, geoip_country: *, id: *, ip_address: *, source_country_name?: *, received_at: *, sdk_version: * }>,
    ...{ mobile_app: ({
        ...{ __typename: 'MobileAppGetEventsDetailsResponseMobileApp' },
      ...$Pick<MobileAppGetEventsDetailsResponseMobileApp, { bundle_id: *, icon_url?: *, id: *, name: *, platform: *, version: * }>
    }) }
  }) }
});

export type MobileProtectNamedFiltersQueryVariables = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
};


export type MobileProtectNamedFiltersQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_protect_named_filters: ({
      ...{ __typename: 'MobileProtectNamedFiltersResponse' },
    ...{ named_filters: Array<({
        ...{ __typename: 'MobileProtectNamedFilterItem' },
      ...$Pick<MobileProtectNamedFilterItem, { name: *, value: *, icon_url?: *, has_children?: * }>,
      ...{ children?: ?Array<({
          ...{ __typename: 'MobileProtectNamedFilterItemChildrenItem' },
        ...$Pick<MobileProtectNamedFilterItemChildrenItem, { name: *, value: *, icon_url?: *, has_data?: * }>
      })> }
    })> }
  }) }
});

export type MobileProtectApplicationsNamedFiltersQueryVariables = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
};


export type MobileProtectApplicationsNamedFiltersQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_protect_applications_named_filters: ({
      ...{ __typename: 'MobileApplicationNamedFiltersResponse' },
    ...{ mobile_applications_named_filters: Array<({
        ...{ __typename: 'MobileProtectNamedFilterItem' },
      ...$Pick<MobileProtectNamedFilterItem, { name: *, value: *, icon_url?: *, has_children?: * }>,
      ...{ children?: ?Array<({
          ...{ __typename: 'MobileProtectNamedFilterItemChildrenItem' },
        ...$Pick<MobileProtectNamedFilterItemChildrenItem, { name: *, value: *, icon_url?: *, has_data?: * }>
      })> }
    })> }
  }) }
});

export type MobileProtectNamedFiltersChildrenQueryVariables = {
  name: $ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type MobileProtectNamedFiltersChildrenQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_protect_named_filters_children: ({
      ...{ __typename: 'SecurityFindingsNamedFiltersChildrenResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), children: Array<?({
        ...{ __typename: 'SecurityFindingsNamedFiltersChildren' },
      ...$Pick<SecurityFindingsNamedFiltersChildren, { has_children?: *, icon_url?: *, name?: *, value?: * }>,
      ...{ children?: ?Array<?({
          ...{ __typename: 'SecurityFindingsNamedFiltersChildren' },
        ...$Pick<SecurityFindingsNamedFiltersChildren, { icon_url?: *, name?: *, value?: * }>
      })> }
    })> }
  }) }
});

export type MobileUsageStatsQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type MobileUsageStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_usage_stats: ({
      ...{ __typename: 'UsageStatsResponse' },
    ...{ usage_stats: Array<({
        ...{ __typename: 'ProtectionStatsResponseItem' },
      ...$Pick<ProtectionStatsResponseItem, { icon_url: *, count?: *, name: *, trend_value: *, trend_period: *, card_type?: *, text?: * }>
    })> }
  }) }
});

export type MobileAppPRotectionLevelsStatsQueryVariables = {};


export type MobileAppPRotectionLevelsStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_applications_protection_levels_stats: ({
      ...{ __typename: 'MobileAppProtectionLevelStatsResponse' },
    ...$Pick<MobileAppProtectionLevelStatsResponse, { level0_apps_count: *, level1_apps_count: *, level2_apps_count: *, level3_apps_count: * }>
  }) }
});

export type MobileProtectionStatsQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type MobileProtectionStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_protection_stats: ({
      ...{ __typename: 'MobileProtectionStatsResponse' },
    ...{ protection_stats: Array<({
        ...{ __typename: 'MobileProtectionStatsResponseItem' },
      ...$Pick<MobileProtectionStatsResponseItem, { count: *, name: *, icon_url: *, trend_value: *, trend_period: * }>
    })> }
  }) }
});

export type MobileTopClientDomainsStatsQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type MobileTopClientDomainsStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_top_client_domains_stats: ({
      ...{ __typename: 'TopClientDomainsStatsResponse' },
    ...{ top_client_domains_stats: Array<({
        ...{ __typename: 'TopClientDomainsStatsResponseItem' },
      ...$Pick<TopClientDomainsStatsResponseItem, { value: *, name: *, trend_value: * }>
    })> }
  }) }
});

export type MobileTopClientCountriesStatsQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type MobileTopClientCountriesStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_top_client_countries_stats: ({
      ...{ __typename: 'TopClientCountriesStatsResponse' },
    ...{ top_client_countries_stats: Array<({
        ...{ __typename: 'TopClientDomainsStatsResponseItem' },
      ...$Pick<TopClientDomainsStatsResponseItem, { value: *, name: *, trend_value: * }>
    })> }
  }) }
});

export type MobileAppSessionBreakdownQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type MobileAppSessionBreakdownQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_app_session_breakdown: ({
      ...{ __typename: 'MobileAppSessionBreakdownResponse' },
    ...$Pick<MobileAppSessionBreakdownResponse, { top_apis_name: * }>,
    ...{ top_apis: Array<({
        ...{ __typename: 'MobileAppSessionBreakdownResponseTopApisItem' },
      ...$Pick<MobileAppSessionBreakdownResponseTopApisItem, { detail: *, icon_url?: *, title: *, title_url: * }>
    })>, metrics: Array<({
        ...{ __typename: 'MobileAppSessionBreakdownResponseMetricsItem' },
      ...$Pick<MobileAppSessionBreakdownResponseMetricsItem, { name: * }>,
      ...{ items: Array<({
          ...{ __typename: 'MobileAppSessionBreakdownResponseMetricsItemsItem' },
        ...$Pick<MobileAppSessionBreakdownResponseMetricsItemsItem, { color: *, name: * }>
      })> }
    })> }
  }) }
});

export type MobileProtectPerCountryStatsQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type MobileProtectPerCountryStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_protect_per_country_events_stats: ({
      ...{ __typename: 'PerCountryEventsStatsResponse' },
    ...{ per_country_events_stats: Array<({
        ...{ __typename: 'PerCountryEventsStatsResponseItem' },
      ...$Pick<PerCountryEventsStatsResponseItem, { country_code: *, country_name: *, total_events_count: * }>,
      ...{ events_count: Array<({
          ...{ __typename: 'PerCountryEventsStatsResponseItemEventCount' },
        ...$Pick<PerCountryEventsStatsResponseItemEventCount, { event_type: *, event_type_name: *, count: * }>
      })>, subdivisions?: ?Array<?({
          ...{ __typename: 'PerCountryEventsStatsResponseItemSubdvision' },
        ...$Pick<PerCountryEventsStatsResponseItemSubdvision, { subdivision_code: *, subdivision_name: *, total_events_count: * }>,
        ...{ events_count: Array<({
            ...{ __typename: 'PerCountryEventsStatsResponseItemEventCount' },
          ...$Pick<PerCountryEventsStatsResponseItemEventCount, { event_type: *, event_type_name: *, count: * }>
        })> }
      })> }
    })> }
  }) }
});

export type GlobalAppStoreAnswersReportCreateMutationVariables = {};


export type GlobalAppStoreAnswersReportCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ global_app_store_answers_reports: ({
      ...{ __typename: 'ComplianceReportResponse' },
    ...$Pick<ComplianceReportResponse, { download_url: * }>
  }) }
});

export type AppStoreAnswersReportCreateMutationVariables = {
  body: ComplianceReportParams,
};


export type AppStoreAnswersReportCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ app_store_answers_reports: ({
      ...{ __typename: 'ComplianceReportResponse' },
    ...$Pick<ComplianceReportResponse, { download_url: * }>
  }) }
});

export type CreateDataTrustReportMutationVariables = {
  report_type: ComplianceReportType,
  body: ComplianceReportParams,
};


export type CreateDataTrustReportMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ data_trust_report: ({
      ...{ __typename: 'ComplianceReportResponse' },
    ...$Pick<ComplianceReportResponse, { download_url: * }>
  }) }
});

export type GetMaterialsQueryVariables = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_is_product_demo?: ?$ElementType<Scalars, 'String'>,
  filter_by_material_type?: ?Array<$ElementType<Scalars, 'String'>>,
  filter_by_category?: ?Array<$ElementType<Scalars, 'String'>>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
};


export type GetMaterialsQuery = ({
    ...{ __typename: 'Query' },
  ...{ sdu_get_materials: ({
      ...{ __typename: 'StanfordDishUniGetMaterialsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: *, next_cursor?: * }>
    }), materials: Array<({
        ...{ __typename: 'StanfordDishUniMaterial' },
      ...$Pick<StanfordDishUniMaterial, { category: *, date_published: *, date_viewed?: *, description: *, file_signed_url: *, material_type: *, user_view_time: *, material_type_icon_url: *, thumbnail_image_signed_url: *, title: *, user_viewing_status: *, uuid: * }>
    })> }
  }) }
});

export type GetUserStatsQueryVariables = {};


export type GetUserStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ sdu_get_user_stats: ({
      ...{ __typename: 'StanfordDishUniGetUserStatsResponse' },
    ...$Pick<StanfordDishUniGetUserStatsResponse, { percentile: *, sections_viewed: *, total_sections: *, total_view_time: * }>,
    ...{ rewards: ({
        ...{ __typename: 'StanfordDishUniGetUserStatsResponseRewards' },
      ...{ api: ({
          ...{ __typename: 'StanfordDishUniGetUserStatsResponseRewardsItem' },
        ...$Pick<StanfordDishUniGetUserStatsResponseRewardsItem, { completed: *, total: * }>
      }), web: ({
          ...{ __typename: 'StanfordDishUniGetUserStatsResponseRewardsItem' },
        ...$Pick<StanfordDishUniGetUserStatsResponseRewardsItem, { completed: *, total: * }>
      }), cloud: ({
          ...{ __typename: 'StanfordDishUniGetUserStatsResponseRewardsItem' },
        ...$Pick<StanfordDishUniGetUserStatsResponseRewardsItem, { completed: *, total: * }>
      }), mobile: ({
          ...{ __typename: 'StanfordDishUniGetUserStatsResponseRewardsItem' },
        ...$Pick<StanfordDishUniGetUserStatsResponseRewardsItem, { completed: *, total: * }>
      }) }
    }) }
  }) }
});

export type GetHighlightsQueryVariables = {};


export type GetHighlightsQuery = ({
    ...{ __typename: 'Query' },
  ...{ sdu_get_highlights: ({
      ...{ __typename: 'StanfordDishUniGetHighlightsResponse' },
    ...{ materials: Array<({
        ...{ __typename: 'StanfordDishUniMaterial' },
      ...$Pick<StanfordDishUniMaterial, { category: *, date_published: *, date_viewed?: *, description: *, file_signed_url: *, material_type: *, material_type_icon_url: *, thumbnail_image_signed_url: *, title: *, user_view_time: *, user_viewing_status: *, uuid: * }>
    })>, changelog_entries: Array<({
        ...{ __typename: 'StanfordDishUniChangelogEntry' },
      ...$Pick<StanfordDishUniChangelogEntry, { date_published: *, description: *, uuid: *, products: *, product_icon_urls: *, tags: *, thumbnail_image_signed_url: *, title: *, learn_more_url?: *, learn_more_display_name?: * }>
    })>, live_trainings: Array<({
        ...{ __typename: 'StanfordDishUniLiveTraining' },
      ...$Pick<StanfordDishUniLiveTraining, { date: *, location?: *, description: *, product: *, product_icon_url: *, thumbnail_image_signed_url: *, title: *, user_attendance_status: *, uuid: * }>
    })> }
  }) }
});

export type GetChangelogEntriesQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
  date_from?: ?$ElementType<Scalars, 'String'>,
  date_to?: ?$ElementType<Scalars, 'String'>,
};


export type GetChangelogEntriesQuery = ({
    ...{ __typename: 'Query' },
  ...{ sdu_get_changelog_entries: ({
      ...{ __typename: 'StanfordDishUniGetChangelogEntriesResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: *, next_cursor?: * }>
    }), changelog_entries: Array<({
        ...{ __typename: 'StanfordDishUniChangelogEntry' },
      ...$Pick<StanfordDishUniChangelogEntry, { date_published: *, description: *, uuid: *, products: *, product_icon_urls: *, tags: *, thumbnail_image_signed_url: *, title: *, learn_more_url?: *, learn_more_display_name?: * }>
    })> }
  }) }
});

export type GetLiveTrainingsQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
};


export type GetLiveTrainingsQuery = ({
    ...{ __typename: 'Query' },
  ...{ sdu_get_live_trainings: ({
      ...{ __typename: 'StanfordDishUniGetLiveTrainingsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: *, next_cursor?: * }>
    }), live_trainings: Array<({
        ...{ __typename: 'StanfordDishUniLiveTraining' },
      ...$Pick<StanfordDishUniLiveTraining, { date: *, description: *, product: *, location?: *, product_icon_url: *, thumbnail_image_signed_url: *, title: *, user_attendance_status: *, uuid: * }>
    })> }
  }) }
});

export type UpdateLiveTrainingMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: StanfordDishUniUpdateLiveTrainingBody,
};


export type UpdateLiveTrainingMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ sdu_update_live_training: ({
      ...{ __typename: 'StanfordDishUniLiveTraining' },
    ...$Pick<StanfordDishUniLiveTraining, { date: *, location?: *, description: *, product: *, product_icon_url: *, thumbnail_image_signed_url: *, title: *, user_attendance_status: *, uuid: * }>
  }) }
});

export type UpdateMaterialViewedMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: StanfordDishUniUpdateMaterialViewedBody,
};


export type UpdateMaterialViewedMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ sdu_update_material_viewed: ({
      ...{ __typename: 'StanfordDishUniMaterial' },
    ...$Pick<StanfordDishUniMaterial, { category: *, date_published: *, date_viewed?: *, description: *, file_signed_url: *, material_type: *, material_type_icon_url: *, thumbnail_image_signed_url: *, title: *, user_view_time: *, user_viewing_status: *, uuid: * }>
  }) }
});

export type GetLatestChangelogEntriesQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
};


export type GetLatestChangelogEntriesQuery = ({
    ...{ __typename: 'Query' },
  ...{ sdu_get_latest_changelog_entries: ({
      ...{ __typename: 'StanfordDishUniGetLatestChangelogEntriesResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: *, next_cursor?: * }>
    }), months_with_changelog_entries: Array<({
        ...{ __typename: 'StanfordDishUniGetLatestChangelogEntriesResponseItem' },
      ...$Pick<StanfordDishUniGetLatestChangelogEntriesResponseItem, { date_from: *, date_to: *, month: *, thumbnail_url: * }>
    })> }
  }) }
});

export type GetSduFiltersQueryVariables = {
  filter_by_is_product_demo?: ?$ElementType<Scalars, 'String'>,
};


export type GetSduFiltersQuery = ({
    ...{ __typename: 'Query' },
  ...{ sdu_assets_search_filter_values: ({
      ...{ __typename: 'MaterialsSearchFilterValuesResponse' },
    ...{ materials_search_filter_values?: ?Array<?({
        ...{ __typename: 'AssetsSearchFilterValue' },
      ...$Pick<AssetsSearchFilterValue, { belongs_to_filter: *, icon_url: *, name: *, value: *, value_category?: * }>
    })> }
  }) }
});

export type ListConnectorsQueryVariables = {};


export type ListConnectorsQuery = ({
    ...{ __typename: 'Query' },
  ...{ list_connectors: ({
      ...{ __typename: 'ListConnectorsResponse' },
    ...{ connectors: Array<({
        ...{ __typename: 'ListConnectorsResponseItem' },
      ...$Pick<ListConnectorsResponseItem, { connector_type: *, connector_name: *, network_type?: *, api_protect_sdk_flavor?: * }>
    })>, pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: *, exact_total_count?: *, hint_for_total_count?: *, is_approximate_total_count_enabled?: * }>
    }) }
  }) }
});

export type PostApiProtectSdkMutationVariables = {
  body: PostApiProtectSdkBody,
};


export type PostApiProtectSdkMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ post_api_protect_sdk: ({
      ...{ __typename: 'PostApiProtectSdkResponse' },
    ...$Pick<PostApiProtectSdkResponse, { api_protect_sdk_flavor: * }>,
    ...{ step_1: ({
        ...{ __typename: 'PostApiProtectSdkResponseStep1' },
      ...$Pick<PostApiProtectSdkResponseStep1, { title: *, artifact_download_url: *, artifact_name: * }>,
      ...{ examples: Array<({
          ...{ __typename: 'PostApiProtectSdkResponseExample' },
        ...$Pick<PostApiProtectSdkResponseExample, { title: *, example_as_markdown: * }>
      })> }
    }), step_2: ({
        ...{ __typename: 'PostApiProtectSdkResponseStep2' },
      ...$Pick<PostApiProtectSdkResponseStep2, { title: * }>,
      ...{ examples: Array<({
          ...{ __typename: 'PostApiProtectSdkResponseExample' },
        ...$Pick<PostApiProtectSdkResponseExample, { title: *, example_as_markdown: * }>
      })> }
    }), step_3: ({
        ...{ __typename: 'PostApiProtectSdkResponseStep3' },
      ...$Pick<PostApiProtectSdkResponseStep3, { title: *, api_key: * }>,
      ...{ examples: Array<({
          ...{ __typename: 'PostApiProtectSdkResponseExample' },
        ...$Pick<PostApiProtectSdkResponseExample, { title: *, example_as_markdown: * }>
      })> }
    }) }
  }) }
});

export type PostNetworkFlowsMutationVariables = {
  body: PostNetworkFlowsBody,
};


export type PostNetworkFlowsMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ post_network_flows: ({
      ...{ __typename: 'PostNetworkFlowsResponse' },
    ...$Pick<PostNetworkFlowsResponse, { network_type: *, network_name?: *, network_id?: *, description: * }>,
    ...{ step_1: ({
        ...{ __typename: 'PostNetworkFlowsResponseStep1' },
      ...$Pick<PostNetworkFlowsResponseStep1, { api_key: *, instructions_as_markdown: * }>
    }), step_2: ({
        ...{ __typename: 'PostNetworkFlowsResponseGenericStep' },
      ...$Pick<PostNetworkFlowsResponseGenericStep, { title: *, intro_as_markdown: *, artifact_download_url: *, artifact_name: *, instructions_as_markdown: * }>
    }), step_3?: ?({
        ...{ __typename: 'PostNetworkFlowsResponseGenericStep' },
      ...$Pick<PostNetworkFlowsResponseGenericStep, { title: *, intro_as_markdown: *, artifact_download_url: *, artifact_name: *, instructions_as_markdown: * }>
    }) }
  }) }
});

export type PatchNetworkFlowsMutationVariables = {
  networkId: $ElementType<Scalars, 'String'>,
  body: PatchNetworkFlowsBody,
};


export type PatchNetworkFlowsMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ patch_network_flows: ({
      ...{ __typename: 'PatchNetworkFlowsResponse' },
    ...$Pick<PatchNetworkFlowsResponse, { id: *, name: * }>
  }) }
});

export type ComplianceReportsStatsQueryVariables = {};


export type ComplianceReportsStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ compliance_reports_stats: ({
      ...{ __typename: 'ComplianceReportsStatsResponse' },
    ...{ compliance_reports_stats: Array<({
        ...{ __typename: 'ComplianceReportsStatsResponseItem' },
      ...$Pick<ComplianceReportsStatsResponseItem, { open_policy_violations_count: *, closed_policy_violations_count: *, downloads_count: *, compliance_report_type: *, compliance_report_name: *, compliance_report_icon_url: * }>
    })> }
  }) }
});

export type ProtectionStatsQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type ProtectionStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ protection_stats: ({
      ...{ __typename: 'ProtectionStatsResponse' },
    ...{ protection_stats: Array<({
        ...{ __typename: 'ProtectionStatsResponseItem' },
      ...$Pick<ProtectionStatsResponseItem, { icon_url: *, count?: *, name: *, trend_value: *, trend_period: * }>
    })> }
  }) }
});

export type UsageStatsQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type UsageStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ usage_stats: ({
      ...{ __typename: 'UsageStatsResponse' },
    ...{ usage_stats: Array<({
        ...{ __typename: 'ProtectionStatsResponseItem' },
      ...$Pick<ProtectionStatsResponseItem, { icon_url: *, count?: *, name: *, trend_value: *, trend_period: *, card_type?: *, text?: * }>
    })> }
  }) }
});

export type AppSecKpisStatsQueryVariables = {};


export type AppSecKpisStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ app_sec_kpis_stats: ({
      ...{ __typename: 'AppSecKpisStatsResponse' },
    ...{ app_sec_kpis_stats: Array<({
        ...{ __typename: 'AppSecKpisStatsResponseItem' },
      ...$Pick<AppSecKpisStatsResponseItem, { name: *, count: *, color: * }>
    })> }
  }) }
});

export type TopClientDomainsStatsQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type TopClientDomainsStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ top_client_domains_stats: ({
      ...{ __typename: 'TopClientDomainsStatsResponse' },
    ...{ top_client_domains_stats: Array<({
        ...{ __typename: 'TopClientDomainsStatsResponseItem' },
      ...$Pick<TopClientDomainsStatsResponseItem, { value: *, name: *, trend_value: * }>
    })> }
  }) }
});

export type TopClientCountriesStatsQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type TopClientCountriesStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ top_client_countries_stats: ({
      ...{ __typename: 'TopClientCountriesStatsResponse' },
    ...{ top_client_countries_stats: Array<({
        ...{ __typename: 'TopClientDomainsStatsResponseItem' },
      ...$Pick<TopClientDomainsStatsResponseItem, { value: *, name: *, trend_value: * }>
    })> }
  }) }
});

export type HttpMethodsBreakdownQueryVariables = {
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type HttpMethodsBreakdownQuery = ({
    ...{ __typename: 'Query' },
  ...{ http_methods_breakdown: ({
      ...{ __typename: 'HttpMethodsBreakdownResponse' },
    ...{ http_methods_breakdown: Array<({
        ...{ __typename: 'HttpMethodBreakdown' },
      ...$Pick<HttpMethodBreakdown, { http_method: *, count: * }>
    })> }
  }) }
});

export type ApiSecurityStatsQueryVariables = {};


export type ApiSecurityStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_security_stats: ({
      ...{ __typename: 'MobileSecurityStatsResponse' },
    ...{ legend: Array<({
        ...{ __typename: 'MobileSecurityStatsResponseLegendItem' },
      ...$Pick<MobileSecurityStatsResponseLegendItem, { color: *, title: * }>
    })>, months: Array<({
        ...{ __typename: 'MobileSecurityStatsResponseMonthItem' },
      ...$Pick<MobileSecurityStatsResponseMonthItem, { month_name: * }>,
      ...{ bars: Array<({
          ...{ __typename: 'MobileSecurityStatsResponseMonthItemBarItem' },
        ...$Pick<MobileSecurityStatsResponseMonthItemBarItem, { bar_total: * }>,
        ...{ metrics: Array<({
            ...{ __typename: 'MobileSecurityStatsResponseMonthItemBarItemMetricItem' },
          ...$Pick<MobileSecurityStatsResponseMonthItemBarItemMetricItem, { color: *, count: *, title: * }>
        })> }
      })> }
    })> }
  }) }
});

export type ActivityLogEventsSearchQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
  keywords?: ?$ElementType<Scalars, 'String'>,
};


export type ActivityLogEventsSearchQuery = ({
    ...{ __typename: 'Query' },
  ...{ activity_log_events_search: ({
      ...{ __typename: 'ActivityLogEventListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), activity_log_events?: ?Array<?({
        ...{ __typename: 'ActivityLogEvent' },
      ...$Pick<ActivityLogEvent, { id: *, description: *, date_created: * }>
    })> }
  }) }
});

export type UploadApiKeyGetQueryVariables = {};


export type UploadApiKeyGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ upload_api_key_get: ({
      ...{ __typename: 'UploadApiKeyResponse' },
    ...$Pick<UploadApiKeyResponse, { upload_api_key?: * }>
  }) }
});

export type BlockerMetricsGetQueryVariables = {};


export type BlockerMetricsGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ blocker_metrics_get: ({
      ...{ __typename: 'BlockerMetrics' },
    ...$Pick<BlockerMetrics, { open_blocker_issue_count: *, open_blocker_issue_due_to_third_party_count: *, open_blocker_issue_due_to_first_party_count: *, closed_blocker_issue_count: *, total_blocker_issue_count: * }>
  }) }
});

export type CustomersTestCredentialsGetQueryVariables = {};


export type CustomersTestCredentialsGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ customers_test_credentials_get: ({
      ...{ __typename: 'CustomersTestCredentialsResponse' },
    ...{ items?: ?Array<?({
        ...{ __typename: 'CustomersTestCredential' },
      ...$Pick<CustomersTestCredential, { id: *, username: *, comments?: * }>
    })> }
  }) }
});

export type CustomersTestCredentialsPostMutationVariables = {
  body: CustomersTestCredentialsPostParams,
};


export type CustomersTestCredentialsPostMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ customers_test_credentials_post: ({
      ...{ __typename: 'CustomersTestCredential' },
    ...$Pick<CustomersTestCredential, { id: *, username: *, comments?: * }>
  }) }
});

export type CustomersTestCredentialsPatchMutationVariables = {
  body: CustomersTestCredentialsPatchParams,
};


export type CustomersTestCredentialsPatchMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ customers_test_credentials_patch: ({
      ...{ __typename: 'CustomersTestCredential' },
    ...$Pick<CustomersTestCredential, { id: *, username: *, comments?: * }>
  }) }
});

export type JiraIntegrationConfigListQueryVariables = {};


export type JiraIntegrationConfigListQuery = ({
    ...{ __typename: 'Query' },
  ...{ jira_integration_configs_list: ({
      ...{ __typename: 'JiraIntegrationConfigListResponse' },
    ...{ jira_integrations?: ?Array<?({
        ...{ __typename: 'JiraIntegrationConfig' },
      ...$Pick<JiraIntegrationConfig, { base_url?: *, date_created?: *, date_updated?: *, export_filter?: *, export_pre_prod?: *, export_prod?: *, id?: *, is_global?: *, minimum_severity_filter?: *, project_key_or_id?: *, type_of_issue_name?: *, username?: * }>,
      ...{ severity_field_config?: ?({
          ...{ __typename: 'JiraIntegrationConfigSeverityFieldConfigMessage' },
        ...$Pick<JiraIntegrationConfigSeverityFieldConfigMessage, { field_id?: *, high_severity_value_id?: *, low_severity_value_id?: *, medium_severity_value_id?: * }>
      }), static_fields?: ?Array<?({
          ...{ __typename: 'JiraIntegrationConfigStaticField' },
        ...$Pick<JiraIntegrationConfigStaticField, { field_id?: *, field_value?: * }>
      })>, dynamic_fields?: ?Array<?({
          ...{ __typename: 'JiraIntegrationConfigDynamicField' },
        ...$Pick<JiraIntegrationConfigDynamicField, { field_id?: *, value?: * }>
      })> }
    })> }
  }) }
});

export type JiraIntegrationConfigGetQueryVariables = {
  id: $ElementType<Scalars, 'String'>,
};


export type JiraIntegrationConfigGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ jira_integration_configs_get: ({
      ...{ __typename: 'JiraIntegrationConfig' },
    ...$Pick<JiraIntegrationConfig, { base_url?: *, date_created?: *, date_updated?: *, export_filter?: *, export_pre_prod?: *, export_prod?: *, id?: *, is_global?: *, minimum_severity_filter?: *, project_key_or_id?: *, type_of_issue_name?: *, username?: * }>,
    ...{ severity_field_config?: ?({
        ...{ __typename: 'JiraIntegrationConfigSeverityFieldConfigMessage' },
      ...$Pick<JiraIntegrationConfigSeverityFieldConfigMessage, { field_id?: *, high_severity_value_id?: *, low_severity_value_id?: *, medium_severity_value_id?: * }>
    }), static_fields?: ?Array<?({
        ...{ __typename: 'JiraIntegrationConfigStaticField' },
      ...$Pick<JiraIntegrationConfigStaticField, { field_id?: *, field_value?: * }>
    })>, dynamic_fields?: ?Array<?({
        ...{ __typename: 'JiraIntegrationConfigDynamicField' },
      ...$Pick<JiraIntegrationConfigDynamicField, { field_id?: *, value?: * }>
    })> }
  }) }
});

export type JiraIntegrationConfigPatchMutationVariables = {
  id: $ElementType<Scalars, 'String'>,
  body: JiraIntegrationConfigsPatchPatchParams,
};


export type JiraIntegrationConfigPatchMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ jira_integration_configs_patch: ({
      ...{ __typename: 'JiraIntegrationConfig' },
    ...$Pick<JiraIntegrationConfig, { base_url?: *, date_created?: *, date_updated?: *, export_filter?: *, export_pre_prod?: *, export_prod?: *, id?: *, is_global?: *, minimum_severity_filter?: *, project_key_or_id?: *, type_of_issue_name?: *, username?: * }>,
    ...{ severity_field_config?: ?({
        ...{ __typename: 'JiraIntegrationConfigSeverityFieldConfigMessage' },
      ...$Pick<JiraIntegrationConfigSeverityFieldConfigMessage, { field_id?: *, high_severity_value_id?: *, low_severity_value_id?: *, medium_severity_value_id?: * }>
    }), static_fields?: ?Array<?({
        ...{ __typename: 'JiraIntegrationConfigStaticField' },
      ...$Pick<JiraIntegrationConfigStaticField, { field_id?: *, field_value?: * }>
    })>, dynamic_fields?: ?Array<?({
        ...{ __typename: 'JiraIntegrationConfigDynamicField' },
      ...$Pick<JiraIntegrationConfigDynamicField, { field_id?: *, value?: * }>
    })> }
  }) }
});

export type JiraIntegrationConfigPostMutationVariables = {
  body: JiraIntegrationConfigsPostParams,
};


export type JiraIntegrationConfigPostMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ jira_integration_configs_post: ({
      ...{ __typename: 'JiraIntegrationConfig' },
    ...$Pick<JiraIntegrationConfig, { base_url?: *, date_created?: *, date_updated?: *, export_filter?: *, export_pre_prod?: *, export_prod?: *, id?: *, is_global?: *, minimum_severity_filter?: *, project_key_or_id?: *, type_of_issue_name?: *, username?: * }>,
    ...{ severity_field_config?: ?({
        ...{ __typename: 'JiraIntegrationConfigSeverityFieldConfigMessage' },
      ...$Pick<JiraIntegrationConfigSeverityFieldConfigMessage, { field_id?: *, high_severity_value_id?: *, low_severity_value_id?: *, medium_severity_value_id?: * }>
    }), static_fields?: ?Array<?({
        ...{ __typename: 'JiraIntegrationConfigStaticField' },
      ...$Pick<JiraIntegrationConfigStaticField, { field_id?: *, field_value?: * }>
    })>, dynamic_fields?: ?Array<?({
        ...{ __typename: 'JiraIntegrationConfigDynamicField' },
      ...$Pick<JiraIntegrationConfigDynamicField, { field_id?: *, value?: * }>
    })> }
  }) }
});

export type IpRangeScansCreateMutationMutationVariables = {
  body: IpRangeScanCreateParams,
};


export type IpRangeScansCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ ip_range_scans_create: ({
      ...{ __typename: 'IpRangeScanCreateResponse' },
    ...{ ip_range_scans: Array<({
        ...{ __typename: 'IpRangeScan' },
      ...$Pick<IpRangeScan, { ip_range: *, ip_range_id?: *, title?: * }>,
      ...{ ip_address_scan_results: Array<({
          ...{ __typename: 'IpRangeScanResult' },
        ...$Pick<IpRangeScanResult, { ip_address: *, online_network_service_urls: * }>
      })> }
    })>, ip_ranges: Array<({
        ...{ __typename: 'IpRange' },
      ...$Pick<IpRange, { id: *, has_continuous_discovery_enabled: *, ip_range: * }>
    })> }
  }) }
});

export type IpRangesListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type IpRangesListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ ip_ranges_list: ({
      ...{ __typename: 'IpRangeListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), ip_ranges: Array<({
        ...{ __typename: 'IpRange' },
      ...$Pick<IpRange, { id: *, has_continuous_discovery_enabled: *, ip_range: * }>
    })> }
  }) }
});

export type IpRangesCreateMutationMutationVariables = {
  body: IpRangeCreateParams,
};


export type IpRangesCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ ip_ranges_create: ({
      ...{ __typename: 'IpRangeCreateResponse' },
    ...{ ip_ranges: Array<({
        ...{ __typename: 'IpRange' },
      ...$Pick<IpRange, { id: *, has_continuous_discovery_enabled: *, ip_range: * }>
    })> }
  }) }
});

export type IpRangesPatchMutationMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: IpRangePatchParams,
};


export type IpRangesPatchMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ ip_ranges_patch: ({
      ...{ __typename: 'IpRangePatchResponse' },
    ...{ ip_ranges: Array<({
        ...{ __typename: 'IpRange' },
      ...$Pick<IpRange, { id: *, has_continuous_discovery_enabled: *, ip_range: * }>
    })> }
  }) }
});

export type MediawatchArticlesQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  impacted_security_finding_id?: ?$ElementType<Scalars, 'String'>,
};


export type MediawatchArticlesQuery = ({
    ...{ __typename: 'Query' },
  ...{ mediawatch_articles_list: ({
      ...{ __typename: 'MediawatchArticlesResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), mediawatch_articles?: ?Array<({
        ...{ __typename: 'MediawatchArticle' },
      ...$Pick<MediawatchArticle, { id: *, description: *, published_time: *, site_name: *, title: *, url: * }>
    })> }
  }) }
});

export type MobileAppGetInsightsByAppIdQueryVariables = {
  appId: $ElementType<Scalars, 'String'>,
};


export type MobileAppGetInsightsByAppIdQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_app_insights_by_app_id: ({
      ...{ __typename: 'MobileAppInsightsByAppIdResponse' },
    ...$Pick<MobileAppInsightsByAppIdResponse, { communicates_with_countries?: *, communicates_with_ip_addresses?: *, communicates_with_first_party_origins?: *, communicates_with_third_party_origins?: * }>,
    ...{ communication_insights?: ?({
        ...{ __typename: 'MobileAppInsightsByAppIdResponseCommunicationInsights' },
      ...$Pick<MobileAppInsightsByAppIdResponseCommunicationInsights, { communicates_with_countries?: *, communicates_with_first_party_origins?: *, communicates_with_third_party_origins?: *, communicates_with_hostnames?: *, communicates_with_ip_addresses?: *, communicates_with_blacklisted_ip_addresses?: * }>
    }) }
  }) }
});

export type MobileAppInsightsQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
  mobile_app_platform?: ?MobileAppPlatform,
  mobile_app_bundle_id?: ?$ElementType<Scalars, 'String'>,
  mobile_app_release_type?: ?MobileAppReleaseType,
};


export type MobileAppInsightsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_app_insights: ({
      ...{ __typename: 'MobileAppInsightsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), mobile_app_insights?: ?Array<({
        ...{ __typename: 'MobileAppInsights' },
      ...$Pick<MobileAppInsights, { mobile_app_id: *, mobile_app_platform?: *, mobile_app_release_type?: *, mobile_app_bundle_id?: *, reminders_accessed_by?: *, microphone_accessed_by?: *, camera_accessed_by?: *, calendar_data_accessed_by?: *, contacts_accessed_by?: *, photo_library_accessed_by?: *, location_accessed_by?: *, advertisement_identifier_accessed_by?: *, open_server_ports?: *, app_container_contents?: *, hard_coded_secrets?: *, custom_fonts?: *, sha256_hash?: *, android_permissions?: *, android_minimum_sdk_version?: *, android_target_sdk_version?: *, android_apk_signature_key_size?: *, android_exposed_app_activities?: *, android_exposed_app_content_providers?: *, android_exposed_app_services?: *, android_exposed_app_broadcast_receivers?: *, android_version_name?: *, android_version_code?: *, android_native_components_loaded?: *, android_sha512_hash?: *, ios_permissions?: *, ios_app_extensions?: *, ios_supports_apple_watch?: *, ios_supports_face_id?: *, ios_supports_imessage?: *, ios_base_sdk?: *, ios_minimum_sdk?: *, ios_universal_links_domains?: *, ios_accessed_healthkit?: *, ios_cf_bundle_version?: *, communicates_with_third_party_origins?: *, communicates_with_first_party_origins?: *, communicates_with_ip_addresses?: *, communicates_with_countries?: *, sdk_count?: *, ios_entitlements?: * }>,
      ...{ communicates_with_blacklisted_ip_addresses?: ?Array<?({
          ...{ __typename: 'BlocklistedIPAddress' },
        ...$Pick<BlocklistedIpAddress, { blacklist?: *, ip_address: *, reason?: * }>
      })> }
    })> }
  }) }
});

export type UpdateGooglePlayDataSafetyManualAnswersMutationVariables = {
  id: $ElementType<Scalars, 'String'>,
  body: UpdateGooglePlayDataSafetyManualAnswersBody,
};


export type UpdateGooglePlayDataSafetyManualAnswersMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ update_google_play_data_safety_manual_answers: ({
      ...{ __typename: 'GooglePlayDataSafetyManualAnswersMutationResponse' },
    ...{ data_collection_user_request_delete_question?: ?({
        ...{ __typename: 'GooglePlayDataSafetyManualAnswersResponseQuestion' },
      ...$Pick<GooglePlayDataSafetyManualAnswersResponseQuestion, { answer_requirement: *, needs_manual_answers: *, question_id: *, question_label: * }>,
      ...{ responses: Array<?({
          ...{ __typename: 'GooglePlayDataSafetyManualAnswersResponses' },
        ...$Pick<GooglePlayDataSafetyManualAnswersResponses, { response_id: *, response_id_display_name: *, response_value?: * }>
      })> }
    }), data_types?: ?Array<?({
        ...{ __typename: 'GooglePlayDataSafetyManualAnswersResponseDataType' },
      ...$Pick<GooglePlayDataSafetyManualAnswersResponseDataType, { data_type: *, data_type_display_name: * }>,
      ...{ data_usage_questions: Array<?({
          ...{ __typename: 'GooglePlayDataSafetyManualAnswersResponseQuestion' },
        ...$Pick<GooglePlayDataSafetyManualAnswersResponseQuestion, { answer_requirement: *, needs_manual_answers: *, question_id: *, question_label: * }>,
        ...{ responses: Array<?({
            ...{ __typename: 'GooglePlayDataSafetyManualAnswersResponses' },
          ...$Pick<GooglePlayDataSafetyManualAnswersResponses, { response_id: *, response_id_display_name: *, response_value?: * }>
        })> }
      })> }
    })> }
  }) }
});

export type GetGooglePlayDataSafetyManualAnswersQueryVariables = {
  id: $ElementType<Scalars, 'String'>,
};


export type GetGooglePlayDataSafetyManualAnswersQuery = ({
    ...{ __typename: 'Query' },
  ...{ get_google_play_data_safety_manual_answers: ({
      ...{ __typename: 'GooglePlayDataSafetyManualAnswersResponse' },
    ...$Pick<GooglePlayDataSafetyManualAnswersResponse, { needs_manual_answers: * }>,
    ...{ data_collection_user_request_delete_question?: ?({
        ...{ __typename: 'GooglePlayDataSafetyManualAnswersResponseQuestion' },
      ...$Pick<GooglePlayDataSafetyManualAnswersResponseQuestion, { answer_requirement: *, needs_manual_answers: *, question_id: *, question_label: * }>,
      ...{ responses: Array<?({
          ...{ __typename: 'GooglePlayDataSafetyManualAnswersResponses' },
        ...$Pick<GooglePlayDataSafetyManualAnswersResponses, { response_id: *, response_id_display_name: *, response_value?: * }>
      })> }
    }), data_types?: ?Array<?({
        ...{ __typename: 'GooglePlayDataSafetyManualAnswersResponseDataType' },
      ...$Pick<GooglePlayDataSafetyManualAnswersResponseDataType, { data_type: *, data_type_display_name: * }>,
      ...{ data_usage_questions: Array<?({
          ...{ __typename: 'GooglePlayDataSafetyManualAnswersResponseQuestion' },
        ...$Pick<GooglePlayDataSafetyManualAnswersResponseQuestion, { answer_requirement: *, needs_manual_answers: *, question_id: *, question_label: * }>,
        ...{ responses: Array<?({
            ...{ __typename: 'GooglePlayDataSafetyManualAnswersResponses' },
          ...$Pick<GooglePlayDataSafetyManualAnswersResponses, { response_id: *, response_id_display_name: *, response_value?: * }>
        })> }
      })> }
    })> }
  }) }
});

export type MobileAppGetGoogleSafetyCsvQueryQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type MobileAppGetGoogleSafetyCsvQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_app_get_google_safety_csv: ({
      ...{ __typename: 'MobileAppCsvResponse' },
    ...{ headers: Array<({
        ...{ __typename: 'MobileAppCsvResponseHeader' },
      ...$Pick<MobileAppCsvResponseHeader, { code_name: *, file_name: * }>
    })>, rows: Array<({
        ...{ __typename: 'MobileAppCsvResponseRows' },
      ...$Pick<MobileAppCsvResponseRows, { answer_requirement: *, question_id: *, question_label: *, response_id: *, response_value: * }>
    })> }
  }) }
});

export type MobileAppGetQueryQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type MobileAppGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_app_get: ({
      ...{ __typename: 'MobileApp' },
    ...$Pick<MobileApp, { id: *, uuid?: *, name: *, platform: *, app_protection_score?: *, app_protection_score_ratio?: *, blocked_scan_reason?: *, category?: *, date_updated?: *, date_created: *, app_secure_id?: *, max_app_protection_score: *, subscription: *, release_type?: *, bundle_id?: *, is_enterprise_internal?: *, app_store_customer_mobile_app_id?: *, icon_url?: *, store_url?: *, scan_status?: *, all_communication_encrypted: *, does_not_offload_data_to_third_parties: *, enhances_password_security: *, full_protection_on_untrusted_networks: *, leverages_secure_browser_settings: *, leverages_secure_system_libraries: *, leverages_secure_system_sdk: *, leverages_secure_system_settings: *, protects_data_on_screens: *, protects_the_keyboard: *, removes_data_from_shared_device_locations: *, requires_android_device_security: *, requires_android_malware_protection: *, server_enforces_https: *, supports_two_factor_authentication: *, trustkit_url?: * }>,
    ...{ integrations?: ?({
        ...{ __typename: 'Integrations' },
      ...$Pick<Integrations, { ci_cd?: *, issue_tracker?: *, trust_kit?: * }>
    }), most_recent_scan?: ?({
        ...{ __typename: 'ApplicationScan' },
      ...$Pick<ApplicationScan, { id: *, date_created: *, date_updated: *, mobile_app_id: *, app_version?: *, start_date: *, end_date?: * }>
    }), metadata: ({
        ...{ __typename: 'ApplicationMetadata' },
      ...$Pick<ApplicationMetadata, { closed_security_finding_apple_app_store_blocker_count: *, closed_security_finding_beyond_subscription_level_count?: *, closed_security_finding_count: *, closed_security_finding_google_play_blocker_count: *, closed_security_finding_high_count: *, closed_security_finding_low_count: *, closed_security_finding_medium_count: *, closed_security_finding_security_p1_count: *, closed_security_finding_regulatory_compliance_count: *, open_insight_finding_count: *, open_sdk_finding_beyond_subscription_level_count: *, open_sdk_finding_beyond_subscription_level_with_open_issues_beyond_subscription_level_count: *, open_sdk_finding_count: *, open_sdk_finding_with_open_issues_beyond_subscription_level_count: *, open_sdk_finding_with_open_issues_count: *, open_security_finding_apple_app_store_blocker_beyond_subscription_level_count: *, open_security_finding_apple_app_store_blocker_count: *, open_security_finding_beyond_subscription_level_count: *, open_security_finding_count: *, open_security_finding_google_play_blocker_beyond_subscription_level_count: *, open_security_finding_google_play_blocker_count: *, open_security_finding_high_beyond_subscription_level_count: *, open_security_finding_high_count: *, open_security_finding_low_beyond_subscription_level_count: *, open_security_finding_low_count: *, open_security_finding_medium_beyond_subscription_level_count: *, open_security_finding_medium_count: *, open_security_finding_security_p1_beyond_subscription_level_count: *, open_security_finding_security_p1_count: *, open_security_finding_regulatory_compliance_count: *, open_security_finding_regulatory_compliance_beyond_subscription_level_count: * }>
    }), keys_to_the_kingdom: ({
        ...{ __typename: 'ToolkitsFindingAndTargetsCount' },
      ...$Pick<ToolkitsFindingAndTargetsCount, { open_security_finding_count: *, open_security_finding_target_count: * }>
    }), coffee_shop_compromise: ({
        ...{ __typename: 'ToolkitsFindingAndTargetsCount' },
      ...$Pick<ToolkitsFindingAndTargetsCount, { open_security_finding_count: *, open_security_finding_target_count: * }>
    }), app_store_privacy?: ?({
        ...{ __typename: 'AppStorePrivacy' },
      ...$Pick<AppStorePrivacy, { data_record_types_collected_count: *, underreported_data_types_count: * }>
    }), app_store_privacy_details?: ?({
        ...{ __typename: 'AppStorePrivacyDetails' },
      ...$Pick<AppStorePrivacyDetails, { app_store_privacy_screenshot_url?: *, app_store_privacy_screenshot_url_date?: *, data_records_collected_count: *, data_records_shared_with_sdks_count: *, embedded_sdk_count: * }>,
      ...{ data_theorem_observed_privacy_data?: ?Array<({
          ...{ __typename: 'DTObservedPrivacyData' },
        ...$Pick<DtObservedPrivacyData, { apple_data_category: *, apple_data_category_name_en: *, is_underreported: * }>
      })>, downloaded_in_countries?: ?Array<({
          ...{ __typename: 'DownloadedInCountriesResponse' },
        ...$Pick<DownloadedInCountriesResponse, { alpha2: *, name_en: * }>
      })>, app_store_privacy_data?: ?Array<({
          ...{ __typename: 'AppleReportedPrivacyData' },
        ...$Pick<AppleReportedPrivacyData, { apple_data_category: *, apple_data_category_name_en: * }>
      })> }
    }), asset_tags?: ?Array<?({
        ...{ __typename: 'AssetTag' },
      ...$Pick<AssetTag, { id: *, tag_id: *, tag: *, value?: *, imported_from?: *, imported_external_id?: *, imported_from_icon_url?: * }>
    })> }
  }) }
});

export type MobileAppsListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  platform?: ?MobileAppPlatform,
  include?: ?Array<$ElementType<Scalars, 'String'>>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
  bundle_id?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  release_type?: ?MobileAppReleaseType,
  subscription?: ?MobileAppSubscription,
  updated_since?: ?$ElementType<Scalars, 'Date'>,
};


export type MobileAppsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_apps_list: ({
      ...{ __typename: 'MobileAppsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), mobile_apps?: ?Array<({
        ...{ __typename: 'MobileApp' },
      ...$Pick<MobileApp, { id: *, uuid?: *, name: *, platform: *, app_protection_score?: *, app_protection_score_ratio?: *, blocked_scan_reason?: *, category?: *, app_secure_id?: *, max_app_protection_score: *, subscription: *, release_type?: *, bundle_id?: *, is_enterprise_internal?: *, app_store_customer_mobile_app_id?: *, icon_url?: *, store_url?: *, date_updated?: *, date_created: *, scan_status?: *, all_communication_encrypted: *, does_not_offload_data_to_third_parties: *, enhances_password_security: *, full_protection_on_untrusted_networks: *, leverages_secure_browser_settings: *, leverages_secure_system_libraries: *, leverages_secure_system_sdk: *, leverages_secure_system_settings: *, protects_data_on_screens: *, protects_the_keyboard: *, removes_data_from_shared_device_locations: *, requires_android_device_security: *, requires_android_malware_protection: *, server_enforces_https: *, supports_two_factor_authentication: *, trustkit_url?: * }>,
      ...{ integrations?: ?({
          ...{ __typename: 'Integrations' },
        ...$Pick<Integrations, { ci_cd?: *, issue_tracker?: *, trust_kit?: * }>
      }), most_recent_scan?: ?({
          ...{ __typename: 'ApplicationScan' },
        ...$Pick<ApplicationScan, { id: *, date_created: *, date_updated: *, mobile_app_id: *, app_version?: *, start_date: *, end_date?: * }>
      }), metadata: ({
          ...{ __typename: 'ApplicationMetadata' },
        ...$Pick<ApplicationMetadata, { closed_security_finding_apple_app_store_blocker_count: *, closed_security_finding_beyond_subscription_level_count?: *, closed_security_finding_count: *, closed_security_finding_google_play_blocker_count: *, closed_security_finding_high_count: *, closed_security_finding_low_count: *, closed_security_finding_medium_count: *, closed_security_finding_security_p1_count: *, closed_security_finding_regulatory_compliance_count: *, open_insight_finding_count: *, open_sdk_finding_beyond_subscription_level_count: *, open_sdk_finding_beyond_subscription_level_with_open_issues_beyond_subscription_level_count: *, open_sdk_finding_count: *, open_sdk_finding_with_open_issues_beyond_subscription_level_count: *, open_sdk_finding_with_open_issues_count: *, open_security_finding_apple_app_store_blocker_beyond_subscription_level_count: *, open_security_finding_apple_app_store_blocker_count: *, open_security_finding_beyond_subscription_level_count: *, open_security_finding_count: *, open_security_finding_google_play_blocker_beyond_subscription_level_count: *, open_security_finding_google_play_blocker_count: *, open_security_finding_high_beyond_subscription_level_count: *, open_security_finding_high_count: *, open_security_finding_low_beyond_subscription_level_count: *, open_security_finding_low_count: *, open_security_finding_medium_beyond_subscription_level_count: *, open_security_finding_medium_count: *, open_security_finding_security_p1_beyond_subscription_level_count: *, open_security_finding_security_p1_count: *, open_security_finding_regulatory_compliance_count: *, open_security_finding_regulatory_compliance_beyond_subscription_level_count: * }>
      }), keys_to_the_kingdom: ({
          ...{ __typename: 'ToolkitsFindingAndTargetsCount' },
        ...$Pick<ToolkitsFindingAndTargetsCount, { open_security_finding_count: *, open_security_finding_target_count: * }>
      }), coffee_shop_compromise: ({
          ...{ __typename: 'ToolkitsFindingAndTargetsCount' },
        ...$Pick<ToolkitsFindingAndTargetsCount, { open_security_finding_count: *, open_security_finding_target_count: * }>
      }), app_store_privacy?: ?({
          ...{ __typename: 'AppStorePrivacy' },
        ...$Pick<AppStorePrivacy, { data_record_types_collected_count: *, underreported_data_types_count: * }>
      }), app_store_privacy_details?: ?({
          ...{ __typename: 'AppStorePrivacyDetails' },
        ...$Pick<AppStorePrivacyDetails, { app_store_privacy_screenshot_url?: *, app_store_privacy_screenshot_url_date?: *, data_records_collected_count: *, data_records_shared_with_sdks_count: *, embedded_sdk_count: * }>,
        ...{ data_theorem_observed_privacy_data?: ?Array<({
            ...{ __typename: 'DTObservedPrivacyData' },
          ...$Pick<DtObservedPrivacyData, { apple_data_category: *, apple_data_category_name_en: *, is_underreported: * }>
        })>, downloaded_in_countries?: ?Array<({
            ...{ __typename: 'DownloadedInCountriesResponse' },
          ...$Pick<DownloadedInCountriesResponse, { alpha2: *, name_en: * }>
        })>, app_store_privacy_data?: ?Array<({
            ...{ __typename: 'AppleReportedPrivacyData' },
          ...$Pick<AppleReportedPrivacyData, { apple_data_category: *, apple_data_category_name_en: * }>
        })> }
      }), asset_tags?: ?Array<?({
          ...{ __typename: 'AssetTag' },
        ...$Pick<AssetTag, { id: *, tag_id: *, tag: *, value?: *, imported_from?: *, imported_external_id?: *, imported_from_icon_url?: * }>
      })> }
    })> }
  }) }
});

export type MobileAppsListWithFilterQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  platform?: ?MobileAppPlatform,
  filter?: ?MobileAppListFilter,
  include?: ?Array<$ElementType<Scalars, 'String'>>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
  bundle_id?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  release_type?: ?MobileAppReleaseType,
  subscription?: ?MobileAppSubscription,
  updated_since?: ?$ElementType<Scalars, 'Date'>,
};


export type MobileAppsListWithFilterQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_apps_list: ({
      ...{ __typename: 'MobileAppsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), mobile_apps?: ?Array<({
        ...{ __typename: 'MobileApp' },
      ...$Pick<MobileApp, { id: *, name: *, platform: *, app_protection_score?: *, app_protection_score_ratio?: *, blocked_scan_reason?: *, category?: *, app_secure_id?: *, max_app_protection_score: *, subscription: *, release_type?: *, bundle_id?: *, is_enterprise_internal?: *, app_store_customer_mobile_app_id?: *, icon_url?: *, store_url?: *, date_updated?: *, date_created: *, scan_status?: *, all_communication_encrypted: *, does_not_offload_data_to_third_parties: *, enhances_password_security: *, full_protection_on_untrusted_networks: *, leverages_secure_browser_settings: *, leverages_secure_system_libraries: *, leverages_secure_system_sdk: *, leverages_secure_system_settings: *, protects_data_on_screens: *, protects_the_keyboard: *, removes_data_from_shared_device_locations: *, requires_android_device_security: *, requires_android_malware_protection: *, server_enforces_https: *, supports_two_factor_authentication: *, trustkit_url?: * }>,
      ...{ integrations?: ?({
          ...{ __typename: 'Integrations' },
        ...$Pick<Integrations, { ci_cd?: *, issue_tracker?: *, trust_kit?: * }>
      }), most_recent_scan?: ?({
          ...{ __typename: 'ApplicationScan' },
        ...$Pick<ApplicationScan, { id: *, date_created: *, date_updated: *, mobile_app_id: *, app_version?: *, start_date: *, end_date?: * }>
      }), metadata: ({
          ...{ __typename: 'ApplicationMetadata' },
        ...$Pick<ApplicationMetadata, { closed_security_finding_apple_app_store_blocker_count: *, closed_security_finding_beyond_subscription_level_count?: *, closed_security_finding_count: *, closed_security_finding_google_play_blocker_count: *, closed_security_finding_high_count: *, closed_security_finding_low_count: *, closed_security_finding_medium_count: *, closed_security_finding_security_p1_count: *, closed_security_finding_regulatory_compliance_count: *, open_insight_finding_count: *, open_sdk_finding_beyond_subscription_level_count: *, open_sdk_finding_beyond_subscription_level_with_open_issues_beyond_subscription_level_count: *, open_sdk_finding_count: *, open_sdk_finding_with_open_issues_beyond_subscription_level_count: *, open_sdk_finding_with_open_issues_count: *, open_security_finding_apple_app_store_blocker_beyond_subscription_level_count: *, open_security_finding_apple_app_store_blocker_count: *, open_security_finding_beyond_subscription_level_count: *, open_security_finding_count: *, open_security_finding_google_play_blocker_beyond_subscription_level_count: *, open_security_finding_google_play_blocker_count: *, open_security_finding_high_beyond_subscription_level_count: *, open_security_finding_high_count: *, open_security_finding_low_beyond_subscription_level_count: *, open_security_finding_low_count: *, open_security_finding_medium_beyond_subscription_level_count: *, open_security_finding_medium_count: *, open_security_finding_security_p1_beyond_subscription_level_count: *, open_security_finding_security_p1_count: *, open_security_finding_regulatory_compliance_count: *, open_security_finding_regulatory_compliance_beyond_subscription_level_count: * }>
      }), keys_to_the_kingdom: ({
          ...{ __typename: 'ToolkitsFindingAndTargetsCount' },
        ...$Pick<ToolkitsFindingAndTargetsCount, { open_security_finding_count: *, open_security_finding_target_count: * }>
      }), coffee_shop_compromise: ({
          ...{ __typename: 'ToolkitsFindingAndTargetsCount' },
        ...$Pick<ToolkitsFindingAndTargetsCount, { open_security_finding_count: *, open_security_finding_target_count: * }>
      }), app_store_privacy?: ?({
          ...{ __typename: 'AppStorePrivacy' },
        ...$Pick<AppStorePrivacy, { data_record_types_collected_count: *, underreported_data_types_count: * }>
      }), app_store_privacy_details?: ?({
          ...{ __typename: 'AppStorePrivacyDetails' },
        ...$Pick<AppStorePrivacyDetails, { app_store_privacy_screenshot_url?: *, app_store_privacy_screenshot_url_date?: *, data_records_collected_count: *, data_records_shared_with_sdks_count: *, embedded_sdk_count: * }>,
        ...{ data_theorem_observed_privacy_data?: ?Array<({
            ...{ __typename: 'DTObservedPrivacyData' },
          ...$Pick<DtObservedPrivacyData, { apple_data_category: *, apple_data_category_name_en: *, is_underreported: * }>
        })>, downloaded_in_countries?: ?Array<({
            ...{ __typename: 'DownloadedInCountriesResponse' },
          ...$Pick<DownloadedInCountriesResponse, { alpha2: *, name_en: * }>
        })>, app_store_privacy_data?: ?Array<({
            ...{ __typename: 'AppleReportedPrivacyData' },
          ...$Pick<AppleReportedPrivacyData, { apple_data_category: *, apple_data_category_name_en: * }>
        })> }
      }), asset_tags?: ?Array<?({
          ...{ __typename: 'AssetTag' },
        ...$Pick<AssetTag, { id: *, tag_id: *, tag: *, value?: *, imported_from?: *, imported_external_id?: *, imported_from_icon_url?: * }>
      })> }
    })> }
  }) }
});

export type AnalysisRequestListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type AnalysisRequestListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_app_analysis_requests_list: ({
      ...{ __typename: 'MobileAppAnalysisRequestListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), mobile_app_analysis_requests?: ?Array<({
        ...{ __typename: 'AnalysisRequest' },
      ...$Pick<AnalysisRequest, { id: *, date_created: *, date_updated: *, feature?: *, progress: *, requested_by: *, status?: * }>,
      ...{ analyzed_apps?: ?Array<({
          ...{ __typename: 'AnalyzedApp' },
        ...$Pick<AnalyzedApp, { mobile_app_id: *, scan_status: * }>
      })> }
    })> }
  }) }
});

export type AnalysisRequestCreateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: MobileAppAnalysisRequestParams,
};


export type AnalysisRequestCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ mobile_app_analysis_request_create: ({
      ...{ __typename: 'AnalysisRequest' },
    ...$Pick<AnalysisRequest, { id: *, date_created: *, date_updated: *, feature?: *, progress: *, requested_by: *, status?: * }>,
    ...{ analyzed_apps?: ?Array<({
        ...{ __typename: 'AnalyzedApp' },
      ...$Pick<AnalyzedApp, { mobile_app_id: *, scan_status: * }>
    })> }
  }) }
});

export type AnalysisScheduleGetQueryQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type AnalysisScheduleGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_app_analysis_schedules_get: ({
      ...{ __typename: 'MobileAppAnalysisScheduleResponse' },
    ...$Pick<MobileAppAnalysisScheduleResponse, { id: *, date_created: *, date_updated: *, time_when_to_scan: *, weekdays_when_to_scan?: * }>
  }) }
});

export type AnalysisScheduleUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: MobileAppAnalysisScheduleParams,
};


export type AnalysisScheduleUpdateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ mobile_app_analysis_schedule_patch: ({
      ...{ __typename: 'MobileAppAnalysisScheduleResponse' },
    ...$Pick<MobileAppAnalysisScheduleResponse, { id: *, date_created: *, date_updated: *, time_when_to_scan: *, weekdays_when_to_scan?: * }>
  }) }
});

export type OnboardingCompletePutMutationMutationVariables = {};


export type OnboardingCompletePutMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ onboarding_completion_put: ({
      ...{ __typename: 'OnboardingCompletionResponse' },
    ...$Pick<OnboardingCompletionResponse, { onboarding_completed: * }>
  }) }
});

export type OnboardingCompleteDeleteMutationMutationVariables = {};


export type OnboardingCompleteDeleteMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ onboarding_completion_delete: ({
      ...{ __typename: 'OnboardingCompletionResponse' },
    ...$Pick<OnboardingCompletionResponse, { onboarding_completed: * }>
  }) }
});

export type OnboardingDataQueryVariables = {};


export type OnboardingDataQuery = ({
    ...{ __typename: 'Query' },
  ...{ onboarding_data_get: ({
      ...{ __typename: 'OnboardingDataResponse' },
    ...$Pick<OnboardingDataResponse, { date_created: *, date_updated: *, date_discount_ending?: *, has_core_driver_compliance: *, has_core_driver_incident_response: *, has_core_driver_security: *, allow_onboarding_of?: *, required_steps?: *, completed_steps?: * }>
  }) }
});

export type OnboardingDataCompleteStepPutMutationMutationVariables = {
  stepEnum: OnboardingStepsEnum,
};


export type OnboardingDataCompleteStepPutMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ onboarding_data_completed_step_put: ({
      ...{ __typename: 'OnboardingDataResponse' },
    ...$Pick<OnboardingDataResponse, { date_created: *, date_updated: *, date_discount_ending?: *, has_core_driver_compliance: *, has_core_driver_incident_response: *, has_core_driver_security: *, allow_onboarding_of?: *, required_steps?: *, completed_steps?: * }>
  }) }
});

export type OnboardingDataPutMutationMutationVariables = {
  body: OnboardingCoreDriverPatch,
};


export type OnboardingDataPutMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ onboarding_data_core_driver_patch: ({
      ...{ __typename: 'OnboardingDataResponse' },
    ...$Pick<OnboardingDataResponse, { date_created: *, date_updated: *, date_discount_ending?: *, has_core_driver_compliance: *, has_core_driver_incident_response: *, has_core_driver_security: *, allow_onboarding_of?: *, required_steps?: *, completed_steps?: * }>
  }) }
});

export type OpenScanMobileAppGetQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type OpenScanMobileAppGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ openscan_mobile_apps_get: ({
      ...{ __typename: 'OpenScanApp' },
    ...$Pick<OpenScanApp, { date_created: *, date_updated: *, id: *, name: *, platform: *, bundle_id: *, store_url?: *, category?: *, last_scanned_version?: *, last_scanned_date?: *, scan_count: *, has_results: *, watchlist_groups?: *, app_protection_score: *, max_app_protection_score: *, app_protection_score_ratio: *, app_protection_percentile?: *, app_description?: *, app_price_in_cents?: *, has_in_app_purchases?: *, publisher_name?: *, publisher_website?: *, initial_release_date?: *, icon_url?: *, screenshot_urls?: *, ranking?: *, publisher_can_be_contacted_automatically: *, inappropriate_for_the_enterprise: *, favorite: * }>,
    ...{ security_metrics?: ?({
        ...{ __typename: 'OpenScanMobileAppSecurityMetrics' },
      ...$Pick<OpenScanMobileAppSecurityMetrics, { open_security_finding_count: *, open_security_finding_security_p1_count: *, open_security_finding_apple_app_store_blocker_count: *, open_security_finding_google_play_blocker_count: *, open_security_finding_high_severity_count: *, open_security_finding_medium_severity_count: *, open_security_finding_low_severity_count: *, open_security_finding_caused_by_sdk_count: *, sdk_with_issue_count: *, closed_security_issues_count: * }>
    }), insights: ({
        ...{ __typename: 'OpenScanMobileAppInsights' },
      ...$Pick<OpenScanMobileAppInsights, { sdk_count: *, communicates_with_hostnames?: *, communicates_with_ip_addresses?: *, communicates_with_countries?: *, ios_base_sdk?: *, ios_minimum_sdk?: *, ios_permissions?: *, ios_app_extensions?: *, ios_supports_apple_watch: *, ios_supports_face_id: *, ios_supports_imessage: *, ios_cf_bundle_version?: *, android_permissions?: *, android_minimum_sdk_version?: *, android_target_sdk_version?: * }>,
      ...{ communicates_with_blacklisted_ip_addresses?: ?Array<?({
          ...{ __typename: 'BlocklistedIPAddress' },
        ...$Pick<BlocklistedIpAddress, { blacklist?: *, ip_address: *, reason?: * }>
      })> }
    }), app_protection_metrics: ({
        ...{ __typename: 'OpenScanMobileAppAppProtectionMetrics' },
      ...$Pick<OpenScanMobileAppAppProtectionMetrics, { full_protection_on_untrusted_networks: *, removes_data_from_shared_device_locations: *, protects_the_keyboard: *, all_communication_encrypted: *, supports_two_factor_authentication: *, leverages_secure_system_sdk: *, leverages_secure_browser_settings: *, leverages_secure_system_libraries: *, leverages_secure_system_settings: *, enhances_password_security: *, does_not_offload_data_to_third_parties: *, protects_data_on_screens: *, requires_android_malware_protection: *, requires_android_device_security: *, server_enforces_https: * }>
    }), sdks?: ?Array<({
        ...{ __typename: 'OpenScanSDK' },
      ...$Pick<OpenScanSdk, { sdk_id: *, title: *, description: *, category: *, url?: *, logo_url?: * }>
    })> }
  }) }
});

export type SearchOpenscanAppsQueryVariables = {
  query: $ElementType<Scalars, 'String'>,
};


export type SearchOpenscanAppsQuery = ({
    ...{ __typename: 'Query' },
  ...{ openscan_mobile_apps_search: ({
      ...{ __typename: 'OpenScanMobileAppSearchResponse' },
    ...{ openscan_mobile_app_search_results?: ?Array<({
        ...{ __typename: 'OpenScanSearchResponse' },
      ...$Pick<OpenScanSearchResponse, { id: *, icon_url?: *, name: *, platform: *, store_url?: * }>
    })> }
  }) }
});

export type AddToFavoritesMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type AddToFavoritesMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ openscan_favorite_apps_add: ({
      ...{ __typename: 'OpenScanApp' },
    ...$Pick<OpenScanApp, { date_created: *, date_updated: *, id: *, name: *, platform: *, bundle_id: *, store_url?: *, category?: *, last_scanned_version?: *, last_scanned_date?: *, scan_count: *, has_results: *, watchlist_groups?: *, app_protection_score: *, max_app_protection_score: *, app_protection_score_ratio: *, app_protection_percentile?: *, app_description?: *, app_price_in_cents?: *, has_in_app_purchases?: *, publisher_name?: *, publisher_website?: *, initial_release_date?: *, icon_url?: *, screenshot_urls?: *, ranking?: *, publisher_can_be_contacted_automatically: *, inappropriate_for_the_enterprise: *, favorite: * }>,
    ...{ security_metrics?: ?({
        ...{ __typename: 'OpenScanMobileAppSecurityMetrics' },
      ...$Pick<OpenScanMobileAppSecurityMetrics, { open_security_finding_count: *, open_security_finding_security_p1_count: *, open_security_finding_apple_app_store_blocker_count: *, open_security_finding_google_play_blocker_count: *, open_security_finding_high_severity_count: *, open_security_finding_medium_severity_count: *, open_security_finding_low_severity_count: *, open_security_finding_caused_by_sdk_count: *, sdk_with_issue_count: *, closed_security_issues_count: * }>
    }), insights: ({
        ...{ __typename: 'OpenScanMobileAppInsights' },
      ...$Pick<OpenScanMobileAppInsights, { sdk_count: *, communicates_with_hostnames?: *, communicates_with_ip_addresses?: *, communicates_with_countries?: *, ios_base_sdk?: *, ios_minimum_sdk?: *, ios_permissions?: *, ios_app_extensions?: *, ios_supports_apple_watch: *, ios_supports_face_id: *, ios_supports_imessage: *, ios_cf_bundle_version?: *, android_permissions?: *, android_minimum_sdk_version?: *, android_target_sdk_version?: * }>,
      ...{ communicates_with_blacklisted_ip_addresses?: ?Array<?({
          ...{ __typename: 'BlocklistedIPAddress' },
        ...$Pick<BlocklistedIpAddress, { blacklist?: *, ip_address: *, reason?: * }>
      })> }
    }), app_protection_metrics: ({
        ...{ __typename: 'OpenScanMobileAppAppProtectionMetrics' },
      ...$Pick<OpenScanMobileAppAppProtectionMetrics, { full_protection_on_untrusted_networks: *, removes_data_from_shared_device_locations: *, protects_the_keyboard: *, all_communication_encrypted: *, supports_two_factor_authentication: *, leverages_secure_system_sdk: *, leverages_secure_browser_settings: *, leverages_secure_system_libraries: *, leverages_secure_system_settings: *, enhances_password_security: *, does_not_offload_data_to_third_parties: *, protects_data_on_screens: *, requires_android_malware_protection: *, requires_android_device_security: *, server_enforces_https: * }>
    }), sdks?: ?Array<({
        ...{ __typename: 'OpenScanSDK' },
      ...$Pick<OpenScanSdk, { sdk_id: *, title: *, description: *, category: *, url?: *, logo_url?: * }>
    })> }
  }) }
});

export type RemoveFromFavoritesMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type RemoveFromFavoritesMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ openscan_favorite_apps_remove: ({
      ...{ __typename: 'OpenScanApp' },
    ...$Pick<OpenScanApp, { date_created: *, date_updated: *, id: *, name: *, platform: *, bundle_id: *, store_url?: *, category?: *, last_scanned_version?: *, last_scanned_date?: *, scan_count: *, has_results: *, watchlist_groups?: *, app_protection_score: *, max_app_protection_score: *, app_protection_score_ratio: *, app_protection_percentile?: *, app_description?: *, app_price_in_cents?: *, has_in_app_purchases?: *, publisher_name?: *, publisher_website?: *, initial_release_date?: *, icon_url?: *, screenshot_urls?: *, ranking?: *, publisher_can_be_contacted_automatically: *, inappropriate_for_the_enterprise: *, favorite: * }>,
    ...{ security_metrics?: ?({
        ...{ __typename: 'OpenScanMobileAppSecurityMetrics' },
      ...$Pick<OpenScanMobileAppSecurityMetrics, { open_security_finding_count: *, open_security_finding_security_p1_count: *, open_security_finding_apple_app_store_blocker_count: *, open_security_finding_google_play_blocker_count: *, open_security_finding_high_severity_count: *, open_security_finding_medium_severity_count: *, open_security_finding_low_severity_count: *, open_security_finding_caused_by_sdk_count: *, sdk_with_issue_count: *, closed_security_issues_count: * }>
    }), insights: ({
        ...{ __typename: 'OpenScanMobileAppInsights' },
      ...$Pick<OpenScanMobileAppInsights, { sdk_count: *, communicates_with_hostnames?: *, communicates_with_ip_addresses?: *, communicates_with_countries?: *, ios_base_sdk?: *, ios_minimum_sdk?: *, ios_permissions?: *, ios_app_extensions?: *, ios_supports_apple_watch: *, ios_supports_face_id: *, ios_supports_imessage: *, ios_cf_bundle_version?: *, android_permissions?: *, android_minimum_sdk_version?: *, android_target_sdk_version?: * }>,
      ...{ communicates_with_blacklisted_ip_addresses?: ?Array<?({
          ...{ __typename: 'BlocklistedIPAddress' },
        ...$Pick<BlocklistedIpAddress, { blacklist?: *, ip_address: *, reason?: * }>
      })> }
    }), app_protection_metrics: ({
        ...{ __typename: 'OpenScanMobileAppAppProtectionMetrics' },
      ...$Pick<OpenScanMobileAppAppProtectionMetrics, { full_protection_on_untrusted_networks: *, removes_data_from_shared_device_locations: *, protects_the_keyboard: *, all_communication_encrypted: *, supports_two_factor_authentication: *, leverages_secure_system_sdk: *, leverages_secure_browser_settings: *, leverages_secure_system_libraries: *, leverages_secure_system_settings: *, enhances_password_security: *, does_not_offload_data_to_third_parties: *, protects_data_on_screens: *, requires_android_malware_protection: *, requires_android_device_security: *, server_enforces_https: * }>
    }), sdks?: ?Array<({
        ...{ __typename: 'OpenScanSDK' },
      ...$Pick<OpenScanSdk, { sdk_id: *, title: *, description: *, category: *, url?: *, logo_url?: * }>
    })> }
  }) }
});

export type FavoriteAppsListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type FavoriteAppsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ openscan_favorite_apps: ({
      ...{ __typename: 'OpenScanMobileAppsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), openscan_mobile_apps?: ?Array<({
        ...{ __typename: 'OpenScanApp' },
      ...$Pick<OpenScanApp, { date_created: *, date_updated: *, id: *, name: *, platform: *, bundle_id: *, store_url?: *, category?: *, last_scanned_version?: *, last_scanned_date?: *, scan_count: *, has_results: *, watchlist_groups?: *, app_protection_score: *, max_app_protection_score: *, app_protection_score_ratio: *, app_protection_percentile?: *, app_description?: *, app_price_in_cents?: *, has_in_app_purchases?: *, publisher_name?: *, publisher_website?: *, initial_release_date?: *, icon_url?: *, screenshot_urls?: *, ranking?: *, publisher_can_be_contacted_automatically: *, inappropriate_for_the_enterprise: *, favorite: * }>,
      ...{ security_metrics?: ?({
          ...{ __typename: 'OpenScanMobileAppSecurityMetrics' },
        ...$Pick<OpenScanMobileAppSecurityMetrics, { open_security_finding_count: *, open_security_finding_security_p1_count: *, open_security_finding_apple_app_store_blocker_count: *, open_security_finding_google_play_blocker_count: *, open_security_finding_high_severity_count: *, open_security_finding_medium_severity_count: *, open_security_finding_low_severity_count: *, open_security_finding_caused_by_sdk_count: *, sdk_with_issue_count: *, closed_security_issues_count: * }>
      }), insights: ({
          ...{ __typename: 'OpenScanMobileAppInsights' },
        ...$Pick<OpenScanMobileAppInsights, { sdk_count: *, communicates_with_hostnames?: *, communicates_with_ip_addresses?: *, communicates_with_countries?: *, ios_base_sdk?: *, ios_minimum_sdk?: *, ios_permissions?: *, ios_app_extensions?: *, ios_supports_apple_watch: *, ios_supports_face_id: *, ios_supports_imessage: *, ios_cf_bundle_version?: *, android_permissions?: *, android_minimum_sdk_version?: *, android_target_sdk_version?: * }>,
        ...{ communicates_with_blacklisted_ip_addresses?: ?Array<?({
            ...{ __typename: 'BlocklistedIPAddress' },
          ...$Pick<BlocklistedIpAddress, { blacklist?: *, ip_address: *, reason?: * }>
        })> }
      }), app_protection_metrics: ({
          ...{ __typename: 'OpenScanMobileAppAppProtectionMetrics' },
        ...$Pick<OpenScanMobileAppAppProtectionMetrics, { full_protection_on_untrusted_networks: *, removes_data_from_shared_device_locations: *, protects_the_keyboard: *, all_communication_encrypted: *, supports_two_factor_authentication: *, leverages_secure_system_sdk: *, leverages_secure_browser_settings: *, leverages_secure_system_libraries: *, leverages_secure_system_settings: *, enhances_password_security: *, does_not_offload_data_to_third_parties: *, protects_data_on_screens: *, requires_android_malware_protection: *, requires_android_device_security: *, server_enforces_https: * }>
      }), sdks?: ?Array<({
          ...{ __typename: 'OpenScanSDK' },
        ...$Pick<OpenScanSdk, { sdk_id: *, title: *, description: *, category: *, url?: *, logo_url?: * }>
      })> }
    })> }
  }) }
});

export type PortalNotificationsListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type PortalNotificationsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ portal_notifications_list: ({
      ...{ __typename: 'PortalNotificationsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), portal_notifications?: ?Array<({
        ...{ __typename: 'SevenhellNotificationEvent' },
      ...$Pick<SevenhellNotificationEvent, { id: *, title?: *, event_type: *, icon_url: *, detail: *, detail_icon_url: *, date_created: *, portal_url: *, subtitle?: * }>
    })> }
  }) }
});

export type SamlGetQueryVariables = {};


export type SamlGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ saml_get: ({
      ...{ __typename: 'SamlGet' },
    ...$Pick<SamlGet, { has_saml_idp?: *, saml_auto_provisioning_of_users_enabled?: * }>,
    ...{ saml_sp_metadata?: ?({
        ...{ __typename: 'SamlSpMetadata' },
      ...$Pick<SamlSpMetadata, { assertion_consumer_service_url: *, entity_id: *, metadata_file_url: * }>
    }) }
  }) }
});

export type ScopedAccessTokenListQueryVariables = {};


export type ScopedAccessTokenListQuery = ({
    ...{ __typename: 'Query' },
  ...{ scoped_access_token_list?: ?({
      ...{ __typename: 'ScopedAccessTokenListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), scoped_access_tokens?: ?Array<({
        ...{ __typename: 'ScopedAccessTokenResponse' },
      ...$Pick<ScopedAccessTokenResponse, { access_granted_by: *, access_token: *, date_created: *, date_expires: *, date_updated?: *, id: * }>,
      ...{ app_secure_access_properties?: ?({
          ...{ __typename: 'AppSecureAccessProperties' },
        ...$Pick<AppSecureAccessProperties, { can_access_all_apps: *, can_access_app_ids?: *, can_access_scope?: *, can_access_app_secure_security_finding_id?: * }>
      }), app_search_access_properties?: ?({
          ...{ __typename: 'AppSearchAccessProperties' },
        ...$Pick<AppSearchAccessProperties, { can_access_all_apps: *, can_access_app_ids?: *, can_access_scope?: * }>
      }) }
    })> }
  }) }
});

export type ScopedAccessTokenDeleteMutationVariables = {
  scoped_access_token_id: $ElementType<Scalars, 'ID'>,
};


export type ScopedAccessTokenDeleteMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ scoped_access_token_delete?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type ScopedAccessTokenForFindingIdCreateMutationVariables = {
  finding_id: $ElementType<Scalars, 'String'>,
  body: ScopedAccessTokenParams,
};


export type ScopedAccessTokenForFindingIdCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ scoped_access_token_for_finding_id: ({
      ...{ __typename: 'ScopedAccessTokenResponse' },
    ...$Pick<ScopedAccessTokenResponse, { access_granted_by: *, access_token: *, date_created: *, date_expires: *, date_updated?: *, id: * }>,
    ...{ app_secure_access_properties?: ?({
        ...{ __typename: 'AppSecureAccessProperties' },
      ...$Pick<AppSecureAccessProperties, { can_access_all_apps: *, can_access_app_ids?: *, can_access_scope?: *, can_access_app_secure_security_finding_id?: * }>
    }), app_search_access_properties?: ?({
        ...{ __typename: 'AppSearchAccessProperties' },
      ...$Pick<AppSearchAccessProperties, { can_access_all_apps: *, can_access_app_ids?: *, can_access_scope?: * }>
    }) }
  }) }
});

export type ScopedAccessTokenForMobileAppIdCreateMutationVariables = {
  mobile_app_id: $ElementType<Scalars, 'String'>,
  body: ScopedAccessTokenParams,
};


export type ScopedAccessTokenForMobileAppIdCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ scoped_access_token_for_mobile_app_id: ({
      ...{ __typename: 'ScopedAccessTokenResponse' },
    ...$Pick<ScopedAccessTokenResponse, { access_granted_by: *, access_token: *, date_created: *, date_expires: *, date_updated?: *, id: * }>,
    ...{ app_secure_access_properties?: ?({
        ...{ __typename: 'AppSecureAccessProperties' },
      ...$Pick<AppSecureAccessProperties, { can_access_all_apps: *, can_access_app_ids?: *, can_access_scope?: *, can_access_app_secure_security_finding_id?: * }>
    }), app_search_access_properties?: ?({
        ...{ __typename: 'AppSearchAccessProperties' },
      ...$Pick<AppSearchAccessProperties, { can_access_all_apps: *, can_access_app_ids?: *, can_access_scope?: * }>
    }) }
  }) }
});

export type ScopedAccessTokenForOpenscanAppIdCreateMutationVariables = {
  openscan_app_id: $ElementType<Scalars, 'String'>,
  body: ScopedAccessTokenParams,
};


export type ScopedAccessTokenForOpenscanAppIdCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ scoped_access_token_for_openscan_app_id: ({
      ...{ __typename: 'ScopedAccessTokenResponse' },
    ...$Pick<ScopedAccessTokenResponse, { access_granted_by: *, access_token: *, date_created: *, date_expires: *, date_updated?: *, id: * }>,
    ...{ app_secure_access_properties?: ?({
        ...{ __typename: 'AppSecureAccessProperties' },
      ...$Pick<AppSecureAccessProperties, { can_access_all_apps: *, can_access_app_ids?: *, can_access_scope?: *, can_access_app_secure_security_finding_id?: * }>
    }), app_search_access_properties?: ?({
        ...{ __typename: 'AppSearchAccessProperties' },
      ...$Pick<AppSearchAccessProperties, { can_access_all_apps: *, can_access_app_ids?: *, can_access_scope?: * }>
    }) }
  }) }
});

export type ScopedAccessTokenForOnboardingCreateMutationVariables = {
  body: ScopedAccessTokenOnboardingParams,
};


export type ScopedAccessTokenForOnboardingCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ scoped_access_token_for_onboarding: ({
      ...{ __typename: 'ScopedAccessTokenResponse' },
    ...$Pick<ScopedAccessTokenResponse, { access_granted_by: *, access_token: *, date_created: *, date_expires: *, date_updated?: *, id: * }>,
    ...{ app_secure_access_properties?: ?({
        ...{ __typename: 'AppSecureAccessProperties' },
      ...$Pick<AppSecureAccessProperties, { can_access_all_apps: *, can_access_app_ids?: *, can_access_scope?: *, can_access_app_secure_security_finding_id?: * }>
    }), app_search_access_properties?: ?({
        ...{ __typename: 'AppSearchAccessProperties' },
      ...$Pick<AppSearchAccessProperties, { can_access_all_apps: *, can_access_app_ids?: *, can_access_scope?: * }>
    }) }
  }) }
});

export type SdkFindingsListQueryVariables = {
  mobile_app_id?: ?$ElementType<Scalars, 'Float'>,
  sdk_id?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter?: ?SdkFindingsListFilter,
  status_group?: ?StatusGroup,
  updated_since?: ?$ElementType<Scalars, 'Date'>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
};


export type SdkFindingsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ sdk_findings_list: ({
      ...{ __typename: 'SDKFindingCollectionResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), sdk_findings?: ?Array<({
        ...{ __typename: 'SDKFindingResponse' },
      ...$Pick<SdkFindingResponse, { date_created: *, date_updated: *, id: *, sdk_id: *, mobile_app_id: *, template_id?: *, aggregated_status: *, aggregated_status_date: *, title?: *, description?: *, logo_url?: *, category: *, url?: *, associated_domains?: * }>,
      ...{ targets?: ?Array<({
          ...{ __typename: 'SDKFindingTarget' },
        ...$Pick<SdkFindingTarget, { date_created: *, date_updated: *, sdk_finding_id: *, originating_scan_result_id?: *, id: *, formatted_text?: *, raw_text?: * }>,
        ...{ statuses?: ?Array<({
            ...{ __typename: 'SDKFindingTargetStatus' },
          ...$Pick<SdkFindingTargetStatus, { date: *, status: * }>
        })> }
      })>, sdk_issues?: ?Array<({
          ...{ __typename: 'SDKIssues' },
        ...$Pick<SdkIssues, { target_ids?: * }>,
        ...{ security_finding: ({
            ...{ __typename: 'SecurityFinding' },
          ...$Pick<SecurityFinding, { id: *, date_updated: *, date_created: *, description: *, description_intro?: *, title: *, category?: *, exploitability?: *, priority?: *, severity: *, finding_type?: *, recommendation?: *, reproduction_steps?: *, screenshot_url?: *, secure_code?: *, template_id?: *, is_permanently_closed?: *, affecting_mediawatch_article_ids?: *, aggregated_status: *, aggregated_status_date: *, issue_type_id: *, app_protection_score?: *, mobile_app_id: *, importance_tags?: * }>,
          ...{ compliance_policy_references?: ?Array<({
              ...{ __typename: 'CompliancePolicyReference' },
            ...$Pick<CompliancePolicyReference, { compliance_policy: *, link: *, markdown: * }>
          })>, targets: Array<({
              ...{ __typename: 'Target' },
            ...$Pick<Target, { date_created: *, date_updated: *, external_id?: *, external_bug_tracker_url?: *, raw_text?: *, security_finding_id: *, formatted_text: *, id: * }>,
            ...{ statuses: Array<({
                ...{ __typename: 'TargetStatusWithDate' },
              ...$Pick<TargetStatusWithDate, { status: *, date: * }>
            })>, additional_contexts?: ?Array<({
                ...{ __typename: 'TargetAdditionalContext' },
              ...$Pick<TargetAdditionalContext, { body: *, type: * }>
            })> }
          })>, notes?: ?Array<({
              ...{ __typename: 'Note' },
            ...$Pick<Note, { id: *, text: *, date: *, date_created: *, date_updated: *, author_email: *, security_finding_id: * }>
          })> }
        }) }
      })> }
    })> }
  }) }
});

export type SecretStringsListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type SecretStringsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ secret_strings_list: ({
      ...{ __typename: 'SecretStringsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), secret_strings?: ?Array<({
        ...{ __typename: 'SecretString' },
      ...$Pick<SecretString, { id: *, added_by: *, date_created: *, value: *, exclude_from_results: * }>
    })> }
  }) }
});

export type SecretStringsCreateMutationVariables = {
  body: SecretStringParams,
};


export type SecretStringsCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ secret_strings_create: ({
      ...{ __typename: 'SecretString' },
    ...$Pick<SecretString, { id: *, added_by: *, date_created: *, value: *, exclude_from_results: * }>
  }) }
});

export type SecretStringsRemoveMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type SecretStringsRemoveMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ secret_strings_remove?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type SecretStringsUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: SecretStringUpdateParams,
};


export type SecretStringsUpdateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ secret_strings_update: ({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type SecurityFindingsSearchQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  keywords?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
  mobile_app_id?: ?Array<$ElementType<Scalars, 'String'>>,
  current_status?: ?Array<TargetStatus>,
  priority?: ?Array<SecurityFindingSearchPriority>,
  importance_tags?: ?Array<SecurityFindingSearchImportanceTag>,
  severity?: ?Array<FindingSeverity>,
  compliance_policy?: ?Array<CompliancePolicy>,
  release_type?: ?Array<SecurityFindingSearchReleaseType>,
  age?: ?$ElementType<Scalars, 'Float'>,
  targets_created_after?: ?$ElementType<Scalars, 'Date'>,
};


export type SecurityFindingsSearchQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ security_findings_search: ({
      ...{ __typename: 'SecurityFindingsSearchResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), security_findings?: ?Array<({
        ...{ __typename: 'SecurityFinding' },
      ...$Pick<SecurityFinding, { id: *, date_updated: *, date_created: *, description: *, description_intro?: *, title: *, category?: *, exploitability?: *, priority?: *, severity: *, finding_type?: *, recommendation?: *, reproduction_steps?: *, screenshot_url?: *, secure_code?: *, template_id?: *, is_permanently_closed?: *, affecting_mediawatch_article_ids?: *, aggregated_status: *, aggregated_status_date: *, issue_type_id: *, app_protection_score?: *, mobile_app_id: *, importance_tags?: * }>,
      ...{ compliance_policy_references?: ?Array<({
          ...{ __typename: 'CompliancePolicyReference' },
        ...$Pick<CompliancePolicyReference, { compliance_policy: *, link: *, markdown: * }>
      })>, targets: Array<({
          ...{ __typename: 'Target' },
        ...$Pick<Target, { date_created: *, date_updated: *, external_id?: *, external_bug_tracker_url?: *, raw_text?: *, security_finding_id: *, formatted_text: *, id: * }>,
        ...{ statuses: Array<({
            ...{ __typename: 'TargetStatusWithDate' },
          ...$Pick<TargetStatusWithDate, { status: *, date: * }>
        })>, additional_contexts?: ?Array<({
            ...{ __typename: 'TargetAdditionalContext' },
          ...$Pick<TargetAdditionalContext, { body: *, type: * }>
        })> }
      })>, notes?: ?Array<({
          ...{ __typename: 'Note' },
        ...$Pick<Note, { id: *, text: *, date: *, date_created: *, date_updated: *, author_email: *, security_finding_id: * }>
      })>, included_mobile_app_summary?: ?({
          ...{ __typename: 'SecurityFindingIncludedMobileAppSummary' },
        ...$Pick<SecurityFindingIncludedMobileAppSummary, { id?: *, name?: *, platform: *, release_type?: *, bundle_id: *, app_version?: *, icon_url?: *, store_url?: * }>
      }) }
    })> }
  }) }
});

export type CreateSupportRequestMutationVariables = {
  body: SevenhellSupportRequestsCreateParams,
};


export type CreateSupportRequestMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ sevenhell_support_requests_create: ({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type JiraTicketCreateMutationVariables = {
  security_finding_id: $ElementType<Scalars, 'ID'>,
  target_id: $ElementType<Scalars, 'ID'>,
};


export type JiraTicketCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ security_finding_targets_jira_ticket_create: ({
      ...{ __typename: 'SecurityFinding' },
    ...$Pick<SecurityFinding, { id: *, description: *, description_intro?: *, date_created: *, date_updated: *, title: *, category?: *, exploitability?: *, priority?: *, severity: *, finding_type?: *, recommendation?: *, reproduction_steps?: *, screenshot_url?: *, secure_code?: *, template_id?: *, is_permanently_closed?: *, affecting_mediawatch_article_ids?: *, aggregated_status: *, aggregated_status_date: *, app_protection_score?: *, mobile_app_id: *, importance_tags?: *, issue_type_id: * }>,
    ...{ compliance_policy_references?: ?Array<({
        ...{ __typename: 'CompliancePolicyReference' },
      ...$Pick<CompliancePolicyReference, { compliance_policy: *, link: *, markdown: * }>
    })>, targets: Array<({
        ...{ __typename: 'Target' },
      ...$Pick<Target, { date_created: *, date_updated: *, external_id?: *, external_bug_tracker_url?: *, raw_text?: *, security_finding_id: *, formatted_text: *, id: * }>,
      ...{ statuses: Array<({
          ...{ __typename: 'TargetStatusWithDate' },
        ...$Pick<TargetStatusWithDate, { status: *, date: * }>
      })>, additional_contexts?: ?Array<({
          ...{ __typename: 'TargetAdditionalContext' },
        ...$Pick<TargetAdditionalContext, { body: *, type: * }>
      })> }
    })>, notes?: ?Array<({
        ...{ __typename: 'Note' },
      ...$Pick<Note, { id: *, text: *, date: *, date_created: *, date_updated: *, author_email: *, security_finding_id: * }>
    })>, included_mobile_app_summary?: ?({
        ...{ __typename: 'SecurityFindingIncludedMobileAppSummary' },
      ...$Pick<SecurityFindingIncludedMobileAppSummary, { id?: *, name?: *, platform: *, release_type?: *, bundle_id: *, app_version?: *, icon_url?: *, store_url?: * }>
    }) }
  }) }
});

export type CreateTargetStatusMutationVariables = {
  security_finding_id: $ElementType<Scalars, 'ID'>,
  target_id: $ElementType<Scalars, 'ID'>,
  body?: ?TargetStatusCreateParams,
};


export type CreateTargetStatusMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ security_findings_targets_statuses_create: ({
      ...{ __typename: 'TargetStatusWithFinding' },
    ...$Pick<TargetStatusWithFinding, { date: *, status: * }>,
    ...{ security_finding?: ?({
        ...{ __typename: 'SecurityFinding' },
      ...$Pick<SecurityFinding, { id: *, description: *, description_intro?: *, date_created: *, date_updated: *, title: *, category?: *, exploitability?: *, priority?: *, severity: *, finding_type?: *, recommendation?: *, reproduction_steps?: *, screenshot_url?: *, secure_code?: *, template_id?: *, is_permanently_closed?: *, affecting_mediawatch_article_ids?: *, aggregated_status: *, aggregated_status_date: *, app_protection_score?: *, mobile_app_id: *, importance_tags?: *, issue_type_id: * }>,
      ...{ compliance_policy_references?: ?Array<({
          ...{ __typename: 'CompliancePolicyReference' },
        ...$Pick<CompliancePolicyReference, { compliance_policy: *, link: *, markdown: * }>
      })>, targets: Array<({
          ...{ __typename: 'Target' },
        ...$Pick<Target, { date_created: *, date_updated: *, external_id?: *, external_bug_tracker_url?: *, raw_text?: *, security_finding_id: *, formatted_text: *, id: * }>,
        ...{ statuses: Array<({
            ...{ __typename: 'TargetStatusWithDate' },
          ...$Pick<TargetStatusWithDate, { status: *, date: * }>
        })>, additional_contexts?: ?Array<({
            ...{ __typename: 'TargetAdditionalContext' },
          ...$Pick<TargetAdditionalContext, { body: *, type: * }>
        })> }
      })>, notes?: ?Array<({
          ...{ __typename: 'Note' },
        ...$Pick<Note, { id: *, text: *, date: *, date_created: *, date_updated: *, author_email: *, security_finding_id: * }>
      })> }
    }) }
  }) }
});

export type GetApiKeysQueryVariables = {};


export type GetApiKeysQuery = ({
    ...{ __typename: 'Query' },
  ...{ get_api_keys: ({
      ...{ __typename: 'GetApiKeysResponse' },
    ...$Pick<GetApiKeysResponse, { upload_api_key: * }>
  }) }
});

export type AppUploadsCreateMutationVariables = {};


export type AppUploadsCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ app_uploads_create: ({
      ...{ __typename: 'AppUploadsResponse' },
    ...$Pick<AppUploadsResponse, { upload_url: * }>
  }) }
});

export type MobileAppInsightFindingsGetQueryVariables = {
  mobile_app_id: $ElementType<Scalars, 'String'>,
};


export type MobileAppInsightFindingsGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_app_insight_findings_get: ({
      ...{ __typename: 'MobileAppInsightFindingResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), insight_findings?: ?Array<({
        ...{ __typename: 'InsightFinding' },
      ...$Pick<InsightFinding, { aggregated_status: *, aggregated_status_date: *, date_created: *, date_updated: *, description: *, id: *, insight_type_id?: *, mobile_app_id?: *, title?: * }>,
      ...{ targets: Array<({
          ...{ __typename: 'InsightFindingTarget' },
        ...$Pick<InsightFindingTarget, { date_created: *, date_updated: *, external_bug_tracker_url?: *, external_id?: *, formatted_text: *, id: *, raw_text?: * }>,
        ...{ additional_contexts?: ?Array<({
            ...{ __typename: 'TargetAdditionalContext' },
          ...$Pick<TargetAdditionalContext, { body: *, type: * }>
        })>, statuses: Array<({
            ...{ __typename: 'TargetStatusWithDate' },
          ...$Pick<TargetStatusWithDate, { date: *, status: * }>
        })> }
      })> }
    })> }
  }) }
});

export type MobileAppScansCredentialsMutationVariables = {
  body: MobileAppScanCredentialsParams,
};


export type MobileAppScansCredentialsMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ mobile_app_scan_credentials_post: ({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type MobileChangesStatsQueryVariables = {};


export type MobileChangesStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_changes_stats: ({
      ...{ __typename: 'MobileChangesStatsResponse' },
    ...{ mobile_changes_stats: Array<({
        ...{ __typename: 'MobileChangesStatsResponseItem' },
      ...$Pick<MobileChangesStatsResponseItem, { event_count: *, event_type: * }>
    })> }
  }) }
});

export type MobileCoverageStatsQueryVariables = {};


export type MobileCoverageStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_coverage_stats: ({
      ...{ __typename: 'MobileCoverageStatsResponse' },
    ...{ card_groups: Array<({
        ...{ __typename: 'MobileCoverageStatsResponseItem' },
      ...$Pick<MobileCoverageStatsResponseItem, { title: * }>,
      ...{ cards: Array<({
          ...{ __typename: 'MobileCoverageStatsResponseItemCard' },
        ...$Pick<MobileCoverageStatsResponseItemCard, { issue_count?: *, name: *, status: * }>
      })> }
    })> }
  }) }
});

export type MobileAppChangesQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type MobileAppChangesQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_apps_changes: ({
      ...{ __typename: 'MobileAppChangesResponse' },
    ...{ included_mobile_changes_stats: ({
        ...{ __typename: 'MobileAppChangesResponseStats' },
      ...$Pick<MobileAppChangesResponseStats, { changes_with_issues_count: *, last_30_days_changes_count: *, last_30_days_changes_with_issues_count: *, last_7_days_changes_count: *, last_year_changes_count: * }>
    }), mobile_changes: Array<({
        ...{ __typename: 'MobileAppChangesResponseItem' },
      ...$Pick<MobileAppChangesResponseItem, { date_created: *, description: *, discovered_via_icon_url: *, icon_url: *, is_compliant_to_security_policy: *, is_internet_accessible: *, issues_count: *, name: * }>,
      ...{ mobile_app_target: ({
          ...{ __typename: 'MobileAppChangesResponseItemMobileAppTarget' },
        ...$Pick<MobileAppChangesResponseItemMobileAppTarget, { bundle_id: *, icon_url: *, mobile_app_id: *, name: *, platform_url: *, version: * }>
      }) }
    })>, pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }) }
  }) }
});

export type MobilePolicyRuleStatsListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_third_party_only?: ?$ElementType<Scalars, 'Boolean'>,
  keywords?: ?$ElementType<Scalars, 'String'>,
  relevance?: ?Array<?PolicyRuleTypeRelevance>,
  mobile_app_id?: ?$ElementType<Scalars, 'String'>,
  mobile_app_uuid?: ?Array<$ElementType<Scalars, 'String'>>,
  sdk_id?: ?Array<$ElementType<Scalars, 'String'>>,
};


export type MobilePolicyRuleStatsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_policy_rule_stats_list: ({
      ...{ __typename: 'MobilePolicyRuleStatsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), mobile_policy_rules?: ?Array<({
        ...{ __typename: 'MobilePolicyRule' },
      ...$Pick<MobilePolicyRule, { id: *, title: *, relevance: *, wont_fix_violations_count: *, resolved_violations_count: *, open_violations_count: * }>
    })> }
  }) }
});

export type OpenScanSecurityFindingsQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type OpenScanSecurityFindingsQuery = ({
    ...{ __typename: 'Query' },
  ...{ openscan_security_findings_get: ({
      ...{ __typename: 'OpenScanSecurityFindingResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), openscan_security_findings?: ?Array<({
        ...{ __typename: 'OpenScanSecurityFinding' },
      ...$Pick<OpenScanSecurityFinding, { date_created: *, openscan_app_id: *, description: *, description_intro?: *, title: *, category?: *, severity: *, exploitability?: *, importance_tags?: *, recommendation: *, screenshot_url?: *, secure_code?: * }>,
      ...{ compliance_policy_references?: ?Array<({
          ...{ __typename: 'CompliancePolicyReference' },
        ...$Pick<CompliancePolicyReference, { compliance_policy: *, link: *, markdown: * }>
      })> }
    })> }
  }) }
});

export type SdkListQueryVariables = {
  page_size?: ?$ElementType<Scalars, 'Float'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter?: ?$ElementType<Scalars, 'String'>,
  omit_sdk_findings?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
};


export type SdkListQuery = ({
    ...{ __typename: 'Query' },
  ...{ sdks_list: ({
      ...{ __typename: 'SDKCollectionResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: *, next_cursor?: * }>
    }), sdks?: ?Array<({
        ...{ __typename: 'SDKResponse' },
      ...$Pick<SdkResponse, { id: *, url?: *, title: *, description: *, category: *, logo_url?: *, sdk_finding_ids?: *, related_open_security_finding_count: *, related_open_security_finding_beyond_subscription_level_count: *, mobile_app_ids_curently_with_this_sdk?: * }>,
      ...{ license_information?: ?Array<({
          ...{ __typename: 'SDKLicenseInformationResponse' },
        ...$Pick<SdkLicenseInformationResponse, { license: *, detail: * }>
      })> }
    })>, sdk_findings?: ?Array<({
        ...{ __typename: 'SDKFindingResponse' },
      ...$Pick<SdkFindingResponse, { date_created: *, date_updated: *, id: *, sdk_id: *, mobile_app_id: *, template_id?: *, aggregated_status: *, aggregated_status_date: *, title?: *, description?: *, logo_url?: *, category: *, url?: *, associated_domains?: * }>,
      ...{ targets?: ?Array<({
          ...{ __typename: 'SDKFindingTarget' },
        ...$Pick<SdkFindingTarget, { date_created: *, date_updated: *, sdk_finding_id: *, originating_scan_result_id?: *, id: *, formatted_text?: *, raw_text?: * }>,
        ...{ statuses?: ?Array<({
            ...{ __typename: 'SDKFindingTargetStatus' },
          ...$Pick<SdkFindingTargetStatus, { date: *, status: * }>
        })> }
      })>, sdk_issues?: ?Array<({
          ...{ __typename: 'SDKIssues' },
        ...$Pick<SdkIssues, { target_ids?: * }>,
        ...{ security_finding: ({
            ...{ __typename: 'SecurityFinding' },
          ...$Pick<SecurityFinding, { id: *, date_updated: *, date_created: *, description: *, description_intro?: *, title: *, category?: *, exploitability?: *, priority?: *, severity: *, finding_type?: *, recommendation?: *, reproduction_steps?: *, screenshot_url?: *, secure_code?: *, template_id?: *, is_permanently_closed?: *, affecting_mediawatch_article_ids?: *, aggregated_status: *, aggregated_status_date: *, issue_type_id: *, app_protection_score?: *, mobile_app_id: *, importance_tags?: * }>,
          ...{ compliance_policy_references?: ?Array<({
              ...{ __typename: 'CompliancePolicyReference' },
            ...$Pick<CompliancePolicyReference, { compliance_policy: *, link: *, markdown: * }>
          })>, targets: Array<({
              ...{ __typename: 'Target' },
            ...$Pick<Target, { date_created: *, date_updated: *, external_id?: *, external_bug_tracker_url?: *, raw_text?: *, security_finding_id: *, formatted_text: *, id: * }>,
            ...{ statuses: Array<({
                ...{ __typename: 'TargetStatusWithDate' },
              ...$Pick<TargetStatusWithDate, { status: *, date: * }>
            })>, additional_contexts?: ?Array<({
                ...{ __typename: 'TargetAdditionalContext' },
              ...$Pick<TargetAdditionalContext, { body: *, type: * }>
            })> }
          })>, notes?: ?Array<({
              ...{ __typename: 'Note' },
            ...$Pick<Note, { id: *, text: *, date: *, date_created: *, date_updated: *, author_email: *, security_finding_id: * }>
          })> }
        }) }
      })> }
    })> }
  }) }
});

export type SdkMobileAppsListQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  page_size?: ?$ElementType<Scalars, 'Float'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  platform?: ?$ElementType<Scalars, 'String'>,
};


export type SdkMobileAppsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_apps_sdks_list: ({
      ...{ __typename: 'SDKMobileAppsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: *, next_cursor?: * }>
    }), mobile_apps?: ?Array<({
        ...{ __typename: 'SDKAppResponse' },
      ...$Pick<SdkAppResponse, { id: *, bundle_id: *, date_added?: *, has_data_firewall_enabled: *, name: *, platform: *, open_findings_count: *, platform_icon_url?: * }>,
      ...{ top_three_findings?: ?Array<({
          ...{ __typename: 'SDKAppFindingResponse' },
        ...$Pick<SdkAppFindingResponse, { id: *, title: * }>
      })> }
    })> }
  }) }
});

export type SecurityFindingFieldsFragment = ({
    ...{ __typename: 'SecurityFinding' },
  ...$Pick<SecurityFinding, { id: *, date_updated: *, date_created: *, description: *, description_intro?: *, title: *, category?: *, exploitability?: *, priority?: *, severity: *, finding_type?: *, recommendation?: *, reproduction_steps?: *, screenshot_url?: *, secure_code?: *, template_id?: *, is_permanently_closed?: *, affecting_mediawatch_article_ids?: *, aggregated_status: *, aggregated_status_date: *, issue_type_id: *, app_protection_score?: *, mobile_app_id: *, importance_tags?: * }>,
  ...{ compliance_policy_references?: ?Array<({
      ...{ __typename: 'CompliancePolicyReference' },
    ...$Pick<CompliancePolicyReference, { compliance_policy: *, link: *, markdown: * }>
  })>, targets: Array<({
      ...{ __typename: 'Target' },
    ...$Pick<Target, { date_created: *, date_updated: *, external_id?: *, external_bug_tracker_url?: *, raw_text?: *, security_finding_id: *, formatted_text: *, id: * }>,
    ...{ statuses: Array<({
        ...{ __typename: 'TargetStatusWithDate' },
      ...$Pick<TargetStatusWithDate, { status: *, date: * }>
    })>, additional_contexts?: ?Array<({
        ...{ __typename: 'TargetAdditionalContext' },
      ...$Pick<TargetAdditionalContext, { body: *, type: * }>
    })> }
  })>, notes?: ?Array<({
      ...{ __typename: 'Note' },
    ...$Pick<Note, { id: *, security_finding_id: *, text: *, author_email: *, date: *, date_created: *, date_updated: * }>
  })>, included_mobile_app_summary?: ?({
      ...{ __typename: 'SecurityFindingIncludedMobileAppSummary' },
    ...$Pick<SecurityFindingIncludedMobileAppSummary, { id?: *, name?: *, platform: *, release_type?: *, bundle_id: *, app_version?: *, icon_url?: *, store_url?: * }>
  }) }
});

export type SecurityFindingsSearchQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  keywords?: ?$ElementType<Scalars, 'String'>,
  filter_by_named_filter?: ?$ElementType<Scalars, 'String'>,
  mobile_app_id?: ?Array<$ElementType<Scalars, 'String'>>,
  mobile_app_uuid?: ?Array<$ElementType<Scalars, 'String'>>,
  current_status?: ?Array<TargetStatus>,
  priority?: ?Array<SecurityFindingSearchPriority>,
  importance_tags?: ?Array<SecurityFindingSearchImportanceTag>,
  severity?: ?Array<FindingSeverity>,
  compliance_policy?: ?Array<CompliancePolicy>,
  release_type?: ?Array<SecurityFindingSearchReleaseType>,
  age?: ?$ElementType<Scalars, 'Float'>,
  targets_created_after?: ?$ElementType<Scalars, 'Date'>,
  issue_type_id?: ?$ElementType<Scalars, 'String'>,
  has_associated_sdks?: ?$ElementType<Scalars, 'Boolean'>,
  include_only_targets_related_to_sdks?: ?$ElementType<Scalars, 'Boolean'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type SecurityFindingsSearchQuery = ({
    ...{ __typename: 'Query' },
  ...{ security_findings_search: ({
      ...{ __typename: 'SecurityFindingsSearchResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), security_findings?: ?Array<({
        ...{ __typename: 'SecurityFinding' },
      ...SecurityFindingFieldsFragment
    })> }
  }) }
});

export type SecurityFindingQueryVariables = {
  id: $ElementType<Scalars, 'String'>,
};


export type SecurityFindingQuery = ({
    ...{ __typename: 'Query' },
  ...{ security_finding: ({
      ...{ __typename: 'SecurityFinding' },
    ...SecurityFindingFieldsFragment
  }) }
});

export type SecurityFindingNoteMutationVariables = {
  id: $ElementType<Scalars, 'String'>,
  body: SecurityFindingNote,
};


export type SecurityFindingNoteMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ security_finding_note_create: ({
      ...{ __typename: 'Note' },
    ...$Pick<Note, { id: *, security_finding_id: *, text: *, author_email: *, date: *, date_created: *, date_updated: * }>
  }) }
});

export type SecurityFindingPatchMutationVariables = {
  id: $ElementType<Scalars, 'String'>,
  body: SecurityFindingPatchParams,
};


export type SecurityFindingPatchMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ security_finding_patch: ({
      ...{ __typename: 'SecurityFinding' },
    ...SecurityFindingFieldsFragment
  }) }
});

export type SecurityFindingPriorityPatchMutationVariables = {
  id: $ElementType<Scalars, 'String'>,
  body: SecurityFindingPatchPriorityParams,
};


export type SecurityFindingPriorityPatchMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ security_finding_update_priority: ({
      ...{ __typename: 'SecurityFinding' },
    ...SecurityFindingFieldsFragment
  }) }
});

export type SecurityFindingsNamedFiltersQueryVariables = {
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
};


export type SecurityFindingsNamedFiltersQuery = ({
    ...{ __typename: 'Query' },
  ...{ security_findings_named_filters: ({
      ...{ __typename: 'SecurityFindingsNamedFiltersResponse' },
    ...{ security_findings_named_filters: Array<({
        ...{ __typename: 'MobileProtectNamedFilterItem' },
      ...$Pick<MobileProtectNamedFilterItem, { name: *, value: *, icon_url?: *, has_children?: * }>,
      ...{ children?: ?Array<({
          ...{ __typename: 'MobileProtectNamedFilterItemChildrenItem' },
        ...$Pick<MobileProtectNamedFilterItemChildrenItem, { name: *, value: *, icon_url?: *, has_data?: * }>
      })> }
    })> }
  }) }
});

export type SecurityFindingsNamedFiltersChildrenQueryVariables = {
  name: $ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type SecurityFindingsNamedFiltersChildrenQuery = ({
    ...{ __typename: 'Query' },
  ...{ security_findings_named_filters_children: ({
      ...{ __typename: 'SecurityFindingsNamedFiltersChildrenResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), children: Array<?({
        ...{ __typename: 'SecurityFindingsNamedFiltersChildren' },
      ...$Pick<SecurityFindingsNamedFiltersChildren, { has_children?: *, icon_url?: *, name?: *, value?: * }>,
      ...{ children?: ?Array<?({
          ...{ __typename: 'SecurityFindingsNamedFiltersChildren' },
        ...$Pick<SecurityFindingsNamedFiltersChildren, { icon_url?: *, name?: *, value?: * }>
      })> }
    })> }
  }) }
});

export type MobileAppSecKpisStatsQueryVariables = {};


export type MobileAppSecKpisStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_app_sec_kpis_stats: ({
      ...{ __typename: 'MobileAppSecKpisStatsResponse' },
    ...{ bars: Array<({
        ...{ __typename: 'MobileAppSecKpisStatsResponseItem' },
      ...$Pick<MobileAppSecKpisStatsResponseItem, { color: *, title: *, value: * }>
    })> }
  }) }
});

export type MobileSecurityStatsQueryVariables = {};


export type MobileSecurityStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_security_stats: ({
      ...{ __typename: 'MobileSecurityStatsResponse' },
    ...{ legend: Array<({
        ...{ __typename: 'MobileSecurityStatsResponseLegendItem' },
      ...$Pick<MobileSecurityStatsResponseLegendItem, { color: *, title: * }>
    })>, months: Array<({
        ...{ __typename: 'MobileSecurityStatsResponseMonthItem' },
      ...$Pick<MobileSecurityStatsResponseMonthItem, { month_name: * }>,
      ...{ bars: Array<({
          ...{ __typename: 'MobileSecurityStatsResponseMonthItemBarItem' },
        ...$Pick<MobileSecurityStatsResponseMonthItemBarItem, { bar_total: * }>,
        ...{ metrics: Array<({
            ...{ __typename: 'MobileSecurityStatsResponseMonthItemBarItemMetricItem' },
          ...$Pick<MobileSecurityStatsResponseMonthItemBarItemMetricItem, { color: *, count: *, title: * }>
        })> }
      })> }
    })> }
  }) }
});

export type DeleteUserMutationVariables = {
  userId: $ElementType<Scalars, 'ID'>,
};


export type DeleteUserMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ delete_user?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type UserListsQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type UserListsQuery = ({
    ...{ __typename: 'Query' },
  ...{ users_list: ({
      ...{ __typename: 'UsersListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), users: Array<({
        ...{ __typename: 'User' },
      ...$Pick<User, { allowed_app_ids?: *, auth_strategy: *, can_access_app_search: *, can_access_app_secure: *, can_access_api_inspect: *, can_access_brand_protect: *, can_invite_users: *, can_download_reports?: *, can_access_vendor_supply_chain_security: *, date_created?: *, date_updated?: *, first_name: *, id: *, last_login_date?: *, last_name: *, login_email: *, notification_email?: *, renewal_reminder_last_dismissed?: *, role: * }>,
      ...{ effective_permissions: ({
          ...{ __typename: 'UserEffectivePermissions' },
        ...$Pick<UserEffectivePermissions, { can_access_all_asset_groups: *, can_access_all_mobile_apps: *, can_access_api_secure: *, can_access_asset_group_ids: *, can_access_cloud_secure: *, can_access_mobile_app_ids: *, can_access_mobile_phishing: *, can_access_mobile_secure: *, can_access_supply_chain_secure: *, can_access_web_secure: * }>
      }) }
    })> }
  }) }
});

export type UserByIdQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type UserByIdQuery = ({
    ...{ __typename: 'Query' },
  ...{ user_by_id?: ?({
      ...{ __typename: 'User' },
    ...$Pick<User, { allowed_app_ids?: *, auth_strategy: *, can_access_app_search: *, can_access_app_secure: *, can_access_api_inspect: *, can_access_brand_protect: *, can_invite_users: *, can_download_reports?: *, can_access_vendor_supply_chain_security: *, date_created?: *, date_updated?: *, first_name: *, id: *, last_login_date?: *, last_name: *, login_email: *, notification_email?: *, renewal_reminder_last_dismissed?: *, role: * }>,
    ...{ effective_permissions: ({
        ...{ __typename: 'UserEffectivePermissions' },
      ...$Pick<UserEffectivePermissions, { can_access_all_asset_groups: *, can_access_all_mobile_apps: *, can_access_api_secure: *, can_access_asset_group_ids: *, can_access_cloud_secure: *, can_access_mobile_app_ids: *, can_access_mobile_phishing: *, can_access_mobile_secure: *, can_access_supply_chain_secure: *, can_access_web_secure: * }>
    }) }
  }) }
});

export type UsersCreateMutationVariables = {
  body: UserCreateParams,
};


export type UsersCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ users_create: ({
      ...{ __typename: 'User' },
    ...$Pick<User, { id: *, allowed_app_ids?: *, auth_strategy: *, can_access_app_search: *, can_access_app_secure: *, can_access_api_inspect: *, can_access_brand_protect: *, can_invite_users: *, can_download_reports?: *, can_access_vendor_supply_chain_security: *, first_name: *, last_name: *, login_email: *, notification_email?: *, role: *, renewal_reminder_last_dismissed?: *, last_login_date?: *, date_updated?: *, date_created?: * }>
  }) }
});

export type UsersUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: UserPatchParams,
};


export type UsersUpdateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ users_update: ({
      ...{ __typename: 'User' },
    ...$Pick<User, { id: *, allowed_app_ids?: *, auth_strategy: *, can_access_app_search: *, can_access_app_secure: *, can_access_api_inspect: *, can_access_brand_protect: *, can_invite_users: *, can_download_reports?: *, can_access_vendor_supply_chain_security: *, first_name: *, last_name: *, login_email: *, notification_email?: *, role: *, renewal_reminder_last_dismissed?: *, last_login_date?: *, date_updated?: *, date_created?: * }>
  }) }
});

export type GetCurrentUserQueryVariables = {};


export type GetCurrentUserQuery = ({
    ...{ __typename: 'Query' },
  ...{ current_user: ({
      ...{ __typename: 'User' },
    ...$Pick<User, { id: *, allowed_app_ids?: *, auth_strategy: *, can_access_app_search: *, can_access_app_secure: *, can_access_api_inspect: *, can_access_brand_protect: *, can_invite_users: *, can_download_reports?: *, can_access_vendor_supply_chain_security: *, first_name: *, last_name: *, login_email: *, notification_email?: *, role: *, renewal_reminder_last_dismissed?: *, last_login_date?: *, date_updated?: *, date_created?: * }>
  }) }
});

export type VendorManagementVendorsListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type VendorManagementVendorsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ vendor_management_vendors_list: ({
      ...{ __typename: 'VendorManagementVendorsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), administrative_account: ({
        ...{ __typename: 'AdministrativeAccount' },
      ...$Pick<AdministrativeAccount, { id: *, logged_in: *, name: * }>
    }), vendors?: ?Array<({
        ...{ __typename: 'Vendor' },
      ...$Pick<Vendor, { scans_disabled_date?: *, date_created: *, date_updated: *, deletion_requested?: *, id: *, last_login_date?: *, logged_in: *, name: *, setup_completion_date?: *, access_to_security_issues_granted: *, setup_needed: *, scans_enabled: * }>,
      ...{ included_overview_stats?: ?({
          ...{ __typename: 'VendorIncludedOverviewStats' },
        ...$Pick<VendorIncludedOverviewStats, { mobile_apps_count: *, web_apps_count: *, combined_apis_count: *, cloud_resources_count: *, proactive_protection_score_percent: * }>
      }), included_contact_email_details?: ?Array<?({
          ...{ __typename: 'VendorIncludedContactEmailDetails' },
        ...$Pick<VendorIncludedContactEmailDetails, { email?: *, is_validated?: * }>
      })> }
    })> }
  }) }
});

export type VendorManagementVendorByIdQueryQueryVariables = {
  include?: ?$ElementType<Scalars, 'String'>,
  vendorId: $ElementType<Scalars, 'ID'>,
};


export type VendorManagementVendorByIdQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ vendor_management_vendor_by_id: ({
      ...{ __typename: 'vendorVanagementVendorByIdResponse' },
    ...$Pick<VendorVanagementVendorByIdResponse, { scans_disabled_date?: *, date_created: *, date_updated: *, deletion_requested?: *, id: *, last_login_date?: *, logged_in: *, name: *, setup_completion_date?: *, setup_needed: *, scans_enabled: * }>,
    ...{ included_contact_email_details?: ?Array<?({
        ...{ __typename: 'VendorIncludedContactEmailDetails' },
      ...$Pick<VendorIncludedContactEmailDetails, { email?: *, is_validated?: * }>
    })> }
  }) }
});

export type CreateVendorMutationVariables = {
  body: VendorManagementCreateParams,
};


export type CreateVendorMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ vendor_management_vendor_create: ({
      ...{ __typename: 'Vendor' },
    ...$Pick<Vendor, { scans_disabled_date?: *, date_created: *, date_updated: *, deletion_requested?: *, id: *, last_login_date?: *, logged_in: *, name: *, setup_completion_date?: *, setup_needed: * }>
  }) }
});

export type UpdateVendorMutationVariables = {
  vendorId: $ElementType<Scalars, 'ID'>,
  body: VendorManagementUpdateParams,
};


export type UpdateVendorMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ vendor_management_vendor_update: ({
      ...{ __typename: 'Vendor' },
    ...$Pick<Vendor, { scans_disabled_date?: *, date_created: *, date_updated: *, deletion_requested?: *, id: *, last_login_date?: *, logged_in: *, name: *, setup_completion_date?: *, setup_needed: *, scans_enabled: * }>
  }) }
});

export type LoginVendorMutationVariables = {
  vendorId: $ElementType<Scalars, 'ID'>,
};


export type LoginVendorMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ vendor_management_vendor_login: ({
      ...{ __typename: 'Session' },
    ...$Pick<Session, { session_id: *, session_expiration: * }>
  }) }
});

export type VendorManagementCurrentVendorGetQueryVariables = {
  include?: ?$ElementType<Scalars, 'String'>,
};


export type VendorManagementCurrentVendorGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ vendor_management_current_vendor_get: ({
      ...{ __typename: 'Vendor' },
    ...$Pick<Vendor, { scans_disabled_date?: *, date_created: *, date_updated: *, deletion_requested?: *, id: *, last_login_date?: *, logged_in: *, name: *, setup_completion_date?: *, access_to_security_issues_granted: *, setup_needed: *, scans_enabled: *, description?: *, industry?: *, size?: *, website_url?: *, contact_emails?: *, meta_customer_name?: * }>,
    ...{ included_overview_stats?: ?({
        ...{ __typename: 'VendorIncludedOverviewStats' },
      ...$Pick<VendorIncludedOverviewStats, { mobile_apps_count: *, web_apps_count: *, combined_apis_count: *, cloud_resources_count: *, proactive_protection_score_percent: * }>
    }), included_contact_email_details?: ?Array<?({
        ...{ __typename: 'VendorIncludedContactEmailDetails' },
      ...$Pick<VendorIncludedContactEmailDetails, { email?: *, is_validated?: * }>
    })> }
  }) }
});

export type VendorManagementCurrentVendorPatchMutationVariables = {
  body: VendorManagementCurrentVendorPatchParams,
};


export type VendorManagementCurrentVendorPatchMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ vendor_management_current_vendor_patch: ({
      ...{ __typename: 'Vendor' },
    ...$Pick<Vendor, { scans_disabled_date?: *, date_created: *, date_updated: *, deletion_requested?: *, id: *, last_login_date?: *, logged_in: *, name: *, setup_completion_date?: *, access_to_security_issues_granted: *, setup_needed: *, scans_enabled: *, description?: *, industry?: *, size?: *, website_url?: *, contact_emails?: *, meta_customer_name?: * }>
  }) }
});

export type VendorManagementCurrentVendorAccessApprovalPatchMutationVariables = {};


export type VendorManagementCurrentVendorAccessApprovalPatchMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ vendor_management_current_vendor_access_approval_patch: ({
      ...{ __typename: 'Vendor' },
    ...$Pick<Vendor, { scans_disabled_date?: *, date_created: *, date_updated: *, deletion_requested?: *, id: *, last_login_date?: *, logged_in: *, name: *, setup_completion_date?: *, access_to_security_issues_granted: *, setup_needed: *, scans_enabled: *, description?: *, industry?: *, size?: *, website_url?: *, contact_emails?: *, meta_customer_name?: * }>
  }) }
});

export type VendorManagementCurrentVendorAccessApprovalDeleteMutationVariables = {};


export type VendorManagementCurrentVendorAccessApprovalDeleteMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ vendor_management_current_vendor_access_approval_delete: ({
      ...{ __typename: 'Vendor' },
    ...$Pick<Vendor, { scans_disabled_date?: *, date_created: *, date_updated: *, deletion_requested?: *, id: *, last_login_date?: *, logged_in: *, name: *, setup_completion_date?: *, access_to_security_issues_granted: *, setup_needed: *, scans_enabled: *, description?: *, industry?: *, size?: *, website_url?: *, contact_emails?: *, meta_customer_name?: * }>
  }) }
});

export type RequestOnboardingFromContactsMutationVariables = {
  vendorId: $ElementType<Scalars, 'ID'>,
  body: VendorManagementRequestOnboardingContactsParams,
};


export type RequestOnboardingFromContactsMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ vendor_management_request_onboarding_from_contacts?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type RequestApprovalFromContactsMutationVariables = {
  vendorId: $ElementType<Scalars, 'ID'>,
};


export type RequestApprovalFromContactsMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ vendor_management_request_approval_from_contacts?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type UserAssetGroupsQueryQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type UserAssetGroupsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ user_asset_groups_get?: ?({
      ...{ __typename: 'UserAssetGroupsResponse' },
    ...$Pick<UserAssetGroupsResponse, { has_access_to_all_asset_groups: * }>,
    ...{ asset_group_accesses: Array<?({
        ...{ __typename: 'AssetGroupAccess' },
      ...$Pick<AssetGroupAccess, { asset_group_id: *, asset_group_name: *, is_accessible_to_user: * }>
    })> }
  }) }
});

export type UserAssetGroupsUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: UserAssetGroupsPatchParams,
};


export type UserAssetGroupsUpdateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ user_asset_groups_update?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type UsersGetQueryQueryVariables = {
  userId: $ElementType<Scalars, 'ID'>,
};


export type UsersGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ user_by_id?: ?({
      ...{ __typename: 'User' },
    ...$Pick<User, { allowed_app_ids?: *, auth_strategy: *, can_access_app_search: *, can_access_app_secure: *, can_access_api_inspect: *, can_access_brand_protect: *, can_invite_users: *, can_download_reports?: *, can_access_vendor_supply_chain_security: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: *, role: *, last_login_date?: *, renewal_reminder_last_dismissed?: *, date_created?: *, date_updated?: * }>
  }) }
});

export type UsersListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type UsersListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ users_list: ({
      ...{ __typename: 'UsersListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), users: Array<({
        ...{ __typename: 'User' },
      ...$Pick<User, { id: *, first_name: *, last_name: *, login_email: *, last_login_date?: *, notification_email?: *, can_access_app_secure: *, can_access_app_search: *, can_access_brand_protect: *, can_access_api_inspect: *, can_access_vendor_supply_chain_security: *, role: *, auth_strategy: *, allowed_app_ids?: *, can_invite_users: *, renewal_reminder_last_dismissed?: *, date_created?: *, date_updated?: * }>
    })> }
  }) }
});

export type VendorManagementMobileStatsQueryVariables = {};


export type VendorManagementMobileStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ vendor_management_mobile_stats: ({
      ...{ __typename: 'VendorManagementMobileStatsResponse' },
    ...$Pick<VendorManagementMobileStatsResponse, { mobile_apps_count: *, mobile_apps_count_30_day_delta: * }>
  }) }
});
